import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import BaseAutocomplete from './BaseAutocomplete'

const FieldAutocomplete = ({
  name,
  options,
  onChange,
  multiple,
  getOptionLabel,
  getOptionSelected,
  disabled,
  noOptionsText,
  ...props
}) => {
  const identity = (value) => {
    if (value === undefined) {
      return null
    }

    return value
  }

  return (
    <Field
      name={name}
      parse={identity}
      multiple={multiple}
      render={({ input: { onChange: inputChange, ...input }, meta }) => (
        <BaseAutocomplete
          {...input}
          noOptionsText={noOptionsText}
          options={options}
          multiple={multiple}
          getOptionLabel={getOptionLabel}
          getOptionSelected={getOptionSelected}
          disabled={disabled}
          onChange={(event, option) => {
            const value = multiple ? option : option?.value
            inputChange(value)
            if (onChange) onChange(value)
          }}
          inputProps={{
            ...props,
            error: meta.touched && !!meta.error,
            helperText: meta.touched && meta.error,
          }}
        />
      )}
    />
  )
}

FieldAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  noOptionsText: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionSelected: PropTypes.func,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
}

export default FieldAutocomplete
