import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const getQualificadorActionTypes = apiActionTypes('qualificadores/API_GET_QUALIFICADOR')
const createQualificadorActionTypes = apiActionTypes('qualificadores/API_CRIAR_QUALIFICADOR')
const updateQualificadorActionTypes = apiActionTypes('qualificadores/API_ATUALIZAR_QUALIFICADOR')
const deleteQualificadorActionTypes = apiActionTypes('qualificadores/API_EXCLUIR_QUALIFICADOR')
const fetchQualificadorActionTypes = apiActionTypes('qualificadores/API_LISTAR_QUALIFICADORES')

export const API_LISTAR_QUALIFICADORES = asApiTypesObject(fetchQualificadorActionTypes)
export const API_GET_QUALIFICADOR = asApiTypesObject(getQualificadorActionTypes)
export const API_CRIAR_QUALIFICADOR = asApiTypesObject(createQualificadorActionTypes)
export const API_ATUALIZAR_QUALIFICADOR = asApiTypesObject(updateQualificadorActionTypes)
export const API_EXCLUIR_QUALIFICADOR = asApiTypesObject(deleteQualificadorActionTypes)

export const apiListarQualificadores = ({ paisId }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/qualificadores`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchQualificadorActionTypes,
}, { ...meta, query: { paisId } })

export const apiGetQualificador = (id, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/qualificadores/${id}`,
  method: 'GET',
  types: getQualificadorActionTypes,
}, meta)

export const apiCriarQualificador = (qualificadores, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/qualificadores`,
  method: 'POST',
  types: createQualificadorActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(qualificadores),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiAtualizarQualificador = ({ id, qualificadores }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/qualificadores/${id}`,
  method: 'PUT',
  types: updateQualificadorActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(qualificadores),
}, { ...meta, successNotification: 'mensagens.atualizado' })

export const apiExcluirQualificador = (id, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/qualificadores/${id}`,
  method: 'DELETE',
  types: deleteQualificadorActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })

export const apiSalvarListaQualificador = ({ lista }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/qualificadores/salvarLista`,
  method: 'POST',
  types: createQualificadorActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(lista),
}, { ...meta, successNotification: 'mensagens.criado' })
