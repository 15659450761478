import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchAspectosActionTypes = apiActionTypes('aspectos/API_LISTAR_ASPECTOS')
const updateAspectosActionTypes = apiActionTypes('aspectos/API_ATUALIZAR_ASPECTOS')

export const API_LISTAR_ASPECTOS = asApiTypesObject(fetchAspectosActionTypes)
export const API_ATUALIZAR_ASPECTOS = asApiTypesObject(updateAspectosActionTypes)

export const apiListarAspectos = ({ paisId }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/aspectos`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchAspectosActionTypes,
}, { ...meta, query: { paisId } })

export const apiAtualizarAspectos = ({ id, aspecto }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/aspectos/${id}`,
  method: 'PUT',
  types: updateAspectosActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(aspecto),
}, { ...meta, successNotification: 'mensagens.atualizado' })
