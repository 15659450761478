import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { filter, map } from 'lodash'
import { API_LISTAR_DADOS_IPB } from 'store/modules/api/pressaoBiodiversidade.actions'
import { API_CRIAR_OCUPACAO_AREA, API_ATUALIZAR_OCUPACAO_AREA, API_EXCLUIR_OCUPACAO_AREA, API_GET_OCUPACAO_AREAS } from 'store/modules/api/ocupacaoArea.actions'
import { CALL_NOVO_OCUPACAO_AREA } from 'store/modules/ocupacaoArea/ocupacaoArea.actions'
import { API_EXCLUIR_ANEXO } from 'store/modules/api/anexos.actions'

const lista = (state = [], { type, payload }) => {
  switch (type) {
    case API_LISTAR_DADOS_IPB.success: return payload.ocupacaoAreas
    case API_CRIAR_OCUPACAO_AREA.success: return [...state, payload.ocupacaoArea]
    case API_EXCLUIR_OCUPACAO_AREA.success: return filter(state, (u) => u.id !== payload.id)
    case API_ATUALIZAR_OCUPACAO_AREA.success:
      return map(state, (u) => (u.id === payload.ocupacaoArea.id ? payload.ocupacaoArea : u))
    default: return state
  }
}

const selecionado = (state = null, { type, payload }) => {
  switch (type) {
    case API_GET_OCUPACAO_AREAS.success: return payload
    case CALL_NOVO_OCUPACAO_AREA: return null
    case API_CRIAR_OCUPACAO_AREA.success: return null
    case API_ATUALIZAR_OCUPACAO_AREA.success: return null
    case API_EXCLUIR_ANEXO.success:
      return state?.anexo?.id === +payload ? { ...state, anexo: null } : state
    default: return state
  }
}

const isCarregandoOcupacaoAreas = loadingReducer(false, API_LISTAR_DADOS_IPB)

const isSaving = loadingReducer(false, {
  request: [
    API_ATUALIZAR_OCUPACAO_AREA.request,
    API_CRIAR_OCUPACAO_AREA.request,
  ],
  success: [
    API_ATUALIZAR_OCUPACAO_AREA.success,
    API_CRIAR_OCUPACAO_AREA.success,
  ],
  failure: [
    API_ATUALIZAR_OCUPACAO_AREA.failure,
    API_CRIAR_OCUPACAO_AREA.failure,
  ],
})

export default combineReducers({
  lista,
  selecionado,
  isCarregandoOcupacaoAreas,
  isSaving,
})
