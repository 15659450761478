const ACTION_PATH = 'app/residuos'

export const CALL_CRIAR_RESIDUOS = `${ACTION_PATH}/CALL_CRIAR_RESIDUOS`
export const CALL_ATUALIZAR_RESIDUOS = `${ACTION_PATH}/CALL_ATUALIZAR_RESIDUOS`
export const CALL_EXCLUIR_RESIDUO = `${ACTION_PATH}/CALL_EXCLUIR_RESIDUO`
export const CALL_SELECIONAR_RESIDUO = `${ACTION_PATH}/CALL_SELECIONAR_RESIDUO`
export const CALL_NOVO_RESIDUO = `${ACTION_PATH}/CALL_NOVO_RESIDUO`

export const callCriarResiduos = (residuo) => ({
  type: CALL_CRIAR_RESIDUOS,
  payload: residuo,
})

export const callAtualizarResiduos = ({ id, residuo }) => ({
  type: CALL_ATUALIZAR_RESIDUOS,
  payload: { id, residuo },
})

export const callExcluirResiduo = ({ id }) => ({
  type: CALL_EXCLUIR_RESIDUO,
  payload: id,
})

export const callSelecionarResiduo = ({ id }) => ({
  type: CALL_SELECIONAR_RESIDUO,
  payload: { id },
})

export const callNovoResiduo = () => ({
  type: CALL_NOVO_RESIDUO,
})
