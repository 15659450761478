import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchMoedasActionTypes = apiActionTypes('moedas/API_LISTAR_MOEDAS')

export const API_LISTAR_MOEDAS = asApiTypesObject(fetchMoedasActionTypes)

export const apiListarMoedas = (data, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/moedas`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchMoedasActionTypes,
}, { ...meta })
