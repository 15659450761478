import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from '../apiAction'

const getRelatorioIPBDetalhadoActionTypes = apiActionTypes('relatorios/API_GET_RELATORIO_IPB_DETALHADO')
const getRelatorioIPBResumidoActionTypes = apiActionTypes('relatorios/API_GET_RELATORIO_IPB_RESUMIDO')
const getRelatorioIPBGeralActionTypes = apiActionTypes('relatorios/API_GET_RELATORIO_IPB_GERAL')

export const API_GET_RELATORIO_IPB_DETALHADO =
  asApiTypesObject(getRelatorioIPBDetalhadoActionTypes)
export const API_GET_RELATORIO_IPB_RESUMIDO =
  asApiTypesObject(getRelatorioIPBResumidoActionTypes)
export const API_GET_RELATORIO_IPB_GERAL =
  asApiTypesObject(getRelatorioIPBGeralActionTypes)

export const apiGetRelatorioIPBDetalhado = ({
  idioma, anoBase, empresaId,
}, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/relatorios/relatorioIPBDetalhado`,
  method: 'GET',
  types: getRelatorioIPBDetalhadoActionTypes,
}, { ...meta, query: { idioma, anoBase, empresaId } })

export const apiGetRelatorioIPBResumido = ({
  idioma, anoBase, empresaId,
}, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/relatorios/relatorioIPBResumido`,
  method: 'GET',
  types: getRelatorioIPBResumidoActionTypes,
}, { ...meta, query: { idioma, anoBase, empresaId } })

export const apiGetRelatorioIPBGeral = ({
  idioma, anoBase, empresaId,
}, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/relatorios/relatorioIPBGeral`,
  method: 'GET',
  types: getRelatorioIPBGeralActionTypes,
}, { ...meta, query: { idioma, anoBase, empresaId } })
