import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  makeStyles,
} from '@material-ui/core'
import { Form, FormSpy } from 'react-final-form'
import { FieldSelect, FieldText, FieldUpload, FieldAutocomplete } from 'components/Fields'
import * as yup from 'yup'
import { useSelector, useDispatch } from 'react-redux'
import { get, find, filter } from 'lodash'
import { chain, bignumber } from 'mathjs'
import { getOcupacaoAreaSelecionado, getIsSavingOcupacaoArea } from 'store/modules/ocupacaoArea/ocupacaoArea.selectors'
import { callAtualizarOcupacaoArea, callCriarOcupacaoArea, callNovoOcupacaoArea } from 'store/modules/ocupacaoArea'
import { getEcorregioes } from 'store/modules/ecorregioes/ecorregioes.selectors'
import { getClassesOcupacao } from 'store/modules/classesOcupacao/classesOcupacao.selectors'
import { getConversoesArea } from 'store/modules/conversoesArea/conversoesArea.selectors'
import { useTranslation } from 'react-i18next'
import { getEmpresaUsuario, getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import ArrowRightCircleLineIcon from 'remixicon-react/ArrowRightCircleLineIcon'
import Button from 'components/Button'
import { validateFormValues } from 'utils/forms'
import { TIPO_USUARIO } from 'utils/constantes'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(12, 1.5, 2),
  },
}))

const listaMarinho = [
  { label: 'pressaoBiodiversidade.marinha', value: true },
  { label: 'pressaoBiodiversidade.terrestre', value: false },
]

const OcupacaoArea = ({ unidadeSelecionada, anoSelecionado, setChangeTab }) => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const [exibirFormulario, setExibirFormulario] = useState(false)

  const { pais } = useSelector(getEmpresaUsuario)
  const ocupacaoAreaSelecionado = useSelector(getOcupacaoAreaSelecionado)
  const listaEcorregioes = useSelector(getEcorregioes)
  const listaClassesOcupacao = useSelector(getClassesOcupacao)
  const listaConversoesArea = useSelector(getConversoesArea)
  const isSaving = useSelector(getIsSavingOcupacaoArea)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const calcularQuantidade = ({ conversaoAreaId, quantidade }) => {
    let quantidadeCalculada = null
    if (conversaoAreaId && quantidade) {
      const conversaoArea = find(listaConversoesArea, { id: conversaoAreaId })

      quantidadeCalculada = chain(bignumber(quantidade))
        .multiply(bignumber(conversaoArea.fator))
        .number()
        .format({ notation: 'fixed', precision: 5 })
        .done()
    }

    if (Number(quantidadeCalculada) === ocupacaoAreaSelecionado?.quantidadeCalculada) {
      return ocupacaoAreaSelecionado.quantidadeCalculada
    }

    return quantidadeCalculada
  }

  const handleValueChange = (form, { conversaoAreaId, quantidade }) => {
    form.change('quantidadeCalculada', calcularQuantidade({ conversaoAreaId, quantidade }))
  }

  const alterarStateFormulario = () => {
    setExibirFormulario(!exibirFormulario)
    dispatch(callNovoOcupacaoArea())
  }

  const handleSave = (values, form) => {
    const data = {
      ...values,
      unidadeId: get(unidadeSelecionada, 'id'),
      marinho: get(values, 'marinho'),
      ano: anoSelecionado,
      empresaId: get(unidadeSelecionada, 'empresaId'),
    }

    if (!ocupacaoAreaSelecionado && callCriarOcupacaoArea) {
      dispatch(callCriarOcupacaoArea(data))
    } else if (callAtualizarOcupacaoArea) {
      dispatch(callAtualizarOcupacaoArea({ id: ocupacaoAreaSelecionado.id, ocupacaoArea: data }))
    }
    form.reset()
    alterarStateFormulario()
  }

  useEffect(() => {
    if (ocupacaoAreaSelecionado?.id) {
      setExibirFormulario(true)
    }
  }, [ocupacaoAreaSelecionado])

  useEffect(() => {
    setExibirFormulario(false)
  }, [unidadeSelecionada])

  const handleClickMapa = (link) => {
    window.open(link, '_blank')
  }

  const ecoOrder = listaEcorregioes.sort((a, b) => a.nome.localeCompare(b.nome, i18n.language))
  const ocupacaoOrder = listaClassesOcupacao
    .sort((a, b) => a.subCategoria.localeCompare(b.subCategoria, i18n.language))

  return exibirFormulario ? (
    <Form
      onSubmit={handleSave}
      initialValues={{
        descricao: ocupacaoAreaSelecionado?.descricao ?? '',
        marinho: ocupacaoAreaSelecionado?.marinho ?? null,
        ecorregiaoId: ocupacaoAreaSelecionado?.ecorregiaoId ?? null,
        classesOcupacaoId: ocupacaoAreaSelecionado?.classesOcupacaoId ?? null,
        quantidade: ocupacaoAreaSelecionado?.quantidade ?? null,
        conversaoAreaId: ocupacaoAreaSelecionado?.conversaoAreaId ?? null,
        evidenciaOcorrencia: ocupacaoAreaSelecionado?.evidenciaOcorrencia ?? '',
        quantidadeCalculada: ocupacaoAreaSelecionado?.quantidadeCalculada ?? null,
        comentario: ocupacaoAreaSelecionado?.comentario ?? '',
        anexo: ocupacaoAreaSelecionado?.anexo ?? null,
      }}
      validate={validateFormValues(yup.object({
        descricao: yup.string().required(),
        marinho: yup.boolean().required(),
        ecorregiaoId: yup.number().required(),
        classesOcupacaoId: yup.number().when(
          'marinho',
          { is: (val) => !val,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.nullable() },
        ),
        quantidade: yup.number().positive().required(),
        conversaoAreaId: yup.number().required(),
        comentario: yup.string(),
        evidenciaOcorrencia: yup.string(),
      }))}
      render={({ handleSubmit, submitting, values, form, pristine, valid }) => (
        <form onSubmit={handleSubmit}>
          <FormSpy
            onChange={({ values: valuesSpy, initialValues }) => {
              setChangeTab({
                values: valuesSpy,
                initialValues,
              })
            }}
            subscription={{ values: true, initialValues: true, pristine: true }}
          />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FieldText
                required
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                name="descricao"
                label={t('inputs.descricao')}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldSelect
                required
                id="marinho"
                name="marinho"
                label={t('inputs.area')}
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                onChange={() => {
                  form.change('ecorregiaoId', null)
                  form.change('classesOcupacaoId', null)
                }}
                options={(listaMarinho || []).map((option) => ({
                  label: t(option.label),
                  value: option.value,
                }))}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldAutocomplete
                required
                name="ecorregiaoId"
                label={t('inputs.ecorregiao')}
                disabled={!unidadeSelecionada || values.marinho == null ||
                  tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                options={filter(ecoOrder, (ecorregiao) => {
                  if (values.marinho) {
                    return ecorregiao.marinho === !!values.marinho
                  }
                  return ecorregiao.terrestre === !values.marinho
                }).map((option) => ({
                  value: option.id,
                  label: option.nome,
                }))}
              />
            </Grid>
            {((values.marinho && pais.linkEcorregioesMarinhas) ||
              (!values.marinho && pais.linkEcorregioesTerrestres)) && (
              <Grid item xs={12}>
                <Button
                  link
                  startIcon={ArrowRightCircleLineIcon}
                // disabled={!values.ecorregiaoId || !find(ecoOrder, {
                //   id: values.ecorregiaoId,
                // }).link}
                // onClick={() => handleClickMapa(find(ecoOrder, {
                //   id: values.ecorregiaoId,
                // }).link)}
                  onClick={() => handleClickMapa(values.marinho ?
                    pais.linkEcorregioesMarinhas :
                    pais.linkEcorregioesTerrestres)}
                >
                  {values.marinho ? t('pressaoBiodiversidade.verMapaRegioesMarinhas') : t('desempenhoBiodiversidade.verMapaEcorregioes')}
                </Button>
              </Grid>
            )}
            <Grid item xs={12}>
              <FieldAutocomplete
                required
                name="classesOcupacaoId"
                disabled={!unidadeSelecionada || values.marinho || values.marinho === '' || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                label={t('inputs.classeOcupacao')}
                options={ocupacaoOrder.map((option) => ({
                  value: option.id,
                  label: option.subCategoria,
                }))}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldText
                required
                type="number"
                name="quantidade"
                label={t('inputs.quantidade')}
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                onChange={handleValueChange(form, values)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldSelect
                required
                id="conversaoAreaId"
                name="conversaoAreaId"
                label={t('inputs.unidade')}
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                onChange={handleValueChange(form, values)}
                options={(listaConversoesArea || []).map((option) => ({
                  label: t(option.unidade),
                  value: option.id,
                }))}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldText
                disabled
                type="number"
                name="quantidadeCalculada"
                label={t('inputs.quantidadeHaAno')}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldText
                multiline
                minRows={5}
                maxRows={5}
                name="comentario"
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                label={t('inputs.comentario')}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldText
                multiline
                minRows={5}
                maxRows={5}
                name="evidenciaOcorrencia"
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                label={t('inputs.evidenciaOcorrencia')}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldUpload name="anexo" disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR} />
            </Grid>

            <Grid container direction="row" justifyContent="space-between" className={classes.submit}>
              <Button variant="danger" onClick={alterarStateFormulario}>
                {t('inputs.cancelar')}
              </Button>
              <Button
                type="submit"
                disabled={submitting || !unidadeSelecionada || pristine || !valid}
                loading={isSaving}
              >
                {t('inputs.salvar')}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  ) : (
    <>
      {tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LEITOR && (
        <Button onClick={alterarStateFormulario}>
          {t('pressaoBiodiversidade.incluirOcorrencia')}
        </Button>
      )}
    </>
  )
}

OcupacaoArea.propTypes = {
  unidadeSelecionada: PropTypes.object,
  anoSelecionado: PropTypes.string,
  setChangeTab: PropTypes.func,
}

export default OcupacaoArea
