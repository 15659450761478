const ACTION_PATH = 'app/especieSilvicultura'

export const CALL_CRIAR_ESPECIE_SILVICULTURA = `${ACTION_PATH}/CALL_CRIAR_ESPECIE_SILVICULTURA`
export const CALL_EXCLUIR_ESPECIE_SILVICULTURA = `${ACTION_PATH}/CALL_EXCLUIR_ESPECIE_SILVICULTURA`
export const CALL_ATUALIZAR_ESPECIE_SILVICULTURA = `${ACTION_PATH}/CALL_ATUALIZAR_ESPECIE_SILVICULTURA`

export const callCriarEspecieSilvicultura = (payload) => ({
  type: CALL_CRIAR_ESPECIE_SILVICULTURA,
  payload,
})

export const callAtualizarEspecieSilvicultura = (payload) => ({
  type: CALL_ATUALIZAR_ESPECIE_SILVICULTURA,
  payload,
})

export const callExcluirEspecieSilvicultura = ({ id }) => ({
  type: CALL_EXCLUIR_ESPECIE_SILVICULTURA,
  payload: { id },
})
