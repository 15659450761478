import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import * as yup from 'yup'
import { find } from 'lodash'
import { useTranslation } from 'react-i18next'
import { makeStyles, Typography } from '@material-ui/core'
import Button from 'components/Button'
import MainColumn from 'components/MainColumn'
import { FieldSelect, FieldText } from 'components/Fields'
import { validateFormValues } from 'utils/forms'
import Filters from 'components/FilterList/Filters'
import Pagination from 'components/Pagination'
import clsx from 'clsx'
import { useSelector, useDispatch } from 'react-redux'
import {
  getDadosAuditoriaSelecionada,
  getParecerPressaoBiodiverdidadeSelecionado,
  getListaParecerPressaoBiodiverdidade,
  getListaUnidadesEmpresa,
  getIsSavingParecerPressaoBiodiverdidade,
} from 'store/modules/auditor/auditor.selectors'
import { callSalvarParecerPressao } from 'store/modules/auditor'
import {
  comboPressaoComentarSobre,
  comboPressaoTipoComentario,
  comboPressaoIpAspecto,
  comboPressaoOcorrenciaFaturamento,
  comboPressaoOcorrenciaResiduo,
  comboPressaoOcorrenciaGee,
  comboPressaoOcorrenciaAgua,
  comboPressaoOcorrenciaEnergia,
  comboPressaoOcorrenciaArea,
} from 'utils/constantes'

const useStyles = makeStyles((theme) => ({
  withoutResults: {
    fontSize: '32px',
    fontWeight: '400',
    color: '#C9CEDB',
    padding: '30px 0',
    textAlign: 'center',
  },
  contentBox: {
    minHeight: '50vh',
  },
  maxInput: {
    maxWidth: '420px',
  },
  footerSection: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  search: {
    marginBottom: '32px',
  },
  listComments: {
    listStyle: 'none',
    padding: '32px 10px',
    borderTop: `1px solid ${theme.palette.grey.main}`,
    display: 'flex',
    flexDirection: 'column',
    rowGap: '12px',
  },
  lineComment: {
    display: 'flex',
    columnGap: '16px',
  },
  textComment: {
    fontSize: '14px',
    color: '#0F3444',
    whiteSpace: 'pre-line',
    margin: 0,
  },
  firstColumn: {
    minWidth: '180px',
  },
  pending: {
    displa: 'block',
    borderRadius: '100px',
    padding: '3px 12px',
    color: '#FFFFFF',
    backgroundColor: '#F85453',
  },
  concluded: {
    displa: 'block',
    borderRadius: '100px',
    padding: '3px 12px',
    color: '#FFFFFF',
    backgroundColor: '#219754',
  },
  pagination: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.grey.main}`,
  },
}))

const TabPressaoBiodiversidade = forwardRef(({
  controlAdded,
  setControlAdded,
}, ref) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const dadosAuditoria = useSelector(getDadosAuditoriaSelecionada)
  const parecerSelecionado = useSelector(getParecerPressaoBiodiverdidadeSelecionado)
  const listaPareceres = useSelector(getListaParecerPressaoBiodiverdidade)
  const unidades = useSelector(getListaUnidadesEmpresa)
  const isSaving = useSelector(getIsSavingParecerPressaoBiodiverdidade)
  const [page, setPage] = useState(0)
  const pageSize = 5
  const filterScreen = [
    { value: true, label: 'filtros.maisAntigos', name: 'time' },
    { value: false, label: 'filtros.maisRecentes', name: 'time' },
  ]

  useImperativeHandle(
    ref,
    () => ({
      handleButtonAdded() {
        setControlAdded(true)
      },
    }),
  )

  const handleSave = (values) => {
    const data = {
      ...values,
      auditoriaEmpresaId: dadosAuditoria.id,
    }

    dispatch(callSalvarParecerPressao(data))

    setTimeout(() => {
      setControlAdded(false)
    }, 800)
  }

  const [data, setData] = useState(listaPareceres)

  useEffect(() => {
    setData(listaPareceres)
  }, [listaPareceres])

  const filteredData = data
    .slice(page * pageSize, page * pageSize + pageSize).map((item) => ({ ...item }))

  const dinamicValidation = yup.object({
    auditor: yup.string().required(),
    tipoComentario: yup.string().required(),
    unidadeId: yup.number().required(),
    tipoOcorrencia: yup.string().required(),
    comentario: yup.string().required(),
    validade: yup.string().required(),
    campo: yup.string().when(
      'tipoOcorrencia',
      {
        is: (val) => val !== 'OUTRO',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      },
    ),
    geracaoResiduoId: yup.number().when(
      'tipoOcorrencia',
      {
        is: (val) => val === 'RESIDUO',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      },
    ),
    emissaoGasesId: yup.number().when(
      'tipoOcorrencia',
      {
        is: (val) => val === 'GEE',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      },
    ),
    consumoAguaId: yup.number().when(
      'tipoOcorrencia',
      {
        is: (val) => val === 'AGUASILVICULTURA',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      },
    ),
    consumoEnergiaId: yup.number().when(
      'tipoOcorrencia',
      {
        is: (val) => val === 'ENERGIA',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      },
    ),
    ocupacaoAreaId: yup.number().when(
      'tipoOcorrencia',
      {
        is: (val) => val === 'AREA',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      },
    ),
  })

  const limparDadosAlteracaoUnidade = (form) => {
    form.change('geracaoResiduoId', null)
    form.change('consumoAguaId', null)
    form.change('consumoEnergiaId', null)
    form.change('ocupacaoAreaId', null)
  }

  const limparDadosAlteracaoTipoOcorrencia = (form) => {
    form.change('geracaoResiduoId', null)
    form.change('emissaoGasesId', null)
    form.change('consumoAguaId', null)
    form.change('consumoEnergiaId', null)
    form.change('ocupacaoAreaId', null)
    form.change('campo', '')
  }

  const getNomeCampo = (parecer) => {
    switch (parecer.tipoOcorrencia) {
      case 'IP_DE_ASPECTO':
        return t(find(comboPressaoIpAspecto, { id: parecer.campo }).label)
      case 'FATURAMENTO':
        return t(find(comboPressaoOcorrenciaFaturamento, { id: parecer.campo }).label)
      case 'RESIDUO':
        return t(find(comboPressaoOcorrenciaResiduo, { id: parecer.campo }).label)
      case 'GEE':
        return t(find(comboPressaoOcorrenciaGee, { id: parecer.campo }).label)
      case 'AGUASILVICULTURA':
        return t(find(comboPressaoOcorrenciaAgua, { id: parecer.campo }).label)
      case 'ENERGIA':
        return t(find(comboPressaoOcorrenciaEnergia, { id: parecer.campo }).label)
      case 'AREA':
        return t(find(comboPressaoOcorrenciaArea, { id: parecer.campo }).label)
      default:
        return ''
    }
  }

  const regraExibirCampoItem = [
    'IP_DE_ASPECTO',
    'FATURAMENTO',
    'RESIDUO',
    'GEE',
    'AGUASILVICULTURA',
    'ENERGIA',
    'AREA',
  ]

  const qualOcorrencia = (tipoOcorrencia) => {
    if (tipoOcorrencia) {
      return (
        <li className={classes.lineComment}>
          <h3 className={clsx(classes.textComment, classes.firstColumn)}>
            {t('auditoriaEmpresa.qualOcorrencia')}
          </h3>
          <p className={classes.textComment}>
            {tipoOcorrencia?.descricao}
          </p>
        </li>
      )
    }
    return null
  }

  return (
    <div className={classes.contentBox}>
      <div className={classes.search}>
        <Filters
          fullWidth
          items={listaPareceres}
          setItems={setData}
          origin={{ vertical: 'top', horizontal: 'right' }}
          filterScreen={filterScreen}
          placeholder={`${data.length} ${t('inputs.comentarios')}`}
          disabled
        />
      </div>

      {controlAdded && (
        <Form
          onSubmit={handleSave}
          validate={validateFormValues(dinamicValidation)}
          initialValues={{
            id: parecerSelecionado?.id ?? undefined,
            auditor: parecerSelecionado?.auditor ?? '',
            tipoComentario: parecerSelecionado?.tipoComentario ?? '',
            unidadeId: parecerSelecionado?.unidadeId ?? null,
            tipoOcorrencia: parecerSelecionado?.tipoOcorrencia ?? '',
            comentario: parecerSelecionado?.comentario ?? '',
            validade: parecerSelecionado?.validade ?? '',
            campo: parecerSelecionado?.campo ?? '',
          }}
          render={({ handleSubmit, values, form, pristine, valid }) => (
            <form onSubmit={handleSubmit}>
              <MainColumn>
                <FieldText
                  required
                  name="auditor"
                  label={t('inputs.auditorResponsavel')}
                  className={classes.maxInput}
                />
                <FieldSelect
                  required
                  id="tipoComentario"
                  name="tipoComentario"
                  label={t('inputs.tipoComentario')}
                  options={comboPressaoTipoComentario.map((option) => ({
                    label: t(option.label),
                    value: option.id,
                  }))}
                  className={classes.maxInput}
                />

                <FieldSelect
                  required
                  id="unidadeId"
                  name="unidadeId"
                  label={t('inputs.divisaoUnidade')}
                  options={(unidades || []).map((option) => ({
                    label: option.nome,
                    value: option.id,
                  }))}
                  className={classes.maxInput}
                  onChange={() => limparDadosAlteracaoUnidade(form)}
                />

                <FieldSelect
                  required
                  id="tipoOcorrencia"
                  name="tipoOcorrencia"
                  label={t('inputs.comentarSobre')}
                  options={comboPressaoComentarSobre.map((option) => ({
                    label: t(option.label),
                    value: option.id,
                  }))}
                  className={classes.maxInput}
                  onChange={() => limparDadosAlteracaoTipoOcorrencia(form)}
                />

                {values.tipoOcorrencia === 'IP_DE_ASPECTO' && (
                  <FieldSelect
                    required
                    id="campo"
                    name="campo"
                    label={t('inputs.aspectoAmbiental')}
                    options={comboPressaoIpAspecto.map((option) => ({
                      label: t(option.label),
                      value: option.id,
                    }))}
                    className={classes.maxInput}
                  />
                )}

                {values.tipoOcorrencia === 'FATURAMENTO' && (
                  <FieldSelect
                    required
                    id="campo"
                    name="campo"
                    label={t('inputs.campoItem')}
                    options={comboPressaoOcorrenciaFaturamento.map((option) => ({
                      label: t(option.label),
                      value: option.id,
                    }))}
                    className={classes.maxInput}
                  />
                )}

                {values.tipoOcorrencia === 'RESIDUO' && (
                  <>
                    <FieldSelect
                      required
                      id="geracaoResiduoId"
                      name="geracaoResiduoId"
                      label={t('inputs.qualOcorrencia')}
                      options={
                        find(unidades, { id: values.unidadeId })?.residuos?.map((option) => ({
                          label: option.descricao,
                          value: option.id,
                        }))
                      }
                      className={classes.maxInput}
                    />

                    <FieldSelect
                      required
                      id="campo"
                      name="campo"
                      label={t('inputs.campoItem')}
                      options={comboPressaoOcorrenciaResiduo.map((option) => ({
                        label: t(option.label),
                        value: option.id,
                      }))}
                      className={classes.maxInput}
                    />
                  </>
                )}

                {values.tipoOcorrencia === 'GEE' && (
                  <>
                    <FieldSelect
                      required
                      id="emissaoGasesId"
                      name="emissaoGasesId"
                      label={t('inputs.qualOcorrencia')}
                      options={
                        find(unidades, { id: values.unidadeId })?.emissaoGases?.map((option) => ({
                          label: option.descricao,
                          value: option.id,
                        }))
                      }
                      className={classes.maxInput}
                    />

                    <FieldSelect
                      required
                      id="campo"
                      name="campo"
                      label={t('inputs.campoItem')}
                      options={comboPressaoOcorrenciaGee.map((option) => ({
                        label: t(option.label),
                        value: option.id,
                      }))}
                      className={classes.maxInput}
                    />
                  </>
                )}

                {values.tipoOcorrencia === 'AGUASILVICULTURA' && (
                  <>
                    <FieldSelect
                      required
                      id="consumoAguaId"
                      name="consumoAguaId"
                      label={t('inputs.qualOcorrencia')}
                      options={
                        find(unidades, { id: values.unidadeId })?.consumosAgua?.map((option) => ({
                          label: option.descricao || option.descricaoSilvicultura,
                          value: option.id,
                        }))
                      }
                      className={classes.maxInput}
                    />

                    <FieldSelect
                      required
                      id="campo"
                      name="campo"
                      label={t('inputs.campoItem')}
                      options={comboPressaoOcorrenciaAgua.map((option) => ({
                        label: t(option.label),
                        value: option.id,
                      }))}
                      className={classes.maxInput}
                    />
                  </>
                )}

                {values.tipoOcorrencia === 'ENERGIA' && (
                  <>
                    <FieldSelect
                      required
                      id="consumoEnergiaId"
                      name="consumoEnergiaId"
                      label={t('inputs.qualOcorrencia')}
                      options={
                        find(unidades, { id: values.unidadeId })?.consumosEnergia?.map((o) => ({
                          label: o.descricao,
                          value: o.id,
                        }))
                      }
                      className={classes.maxInput}
                    />

                    <FieldSelect
                      required
                      id="campo"
                      name="campo"
                      label={t('inputs.campoItem')}
                      options={comboPressaoOcorrenciaEnergia.map((option) => ({
                        label: t(option.label),
                        value: option.id,
                      }))}
                      className={classes.maxInput}
                    />
                  </>
                )}

                {values.tipoOcorrencia === 'AREA' && (
                  <>
                    <FieldSelect
                      required
                      id="ocupacaoAreaId"
                      name="ocupacaoAreaId"
                      label={t('inputs.qualOcorrencia')}
                      options={
                        find(unidades, { id: values.unidadeId })?.ocupacoesAreas?.map((option) => ({
                          label: option.descricao,
                          value: option.id,
                        }))
                      }
                      className={classes.maxInput}
                    />

                    <FieldSelect
                      required
                      id="campo"
                      name="campo"
                      label={t('inputs.campoItem')}
                      options={comboPressaoOcorrenciaArea.map((option) => ({
                        label: t(option.label),
                        value: option.id,
                      }))}
                      className={classes.maxInput}
                    />
                  </>
                )}

                <FieldText
                  required
                  name="validade"
                  label={t('inputs.validadeEmDias')}
                  type="number"
                  className={classes.maxInput}
                />

                <FieldText
                  required
                  name="comentario"
                  label={t('inputs.comentarioAuditoria')}
                  multiline
                  minRows={5}
                />
              </MainColumn>
              <div className={classes.footerSection}>
                <Button
                  variant="danger"
                  onClick={() => setControlAdded(false)}
                >
                  {t('inputs.cancelar')}
                </Button>

                <Button
                  disabled={pristine || !valid}
                  loading={isSaving}
                  type="submit"
                >
                  {t('inputs.salvar')}
                </Button>
              </div>
            </form>
          )}
        />
      )}

      {data.length < 1 ? (
        <Typography component="h1" className={classes.withoutResults}>
          {t('auditoriaEmpresa.voceAindaNaoFezComentarios')}
        </Typography>
      ) : (
        <>
          {filteredData.map((parecer, index) => (
            <ul className={classes.listComments} key={index}>
              <li className={classes.lineComment}>
                <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.auditorResponsavel')}</h3>
                <p className={classes.textComment}>{parecer.auditor}</p>
              </li>
              <li className={classes.lineComment}>
                <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.tipoComentario')}</h3>
                <p className={classes.textComment}>
                  {t(find(comboPressaoTipoComentario, { id: parecer.tipoComentario })?.label)}
                </p>
              </li>
              <li className={classes.lineComment}>
                <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.divisaoUnidade')}</h3>
                <p className={classes.textComment}>{parecer.unidade?.nome}</p>
              </li>
              <li className={classes.lineComment}>
                <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.comentarioSobre')}</h3>
                <p className={classes.textComment}>
                  {t(find(comboPressaoComentarSobre, { id: parecer.tipoOcorrencia })?.label)}
                </p>
              </li>
              {qualOcorrencia(parecer.geracaoResiduo)}
              {qualOcorrencia(parecer.emissaoGases)}
              {qualOcorrencia(parecer.consumoAgua)}
              {qualOcorrencia(parecer.consumoEnergia)}
              {qualOcorrencia(parecer.ocupacaoArea)}
              {
                regraExibirCampoItem.includes(parecer.tipoOcorrencia) && (
                <li className={classes.lineComment}>
                  <h3 className={clsx(classes.textComment, classes.firstColumn)}>
                    {t(`auditoriaEmpresa${(parecer.tipoOcorrencia === 'IP_DE_ASPECTO' ? '.aspectoAmbiental' : '.campoItem')}`)}
                  </h3>
                  <p className={classes.textComment}>{getNomeCampo(parecer)}</p>
                </li>
                )
              }
              <li className={classes.lineComment}>
                <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.validadeDias')}</h3>
                <p className={classes.textComment}>{parecer.validade}</p>
              </li>
              <li className={classes.lineComment}>
                <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.comentario')}</h3>
                <p className={classes.textComment}>{parecer.comentario}</p>
              </li>
              <li className={classes.lineComment}>
                <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.status')}</h3>
                <p className={clsx(
                  classes.textComment,
                  parecer.resolvido ? classes.concluded : classes.pending,
                )}
                >
                  {t(`auditoriaEmpresa.${(parecer.resolvido ? 'concluido' : 'pendente')}`)}
                </p>
              </li>
            </ul>
          ))}
          <div className={classes.pagination}>
            {!!filteredData.length && (
              <Pagination
                page={page}
                onChange={setPage}
                pageSize={pageSize}
                count={data.length}
              />
            )}
          </div>
        </>
      )}
    </div>
  )
})

TabPressaoBiodiversidade.propTypes = {
  controlAdded: PropTypes.bool,
  setControlAdded: PropTypes.func,
}

export default TabPressaoBiodiversidade
