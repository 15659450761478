const ACTION_PATH = 'app/tipoAtividade'

export const CALL_CRIAR_TIPO_ATIVIDADE = `${ACTION_PATH}/CALL_CRIAR_TIPO_ATIVIDADE`
export const CALL_EXCLUIR_TIPO_ATIVIDADE = `${ACTION_PATH}/CALL_EXCLUIR_TIPO_ATIVIDADE`
export const CALL_ATUALIZAR_TIPO_ATIVIDADE = `${ACTION_PATH}/CALL_ATUALIZAR_TIPO_ATIVIDADE`

export const callCriartipoAtividade = (payload) => ({
  type: CALL_CRIAR_TIPO_ATIVIDADE,
  payload,
})

export const callAtualizartipoAtividade = (payload) => ({
  type: CALL_ATUALIZAR_TIPO_ATIVIDADE,
  payload,
})

export const callExcluirtipoAtividade = ({ id }) => ({
  type: CALL_EXCLUIR_TIPO_ATIVIDADE,
  payload: { id },
})
