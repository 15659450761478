import { map, filter } from 'lodash'
import { ENQUEUE_SNACKBAR, CLOSE_SNACKBAR, REMOVE_SNACKBAR } from './notifications.actions'

const defaultState = {
  notifications: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.key,
            ...action.notification,
          },
        ],
      }

    case CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: map(state.notifications, (notification) => (
          (action.dismissAll || notification.key === action.key) ?
            { ...notification, dismissed: true } :
            { ...notification }
        )),
      }

    case REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: filter(state.notifications, (n) => n.key !== action.key),
      }

    default:
      return state
  }
}
