import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchTemasActionTypes = apiActionTypes('temas/API_LISTAR_TEMAS')
const updateTemaActionTypes = apiActionTypes('temas/API_ATUALIZAR_TEMAS')
const createTemaActionTypes = apiActionTypes('temas/API_CRIAR_TEMAS')
const deleteTemaActionTypes = apiActionTypes('temas/API_EXCLUIR_TEMAS')

export const API_LISTAR_TEMAS = asApiTypesObject(fetchTemasActionTypes)
export const API_CRIAR_TEMA = asApiTypesObject(createTemaActionTypes)
export const API_EXCLUIR_TEMA = asApiTypesObject(deleteTemaActionTypes)

export const apiListarTemas = ({ paisId }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/temas`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchTemasActionTypes,
}, { ...meta, query: { paisId } })

export const apiAtualizarTema = ({ id, tema }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/temas/${id}`,
  method: 'PUT',
  types: updateTemaActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(tema),
}, { ...meta, successNotification: 'mensagens.atualizado' })

export const apiCriarTema = (tema, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/temas`,
  method: 'POST',
  types: createTemaActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(tema),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiSalvarListaTema = ({ lista }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/temas/salvarLista`,
  method: 'POST',
  types: createTemaActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(lista),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiExcluirTema = ({ id }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/temas/${id}`,
  method: 'DELETE',
  types: deleteTemaActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })
