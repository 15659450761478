import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import {
  apiCriarTipoGas,
  apiExcluirTipoGas,
  apiAtualizarTipoGas,
} from 'store/modules/api/tiposGases.actions'
import {
  CALL_CRIAR_TIPO_GASES,
  CALL_EXCLUIR_TIPO_GASES,
  CALL_ATUALIZAR_TIPO_GASES,
} from 'store/modules/tiposGases/tiposGases.actions'

const onCallCriarTipoGas = (action$) => action$.pipe(
  ofType(CALL_CRIAR_TIPO_GASES),
  debounceTime(250),
  map(({ payload }) => apiCriarTipoGas(payload)),
)

const onCallExcluirTipoGas = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_TIPO_GASES),
  debounceTime(250),
  map(({ payload }) => apiExcluirTipoGas(payload)),
)

const onCallAtualizarTipoGas = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_TIPO_GASES),
  debounceTime(250),

  map(({ payload }) => apiAtualizarTipoGas(payload)),
)

export default combineEpics(
  onCallCriarTipoGas,
  onCallExcluirTipoGas,
  onCallAtualizarTipoGas,
)
