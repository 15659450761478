import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { LOCATION_CHANGE } from 'connected-react-router'
import {
  API_FETCH_USERS,
  API_GET_USER,
  API_CRIAR_USUARIO,
  API_EXCLUIR_USUARIO,
  API_ATUALIZAR_USUARIO,
} from 'store/modules/api/usuarios.actions'
import { CALL_NOVO_USUARIO, CALL_EXCLUIR_USUARIO } from 'store/modules/usuarios/usuarios.actions'
import { matchLocation } from 'utils/location'
import { filter, map } from 'lodash'

const lista = (state = [], { type, payload }) => {
  switch (type) {
    case API_FETCH_USERS.success: return payload
    case API_CRIAR_USUARIO.success: return [...state, payload]
    case API_EXCLUIR_USUARIO.success: return filter(state, (u) => u.id !== payload)
    case API_ATUALIZAR_USUARIO.success:
      return map(state, (u) => (u.id === payload.id ? payload : u))
    default: return state
  }
}

const selecionado = (state = null, { type, payload }) => {
  switch (type) {
    case API_GET_USER.success: return payload
    case CALL_NOVO_USUARIO: return null
    case LOCATION_CHANGE:
      return matchLocation(payload, '/usuarios/:id') ? state : null
    case API_ATUALIZAR_USUARIO.success: return payload
    case CALL_EXCLUIR_USUARIO: return null
    default: return state
  }
}

const isCarregandoLista = loadingReducer(false, API_FETCH_USERS)
const isCarregandoUsuario = loadingReducer(false, API_GET_USER)

export default combineReducers({
  lista,
  isCarregandoLista,
  isCarregandoUsuario,
  selecionado,
})
