import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from '../apiAction'

const getRelatorioAuditoriaActionTypes = apiActionTypes('relatorios/API_GET_RELATORIO_AUDITORIA')
const getRelatorioSumarionActionTypes = apiActionTypes('relatorios/API_GET_RELATORIO_SUMARIO')

export const API_GET_RELATORIO_AUDITORIA =
  asApiTypesObject(getRelatorioAuditoriaActionTypes)
export const API_GET_RELATORIO_SUMARIO =
  asApiTypesObject(getRelatorioSumarionActionTypes)

export const apiGetRelatorioAuditoria = ({
  idioma, anoBase, empresaId,
}, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/relatorios/relatorioAuditoria`,
  method: 'GET',
  types: getRelatorioAuditoriaActionTypes,
}, { ...meta, query: { idioma, anoBase, empresaId } })

export const apiGetRelatorioSumario = ({
  idioma, anoBase, empresaId,
}, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/relatorios/relatorioSumarioPublico`,
  method: 'GET',
  types: getRelatorioSumarionActionTypes,
}, { ...meta, query: { idioma, anoBase, empresaId } })
