import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import {
  API_GET_RELATORIO_INDICADORES_GESTAO_DETALHADO,
  API_GET_RELATORIO_INDICADORES_GESTAO_RESUMIDO,
  API_GET_RELATORIO_INDICADORES_GESTAO_GERAL,
} from 'store/modules/api/relatorios/relatoriosIndicadoresGestao.actions'

const isCarregandoRelatorioIndicadoresGestaoDetalhado =
  loadingReducer(false, API_GET_RELATORIO_INDICADORES_GESTAO_DETALHADO)
const isCarregandoRelatorioIndicadoresGestaoResumido =
  loadingReducer(false, API_GET_RELATORIO_INDICADORES_GESTAO_RESUMIDO)
const isCarregandoRelatorioIndicadoresGestaoGeral =
  loadingReducer(false, API_GET_RELATORIO_INDICADORES_GESTAO_GERAL)

export default combineReducers({
  isCarregandoRelatorioIndicadoresGestaoDetalhado,
  isCarregandoRelatorioIndicadoresGestaoResumido,
  isCarregandoRelatorioIndicadoresGestaoGeral,
})
