import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import * as yup from 'yup'
import Button from 'components/Button'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { validateFormValues } from 'utils/forms'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { FieldText, FieldSelect } from 'components/Fields'
import { getMoedas } from 'store/modules/moedas/moedas.selectors'
import { getIdiomas } from 'store/modules/idiomas/idiomas.selectors'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import { getPaisSelecionado } from 'store/modules/paises/paises.selectors'
import { callAtualizarPais, callCriarPais, callExcluirPais } from 'store/modules/paises'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { TIPO_USUARIO } from 'utils/constantes'

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
  screenTitle: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(5),
    fontSize: theme.typography.pxToRem(24),
  },
  form: {
    marginTop: theme.spacing(4),
  },
  footer: {
    marginTop: theme.spacing(6),
  },
}))

const PaisRegiao = ({ onCancel, onCreate }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const dialog = useConfirmationDialog()

  const moedas = useSelector(getMoedas)
  const idiomas = useSelector(getIdiomas)
  const paisSelecionado = useSelector(getPaisSelecionado)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const isEdit = paisSelecionado?.id != null

  const desabilitarFormulario = tipoUsuarioLogado === TIPO_USUARIO.ADMIN.OBSERVADOR

  const handleSave = (values) => {
    if (isEdit) return dispatch(callAtualizarPais({ id: paisSelecionado.id, payload: values }))
    dispatch(callCriarPais(values))
    return onCreate()
  }

  const handleDelete = (pais) => {
    dialog.showConfirmation({
      title: t('dialogs.confirmarExclusao'),
      message: t('dialogs.excluirPaisRegiao', {
        pais,
      }),
    }).then((result) => result && dispatch(callExcluirPais({ id: paisSelecionado.id })))
  }

  const handleAction = () => (isEdit ? onCancel() : onCreate())

  return (
    <>
      <div className={classes.sectionTitle}>
        <Typography className={classes.screenTitle}>
          {t('inputs.adicionarPaisRegiao')}
        </Typography>
        {tipoUsuarioLogado !== TIPO_USUARIO.ADMIN.OBSERVADOR && isEdit && (
          <Button
            onClick={() => handleDelete(paisSelecionado?.nome)}
            variant="outlined"
          >
            {t('paisesCalculos.excluirPaisRegiao')}
          </Button>
        )}
      </div>
      <Form
        initialValues={{
          nome: paisSelecionado?.nome || '',
          moedaId: paisSelecionado?.moedaId || '',
          idiomaId: paisSelecionado?.idiomaId || null,
          comentarios: paisSelecionado?.comentarios || '',
          linkEcorregioesTerrestres: paisSelecionado?.linkEcorregioesTerrestres || '',
          linkEcorregioesMarinhas: paisSelecionado?.linkEcorregioesMarinhas || '',
          linkRegioesHidrograficas: paisSelecionado?.linkRegioesHidrograficas || '',
        }}
        onSubmit={handleSave}
        validate={validateFormValues(yup.object().shape({
          nome: yup.string().required(),
          idiomaId: yup.number().nullable().required(),
          moedaId: yup.number().nullable().required(),
          comentarios: yup.string(),
          linkEcorregioesTerrestres: yup.string().url(),
          linkEcorregioesMarinhas: yup.string().url(),
          linkRegioesHidrograficas: yup.string().url(),
        }))}
        render={({ handleSubmit, pristine, submitting }) => (
          <form className={classes.form} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid xs={12} item>
                <FieldText
                  required
                  name="nome"
                  label={t('paisesCalculos.nomePaisRegiao')}
                  disabled={desabilitarFormulario}
                />
              </Grid>
              <Grid xs={12} item>
                <FieldSelect
                  required
                  options={(idiomas || []).map((idioma) => ({
                    label: t(idioma.label),
                    value: idioma.id,
                  }))}
                  name="idiomaId"
                  label={t('paisesCalculos.idiomaPadrao')}
                  disabled={desabilitarFormulario}
                />
              </Grid>
              <Grid xs={12} item>
                <FieldSelect
                  required
                  name="moedaId"
                  label={t('paisesCalculos.moedaPadrao')}
                  options={(moedas || []).map((currency) => ({
                    label: t(`${currency.nome}`),
                    value: currency.id,
                  }))}
                  disabled={desabilitarFormulario}
                />
              </Grid>
              <Grid xs={12} item>
                <FieldText
                  name="linkEcorregioesTerrestres"
                  label={t('paisesCalculos.linkEcorregioesTerrestres')}
                  disabled={desabilitarFormulario}
                />
              </Grid>
              <Grid xs={12} item>
                <FieldText
                  name="linkEcorregioesMarinhas"
                  label={t('paisesCalculos.linkEcorregioesMarinhas')}
                  disabled={desabilitarFormulario}
                />
              </Grid>
              <Grid xs={12} item>
                <FieldText
                  name="linkRegioesHidrograficas"
                  label={t('paisesCalculos.linkRegioesHidrograficas')}
                  disabled={desabilitarFormulario}
                />
              </Grid>
              <Grid xs={12} item>
                <FieldText
                  multiline
                  minRows={5}
                  name="comentarios"
                  label={t('paisesCalculos.comentariosAdicionais')}
                  disabled={desabilitarFormulario}
                />
              </Grid>
            </Grid>
            {tipoUsuarioLogado !== TIPO_USUARIO.ADMIN.OBSERVADOR && (
              <Grid
                container
                justifyContent="space-between"
                className={clsx(isEdit ? classes.form : classes.footer)}
              >
                <Grid item>
                  <Button variant="danger" onClick={() => handleAction()}>
                    {t('inputs.cancelar')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button disabled={pristine || submitting} type="submit">
                    {t('inputs.salvar')}
                  </Button>
                </Grid>
              </Grid>
            )}
          </form>
        )}
      />
    </>
  )
}

PaisRegiao.propTypes = {
  onCancel: PropTypes.func,
  onCreate: PropTypes.func,
}

export default PaisRegiao
