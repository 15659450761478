import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchDadosDashboardActionTypes = apiActionTypes('dashboard/API_LISTAR_DADOS_DASHBOARD')

export const API_LISTAR_DADOS_DASHBOARD = asApiTypesObject(fetchDadosDashboardActionTypes)

export const apiListarDadosDashboard = ({ empresaId, ano, unidadeId }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/dashboard`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchDadosDashboardActionTypes,
}, { ...meta, query: { empresaId, ano, unidadeId } })
