import React from 'react'
import AuditorEmpresaLayout from 'components/AuditorEmpresaLayout'
import * as yup from 'yup'
import { Form, Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { validateFormValues } from 'utils/forms'
import { saveAs } from 'file-saver'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, makeStyles } from '@material-ui/core'
import MainColumn from 'components/MainColumn'
import Button from 'components/Button'
import FieldText from 'components/Fields/Text'
import UploadFile from 'components/UploadFile'
import { TIPO_USUARIO } from 'utils/constantes'
import FileList3LineIcon from 'remixicon-react/FileList3LineIcon'
import DeleteBinLineIcon from 'remixicon-react/DeleteBin7LineIcon'
import DownloadLineIcon from 'remixicon-react/Download2LineIcon'
import FilePdfLineIcon from 'remixicon-react/FilePdfLineIcon'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import {
  getEmpresaAuditoriaSelecionada,
  getDadosAuditoriaSelecionada,
  getAnoSelecionado,
  getIsSavingAuditoriaSelecionada,
} from 'store/modules/auditor/auditor.selectors'
import { callSalvarDadosAuditoria } from 'store/modules/auditor'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    color: theme.palette.primary.main,
  },
  infoSection: {
    marginTop: theme.spacing(8),
  },
  footerSection: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(8),
  },
  viewEditForm: {
    boxSizing: 'border-box',
    maxWidth: theme.spacing(100),
    padding: theme.spacing(4, 8),
    margin: theme.spacing(0, 'auto'),
  },
  viewDatasForm: {
    border: '2px solid #C5E8F7',
    marginTop: '48px',
    borderRadius: '8px',
    background: 'linear-gradient(180deg, #C5E8F7 0%, #FFFFFF 59.9%)',
    padding: '16px',
  },
  wrapper: {
    display: 'flex',
    columnGap: '24px',
  },
  icon: {
    width: 48,
    height: 48,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.common.white,
  },
  titleInfos: {
    display: 'flex',
    alignItems: 'center',
    color: '#31ADE3',
    fontSize: theme.typography.pxToRem(20),
  },
  descriptionInfo: {
    color: '#0F3444',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: '22px',
    margin: '24px 0',
  },
  fileDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  fileName: {
    color: theme.palette.primary.main,
    fontSize: 12,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginLeft: 10,
  },
  iconsDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  downloadIcon: {
    color: theme.palette.primary.main,
    marginRight: 20,
    '&:hover': {
      color: theme.palette.hover.lightBlue,
      cursor: 'pointer',
    },
  },
  deleteIcon: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.hover.lightBlue,
      cursor: 'pointer',
    },
  },
  withoutResults: {
    fontSize: '32px',
    fontWeight: '400',
    color: '#C9CEDB',
    padding: '30px 0',
    textAlign: 'center',
  },
}))

const PlanoAuditoria = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dialog = useConfirmationDialog()
  const dispatch = useDispatch()

  const empresaSelecionada = useSelector(getEmpresaAuditoriaSelecionada)
  const isSaving = useSelector(getIsSavingAuditoriaSelecionada)
  const dadosAuditoria = useSelector(getDadosAuditoriaSelecionada)
  const anoSelecionado = useSelector(getAnoSelecionado)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const handleSave = (values) => {
    const data = {
      ...values,
      empresaId: empresaSelecionada.id,
      ano: anoSelecionado,
    }

    dispatch(callSalvarDadosAuditoria(data))
  }

  const handleDelete = (nome) => (
    dialog.showConfirmation({
      title: t('dialogs.confirmarExclusaoPlanoAuditoria'),
      message: t('dialogs.excluirPlanoAuditoria', { planoAuditoria: nome }),
    }).then((result) => {
      if (result) {
        const data = {
          empresaId: empresaSelecionada.id,
          ano: anoSelecionado,
          nomePlano: null,
          descricaoPlano: null,
          anexoPlano: null,
          nomeAnexo: null,
          planoSalvo: false,
        }
        dispatch(callSalvarDadosAuditoria(data))
      }
    })
  )

  return (
    <AuditorEmpresaLayout
      renderChildren={(
        <Form
          onSubmit={handleSave}
          validate={validateFormValues(yup.object({
            nomePlano: yup.string().required(),
            descricaoPlano: yup.string().required(),
            anexoPlano: yup.object().required(),
          }))}
          initialValues={{
            nomePlano: dadosAuditoria?.nomePlano ?? '',
            descricaoPlano: dadosAuditoria?.descricaoPlano ?? '',
            planoSalvo: dadosAuditoria?.planoSalvo ?? false,
            anexoPlano: dadosAuditoria?.anexoPlano ?? '',
            nomeAnexo: dadosAuditoria?.nomeAnexo ?? '',
          }}
          render={({ handleSubmit, values, form, pristine, valid }) => (
            <form onSubmit={handleSubmit}>
              {!values.planoSalvo && tipoUsuarioLogado === TIPO_USUARIO.AUDITOR.MONITOR && (
                <Typography component="h1" className={classes.withoutResults}>
                  {t('secaoAuditoria.oAuditorAindaNaoEnviouPlanoAuditoria')}
                </Typography>
              )}
              {!values.planoSalvo && tipoUsuarioLogado === TIPO_USUARIO.AUDITOR.AUDITOR && (
                <div className={classes.viewEditForm}>
                  <MainColumn className={classes.infoSection}>
                    <FieldText
                      required
                      name="nomePlano"
                      label={t('inputs.nomePlanoAuditoria')}
                    />
                    <FieldText
                      required
                      name="descricaoPlano"
                      label={t('inputs.descricaoPlanoAuditoria')}
                      multiline
                      minRows={5}
                    />
                    <Field
                      name="anexoPlano"
                      render={({ input }) => (
                        <UploadFile
                          title={`${t('inputs.anexarPlanoAuditoria')} *`}
                          subtitle={t('inputs.arquivosFormatosPDFTamanhoTotal10mb')}
                          {...input}
                        />
                      )}
                    />
                  </MainColumn>

                  <div className={classes.footerSection}>
                    <Button
                      disabled={pristine || isSaving}
                      variant="danger"
                      onClick={() => {
                        form.reset()
                        Object.keys(values).forEach((key) => {
                          form.resetFieldState(key)
                        })
                      }}
                    >
                      {t('inputs.cancelar')}
                    </Button>

                    <Button
                      disabled={pristine || !valid}
                      loading={isSaving}
                      type="submit"
                    >
                      {t('inputs.salvar')}
                    </Button>
                  </div>
                </div>
              )}

              {values.planoSalvo && (
                <div className={classes.viewDatasForm}>
                  <div className={classes.wrapper}>
                    <div className={classes.icon}>
                      <FileList3LineIcon color="#31ADE3" />
                    </div>
                    <Typography className={classes.titleInfos}>
                      {values.nomePlano}
                    </Typography>
                  </div>
                  <Typography className={classes.descriptionInfo}>
                    {values.descricaoPlano}
                  </Typography>
                  <div className={classes.fileDiv}>
                    <div className={classes.fileDiv}>
                      <FilePdfLineIcon />
                      <p className={classes.fileName}>
                        {values.nomeAnexo}
                      </p>
                    </div>
                    <div className={classes.iconsDiv}>
                      <DownloadLineIcon
                        className={classes.downloadIcon}
                        onClick={() => saveAs(values.anexoPlano, values.nomeAnexo)}
                      />
                      {tipoUsuarioLogado === TIPO_USUARIO.AUDITOR.AUDITOR && (
                        <DeleteBinLineIcon
                          className={classes.deleteIcon}
                          onClick={() => handleDelete(values.nomePlano)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </form>
          )}
        />
      )}
    />
  )
}

export default PlanoAuditoria
