import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import gerarAvatar from 'utils/gerarAvatar'
import { Avatar as MuiAvatar, Typography, makeStyles } from '@material-ui/core'

const sizeProp = (props) => {
  if (props.size === 'small') return 32
  if (props.size === 'large') return 96
  return 48
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    textTransform: 'capitalize',
    color: theme.palette.common.white,
  },
  image: {
    width: sizeProp,
    height: sizeProp,
    order: (props) => (props.invert ? 1 : -1),
    margin: (props) => {
      if (!props.hasMargins) return 0
      return theme.spacing(0, props.invert ? 1 : 2, 0, props.invert ? 2 : 1)
    },
  },
  texts: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: (props) => (props.invert ? 'flex-end' : 'flex-start'),
  },
  description: {
    fontSize: 12,
    fontFamily: theme.typography.secondary,
  },
}))

const Avatar = ({ image, size, title, description, invert, className, children, initials }) => {
  const classes = useStyles({ invert, size, hasMargins: title || description })

  return (
    <div className={clsx(classes.wrapper, className)}>
      <MuiAvatar className={classes.image} src={image}>
        <Typography>{title ? gerarAvatar(title) : gerarAvatar(initials)}</Typography>
      </MuiAvatar>
      {(title || description) && (
        <div className={classes.texts}>
          {title && <Typography>{title}</Typography>}
          {description && <Typography className={classes.description}>{description}</Typography>}
        </div>
      )}
      {children && children}
    </div>
  )
}

Avatar.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  initials: PropTypes.string,
  description: PropTypes.string,
  invert: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.oneOf(['default', 'large', 'small']),
}

export default Avatar
