// https://github.com/jquense/yup/blob/master/src/locale.ts
// https://v4.mui.com/pt/guides/localization/#localization
import * as yup from 'yup'

export const buildYupLocale = (_, t) => {
  yup.setLocale({
    mixed: {
      default: t('validacaoInputs.mixed.default'),
      required: t('validacaoInputs.mixed.required'),
      oneOf: t('validacaoInputs.mixed.oneOf'),
      notOneOf: t('validacaoInputs.mixed.notOneOf'),
      notType: t('validacaoInputs.mixed.notType'),
    },
    string: {
      length: t('validacaoInputs.string.length'),
      min: t('validacaoInputs.string.min'),
      max: t('validacaoInputs.string.max'),
      email: t('validacaoInputs.string.email'),
      url: t('validacaoInputs.string.url'),
      trim: t('validacaoInputs.string.trim'),
      lowercase: t('validacaoInputs.string.lowercase'),
      uppercase: t('validacaoInputs.string.uppercase'),
    },
    number: {
      min: t('validacaoInputs.number.min'),
      max: t('validacaoInputs.number.max'),
      lessThan: t('validacaoInputs.number.lessThan'),
      moreThan: t('validacaoInputs.number.moreThan'),
      notEqual: t('validacaoInputs.number.notEqual'),
      positive: t('validacaoInputs.number.positive'),
      negative: t('validacaoInputs.number.negative'),
      integer: t('validacaoInputs.number.integer'),
    },
    date: {
      min: t('validacaoInputs.date.min'),
      max: t('validacaoInputs.date.max'),
    },
    array: {
      min: t('validacaoInputs.array.min'),
      max: t('validacaoInputs.array.max'),
    },
  })
}

export default yup
