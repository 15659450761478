import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { filter, map } from 'lodash'
import { API_GET_ACAO } from 'store/modules/api/acoes.actions'
import {
  API_CRIAR_ACAO_CLASSIFICACAO,
  API_ATUALIZAR_ACAO_CLASSIFICACAO,
  API_EXCLUIR_ACAO_CLASSIFICACAO,
  API_GET_ACAO_CLASSIFICACAO,
} from 'store/modules/api/acaoClassificacao.actions'
import { CALL_NOVO_ACAO } from 'store/modules/acoes/acoes.actions'

const lista = (state = [], { type, payload }) => {
  switch (type) {
    case API_CRIAR_ACAO_CLASSIFICACAO.success: return [...state, payload]
    case API_EXCLUIR_ACAO_CLASSIFICACAO.success: return filter(state, (o) => o.id !== payload)
    case API_ATUALIZAR_ACAO_CLASSIFICACAO.success:
      return map(state, (o) => (o.id === payload.id ? payload : o))
    default: return state
  }
}

const selecionada = (state = null, { type, payload }) => {
  switch (type) {
    case API_GET_ACAO_CLASSIFICACAO.success: return payload
    case API_CRIAR_ACAO_CLASSIFICACAO.success: return payload
    case API_ATUALIZAR_ACAO_CLASSIFICACAO.success: return payload
    case API_EXCLUIR_ACAO_CLASSIFICACAO.success: return null
    case API_GET_ACAO.success: return payload.acaoClassificacao ?? null
    case CALL_NOVO_ACAO: return null
    default: return state
  }
}

const isCarregandoAcoes = loadingReducer(false, API_GET_ACAO_CLASSIFICACAO)

export default combineReducers({
  lista,
  selecionada,
  isCarregandoAcoes,
})
