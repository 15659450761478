import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, mergeMap, map } from 'rxjs/operators'
import { ofLocationChangeTo } from 'utils/location'
import { apiListarDadosDesempenhoBiodiversidade } from 'store/modules/api/desempenhoBiodiversidade.actions'
import { apiListarQualificadores } from 'store/modules/api/qualificadores.actions'
import { apiListarGrupoTema } from 'store/modules/api/grupoTema.actions'
import { CALL_LISTAR_DADOS_DESEMPENHO_BIODIVERSIDADE } from 'store/modules/desempenhoBiodiversidade/desempenhoBiodiversidade.actions'
import { apiListarQualificadorAvaliacao } from 'store/modules/api/qualificadorAvaliacao.actions'
import { getPaisEmpresa } from 'store/modules/auth/auth.selectors'

const onLocationChangeDesempenhoBiodiversidade = (action$, state$) => action$.pipe(
  ofLocationChangeTo('/desempenhoBiodiversidade'),
  debounceTime(250),
  mergeMap(() => {
    const data = { paisId: getPaisEmpresa(state$.value)?.id }

    return [
      apiListarQualificadores(data),
      apiListarGrupoTema(data),
      apiListarQualificadorAvaliacao(data),
    ]
  }),
)

const onCallListarDadosDesempenhoBiodiversidade = (action$) => action$.pipe(
  ofType(CALL_LISTAR_DADOS_DESEMPENHO_BIODIVERSIDADE),
  debounceTime(250),
  map(({ payload }) => apiListarDadosDesempenhoBiodiversidade(payload)),
)

export default combineEpics(
  onLocationChangeDesempenhoBiodiversidade,
  onCallListarDadosDesempenhoBiodiversidade,
)
