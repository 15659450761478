import { Grid, makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import Accordion from 'components/Accordion'
import Button from 'components/Button'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import { FieldText } from 'components/Fields'
import Filters from 'components/FilterList/Filters'
import PropTypes from 'prop-types'
import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { callAtualizarTipoAgua, callCriarTipoAgua, callExcluirTipoAgua } from 'store/modules/tipoAgua'
import { getIsSavingTipoAgua, getIsUpdatingTipoAgua, getTipoAgua } from 'store/modules/tipoAgua/tipoAgua.selectors'
import { TIPO_USUARIO } from 'utils/constantes'
import { validateFormValues } from 'utils/forms'
import * as yup from 'yup'

const useStyles = makeStyles((theme) => ({
  rowText: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(5),
    fontSize: theme.typography.pxToRem(14),
  },
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    width: (props) => props.fullWidth && '100%',
  },
  hidden: {
    display: 'none',
  },
  formAdd: {
    transition: '0.20s ease-out',
    maxHeight: 0,
    overflow: 'hidden',
  },
  openFormAdd: {
    marginTop: '-10px',
    paddingTop: 10,
    maxHeight: '1300px',
    transition: '0.25s ease-in',
  },
  normalTop: {
    marginTop: '16px',
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '0 !important',
  },
  itemAlignRight: {
    maxWidth: '190px',
  },
  listItems: {
    marginTop: '32px',
    border: '1px solid #C9CEDB',
    boxShadow: '0px 8px 32px rgba(15, 52, 68, 0.08)',
    borderRadius: '8px',
    padding: '0px 16px',
  },
  itemTitle: {
    fontFamily: 'Poppins',
    color: '#0F3444',
    fontWeight: '600',
    fontSize: '16px',
  },
  divider: {
    height: 1,
    width: 'calc(100% + 32px)',
    marginLeft: '-16px',
    backgroundColor: theme.palette.grey.main,
  },
  dividerField: {
    borderBottom: `1px solid ${theme.palette.grey.main}`,
    margin: '8px 0',
  },
}))

const TabAgua = forwardRef(({ paisId, controlAdded, setControlAdded }, ref) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const isSaving = useSelector(getIsSavingTipoAgua)
  const isUpdating = useSelector(getIsUpdatingTipoAgua)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const desabilitarFormulario = Boolean(tipoUsuarioLogado === TIPO_USUARIO.ADMIN.OBSERVADOR)

  const filterScreen = [
    { value: true, label: 'filtros.nomeAZ', name: 'order' },
    { value: false, label: 'filtros.nomeZA', name: 'order' },
  ]
  const dialog = useConfirmationDialog()
  const listaTiposAgua = useSelector(getTipoAgua)
  const [data, setData] = useState(listaTiposAgua)

  const validation = yup.object({
    tipo: yup.string().required(),
  })

  const handleUpdate = (values) => {
    const { id } = values
    const payload = ({ ...values, paisId })
    delete payload.id
    dispatch(callAtualizarTipoAgua({ id, tipoAgua: payload }))
  }

  const handleSave = (values, form) => {
    const payload = ({ ...values, paisId })
    dispatch(callCriarTipoAgua(payload))
    form.reset()
    Object.keys(values).forEach((key) => {
      form.resetFieldState(key)
    })
  }

  const handleDelete = (id, tipoAgua) => dialog.showConfirmation({
    title: t('dialogs.confirmarExclusao'),
    message: t('dialogs.excluirTipoAgua', {
      tipoAgua,
    }),
  }).then((result) => {
    if (result) {
      dispatch(callExcluirTipoAgua({ id }))
    }
    return null
  })

  const [openItem, setOpenItem] = useState('')

  const handleExpand = (item) => {
    setOpenItem(openItem === item ? '' : item)
  }

  useImperativeHandle(
    ref,
    () => ({
      handleButtonAdded() {
        setControlAdded(true)
        handleExpand('')
      },
    }),
  )

  const clearFields = (form, values, type) => {
    if (type === 'add') {
      setControlAdded(!controlAdded)
    }

    form.reset()
    Object.keys(values).forEach((key) => {
      form.resetFieldState(key)
    })
  }

  const formFields = [
    {
      name: 'tipo',
      label: t('inputs.TipoAgua'),
      required: true,
    },
  ]

  const FieldsForm = () => (
    formFields.map((elem, index) => (
      <Fragment key={index}>
        {index === 0 ? (
          <Grid item xs={12}>
            <FieldText
              required={elem.required}
              label={elem.label}
              name={elem.name}
              disabled={desabilitarFormulario}
            />
          </Grid>
        ) : (
          <>
            <Grid container spacing={2} className={classes.dividerField}>
              <Grid item xs={9} className={classes.normalTop}>
                <Typography className={classes.rowText}>
                  {elem.label}
                </Typography>
              </Grid>
              <Grid item xs={3} className={classes.alignRight}>
                <FieldText
                  type="number"
                  name={elem.name}
                  className={classes.itemAlignRight}
                  InputProps={{
                    inputProps: {
                      style: { textAlign: 'center' },
                    },
                  }}
                  disabled={desabilitarFormulario}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Fragment>
    ))
  )

  const ItemForm = (position) => {
    const itemTipoAgua = [data[position]]
    return (
      <Form
        onSubmit={handleUpdate}
        validate={validateFormValues(validation)}
        initialValues={{
          tipo: itemTipoAgua[0]?.tipo ?? '',
          id: itemTipoAgua[0]?.id ?? '',
        }}
        render={({ handleSubmit, form, pristine, valid }) => (
          <form onSubmit={handleSubmit}>
            <Accordion
              hideButton
              variant="no-borders"
              classes={{ title: classes.itemTitle }}
              title={itemTipoAgua[0]?.tipo}
              onDelete={() => {
                handleDelete(
                  itemTipoAgua[0]?.id,
                  itemTipoAgua[0]?.tipo,
                )
                handleExpand('')
              }}
              expanded={position === openItem}
              onExpand={() => handleExpand(position)}
              disableDelete={desabilitarFormulario}
            >
              <Grid container>
                { FieldsForm() }
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    className={classes.normalTop}
                  >
                    <Grid item className={classes.deleteButton}>
                      <Button
                        variant="danger"
                        onClick={() => { form.reset(); handleExpand('') }}
                      >
                        {t('inputs.cancelar')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        disabled={Boolean(pristine || !valid) || desabilitarFormulario}
                        loading={isUpdating}
                      >
                        {t('inputs.salvar')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Accordion>
            <div className={classes.divider} />
          </form>
        )}
      />
    )
  }

  useEffect(() => {
    setData(listaTiposAgua)
  }, [listaTiposAgua])

  return (
    <>
      <div className={clsx(classes.search, controlAdded && classes.hidden)}>
        <Filters
          fullWidth
          items={listaTiposAgua}
          setItems={setData}
          origin={{ vertical: 'top', horizontal: 'right' }}
          filterScreen={filterScreen}
          nameSearch="tipo"
        />
      </div>
      <div
        className={clsx(
          classes.formAdd,
          controlAdded && classes.openFormAdd,
        )}
      >
        <Form
          onSubmit={handleSave}
          initialValues={{
            tipo: '',
          }}
          validate={validateFormValues(validation)}
          render={({ handleSubmit, form, values, valid }) => (
            <form onSubmit={handleSubmit}>
              <Grid container>
                { FieldsForm() }
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    className={classes.normalTop}
                  >
                    <Grid item className={classes.deleteButton}>
                      <Button
                        variant="danger"
                        onClick={(e) => {
                          e.preventDefault()
                          clearFields(form, values, 'add')
                        }}
                      >
                        {t('inputs.cancelar')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        disabled={!valid || desabilitarFormulario}
                        loading={isSaving}
                      >
                        {t('inputs.salvar')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </div>
      <div className={classes.listItems}>
        {
          data.length > 0 &&
          data.map((elem, index) => (
            <Fragment key={index}>
              {ItemForm(index)}
            </Fragment>
          ))
        }
      </div>
    </>
  )
})

TabAgua.propTypes = {
  paisId: PropTypes.number,
  controlAdded: PropTypes.bool,
  setControlAdded: PropTypes.func,
}

export default TabAgua
