import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { formatDecimal } from 'utils/numbers'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  box: {
    color: theme.palette.primary.main,
    minHeight: '144px',
    borderRadius: '8px',
    boxShadow: '0px 8px 32px 0px #0F344414',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  titleCard: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    fontFamily: theme.typography.secondary,
    marginTop: theme.spacing(1),
  },
  value: {
    fontSize: 32,
    display: 'flex',
    alignItems: 'flex-end',
    letterSpacing: '-0.02em',
    marginTop: theme.spacing(1),
    minHeight: theme.spacing(5),
  },
  cardText: {
    fontSize: 10,
    marginTop: theme.spacing(3),
    color: theme.palette.primary.main,
    fontFamily: theme.typography.secondary,
  },
  orange: {
    background: 'linear-gradient(180deg, #FADBCA 0%, #FFFFFF 59.9%)',
    '& h2': {
      color: '#EE7D40',
    },
  },
  green: {
    background: 'linear-gradient(180deg, #C1E2CF 0%, #FFFFFF 59.9%)',
    '& h2': {
      color: '#219754',
    },
  },
  blue: {
    background: 'linear-gradient(180deg, #C5E8F7 0%, #FFFFFF 59.9%)',
    '& h2': {
      color: '#31ADE3',
    },
  },
  purple: {
    background: 'linear-gradient(180deg, #DAD4F6 0%, #FFFFFF 59.9%)',
    '& h2': {
      color: '#7B65DE',
    },
  },
}))

const Card = ({ title, content, color, description, decimalMin, decimalMax }) => {
  const classes = useStyles()
  const { i18n } = useTranslation()

  return (
    <div className={clsx(classes.box, classes[color])}>
      <div>
        <Typography variant="h1" className={classes.titleCard}>
          {title}
        </Typography>

        <Typography variant="h2" className={classes.value}>
          {formatDecimal({
            min: decimalMin,
            max: decimalMax,
            locale: i18n.language,
            value: content,
          })}
        </Typography>
      </div>
      <div>
        {description && (
        <Typography className={classes.cardText}>{description}</Typography>
        )}
      </div>
    </div>
  )
}

Card.propTypes = {
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  description: PropTypes.string,
  decimalMin: PropTypes.number,
  decimalMax: PropTypes.number,
}

export default Card
