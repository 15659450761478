import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import { apiCriarGasEfeitoEstufa, apiAtualizarGasEfeitoEstufa, apiExcluirGasEfeitoEstufa, apiGetGasEfeitoEstufa } from 'store/modules/api/gasesEfeitoEstufa.actions'
import { CALL_ATUALIZAR_GAS_EFEITO_ESTUFA, CALL_CRIAR_GAS_EFEITO_ESTUFA, CALL_EXCLUIR_GAS_EFEITO_ESTUFA, CALL_SELECIONAR_GAS_EFEITO_ESTUFA } from 'store/modules/gasesEfeitoEstufa/gasesEfeitoEstufa.actions'

const onSelected = (action$) => action$.pipe(
  ofType(CALL_SELECIONAR_GAS_EFEITO_ESTUFA),
  debounceTime(250),
  map(({ payload }) => apiGetGasEfeitoEstufa(payload.id)),
)

const onCallCriarGasesEfeitoEstufa = (action$) => action$.pipe(
  ofType(CALL_CRIAR_GAS_EFEITO_ESTUFA),
  debounceTime(250),
  map(({ payload }) => apiCriarGasEfeitoEstufa(payload)),
)

const onCallAtualizarGasesEfeitoEstufa = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_GAS_EFEITO_ESTUFA),
  debounceTime(250),
  map(({ payload }) => apiAtualizarGasEfeitoEstufa(payload)),
)

const onCallExcluirGasEfeitoEstufa = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_GAS_EFEITO_ESTUFA),
  debounceTime(250),
  map(({ payload }) => apiExcluirGasEfeitoEstufa(payload)),
)

export default combineEpics(
  onCallCriarGasesEfeitoEstufa,
  onCallAtualizarGasesEfeitoEstufa,
  onSelected,
  onCallExcluirGasEfeitoEstufa,
)
