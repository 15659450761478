import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, mergeMap, map } from 'rxjs/operators'
import { apiListarUnidades } from 'store/modules/api/unidades.actions'
import { apiListarDadosDashboard } from 'store/modules/api/dashboard.actions'
import { ofLocationChangeTo } from 'utils/location'
import { getEmpresaUsuario } from 'store/modules/auth/auth.selectors'
import { CALL_LISTAR_DADOS_DASHBOARD } from 'store/modules/dashboard/dashboard.actions'

const onLocationChangeDashboard = (action$, state$) => action$.pipe(
  ofLocationChangeTo('/dashboard'),
  debounceTime(250),
  mergeMap(() => [
    apiListarUnidades(getEmpresaUsuario(state$.value)?.id),
    // apiListarDivisoesSetores(getEmpresaUsuario(state$.value)?.id),
  ]),
)

const onCallListarDadosDashboard = (action$) => action$.pipe(
  ofType(CALL_LISTAR_DADOS_DASHBOARD),
  debounceTime(250),
  map(({ payload }) => apiListarDadosDashboard(payload)),
)

export default combineEpics(
  onLocationChangeDashboard,
  onCallListarDadosDashboard,
)
