import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import { diff } from 'deep-object-diff'
import { isFormChanged } from 'utils/forms'
import Tabs from 'components/Tabs'
import RouterPrompt from 'components/AppRouter/RouterPrompt'
import PropTypes from 'prop-types'
import DadosAcao from './DadosAcao'
import ClassificacaoPontuacao from './ClassificacaoPontuacao'

const useStyles = makeStyles((theme) => ({
  tabList: {
    marginBottom: theme.spacing(4),
  },
}))

const Acoes = ({ setAllowChange }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dialog = useConfirmationDialog()
  const [allowTabChange, setAllowTabChange] = useState(true)
  const [activeTab, setActiveTab] = useState('0')
  const tabs = [
    { titulo: t('desempenhoBiodiversidade.dadosAcao'), component: DadosAcao },
    { titulo: t('desempenhoBiodiversidade.classificacaoPontuacao'), component: ClassificacaoPontuacao },
  ]

  const handleTabChange = (value) => {
    if (!allowTabChange) {
      return dialog.showConfirmation({
        title: t('dialogs.salvarDadosTitulo'),
        message: t('dialogs.salvarDadosDescricao'),
      }).then((result) => result && setActiveTab(value))
    }
    return setActiveTab(value)
  }

  const handleAllowTabChange = ({ initialValues, values }) => {
    const diffValues = diff(initialValues, values)
    const changed = isFormChanged({ dirtyValues: diffValues, formValues: values, initialValues })
    setAllowTabChange(!changed)
    setAllowChange(!changed)
  }

  return (
    <>
      <RouterPrompt
        when={!allowTabChange}
        title={t('dialogs.salvarDadosTitulo')}
        message={t('dialogs.salvarDadosDescricao')}
      />
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        className={classes.tabList}
        renderTabs={({ Tab }) => tabs.map((tab, index) => (
          <Tab label={t(tab.titulo)} value={index.toString()} key={index.toString()} />
        ))}
      >
        {({ TabPanel }) => tabs.map(({ component: Component }, index) => (
          <TabPanel value={index.toString()} key={index.toString()}>
            <Component
              setChangeTab={handleAllowTabChange}
            />
          </TabPanel>
        ))}
      </Tabs>
    </>

  )
}

Acoes.prototype = {
  setAllowChange: PropTypes.func,
}

export default Acoes
