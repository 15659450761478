import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchEcorregioesActionTypes = apiActionTypes('ecorregioes/API_LISTAR_ECORREGIOES')
const createEcorregioesActionTypes = apiActionTypes('ecorregioes/API_CRIAR_ECORREGIOES')
const deleteEcorregioesActionTypes = apiActionTypes('ecorregioes/API_EXCLUIR_ECORREGIOES')
const updateEcorregioesActionTypes = apiActionTypes('ecorregioes/API_ATUALIZAR_ECORREGIOES')

export const API_LISTAR_ECORREGIOES = asApiTypesObject(fetchEcorregioesActionTypes)

export const API_CRIAR_ECORREGIOES = asApiTypesObject(
  createEcorregioesActionTypes,
)
export const API_EXCLUIR_ECORREGIOES = asApiTypesObject(
  deleteEcorregioesActionTypes,
)
export const API_ATUALIZAR_ECORREGIOES = asApiTypesObject(
  updateEcorregioesActionTypes,
)

export const apiListarEcorregioes = ({ paisId }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/ecorregioes`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchEcorregioesActionTypes,
}, { ...meta, query: { paisId } })

export const apiCriarEcorregioes = (ecorregiao, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/ecorregioes`,
  method: 'POST',
  types: createEcorregioesActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(ecorregiao),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiExcluirEcorregioes = ({ id }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/ecorregioes/${id}`,
  method: 'DELETE',
  types: deleteEcorregioesActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })

export const apiAtualizarEcorregioes = ({ id, ecorregiao }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/ecorregioes/${id}`,
  method: 'PUT',
  types: updateEcorregioesActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(ecorregiao),
}, { ...meta, successNotification: 'mensagens.atualizado' })
