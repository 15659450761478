import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchUsuarioAuditoresActionTypes = apiActionTypes('usuariosAuditor/API_FETCH_USUARIO_AUDITOR')
const getUserActionTypes = apiActionTypes('usuariosAuditor/API_GET_USUARIO_AUDITOR')
const createUsuarioActionTypes = apiActionTypes('usuariosAuditor/API_CRIAR_USUARIO')
const updateUsuarioActionTypes = apiActionTypes('usuariosAuditor/API_ATUALIZAR_USUARIO')
const deleteUsuarioActionTypes = apiActionTypes('usuariosAuditor/API_EXCLUIR_USUARIO')

export const API_FETCH_USUARIO_AUDITOR = asApiTypesObject(fetchUsuarioAuditoresActionTypes)
export const API_GET_USUARIO_AUDITOR = asApiTypesObject(getUserActionTypes)
export const API_CRIAR_USUARIO = asApiTypesObject(createUsuarioActionTypes)
export const API_ATUALIZAR_USUARIO = asApiTypesObject(updateUsuarioActionTypes)
export const API_EXCLUIR_USUARIO = asApiTypesObject(deleteUsuarioActionTypes)

export const apiListarUsuariosAuditores = (empresaId, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/usuariosAuditor`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchUsuarioAuditoresActionTypes,
}, { ...meta, ...empresaId && ({ query: { empresaId } }) })

export const apiGetUsuarioAuditor = (id, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/usuariosAuditor/${id}`,
  method: 'GET',
  types: getUserActionTypes,
}, meta)

export const apiCriarUsuarioAuditor = (usuario, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/usuariosAuditor`,
  method: 'POST',
  types: createUsuarioActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(usuario),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiAtualizarUsuarioAuditor = ({ id, usuario }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/usuariosAuditor/${id}`,
  method: 'PUT',
  types: updateUsuarioActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(usuario),
}, { ...meta, successNotification: 'mensagens.atualizado' })

export const apiExcluirUsuarioAuditor = ({ id }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/usuariosAuditor/${id}`,
  method: 'DELETE',
  types: deleteUsuarioActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })
