import React from 'react'
import PropTypes from 'prop-types'

import {
  Tooltip as MuiTooltip,
  makeStyles,
} from '@material-ui/core'

const useStyledTooltip = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.primary.main,
  },
  tooltip: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
    color: theme.palette.white.main,
    fontSize: theme.typography.pxToRem(12),
  },
}))

const Tooltip = (props) => {
  const { placement = 'right' } = props

  const classes = useStyledTooltip()

  return <MuiTooltip arrow placement={placement} classes={classes} {...props} />
}

Tooltip.propTypes = {
  placement: PropTypes.string,
}

export default Tooltip
