import React from 'react'
import * as yup from 'yup'
import PageTitle from 'components/PageTitle'
import AdminLayout from 'components/AdminLayout'
import { Grid } from '@material-ui/core'
import { FieldAutocomplete, FieldText } from 'components/Fields'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import { getLicencas, getLicencaSelecionada } from 'store/modules/licencas/licencas.selectors'
import {
  callAtualizarLicenca,
  callCriarLicenca,
  callNovaLicenca,
  callExcluirLicenca,
  callSelecionarLicenca,
} from 'store/modules/licencas'

const Licenca = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const dialog = useConfirmationDialog()
  const licencas = useSelector(getLicencas)
  const licencaSelecionada = useSelector(getLicencaSelecionada)

  const tableColumns = [
    { title: t('licencasAdm.tabelaNome'), key: 'nome', width: '30%' },
    { title: t('licencasAdm.tabelaUsuarios'), key: 'numeroUsuariosPossiveis', width: '27%' },
    { title: t('licencasAdm.tabelaUnidades'), key: 'numeroUnidadesPossiveis', width: '19%' },
    { title: t('licencasAdm.tabelaStatus'), key: 'status', width: '20%' },
  ]

  const handleSave = (values) => {
    dispatch(licencaSelecionada ?
      callAtualizarLicenca({ licenca: values, id: licencaSelecionada.id }) :
      callCriarLicenca(values))
  }

  const getTableData = (data) => data.map((item) => ({
    ...item,
    status: t(item.status),
  }))

  const handleDelete = ({ id, nome }) => (
    dialog.showConfirmation({
      title: t('dialogs.confirmarExclusao'),
      message: t('dialogs.excluirLicenca', { licenca: nome }),
    }).then((result) => result && dispatch(callExcluirLicenca({ id })))
  )

  const handleEdit = ({ id }) => {
    dispatch(callSelecionarLicenca({ id }))
  }

  const handleCancel = () => {
    dispatch(callNovaLicenca())
  }

  return (
    <>
      <PageTitle title={t('menuItemsAdm.contas')} />
      <AdminLayout
        title={t('menuItemsAdm.licencas')}
        buttonText={t('licencasAdm.adicionarLicenca')}
        tableColumns={tableColumns}
        tableData={getTableData(licencas)}
        onDelete={handleDelete}
        onEdit={handleEdit}
        onCancel={handleCancel}
        renderForm={(Form) => (
          <Form
            onSave={handleSave}
            validate={yup.object({
              nome: yup.string().required(),
              numeroUsuariosPossiveis: yup.string().required(),
              numeroUnidadesPossiveis: yup.number().required().nullable(),
              status: yup.string().required().nullable(),
            })}
            title={t('licencasAdm.informacoesLicenca')}
            initialValues={{
              nome: licencaSelecionada?.nome ?? '',
              numeroUsuariosPossiveis: licencaSelecionada?.numeroUsuariosPossiveis ?? '',
              numeroUnidadesPossiveis: licencaSelecionada?.numeroUnidadesPossiveis ?? null,
              status: licencaSelecionada?.status ?? null,
            }}
          >
            <Grid spacing={4} container>
              <Grid item xs={12}>
                <Grid spacing={2} container>
                  <Grid item xs={6}>
                    <FieldText
                      required
                      name="nome"
                      label={t('inputs.nomeLicenca')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FieldAutocomplete
                      required
                      name="status"
                      label={t('licencasAdm.statusLicenca')}
                      options={[
                        { label: t('statusLicenca.ativo'), value: 'statusLicenca.ativo' },
                        { label: t('statusLicenca.inativo'), value: 'statusLicenca.inativo' },
                      ]}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid spacing={2} container>
                  <Grid item xs={6}>
                    <FieldText
                      required
                      name="numeroUsuariosPossiveis"
                      label={t('inputs.numeroUsuariosPossiveis')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FieldText
                      required
                      name="numeroUnidadesPossiveis"
                      label={t('inputs.numeroUnidadesPossiveis')}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      />
    </>
  )
}

export default Licenca
