import {
  Button,
  withStyles,
} from '@material-ui/core'

const StyledLinkBtn = withStyles((theme) => ({
  root: {
    borderRadius: 4,
    border: 'none',
    color: theme.palette.blue.main,
    fontFamily: 'Poppins',
    fontSize: 14,
    textTransform: 'capitalize',
  },
}))(Button)

export default StyledLinkBtn
