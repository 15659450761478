import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const getResiduoActionTypes = apiActionTypes('residuos/API_GET_RESIDUOS')
const createResiduoActionTypes = apiActionTypes('residuos/API_CRIAR_RESIDUO')
const updateResiduoActionTypes = apiActionTypes('residuos/API_ATUALIZAR_RESIDUO')
const deleteResiduoActionTypes = apiActionTypes('residuos/API_EXCLUIR_RESIDUO')

export const API_GET_RESIDUOS = asApiTypesObject(getResiduoActionTypes)
export const API_CRIAR_RESIDUO = asApiTypesObject(createResiduoActionTypes)
export const API_ATUALIZAR_RESIDUO = asApiTypesObject(updateResiduoActionTypes)
export const API_EXCLUIR_RESIDUO = asApiTypesObject(deleteResiduoActionTypes)

export const apiGetResiduo = (id, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/geracaoResiduo/${id}`,
  method: 'GET',
  types: getResiduoActionTypes,
}, meta)

export const apiCriarResiduo = (residuo, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/geracaoResiduo`,
  method: 'POST',
  types: createResiduoActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(residuo),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiAtualizarResiduo = ({ id, residuo }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/geracaoResiduo/${id}`,
  method: 'PUT',
  types: updateResiduoActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(residuo),
}, { ...meta, successNotification: 'mensagens.atualizado' })

export const apiExcluirResiduo = (id, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/geracaoResiduo/${id}`,
  method: 'DELETE',
  types: deleteResiduoActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })
