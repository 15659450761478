const ACTION_PATH = 'app/grupo'

export const CALL_CRIAR_GRUPO = `${ACTION_PATH}/CALL_CRIAR_GRUPO`
export const CALL_ATUALIZAR_GRUPO = `${ACTION_PATH}/CALL_ATUALIZAR_GRUPO`
export const CALL_SALVAR_LISTA_GRUPO = `${ACTION_PATH}/CALL_SALVAR_LISTA_GRUPO`
export const CALL_EXCLUIR_GRUPO = `${ACTION_PATH}/CALL_EXCLUIR_GRUPO`

export const callCriarGrupo = (grupo) => ({
  type: CALL_CRIAR_GRUPO,
  payload: grupo,
})

export const callAtualizarGrupo = ({ id, grupo }) => ({
  type: CALL_ATUALIZAR_GRUPO,
  payload: { id, grupo },
})

export const callSalvarListaGrupo = (payload) => ({
  type: CALL_SALVAR_LISTA_GRUPO,
  payload,
})

export const callExcluirGrupo = ({ id }) => ({
  type: CALL_EXCLUIR_GRUPO,
  payload: { id },
})
