import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import {
  apiCriarQualificador,
  apiAtualizarQualificador,
  apiExcluirQualificador,
  apiGetQualificador,
  apiSalvarListaQualificador,
} from 'store/modules/api/qualificadores.actions'
import {
  CALL_ATUALIZAR_QUALIFICADOR,
  CALL_CRIAR_QUALIFICADOR,
  CALL_EXCLUIR_QUALIFICADOR,
  CALL_SELECIONAR_QUALIFICADOR,
  CALL_SALVAR_LISTA_QUALIFICADOR,
} from 'store/modules/qualificadores/qualificadores.actions'

const onSelected = (action$) => action$.pipe(
  ofType(CALL_SELECIONAR_QUALIFICADOR),
  debounceTime(250),
  map(({ payload }) => apiGetQualificador(payload.id)),
)

const onCallCriarQualificador = (action$) => action$.pipe(
  ofType(CALL_CRIAR_QUALIFICADOR),
  debounceTime(250),
  map(({ payload }) => apiCriarQualificador(payload)),
)

const onCallAtualizarQualificador = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_QUALIFICADOR),
  debounceTime(250),
  map(({ payload }) => apiAtualizarQualificador(payload)),
)

const onCallExcluirQualificador = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_QUALIFICADOR),
  debounceTime(250),
  map(({ payload }) => apiExcluirQualificador(payload)),
)

const onCallSalvarListaQualificador = (action$) => action$.pipe(
  ofType(CALL_SALVAR_LISTA_QUALIFICADOR),
  debounceTime(250),
  map(({ payload }) => apiSalvarListaQualificador({ lista: payload })),
)

export default combineEpics(
  onCallCriarQualificador,
  onCallAtualizarQualificador,
  onSelected,
  onCallExcluirQualificador,
  onCallSalvarListaQualificador,
)
