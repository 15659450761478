import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchDadosIPBActionTypes = apiActionTypes('pressaoBiodiversidade/API_LISTAR_DADOS_IPB')

export const API_LISTAR_DADOS_IPB = asApiTypesObject(fetchDadosIPBActionTypes)

export const apiListarDadosPressaoBiodiversidade = ({ id, ano }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/pressaoBiodiversidade`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchDadosIPBActionTypes,
}, { ...meta, query: { unidadeId: id, ano } })
