import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import {
  apiListarUnidades,
  apiAtualizarUnidade,
  apiCriarUnidade,
  apiSalvarListaUnidade,
  API_CRIAR_UNIDADE,
} from 'store/modules/api/unidades.actions'
import {
  CALL_ATUALIZAR_UNIDADE,
  CALL_CRIAR_UNIDADE,
  CALL_SALVAR_LISTA_UNIDADE,
} from 'store/modules/unidades/unidades.actions'
import { ofLocationChangeTo } from 'utils/location'
import { getDadosUsuario } from 'store/modules/auth/auth.selectors'

const onLocationChangeToLista = (action$) => action$.pipe(
  ofLocationChangeTo('/unidades'),
  debounceTime(250),
  map(({ payload }) => apiListarUnidades(payload)),
)

const onCallCriarUnidade = (action$) => action$.pipe(
  ofType(CALL_CRIAR_UNIDADE),
  debounceTime(250),
  map(({ payload }) => apiCriarUnidade(payload)),
)

const onCallAtualizarUnidade = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_UNIDADE),
  debounceTime(250),
  map(({ payload }) => apiAtualizarUnidade(payload)),
)

const onCallSalvarListaUnidade = (action$) => action$.pipe(
  ofType(CALL_SALVAR_LISTA_UNIDADE),
  debounceTime(250),
  map(({ payload }) => apiSalvarListaUnidade(payload)),
)

const onCallSalvarListaUnidadeSuccess = (action$, state$) => action$.pipe(
  ofType(API_CRIAR_UNIDADE.success),
  debounceTime(250),
  map(() => apiListarUnidades(getDadosUsuario(state$.value).empresa.id)),
)

export default combineEpics(
  onLocationChangeToLista,
  onCallCriarUnidade,
  onCallAtualizarUnidade,
  onCallSalvarListaUnidade,
  onCallSalvarListaUnidadeSuccess,
)
