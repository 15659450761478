const ACTION_PATH = 'app/relatorios'

export const CALL_RELATORIO_PABS_DETALHADO = `${ACTION_PATH}/CALL_RELATORIO_PABS_DETALHADO`
export const CALL_RELATORIO_PABS_RESUMIDO = `${ACTION_PATH}/CALL_RELATORIO_PABS_RESUMIDO`
export const CALL_RELATORIO_PABS_GERAL = `${ACTION_PATH}/CALL_RELATORIO_PABS_GERAL`

export const callRelatorioPABSDetalhado = (payload) => ({
  type: CALL_RELATORIO_PABS_DETALHADO,
  payload,
})

export const callRelatorioPABSResumido = (payload) => ({
  type: CALL_RELATORIO_PABS_RESUMIDO,
  payload,
})

export const callRelatorioPABSGeral = (payload) => ({
  type: CALL_RELATORIO_PABS_GERAL,
  payload,
})
