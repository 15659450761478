import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import { apiCriarFaturamento, apiAtualizarFaturamento } from 'store/modules/api/faturamento.actions'
import { CALL_ATUALIZAR_FATURAMENTO, CALL_CRIAR_FATURAMENTO } from 'store/modules/faturamento/faturamento.actions'

const onCallCriarFaturamento = (action$) => action$.pipe(
  ofType(CALL_CRIAR_FATURAMENTO),
  debounceTime(250),
  map(({ payload }) => apiCriarFaturamento(payload)),
)

const onCallAtualizarFaturamento = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_FATURAMENTO),
  debounceTime(250),
  map(({ payload }) => apiAtualizarFaturamento(payload)),
)

export default combineEpics(
  onCallCriarFaturamento,
  onCallAtualizarFaturamento,
)
