import {
  Backdrop,
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput as MuiOutlinedInput,
  Select,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core'
import { TabContext } from '@material-ui/lab'
import Card from 'components/Card'
import MainColumn from 'components/MainColumn'
import PageTitle from 'components/PageTitle'
import { compact, findIndex, map, range, split } from 'lodash'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import FileCopyLineIcon from 'remixicon-react/FileCopy2LineIcon'
import FolderChartIcon from 'remixicon-react/FolderChart2LineIcon'
import FolderShieldIcon from 'remixicon-react/FolderShieldLineIcon'
import { getEmpresaUsuario } from 'store/modules/auth/auth.selectors'
import { callListarIndicadorEmpresa } from 'store/modules/indicadorEmpresa/indicadorEmpresa.actions'
import { getIsCarregandoIndicadoresCriterioSelecionado } from 'store/modules/indicadores/indicadores.selectors'
import { getAnoCarregado } from 'store/modules/indicadorEmpresa/indicadorEmpresa.selectors'
import { getPrincipios } from 'store/modules/principios/principios.selectors'
import { DOCUMENTS_URL } from 'utils/constantes'
import { diff } from 'deep-object-diff'
import { isFormChanged } from 'utils/forms'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import RouterPrompt from 'components/AppRouter/RouterPrompt'
import CardInfo from './CardInfo'
import CardStatistics from './CardStatistics'
import IndicadorEmpresa from './IndicadorEmpresa'
import { getIndicatorsInfo, getIndicatorsStats } from './helpers'
import { ListItemStyled, TabItem, TabListItem } from './styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(5),
  },
  indicadorTitle: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  indicadorDescricao: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main,
    fontFamily: theme.typography.secondary,
    lineHeight: '24px',
    marginBottom: theme.spacing(4),
  },
  essencial: {
    color: theme.palette.danger.main,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: '18px',
    background: '#FEEEEE',
    display: 'inline-block',
    borderRadius: '30px',
    padding: theme.spacing(0.25, 1),
    marginBottom: theme.spacing(2),
  },
  verificadores: {
    fontSize: theme.typography.pxToRem(14),
    fontFamily: theme.typography.secondary,
    color: theme.palette.primary.main,
    lineHeight: '24px',
  },
  verificadoresTitle: {
    fontSize: theme.typography.pxToRem(14),
    fontFamily: theme.typography.secondary,
    color: theme.palette.primary.main,
    lineHeight: '14px',
    width: 'fit-content',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    marginBottom: '8px',
  },
  verificadoresList: {
    fontSize: theme.typography.pxToRem(14),
    fontFamily: theme.typography.secondary,
    color: theme.palette.primary.main,
    margin: 0,
    marginBottom: '16px',
    padding: theme.spacing(0, 3),
    '& > li': {
      lineHeight: '24px',
    },
  },
  content: {
    flexGrow: 0,
  },
  infoIcon: {
    minWidth: '40px',
    marginLeft: 'auto',
  },
  active: {
    backgroundColor: 'unset !important',
    '& > .MuiBox-root': {
      backgroundColor: '#F5F5F5',
    },
  },
  boxContainer: {
    boxShadow: '0px 8px 32px rgba(15, 52, 68, 0.08)',
    borderRadius: '8px',
    padding: theme.spacing(4),
    display: 'flex',
    width: '100%',
    backgroundColor: theme.palette.common.white,
  },
  leftTab: {
    borderRight: '1px solid #C9CEDB',
    paddingRight: theme.spacing(4),
    width: '25%',
    minWidth: '25%',
    maxWidth: '400px',
  },
  leftTabItem: {
    borderBottom: '1px solid #C9CEDB',
    padding: theme.spacing(1),
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(14),
    '&:last-child': {
      borderBottom: 0,
    },
    '& > .MuiBox-root': {
      padding: theme.spacing(1),
      margin: theme.spacing(0, -1),
    },
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  leftTabItemBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  leftTabItemText: {
    margin: 0,
  },
  rightTab: {
    paddingLeft: theme.spacing(4),
    width: '75%',
  },
  rightTabContentTitle: {
    fontSize: theme.typography.pxToRem(24),
    marginBottom: theme.spacing(3),
    color: theme.palette.primary.main,
  },
  rightTabContentLabel: {
    fontFamily: theme.typography.secondary,
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  rightTabContentDescription: {
    fontFamily: theme.typography.secondary,
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(2),
    lineHeight: '24px',
    color: theme.palette.primary.main,
  },
  rightTabRodapeDescription: {
    fontFamily: theme.typography.secondary,
    fontSize: theme.typography.pxToRem(10),
    marginBottom: '32px',
    lineHeight: '24px',
    color: '#0F3444',
    fontStyle: 'italic',
    whiteSpace: 'pre-line',
  },
  rightTabRodapeVerificadores: {
    marginTop: '16px',
    marginBottom: '32px',
  },
  rightTabPanelText: {
    fontFamily: theme.typography.secondary,
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(4),
    lineHeight: '24px',
    color: theme.palette.primary.main,
    display: 'block',
  },
  container: {
    padding: theme.spacing(3),
    boxShadow: '0px 8px 32px rgba(15, 52, 68, 0.08)',
    borderRadius: '8px',
    backgroundColor: theme.palette.common.white,
  },
  message: {
    fontSize: theme.typography.pxToRem(14),
    fontStyle: 'italic',
  },
  formControl: {
    height: 60,
    width: 260,
    marginLeft: theme.spacing(1),
    '& .MuiFormLabel-root': {
      color: theme.palette.white.main,
      fontSize: theme.typography.pxToRem(14),
    },
    '& .MuiInputBase-root:hover': {
      color: theme.palette.white.main,
    },
  },
  select: {
    color: theme.palette.white.main,
  },
  iconSelect: {
    color: theme.palette.white.main,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const OutlinedInput = withStyles((theme) => ({
  root: {
    '& $notchedOutline': {
      borderColor: theme.palette.white.main,
    },
    '&:hover $notchedOutline': {
      borderColor: theme.palette.white.main,
    },
    '&$focused $notchedOutline': {
      borderColor: theme.palette.white.main,
    },
  },
  focused: {},
  notchedOutline: {},
}))(MuiOutlinedInput)

const anoAtual = +moment().format('YYYY')
const anos = map(range(2014, anoAtual + 1), (ano) => ({
  value: ano.toString(), label: `${(ano - 1).toString()}/${ano.toString().slice(2)}`,
}))

const IndicadoresGestao = () => {
  const location = useLocation()
  const params = location?.state?.data
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const dialog = useConfirmationDialog()

  const isLoading = useSelector(getIsCarregandoIndicadoresCriterioSelecionado)
  const principios = useSelector(getPrincipios)
  const empresa = useSelector(getEmpresaUsuario)
  const anoCarregado = useSelector(getAnoCarregado)

  const [principioSelecionado, setPrincipioSelecionado] = useState(null)
  const [criterioSelecionado, setCriterioSelecionado] = useState(null)
  const [criterios, setCriterios] = useState([])
  const [indicadores, setIndicadores] = useState([])
  const [tabSelecionada, setTabSelecionada] = useState(0)
  const [subTabSelecionada, setSubTabSelecionada] = useState(0)
  const [indicadorSelecionado, setIndicadorSelecionado] = useState(null)
  const [verificadores, setVerificadores] = useState([])
  const [anoSelecionado, setAnoSelecionado] =
      useState(params?.ano || anoCarregado || anoAtual.toString())
  const [indicadoresInfo, setIndicadoresInfo] = useState({})
  const [indicadoresStats, setIndicadoresStats] = useState({})
  const [salvando, setSalvando] = useState(false)
  const [posicoes, setPosicoes] = useState({
    principio: 0,
    criterio: 0,
    indicador: 0,
  })
  const [allowTabChange, setAllowTabChange] = useState(true)
  const [formSpyChange, setFormSpyChange] = useState(false)

  const ref = useRef()

  useEffect(() => {
    if (principios?.length && principios[0]?.criterios) {
      if (!salvando) {
        setPrincipioSelecionado(principios[0])
        setCriterioSelecionado(principios[0].criterios[0])
        setIndicadorSelecionado(principios[0].criterios[0].indicadores[0])
        setCriterios(principios[0].criterios)
        setIndicadores(principios[0].criterios[0].indicadores)

        setTabSelecionada(`${principios[0].id}${principios[0].criterios[0].id}`)
        setSubTabSelecionada(`${principios[0].criterios[0].id}${principios[0].criterios[0].indicadores[0].id}`)
        setSalvando(true)
      } else {
        const atualizaSelecionado = principios[posicoes.principio]
          ?.criterios[posicoes.criterio]?.indicadores[posicoes.indicador] ?? {}
        setIndicadorSelecionado(atualizaSelecionado)
      }

      const indicatorsInfo = getIndicatorsInfo(principios)
      const indicatorsStats = getIndicatorsStats(indicatorsInfo)
      setIndicadoresInfo(indicatorsInfo)
      setIndicadoresStats(indicatorsStats)
    }
  }, [principios, salvando, posicoes])

  useEffect(() => {
    if (indicadorSelecionado && indicadorSelecionado.verificadores) {
      setVerificadores(compact(split(indicadorSelecionado.verificadores, ';')))
    }
  }, [indicadorSelecionado])

  const handleChangePrincipio = (principio, posicao) => {
    setPosicoes((e) => ({
      ...e,
      principio: posicao,
      criterio: 0,
      indicador: 0,
    }))
    setPrincipioSelecionado(principio)
    setCriterioSelecionado(principio.criterios[0])
    setCriterios(principio.criterios)
    setIndicadores(principio.criterios[0].indicadores)
    setIndicadorSelecionado(principio.criterios[0].indicadores[0])
    setTabSelecionada(`${principio.id}${principio.criterios[0].id}`)
    setSubTabSelecionada(`${principio.criterios[0].id}${principio.criterios[0].indicadores[0].id}`)
  }

  const handleClickPrincipio = (principio, posicao) => {
    if (!allowTabChange) {
      return dialog.showConfirmation({
        title: t('dialogs.salvarDadosTitulo'),
        message: t('dialogs.salvarDadosDescricao'),
      }).then((result) => (result && handleChangePrincipio(principio, posicao)))
    }
    return handleChangePrincipio(principio, posicao)
  }

  const setActiveTab = (criterio, posicao) => {
    setFormSpyChange(false)
    setCriterioSelecionado(criterio)
    setIndicadorSelecionado(criterio.indicadores[0])
    setIndicadores(criterio.indicadores)
    setTabSelecionada(`${principioSelecionado.id}${criterio.id}`)
    setSubTabSelecionada(`${criterio.id}${criterio.indicadores[0].id}`)
    setPosicoes((e) => ({
      ...e,
      criterio: posicao,
      indicador: 0,
    }))
  }

  const setActiveSubTab = (indicador, posicao) => {
    setFormSpyChange(false)
    setIndicadorSelecionado(indicador)
    setSubTabSelecionada(`${criterioSelecionado.id}${indicador.id}`)
    setPosicoes((e) => ({ ...e, indicador: posicao }))
  }

  const handleClickTab = (criterio, posicao) => {
    if (!allowTabChange) {
      return dialog.showConfirmation({
        title: t('dialogs.salvarDadosTitulo'),
        message: t('dialogs.salvarDadosDescricao'),
      }).then((result) => result && setActiveTab(criterio, posicao))
    }
    return setActiveTab(criterio, posicao)
  }

  const handleClickSubTab = (indicador, posicao) => {
    if (!allowTabChange) {
      return dialog.showConfirmation({
        title: t('dialogs.salvarDadosTitulo'),
        message: t('dialogs.salvarDadosDescricao'),
      }).then((result) => result && setActiveSubTab(indicador, posicao))
    }
    return setActiveSubTab(indicador, posicao)
  }

  const handleChangeAno = (event) => {
    setAnoSelecionado(event.target.value)
  }

  useEffect(() => {
    if (anoCarregado !== anoSelecionado) {
      dispatch(callListarIndicadorEmpresa({
        empresaId: empresa.id, ano: anoSelecionado, paisId: empresa.pais.id,
      }))
    }
  }, [anoSelecionado, dispatch, empresa.id, empresa.pais.id, anoCarregado])

  const onSaveIndicadorEmpresa = (indicadorEmpresa, indicadorId) => {
    const criterio = { ...criterioSelecionado }
    const indexIndicador = findIndex(criterio.indicadores, ['id', indicadorId])
    const indicador = criterio.indicadores[indexIndicador]
    indicador.indicadorEmpresa = { ...indicadorEmpresa }
    criterio.indicadores[indexIndicador] = { ...indicador }
    const indexPrincipio = findIndex(principios, ['id', principioSelecionado.id])
    const indexCriterio = findIndex(principioSelecionado.criterios, ['id', criterio.id])
    principios[indexPrincipio].criterios[indexCriterio] = criterio
  }

  const scrollToTop = () => {
    ref.current.scrollIntoView()
  }

  const loading = <Typography className={classes.message}>{t('indicadoresGestao.carregando')}</Typography>

  const handleAllowTabChange = ({ initialValues, values }) => {
    setFormSpyChange(true)
    const diffValues = diff(initialValues, values)
    const changed = isFormChanged({ dirtyValues: diffValues, formValues: values, initialValues })
    setAllowTabChange(!changed)
  }

  const handleCanChangeLocation = (event, cb) => {
    if (!allowTabChange) {
      return dialog.showConfirmation({
        title: t('dialogs.salvarDadosTitulo'),
        message: t('dialogs.salvarDadosDescricao'),
      }).then((result) => {
        if (result) {
          cb(event)
          setAllowTabChange(true)
        }
      })
    }
    return cb(event)
  }

  return (
    <Grid container justifyContent="center">
      <RouterPrompt
        when={!allowTabChange}
        title={t('dialogs.salvarDadosTitulo')}
        message={t('dialogs.salvarDadosDescricao')}
      />
      <Backdrop className={classes.backdrop} open={isLoading} transitionDuration={500}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid item container xs={6} justifyContent="flex-start">
        <PageTitle title={t('indicadoresGestao.indicadoresGestao')} />
      </Grid>
      <Grid item container xs={6} justifyContent="flex-end">
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="ano-base">
            {t('pressaoBiodiversidade.selecioneAno')}
          </InputLabel>
          <Select
            classes={{
              select: classes.select,
              icon: classes.iconSelect,
            }}
            value={anoSelecionado}
            onChange={(event) => handleCanChangeLocation(event, handleChangeAno)}
            label={t('pressaoBiodiversidade.selecioneAno')}
            inputProps={{
              name: 'ano-base',
              id: 'ano-base',
            }}
            input={<OutlinedInput label={t('pressaoBiodiversidade.selecioneAno')} />}
          >
            {map(anos, (option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <MainColumn columns="1fr 1fr 120px">
          <Card
            hasGradient
            status="purple"
            icon={FolderShieldIcon}
            title={t('indicadoresGestao.indicadoresEssenciais')}
          >
            <CardInfo {...(indicadoresInfo.essencial || {})} />
          </Card>
          <Card
            hasGradient
            status="orange"
            icon={FolderChartIcon}
            title={t('indicadoresGestao.indicadoresGerais')}
          >
            <CardInfo {...(indicadoresInfo.geral || {})} />
          </Card>
          <Card
            shrink
            status="purple"
            href={DOCUMENTS_URL}
            icon={FileCopyLineIcon}
            title={t('pressaoBiodiversidade.documentosLife')}
          />
        </MainColumn>
      </Grid>
      <Grid item xs={12}>
        <MainColumn columns="repeat(3, 1fr)">
          <CardStatistics
            percentage={indicadoresStats.indicadoresEssenciaisGerais}
            description={t('indicadoresGestao.estatisticaIndicadoresEssenciaisGerais')}
          />
          <CardStatistics
            percentage={indicadoresStats.indicadoresEssenciais}
            description={t('indicadoresGestao.estatisticaIndicadoresEssenciais')}
          />
          <CardStatistics
            percentage={indicadoresStats.indicadoresGerais}
            smallText={t('indicadoresGestao.certificacaoInicial')}
            description={t('indicadoresGestao.estatisticaIndicadoresGerais')}
          />
        </MainColumn>
      </Grid>
      <Grid container item xs={12} className={classes.container} ref={ref}>
        <Grid item xs={4} className={classes.leftTab}>
          {principios ? (
            map(principios, (principio, index) => (
              <ListItemStyled
                key={principio.id}
                button
                selected={principioSelecionado?.id === principio.id}
                classes={{ selected: classes.active, root: classes.leftTabItem }}
                onClick={() => handleClickPrincipio(principio, index)}
                disableRipple
                // warning: use this flag for the warning tab color
                // completed: use this flag for the green tab color
              >
                <Box className={classes.leftTabItemBox}>
                  <ListItemText
                    primary={`${t('indicadoresGestao.principio')} ${principio.numero}`}
                    title={principio.nome}
                    className={classes.leftTabItemText}
                  />
                  {/* TODO: rule to show warning icon */}
                  {/* <ListItemIcon className={classes.infoIcon}>
                    <ErrorOutline style={{ color: '#EE7D40' }} />
                  </ListItemIcon> */}
                  {/* TODO: rule to show check icon */}
                  {/* <ListItemIcon className={classes.infoIcon}>
                    <CheckIcon style={{ color: '#219754' }} />
                  </ListItemIcon> */}
                </Box>
              </ListItemStyled>
            ))
          ) : loading}
        </Grid>
        <Grid item xs={8} className={classes.rightTab}>
          {principioSelecionado ? (
            <TabContext value={tabSelecionada.toString()}>
              <Typography variant="h6" className={classes.rightTabContentTitle}>
                {`${t('indicadoresGestao.principio')} ${principioSelecionado?.numero}`}
              </Typography>

              <Typography variant="h6" className={classes.rightTabContentLabel}>
                {principioSelecionado?.nome}
              </Typography>

              <Typography variant="h6" className={classes.rightTabContentDescription}>
                {principioSelecionado?.descricao}
              </Typography>

              {principioSelecionado?.notasRodape && (
                <Typography
                  variant="h6"
                  className={classes.rightTabRodapeDescription}
                >
                  {principioSelecionado?.notasRodape}
                </Typography>
              )}

              <TabListItem>
                {criterios && (
                  map(criterios, (criterio, i) => (
                    <TabItem
                      key={`${criterio.id}${criterio.principioId}${i}`}
                      selected={(`${principioSelecionado?.id}${criterio?.id}`) === tabSelecionada}
                      onClick={() => handleClickTab(criterio, i)}
                    >
                      {`${t('indicadoresGestao.criterio')} ${criterio.numero}`}
                    </TabItem>
                  ))
                )}
              </TabListItem>

              {(`${principioSelecionado?.id}${criterioSelecionado?.id}`) === tabSelecionada && (
                <Box pt={4}>
                  <Typography component="span" className={classes.rightTabPanelText}>
                    {criterioSelecionado?.descricao}
                  </Typography>

                  {criterioSelecionado?.notasRodape && (
                    <Typography
                      variant="h6"
                      className={classes.rightTabRodapeDescription}
                    >
                      {criterioSelecionado?.notasRodape}
                    </Typography>
                  )}

                  <TabListItem>
                    {indicadores && (
                      map(indicadores, (indicador, i) => (
                        <TabItem
                          key={i}
                          selected={(`${criterioSelecionado?.id}${indicador?.id}`) === subTabSelecionada}
                          onClick={() => handleClickSubTab(indicador, i)}
                          // warning: use this flag for the warning tab color
                          // completed: use this flag for the green tab color
                        >
                          {`${t('indicadoresGestao.indicador')} ${indicador.numero}`}
                        </TabItem>
                      ))
                    )}
                  </TabListItem>

                  {(`${criterioSelecionado?.id}${indicadorSelecionado?.id}`) === subTabSelecionada && (
                    <Box pt={2}>
                      {indicadorSelecionado?.essencial && (
                        <Typography className={classes.essencial}>
                          {t('indicadoresGestao.indicadorEssencial')}
                        </Typography>
                      )}
                      <Typography className={classes.indicadorDescricao}>
                        {indicadorSelecionado?.descricao}
                      </Typography>

                      {indicadorSelecionado?.verificadores && (
                        <>
                          <Typography className={classes.verificadoresTitle}>
                            {`${t('indicadoresGestao.verificadores')}:`}
                          </Typography>
                          <ul className={classes.verificadoresList}>
                            {map(verificadores, (verificador, indexV) => (
                              <li className={classes.typography} key={indexV}>
                                {verificador}
                              </li>
                            ))}
                          </ul>
                        </>
                      )}

                      {indicadorSelecionado?.notasRodape && (
                        <Typography
                          variant="h6"
                          className={[classes.rightTabRodapeDescription, classes.rightTabRodapeVerificadores].join(' ')}
                        >
                          {indicadorSelecionado?.notasRodape}
                        </Typography>
                      )}

                      <IndicadorEmpresa
                        indicadorEmpresaParam={indicadorSelecionado?.indicadorEmpresa || {}}
                        indicadorId={indicadorSelecionado?.id}
                        onSave={onSaveIndicadorEmpresa}
                        scrollToTop={scrollToTop}
                        anoSelecionado={anoSelecionado}
                        setSalvando={setSalvando}
                        setChangeTab={handleAllowTabChange}
                        canResetForm={!formSpyChange}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </TabContext>
          ) : loading}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default IndicadoresGestao
