import {
  keyBy,
  isNil,
  isUndefined,
  isObject,
  matchesObject,
  normalizeArray,
  emptyAction,
  immutableSplice
} from '../utils'

const { isArray } = Array

const defaultMerge = (obj, ...sources) => (
  sources.reduce((o, s) => ({ ...o, ...s }), obj)
)

const normalizeArrayPayload = (payload) => normalizeArray(payload).filter(v => v)

/**
 * Default value for collectionReducer's `getFilterCallback` option
 * @alias module:collection
 * @param {*} payload a filter action's payload
 * @returns {function} Array.filter callback function
 */
export const getCollectionFilterCallback = (payload) => (element) => matchesObject(element, payload)

/**
 * Default value for collectionReducer's `getModelReducer` option
 * @alias module:collection
 * @param {{}} options subset of collectionReducer options
 * @param {string} options.idAttribute id attribute used for collection elements
 * @param {string} options.cidAttribute cid attribute used for collection elements
 * @returns {function} "redux-style" reducer; given a model, 'reduce' action, and model cid, returns next model
 */
export const getCollectionModelReducer = ({
  idAttribute,
  cidAttribute,
  merge
}) => (model, action, cid) => {
  const { payload } = action
  const idsObj = { [cidAttribute]: cid }
  if (!isUndefined(model[idAttribute])) {
    idsObj[idAttribute] = model[idAttribute]
  }
  return merge(model, payload, idsObj)
}

const add = (collection, payload, meta, cidAttr, mergeFn) => {
  // http://backbonejs.org/#Collection-add
  // expects `payload` to be an array of models or a single model
  // expects `meta.at` to specify where to insert in collection (defaults
  //   to the end of the collection)
  // expect `meta.merge` (bool) to determine if models that are already part
  //   of the collection be merged onto existing objects in place.
  if (!isObject(payload)) return collection
  const {
    at = collection.length,
    merge = true
  } = meta || {}
  const collectionMap = keyBy(collection, cidAttr)
  const newModels = normalizeArrayPayload(payload)
  const addedModels = newModels.filter(model => isUndefined(collectionMap[model[cidAttr]]))

  if (merge) {
    // when merging/updating existing models, figure out which are new, and then
    // shallow merge updated models onto existing ones.
    const addedModelsMap = keyBy(addedModels, cidAttr)
    const newModelsMap = keyBy(newModels, cidAttr)

    return immutableSplice(collection.map(model => {
      const modelId = model[cidAttr]
      const mergeModel = newModelsMap[modelId]
      if (mergeModel && isUndefined(addedModelsMap[modelId])) {
        return mergeFn(model, mergeModel)
      }
      return model
    }), at, 0, ...addedModels)
  }
  // if merge not specified don't add new models to collection if they are
  // already in the collection.  this attempts to match Backbone "add"
  return immutableSplice(collection, at, 0, ...addedModels)
}

const push = (collection, payload, meta, ...rest) => {
  // http://backbonejs.org/#Collection-push
  // expect `meta.merge` (bool) to determine if models that are already part
  //   of the collection be merged onto existing objects in place.
  const newMeta = { ...meta, at: collection.length }
  return add(collection, payload, newMeta, ...rest)
}

const pop = (collection, count = 1) => {
  // http://backbonejs.org/#Collection-pop
  if (!collection.length) return collection
  return collection.slice(0, -(count))
}

const remove = (collection, payload, cidAttr) => {
  // http://backbonejs.org/#Collection-remove
  // removes a model (or models) from collection by ID.
  // TODO: this doesn't quite match Backbone (which allows objects), but I
  // think (most often in redux-land with normalized objects) removing by ID
  // will be easiest and work for most of our needs.  If we find a use case
  // to remove by object _or_ by ID, we can implement that later.
  if (!collection.length) return collection
  const ids = normalizeArrayPayload(payload)
  return collection.filter(model => ids.indexOf(model[cidAttr]) === -1)
}

const filter = (collection, payload, getCallback) => {
  // filters collection for models using supplied getFilterCallback option.
  // default option attempts to mimic lodash.filter functionality, where any
  // objects that don't match the given payload are removed.
  if (!collection.length) return collection
  return collection.filter(getCallback(payload))
}

const reject = (collection, payload, getCallback) => {
  // filters collection for models using supplied getFilterCallback option.
  // default option attempts to mimic lodash.reject functionality, where any
  // objects that match the given payload are removed.
  if (!collection.length) return collection
  return collection.filter((...args) => !getCallback(payload)(...args))
}

const reset = (payload) => {
  // http://backbonejs.org/#Collection-reset
  // reset the collection
  // replaces collection with new models, or resets to empty array
  return normalizeArrayPayload(payload)
}

const unshift = (collection, payload, meta, cidAttr) => {
  // http://backbonejs.org/#Collection-unshift
  // add model to beginning of array
  // supports same `meta.merge` option as `addReducer`
  if (!payload) return collection
  const newMeta = { at: 0, ...meta }
  return add(collection, payload, newMeta, cidAttr)
}

const shift = (collection, count = 1) => {
  // http://backbonejs.org/#Collection-shift
  // remove model from beginning of array
  if (!collection.length) return collection
  return collection.slice(count)
}

const reduce = (collection, action, cidAttr, reducer) => {
  if (!collection.length) return collection
  const { meta } = action
  if (isNil(meta)) {
    return collection.map(model => reducer(model, action, model[cidAttr]))
  }
  const ids = (!isArray(meta) && isObject(meta))
    ? normalizeArray(meta.ids)
    : normalizeArray(meta)
  const idMap = keyBy(collection, cidAttr)
  const modelIds = ids.filter(id => !isNil(id) && idMap[id])
  const indices = modelIds.map(id => collection.indexOf(idMap[id]))
  return indices.reduce((acc, cur, i) => {
    const id = modelIds[i]
    const nextModel = reducer(idMap[id], action, id)
    return immutableSplice(acc, cur, 1, nextModel)
  }, collection)
}

const batch = (collection, reducer, actions) => (
  actions.reduce((acc, cur) => reducer(acc, cur), collection)
)

/**
 * Inspired by the (most excellent) Backbone.Collection API
 * http://backbonejs.org/#Collection
 *
 * Action-managed "collection" implementation for common client-side
 * list operations when dealing with an array of uniquely identifiable
 * objects.  This can be useful for "list builder" features or anything
 * else that requires managing an array of objects w/ actions.
 *
 * @alias module:collection
 * @param {Object[]} [defaultState=[]] initial state to return, null or error
 * @param {{}} [types={}] action types to match for payload history
 * @param {string|symbol} types.add add action type
 * @param {string|symbol} types.push push action type
 * @param {string|symbol} types.pop pop action type
 * @param {string|symbol} types.remove remove action type
 * @param {string|symbol} types.filter filter action type
 * @param {string|symbol} types.reject reject action type
 * @param {string|symbol} types.reset reset action type
 * @param {string|symbol} types.unshift unshift action type
 * @param {string|symbol} types.shift shift action type
 * @param {string|symbol} types.reduce reduce action type
 * @param {string|symbol} types.batch batch action type}
 * @param {{}} [options={}] additional options for resulting reducer
 * @param {string|symbol} options.idAttribute model attribute to use as preassigned ID
 * @param {string|symbol} options.cidAttribute model attribute to use as dynamic "client ID"
 * @param {function} options.getModelReducer method to invoke on model(s) when processing "reduce" actions
 * @param {function} options.getFilterCallback given action payload, returns filter callback function
 * @param {function} options.merge object merge method for `add` reducer. defaults to shallow merge: (obj, source) => result
 * @param {function} options.uid method to make a unique id for "cid" generation. defaults to scoped, int incrementor
 * @return {function} redux reducer function
 */
const collectionReducer = (defaultState = [], {
  add: addAction,
  push: pushAction,
  pop: popAction,
  remove: removeAction,
  filter: filterAction,
  reject: rejectAction,
  reset: resetAction,
  unshift: unshiftAction,
  shift: shiftAction,
  reduce: reduceAction,
  batch: batchAction
} = {}, {
  idAttribute = 'id',
  cidAttribute = 'cid',
  getModelReducer = getCollectionModelReducer,
  getFilterCallback = getCollectionFilterCallback,
  merge = defaultMerge
} = {}) => {
  const reduceHandler = getModelReducer({ idAttribute, cidAttribute, merge })

  const reducer = (collection = defaultState, action = emptyAction()) => {
    const { type, payload, meta } = action
    switch (type) {
      case addAction: {
        return add(collection, payload, meta, cidAttribute, merge)
      }
      case pushAction: {
        return push(collection, payload, meta, cidAttribute, merge)
      }
      case popAction: {
        return pop(collection, payload)
      }
      case removeAction: {
        return remove(collection, payload, cidAttribute)
      }
      case filterAction: {
        return filter(collection, payload, getFilterCallback)
      }
      case rejectAction: {
        return reject(collection, payload, getFilterCallback)
      }
      case resetAction: {
        return reset(payload)
      }
      case unshiftAction: {
        return unshift(collection, payload, meta || {}, cidAttribute)
      }
      case shiftAction: {
        return shift(collection, payload)
      }
      case reduceAction: {
        return reduce(collection, action, cidAttribute, reduceHandler)
      }
      case batchAction: {
        return batch(collection, reducer, normalizeArray(payload))
      }
      // TODO: Backbone.Collection "set" ("smart" merge of models)?
      default: {
        return collection
      }
    }
  }
  return reducer
}

export default collectionReducer
