import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { MenuItem } from '@material-ui/core'
import Input from './BaseInput'

const FieldSelect = ({ name, options, onChange, ...props }) => (
  <Field
    name={name}
    render={({ input: { onChange: inputChange, ...input }, meta }) => (
      <Input
        {...input}
        {...props}
        select
        error={meta.touched && !!meta.error}
        helperText={meta.touched && meta.error}
        onChange={(event) => {
          inputChange(event?.target?.value)
          if (onChange) onChange(event?.target?.value)
        }}
      >
        {options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        )) || []}
      </Input>
    )}
  />
)

FieldSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  onChange: PropTypes.func,
}

export default FieldSelect
