import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import {
  API_GET_RELATORIO_GESTAO_DETALHADO,
  API_GET_RELATORIO_GESTAO_RESUMIDO,
  API_GET_RELATORIO_GESTAO_GERAL,
} from 'store/modules/api/relatorios/relatoriosGestao.actions'

const isCarregandoRelatorioGestaoDetalhado =
  loadingReducer(false, API_GET_RELATORIO_GESTAO_DETALHADO)
const isCarregandoRelatorioGestaoResumido =
  loadingReducer(false, API_GET_RELATORIO_GESTAO_RESUMIDO)
const isCarregandoRelatorioGestaoGeral =
  loadingReducer(false, API_GET_RELATORIO_GESTAO_GERAL)

export default combineReducers({
  isCarregandoRelatorioGestaoDetalhado,
  isCarregandoRelatorioGestaoResumido,
  isCarregandoRelatorioGestaoGeral,
})
