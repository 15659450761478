import { filter } from 'lodash'
import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { API_LISTAR_CLASSES_IRC_ADMIN,
  API_SALVAR_IRC_ADMIN,
  API_EXCLUIR_CLASSES_IRC_ADMIN,
  API_ATUALIZAR_CLASSES_IRC_ADMIN } from 'store/modules/api/classesIrcAdmin.actions'

const lista = (state = [], { type, payload }) => {
  switch (type) {
    case API_LISTAR_CLASSES_IRC_ADMIN.success:
      return payload.sort((a, b) => a.nome.localeCompare(b.nome))
    case API_SALVAR_IRC_ADMIN.success:
      return [...state, payload].sort((a, b) => a.nome.localeCompare(b.nome))
    case API_ATUALIZAR_CLASSES_IRC_ADMIN.success:
      return state.map((e) => (e.id === payload.id ?
        payload : e))
    case API_EXCLUIR_CLASSES_IRC_ADMIN.success:
      return filter(state, (o) => o.id !== Number(payload))
    default: return state
  }
}

const isCarregandoLista = loadingReducer(false, API_LISTAR_CLASSES_IRC_ADMIN)

const isSaving = loadingReducer(false, API_SALVAR_IRC_ADMIN)
const isUpdate = loadingReducer(false, API_ATUALIZAR_CLASSES_IRC_ADMIN)

export default combineReducers({
  lista,
  isCarregandoLista,
  isSaving,
  isUpdate,
})
