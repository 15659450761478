const ACTION_PATH = 'app/biomas'

export const CALL_CRIAR_BIOMA = `${ACTION_PATH}/CALL_CRIAR_BIOMA`
export const CALL_EXCLUIR_BIOMA = `${ACTION_PATH}/CALL_EXCLUIR_BIOMA`
export const CALL_ATUALIZAR_BIOMA = `${ACTION_PATH}/CALL_ATUALIZAR_BIOMA`

export const callCriarBioma = (payload) => ({
  type: CALL_CRIAR_BIOMA,
  payload,
})

export const callAtualizarBioma = (payload) => ({
  type: CALL_ATUALIZAR_BIOMA,
  payload,
})

export const callExcluirBioma = ({ id }) => ({
  type: CALL_EXCLUIR_BIOMA,
  payload: { id },
})
