import React, { useState, useEffect } from 'react'
import {
  Typography,
  Container,
  Box,
  Modal,
  makeStyles,
  CardContent,
  Button,
  Grid,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { push } from 'connected-react-router'
import { getEmpresaUsuario, getDadosUsuario } from 'store/modules/auth/auth.selectors'
import { useTranslation } from 'react-i18next'
import { TIPO_USUARIO } from 'utils/constantes'
import dadosEmpresa from 'assets/images/iniciarCadastro/dadosEmpresa.png'
import dadosFiliais from 'assets/images/iniciarCadastro/dadosFiliais.png'
import seusDados from 'assets/images/iniciarCadastro/seusDados.png'

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 560,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[8],
  },
  typo: {
    color: '#9E9E9E',
  },
  subtitle: {
    color: '#9E9E9E',
    textAlign: 'center',
  },
  botaoIniciar: {
    textAlign: 'center',
  },
}))

const HomePage = () => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const dispatch = useDispatch()
  const empresa = useSelector(getEmpresaUsuario)
  const usuario = useSelector(getDadosUsuario)

  const iniciarCadastro = () => {
    dispatch(push('/cadastroInicial'))
  }

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false)
    }
  }

  useEffect(() => {
    if (!empresa) {
      return
    }
    if (!empresa.cadastroInicial &&
        usuario.tipoUsuario.id === TIPO_USUARIO.CLIENTE.LIDER) {
      // dispatch(push('/cadastroInicial')) TODO: desbloquear onboarding
      dispatch(push('/dashboard'))
    } else {
      dispatch(push('/dashboard'))
    }
  }, [empresa, dispatch, usuario])

  const Card = ({ imagem, texto }) => (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <img src={imagem} alt="Logo" className={classes.typo} />
      </Box>
      <CardContent>
        <Typography variant="subtitle2" noWrap className={classes.subtitle}>
          {texto}
        </Typography>
      </CardContent>
    </>
  )

  Card.propTypes = {
    imagem: PropTypes.node,
    texto: PropTypes.string,
  }

  return (
    <Container maxWidth="sm">
      <Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          disableEscapeKeyDown
        >
          <div className={classes.paper}>
            <h2 id="simple-modal-title">{t('modalInicial.titulo')}</h2>
            <Typography component="h1" variant="body1" className={classes.typo}>
              {t('modalInicial.subtitulo')}
            </Typography>
            <Box pt={10}>
              <Grid container alignItems="center" direction="row" justifyContent="space-between" spacing={3}>
                <Grid item xs>
                  <Card imagem={seusDados} texto={t('modalInicial.seusDados')} />
                </Grid>
                <Grid item xs>
                  <Card imagem={dadosEmpresa} texto={t('modalInicial.dadosEmpresa')} />
                </Grid>
                <Grid item xs>
                  <Card imagem={dadosFiliais} texto={t('modalInicial.dadosFiliais')} />
                </Grid>
              </Grid>
            </Box>
            <Box pt={5} className={classes.botaoIniciar}>
              <Button
                type="button"
                onClick={iniciarCadastro}
                variant="contained"
                color="primary"
                size="large"
              >
                {t('modalInicial.iniciarCadastro')}
              </Button>
            </Box>
          </div>
        </Modal>
      </Box>
    </Container>
  )
}

export default HomePage
