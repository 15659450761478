import { mapValues } from '../utils'

/**
 * action types for "stackReducer"
 * @alias module:stack
 * @constant
 * @type {{}}
 * @prop {string} push
 * @prop {string} pop
 * @prop {string} replace
 * @prop {string} remove
 * @prop {string} clear
 */
export const STACK_TYPES = Object.freeze({
  push: 'push',
  pop: 'pop',
  replace: 'replace',
  remove: 'remove',
  clear: 'clear'
})

/**
 * creates a map of "stack" action types.
 * produces an object in the form expected by the `actionTypes` argument
 * of the `stackReducer` method.  can be given a `getType` argument
 * to customize the action type values, per action.
 *
 * @alias module:stack
 * @param {function} [getType] assigns property values to resulting object
 * @return {{}} map of "stack" action types to string|symbol values
 */
const stackActionTypes = (getType) => mapValues(STACK_TYPES, getType)

export default stackActionTypes
