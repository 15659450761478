const ACTION_PATH = 'app/gasesEfeitoEstufa'

export const CALL_CRIAR_GAS_EFEITO_ESTUFA = `${ACTION_PATH}/CALL_CRIAR_GAS_EFEITO_ESTUFA`
export const CALL_ATUALIZAR_GAS_EFEITO_ESTUFA = `${ACTION_PATH}/CALL_ATUALIZAR_GAS_EFEITO_ESTUFA`
export const CALL_EXCLUIR_GAS_EFEITO_ESTUFA = `${ACTION_PATH}/CALL_EXCLUIR_GAS_EFEITO_ESTUFA`
export const CALL_SELECIONAR_GAS_EFEITO_ESTUFA = `${ACTION_PATH}/CALL_SELECIONAR_GAS_EFEITO_ESTUFA`
export const CALL_NOVO_GAS_EFEITO_ESTUFA = `${ACTION_PATH}/CALL_NOVO_GAS_EFEITO_ESTUFA`

export const callCriarGasEfeitoEstufa = (gasEfeitoEstufa) => ({
  type: CALL_CRIAR_GAS_EFEITO_ESTUFA,
  payload: gasEfeitoEstufa,
})

export const callAtualizarGasEfeitoEstufa = ({ id, gasEfeitoEstufa }) => ({
  type: CALL_ATUALIZAR_GAS_EFEITO_ESTUFA,
  payload: { id, gasEfeitoEstufa },
})

export const callExcluirGasEfeitoEstufa = ({ id }) => ({
  type: CALL_EXCLUIR_GAS_EFEITO_ESTUFA,
  payload: id,
})

export const callSelecionarGasEfeitoEstufa = ({ id }) => ({
  type: CALL_SELECIONAR_GAS_EFEITO_ESTUFA,
  payload: { id },
})

export const callNovoGasEfeitoEstufa = () => ({
  type: CALL_NOVO_GAS_EFEITO_ESTUFA,
})
