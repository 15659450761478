const ACTION_PATH = 'app/paisesCalculos'

export const CALL_LISTAR_DADOS_PAIS_CALCULOS = `${ACTION_PATH}/CALL_LISTAR_DADOS_PAIS_CALCULOS`
export const CALL_SELECIONAR_PAIS = `${ACTION_PATH}/CALL_SELECIONAR_PAIS`

export const callListarDadosPaisCalculos = (payload) => ({
  type: CALL_LISTAR_DADOS_PAIS_CALCULOS,
  payload,
})

export const callSelecionarPais = (payload) => ({
  type: CALL_SELECIONAR_PAIS,
  payload,
})
