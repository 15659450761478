import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchDivisoesSetoresActionTypes = apiActionTypes('divisoesSetores/API_LISTAR_DIVISOES_SETORES')
const getDivisaoSetorActionTypes = apiActionTypes('divisoesSetores/API_GET_DIVISAO_SETOR')
const createDivisaoSetorActionTypes = apiActionTypes('divisoesSetores/API_CRIAR_DIVISAO_SETOR')
const updateDivisaoSetorActionTypes = apiActionTypes('divisoesSetores/API_ATUALIZAR_DIVISAO_SETOR')

export const API_LISTAR_DIVISOES_SETORES = asApiTypesObject(fetchDivisoesSetoresActionTypes)
export const API_GET_DIVISAO_SETOR = asApiTypesObject(getDivisaoSetorActionTypes)
export const API_CRIAR_DIVISAO_SETOR = asApiTypesObject(createDivisaoSetorActionTypes)
export const API_ATUALIZAR_DIVISAO_SETOR = asApiTypesObject(updateDivisaoSetorActionTypes)

export const apiListarDivisoesSetores = (empresaId, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/divisoesSetores`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchDivisoesSetoresActionTypes,
}, { ...meta, query: { empresaId } })

export const apiCriarDivisaoSetor = (divisaoSetor, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/divisoesSetores`,
  method: 'POST',
  types: createDivisaoSetorActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(divisaoSetor),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiAtualizarDivisaoSetor = ({ id, divisaoSetor }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/divisoesSetores/${id}`,
  method: 'PUT',
  types: updateDivisaoSetorActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(divisaoSetor),
}, { ...meta, successNotification: 'mensagens.atualizado' })

export const apiSalvarListaDivisaoSetor = ({ lista, successNotification }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/divisoesSetores/salvarLista`,
  method: 'POST',
  types: createDivisaoSetorActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(lista),
}, { ...meta, successNotification })
