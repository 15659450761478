import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import ContentBox from 'components/ContentBox'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 580px)',
  },
  contentBox: {
    flex: 1,
    height: '100%',
    display: 'flex',
  },
  contentLeft: {
    width: 374,
    flexShrink: 0,
  },
  contentSeparator: {
    width: 1,
    flexShrink: 0,
    margin: theme.spacing(0, 4),
    backgroundColor: theme.palette.grey.main,
    height: (props) => (props.hasHeader ? '100%' : 'auto'),
  },
  contentRight: {
    width: '100%',
  },
}))

const ColumnsBox = ({
  header, leftColumn, rightColumn, className, classes = {},
}) => {
  const classnames = useStyles({ hasHeader: header })

  return (
    <ContentBox className={clsx(className, classnames.wrapper, classes.root)} mt={6}>
      {header}
      <div className={clsx(classnames.contentBox, classes.contentBox)}>
        <div className={clsx(classnames.contentLeft, classes.leftColumn)}>
          {leftColumn}
        </div>
        <div className={classnames.contentSeparator} />
        <div className={clsx(classnames.contentRight, classes.rightColumn)}>
          {rightColumn}
        </div>
      </div>
    </ContentBox>
  )
}

ColumnsBox.propTypes = {
  header: PropTypes.node,
  leftColumn: PropTypes.node,
  rightColumn: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.shape({
    root: PropTypes.string,
    contentBox: PropTypes.string,
    leftColumn: PropTypes.string,
    rightColumn: PropTypes.string,
  }),
}

export default ColumnsBox
