const ACTION_PATH = 'app/irc'

export const CALL_SALVAR_IRC = `${ACTION_PATH}/CALL_SALVAR_IRC`
export const CALL_EXCLUIR_CLASSE_IRC = `${ACTION_PATH}/CALL_EXCLUIR_CLASSE_IRC`
export const CALL_ATUALIZAR_CLASSE_IRC = `${ACTION_PATH}/CALL_ATUALIZAR_CLASSE_IRC`

export const callSalvarIrc = (payload) => ({
  type: CALL_SALVAR_IRC,
  payload,
})
