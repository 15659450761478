import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import { apiCriarConsumoAgua, apiAtualizarConsumoAgua, apiExcluirConsumoAgua, apiGetConsumoAgua } from 'store/modules/api/consumoAgua.actions'
import { CALL_ATUALIZAR_CONSUMO_AGUA, CALL_CRIAR_CONSUMO_AGUA, CALL_EXCLUIR_CONSUMO_AGUA, CALL_SELECIONAR_CONSUMO_AGUA } from 'store/modules/consumoAgua/consumoAgua.actions'

const onSelected = (action$) => action$.pipe(
  ofType(CALL_SELECIONAR_CONSUMO_AGUA),
  debounceTime(250),
  map(({ payload }) => apiGetConsumoAgua(payload.id)),
)

const onCallCriarConsumoAgua = (action$) => action$.pipe(
  ofType(CALL_CRIAR_CONSUMO_AGUA),
  debounceTime(250),
  map(({ payload }) => apiCriarConsumoAgua(payload)),
)

const onCallAtualizarConsumoAgua = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_CONSUMO_AGUA),
  debounceTime(250),
  map(({ payload }) => apiAtualizarConsumoAgua(payload)),
)

const onCallExcluirConsumoAgua = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_CONSUMO_AGUA),
  debounceTime(250),
  map(({ payload }) => apiExcluirConsumoAgua(payload)),
)

export default combineEpics(
  onCallCriarConsumoAgua,
  onCallAtualizarConsumoAgua,
  onSelected,
  onCallExcluirConsumoAgua,
)
