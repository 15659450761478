const ACTION_PATH = 'app/faturamentos'

export const CALL_CRIAR_FATURAMENTO = `${ACTION_PATH}/CALL_CRIAR_FATURAMENTO`
export const CALL_ATUALIZAR_FATURAMENTO = `${ACTION_PATH}/CALL_ATUALIZAR_FATURAMENTO`

export const callCriarFaturamento = (faturamento) => ({
  type: CALL_CRIAR_FATURAMENTO,
  payload: faturamento,
})

export const callAtualizarFaturamento = ({ id, faturamento }) => ({
  type: CALL_ATUALIZAR_FATURAMENTO,
  payload: { id, faturamento },
})
