import { isObject } from '../utils'
import {
  CANCEL,
  canceledAction
} from './cancelActions'

/**
 * configurable version of cancelActionMiddleware, if user wants to use own
 * "cancelAction" creator, instead of built-in redux-foundry version.
 *
 * @alias module:core
 * @param {function} actionCreator override the default ACTION_CANCELED creator
 * @return {function} a redux-middleware function
 */
export const configureCancelActionMiddleware = (actionCreator = canceledAction) =>
  store => next => action => {
    const { getState } = store
    const { meta = {} } = action
    const cancel = isObject(meta) ? meta[CANCEL] : null

    if (cancel && cancel(getState(), action)) {
      return next(actionCreator(action))
    }
    return next(action)
  }

const defaultCancelActionMiddleware = configureCancelActionMiddleware()

/**
 * Middleware that allows for cancelation of FSAs.
 *
 * To use, add a `CANCEL` function property to any normal FSA action's meta data.
 *
 * The value of the `CANCEL` function should be of form:
 *   (state: Object, action: FSA) => boolean
 *
 * When evaluated, if the function returns true the action will be replaced by `ACTION_CANCELED`.
 *
 * Example 1 (FSA):
 * Any normal FSA can be canceled:
 * ```
 * {
 *   type: 'FOO',
 *   meta: {
 *     [CANCEL]: (state, action) => (shouldCancel(state, action) ? true : false)
 *   }
 * }
 * ```
 *
 * Example 2: (RSAA)
 * When used alongside `redux-api-middleware` & `apiMeta` middleware, it gets more useful.
 * You can add `CANCEL` to an RSAA, and it can cancel the async request/success/failure FSAs.
 * ```
 * {
 *   [RSAA]: { ... },
 *   [RSAA_META]: {
 *     [CANCEL]: (state, action) => (shouldCancel(state, action) ? true : false)
 *   }
 * }
 * ```
 *
 * @alias module:core
 * @return {function} normal redux-middleware signature
 */
const cancelActionMiddleware = (store) => defaultCancelActionMiddleware(store)
export default cancelActionMiddleware
