const ACTION_PATH = 'app/acoes'

export const CALL_CRIAR_ACAO_CLASSIFICACAO = `${ACTION_PATH}/CALL_CRIAR_ACAO_CLASSIFICACAO`
export const CALL_ATUALIZAR_ACAO_CLASSIFICACAO = `${ACTION_PATH}/CALL_ATUALIZAR_ACAO_CLASSIFICACAO`
export const CALL_EXCLUIR_ACAO_CLASSIFICACAO = `${ACTION_PATH}/CALL_EXCLUIR_ACAO_CLASSIFICACAO`
export const CALL_SELECIONAR_ACAO_CLASSIFICACAO = `${ACTION_PATH}/CALL_SELECIONAR_ACAO_CLASSIFICACAO`
export const CALL_NOVO_ACAO_CLASSIFICACAO = `${ACTION_PATH}/CALL_NOVO_ACAO_CLASSIFICACAO`

export const callCriarAcaoClassificacao = (acaoClassificacao) => ({
  type: CALL_CRIAR_ACAO_CLASSIFICACAO,
  payload: acaoClassificacao,
})

export const callAtualizarAcaoClassificacao = ({ id, acaoClassificacao }) => ({
  type: CALL_ATUALIZAR_ACAO_CLASSIFICACAO,
  payload: { id, acaoClassificacao },
})

export const callExcluirAcaoClassificacao = ({ id }) => ({
  type: CALL_EXCLUIR_ACAO_CLASSIFICACAO,
  payload: id,
})

export const callSelecionarAcaoClassificacao = ({ id }) => ({
  type: CALL_SELECIONAR_ACAO_CLASSIFICACAO,
  payload: { id },
})

export const callNovaAcaoClassificacao = () => ({
  type: CALL_NOVO_ACAO_CLASSIFICACAO,
})
