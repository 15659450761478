import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { KeyboardDatePicker } from '@material-ui/pickers'
import CalendarEventIcon from 'remixicon-react/CalendarEventLineIcon'
import { useTranslation } from 'react-i18next'
import { StyledInput } from './BaseInput'

const FieldDate = ({ name, onChange, minDate, maxDate, ...props }) => {
  const { t } = useTranslation()
  return (

    <Field
      name={name}
      render={({ input, meta }) => (
        <KeyboardDatePicker
          {...props}
          {...input}
          autoOk
          fullWidth
          maskChar=" "
          name={input.name}
          format="DD/MM/yyyy"
          orientation="portrait"
          inputVariant="outlined"
          value={input.value || null}
          minDate={minDate || undefined}
          maxDate={maxDate || undefined}
          TextFieldComponent={StyledInput}
          keyboardIcon={<CalendarEventIcon />}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && meta.error}
          onChange={(date) => input.onChange(date?.format() || null)}
          cancelLabel={t('inputs.calendario.cancelar')}
        />
      )}
    />
  )
}

FieldDate.propTypes = {
  onChange: PropTypes.func,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  name: PropTypes.string.isRequired,
}

export default FieldDate
