import { mapValues } from '../utils'

/**
 * action types for "dictionaryReducer"
 * @alias module:dictionary
 * @constant
 * @type {{}}
 * @prop {string} updateValue
 * @prop {string} merge
 * @prop {string} mergeValue
 * @prop {string} remove
 * @prop {string} filter
 * @prop {string} reject
 * @prop {string} reset
 * @prop {string} reduce
 * @prop {string} batch
 */
export const DICTIONARY_TYPES = Object.freeze({
  updateValue: 'updateValue',
  merge: 'merge',
  mergeValue: 'mergeValue',
  remove: 'remove',
  filter: 'filter',
  reject: 'reject',
  reset: 'reset',
  reduce: 'reduce',
  batch: 'batch'
})

/**
 * creates a map of "dictionary" action types.
 * produces an object in the form expected by the `actionTypes` argument
 * of the `dictionaryReducer` method.  can be given a `getType` argument
 * to customize the action type values, per action.
 *
 * @alias module:dictionary
 * @param {function} [getType] assigns property values to resulting object
 * @return {{}} map of "dictionary" action types to string|symbol values
 */
const dictionaryActionTypes = (getType) => mapValues(DICTIONARY_TYPES, getType)

export default dictionaryActionTypes
