import { RSAA } from 'redux-api-middleware'
import { RSAA_META } from './rsaaMeta'
import { applyQueryParams } from '../utils'

/**
 * Basic helper method to create a "Redux Standard Api Action" (RSAA)
 * Accepts a single object argument that must conform to RSAA spec,
 * see documentation: https://github.com/agraboso/redux-api-middleware
 *
 * ```
 * type RSAAction = { [RSAA]: {}, [RSAA_META]?: {} }
 * type apiAction = ({}) => RSAAction
 * ```
 *
 * @alias module:api
 * @param {{}} rsaaProps RSAA declaration object
 * @return {{}} {RSAAction} the RSAA object
 */
const apiAction = (rsaaProps = {}) => ({
  [RSAA]: rsaaProps
})

export default apiAction

/**
 * Helper method to create a "Redux Standard Api Action" (RSAA) using
 * `rsaaMeta` extensions.  Identical to the basic `apiAction` helper,
 * but accepts an additional object argument for specifying metadata
 * to relate the RSAA with cooresponding FSAs when fetched.
 *
 * ```
 * type apiActionWithMeta = ({}, {}?) => RSAAction
 * ```
 *
 * @alias module:api
 * @param {{}} rsaaProps RSAA declaration object
 * @param {{}} rsaaMeta metadata to assign to the RSAA
 * @return {{}} {RSAAction} the RSAA object
 */
export const apiActionWithMeta = (rsaaProps = {}, rsaaMeta) => {
  const rsaa = apiAction(rsaaProps)
  if (rsaaMeta) {
    // TODO: investigate issues bundling redux-foundry in ways that works nicely
    // with _any_ type of Symbol polyfill solution, like RN uses by default.
    // The following mutation seems to work with what we've tried, but needs investigation.
    // Using Objest.assign didn't work as expected here on RN.
    // Best github issue we've found realted to this:
    // https://github.com/zloirock/core-js/issues/118
    rsaa[RSAA_META] = rsaaMeta
  }
  return rsaa
}

/**
 * Factory method to create a function that accepts an RSAA object, and optionally
 * applies RSAA_META supplied query object data to RSAA endpoint as stringified
 * query params. Useful when you need to support dynamic queryParams, but want to
 * use a pre-configured url string with your RSAA params.  Most useful when used
 * alongside `rsaaMetaMiddleware`, so that any specified `query` params are
 * available as `meta` properties of resulting request, success, & failure FSAs.
 *
 * ```
 * type Stringify = ({}) => string
 * type configureMetaQuery = (Stringify, string) => (RSAAAction) => RSAAAction
 * ```
 *
 * @alias module:api
 * @param {function} stringify function used to convert object to query string
 * @param {string} [queryKey=query] RSAA_META property key for query params
 * @return {function} function to process query params for RSAA
 */
export const configureMetaQuery = (stringify, queryKey = 'query') => (rsaa) => {
  const rsaaProps = rsaa[RSAA]
  const rsaaMeta = rsaa[RSAA_META]
  const { endpoint = '' } = rsaaProps
  const { [queryKey]: params } = rsaaMeta || {}
  if (!params) return rsaa

  const newEndpoint = applyQueryParams(endpoint, stringify(params))
  return {
    ...rsaa,
    [RSAA]: {
      ...rsaaProps,
      endpoint: newEndpoint
    }
  }
}

/**
 * Factory method to create a function that can produce RSAA objects. Uses
 * apiAction, apiActionWithMeta, and configureMetaQuery utils internally.
 *
 * ```
 * type Options = {
 *   defaultRsaa?: {},
 *   enableMeta?: bool,
 *   metaQuery?: ({}) => string
 * }
 * type configureApiAction = (Options) => ({}, {}?) => RSAAction
 * ```
 *
 * @alias module:api
 * @param {{}} [options]
 * @param {{}} [options.defaultRsaa={}] default RSAA params
 * @param {string} [options.enableMeta=false] enable use of RSAA_META property
 * @param {string} [options.metaQuery=null] query param stringify method. if null|undefined, ignore param processing
 * @return {function} function to process query params for RSAA
 */
export const configureApiAction = (options = {}) => {
  const {
    defaultRsaa = {},
    enableMeta = false,
    metaQuery = null
  } = options

  if (!enableMeta) {
    return (rsaaProps = {}) => apiAction({ ...defaultRsaa, ...rsaaProps })
  }

  const actionCreator = (rsaaProps = {}, rsaaMeta) => apiActionWithMeta(
    { ...defaultRsaa, ...rsaaProps },
    rsaaMeta
  )

  if (metaQuery) {
    const withMetaQuery = configureMetaQuery(metaQuery)
    return (...args) => withMetaQuery(actionCreator(...args))
  }

  return actionCreator
}
