import React, { useState, Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import Button from 'components/Button'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { validateFormValues } from 'utils/forms'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import { map, filter, some, noop } from 'lodash'
import {
  Typography,
  Grid,
  makeStyles,
  Box,
} from '@material-ui/core'
import Accordion from 'components/Accordion'
import Tab from 'components/Tabs/Tab'
import { useSelector, useDispatch } from 'react-redux'
import { TabContext, TabList } from '@material-ui/lab'
import { FieldText } from 'components/Fields'
import BaseCheckbox from 'components/Fields/BaseCheckbox'
import { getResiduoDestinacoes } from 'store/modules/residuoDestinacoes/residuoDestinacoes.selectors'
import { getMatrizResiduos, getIsSavingMatrizResiduos } from 'store/modules/matrizResiduos/matrizResiduos.selectors'
import { callSalvarNovasMatrizes, callSalvarMatrizResiduo, callExcluirMatrizResiduo } from 'store/modules/matrizResiduos'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { TIPO_USUARIO } from 'utils/constantes'

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
  screenTitle: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(5),
    fontSize: theme.typography.pxToRem(24),
  },
  cancelButton: {
    marginRight: 'auto',
  },
  group: {
    paddingTop: theme.spacing(2),
  },
  deleteIcon: {
    cursor: 'pointer',
    marginTop: theme.spacing(1),
    color: theme.palette.grey.blue,
    '&:hover': {
      color: theme.palette.grey['2E'],
    },
  },
  tabPanel: {
    width: '100%',
  },
  tabHeader: {
    width: '100%',
    borderBottom: `1px solid ${theme.palette.grey.main}`,
    '& .Mui-selected': {
      color: theme.palette.blue.main,
      '&.Mui-disabled': {
        color: theme.palette.grey.blue,
      },
    },
    '& .MuiTab-wrapper': {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(14),
    },
    '& .MuiTab-root': {
      minWidth: 'fit-content',
      margin: theme.spacing(0, 1.5),
    },
  },
  tabIndicator: {
    height: 4,
    transform: 'scaleX(0.8)',
    borderRadius: theme.spacing(2, 2, 0, 0),
    backgroundColor: theme.palette.blue.main,
  },
  tabList: {
    marginTop: -1,
    '& .MuiTab-root': {
      color: '#78839D',
      fontSize: '14px',
      minWidth: 'auto',
      '&.Mui-selected': {
        color: '#31ADE3',
      },
    },
  },
  border: {
    marginTop: '32px',
    border: '1px solid #C9CEDB',
    boxShadow: '0px 8px 32px rgba(15, 52, 68, 0.08)',
    borderRadius: '8px',
    padding: '0px 16px',
  },
  itemTitle: {
    fontFamily: 'Poppins',
    color: '#0F3444',
    fontWeight: '600',
    fontSize: '16px',
  },
  divider: {
    height: 1,
    width: 'calc(100% + 32px)',
    marginLeft: '-16px',
    backgroundColor: theme.palette.grey.main,
  },
  noResults: {
    marginTop: '32px',
  },
  lineResult: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey.main}`,
    '&:first-child': {
      marginTop: '32px',
    },
  },
  dividerField: {
    borderBottom: `1px solid ${theme.palette.grey.main}`,
    margin: '8px auto 8px',
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 20px)',
  },
  rowText: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(5),
    fontSize: theme.typography.pxToRem(14),
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '0 !important',
  },
  itemAlignRight: {
    maxWidth: '190px',
  },
}))

const Residuos = ({ paisId }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const dialog = useConfirmationDialog()

  const matrizResiduos = useSelector(getMatrizResiduos)
  const listaResiduoDestinacoes = useSelector(getResiduoDestinacoes)
  const isSaving = useSelector(getIsSavingMatrizResiduos)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const [tabSelecionado, setTabSelecionado] = useState('1')
  const [abrirTelaAdicionar, setAbrirTelaAdicionar] = useState(false)
  const [listaResiduoDestinacoesFiltrado, setListaResiduoDestinacoesFiltrado] = useState([])
  const [listaMatrizFiltrado, setListaMatrizFiltrado] = useState([])
  const [openItem, setOpenItem] = useState('')

  const desabilitarFormulario = Boolean(tipoUsuarioLogado === TIPO_USUARIO.ADMIN.OBSERVADOR)

  const handleExpand = (item) => {
    setOpenItem(openItem === item ? '' : item)
  }

  const handleSave = (matriz) => {
    dispatch(callSalvarMatrizResiduo(matriz))
  }

  const handleSalvarNovasMatrizes = () => {
    const listaSalvar = filter(listaResiduoDestinacoesFiltrado, { checked: true })
      .map((destinacao) => ({
        residuoDestinacaoId: destinacao.id,
        residuoClasseId: tabSelecionado,
        paisId,
      }))

    dispatch(callSalvarNovasMatrizes(listaSalvar))
    setAbrirTelaAdicionar(false)
    setListaResiduoDestinacoesFiltrado([])
  }

  const handleTabChange = (e, newValue) => {
    setAbrirTelaAdicionar(false)
    setListaResiduoDestinacoesFiltrado([])
    setTabSelecionado(newValue)
  }

  const onCancelAddItem = () => {
    setAbrirTelaAdicionar(false)
    setListaResiduoDestinacoesFiltrado([])
  }

  const handleAddResiduo = () => {
    const lista = []
    listaResiduoDestinacoes.forEach((residuoDestinacao) => {
      if (!some(matrizResiduos, ({
        residuoClasseId: +tabSelecionado,
        residuoDestinacaoId: residuoDestinacao.id,
      }))) {
        lista.push({ ...residuoDestinacao, checked: false })
      }
    })

    setListaResiduoDestinacoesFiltrado(lista)
    setAbrirTelaAdicionar(true)
  }

  const handleCheckBox = (index) => {
    setListaResiduoDestinacoesFiltrado(listaResiduoDestinacoesFiltrado.map(
      (residuo, i) => (i === index ? { ...residuo, checked: !residuo.checked } : residuo),
    ))
  }

  const handleDelete = (matriz) => {
    dialog.showConfirmation({
      title: t('dialogs.confirmarExclusao'),
      message: t('dialogs.excluirMatrizResiduo', {
        residuo: t(matriz.residuoDestinacao.destinacao),
        classe: matriz.residuoClasse.classe,
      }),
    }).then((result) => {
      if (result) {
        dispatch(callExcluirMatrizResiduo({ id: matriz.id }))
      }
      return null
    })
  }

  useEffect(() => {
    const lista = filter(matrizResiduos, { residuoClasse: { id: +tabSelecionado } })
    setListaMatrizFiltrado(lista)
  }, [matrizResiduos, tabSelecionado])

  const tabs = [
    { titulo: 'paisesCalculos.residuoClasseI', id: '1' },
    { titulo: 'paisesCalculos.residuoClasseII', id: '2' },
  ]

  return (
    <Form
      onSubmit={noop}
      validationSchema={validateFormValues(yup.object().shape({
        temas: yup.array().of(
          yup.object().shape({
            identificador: yup.string().required(),
            nome: yup.string().required(),
            fator: yup.number().required(),
          }),
        ),
      }))}
      initialValues={{
        matrizResiduos: !matrizResiduos.length ? [] : listaMatrizFiltrado,
      }}
      render={({ handleSubmit, form, values, pristine }) => (
        <form onSubmit={handleSubmit}>
          <TabContext value={tabSelecionado}>
            <div className={classes.sectionTitle}>
              <Typography className={classes.screenTitle}>
                {t('paisesCalculos.residuos')}
              </Typography>
            </div>
            <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.tabHeader}>
              <TabList
                onChange={handleTabChange}
                classes={{
                  root: classes.tabList,
                  indicator: classes.tabIndicator,
                }}
              >
                {map(tabs, ({ titulo, id }) => (
                  <Tab
                    label={t(titulo)}
                    value={id}
                    key={id}
                  />
                ))}
              </TabList>
              <Button
                onClick={handleAddResiduo}
                disabled={abrirTelaAdicionar || desabilitarFormulario}
              >
                {t('inputs.adicionarDestinacao')}
              </Button>

            </Box>
            {abrirTelaAdicionar && listaResiduoDestinacoesFiltrado.length > 0 && (
              <Grid container direction="row">
                {listaResiduoDestinacoesFiltrado.map((destinacao, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    key={destinacao.destinacao}
                    className={classes.lineResult}
                  >
                    {t(destinacao.destinacao)}
                    <BaseCheckbox
                      onChange={() => handleCheckBox(index)}
                      checked={destinacao.checked}
                    />
                  </Grid>
                ))}

                <Grid justifyContent="flex-end" spacing={2} container>
                  <Grid className={classes.cancelButton} item>
                    <Button variant="danger" onClick={onCancelAddItem}>
                      {t('inputs.cancelar')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      loading={isSaving}
                      disabled={
                        !some(listaResiduoDestinacoesFiltrado, ({ checked: true })) ||
                        desabilitarFormulario
                      }
                      onClick={handleSalvarNovasMatrizes}
                    >
                      {t('inputs.salvar')}
                    </Button>
                  </Grid>
                </Grid>

              </Grid>
            )}
            {abrirTelaAdicionar && listaResiduoDestinacoesFiltrado.length === 0 && (
              <Typography className={classes.noResults}>
                {t('paisesCalculos.semDestinacoesParaSelecionar')}
              </Typography>
            )}
            <Grid container direction="row">
              <div className={classes.border}>
                {values.matrizResiduos.map((matriz, index) => (
                  <Fragment key={`${matriz.id}${matriz.residuoClasseId}${matriz.residuoDestinacaoId}${matriz.updatedAt}`}>
                    <Accordion
                      hideButton
                      variant="no-borders"
                      classes={{ title: classes.itemTitle }}
                      title={t(matriz.residuoDestinacao.destinacao)}
                      expanded={index === openItem}
                      onExpand={() => handleExpand(index)}
                      onDelete={() => handleDelete(matriz)}
                      disableDelete={desabilitarFormulario}
                    >
                      <Grid
                        container
                        spacing={3}
                        alignItems="center"
                        className={classes.group}
                      >
                        <Grid container spacing={2} className={classes.dividerField}>
                          <Grid item xs={9}>
                            <Typography className={classes.rowText}>
                              {t('inputs.possibilidadeDeGeracao')}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} className={classes.alignRight}>
                            <FieldText
                              name={`matrizResiduos.${index}.possibilidadeGeracao`}
                              className={classes.itemAlignRight}
                              InputProps={{
                                inputProps: {
                                  style: { textAlign: 'center' },
                                },
                              }}
                              disabled={desabilitarFormulario}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} className={classes.dividerField}>
                          <Grid item xs={9}>
                            <Typography className={classes.rowText}>
                              {t('inputs.degradacaoDaArea')}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} className={classes.alignRight}>
                            <FieldText
                              name={`matrizResiduos.${index}.degradacaoArea`}
                              className={classes.itemAlignRight}
                              InputProps={{
                                inputProps: {
                                  style: { textAlign: 'center' },
                                },
                              }}
                              disabled={desabilitarFormulario}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} className={classes.dividerField}>
                          <Grid item xs={9}>
                            <Typography className={classes.rowText}>
                              {t('inputs.reducaoDeVolume')}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} className={classes.alignRight}>
                            <FieldText
                              name={`matrizResiduos.${index}.reducaoVolume`}
                              className={classes.itemAlignRight}
                              InputProps={{
                                inputProps: {
                                  style: { textAlign: 'center' },
                                },
                              }}
                              disabled={desabilitarFormulario}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} className={classes.dividerField}>
                          <Grid item xs={9}>
                            <Typography className={classes.rowText}>
                              {t('inputs.inflamabilidade')}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} className={classes.alignRight}>
                            <FieldText
                              name={`matrizResiduos.${index}.inflamabilidade`}
                              className={classes.itemAlignRight}
                              InputProps={{
                                inputProps: {
                                  style: { textAlign: 'center' },
                                },
                              }}
                              disabled={desabilitarFormulario}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} className={classes.dividerField}>
                          <Grid item xs={9}>
                            <Typography className={classes.rowText}>
                              {t('inputs.reducaoDePericulosidade')}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} className={classes.alignRight}>
                            <FieldText
                              name={`matrizResiduos.${index}.reducaoPericulosidade`}
                              className={classes.itemAlignRight}
                              InputProps={{
                                inputProps: {
                                  style: { textAlign: 'center' },
                                },
                              }}
                              disabled={desabilitarFormulario}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} className={classes.dividerField}>
                          <Grid item xs={9}>
                            <Typography className={classes.rowText}>
                              {t('inputs.corrosividade')}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} className={classes.alignRight}>
                            <FieldText
                              name={`matrizResiduos.${index}.corrosividade`}
                              className={classes.itemAlignRight}
                              InputProps={{
                                inputProps: {
                                  style: { textAlign: 'center' },
                                },
                              }}
                              disabled={desabilitarFormulario}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} className={classes.dividerField}>
                          <Grid item xs={9}>
                            <Typography className={classes.rowText}>
                              {t('inputs.geracaoDeNovosProdutos')}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} className={classes.alignRight}>
                            <FieldText
                              name={`matrizResiduos.${index}.geracaoNovosProdutos`}
                              className={classes.itemAlignRight}
                              InputProps={{
                                inputProps: {
                                  style: { textAlign: 'center' },
                                },
                              }}
                              disabled={desabilitarFormulario}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} className={classes.dividerField}>
                          <Grid item xs={9}>
                            <Typography className={classes.rowText}>
                              {t('inputs.reatividade')}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} className={classes.alignRight}>
                            <FieldText
                              name={`matrizResiduos.${index}.reatividade`}
                              className={classes.itemAlignRight}
                              InputProps={{
                                inputProps: {
                                  style: { textAlign: 'center' },
                                },
                              }}
                              disabled={desabilitarFormulario}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} className={classes.dividerField}>
                          <Grid item xs={9}>
                            <Typography className={classes.rowText}>
                              {t('inputs.reaproveitamentoEnergetico')}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} className={classes.alignRight}>
                            <FieldText
                              name={`matrizResiduos.${index}.reaproveitamentoEnergetico`}
                              className={classes.itemAlignRight}
                              InputProps={{
                                inputProps: {
                                  style: { textAlign: 'center' },
                                },
                              }}
                              disabled={desabilitarFormulario}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} className={classes.dividerField}>
                          <Grid item xs={9}>
                            <Typography className={classes.rowText}>
                              {t('inputs.patogenidade')}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} className={classes.alignRight}>
                            <FieldText
                              name={`matrizResiduos.${index}.patogenicidade`}
                              className={classes.itemAlignRight}
                              InputProps={{
                                inputProps: {
                                  style: { textAlign: 'center' },
                                },
                              }}
                              disabled={desabilitarFormulario}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} className={classes.dividerField}>
                          <Grid item xs={9}>
                            <Typography className={classes.rowText}>
                              {t('inputs.reducaoDemandaEnergetica')}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} className={classes.alignRight}>
                            <FieldText
                              name={`matrizResiduos.${index}.reducaoDemandaEnergetica`}
                              className={classes.itemAlignRight}
                              InputProps={{
                                inputProps: {
                                  style: { textAlign: 'center' },
                                },
                              }}
                              disabled={desabilitarFormulario}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} className={classes.dividerField}>
                          <Grid item xs={9}>
                            <Typography className={classes.rowText}>
                              {t('inputs.toxidade')}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} className={classes.alignRight}>
                            <FieldText
                              name={`matrizResiduos.${index}.toxicidade`}
                              className={classes.itemAlignRight}
                              InputProps={{
                                inputProps: {
                                  style: { textAlign: 'center' },
                                },
                              }}
                              disabled={desabilitarFormulario}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} className={classes.dividerField}>
                          <Grid item xs={9}>
                            <Typography className={classes.rowText}>
                              {t('inputs.geracaoEfluentes')}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} className={classes.alignRight}>
                            <FieldText
                              name={`matrizResiduos.${index}.geracaoEfluentes`}
                              className={classes.itemAlignRight}
                              InputProps={{
                                inputProps: {
                                  style: { textAlign: 'center' },
                                },
                              }}
                              disabled={desabilitarFormulario}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} className={classes.dividerField}>
                          <Grid item xs={9}>
                            <Typography className={classes.rowText}>
                              {t('inputs.somatorio')}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} className={classes.alignRight}>
                            <FieldText
                              name={`matrizResiduos.${index}.somatorio`}
                              className={classes.itemAlignRight}
                              InputProps={{
                                inputProps: {
                                  style: { textAlign: 'center' },
                                },
                              }}
                              disabled={desabilitarFormulario}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} className={classes.dividerField}>
                          <Grid item xs={9}>
                            <Typography className={classes.rowText}>
                              {t('inputs.geracaoGases')}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} className={classes.alignRight}>
                            <FieldText
                              name={`matrizResiduos.${index}.geracaoGases`}
                              className={classes.itemAlignRight}
                              InputProps={{
                                inputProps: {
                                  style: { textAlign: 'center' },
                                },
                              }}
                              disabled={desabilitarFormulario}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} className={classes.dividerField}>
                          <Grid item xs={9}>
                            <Typography className={classes.rowText}>
                              {t('inputs.pontuacaodoProcesso')}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} className={classes.alignRight}>
                            <FieldText
                              name={`matrizResiduos.${index}.pontuacaoProcesso`}
                              className={classes.itemAlignRight}
                              InputProps={{
                                inputProps: {
                                  style: { textAlign: 'center' },
                                },
                              }}
                              disabled={desabilitarFormulario}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} className={classes.dividerField}>
                          <Grid item xs={9}>
                            <Typography className={classes.rowText}>
                              {t('inputs.consumoRecursosNaturais')}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} className={classes.alignRight}>
                            <FieldText
                              name={`matrizResiduos.${index}.consumoRecursosNaturais`}
                              className={classes.itemAlignRight}
                              InputProps={{
                                inputProps: {
                                  style: { textAlign: 'center' },
                                },
                              }}
                              disabled={desabilitarFormulario}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} className={classes.dividerField}>
                          <Grid item xs={9}>
                            <Typography className={classes.rowText}>
                              ID
                            </Typography>
                          </Grid>
                          <Grid item xs={3} className={classes.alignRight}>
                            <FieldText
                              name={`matrizResiduos.${index}.indiceImpacto`}
                              className={classes.itemAlignRight}
                              InputProps={{
                                inputProps: {
                                  style: { textAlign: 'center' },
                                },
                              }}
                              disabled={desabilitarFormulario}
                            />
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid
                            container
                            direction="row"
                            spacing={2}
                            justifyContent="space-between"
                            className={classes.normalTop}
                          >
                            <Grid item className={classes.deleteButton}>
                              <Button
                                variant="danger"
                                onClick={() => { form.reset(); handleExpand('') }}
                              >
                                {t('inputs.cancelar')}
                              </Button>

                            </Grid>
                            <Grid item>
                              <Button
                                loading={isSaving}
                                type="submit"
                                disabled={pristine || desabilitarFormulario}
                                onClick={() => handleSave(matriz)}
                              >
                                {t('inputs.salvar')}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Accordion>
                    <div className={classes.divider} />
                  </Fragment>
                ))}
              </div>
            </Grid>
          </TabContext>
        </form>
      )}
    />
  )
}

Residuos.propTypes = {
  paisId: PropTypes.number,
}

export default Residuos
