import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { LOCATION_CHANGE } from 'connected-react-router'
import { API_GET_EMPRESA, API_LISTAR_EMPRESAS, API_CRIAR_EMPRESA, API_EXCLUIR_EMPRESA, API_ATUALIZAR_EMPRESA } from 'store/modules/api/empresas.actions'
import { CALL_NOVA_EMPRESA, CALL_SELECIONAR_EMPRESA_FILTRO } from 'store/modules/empresas/empresas.actions'
import { matchLocation } from 'utils/location'
import { filter, map } from 'lodash'

const lista = (state = [], { type, payload }) => {
  switch (type) {
    case API_LISTAR_EMPRESAS.success: return payload
    case API_CRIAR_EMPRESA.success: return [...state, payload]
    case API_EXCLUIR_EMPRESA.success: return filter(state, (o) => o.id !== payload)
    case API_ATUALIZAR_EMPRESA.success:
      return map(state, (o) => (o.id === payload.id ? payload : o))
    default: return state
  }
}

const selecionado = (state = null, { type, payload }) => {
  switch (type) {
    case API_GET_EMPRESA.success: return payload
    case CALL_NOVA_EMPRESA: return null
    case LOCATION_CHANGE:
      return (matchLocation(payload, '/empresas/:id') || matchLocation(payload, '/dadosEmpresa')) ? state : null
    case API_ATUALIZAR_EMPRESA.success: return payload
    default: return state
  }
}

const selecionadaFiltro = (state = null, { type, payload }) => {
  switch (type) {
    case CALL_SELECIONAR_EMPRESA_FILTRO: return payload
    default: return state
  }
}

const isCarregandoLista = loadingReducer(false, API_LISTAR_EMPRESAS)
const isCarregandoEmpresa = loadingReducer(false, API_GET_EMPRESA)
const isSaving = loadingReducer(false, {
  request: [
    API_CRIAR_EMPRESA.request,
    API_ATUALIZAR_EMPRESA.request,
  ],
  success: [
    API_CRIAR_EMPRESA.success,
    API_ATUALIZAR_EMPRESA.success,
  ],
  failure: [
    API_CRIAR_EMPRESA.failure,
    API_ATUALIZAR_EMPRESA.failure,
  ],
})
export default combineReducers({
  lista,
  isCarregandoLista,
  isCarregandoEmpresa,
  selecionado,
  selecionadaFiltro,
  isSaving,
})
