import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import {
  apiCriarEspecieSilvicultura,
  apiExcluirEspecieSilvicultura,
  apiAtualizarEspecieSilvicultura,
} from 'store/modules/api/especieSilvicultura.actions'
import {
  CALL_CRIAR_ESPECIE_SILVICULTURA,
  CALL_EXCLUIR_ESPECIE_SILVICULTURA,
  CALL_ATUALIZAR_ESPECIE_SILVICULTURA,
} from 'store/modules/especieSilvicultura/especieSilvicultura.actions'

const onCallCriarEspecieSilvicultura = (action$) => action$.pipe(
  ofType(CALL_CRIAR_ESPECIE_SILVICULTURA),
  debounceTime(250),
  map(({ payload }) => apiCriarEspecieSilvicultura(payload)),
)

const onCallExcluirEspecieSilvicultura = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_ESPECIE_SILVICULTURA),
  debounceTime(250),
  map(({ payload }) => apiExcluirEspecieSilvicultura(payload)),
)

const onCallAtualizarEspecieSilvicultura = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_ESPECIE_SILVICULTURA),
  debounceTime(250),
  map(({ payload }) => apiAtualizarEspecieSilvicultura(payload)),
)

export default combineEpics(
  onCallCriarEspecieSilvicultura,
  onCallExcluirEspecieSilvicultura,
  onCallAtualizarEspecieSilvicultura,
)
