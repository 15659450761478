import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import {
  API_FETCH_USUARIO_AUDITOR,
  API_GET_USUARIO_AUDITOR,
  API_CRIAR_USUARIO,
  API_EXCLUIR_USUARIO,
  API_ATUALIZAR_USUARIO,
} from 'store/modules/api/usuariosAuditor.actions'
import { CALL_NOVO_USUARIO_AUDITOR } from 'store/modules/usuariosAuditor/usuariosAuditor.actions'
import { filter, map } from 'lodash'

const lista = (state = [], { type, payload }) => {
  switch (type) {
    case API_FETCH_USUARIO_AUDITOR.success: return payload
    case API_CRIAR_USUARIO.success: return [...state, payload]
    case API_EXCLUIR_USUARIO.success:
      return filter(state, (u) => u.id !== Number(payload))
    case API_ATUALIZAR_USUARIO.success:
      return map(state, (u) => (u.id === payload.id ? payload : u))
    default: return state
  }
}

const selecionado = (state = null, { type, payload }) => {
  switch (type) {
    case API_GET_USUARIO_AUDITOR.success: return payload
    case CALL_NOVO_USUARIO_AUDITOR: return null
    case API_ATUALIZAR_USUARIO.success: return payload
    case API_EXCLUIR_USUARIO.success: return state?.id === payload ? null : state
    default: return state
  }
}

const isCarregandoLista = loadingReducer(false, API_FETCH_USUARIO_AUDITOR)
const isCarregandoUsuario = loadingReducer(false, API_GET_USUARIO_AUDITOR)

export default combineReducers({
  lista,
  isCarregandoLista,
  isCarregandoUsuario,
  selecionado,
})
