import { combineEpics, ofType } from 'redux-observable'
import { get, startsWith } from 'lodash'
import { map, debounceTime, tap, filter, mergeMap, ignoreElements, mergeMapTo, mapTo } from 'rxjs/operators'
import { push, LOCATION_CHANGE } from 'connected-react-router'
import {
  apiAuthenticate,
  API_AUTHENTICATE,
  apiValidate,
  API_VALIDATE,
  apiResetPassword,
  apiResetPasswordRequest,
} from 'store/modules/api/auth.actions'
import { enqueueSnackbar } from 'store/modules/notifications'
import { localStore } from 'store/localStore'
import {
  USER_AUTHENTICATE,
  USER_LOGOUT,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD,
} from 'store/modules/auth/auth.actions'
import { API_ATUALIZAR_USUARIO } from 'store/modules/api/usuarios.actions'
import { API_ATUALIZAR_EMPRESA } from 'store/modules/api/empresas.actions'
import { apiListarEstados } from 'store/modules/api/estados.actions'
import { apiListarMunicipios } from 'store/modules/api/municipios.actions'
import { apiListarSetores } from 'store/modules/api/setores.actions'
import { apiListarTipoAtividades } from 'store/modules/api/tipoAtividades.actions'
import { apiListarTipoUsuarios } from 'store/modules/api/tipoUsuarios.actions'
import { apiListarConversoesMassa } from 'store/modules/api/conversoesMassa.actions'
import { apiListarConversoesTempo } from 'store/modules/api/conversoesTempo.actions'
import { apiListarConversoesEnergia } from 'store/modules/api/conversoesEnergia.actions'
import { apiListarConversoesArea } from 'store/modules/api/conversoesArea.actions'
import { apiListarConversoesVolume } from 'store/modules/api/conversoesVolume.actions'
import { apiListarMoedas } from 'store/modules/api/moedas.actions'
import { apiListarEcorregioes } from 'store/modules/api/ecorregioes.actions'
import { apiListarBiomas } from 'store/modules/api/biomas.actions'
import { apiListarPaises } from 'store/modules/api/paises.actions'
import { apiListarLicencas } from 'store/modules/api/licencas.actions'
import { getDadosUsuario, getIsAuthenticated, getEmpresaUsuario } from 'store/modules/auth/auth.selectors'
import { apiListarGrupos } from 'store/modules/api/grupos.actions'
import { apiListarTemas } from 'store/modules/api/temas.actions'
import { apiListarIdiomas } from 'store/modules/api/idiomas.actions'
import { apiListarUsuarioEmpresasAuditorias } from 'store/modules/api/auditor.actions'
import { VIEW_CLIENT, VIEW_ADMIN, VIEW_AUDITOR } from 'utils/constantes'

const listarDadosGeraisUsuario = (state$) => (source) => source.pipe(
  mergeMap(({ payload }) => {
    let data = null

    if (state$) {
      const paisId = state$.value.auth.usuario.empresa.paisId ||
          state$.value.auth.usuario.empresa.pais.id

      data = { paisId }
    } else {
      data = { paisId: payload.dadosUsuario.empresa.pais.id }
    }

    return [
      apiListarEstados(),
      apiListarSetores(),
      apiListarTipoAtividades(data),
      apiListarTipoUsuarios(),
      apiListarConversoesMassa(),
      apiListarConversoesTempo(),
      apiListarConversoesEnergia(),
      apiListarConversoesArea(),
      apiListarConversoesVolume(),
      apiListarMoedas(),
      apiListarEcorregioes(data),
      apiListarBiomas(data),
      apiListarPaises(),
      apiListarLicencas(),
      apiListarGrupos(data),
      apiListarTemas(data),
      apiListarMunicipios(),
      apiListarIdiomas(),
    ]
  }),
)

const listarDadosGeraisAdmin = () => (source) => source.pipe(
  mergeMap(() => [
    apiListarPaises(),
    apiListarTipoUsuarios(),
    apiListarLicencas(),
    apiListarMoedas(),
    apiListarIdiomas(),
  ]),
)

const listarDadosGeraisAuditor = (usuarioId) => (source) => source.pipe(
  mergeMap(({ payload }) => {
    let data = null

    if (!usuarioId) {
      data = { usuarioId: payload.dadosUsuario.id }
    } else {
      data = { usuarioId }
    }
    return [
      apiListarUsuarioEmpresasAuditorias(data),
      apiListarIdiomas(),
    ]
  }),
)

const onAuthenticate = (action$) => action$.pipe(
  ofType(USER_AUTHENTICATE),
  debounceTime(250),
  map(({ payload }) => apiAuthenticate(payload)),
)

const onPasswordResetRequest = (action$) => action$.pipe(
  ofType(USER_RESET_PASSWORD_REQUEST),
  debounceTime(250),
  map(({ payload }) => apiResetPasswordRequest(payload)),
)

const onPasswordReset = (action$) => action$.pipe(
  ofType(USER_RESET_PASSWORD),
  debounceTime(250),
  map(({ payload }) => apiResetPassword(payload)),
)

const onAuthenticateSuccess = (action$, state$) => action$.pipe(
  ofType(API_AUTHENTICATE.success),
  mergeMapTo([() => localStore.saveState(state$.value), push('/')]),
)

const onUpdateUsuarioLogado = (action$, state$) => action$.pipe(
  ofType(API_ATUALIZAR_USUARIO.success),
  filter(({ payload }) => payload.id === getDadosUsuario(state$.value).id),
  map(() => localStore.saveState(state$.value)),
  ignoreElements(),
)

const onUpdateEmpresaUsuarioLogado = (action$, state$) => action$.pipe(
  ofType(API_ATUALIZAR_EMPRESA.success),
  filter(({ payload }) => payload?.id === getEmpresaUsuario(state$.value)?.id),
  map(() => localStore.saveState(state$.value)),
  ignoreElements(),
)

const onAuthenticateSuccessLoadData = (action$, state$) => action$.pipe(
  ofType(API_AUTHENTICATE.success),
  filter(() => VIEW_CLIENT.includes(getDadosUsuario(state$.value).tipoUsuario.id)),
  listarDadosGeraisUsuario(),
)

const onAuthenticateSuccessLoadDataAdmin = (action$) => action$.pipe(
  ofType(API_AUTHENTICATE.success),
  filter(({ payload }) => VIEW_ADMIN.includes(payload.dadosUsuario.tipoUsuario.id)),
  listarDadosGeraisAdmin(),
)

const onAuthenticateSuccessLoadDataAuditor = (action$) => action$.pipe(
  ofType(API_AUTHENTICATE.success),
  filter(({ payload }) => VIEW_AUDITOR.includes(payload.dadosUsuario.tipoUsuario.id)),
  listarDadosGeraisAuditor(),
)

const onLogout = (action$) => action$.pipe(
  ofType(USER_LOGOUT),
  tap(() => localStorage.removeItem('state')),
  mapTo(push('/login')),
)

const onAuthenticateFailure = (action$) => action$.pipe(
  ofType(API_AUTHENTICATE.failure),
  map(({ payload = {} }) => enqueueSnackbar({
    message: get(payload, 'response.message'),
    options: { key: new Date().getTime() + Math.random(), variant: 'warning' },
  })),
)

const onRefresh = (action$, state$) => action$.pipe(
  ofType(LOCATION_CHANGE),
  filter(({ payload }) => payload.isFirstRendering &&
    getIsAuthenticated(state$.value) &&
    payload.location.pathname !== '/login' &&
    payload.location.pathname !== '/esqueciSenha' &&
    !startsWith(payload.location.pathname, '/alterarSenha/')),
  map(() => apiValidate()),
)

const onValidateSuccess = (action$, state$) => action$.pipe(
  ofType(API_VALIDATE.success),
  filter(() => VIEW_CLIENT.includes(getDadosUsuario(state$.value)?.tipoUsuario?.id)),
  listarDadosGeraisUsuario(state$),
)

const onValidateSuccessLoadDataAdmin = (action$, state$) => action$.pipe(
  ofType(API_VALIDATE.success),
  filter(() => VIEW_ADMIN.includes(getDadosUsuario(state$.value).tipoUsuario?.id)),
  listarDadosGeraisAdmin(),
)

const onValidateSuccessLoadDataAuditor = (action$, state$) => action$.pipe(
  ofType(API_VALIDATE.success),
  filter(() => VIEW_AUDITOR.includes(getDadosUsuario(state$.value).tipoUsuario?.id)),
  listarDadosGeraisAuditor(getDadosUsuario(state$.value)?.id),
)

export default combineEpics(
  onUpdateUsuarioLogado,
  onAuthenticate,
  onAuthenticateSuccess,
  onAuthenticateFailure,
  onLogout,
  onRefresh,
  onAuthenticateSuccessLoadData,
  onValidateSuccess,
  onPasswordResetRequest,
  onPasswordReset,
  onAuthenticateSuccessLoadDataAdmin,
  onValidateSuccessLoadDataAdmin,
  onUpdateEmpresaUsuarioLogado,
  onAuthenticateSuccessLoadDataAuditor,
  onValidateSuccessLoadDataAuditor,
)
