import { combineEpics } from 'redux-observable'
import { filter, map, mergeMap } from 'rxjs/operators'
import { get, startsWith, endsWith } from 'lodash'
import { userLogout } from 'store/modules/auth'
import { enqueueSnackbar } from 'store/modules/notifications'

const onJwtExpire = (action$) => action$.pipe(
  filter(({ error, payload }) => (
    error &&
    (get(payload, 'response.message') === 'jwt expired' ||
    get(payload, 'response.message') === 'jwt malformed' ||
    get(payload, 'response.message') === 'invalid signature')
  )),
  mergeMap(() => [
    userLogout(),
    enqueueSnackbar({
      message: 'mensagens.loginExpirado',
      options: { variant: 'error', autoHideDuration: null, dismissible: true, key: '401 - Unauthorized' },
    }),
  ]),
)

const onApiFailure = (action$) => action$.pipe(
  filter(({ type, error, meta, payload }) => (
    error &&
    startsWith(type, 'api/') &&
    (get(meta, 'defaultError') === undefined || get(meta, 'defaultError')) &&
    !['jwt expired', 'jwt malformed', 'invalid signature'].includes(payload?.response?.message)
  )),
  map(({ payload = {} }) => {
    const { message: statusMessage, response = {} } = payload
    return enqueueSnackbar({
      message: `(${statusMessage}) ${response.message ? response.message : ''}`,
      options: { variant: 'error', autoHideDuration: null, dismissible: true, key: statusMessage },
    })
  }),
)

const onApiSuccess = (action$) => action$.pipe(
  filter(({ type, meta }) => startsWith(type, 'api/') && endsWith(type, '/Success') && get(meta, 'successNotification')),
  map(({ meta: { successNotification } }) => (
    enqueueSnackbar({
      message: successNotification,
      options: { variant: 'success' },
    })
  )),
)

export default combineEpics(
  onJwtExpire,
  onApiFailure,
  onApiSuccess,
)
