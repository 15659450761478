import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import BaseCheckbox from './BaseCheckbox'

const Checkbox = ({ name, onChange, ...props }) => {
  const handleChange = (input) => ({ target }) => {
    input.onChange(target.checked)
    if (onChange) onChange(target.checked)
  }

  return (
    <Field
      name={name}
      type="checkbox"
      render={({ input }) => (
        <BaseCheckbox
          {...input}
          {...props}
          onChange={handleChange(input)}
        />
      )}
    />
  )
}

Checkbox.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
}

export default Checkbox
