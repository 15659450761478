import { Grid, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import Accordion from 'components/Accordion'
import Button from 'components/Button'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import { FieldText } from 'components/Fields'
import PropTypes from 'prop-types'
import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { callAtualizarEspecieSilvicultura, callCriarEspecieSilvicultura, callExcluirEspecieSilvicultura } from 'store/modules/especieSilvicultura'
import { getEspecieSilvicultura, getIsSavingEspecieSilvicultura, getIsUpdatingEspecieSilvicultura } from 'store/modules/especieSilvicultura/especieSilvicultura.selectors'
import { TIPO_USUARIO } from 'utils/constantes'
import { validateFormValues } from 'utils/forms'
import * as yup from 'yup'

const useStyles = makeStyles((theme) => ({
  rowText: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(5),
    fontSize: theme.typography.pxToRem(14),
  },
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    width: (props) => props.fullWidth && '100%',
  },
  hidden: {
    display: 'none',
  },
  formAdd: {
    transition: '0.20s ease-out',
    maxHeight: 0,
    overflow: 'hidden',
  },
  openFormAdd: {
    marginTop: '-10px',
    paddingTop: 10,
    maxHeight: '1300px',
    transition: '0.25s ease-in',
    marginBottom: '32px',
  },
  normalTop: {
    marginTop: '16px',
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '0 !important',
  },
  itemAlignRight: {
    maxWidth: '190px',
  },
  listItems: {
    border: '1px solid #C9CEDB',
    boxShadow: '0px 8px 32px rgba(15, 52, 68, 0.08)',
    borderRadius: '8px',
    padding: '0px 16px',
  },
  itemTitle: {
    fontFamily: 'Poppins',
    color: '#0F3444',
    fontWeight: '600',
    fontSize: '16px',
  },
  divider: {
    height: 1,
    width: 'calc(100% + 32px)',
    marginLeft: '-16px',
    backgroundColor: theme.palette.grey.main,
  },
  dividerField: {
    borderBottom: `1px solid ${theme.palette.grey.main}`,
    margin: '8px 0',
  },
  itemsForm: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-between',
  },
  itemFormField: {
    width: 'calc( 50% - 4px )',
  },
}))

const TabEspecies = forwardRef(({ paisId, controlAdded, setControlAdded }, ref) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const dialog = useConfirmationDialog()

  const isSaving = useSelector(getIsSavingEspecieSilvicultura)
  const isUpdating = useSelector(getIsUpdatingEspecieSilvicultura)
  const EspecieSilvicultura = useSelector(getEspecieSilvicultura)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const desabilitarFormulario = Boolean(tipoUsuarioLogado === TIPO_USUARIO.ADMIN.OBSERVADOR)

  const [data, setData] = useState(EspecieSilvicultura)

  const validation = yup.object({
    especie: yup.string().required(),
    fator: yup.number().required(),
  })

  const handleUpdate = (values) => {
    const { id } = values
    const payload = ({ ...values, paisId })
    delete payload.id
    dispatch(callAtualizarEspecieSilvicultura({ id, especieSilvicultura: payload }))
  }

  const handleSave = (values, form) => {
    const payload = ({ ...values, paisId })
    dispatch(callCriarEspecieSilvicultura(payload))
    form.reset()
    Object.keys(values).forEach((key) => {
      form.resetFieldState(key)
    })
  }

  const handleDelete = (id, especie) => dialog.showConfirmation({
    title: t('dialogs.confirmarExclusao'),
    message: t('dialogs.excluirEspecie', {
      especie,
    }),
  }).then((result) => {
    if (result) {
      dispatch(callExcluirEspecieSilvicultura({ id }))
    }
    return null
  })

  const [openItem, setOpenItem] = useState('')

  const handleExpand = (item) => {
    setOpenItem(openItem === item ? '' : item)
  }

  useImperativeHandle(
    ref,
    () => ({
      handleButtonAdded() {
        setControlAdded(true)
        handleExpand('')
      },
    }),
  )

  const clearFields = (form, values, type) => {
    if (type === 'add') {
      setControlAdded(!controlAdded)
    }
    form.reset()
    Object.keys(values).forEach((key) => {
      form.resetFieldState(key)
    })
  }

  const formFields = [
    {
      name: 'especie',
      label: t('inputs.especie'),
      type: 'text',
      required: true,
    },
    {
      name: 'fator',
      label: t('inputs.Fator'),
      type: 'number',
      required: true,
    },
  ]

  const FieldsForm = () => (
    <div className={classes.itemsForm}>
      {
      formFields.map((elem, index) => (
        <FieldText
          required={elem.required}
          label={elem.label}
          name={elem.name}
          key={index}
          type={elem.type}
          className={classes.itemFormField}
          disabled={desabilitarFormulario}
        />
      ))
    }
    </div>
  )
  const ItemForm = (position) => {
    const itemEspecie = [data[position]]
    return (
      <Form
        onSubmit={handleUpdate}
        validate={validateFormValues(validation)}
        initialValues={{
          especie: itemEspecie[0]?.especie ?? '',
          fator: itemEspecie[0]?.fator ?? '',
          id: itemEspecie[0]?.id ?? '',
        }}
        render={({ handleSubmit, form, pristine, valid }) => (
          <form onSubmit={handleSubmit}>
            <Accordion
              hideButton
              variant="no-borders"
              classes={{ title: classes.itemTitle }}
              title={itemEspecie[0]?.especie}
              onDelete={() => {
                handleDelete(
                  itemEspecie[0]?.id,
                  itemEspecie[0]?.nome,
                )
                handleExpand('')
              }}
              expanded={position === openItem}
              onExpand={() => handleExpand(position)}
              disableDelete={desabilitarFormulario}
            >
              <Grid container>
                { FieldsForm() }
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    className={classes.normalTop}
                  >
                    <Grid item className={classes.deleteButton}>
                      <Button
                        variant="danger"
                        onClick={() => { form.reset(); handleExpand('') }}
                      >
                        {t('inputs.cancelar')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        disabled={Boolean(pristine || !valid) || desabilitarFormulario}
                        loading={isUpdating}
                      >
                        {t('inputs.salvar')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Accordion>
            <div className={classes.divider} />
          </form>
        )}
      />
    )
  }

  useEffect(() => {
    setData(EspecieSilvicultura)
  }, [EspecieSilvicultura])

  return (
    <>
      <div
        className={clsx(
          classes.formAdd,
          controlAdded && classes.openFormAdd,
        )}
      >
        <Form
          onSubmit={handleSave}
          initialValues={{
            especie: '',
            fator: '',
          }}
          validate={validateFormValues(validation)}
          render={({ handleSubmit, form, values, valid }) => (
            <form onSubmit={handleSubmit}>
              <Grid container>
                { FieldsForm() }
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    className={classes.normalTop}
                  >
                    <Grid item className={classes.deleteButton}>
                      <Button
                        variant="danger"
                        onClick={(e) => {
                          e.preventDefault()
                          clearFields(form, values, 'add')
                        }}
                      >
                        {t('inputs.cancelar')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        disabled={!valid || desabilitarFormulario}
                        loading={isSaving}
                      >
                        {t('inputs.salvar')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </div>
      <div className={classes.listItems}>
        {
          data.length > 0 &&
          data.map((elem, index) => (
            <Fragment key={index}>
              {ItemForm(index)}
            </Fragment>
          ))
        }
      </div>
    </>
  )
})

TabEspecies.propTypes = {
  paisId: PropTypes.number,
  controlAdded: PropTypes.bool,
  setControlAdded: PropTypes.func,
}

export default TabEspecies
