import BriefcaseLineIcon from 'remixicon-react/Briefcase2LineIcon'
import BuildingLineIcon from 'remixicon-react/Building4LineIcon'
import DeleteBinLineIcon from 'remixicon-react/DeleteBin7LineIcon'
import WindyLineIcon from 'remixicon-react/WindyLineIcon'
import DropLineIcon from 'remixicon-react/DropLineIcon'
import LightbulbFlashLineIcon from 'remixicon-react/LightbulbFlashLineIcon'
import TreasureMapLineIcon from 'remixicon-react/TreasureMapLineIcon'

export const cards = [
  {
    name: 'pressaoBiodiversidade.IPBUnidade',
    selector: 'iib',
    statsEValues: 'classes.ipbText',
    statusColor: 'orange',
    icon: BriefcaseLineIcon,
    decimalMin: 0,
    decimalMax: 5,
  },
  {
    name: 'pressaoBiodiversidade.DMBUnidade',
    selector: 'acb',
    statsEValues: 'ipbText',
    statusColor: 'green',
    icon: BriefcaseLineIcon,
    decimalMin: 0,
    decimalMax: 2,
  },
  {
    name: 'pressaoBiodiversidade.DMBOrganizacao',
    selector: 'acbOrganizacao',
    statsEValues: 'ipbText',
    statusColor: 'blue',
    icon: BuildingLineIcon,
    text: 'pressaoBiodiversidade.informEcoRegiao',
    decimalMin: 0,
    decimalMax: 2,
  },
]

export const metrics = [
  {
    name: 'pressaoBiodiversidade.residuos',
    selector: 'indiceResiduo',
    icon: DeleteBinLineIcon,
    statusColor: 'red',
  },
  {
    name: 'pressaoBiodiversidade.GEE',
    selector: 'indiceEmissaoGas',
    icon: WindyLineIcon,
    statusColor: 'green',
  },
  {
    name: 'pressaoBiodiversidade.agua',
    selector: 'indiceAgua',
    icon: DropLineIcon,
    statusColor: 'blue',
  },
  {
    name: 'pressaoBiodiversidade.energia',
    selector: 'indiceEnergia',
    icon: LightbulbFlashLineIcon,
    statusColor: 'yellow',
  },
  {
    name: 'pressaoBiodiversidade.area',
    selector: 'indiceArea',
    icon: TreasureMapLineIcon,
    statusColor: 'orange',
  },
]
