const ACTION_PATH = 'app/indicadoresIrc'

export const CALL_SALVAR_INDICADORES_ADMIN = `${ACTION_PATH}/CALL_SALVAR_INDICADORES_ADMIN`
export const CALL_EXCLUIR_CLASSE_INDICADORES_ADMIN = `${ACTION_PATH}/CALL_EXCLUIR_CLASSE_INDICADORES_ADMIN`
export const CALL_EXCLUIR_INDICADOR_ADMIN = `${ACTION_PATH}/CALL_EXCLUIR_INDICADOR_ADMIN`

export const callSalvarIndicadoresAdmin = (payload) => ({
  type: CALL_SALVAR_INDICADORES_ADMIN,
  payload,
})

export const callExcluirClasseIndicadoresAdmin = ({ id }) => ({
  type: CALL_EXCLUIR_CLASSE_INDICADORES_ADMIN,
  payload: { id },
})

export const callExcluirIndicadorAdmin = ({ id }) => ({
  type: CALL_EXCLUIR_INDICADOR_ADMIN,
  payload: { id },
})
