import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchClassesOcupacaoActionTypes = apiActionTypes('classesOcupacao/API_LISTAR_CLASSES_OCUPACAO')
const createClassesOcupacaoActionTypes = apiActionTypes('classesOcupacao/API_CRIAR_CLASSES_OCUPACAO')
const deleteClassesOcupacaoActionTypes = apiActionTypes('classesOcupacao/API_EXCLUIR_CLASSES_OCUPACAO')
const updateClassesOcupacaoActionTypes = apiActionTypes('classesOcupacao/API_ATUALIZAR_CLASSES_OCUPACAO')

export const API_LISTAR_CLASSES_OCUPACAO = asApiTypesObject(fetchClassesOcupacaoActionTypes)
export const API_CRIAR_CLASSES_OCUPACAO = asApiTypesObject(createClassesOcupacaoActionTypes)
export const API_EXCLUIR_CLASSES_OCUPACAO = asApiTypesObject(deleteClassesOcupacaoActionTypes)
export const API_ATUALIZAR_CLASSES_OCUPACAO = asApiTypesObject(updateClassesOcupacaoActionTypes)

export const apiListarClassesOcupacao = ({ paisId }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/classesOcupacao`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchClassesOcupacaoActionTypes,
}, { ...meta, query: { paisId } })

export const apiCriarClassesOcupacao = (classeOcupacao, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/classesOcupacao`,
  method: 'POST',
  types: createClassesOcupacaoActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(classeOcupacao),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiExcluirClassesOcupacao = ({ id }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/classesOcupacao/${id}`,
  method: 'DELETE',
  types: deleteClassesOcupacaoActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })

export const apiAtualizarClassesOcupacao = ({ id, classeOcupacao }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/classesOcupacao/${id}`,
  method: 'PUT',
  types: updateClassesOcupacaoActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(classeOcupacao),
}, { ...meta, successNotification: 'mensagens.atualizado' })
