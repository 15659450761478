import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { FormControlLabel, makeStyles, Radio, Typography } from '@material-ui/core'
import Button from 'components/Button'
import Filters from 'components/FilterList/Filters'
import clsx from 'clsx'
import { get, find } from 'lodash'
import Pagination from 'components/Pagination'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import { useSelector, useDispatch } from 'react-redux'
import { getListaParecerIndicadorGestao } from 'store/modules/auditor/auditor.selectors'
import { callSalvarParecerIndicador } from 'store/modules/auditor'
import {
  comboIndicadoresTipoComentario,
  comboIndicadoresComentarSobre,
  comboIndicadoresPreenchimentoIndicadorEspecifico,
  TIPO_USUARIO,
} from 'utils/constantes'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'

const useStyles = makeStyles((theme) => ({
  withoutResults: {
    fontSize: '32px',
    fontWeight: '400',
    color: '#C9CEDB',
    textAlign: 'center',
    padding: '8vh 0',
  },
  contentBox: {
    minHeight: '40vh',
  },
  maxInput: {
    maxWidth: '420px',
  },
  footerSection: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '33px',
    marginBottom: '33px',
  },
  search: {
    marginBottom: '32px',
  },
  listComments: {
    listStyle: 'none',
    padding: '32px 10px',
    borderTop: `1px solid ${theme.palette.grey.main}`,
    display: 'flex',
    flexDirection: 'column',
    rowGap: '12px',
  },
  lineComment: {
    display: 'flex',
    columnGap: '16px',
  },
  textComment: {
    fontSize: '14px',
    color: '#0F3444',
    whiteSpace: 'pre-line',
    margin: 0,
  },
  firstColumn: {
    minWidth: '180px',
  },
  pending: {
    displa: 'block',
    borderRadius: '100px',
    padding: '3px 12px',
    color: '#FFFFFF',
    backgroundColor: '#F85453',
  },
  concluded: {
    displa: 'block',
    borderRadius: '100px',
    padding: '3px 12px',
    color: '#FFFFFF',
    backgroundColor: '#219754',
  },
  pagination: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.grey.main}`,
    paddingTop: '32px',
    boxSizing: 'border-box',
  },
}))

const TabDesempenhoBiodiversidade = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dialog = useConfirmationDialog()
  const dispatch = useDispatch()

  const listaPareceres = useSelector(getListaParecerIndicadorGestao)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const [page, setPage] = useState(0)
  const pageSize = 5
  const filterScreen = [
    { value: true, label: 'filtros.maisAntigos', name: 'time' },
    { value: false, label: 'filtros.maisRecentes', name: 'time' },
  ]

  const handleSave = (values) => {
    dialog.showConfirmation({
      title: t('dialogs.conclusaoParecerAuditoria'),
      message: t('dialogs.temCertezaQueDesejaConfirmarConclusaoDesteParecerAuditoria'),
    }).then((result) => {
      if (result) {
        dispatch(callSalvarParecerIndicador(values))
      }
    })
  }

  const [data, setData] = useState(listaPareceres)

  useEffect(() => {
    setData(listaPareceres)
  }, [listaPareceres])

  const filteredData = data
    .slice(page * pageSize, page * pageSize + pageSize).map((item) => ({ ...item }))

  return (
    <div className={classes.contentBox}>
      <div className={classes.search}>
        <Filters
          fullWidth
          items={listaPareceres}
          setItems={setData}
          origin={{ vertical: 'top', horizontal: 'right' }}
          filterScreen={filterScreen}
          placeholder={`${data.length} ${t('inputs.comentarios')}`}
          disabled
        />
      </div>
      {data.length > 0 ? (
        <>
          {filteredData.map((parecer, index) => (
            <Form
              key={index}
              onSubmit={handleSave}
              initialValues={{
                id: parecer.id,
                resolvido: parecer?.resolvido ?? false,
              }}
              render={({ handleSubmit, values, form }) => (
                <form onSubmit={handleSubmit}>
                  <ul className={classes.listComments} key={index}>
                    <li className={classes.lineComment}>
                      <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.auditorResponsavel')}</h3>
                      <p className={classes.textComment}>{parecer.auditor}</p>
                    </li>
                    <li className={classes.lineComment}>
                      <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.tipoComentario')}</h3>
                      <p className={classes.textComment}>
                        {t(find(comboIndicadoresTipoComentario, {
                          id: parecer.tipoComentario,
                        }).label)}
                      </p>
                    </li>
                    <li className={classes.lineComment}>
                      <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.comentarioSobre')}</h3>
                      <p className={classes.textComment}>
                        {t(find(comboIndicadoresComentarSobre, {
                          id: parecer.tipoOcorrencia,
                        }).label)}
                      </p>
                    </li>
                    {parecer.tipoOcorrencia === 'PREENCHIMENTO_INDICADOR_ESPECIFICO' && (
                      <>
                        <li className={classes.lineComment}>
                          <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.qualPrincipio')}</h3>
                          <p className={classes.textComment}>{t('auditoriaEmpresa.principioNumero', { numero: parecer.principio?.numero }) }</p>
                        </li>
                        <li className={classes.lineComment}>
                          <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.qualCriterio')}</h3>
                          <p className={classes.textComment}>{t('auditoriaEmpresa.criterioNumero', { numero: parecer.criterio?.numero }) }</p>
                        </li>
                        <li className={classes.lineComment}>
                          <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.qualIndicador')}</h3>
                          <p className={classes.textComment}>{t('auditoriaEmpresa.indicadorNumero', { numero: parecer.indicador?.numero }) }</p>
                        </li>
                        <li className={classes.lineComment}>
                          <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.campoItem')}</h3>
                          <p className={classes.textComment}>
                            {t(find(comboIndicadoresPreenchimentoIndicadorEspecifico, {
                              id: parecer.campo,
                            }).label)}
                          </p>
                        </li>
                      </>
                    )}
                    <li className={classes.lineComment}>
                      <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.validadeDias')}</h3>
                      <p className={classes.textComment}>{parecer.validade}</p>
                    </li>
                    <li className={classes.lineComment}>
                      <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.comentario')}</h3>
                      <p className={classes.textComment}>{parecer.comentario}</p>
                    </li>
                    <li className={classes.lineComment}>
                      <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.status')}</h3>
                      <p className={
                    clsx(
                      classes.textComment,
                      parecer.resolvido ? classes.concluded : classes.pending,
                    )
                    }
                      >{t(`auditoriaEmpresa.${(parecer.resolvido ? 'concluido' : 'pendente')}`)}
                      </p>
                    </li>
                    {!parecer.resolvido && tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LEITOR && (
                      <li className={classes.lineComment}>
                        <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.conclusao')}</h3>
                        <FormControlLabel
                          className={classes.label}
                          label={<Typography className={classes.label}>{t('inputs.sim')}</Typography>}
                          control={(
                            <Radio
                              checked={get(values, 'resolvido', false)}
                              onChange={() => form.change('resolvido', !values.resolvido)}
                              color="primary"
                            />
                          )}
                        />
                      </li>
                    )}
                  </ul>
                  {!parecer.resolvido && tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LEITOR && (
                  <div className={classes.footerSection}>
                    <Button
                      disabled={!values.resolvido}
                      variant="danger"
                      onClick={() => form.change('resolvido', false)}
                    >
                      {t('inputs.cancelar')}
                    </Button>

                    <Button
                      disabled={!values.resolvido}
                      type="submit"
                    >
                      {t('inputs.salvar')}
                    </Button>
                  </div>
                  )}

                </form>
              )}
            />
          ))}
          <div className={classes.pagination}>
            {!!filteredData.length && (
              <Pagination
                page={page}
                onChange={setPage}
                pageSize={pageSize}
                count={data.length}
              />
            )}
          </div>
        </>
      ) : (

        <Typography component="h1" className={classes.withoutResults}>
          {t('auditoriaEmpresa.seuAuditorAindaNaoEnviouComentarios')}
        </Typography>
      )}
    </div>
  )
}

export default TabDesempenhoBiodiversidade
