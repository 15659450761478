/**
 * returns true if action's type matches any supplied type.
 * @alias module:core
 * @param {object} action action (FSA) to test
 * @param {...string} types action type strings to test against
 * @return {boolean} returns `true` for match, `false` otherwise
 */
const matchesType = (action, ...types) => {
  const { type } = action
  return types.indexOf(type) !== -1
}

export default matchesType
