const ACTION_PATH = 'app/grupoTema'

export const CALL_CRIAR_GRUPO_TEMA = `${ACTION_PATH}/CALL_CRIAR_GRUPO_TEMA`
export const CALL_ATUALIZAR_GRUPO_TEMA = `${ACTION_PATH}/CALL_ATUALIZAR_GRUPO_TEMA`
export const CALL_SALVAR_LISTA_GRUPO_TEMA = `${ACTION_PATH}/CALL_SALVAR_LISTA_GRUPO_TEMA`
export const CALL_EXCLUIR_GRUPO_TEMA = `${ACTION_PATH}/CALL_EXCLUIR_GRUPO_TEMA`

export const callCriarGrupoTema = (grupoTema) => ({
  type: CALL_CRIAR_GRUPO_TEMA,
  payload: grupoTema,
})

export const callAtualizarGrupoTema = ({ id, grupoTema }) => ({
  type: CALL_ATUALIZAR_GRUPO_TEMA,
  payload: { id, grupoTema },
})

export const callSalvarListaGrupoTema = (payload) => ({
  type: CALL_SALVAR_LISTA_GRUPO_TEMA,
  payload,
})

export const callExcluirGrupoTema = ({ id }) => ({
  type: CALL_EXCLUIR_GRUPO_TEMA,
  payload: { id },
})
