import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import {
  apiListarDivisoesSetores,
  apiAtualizarDivisaoSetor,
  apiCriarDivisaoSetor,
  apiSalvarListaDivisaoSetor,
  API_CRIAR_DIVISAO_SETOR,
} from 'store/modules/api/divisoesSetores.actions'
import {
  CALL_ATUALIZAR_DIVISAO_SETOR,
  CALL_CRIAR_DIVISAO_SETOR,
  CALL_SALVAR_LISTA_DIVISAO_SETOR,
} from 'store/modules/divisoesSetores/divisoesSetores.actions'
import { ofLocationChangeTo } from 'utils/location'
import { getDadosUsuario } from 'store/modules/auth/auth.selectors'

const onLocationChangeToLista = (action$) => action$.pipe(
  ofLocationChangeTo('/divisoesSetores'),
  debounceTime(250),
  map(({ payload }) => apiListarDivisoesSetores(payload)),
)

const onCallCriarDivisaoSetor = (action$) => action$.pipe(
  ofType(CALL_CRIAR_DIVISAO_SETOR),
  debounceTime(250),
  map(({ payload }) => apiCriarDivisaoSetor(payload)),
)

const onCallAtualizarDivisaoSetor = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_DIVISAO_SETOR),
  debounceTime(250),
  map(({ payload }) => apiAtualizarDivisaoSetor(payload)),
)

const onCallSalvarListaDivisaoSetor = (action$) => action$.pipe(
  ofType(CALL_SALVAR_LISTA_DIVISAO_SETOR),
  debounceTime(250),
  map(({ payload }) => apiSalvarListaDivisaoSetor(payload)),
)

const onCallSalvarListaDivisaoSetorSuccess = (action$, state$) => action$.pipe( // remover
  ofType(API_CRIAR_DIVISAO_SETOR.success),
  debounceTime(250),
  map(() => apiListarDivisoesSetores(getDadosUsuario(state$.value).empresa.id)),
)

export default combineEpics(
  onLocationChangeToLista,
  onCallCriarDivisaoSetor,
  onCallAtualizarDivisaoSetor,
  onCallSalvarListaDivisaoSetor,
  onCallSalvarListaDivisaoSetorSuccess,
)
