const ACTION_PATH = 'app/indicadores'

export const CALL_LISTAR_INDICADORES_CRITERIO = `${ACTION_PATH}/CALL_LISTAR_INDICADORES_CRITERIO`
export const CALL_CRIAR_INDICADOR = `${ACTION_PATH}/CALL_CRIAR_INDICADOR`
export const CALL_ATUALIZAR_INDICADOR = `${ACTION_PATH}/CALL_ATUALIZAR_INDICADOR`
export const CALL_SELECIONAR_INDICADOR = `${ACTION_PATH}/CALL_SELECIONAR_INDICADOR`
export const CALL_EXCLUIR_INDICADOR = `${ACTION_PATH}/CALL_EXCLUIR_INDICADOR`
export const CALL_NOVA_INDICADOR = `${ACTION_PATH}/CALL_NOVA_INDICADOR`

export const callBuscarIndicadoresCriterio = (criterioId) => ({
  type: CALL_LISTAR_INDICADORES_CRITERIO,
  payload: criterioId,
})

export const callCriarIndicador = (indicador) => ({
  type: CALL_CRIAR_INDICADOR,
  payload: indicador,
})

export const callAtualizarIndicador = ({ id, indicador }) => ({
  type: CALL_ATUALIZAR_INDICADOR,
  payload: { id, indicador },
})

export const callExcluirIndicador = ({ id }) => ({
  type: CALL_EXCLUIR_INDICADOR,
  payload: { id },
})

export const callNovoIndicador = () => ({
  type: CALL_NOVA_INDICADOR,
})
