import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { Grid, makeStyles } from '@material-ui/core'
import { chain, bignumber } from 'mathjs'
import { Form, FormSpy } from 'react-final-form'
import { validateFormValues } from 'utils/forms'
import { useSelector, useDispatch } from 'react-redux'
import { mapValues } from 'lodash'
import { getProjetoSelecionado, getIsSavingProjetos } from 'store/modules/projetos/projetos.selectors'
import { callAtualizarProjeto, callExcluirProjeto } from 'store/modules/projetos'
import { getMoedas } from 'store/modules/moedas/moedas.selectors'
import { getEmpresaUsuario, getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { FieldText, FieldCurrency, FieldSelect, FieldUpload } from 'components/Fields'
import Button from 'components/Button'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import { TIPO_USUARIO } from 'utils/constantes'

const useStyles = makeStyles((theme) => ({
  stepper: {
    padding: theme.spacing(3, 12, 5),
  },
  gridButton: {
    padding: theme.spacing(4),
  },
  backButton: {
    margin: theme.spacing(0, 4, 0, 0),
  },
  deleteButton: {
    marginRight: 'auto',
  },
  information: {
    color: theme.palette.primary.main,
    fontFamily: 'Poppins',
    fontSize: 10,
    fontWeight: 400,
  },
}))

const DadosGerais = ({ handleAddAcoes, handleProjectName, setChangeTab }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const dialog = useConfirmationDialog()

  const empresa = useSelector(getEmpresaUsuario)
  const projetoSelecionado = useSelector(getProjetoSelecionado)
  const listaMoedas = useSelector(getMoedas)
  const isSaving = useSelector(getIsSavingProjetos)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const calcularFaturamento = ({ investimento, cotacaoDolar } = {}) => {
    let faturamento = ''
    if (investimento && cotacaoDolar) {
      faturamento = chain(bignumber(investimento))
        .divide(bignumber(cotacaoDolar))
        .number()
        .done()
    }
    return faturamento
  }

  const handleValueChange = (form, { investimento, cotacaoDolar }) => {
    form.change('valorUs', calcularFaturamento({ investimento, cotacaoDolar }))
  }

  const handleSave = (values) => {
    const data = {
      ...values,
      ...mapValues(values, (v) => (v === '' ? null : v)),
      empresaId: empresa.id,
    }
    dispatch(callAtualizarProjeto({ id: projetoSelecionado.id, projeto: data }))
  }

  const handleDelete = (nome) => {
    dialog.showConfirmation({
      title: t('dialogs.confirmarExclusao'),
      message: t('dialogs.excluirProjeto', { projeto: nome }),
    }).then((result) => result && dispatch(callExcluirProjeto({ id: projetoSelecionado.id })))
  }

  return (
    <Form
      onSubmit={handleSave}
      validate={validateFormValues(yup.object({
        nome: yup.string().required(),
        objetivo: yup.string().required(),
        investimento: yup.number().nullable(),
        moedaId: yup.number().nullable(),
        cotacaoDolar: yup.number().nullable(),
        responsavelProjeto: yup.string().required(),
        comentario: yup.string(),
        evidenciaOcorrencia: yup.string(),
      }))}
      initialValues={{
        nome: projetoSelecionado?.nome ?? '',
        moedaId: projetoSelecionado?.moedaId ?? null,
        objetivo: projetoSelecionado?.objetivo ?? '',
        investimento: projetoSelecionado?.investimento ?? null,
        cotacaoDolar: projetoSelecionado?.cotacaoDolar ?? null,
        valorUs: projetoSelecionado?.valorUs ?? '',
        responsavelProjeto: projetoSelecionado?.responsavelProjeto ?? '',
        comentario: projetoSelecionado?.comentario ?? '',
        evidenciaOcorrencia: projetoSelecionado?.evidenciaOcorrencia ?? '',
        anexo: projetoSelecionado?.anexo ?? null,
      }}
      render={({ handleSubmit, submitting, form, values, pristine, valid }) => (
        <form onSubmit={handleSubmit}>
          <FormSpy
            onChange={({ values: valuesSpy, initialValues }) => {
              setChangeTab({
                values: valuesSpy,
                initialValues,
              })
            }}
            subscription={{ values: true, initialValues: true, pristine: true }}
          />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FieldText
                required
                name="nome"
                onChange={handleProjectName}
                label={t('inputs.nomeProjeto')}
                placeholder={t('inputs.insiraNome')}
                disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldText
                required
                name="objetivo"
                label={t('inputs.objetivoProjetoRelacionadoBiodiversidade')}
                disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldCurrency
                name="investimento"
                label={t('inputs.investimento')}
                onChange={handleValueChange(form, values)}
                disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldSelect
                name="moedaId"
                label={t('inputs.moeda')}
                options={(listaMoedas || []).map((currency) => ({
                  value: currency.id,
                  label: t(currency.nome),
                }))}
                disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldCurrency
                name="cotacaoDolar"
                label={t('inputs.cotacaoDolar')}
                onChange={handleValueChange(form, values)}
                disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldCurrency
                disabled
                name="valorUs"
                label={t('inputs.valorUs')}
              />
            </Grid>
            <Grid item xs={12}>
              <small className={classes.information}>
                {t('faturamento.informacao')}
              </small>
            </Grid>
            <Grid item xs={12}>
              <FieldText
                required
                name="responsavelProjeto"
                label={t('inputs.responsavelProjeto')}
                placeholder={t('inputs.inserirResponsavelProjeto')}
                disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldText
                multiline
                minRows={5}
                maxRows={5}
                name="comentario"
                placeholder={t('inputs.adicioneComentario')}
                label={t('inputs.comentariosReferentesProjeto')}
                disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldText
                multiline
                minRows={5}
                maxRows={5}
                name="evidenciaOcorrencia"
                label={t('inputs.evidenciaProjeto')}
                placeholder={t('inputs.comentarioEmpresa')}
                disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldUpload
                name="anexo"
                disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
              />
            </Grid>
            {tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LEITOR && (
              <Grid item xs={12}>
                <Grid container direction="row" spacing={2} justifyContent="space-between">
                  <Grid item className={classes.deleteButton}>
                    <Button variant="danger" disabled={!projetoSelecionado} onClick={() => handleDelete(values.nome)}>
                      {t('inputs.excluir')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="outlined" disabled={!projetoSelecionado?.nome} onClick={() => handleAddAcoes('TELA_ACOES')}>
                      {t('desempenhoBiodiversidade.adicionarAcoes')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      disabled={submitting || pristine || !valid}
                      loading={isSaving}
                    >
                      {t('inputs.salvar')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </form>
      )}
    />
  )
}

DadosGerais.propTypes = {
  handleProjectName: PropTypes.func,
  handleAddAcoes: PropTypes.func,
  setChangeTab: PropTypes.func,
}

export default DadosGerais
