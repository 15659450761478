import { merge } from 'lodash'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// import { API_ENDPOINT } from 'env'
import LanguageDetector from 'i18next-browser-languagedetector'
// import Backend from 'i18next-http-backend'
// import { i18nextPlugin } from 'translation-check'
import translationPT from './locales/pt/traduzir.json'
import translationPTTraduzido from './locales/pt/traduzido.json'
import translationEN from './locales/en/translation.json'
import { buildYupLocale } from './validations'
import translationES from './locales/es/translation.json'

// const backend = {
//   loadPath: () => `${API_ENDPOINT}/locales/{{lng}}`,
// }

const resourcesPT = merge(translationPTTraduzido, translationPT)

// the translations
const resources = {
  'pt-BR': {
    translation: resourcesPT,
  },
  'en-US': {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
}

i18n
  // .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  // .use(i18nextPlugin)
  .init(
    {
      resources,
      // backend,
      fallbackLng: 'pt-BR', // default language
      // fallbackLng: 'pt-BR', // default language
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      load: 'currentOnly',
      translationStats: {
        sourceLng: 'pt-BR',
        targetLngs: ['pt-BR', 'en-US', 'es'],
      },
    },
    buildYupLocale,
  )

export default i18n
