import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { filter, map } from 'lodash'
import { API_LISTAR_DADOS_IPB } from 'store/modules/api/pressaoBiodiversidade.actions'
import { API_CRIAR_CONSUMO_AGUA, API_ATUALIZAR_CONSUMO_AGUA, API_EXCLUIR_CONSUMO_AGUA, API_GET_CONSUMO_AGUA } from 'store/modules/api/consumoAgua.actions'
import { CALL_NOVO_CONSUMO_AGUA } from 'store/modules/consumoAgua/consumoAgua.actions'
import { API_EXCLUIR_ANEXO } from 'store/modules/api/anexos.actions'

const lista = (state = [], { type, payload }) => {
  switch (type) {
    case API_LISTAR_DADOS_IPB.success: return payload.consumoAgua
    case API_CRIAR_CONSUMO_AGUA.success: return [...state, payload.consumoAgua]
    case API_EXCLUIR_CONSUMO_AGUA.success: return filter(state, (u) => u.id !== payload.id)
    case API_ATUALIZAR_CONSUMO_AGUA.success:
      return map(state, (u) => (u.id === payload.consumoAgua.id ? payload.consumoAgua : u))
    default: return state
  }
}

const selecionado = (state = null, { type, payload }) => {
  switch (type) {
    case API_GET_CONSUMO_AGUA.success: return payload
    case CALL_NOVO_CONSUMO_AGUA: return null
    case API_CRIAR_CONSUMO_AGUA.success: return null
    case API_ATUALIZAR_CONSUMO_AGUA.success: return null
    case API_EXCLUIR_ANEXO.success:
      return state?.anexo?.id === +payload ? { ...state, anexo: null } : state
    default: return state
  }
}

const isSaving = loadingReducer(false, {
  request: [
    API_ATUALIZAR_CONSUMO_AGUA.request,
    API_CRIAR_CONSUMO_AGUA.request,
  ],
  success: [
    API_ATUALIZAR_CONSUMO_AGUA.success,
    API_CRIAR_CONSUMO_AGUA.success,
  ],
  failure: [
    API_ATUALIZAR_CONSUMO_AGUA.failure,
    API_CRIAR_CONSUMO_AGUA.failure,
  ],
})

const isCarregandoConsumoAgua = loadingReducer(false, API_LISTAR_DADOS_IPB)

export default combineReducers({
  lista,
  selecionado,
  isCarregandoConsumoAgua,
  isSaving,
})
