import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const deleteAnexoActionTypes = apiActionTypes('anexos/API_EXCLUIR_ANEXO')

export const API_EXCLUIR_ANEXO = asApiTypesObject(deleteAnexoActionTypes)

export const apiExcluirAnexo = (id, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/anexos/${id}`,
  method: 'DELETE',
  types: deleteAnexoActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })
