import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import BaseField from 'components/Fields/BaseInput'
import BaseCheckbox from 'components/Fields/BaseCheckbox'
import SearchLineIcon from 'remixicon-react/SearchLineIcon'
import FilterLineIcon from 'remixicon-react/Filter3LineIcon'
import CloseIcon from 'remixicon-react/CloseLineIcon'
import { useTranslation } from 'react-i18next'
import { IconButton, Popover, InputAdornment, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    width: (props) => props.fullWidth && '100%',
  },
  searchIcon: {
    color: theme.palette.grey.main,
  },
  popover: {
    minWidth: 257,
    boxSizing: 'border-box',
    padding: theme.spacing(2),
    boxShadow: `0 8px 32px ${theme.palette.shadows.main}, 0 8px 32px ${theme.palette.shadows.main}`,
  },
  filterHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.primary.main,
  },
  filterTitle: {
    fontSize: 24,
    lineHeight: 1.25,
  },
  closeIcon: {
    cursor: 'pointer',
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  filterBody: {
    marginTop: theme.spacing(4),
    color: theme.palette.primary.main,
  },
  field: {
    display: 'flex',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  fieldTitle: {
    fontSize: 12,
    marginLeft: theme.spacing(0.5),
  },
  fieldBorder: {
    paddingBottom: 12,
    marginBottom: 12,
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
  customFieldTitle: {
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.grey.main}`,
  },
  customField: {
    display: 'flex',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.grey.main}`,
    },
  },
  customFieldItem: {
    display: 'flex',
    alignItems: 'center',
    '&:first-child': {
      width: 120,
      flexShrink: 0,
    },
  },
}))

const defaultFilters = [
  { value: true, label: 'filtros.nomeAZ', name: 'order' },
  { value: false, label: 'filtros.nomeZA', name: 'order', divider: true },
  { value: false, label: 'filtros.maisAntigos', name: 'time' },
  { value: true, label: 'filtros.maisRecentes', name: 'time' },
]

const Filters = ({
  setItems,
  items,
  customFilters,
  fullWidth,
  onChange,
  origin,
  filterScreen,
  disabled = false,
  nameSearch = 'nome',
  placeholder,
}) => {
  const classes = useStyles({ fullWidth })
  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const [filters, setFilters] = useState({})
  const [filterAnchor, setFilterAnchor] = React.useState(null)

  const handleSearch = ({ target }) => {
    setSearch(target.value)
  }

  const openFilter = ({ currentTarget }) => {
    setFilterAnchor(currentTarget)
  }

  const closeFilter = () => {
    setFilterAnchor(null)
  }

  const handleFilter = (key, value) => ({ target }) => {
    setFilters({ type: key, value: target.checked ? value : null })
  }

  useEffect(() => {
    const filterByName = items
      .filter((item) => {
        if (!search) return true
        return (item[nameSearch] || '').toLowerCase().includes(search?.toLowerCase())
      })
      .sort((prev, next) => {
        if (filters.type === 'time') {
          const prevDate = new Date(prev.createdAt).getTime()
          const nextDate = new Date(next.createdAt).getTime()
          return (nextDate - prevDate) * (filters.value ? 1 : -1)
        }

        if (filters.type === 'order' && filters.value !== null) {
          return (prev[nameSearch] || '').localeCompare((next[nameSearch] || '')) * (filters.value ? 1 : -1)
        }

        return 0
      })
    setItems(filterByName)
    if (onChange) onChange()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, search])

  return (
    <div className={classes.search}>
      <BaseField
        value={search || ''}
        onChange={handleSearch}
        placeholder={placeholder || t('filtros.buscar')}
        disabled={disabled}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {!disabled && <SearchLineIcon className={classes.searchIcon} />}
            </InputAdornment>
          ),
        }}
      />
      <IconButton onClick={openFilter}>
        <FilterLineIcon className={classes.filterIcon} />
      </IconButton>
      <Popover
        open={!!filterAnchor}
        onClose={closeFilter}
        anchorEl={filterAnchor}
        classes={{ paper: classes.popover }}
        anchorOrigin={origin || { vertical: 'top', horizontal: 'center' }}
        transformOrigin={origin || { vertical: 'top', horizontal: 'center' }}
      >
        <div className={classes.filterHeader}>
          <div className={classes.filterTitle}>{t('filtros.ordenarPor')}</div>
          <CloseIcon className={classes.closeIcon} onClick={closeFilter} />
        </div>
        <div className={classes.filterBody}>
          {(filterScreen || defaultFilters).map((filter) => (
            <div
              key={filter.label}
              className={clsx(classes.field, filter.divider && classes.fieldBorder)}
            >
              <BaseCheckbox
                onChange={handleFilter(filter.name, filter.value)}
                checked={filters.type === filter.name && filters.value === filter.value}
              />
              <span className={classes.fieldTitle}>{t(filter.label)}</span>
            </div>
          ))}
        </div>
        {customFilters && (
          <div>
            <div className={clsx(classes.filterTitle, classes.customFieldTitle)}>
              {t('filtros.filtrarPor')}
            </div>
            {customFilters.map((filter) => (
              <div key={filter.name} className={classes.customField}>
                <div className={classes.customFieldItem}>
                  <BaseCheckbox
                    checked={filters[filter.name] === true}
                    onChange={handleFilter(filter.name, true)}
                  />
                  <span className={classes.fieldTitle}>{t(filter.enabled)}</span>
                </div>
                <div className={classes.customFieldItem}>
                  <BaseCheckbox
                    checked={filters[filter.name] === false}
                    onChange={handleFilter(filter.name, false)}
                  />
                  <span className={classes.fieldTitle}>{t(filter.disabled)}</span>
                </div>
              </div>
            ))}
          </div>
        )}
      </Popover>
    </div>
  )
}

Filters.propTypes = {
  setItems: PropTypes.func,
  items: PropTypes.array,
  customFilters: PropTypes.array,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  origin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
  filterScreen: PropTypes.array,
  nameSearch: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
}

export default Filters
