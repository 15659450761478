import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import {
  apiListarGrupos,
  apiAtualizarGrupo,
  apiCriarGrupo,
  apiSalvarListaGrupo,
  apiExcluirGrupo,
} from 'store/modules/api/grupos.actions'
import {
  CALL_ATUALIZAR_GRUPO,
  CALL_CRIAR_GRUPO,
  CALL_SALVAR_LISTA_GRUPO,
  CALL_EXCLUIR_GRUPO,
} from 'store/modules/grupos/grupos.actions'
import { ofLocationChangeTo } from 'utils/location'

const onLocationChangeToLista = (action$) => action$.pipe(
  ofLocationChangeTo('/grupos'),
  debounceTime(250),
  map(({ payload }) => apiListarGrupos(payload)),
)

const onCallCriarGrupo = (action$) => action$.pipe(
  ofType(CALL_CRIAR_GRUPO),
  debounceTime(250),
  map(({ payload }) => apiCriarGrupo(payload)),
)

const onCallAtualizarGrupo = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_GRUPO),
  debounceTime(250),
  map(({ payload }) => apiAtualizarGrupo(payload)),
)

const onCallSalvarListaGrupo = (action$) => action$.pipe(
  ofType(CALL_SALVAR_LISTA_GRUPO),
  debounceTime(250),
  map(({ payload }) => apiSalvarListaGrupo({ lista: payload })),
)

const onCallExcluirGrupos = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_GRUPO),
  debounceTime(250),
  map(({ payload }) => apiExcluirGrupo(payload)),
)

export default combineEpics(
  onLocationChangeToLista,
  onCallCriarGrupo,
  onCallAtualizarGrupo,
  onCallSalvarListaGrupo,
  onCallExcluirGrupos,
)
