const ACTION_PATH = 'app/relatorios'

export const CALL_RELATORIO_GESTAO_DETALHADO = `${ACTION_PATH}/CALL_RELATORIO_GESTAO_DETALHADO`
export const CALL_RELATORIO_GESTAO_RESUMIDO = `${ACTION_PATH}/CALL_RELATORIO_GESTAO_RESUMIDO`
export const CALL_RELATORIO_GESTAO_GERAL = `${ACTION_PATH}/CALL_RELATORIO_GESTAO_GERAL`

export const callRelatorioGestaoDetalhado = (payload) => ({
  type: CALL_RELATORIO_GESTAO_DETALHADO,
  payload,
})

export const callRelatorioGestaoResumido = (payload) => ({
  type: CALL_RELATORIO_GESTAO_RESUMIDO,
  payload,
})

export const callRelatorioGestaoGeral = (payload) => ({
  type: CALL_RELATORIO_GESTAO_GERAL,
  payload,
})
