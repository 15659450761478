import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import Button from 'components/Button'
import Accordion from 'components/Accordion'
import DeleteIcon from 'remixicon-react/DeleteBin7LineIcon'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { FieldCheckbox, FieldText } from 'components/Fields'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import { getQualificadores, getIsSavingQualificador } from 'store/modules/qualificadores/qualificadores.selectors'
import { callSalvarListaQualificador, callExcluirQualificador } from 'store/modules/qualificadores'
import clsx from 'clsx'
import { validateFormValues } from 'utils/forms'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { TIPO_USUARIO } from 'utils/constantes'

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
  screenTitle: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(5),
    fontSize: theme.typography.pxToRem(24),
  },
  cancelButton: {
    marginRight: 'auto',
  },
  group: {
    padding: theme.spacing(2),
  },
  groupContainer: {
    marginTop: '32px',
    border: '1px solid #C9CEDB',
    boxShadow: '0px 8px 32px rgba(15, 52, 68, 0.08)',
    borderRadius: '8px',
    padding: '0px 16px',
  },
  groupItemSpacing: {
    marginTop: theme.spacing(2),
  },
  deleteIcon: {
    flexShrink: 0,
    cursor: ({ desabilitarFormulario }) => (desabilitarFormulario ? 'default' : 'pointer'),
    margin: theme.spacing(1, 2),
    color: theme.palette.grey.blue,
    '&:hover': {
      color: ({ desabilitarFormulario }) => (desabilitarFormulario ? theme.palette.grey.blue : theme.palette.grey['2E']),
    },
  },
  accordionTitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  deleteColumn: {
    display: 'flex',
  },
  itemDelete: {
    cursor: 'pointer',
    color: theme.palette.primary.light,
    fontSize: theme.typography.pxToRem(12),
  },
  divider: {
    height: 1,
    backgroundColor: theme.palette.grey.main,
    width: 'calc(100% + 32px)',
    marginLeft: '-16px',
  },
  itemTitle: {
    fontFamily: 'Poppins',
    color: '#0F3444',
    fontWeight: '600',
    fontSize: '16px',
  },
  formAdd: {
    transition: '0.20s ease-out',
    maxHeight: 0,
    overflow: 'hidden',
  },
  openFormAdd: {
    paddingTop: 10,
    marginTop: '22px',
    maxHeight: 700,
    transition: '0.25s ease-in',
  },
  dividerButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
  labelField: {
    fontSize: 14,
  },
  contentCheckBox: {
    display: 'flex',
    marginLeft: 12,
    alignItems: 'center',
  },
}))

const Qualificadores = ({ paisId }) => {
  const { t } = useTranslation()

  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)
  const desabilitarFormulario = Boolean(tipoUsuarioLogado === TIPO_USUARIO.ADMIN.OBSERVADOR)

  const classes = useStyles({ desabilitarFormulario })
  const dispatch = useDispatch()
  const dialog = useConfirmationDialog()
  const listaQualificadores = useSelector(getQualificadores)
  const [identificador, setIdentificador] = useState(null)
  const [controlAdded, setControlAdded] = useState(false)
  const [openItem, setOpenItem] = useState('')
  const isSaving = useSelector(getIsSavingQualificador)

  const validationSchema = yup.object({
    fator: yup.number().required(), // peso
    nome: yup.string().required(),
    nomeColuna: yup.string().required(),
    qualificadorAvaliacoes: yup.array().of(
      yup.object().shape({
        descricao: yup.string()
          .required(),
        fatorJ: yup.number()
          .required(),
      }),
    ),
  })

  const handleExpand = (item) => {
    setOpenItem(openItem === item ? '' : item)
  }
  useEffect(() => {
    setIdentificador(listaQualificadores ? listaQualificadores.length + 1 : 1)
  }, [paisId, listaQualificadores])

  const handleAdded = (values, form) => {
    const data = [...listaQualificadores, values]
    form.restart()
    setControlAdded(false)
    dispatch(callSalvarListaQualificador(data))
  }

  const handleUpdate = (values) => {
    const data = [
      ...listaQualificadores.filter((e) => e.id !== values.id), values]
    dispatch(callSalvarListaQualificador(data))
  }

  const handleDelete = (id, nome, Qidentificador) => dialog.showConfirmation({
    title: t('dialogs.confirmarExclusao'),
    message: t(
      'dialogs.excluirQualificador',
      { qualificador: `${Qidentificador} - ${nome}` },
    ),
  }).then((result) => {
    if (result) {
      if (id) return dispatch(callExcluirQualificador({ id }))
    }
    return null
  })
  const fieldsForm = (form, values, id) => (
    <>

      <Grid item xs={12} sm={10}>
        <FieldText
          required
          label={t('inputs.nomeQualificador')}
          name="nome"
          disabled={desabilitarFormulario}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <FieldText
          required
          type="number"
          label={t('inputs.peso')}
          name="fator"
          disabled={desabilitarFormulario}
        />
      </Grid>
      <Grid
        container
        alignItems="center"
      >
        <div className={classes.contentCheckBox}>
          <FieldCheckbox
            name="somenteACB"
            disabled={desabilitarFormulario}
          />
          <Typography className={classes.labelField}>
            {t('inputs.esteQualificadorDeveSerIntegradoFormulaPontuacaoAcao')}
          </Typography>
        </div>
      </Grid>
      <Grid className={classes.groupItemSpacing} item xs={12}>
        <FieldText
          required
          label={t('inputs.nomeColuna')}
          name="nomeColuna"
          disabled={desabilitarFormulario}
        />
      </Grid>
      {values.qualificadorAvaliacoes.map((item, itemIndex) => (
        <Fragment key={itemIndex}>
          <Grid item xs={12} sm={8}>
            <FieldText
              required
              label={t('inputs.nomeItem')}
              name={`qualificadorAvaliacoes.${itemIndex}.descricao`}
              disabled={desabilitarFormulario}
            />
          </Grid>
          <Grid className={classes.deleteColumn} item xs={6} sm={4}>
            <FieldText
              required
              type="number"
              label="J"
              name={`qualificadorAvaliacoes.${itemIndex}.fatorJ`}
              disabled={desabilitarFormulario}
            />
            <DeleteIcon
              className={classes.deleteIcon}
              onClick={() => {
                if (desabilitarFormulario) return null
                return form.change(
                  'qualificadorAvaliacoes',
                  values.qualificadorAvaliacoes.filter(
                    (_, currentIndex) => currentIndex !== itemIndex,
                  ),
                )
              }}
            />
          </Grid>
        </Fragment>
      ))}

      <div className={classes.dividerButton}>
        <Button
          variant="outlined"
          onClick={() => form.change('qualificadorAvaliacoes', [
            ...values.qualificadorAvaliacoes,
            {
              fatorJ: '',
              descricao: '',
              qualificadorId: id ?? undefined,

            },
          ])}
          disabled={desabilitarFormulario}
        >
          {t('paisesCalculos.incluirItem')}
        </Button>
      </div>
    </>
  )

  return (
    <>
      <div className={classes.sectionTitle}>
        <Typography className={classes.screenTitle}>
          {t('paisesCalculos.qualificadores')}
        </Typography>
        <Button
          variant="default"
          disabled={controlAdded || desabilitarFormulario}
          onClick={() => {
            setControlAdded(true)
            handleExpand('')
          }}
        >
          {t('paisesCalculos.adicionarQualificador')}
        </Button>
      </div>

      <div
        className={clsx(
          classes.formAdd,
          controlAdded && classes.openFormAdd,
        )}
      >
        <Form
          onSubmit={handleAdded}
          validate={validateFormValues(validationSchema)}
          initialValues={{
            identificador: `Q${identificador}`,
            nome: '',
            nomeColuna: '',
            fator: '',
            qualificadorAvaliacoes: [],
            paisId,
            somenteACB: false,
          }}
          render={({ handleSubmit, form, values, pristine, valid }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                {fieldsForm(form, values)}
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                  >
                    <Grid item className={classes.deleteButton}>
                      <Button
                        variant="danger"
                        type="button"
                        onClick={() => {
                          form.restart()
                          setControlAdded(false)
                        }}
                      >
                        {t('inputs.cancelar')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        disabled={Boolean(pristine || !valid) || desabilitarFormulario}
                        loading={isSaving}
                      >
                        {t('inputs.salvar')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </div>
      <div className={classes.groupContainer}>
        {listaQualificadores.map((item, index) => (
          <Form
            key={index}
            onSubmit={handleUpdate}
            validate={validateFormValues(validationSchema)}
            initialValues={{
              fator: item?.fator ?? '',
              id: item?.id ?? '',
              identificador: item?.identificador ?? '',
              listaGrupoTema: item?.listaGrupoTema ?? [],
              nome: item?.nome ?? '',
              nomeColuna: item?.nomeColuna ?? '',
              pais: item?.pais ?? [],
              paisId: item?.paisId ?? paisId,
              qualificadorAvaliacoes: item?.qualificadorAvaliacoes ?? [],
              somenteACB: item?.somenteACB ?? '',
            }}
            render={({ handleSubmit, form, values, pristine, valid }) => (
              <form onSubmit={handleSubmit}>
                <Accordion
                  hideButton
                  variant="no-borders"
                  classes={{ title: classes.itemTitle }}
                  title={`${item.identificador} - ${item.nome}`}
                  onDelete={() => {
                    handleDelete(item.id, item.nome, item.identificador)
                    handleExpand('')
                  }}
                  expanded={index === openItem}
                  onExpand={() => handleExpand(index)}
                  disableDelete={desabilitarFormulario}
                >
                  <Grid container spacing={3}>
                    {fieldsForm(form, values, values.id)}
                    <Grid item xs={12}>
                      <Grid
                        container
                        direction="row"
                        spacing={2}
                        justifyContent="space-between"
                      >
                        <Grid item className={classes.deleteButton}>
                          <Button
                            variant="danger"
                            type="button"
                            onClick={() => {
                              form.restart()
                              handleExpand('')
                            }}
                          >
                            {t('inputs.cancelar')}
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            type="submit"
                            disabled={
                              (Boolean(pristine || !valid) || desabilitarFormulario) &&
                              (item.qualificadorAvaliacoes.length ===
                                  values.qualificadorAvaliacoes.length)
                            }
                            loading={isSaving}
                          >
                            {t('inputs.salvar')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Accordion>
                <div className={classes.divider} />
              </form>
            )}
          />
        ))}
      </div>
    </>
  )
}

Qualificadores.propTypes = {
  paisId: PropTypes.number,
}

export default Qualificadores
