import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import { apiExcluirAnexo } from 'store/modules/api/anexos.actions'
import { CALL_EXCLUIR_ANEXO } from 'store/modules/anexos/anexos.actions'

const onCallExcluirAcao = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_ANEXO),
  debounceTime(250),
  map(({ payload }) => apiExcluirAnexo(payload)),
)

export default combineEpics(
  onCallExcluirAcao,
)
