import { useEffect } from 'react'
import { forEach, filter, includes } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { removeSnackbar } from 'store/modules/notifications'
import { useTranslation } from 'react-i18next'

let displayed = []

const Notifier = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const notifications = useSelector((store) => store.notifications.notifications || [])
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const storeDisplayed = (id) => {
    displayed = [...displayed, id]
  }

  const removeDisplayed = (id) => {
    displayed = [...filter(displayed, ((key) => id !== key))]
  }

  useEffect(() => {
    forEach(notifications, ({
      key,
      message,
      options = {},
      dismissed = false,
    }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key)
        return
      }

      // do nothing if snackbar is already displayed
      if (includes(displayed, key)) return

      const { dismissible = false, variant, ...rest } = options

      // display snackbar using notistack
      enqueueSnackbar({
        variant,
        message: t(message),
      }, {
        key,
        variant,
        ...(dismissible && { onClick: () => closeSnackbar(key) }),
        ...rest,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey)
          }
        },
        onExited: (event, myKey) => {
          // remove this snackbar from redux store
          dispatch(removeSnackbar(myKey))
          removeDisplayed(myKey)
        },
      })

      // keep track of snackbars that we've displayed
      storeDisplayed(key)
    })
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch, t])

  return null
}

export default Notifier
