const ACTION_PATH = 'app/criterios'

export const CALL_LISTAR_CRITERIOS_PRINCIPIO = `${ACTION_PATH}/CALL_LISTAR_CRITERIOS_PRINCIPIO`
export const CALL_CRIAR_CRITERIO = `${ACTION_PATH}/CALL_CRIAR_CRITERIO`
export const CALL_ATUALIZAR_CRITERIO = `${ACTION_PATH}/CALL_ATUALIZAR_CRITERIO`
export const CALL_SELECIONAR_CRITERIO = `${ACTION_PATH}/CALL_SELECIONAR_CRITERIO`
export const CALL_EXCLUIR_CRITERIO = `${ACTION_PATH}/CALL_EXCLUIR_CRITERIO`
export const CALL_NOVA_CRITERIO = `${ACTION_PATH}/CALL_NOVA_CRITERIO`

export const callBuscarCriteriosPrincipio = (principioId) => ({
  type: CALL_LISTAR_CRITERIOS_PRINCIPIO,
  payload: principioId,
})

export const callCriarCriterio = (criterio) => ({
  type: CALL_CRIAR_CRITERIO,
  payload: criterio,
})

export const callAtualizarCriterio = ({ id, criterio }) => ({
  type: CALL_ATUALIZAR_CRITERIO,
  payload: { id, criterio },
})

export const callExcluirCriterio = ({ id }) => ({
  type: CALL_EXCLUIR_CRITERIO,
  payload: { id },
})

export const callNovoCriterio = () => ({
  type: CALL_NOVA_CRITERIO,
})
