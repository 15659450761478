import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { API_ATUALIZAR_CONSUMO_AGUA, API_CRIAR_CONSUMO_AGUA, API_EXCLUIR_CONSUMO_AGUA } from 'store/modules/api/consumoAgua.actions'
import { API_ATUALIZAR_ENERGIA, API_CRIAR_ENERGIA, API_EXCLUIR_ENERGIA } from 'store/modules/api/energia.actions'
import { API_ATUALIZAR_FATURAMENTO, API_CRIAR_FATURAMENTO } from 'store/modules/api/faturamento.actions'
import { API_ATUALIZAR_GAS_EFEITO_ESTUFA, API_CRIAR_GAS_EFEITO_ESTUFA, API_EXCLUIR_GAS_EFEITO_ESTUFA } from 'store/modules/api/gasesEfeitoEstufa.actions'
import { API_ATUALIZAR_OCUPACAO_AREA, API_CRIAR_OCUPACAO_AREA, API_EXCLUIR_OCUPACAO_AREA } from 'store/modules/api/ocupacaoArea.actions'
import { API_LISTAR_DADOS_IPB } from 'store/modules/api/pressaoBiodiversidade.actions'
import { API_ATUALIZAR_RESIDUO, API_CRIAR_RESIDUO, API_EXCLUIR_RESIDUO } from 'store/modules/api/residuos.actions'

const indices = (state = null, { type, payload }) => {
  switch (type) {
    case API_LISTAR_DADOS_IPB.success: return {
      ...payload.indiceUnidade, acbOrganizacao: payload.acbOrganizacao,
    }

    case API_ATUALIZAR_FATURAMENTO.success:
    case API_CRIAR_FATURAMENTO.success: return {
      ...state, acb: payload.acb, acbOrganizacao: payload.acbOrganizacao,
    }

    case API_ATUALIZAR_RESIDUO.success:
    case API_EXCLUIR_RESIDUO.success:
    case API_CRIAR_RESIDUO.success: return {
      ...state,
      indiceResiduo: payload.indiceImpacto,
      iib: payload.iib,
      acb: payload.acb,
      acbOrganizacao: payload.acbOrganizacao,
    }

    case API_ATUALIZAR_GAS_EFEITO_ESTUFA.success:
    case API_EXCLUIR_GAS_EFEITO_ESTUFA.success:
    case API_CRIAR_GAS_EFEITO_ESTUFA.success: return {
      ...state,
      indiceEmissaoGas: payload.indiceImpacto,
      iib: payload.iib,
      acb: payload.acb,
      acbOrganizacao: payload.acbOrganizacao,
    }

    case API_ATUALIZAR_CONSUMO_AGUA.success:
    case API_EXCLUIR_CONSUMO_AGUA.success:
    case API_CRIAR_CONSUMO_AGUA.success: return {
      ...state,
      indiceAgua: payload.indiceImpacto,
      iib: payload.iib,
      acb: payload.acb,
      acbOrganizacao: payload.acbOrganizacao,
    }

    case API_ATUALIZAR_ENERGIA.success:
    case API_EXCLUIR_ENERGIA.success:
    case API_CRIAR_ENERGIA.success: return {
      ...state,
      indiceEnergia: payload.indiceImpacto,
      iib: payload.iib,
      acb: payload.acb,
      acbOrganizacao: payload.acbOrganizacao,
    }

    case API_ATUALIZAR_OCUPACAO_AREA.success:
    case API_EXCLUIR_OCUPACAO_AREA.success:
    case API_CRIAR_OCUPACAO_AREA.success: return {
      ...state,
      indiceArea: payload.indiceImpacto,
      iib: payload.iib,
      acb: payload.acb,
      acbOrganizacao: payload.acbOrganizacao,
    }

    default: return state
  }
}

const anoCarregado = (state = null, { type, meta }) => (
  (type === API_LISTAR_DADOS_IPB.success) ? meta.query.ano : state
)

const isLoading = loadingReducer(false, API_LISTAR_DADOS_IPB)

export default combineReducers({
  indices,
  isLoading,
  anoCarregado,
})
