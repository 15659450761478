import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { API_LISTAR_CONVERSOES_VOLUME } from 'store/modules/api/conversoesVolume.actions'

const lista = (state = [], { type, payload }) => (
  (type === API_LISTAR_CONVERSOES_VOLUME.success) ? payload : state
)

const isCarregandoLista = loadingReducer(false, API_LISTAR_CONVERSOES_VOLUME)

export default combineReducers({
  lista,
  isCarregandoLista,
})
