import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map, mergeMap } from 'rxjs/operators'
import {
  apiFetchUsers,
  apiGetUsuario,
  apiCriarUsuario,
  apiAtualizarUsuario,
  apiExcluirUsuario,
} from 'store/modules/api/usuarios.actions'
import { apiListarEmpresas } from 'store/modules/api/empresas.actions'
import { CALL_ATUALIZAR_USUARIO, CALL_CRIAR_USUARIO, CALL_EXCLUIR_USUARIO, CALL_SELECIONAR_USUARIO } from 'store/modules/usuarios/usuarios.actions'
import { ofLocationChangeTo } from 'utils/location'

const onLocationChangeToLista = (action$) => action$.pipe(
  ofLocationChangeTo(['/usuarios', '/adm/usuarios', '/adm/usuariosLife']),
  debounceTime(250),
  mergeMap(() => [
    apiFetchUsers(),
    apiListarEmpresas(),
  ]),
)

const onSelected = (action$) => action$.pipe(
  ofType(CALL_SELECIONAR_USUARIO),
  debounceTime(250),
  map(({ payload }) => apiGetUsuario(payload.id)),
)

const onCallCriarUsuario = (action$) => action$.pipe(
  ofType(CALL_CRIAR_USUARIO),
  debounceTime(250),
  map(({ payload }) => apiCriarUsuario(payload)),
)

const onCallAtualizarUsuario = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_USUARIO),
  debounceTime(250),
  map(({ payload }) => apiAtualizarUsuario(payload)),
)

const onCallExcluirUsuario = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_USUARIO),
  debounceTime(250),
  map(({ payload }) => apiExcluirUsuario(payload)),
)

export default combineEpics(
  onLocationChangeToLista,
  onSelected,
  onCallCriarUsuario,
  onCallAtualizarUsuario,
  onCallExcluirUsuario,
)
