import React, { useContext, useRef, useState, createContext } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core'
import CloseLineIcon from 'remixicon-react/CloseLineIcon'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'

const ConfirmDialogContext = createContext({})

const useStyles = makeStyles((theme) => ({
  actions: {
    padding: theme.spacing(5, 4),
    display: 'flex',
    justifyContent: 'space-between',
  },
  modal: {
    '& .MuiPaper-rounded': {
      borderRadius: 8,
    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(46, 58, 89, 0.65)',
    },
  },
  title: {
    padding: theme.spacing(4, 4, 0),
    '& .MuiTypography-root': {
      fontSize: theme.typography.pxToRem(24),
    },
  },
  divider: {
    width: '90%',
    height: 1,
    background: theme.palette.grey.main,
    margin: theme.spacing(2, 'auto', 0),
    border: 0,
  },
  body: {
    padding: theme.spacing(4, 4, 0),
  },
  description: {
    fontSize: theme.typography.pxToRem(14),
    fontFamily: theme.typography.secondary,
  },
}))

const ConfirmDialogProvider = ({ children }) => {
  const { t } = useTranslation()
  const [showDialog, setShowDialog] = useState(false)
  const [dialogTitle, setDialogTitle] = useState(t('dialogs.confirmarAcao'))
  const [dialogMessage, setDialogMessage] = useState('')
  const [dialogCancelText, setDialogCancelText] = useState('')
  const [dialogConfirmText, setDialogConfirmText] = useState('')
  const [showCancelButton, setShowCancelButton] = useState(true)
  const resolver = useRef()
  const classes = useStyles()

  const handleShow = ({
    title = null,
    message = null,
    cancelText = null,
    confirmText = null,
    showCancelBtn = true,
  }) => {
    if (title) {
      setDialogTitle(title)
    }
    if (message) {
      setDialogMessage(message)
    }

    setShowCancelButton(showCancelBtn)

    // Traduz o texto toda vez para atualizar o texto caso o idioma mude
    setDialogCancelText(cancelText ? t(cancelText) : t('dialogs.cancelar'))
    setDialogConfirmText(confirmText ? t(confirmText) : t('dialogs.confirmar'))

    setShowDialog(true)

    return new Promise((resolve) => {
      resolver.current = resolve
    })
  }

  const handleOk = async () => {
    // resolver.current && resolver.current(true)
    if (resolver.current) {
      await resolver.current(true)
    }
    setShowDialog(false)
  }

  const handleCancel = async () => {
    // resolver.current && resolver.current(false)
    if (resolver.current) {
      await resolver.current(false)
    }
    setShowDialog(false)
  }

  return (
    <ConfirmDialogContext.Provider value={{ showConfirmation: handleShow }}>
      {children}
      <Dialog open={showDialog} maxWidth="sm" fullWidth className={classes.modal}>
        <DialogTitle className={classes.title}>{dialogTitle}</DialogTitle>
        <Box position="absolute" top={26} right={14}>
          <IconButton onClick={handleCancel}>
            <CloseLineIcon />
          </IconButton>
        </Box>
        <hr className={classes.divider} />
        <DialogContent className={classes.body}>
          <Typography
            className={classes.description}
            dangerouslySetInnerHTML={{ __html: dialogMessage }}
          />
        </DialogContent>
        <DialogActions className={classes.actions}>
          {showCancelButton && (
            <Button variant="danger" onClick={handleCancel}>
              {dialogCancelText}
            </Button>
          )}
          <Button onClick={handleOk}>
            {dialogConfirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </ConfirmDialogContext.Provider>
  )
}

ConfirmDialogProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

export const useConfirmationDialog = () => useContext(ConfirmDialogContext)
export default ConfirmDialogProvider
