import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchClasseResiduosActionTypes = apiActionTypes('classeResiduos/API_LISTAR_CLASSE_RESIDUOS')

export const API_LISTAR_CLASSE_RESIDUOS = asApiTypesObject(fetchClasseResiduosActionTypes)

export const apiListarClasseResiduos = (data, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/residuoClasses`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchClasseResiduosActionTypes,
}, { ...meta })
