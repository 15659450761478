import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import { apiCriarAcao, apiAtualizarAcao, apiExcluirAcao, apiGetAcao } from 'store/modules/api/acoes.actions'
import { CALL_ATUALIZAR_ACAO, CALL_CRIAR_ACAO, CALL_EXCLUIR_ACAO, CALL_SELECIONAR_ACAO } from 'store/modules/acoes/acoes.actions'

const onSelected = (action$) => action$.pipe(
  ofType(CALL_SELECIONAR_ACAO),
  debounceTime(250),
  map(({ payload }) => apiGetAcao(payload.id)),
)

const onCallCriarAcao = (action$) => action$.pipe(
  ofType(CALL_CRIAR_ACAO),
  debounceTime(250),
  map(({ payload }) => apiCriarAcao(payload)),
)

const onCallAtualizarAcao = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_ACAO),
  debounceTime(250),
  map(({ payload }) => apiAtualizarAcao(payload)),
)

const onCallExcluirAcao = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_ACAO),
  debounceTime(250),
  map(({ payload }) => apiExcluirAcao(payload)),
)

export default combineEpics(
  onCallCriarAcao,
  onCallAtualizarAcao,
  onSelected,
  onCallExcluirAcao,
)
