import React from 'react'
import {
  Typography,
  Container,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TableContainer,
  CircularProgress,
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { map } from 'lodash'
import { useTranslation } from 'react-i18next'
import { getEmpresas, getIsCarregandoListaEmpresas } from 'store/modules/empresas/empresas.selectors'

const EmpresaLista = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsCarregandoListaEmpresas)
  const users = useSelector(getEmpresas)

  return (
    <Container>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        {t('empresa.empresas')}
      </Typography>
      {isLoading && <CircularProgress />}
      {!isLoading && (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('empresa.nome')}</TableCell>
                  <TableCell>{t('empresa.cnpj')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {map(users, ({ id, nome, cnpj }) => (
                  <TableRow key={id} onClick={() => dispatch(push(`/empresas/${id}`))}>
                    <TableCell>{nome}</TableCell>
                    <TableCell>{cnpj}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Container>
  )
}

export default EmpresaLista
