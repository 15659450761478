/**
 * hasAuthorization
 *
 * Check if the user meet at least one required set of permissions
 *
 * permissions = P_1 // Requires P_1
 * permissions = [P_1, P_2] // Requires P_1 or P_2
 * permissions = [[P_1, P_2 P_3]] // Requires P_1 and P_2 and P3
 * permissions = [[P_1, P_2], P_3] // Requires (P_1 and P_2) or P3
 */
const hasAuthorization = (requiredPermissions, userPermissions) => (
  requiredPermissions.includes(userPermissions)
)

export default hasAuthorization
