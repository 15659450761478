const ACTION_PATH = 'app/divisaoSetor'

export const CALL_CRIAR_DIVISAO_SETOR = `${ACTION_PATH}/CALL_CRIAR_DIVISAO_SETOR`
export const CALL_ATUALIZAR_DIVISAO_SETOR = `${ACTION_PATH}/CALL_ATUALIZAR_DIVISAO_SETOR`
export const CALL_SALVAR_LISTA_DIVISAO_SETOR = `${ACTION_PATH}/CALL_SALVAR_LISTA_DIVISAO_SETOR`

export const callCriarDivisaoSetor = (divisaoSetor) => ({
  type: CALL_CRIAR_DIVISAO_SETOR,
  payload: divisaoSetor,
})

export const callAtualizarDivisaoSetor = ({ id, divisaoSetor }) => ({
  type: CALL_ATUALIZAR_DIVISAO_SETOR,
  payload: { id, divisaoSetor },
})

export const callSalvarListaDivisaoSetor = (payload) => ({
  type: CALL_SALVAR_LISTA_DIVISAO_SETOR,
  payload,
})
