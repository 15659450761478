import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import {
  apiListarLicencas,
  apiGetLicenca,
  apiAtualizarLicenca,
  apiCriarLicenca,
  apiExcluirLicenca,
} from 'store/modules/api/licencas.actions'
import { CALL_ATUALIZAR_LICENCA, CALL_CRIAR_LICENCA, CALL_SELECIONAR_LICENCA, CALL_EXCLUIR_LICENCA } from 'store/modules/licencas/licencas.actions'
import { ofLocationChangeTo } from 'utils/location'
import { getDadosUsuario } from 'store/modules/auth/auth.selectors'

const onLocationChangeToLista = (action$) => action$.pipe(
  ofLocationChangeTo(['/licencas', '/adm/licencas']),
  debounceTime(250),
  map(({ payload }) => apiListarLicencas(payload)),
)

const onSelected = (action$) => action$.pipe(
  ofType(CALL_SELECIONAR_LICENCA),
  ofLocationChangeTo('/licencas/:id'),
  debounceTime(250),
  map(({ payload }) => apiGetLicenca(payload.id)),
)

const onCallGetLicenca = (action$) => action$.pipe(
  ofType(CALL_SELECIONAR_LICENCA),
  debounceTime(250),
  map(({ payload }) => apiGetLicenca(payload.id)),
)

const onDadosLicenca = (action$, state$) => action$.pipe(
  ofLocationChangeTo('/dadosLicenca'),
  debounceTime(250),
  map(() => apiGetLicenca(getDadosUsuario(state$.value).licenca.id)),
)

const onCallCriarLicenca = (action$) => action$.pipe(
  ofType(CALL_CRIAR_LICENCA),
  debounceTime(250),
  map(({ payload }) => apiCriarLicenca(payload)),
)

const onCallAtualizarLicenca = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_LICENCA),
  debounceTime(250),
  map(({ payload }) => apiAtualizarLicenca(payload)),
)

const onCallExcluirLicenca = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_LICENCA),
  debounceTime(250),
  map(({ payload }) => apiExcluirLicenca(payload)),
)

export default combineEpics(
  onLocationChangeToLista,
  onSelected,
  onDadosLicenca,
  onCallCriarLicenca,
  onCallAtualizarLicenca,
  onCallExcluirLicenca,
  onCallGetLicenca,
)
