const ACTION_PATH = 'app/licencas'

export const CALL_CRIAR_LICENCA = `${ACTION_PATH}/CALL_CRIAR_LICENCA`
export const CALL_ATUALIZAR_LICENCA = `${ACTION_PATH}/CALL_ATUALIZAR_LICENCA`
export const CALL_SELECIONAR_LICENCA = `${ACTION_PATH}/CALL_SELECIONAR_LICENCA`
export const CALL_EXCLUIR_LICENCA = `${ACTION_PATH}/CALL_EXCLUIR_LICENCA`
export const CALL_NOVA_LICENCA = `${ACTION_PATH}/CALL_NOVA_LICENCA`

export const callCriarLicenca = (licenca) => ({
  type: CALL_CRIAR_LICENCA,
  payload: licenca,
})

export const callAtualizarLicenca = ({ id, licenca }) => ({
  type: CALL_ATUALIZAR_LICENCA,
  payload: { id, licenca },
})

export const callSelecionarLicenca = ({ id }) => ({
  type: CALL_SELECIONAR_LICENCA,
  payload: { id },
})

export const callExcluirLicenca = ({ id }) => ({
  type: CALL_EXCLUIR_LICENCA,
  payload: { id },
})

export const callNovaLicenca = () => ({
  type: CALL_NOVA_LICENCA,
})
