import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchTiposGestaoActionTypes = apiActionTypes('tiposGestao/API_LISTAR_TIPOS_GESTAO')
const createTiposGestaoActionTypes = apiActionTypes('tiposGestao/API_CRIAR_TIPOS_GESTAO')
const deleteTiposGestaoActionTypes = apiActionTypes('tiposGestao/API_EXCLUIR_TIPOS_GESTAO')
const updateTiposGestaoActionTypes = apiActionTypes('tiposGestao/API_ATUALIZAR_TIPOS_GESTAO')

export const API_LISTAR_TIPOS_GESTAO = asApiTypesObject(fetchTiposGestaoActionTypes)
export const API_CRIAR_TIPOS_GESTAO = asApiTypesObject(createTiposGestaoActionTypes)
export const API_EXCLUIR_TIPOS_GESTAO = asApiTypesObject(deleteTiposGestaoActionTypes)
export const API_ATUALIZAR_TIPOS_GESTAO = asApiTypesObject(updateTiposGestaoActionTypes)

export const apiListarTipoGestao = ({ paisId }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/tiposGestao`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchTiposGestaoActionTypes,
}, { ...meta, query: { paisId } })

export const apiCriarTipoGestao = (tipoGestao, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/tiposGestao`,
  method: 'POST',
  types: createTiposGestaoActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(tipoGestao),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiExcluirTipoGestao = ({ id }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/tiposGestao/${id}`,
  method: 'DELETE',
  types: deleteTiposGestaoActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })

export const apiAtualizarTipoGestao = ({ id, tipoGestao }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/tiposGestao/${id}`,
  method: 'PUT',
  types: updateTiposGestaoActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(tipoGestao),
}, { ...meta, successNotification: 'mensagens.atualizado' })
