import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map, ignoreElements, filter } from 'rxjs/operators'
import {
  apiGetRelatorioAuditoria,
  apiGetRelatorioSumario,
  API_GET_RELATORIO_AUDITORIA,
  API_GET_RELATORIO_SUMARIO,

} from 'store/modules/api/relatorios/relatoriosAuditoria.actions'
import { saveAs } from 'file-saver'
import { enqueueSnackbar } from 'store/modules/notifications'

import {
  CALL_RELATORIO_AUDITORIA,
  CALL_RELATORIO_SUMARIO,
} from './auditoria.actions'

const onCallRelatorioAuditoria = (action$) => action$.pipe(
  ofType(CALL_RELATORIO_AUDITORIA),
  debounceTime(250),
  map(({ payload }) => apiGetRelatorioAuditoria(payload)),
)

const onDownloadRelatorioAuditoria = (action$) => action$.pipe(
  ofType(API_GET_RELATORIO_AUDITORIA.success),
  filter(({ payload }) => !payload.error),
  // map(({ payload }) => saveAs(payload.base64, `${payload.nome}.pdf`)),
  ignoreElements(),
)

const onErrorRelatorioAuditoria = (action$) => action$.pipe(
  ofType(API_GET_RELATORIO_AUDITORIA.success),
  filter(({ payload }) => payload.error),
  map(({ payload = {} }) => enqueueSnackbar({
    message: payload.error,
    options: { variant: 'error', autoHideDuration: 3000, dismissible: true },
  })),
)

const onCallRelatorioSumario = (action$) => action$.pipe(
  ofType(CALL_RELATORIO_SUMARIO),
  debounceTime(250),
  map(({ payload }) => apiGetRelatorioSumario(payload)),
)

const onDownloadRelatorioSumario = (action$) => action$.pipe(
  ofType(API_GET_RELATORIO_SUMARIO.success),
  filter(({ payload }) => !payload.error),
  map(({ payload }) => saveAs(payload.base64, `${payload.nome}.pdf`)),
  ignoreElements(),
)

const onErrorRelatorioSumario = (action$) => action$.pipe(
  ofType(API_GET_RELATORIO_SUMARIO.success),
  filter(({ payload }) => payload.error),
  map(({ payload = {} }) => enqueueSnackbar({
    message: payload.error,
    options: { variant: 'error', autoHideDuration: 3000, dismissible: true },
  })),
)

export default combineEpics(
  onCallRelatorioAuditoria,
  onCallRelatorioSumario,
  onDownloadRelatorioAuditoria,
  onDownloadRelatorioSumario,
  onErrorRelatorioAuditoria,
  onErrorRelatorioSumario,
)
