import React from 'react'
import {
  Grid,
  Typography,
  makeStyles,
  Button,
} from '@material-ui/core'
import { validateFormValues } from 'utils/forms'
import { FieldText } from 'components/Fields'
import { Form } from 'react-final-form'
import * as yup from 'yup'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { callAtualizarUsuario, callCriarUsuario } from 'store/modules/usuarios'
import { getDadosUsuario } from 'store/modules/auth/auth.selectors'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 32,
    fontWeight: 400,
    color: theme.palette.primary.main,
  },
  subtitle: {
    fontFamily: theme.typography.secondary,
    color: theme.palette.primary.main,
    fontWeight: 400,
    margin: theme.spacing(0, 0, 6, 0),
    fontSize: 14,
  },
  input: {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 600,
  },
  formContainer: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 8,
    padding: theme.spacing(4),
    margin: theme.spacing(0, 0, 2, 0),
  },
  separator: {
    width: '96%',
    height: 1,
    background: theme.palette.grey.main,
    marginBlockEnd: theme.spacing(2),
    border: 0,
  },
}))

const UsuarioDetalhe = ({ Botoes }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const usuarioSelecionado = useSelector(getDadosUsuario)

  const handleSave = (values) => {
    if (!usuarioSelecionado && callCriarUsuario) {
      dispatch(callCriarUsuario(values))
    } else if (callAtualizarUsuario) {
      dispatch(callAtualizarUsuario({ id: usuarioSelecionado.id, usuario: values }))
    }
  }

  return (
    <Grid container item xs={6} justifyContent="center" direction="column" className={classes.formContainer}>
      <Typography component="h1" className={classes.title}>
        {t('usuarioDetalhe.meusDados')}
      </Typography>
      <Typography component="p" className={classes.subtitle}>
        {t('cadastroInicial.etapa1')}
      </Typography>

      <Form
        onSubmit={handleSave}
        validate={validateFormValues(yup.object({
          nome: yup.string().required(),
          email: yup.string().email().required(),
          funcao: yup.string().required(),
          telefone: yup.string().required(),
        }))}
        initialValues={{
          nome: usuarioSelecionado?.nome ?? '',
          email: usuarioSelecionado?.email ?? '',
          funcao: usuarioSelecionado?.funcao ?? '',
          telefone: usuarioSelecionado?.telefone ?? '',
        }}
        render={({ handleSubmit, errors }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <FieldText
                  required
                  name="nome"
                  label={t('inputs.nomeCompleto')}
                  placeholder={t('inputs.insiraNome')}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <FieldText
                  required
                  name="email"
                  label={t('inputs.email')}
                  placeholder={t('inputs.insiraEmail')}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FieldText
                  required
                  name="funcao"
                  label={t('inputs.funcao')}
                  placeholder={t('inputs.insiraFuncao')}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FieldText
                  required
                  name="telefone"
                  label={t('inputs.telefone')}
                  placeholder={t('inputs.insiraTelefone')}
                  variant="outlined"
                />
              </Grid>
              {Botoes && <Botoes extraFunc={handleSubmit} formErrors={errors} />}
              {!Botoes && (
                <Grid container direction="row" justifyContent="center">
                  <Button
                    variant="contained"
                    color="primary"
                  >
                    {t('inputs.salvar')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </form>
        )}
      />
    </Grid>
  )
}

UsuarioDetalhe.propTypes = {
  Botoes: PropTypes.func,
}

export default UsuarioDetalhe
