import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import Button from 'components/Button'
import { Grid, makeStyles } from '@material-ui/core'
import { chain, bignumber } from 'mathjs'
import { Form, FormSpy } from 'react-final-form'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import { getFaturamentoSelecionado, getIsSavingFaturamento } from 'store/modules/faturamento/faturamento.selectors'
import { callAtualizarFaturamento, callCriarFaturamento } from 'store/modules/faturamento'
import { getMoedas } from 'store/modules/moedas/moedas.selectors'
import { useTranslation } from 'react-i18next'
import { validateFormValues } from 'utils/forms'
import { FieldCurrency, FieldSelect, FieldText, FieldUpload } from 'components/Fields'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { TIPO_USUARIO } from 'utils/constantes'

const useStyles = makeStyles((theme) => ({
  randomClass: {
    color: '#FFFFFF',
  },
  submit: {
    margin: theme.spacing(4, 1.5, 2),
  },
  information: {
    color: theme.palette.primary.main,
    fontFamily: 'Poppins',
    fontSize: 10,
    fontWeight: 400,
  },
}))

const Faturamento = ({ unidadeSelecionada, anoSelecionado, setChangeTab }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const faturamentoSelecionado = useSelector(getFaturamentoSelecionado)
  const listaMoedas = useSelector(getMoedas)
  const isSaving = useSelector(getIsSavingFaturamento)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const calcularFaturamento = ({ faturamentoBruto, cotacaoDolar } = {}) => {
    let faturamento = null
    if (faturamentoBruto && cotacaoDolar) {
      faturamento = chain(bignumber(faturamentoBruto))
        .divide(bignumber(cotacaoDolar))
        .number()
        .done()
    }
    return faturamento
  }

  const handleSave = (values) => {
    const data = {
      ...values,
      unidadeId: get(unidadeSelecionada, 'id'),
      ano: anoSelecionado,
      empresaId: get(unidadeSelecionada, 'empresaId'),
    }

    if (!faturamentoSelecionado && callCriarFaturamento) {
      dispatch(callCriarFaturamento(data))
    } else if (callAtualizarFaturamento) {
      dispatch(callAtualizarFaturamento({ id: faturamentoSelecionado.id, faturamento: data }))
    }
  }

  const handleValueChange = (form, { faturamentoBruto, cotacaoDolar }) => {
    form.change('faturamento', calcularFaturamento({ faturamentoBruto, cotacaoDolar }))
  }

  return (
    <Form
      onSubmit={handleSave}
      initialValues={{
        moedaId: faturamentoSelecionado?.moedaId ?? null,
        faturamento: faturamentoSelecionado?.faturamento ?? null,
        cotacaoDolar: faturamentoSelecionado?.cotacaoDolar ?? null,
        faturamentoBruto: faturamentoSelecionado?.faturamentoBruto ?? null,
        evidenciaOcorrencia: faturamentoSelecionado?.evidenciaOcorrencia ?? '',
        comentario: faturamentoSelecionado?.comentario ?? '',
        anexo: faturamentoSelecionado?.anexo ?? null,
      }}
      validate={validateFormValues(yup.object({
        cotacaoDolar: yup.number().required().positive().nullable(),
        faturamentoBruto: yup.number().required().positive().nullable(),
        moedaId: yup.number().required().nullable(),
      }))}
      render={({ handleSubmit, submitting, values, form, pristine, valid }) => (
        <form onSubmit={handleSubmit}>
          <FormSpy
            onChange={({ values: valuesSpy, initialValues }) => {
              setChangeTab({
                /**
                 * \//FIXME: { ...valuesSpy, faturamento: initialValues.faturamento }
                 * O valor do faturamento está sendo alterado no form
                 * em uma segunda renderização.
                 *
                 * Como o campo é um campo preenchido automáticamente sobrescrevi para
                 * que na validação de change do form não de problema.
                 *
                 * Exemplo: 0.23909215417726476 => 0.23909215417726473
                 */
                values: { ...valuesSpy, faturamento: initialValues.faturamento },
                initialValues,
              })
            }}
            subscription={{ values: true, initialValues: true, pristine: true }}
          />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FieldCurrency
                required
                name="faturamentoBruto"
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                label={t('inputs.faturamentoBrutoReferenteAnoBase')}
                onChange={handleValueChange(form, values)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldSelect
                required
                id="moedaId"
                name="moedaId"
                label={t('inputs.moeda')}
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                options={(listaMoedas || []).map((currency) => ({
                  label: t(currency.nome),
                  value: currency.id,
                }))}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldCurrency
                required
                name="cotacaoDolar"
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                label={t('inputs.cotacaoDolarDezembroAnoBase')}
                onChange={handleValueChange(form, values)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldCurrency
                disabled
                name="faturamento"
                label={t('inputs.faturamentoUs')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <small className={classes.information}>
                {t('faturamento.informacao')}
              </small>
            </Grid>
            <Grid item xs={12}>
              <FieldText
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                name="comentario"
                label={t('inputs.comentario')}
                placeholder={t('inputs.insiraComentario')}
                multiline
                minRows={5}
                maxRows={5}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldText
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                name="evidenciaOcorrencia"
                label={t('inputs.evidenciaOcorrencia')}
                placeholder={t('inputs.comentarioEmpresa')}
                multiline
                minRows={5}
                maxRows={5}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldUpload name="anexo" disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR} />
            </Grid>
            <Grid container justifyContent="flex-end" className={classes.submit}>
              <Button
                type="submit"
                disabled={submitting || !unidadeSelecionada || pristine || !valid}
                loading={isSaving}
              >
                {t('inputs.salvar')}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  )
}

Faturamento.propTypes = {
  unidadeSelecionada: PropTypes.object,
  anoSelecionado: PropTypes.string,
  setChangeTab: PropTypes.func,
}

export default Faturamento
