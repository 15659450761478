import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import {
  API_LISTAR_TIPOS_GESTAO,
  API_CRIAR_TIPOS_GESTAO,
  API_EXCLUIR_TIPOS_GESTAO,
  API_ATUALIZAR_TIPOS_GESTAO,
} from 'store/modules/api/tiposGestao.actions'

import { filter } from 'lodash'

const lista = (state = [], { type, payload }) => {
  switch (type) {
    case API_LISTAR_TIPOS_GESTAO.success:
      return payload.sort((a, b) => a.nome.localeCompare(b.nome))
    case API_CRIAR_TIPOS_GESTAO.success:
      return [...state, payload].sort((a, b) => a.nome.localeCompare(b.nome))
    case API_ATUALIZAR_TIPOS_GESTAO.success: return state.map((e) => (e.id === payload.id ?
      payload : e))
    case API_EXCLUIR_TIPOS_GESTAO.success: return filter(state, (o) => o.id !== Number(payload))
    default: return state
  }
}

const isCarregandoLista = loadingReducer(false, API_LISTAR_TIPOS_GESTAO)

const isSaving = loadingReducer(false, API_CRIAR_TIPOS_GESTAO)

const isUpdating = loadingReducer(false, API_ATUALIZAR_TIPOS_GESTAO)

export default combineReducers({
  lista,
  isCarregandoLista,
  isSaving,
  isUpdating,
})
