import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { API_LISTAR_UNIDADES } from 'store/modules/api/unidades.actions'
import { API_LISTAR_DADOS_IPB } from 'store/modules/api/pressaoBiodiversidade.actions'

const lista = (state = null, { type, payload }) => (
  (type === API_LISTAR_UNIDADES.success) ? payload.lista : state
)

const total = (state = null, { type, payload }) => (
  (type === API_LISTAR_UNIDADES.success) ? payload.total : state
)

const unidadeSelecionada = (state = null, { type, payload }) => (
  (type === API_LISTAR_DADOS_IPB.success) ? payload.unidade : state
)

const isCarregandoLista = loadingReducer(false, API_LISTAR_UNIDADES)

export default combineReducers({
  unidadeSelecionada,
  lista,
  total,
  isCarregandoLista,
})
