import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import {
  apiCriarEstacaoMeteorologica,
  apiExcluirEstacaoMeteorologica,
  apiAtualizarEstacaoMeteorologica,
} from 'store/modules/api/estacoesMeteorologicas.actions'
import {
  CALL_CRIAR_ESTACAO_METEOROLOGICA,
  CALL_EXCLUIR_ESTACAO_METEOROLOGICA,
  CALL_ATUALIZAR_ESTACAO_METEOROLOGICA,
} from 'store/modules/estacaoMeteorologica/estacaoMeteorologica.actions'

const onCallCriarEstacaoMeteorologica = (action$) => action$.pipe(
  ofType(CALL_CRIAR_ESTACAO_METEOROLOGICA),
  debounceTime(250),
  map(({ payload }) => apiCriarEstacaoMeteorologica(payload)),
)

const onCallExcluirEstacaoMeteorologica = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_ESTACAO_METEOROLOGICA),
  debounceTime(250),
  map(({ payload }) => apiExcluirEstacaoMeteorologica(payload)),
)

const onCallAtualizarEstacaoMeteorologica = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_ESTACAO_METEOROLOGICA),
  debounceTime(250),
  map(({ payload }) => apiAtualizarEstacaoMeteorologica(payload)),
)

export default combineEpics(
  onCallCriarEstacaoMeteorologica,
  onCallExcluirEstacaoMeteorologica,
  onCallAtualizarEstacaoMeteorologica,
)
