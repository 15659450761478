import React, { useState, useEffect } from 'react'
import {
  Grid,
  makeStyles,
} from '@material-ui/core'
import { Form, FormSpy } from 'react-final-form'
import { FieldSelect, FieldText, FieldUpload, FieldAutocomplete } from 'components/Fields'
import * as yup from 'yup'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { get, find } from 'lodash'
import { chain, bignumber } from 'mathjs'
import { callAtualizarGasEfeitoEstufa, callCriarGasEfeitoEstufa, callNovoGasEfeitoEstufa } from 'store/modules/gasesEfeitoEstufa'
import { getConversoesMassa } from 'store/modules/conversoesMassa/conversoesMassa.selectors'
import { getConversoesTempo } from 'store/modules/conversoesTempo/conversoesTempo.selectors'
import { getGasesEfeitoEstufaSelecionado, getIsSavingGasesEfeitoEstufa } from 'store/modules/gasesEfeitoEstufa/gasesEfeitoEstufa.selectors'
import { getEscopoGases } from 'store/modules/escopoGases/escopoGases.selectors'
import { getTiposGases } from 'store/modules/tiposGases/tiposGases.selectors'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'
import { validateFormValues } from 'utils/forms'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { TIPO_USUARIO } from 'utils/constantes'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(12, 1.5, 2),
  },
}))

const GasesEfeitoEstufa = ({ unidadeSelecionada, anoSelecionado, setChangeTab }) => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const [exibirFormulario, setExibirFormulario] = useState(false)

  const gasEfeitoEstufaSelecionado = useSelector(getGasesEfeitoEstufaSelecionado)
  const listaEscopoGases = useSelector(getEscopoGases)
  const listaTiposGases = useSelector(getTiposGases)
  const listaConversoesMassa = useSelector(getConversoesMassa)
  const listaConversoesTempo = useSelector(getConversoesTempo)
  const isSaving = useSelector(getIsSavingGasesEfeitoEstufa)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const tipoGasesOrder = listaTiposGases.sort((a, b) => a.gas.localeCompare(b.gas, i18n.language))

  const calcularQuantidade = ({ conversaoMassaId, conversaoTempoId, quantidade, gasesTipoId }) => {
    let quantidadeCalculada = null
    if (conversaoMassaId && conversaoTempoId && quantidade && gasesTipoId) {
      const conversaoMassa = find(listaConversoesMassa, { id: conversaoMassaId })
      const conversaoTempo = find(listaConversoesTempo, { id: conversaoTempoId })
      const tipoGas = find(listaTiposGases, { id: gasesTipoId })

      quantidadeCalculada = chain(bignumber(quantidade))
        .multiply(bignumber(tipoGas.pag100))
        .multiply(bignumber(conversaoMassa.fator))
        .multiply(bignumber(conversaoTempo.fator))
        .number()
        .format({ notation: 'fixed', precision: 5 })
        .done()

      if (Number(quantidadeCalculada) === gasEfeitoEstufaSelecionado?.quantidadeCalculada) {
        return gasEfeitoEstufaSelecionado.quantidadeCalculada
      }
    }

    return quantidadeCalculada
  }

  const handleValueChange = (form, {
    conversaoMassaId, conversaoTempoId, quantidade, gasesTipoId,
  }) => {
    form.change('quantidadeCalculada', calcularQuantidade({ conversaoMassaId, conversaoTempoId, quantidade, gasesTipoId }))
  }

  const alterarStateFormulario = () => {
    setExibirFormulario(!exibirFormulario)
    dispatch(callNovoGasEfeitoEstufa())
  }

  const handleSave = (values, form) => {
    const data = {
      ...values,
      unidadeId: get(unidadeSelecionada, 'id'),
      ano: anoSelecionado,
      empresaId: get(unidadeSelecionada, 'empresaId'),
    }

    if (!gasEfeitoEstufaSelecionado && callCriarGasEfeitoEstufa) {
      dispatch(callCriarGasEfeitoEstufa(data))
    } else if (callAtualizarGasEfeitoEstufa) {
      dispatch(callAtualizarGasEfeitoEstufa({
        id: gasEfeitoEstufaSelecionado.id,
        gasEfeitoEstufa: data,
      }))
    }
    form.reset()
    alterarStateFormulario()
  }

  useEffect(() => {
    if (gasEfeitoEstufaSelecionado?.id) {
      setExibirFormulario(true)
    }
  }, [gasEfeitoEstufaSelecionado])

  useEffect(() => {
    setExibirFormulario(false)
  }, [unidadeSelecionada])

  return exibirFormulario ? (
    <Form
      onSubmit={handleSave}
      initialValues={{
        descricao: gasEfeitoEstufaSelecionado?.descricao ?? '',
        quantidade: gasEfeitoEstufaSelecionado?.quantidade ?? null,
        escopoGasesId: gasEfeitoEstufaSelecionado?.escopoGasesId ?? null,
        gasesTipoId: gasEfeitoEstufaSelecionado?.gasesTipoId ?? null,
        conversaoMassaId: gasEfeitoEstufaSelecionado?.conversaoMassaId ?? null,
        conversaoTempoId: gasEfeitoEstufaSelecionado?.conversaoTempoId ?? null,
        evidenciaOcorrencia: gasEfeitoEstufaSelecionado?.evidenciaOcorrencia ?? '',
        comentario: gasEfeitoEstufaSelecionado?.comentario ?? '',
        quantidadeCalculada: gasEfeitoEstufaSelecionado?.quantidadeCalculada ?? null,
        anexo: gasEfeitoEstufaSelecionado?.anexo ?? null,
      }}
      validate={validateFormValues(yup.object({
        descricao: yup.string().required(),
        escopoGasesId: yup.number().required(),
        gasesTipoId: yup.number().required(),
        quantidade: yup.number().positive().required(),
        conversaoMassaId: yup.number().required(),
        conversaoTempoId: yup.number().required(),
        evidenciaOcorrencia: yup.string(),
        comentario: yup.string(),
      }))}
      render={({ handleSubmit, submitting, values, form, pristine, valid }) => (
        <form onSubmit={handleSubmit}>
          <FormSpy
            onChange={({ values: valuesSpy, initialValues }) => {
              setChangeTab({
                values: valuesSpy,
                initialValues,
              })
            }}
            subscription={{ values: true, initialValues: true, pristine: true }}
          />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FieldText
                required
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                name="descricao"
                label={t('inputs.descricao')}
                placeholder={t('inputs.insiraDescricao')}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldAutocomplete
                required
                name="gasesTipoId"
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                label={t('inputs.gas')}
                options={tipoGasesOrder.map((option) => ({
                  value: option.id,
                  label: option.gas,
                }))}
                onChange={handleValueChange(form, values)}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldAutocomplete
                required
                name="escopoGasesId"
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                label={t('inputs.escopo')}
                options={listaEscopoGases.map((option) => ({
                  value: option.id,
                  label: t(option.escopo),
                }))}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldText
                required
                type="number"
                name="quantidade"
                label={t('inputs.quantidade')}
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                onChange={handleValueChange(form, values)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldSelect
                required
                id="conversaoMassaId"
                name="conversaoMassaId"
                label={t('inputs.unidade')}
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                options={(listaConversoesMassa || []).map((option) => ({
                  label: t(option.unidade),
                  value: option.id,
                }))}
                onChange={handleValueChange(form, values)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldSelect
                required
                id="conversaoTempoId"
                name="conversaoTempoId"
                label={t('inputs.periodo')}
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                options={(listaConversoesTempo || []).map((option) => ({
                  label: t(option.unidade),
                  value: option.id,
                }))}
                onChange={handleValueChange(form, values)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldText
                disabled
                type="number"
                name="quantidadeCalculada"
                label={t('inputs.quantidadeTco2Ano')}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldText
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                name="comentario"
                label={t('inputs.comentario')}
                placeholder={t('inputs.insiraComentario')}
                multiline
                minRows={5}
                maxRows={5}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldText
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                name="evidenciaOcorrencia"
                label={t('inputs.evidenciaOcorrencia')}
                placeholder={t('inputs.comentarioEmpresa')}
                multiline
                minRows={5}
                maxRows={5}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldUpload name="anexo" disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR} />
            </Grid>
            <Grid container direction="row" justifyContent="space-between" className={classes.submit}>
              <Button variant="danger" onClick={alterarStateFormulario}>
                {t('inputs.cancelar')}
              </Button>
              <Button
                type="submit"
                disabled={submitting || !unidadeSelecionada || pristine || !valid}
                loading={isSaving}
              >
                {t('inputs.salvar')}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  ) : (
    <>
      {tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LEITOR && (
        <Button onClick={alterarStateFormulario}>
          {t('pressaoBiodiversidade.incluirOcorrencia')}
        </Button>
      )}
    </>
  )
}

GasesEfeitoEstufa.propTypes = {
  unidadeSelecionada: PropTypes.object,
  anoSelecionado: PropTypes.string,
  setChangeTab: PropTypes.func,
}

export default GasesEfeitoEstufa
