import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const authenticateActionTypes = apiActionTypes('auth/API_AUTHENTICATE')
const validateActionTypes = apiActionTypes('auth/API_VALIDATE')
const resetPasswordRequestActionTypes = apiActionTypes('auth/API_RESET_PASSWORD_REQUEST')
const resetPasswordActionTypes = apiActionTypes('auth/API_RESET_PASSWORD')

export const API_AUTHENTICATE = asApiTypesObject(authenticateActionTypes)
export const API_VALIDATE = asApiTypesObject(validateActionTypes)
export const API_RESET_PASSWORD_REQUEST = asApiTypesObject(resetPasswordRequestActionTypes)
export const API_RESET_PASSWORD = asApiTypesObject(resetPasswordActionTypes)

export const apiAuthenticate = (data, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/auth`,
  method: 'POST',
  types: authenticateActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(data),
}, { ...meta, defaultError: false })

export const apiValidate = (data, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/validate`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: validateActionTypes,
}, { ...meta, defaultError: false })

export const apiResetPasswordRequest = (data, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/auth/resetPasswordRequest`,
  method: 'POST',
  types: resetPasswordRequestActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(data),
}, { ...meta, successNotification: 'mensagens.emailEnviadoAlterarSenha' })

export const apiResetPassword = (data, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/auth/resetPassword`,
  method: 'POST',
  types: resetPasswordActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(data),
}, { ...meta, successNotification: 'mensagens.senhaAlteradaSucesso' })
