import { isStringOrNull, normalizeArray } from '../utils'

const { isArray } = Array

/**
 * Creates an array of 3 action types given a base "type" and options.
 * Intended to be used alongside the RSAA specification defined by `redux-api-middleware`:
 *   https://github.com/agraboso/redux-api-middleware
 *
 * ```
 * type API_ACTION_TYPES = [ request: string, success: string, failure: string ];
 * type ApiActionTypes = { request: string, success: string, failure: string };
 * ```
 *
 * Example:
 * ```
 * apiActionTypes('Foo', { suffix: ['R', 'S', 'F'], delim: '.' });
 * // -> ['Foo.R', 'Foo.S', 'Foo.F']
 * ```
 *
 * @alias module:api
 * @param {string} type base "type" all 3 result actions will share
 * @param {{}} [options] additional options
 * @param {string|string[]} [options.prefix] custom prefix for action type
 * @param {string|string[]} [options.suffix] custom suffix for action type
 * @param {string} [options.delim] custom delimiter for string segments
 * @return {API_ACTION_TYPES} array with 3 types; [request, success, failure]
 */
const apiActionTypes = (type, options = {}) => {
  const {
    prefix = null,
    suffix = ['Request', 'Success', 'Failure'],
    delim = '/'
  } = options

  const TYPE = `${prefix ? delim : ''}${type}${suffix ? delim : ''}`
  const prefixes = isStringOrNull(prefix) ? fillWith(prefix) : prefix
  const suffixes = isStringOrNull(suffix) ? fillWith(suffix) : suffix

  return [
    `${prefixes[0]}${TYPE}${suffixes[0]}`,
    `${prefixes[1]}${TYPE}${suffixes[1]}`,
    `${prefixes[2]}${TYPE}${suffixes[2]}`
  ]
}

export default apiActionTypes

/**
 * Convert Array API Action Types to Object form
 * @alias module:api
 * @param {string[]} array of 3 api action types: [ request, success, failure ]
 * @return {{}} object of 3 api action types: { request, success, failure }
 */
export const asApiTypesObject = (apiTypes = []) => {
  const [ request, success, failure ] = apiTypes
  return { request, success, failure }
}

/**
 * Convert Object API Action Types to Array form
 * @alias module:api
 * @param {{}} object of 3 api action types: { request, success, failure }
 * @return {string[]} array of 3 api action types: [ request, success, failure ]
 */
export const asApiTypesArray = (apiTypes = {}) => {
  const { request, success, failure } = apiTypes
  return [ request, success, failure ]
}

/**
 * Accept either API Action Types in Array or Object form, and return Array form
 * @alias module:api
 * @param {{}|string[]} object or array of 3 api action types
 * @return {string[]} array of 3 api action types: [ request, success, failure ]
 */
export const normalizeApiTypes = (types) => {
  if (isArray(types)) return types
  return asApiTypesArray(types)
}

/**
 * Like `normalizeApiTypes`, but supports api action types specified as arrays,
 * and ensures normalized result also defines api action types as an array of
 * arrays of action type strings.
 * @alias module:api
 * @param {{}|string[]} object or array of 3 api action types
 * @return {string[][]} array of 3 api action types: [ request: string[], success: string[], failure: string[] ]
 */
export const normalizeApiTypeArrays = (types) => {
  const [ request, success, failure ] = normalizeApiTypes(types)
  return [
    normalizeArray(request),
    normalizeArray(success),
    normalizeArray(failure)
  ]
}

/**
 * Configurable version of apiActionTypes.
 * Accepts `options` and returns a function which calls apiActionTypes with same `options`.
 * @alias module:api
 * @param {{}} options apiActionTypes options
 * @return {function} preconfigured apiActionTypes function that only accepts 1 `type` argument
 */
export const configureApiActionTypes = (options) => (type) => apiActionTypes(type, options)

function fillWith (str) {
  return Array(3).fill(str || '')
}
