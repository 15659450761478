import { ACTION_PATH } from '../utils'

/**
 * Key used to define the "cancel action" function in action meta
 * @alias module:core
 * @constant {string}
 */
export const CANCEL = `${ACTION_PATH}/cancelAction`

/**
 * Default "Cancel Action" type
 * @alias module:core
 * @constant {string}
 */
export const ACTION_CANCELED = `${ACTION_PATH}/canceled`

/**
 * Action Creator for ACTION_CANCELED actions
 * @alias module:core
 * @param {string} action the original action to be canceled
 * @return {{}} FSA w/ `ACTION_CANCELED` as type and `action` as payload
 */
export const canceledAction = (action) => ({
  type: ACTION_CANCELED,
  payload: action
})
