// only support Observable implementations compliant with the latest tc39 proposal:
// https://github.com/tc39/proposal-observable
import { apiMiddleware } from 'redux-api-middleware'

/**
 * Factory method to configure methods for directly calling RSAAs, without relying on
 * a redux store's configured middleware.
 *
 * Generally used for _advanced_ use-cases, where you'd like more control over async
 * action handling for one or more API requests within a middleware _other_ than the
 * redux-api-middleware instance configured with the redux store (e.g. redux-observable
 * or redux-saga).
 *
 * ```
 * type CALL_RSAA_API = {|
 *   fetchRSAA: (rsaa: RSAA, getState: Function) => [Promise, FSA],
 *   fromRSAA: (rsaa: RSAA, getState: Function) => Observable
 * |}
 * ```
 *
 * Example Configuration:
 * ```
 * // Some app middlewares we'd like to use for RSAAs.
 * // These should be ones that target the RSAA action itself (i.e. isRSAA).
 * const rsaaMiddleware = [
 *   authMiddleware,
 *   apiGlobalRetryMiddleware,
 *   rsaaMetaMiddleware,
 * ]
 *
 * // When using `fromRSAA` or `fetchRSAA`, make sure you _don't_ include
 * // any middleware that use `dispatch` internally!
 * export const { fromRSAA, fetchRSAA } = configureCallRSAA({
 *   ObservableClass: ActionsObservable,
 *   rsaaMiddleware,
 *   fsaMiddleware: [rsaaRetryMiddleware({ useDispatch: false })]
 * })
 *
 * ```
 *
 * Example Use (w/ redux-observable):
 * ```
 * // Returns an array whose first value is a Promise for the `fetch` request, and
 * // whose second value is the "request" FSA.  Promise will resolve the async "success"
 * // or "failure" FSA.  If you'd like to dispatch the "request" action before handling
 * // the resolved value, you must do so manually.
 * const testFetchRSAA = (action$, { getState }) =>
 *   action$.ofType('TEST_FETCH_RSAA').switchMap(() => {
 *     const [ promise, request ] = fetchRSAA(
 *       rsaaCreator({ foo: 'bar'}),
 *       getState
 *     )
 *     return Observable.from(promise).startWith(request)
 *   })
 *
 * // Returns an Observable which will emit the "request" and "success|failure" FSAs to
 * // any subscriptions.  Useful for utilizing rxjs operators that leverage higher order
 * // observables, like switchMap.
 * const testFromRSAA = (action$, { getState }) =>
 *   action$.ofType('TEST_FROM_RSAA').switchMap(() => (
 *     fromRSAA(rsaaCreator({ foo: 'bar'}), getState)
 *   ))
 * ```
 *
 * @alias module:api
 * @param {Observable} ObservableClass tc39 complient Observable class to use for `fromRSAA`
 * @param {function} [middlewareFn] override the redux-api-middleware with different implementation (useful for mocks/tests, generally should not use in production!)
 * @param {function[]} [fsaMiddleware] list of "redux" middleware functions to use for the RSAA's resulting FSAs
 * @param {function} [fsaTransform] custom "transform" to apply to resulting FSAs from called RSAA
 * @param {function[]} [rsaaMiddleware] list of "redux" middleware functions process incomming RSAA
 * @param {function} [rsaaTransform] custom "transform" to apply to incomming RSAA
 * @return {CALL_RSAA_API} the 2 "Call RSAA" API methods
 */
const configureCallRSAA = (options = {}) => {
  const {
    ObservableClass = defaultObservable(),
    middlewareFn = apiMiddleware,
    fsaMiddleware = [],
    fsaTransform = n => n,
    rsaaMiddleware = [],
    rsaaTransform = n => n
  } = options
  const getRsaaTransform = (rsaa, getState) => {
    const args = [ rsaaMiddleware, rsaa, getState ]
    return rsaaTransform(reduceMiddleware('rsaa', ...args), getState)
  }
  const getFsaTransform = (action, getState) => {
    const args = [ fsaMiddleware, action, getState ]
    return fsaTransform(reduceMiddleware('fsa', ...args), getState)
  }
  return {
    fetchRSAA: fetchRSAA(middlewareFn, getRsaaTransform, getFsaTransform),
    fromRSAA: fromRSAA(ObservableClass, middlewareFn, getRsaaTransform, getFsaTransform)
  }
}

export default configureCallRSAA

export const ErrorObservable = Object.freeze({
  create: (observer) => {
    throw new Error('configureFromRSAA: Must be assigned an Observable Class!')
  }
})

const fetchRSAA = (middlewareFn, rsaaTransform, fsaTransform) => (rsaa, getState) => {
  const nextRSAA = rsaaTransform(rsaa, getState)
  let requestAction = null
  const promise = new Promise((resolve, reject) => {
    const next = (action) => {
      if (requestAction) {
        resolve(fsaTransform(action, getState))
      } else {
        requestAction = fsaTransform(action, getState)
      }
    }
    const fetchRSAA = middlewareFn({ getState })(next)
    fetchRSAA(nextRSAA).catch((e) => reject(e))
  })
  return [promise, requestAction]
}

const fromRSAA = (ObservableClass, middlewareFn, rsaaTransform, fsaTransform) => (rsaa, getState) => (
  ObservableClass.create(observer => {
    const nextRSAA = rsaaTransform(rsaa, getState)

    // NOTE: We need this wrapper `next` method that passes through args to
    // work around a scoping (?) issue in RxJS:
    // https://github.com/ReactiveX/rxjs/issues/1981
    const next = (action) => observer.next(fsaTransform(action, getState))
    const fetchRSAA = middlewareFn({ getState })(next)

    fetchRSAA(nextRSAA).then(a => {
      observer.complete()
    }).catch((e) => {
      observer.error(e)
    })
  })
)

function throwDispatchError (action, mwType, index) {
  throw new Error(`configureCallRSAA: configured middleware cannot dispatch action!\n
    dispatched: ${action.toString()};\n
    middleware type: ${mwType}\n
    middleware index: ${index};`)
}

function reduceMiddleware (
  type,
  middleware,
  action,
  getState
) {
  return middleware.reduce((accAction, curMiddleware, i) => {
    let nextAction = null
    const dispatch = a => throwDispatchError(a, type, i)
    const mw = curMiddleware({ getState, dispatch })
    const next = a => { nextAction = a }
    mw(next)(accAction)
    return nextAction
  }, action)
}

function defaultObservable () {
  return ErrorObservable
}
