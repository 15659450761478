import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchEmpresasActionTypes = apiActionTypes('empresas/API_LISTAR_EMPRESAS')
const getEmpresaActionTypes = apiActionTypes('empresas/API_GET_EMPRESA')
const getEmpresaUsuarioActionTypes = apiActionTypes('empresas/API_GET_EMPRESA_USUARIO')
const createEmpresaActionTypes = apiActionTypes('empresas/API_CRIAR_EMPRESA')
const updateEmpresaActionTypes = apiActionTypes('empresas/API_ATUALIZAR_EMPRESA')
const deleteEmpresaActionTypes = apiActionTypes('empresas/API_EXCLUIR_EMPRESA')
const finalizarCadastroActionTypes = apiActionTypes('empresas/API_FINALIZAR_CADASTRO')

export const API_LISTAR_EMPRESAS = asApiTypesObject(fetchEmpresasActionTypes)
export const API_GET_EMPRESA = asApiTypesObject(getEmpresaActionTypes)
export const API_GET_EMPRESA_USUARIO = asApiTypesObject(getEmpresaUsuarioActionTypes)
export const API_CRIAR_EMPRESA = asApiTypesObject(createEmpresaActionTypes)
export const API_ATUALIZAR_EMPRESA = asApiTypesObject(updateEmpresaActionTypes)
export const API_EXCLUIR_EMPRESA = asApiTypesObject(deleteEmpresaActionTypes)
export const API_FINALIZAR_CADASTRO = asApiTypesObject(finalizarCadastroActionTypes)

export const apiListarEmpresas = (data, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/empresas`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchEmpresasActionTypes,
}, { ...meta })

export const apiGetEmpresa = (id, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/empresas/${id}`,
  method: 'GET',
  types: getEmpresaActionTypes,
}, meta)

export const apiGetEmpresaUsuario = (id, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/empresas/${id}`,
  method: 'GET',
  types: getEmpresaUsuarioActionTypes,
}, meta)

export const apiCriarEmpresa = (empresa, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/empresas`,
  method: 'POST',
  types: createEmpresaActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(empresa),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiAtualizarEmpresa = ({ id, empresa, successNotification }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/empresas/${id}`,
  method: 'PUT',
  types: updateEmpresaActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(empresa),
}, { ...meta, successNotification })

export const apiFinalizarCadastroInicial = (id, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/empresas/finalizarCadastroInicial/${id}`,
  method: 'PUT',
  types: finalizarCadastroActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, meta)

export const apiExcluirEmpresa = ({ id }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/empresas/${id}`,
  method: 'DELETE',
  types: deleteEmpresaActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })
