import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box, makeStyles, Tab, Typography } from '@material-ui/core'
import Button from 'components/Button'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { TIPO_USUARIO } from 'utils/constantes'
import TabClasses from './TabClasses'
import TabIndicadores from './TabIndicadores'

export const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
  screenTitle: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(5),
    fontSize: theme.typography.pxToRem(24),
  },
  panel: {
    padding: theme.spacing(4, 0),
  },
  tabIndicator: {
    height: 4,
    transform: 'scaleX(0.8)',
    borderRadius: theme.spacing(2, 2, 0, 0),
    backgroundColor: theme.palette.blue.main,
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
  tabList: {
    '& .MuiTab-root': {
      '&.Mui-selected': {
        color: '#31ADE3',
      },
    },
  },
}))

const ClassesIndicadores = ({ paisId }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const tabs = [
    { key: '0', title: t('paisesCalculos.Classes'), component: TabClasses },
    { key: '1', title: t('paisesCalculos.Indicadores'), component: TabIndicadores },
  ]

  const [currentTab, setCurrentTab] = useState(tabs[0].key)

  const [controlAdded, setControlAdded] = useState(false)
  const childRef = useRef()

  const hearChange = () => {
    if (controlAdded) {
      setControlAdded(false)
    }
  }

  const desabilitarFormulario = Boolean(tipoUsuarioLogado === TIPO_USUARIO.ADMIN.OBSERVADOR)

  return (
    <>
      <div className={classes.sectionTitle}>
        <Typography className={classes.screenTitle}>
          {t('paisesCalculos.TabelaIRC')}
        </Typography>
      </div>
      <TabContext value={currentTab}>
        <div className={classes.sectionHeader}>
          <Box>
            <TabList
              classes={{ indicator: classes.tabIndicator }}
              className={classes.tabList}
            >
              {
                tabs.map((elem, index) => (
                  <Tab
                    label={elem.title}
                    value={elem.key}
                    key={index}
                    onClick={() => { hearChange(); setCurrentTab(elem.key) }}
                  />
                ))
              }
            </TabList>
          </Box>

          {currentTab === '0' && (
            <Button
              disabled={controlAdded || desabilitarFormulario}
              onClick={() => { childRef.current.handleButtonAdded() }}
            >
              {t('inputs.AdicionarClasse')}
            </Button>
          )}

        </div>
        {tabs.map(({ component: Component, ...tab }) => (
          <TabPanel className={classes.panel} value={tab.key} key={tab.key}>
            <Component
              paisId={paisId}
              controlAdded={controlAdded || desabilitarFormulario}
              setControlAdded={setControlAdded}
              ref={childRef}
            />
          </TabPanel>
        ))}
      </TabContext>
    </>
  )
}

ClassesIndicadores.propTypes = {
  paisId: PropTypes.number,
}

export default ClassesIndicadores
