import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { sortBy } from 'lodash'
import { API_LISTAR_TIPO_USUARIOS } from 'store/modules/api/tipoUsuarios.actions'

const lista = (state = [], { type, payload }) => (
  (type === API_LISTAR_TIPO_USUARIOS.success) ? sortBy(payload, ['tipo']) : state
)

const isCarregandoLista = loadingReducer(false, API_LISTAR_TIPO_USUARIOS)

export default combineReducers({
  lista,
  isCarregandoLista,
})
