import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import {
  apiListarIndicadoresCriterio,
  apiAtualizarIndicador,
  apiCriarIndicador,
  apiExcluirIndicador,
} from 'store/modules/api/indicadores.actions'
import {
  CALL_LISTAR_INDICADORES_CRITERIO,
  CALL_ATUALIZAR_INDICADOR,
  CALL_CRIAR_INDICADOR,
  CALL_EXCLUIR_INDICADOR,
} from 'store/modules/indicadores/indicadores.actions'

const onCallBuscarIndicadoresCriterio = (action$) => action$.pipe(
  ofType(CALL_LISTAR_INDICADORES_CRITERIO),
  debounceTime(250),
  map(({ payload }) => apiListarIndicadoresCriterio(payload)),
)

const onCallCriarIndicador = (action$) => action$.pipe(
  ofType(CALL_CRIAR_INDICADOR),
  debounceTime(250),
  map(({ payload }) => apiCriarIndicador(payload)),
)

const onCallAtualizarIndicador = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_INDICADOR),
  debounceTime(250),
  map(({ payload }) => apiAtualizarIndicador(payload)),
)

const onCallExcluirIndicador = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_INDICADOR),
  debounceTime(250),
  map(({ payload }) => apiExcluirIndicador(payload)),
)

export default combineEpics(
  onCallBuscarIndicadoresCriterio,
  onCallCriarIndicador,
  onCallAtualizarIndicador,
  onCallExcluirIndicador,
)
