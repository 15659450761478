import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchLicencasActionTypes = apiActionTypes('licencas/API_LISTAR_LICENCA')
const getLicencaActionTypes = apiActionTypes('licencas/API_GET_LICENCA')
const createLicencaActionTypes = apiActionTypes('licencas/API_CRIAR_LICENCA')
const updateLicencaActionTypes = apiActionTypes('licencas/API_ATUALIZAR_LICENCA')
const deleteLicencaActionTypes = apiActionTypes('licencas/API_EXCLUIR_LICENCA')

export const API_LISTAR_LICENCAS = asApiTypesObject(fetchLicencasActionTypes)
export const API_GET_LICENCA = asApiTypesObject(getLicencaActionTypes)
export const API_CRIAR_LICENCA = asApiTypesObject(createLicencaActionTypes)
export const API_ATUALIZAR_LICENCA = asApiTypesObject(updateLicencaActionTypes)
export const API_EXCLUIR_LICENCA = asApiTypesObject(deleteLicencaActionTypes)

export const apiListarLicencas = (data, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/licencas`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchLicencasActionTypes,
}, { ...meta })

export const apiGetLicenca = (id, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/licencas/${id}`,
  method: 'GET',
  types: getLicencaActionTypes,
}, meta)

export const apiCriarLicenca = (licenca, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/licencas`,
  method: 'POST',
  types: createLicencaActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(licenca),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiAtualizarLicenca = ({ id, licenca }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/licencas/${id}`,
  method: 'PUT',
  types: updateLicencaActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(licenca),
}, { ...meta, successNotification: 'mensagens.atualizado' })

export const apiExcluirLicenca = ({ id }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/licencas/${id}`,
  method: 'DELETE',
  types: deleteLicencaActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })
