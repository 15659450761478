import { debounceTime, map } from 'rxjs/operators'
import { combineEpics, ofType } from 'redux-observable'
import { apiCriarPais, apiAtualizarPais, apiGetPais, apiExcluirPais } from 'store/modules/api/paises.actions'
import { CALL_SELECIONAR_PAIS, CALL_CRIAR_PAIS, CALL_ATUALIZAR_PAIS, CALL_EXCLUIR_PAIS } from 'store/modules/paises/paises.actions'

const onSelected = (action$) => action$.pipe(
  ofType(CALL_SELECIONAR_PAIS),
  debounceTime(250),
  map(({ payload }) => apiGetPais(payload.id)),
)

const onCallCriarPais = (action$) => action$.pipe(
  ofType(CALL_CRIAR_PAIS),
  debounceTime(250),
  map(({ payload }) => apiCriarPais(payload)),
)

const onCallAtualizarPais = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_PAIS),
  debounceTime(250),
  map(({ payload }) => apiAtualizarPais(payload)),
)

const onCallExcluirPais = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_PAIS),
  debounceTime(250),
  map(({ payload }) => apiExcluirPais(payload.id)),
)

export default combineEpics(
  onSelected,
  onCallCriarPais,
  onCallAtualizarPais,
  onCallExcluirPais,
)
