import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import {
  apiCriarFontesEnergia,
  apiExcluirFontesEnergia,
  apiAtualizarFontesEnergia,
} from 'store/modules/api/fontesEnergia.actions'
import {
  CALL_CRIAR_FONTE_ENERGIAS,
  CALL_EXCLUIR_FONTE_ENERGIAS,
  CALL_ATUALIZAR_FONTE_ENERGIAS,
} from 'store/modules/fontesEnergia/fontesEnergia.actions'

const onCallCriarFontesEnergia = (action$) => action$.pipe(
  ofType(CALL_CRIAR_FONTE_ENERGIAS),
  debounceTime(250),
  map(({ payload }) => apiCriarFontesEnergia(payload)),
)

const onCallExcluirFontesEnergia = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_FONTE_ENERGIAS),
  debounceTime(250),
  map(({ payload }) => apiExcluirFontesEnergia(payload)),
)

const onCallAtualizarFontesEnergia = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_FONTE_ENERGIAS),
  debounceTime(250),

  map(({ payload }) => apiAtualizarFontesEnergia(payload)),
)

export default combineEpics(
  onCallCriarFontesEnergia,
  onCallExcluirFontesEnergia,
  onCallAtualizarFontesEnergia,
)
