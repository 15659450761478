import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import {
  API_GET_RELATORIO_IPB_DETALHADO,
  API_GET_RELATORIO_IPB_RESUMIDO,
  API_GET_RELATORIO_IPB_GERAL,
} from 'store/modules/api/relatorios/relatoriosIPB.actions'

const isCarregandoRelatorioIPBDetalhado =
  loadingReducer(false, API_GET_RELATORIO_IPB_DETALHADO)
const isCarregandoRelatorioIPBResumido =
  loadingReducer(false, API_GET_RELATORIO_IPB_RESUMIDO)
const isCarregandoRelatorioIPBGeral =
  loadingReducer(false, API_GET_RELATORIO_IPB_GERAL)

export default combineReducers({
  isCarregandoRelatorioIPBDetalhado,
  isCarregandoRelatorioIPBResumido,
  isCarregandoRelatorioIPBGeral,
})
