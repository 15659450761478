import React, { useEffect, useState } from 'react'
import AuditorEmpresaLayout from 'components/AuditorEmpresaLayout'
import * as yup from 'yup'
import MomentUtils from '@date-io/moment'
import { isEmpty } from 'lodash'
import { Form, Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { validateFormValues } from 'utils/forms'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, makeStyles } from '@material-ui/core'
import DeleteBinLineIcon from 'remixicon-react/DeleteBin7LineIcon'
import MainColumn from 'components/MainColumn'
import Avatar from 'components/Avatar'
import Button from 'components/Button'
import { FieldDate, FieldSelect, FieldText } from 'components/Fields'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import clsx from 'clsx'
import {
  getEmpresaAuditoriaSelecionada,
  getDadosAuditoriaSelecionada,
  getAnoSelecionado,
  getIsSavingAuditoriaSelecionada,
} from 'store/modules/auditor/auditor.selectors'
import { callSalvarDadosAuditoria } from 'store/modules/auditor'
import { comboPressaoTipoAuditoria, TIPO_USUARIO } from 'utils/constantes'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import moment from 'moment'
import 'moment/locale/es'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    color: theme.palette.primary.main,
  },
  avatarSection: {
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  infoSection: {
    marginTop: theme.spacing(8),
  },
  footerSection: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(8),
  },
  content: {
    boxSizing: 'border-box',
    padding: theme.spacing(4, 8),
    maxWidth: theme.spacing(100),
    margin: theme.spacing(0, 'auto'),
  },
  marginTop: {
    marginTop: '64px',
  },
}))

const DadosGerais = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const empresaSelecionada = useSelector(getEmpresaAuditoriaSelecionada)
  const isSaving = useSelector(getIsSavingAuditoriaSelecionada)
  const dadosAuditoria = useSelector(getDadosAuditoriaSelecionada)
  const anoSelecionado = useSelector(getAnoSelecionado)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  useEffect(() => {
    moment.locale(t('inputs.calendario.idioma'))
  }, [t])

  const [avatarPreview, setAvatarPreview] = useState(null)

  const handleSave = (values) => {
    const data = {
      ...values,
      empresaId: empresaSelecionada.id,
      ano: anoSelecionado,
    }

    dispatch(callSalvarDadosAuditoria(data))
  }

  const handleDeleteImage = (form) => () => {
    form.change('avatar', null)
  }

  return (
    <AuditorEmpresaLayout
      renderChildren={(
        <Form
          onSubmit={handleSave}
          validate={validateFormValues(yup.object({
            nomeEmpresa: yup.string().required(),
            nomeContato: yup.string().required(),
            telefoneContato: yup.string().required(),
            emailContato: yup.string().required(),
            inicioAuditoria: yup.date().required(),
            terminoAuditoria: yup.date().required(),
            equipeAuditoria: yup.string().required(),
            numeroAuditoria: yup.string().required(),
            documentos: yup.string().required(),
            tipoAuditoria: yup.string().required(),
          }))}
          initialValues={{
            nomeEmpresa: dadosAuditoria?.nomeEmpresa ?? '',
            nomeContato: dadosAuditoria?.nomeContato ?? '',
            telefoneContato: dadosAuditoria?.telefoneContato ?? '',
            emailContato: dadosAuditoria?.emailContato ?? '',
            inicioAuditoria: dadosAuditoria?.inicioAuditoria ?? '',
            terminoAuditoria: dadosAuditoria?.terminoAuditoria ?? '',
            equipeAuditoria: dadosAuditoria?.equipeAuditoria ?? '',
            numeroAuditoria: dadosAuditoria?.numeroAuditoria ?? '',
            documentos: dadosAuditoria?.documentos ?? '',
            tipoAuditoria: dadosAuditoria?.tipoAuditoria ?? '',
            logoEmpresa: dadosAuditoria?.logoEmpresa ?? '',
          }}
          render={({ handleSubmit, values, form, pristine, valid }) => (
            <MuiPickersUtilsProvider
              locale={t('inputs.calendario.idioma')}
              utils={MomentUtils}
              libInstance={moment}
            >
              <form onSubmit={handleSubmit} className={classes.content}>
                <Typography variant="h2" className={classes.title}>
                  {t('auditoriaEmpresa.logoOrganismoCertificador')}
                </Typography>
                <MainColumn className={classes.avatarSection} columns="repeat(3, min-content)">
                  <Avatar size="large" image={avatarPreview || values?.logoEmpresa} initials={values?.nome} />
                  {tipoUsuarioLogado === TIPO_USUARIO.AUDITOR.AUDITOR && (
                    <>
                      <Button component="label" id="image-upload" variant="outlined">
                        {t('meuPerfil.alterar')}
                        <Field type="file" name="logoEmpresa">
                          {({ input: { value, onChange, ...input } }) => (
                            <input
                              {...input}
                              hidden
                              type="file"
                              accept="image/*"
                              id="image-upload"
                              onChange={(e) => {
                                if (!isEmpty(e.target.files)) {
                                  const fileReader = new FileReader()
                                  fileReader.onload = () => {
                                    if (fileReader.readyState === 2) {
                                      form.change('logoEmpresa', fileReader.result)
                                      form.change('novoAvatar', fileReader.result)
                                      form.change('novoAvatarNome', e.target.files[0].name)
                                      setAvatarPreview(fileReader.result)
                                    }
                                  }
                                  fileReader.readAsDataURL(e.target.files[0])
                                }
                              }}
                            />
                          )}
                        </Field>
                      </Button>
                      <Button variant="outlined" icon={DeleteBinLineIcon} id="image-delete" onClick={handleDeleteImage(form)} />
                    </>
                  )}
                </MainColumn>
                <MainColumn className={classes.infoSection}>
                  <Typography variant="h2" className={classes.title}>
                    {t('auditoriaEmpresa.dadosOrganismoCertificador')}
                  </Typography>

                  <FieldText
                    required
                    name="nomeEmpresa"
                    label={t('inputs.organismoCertificador')}
                    disabled={tipoUsuarioLogado === TIPO_USUARIO.AUDITOR.MONITOR}
                  />

                  <FieldText
                    required
                    name="nomeContato"
                    label={t('inputs.nomeContato')}
                    disabled={tipoUsuarioLogado === TIPO_USUARIO.AUDITOR.MONITOR}
                  />

                  <MainColumn columns="repeat(2, 1fr)">
                    <FieldText
                      required
                      name="telefoneContato"
                      label={t('inputs.telefoneContato')}
                      disabled={tipoUsuarioLogado === TIPO_USUARIO.AUDITOR.MONITOR}
                    />
                    <FieldText
                      required
                      name="emailContato"
                      type="email"
                      label={t('inputs.emailContato')}
                      disabled={tipoUsuarioLogado === TIPO_USUARIO.AUDITOR.MONITOR}
                    />
                  </MainColumn>

                  <Typography variant="h2" className={clsx(classes.title, classes.marginTop)}>
                    {t('auditoriaEmpresa.dadosAuditoria')}
                  </Typography>

                  <MainColumn columns="repeat(2, 1fr)">
                    <FieldDate
                      required
                      name="inicioAuditoria"
                      label={t('inputs.inicioAuditoria')}
                      disabled={tipoUsuarioLogado === TIPO_USUARIO.AUDITOR.MONITOR}
                    />
                    <FieldDate
                      required
                      name="terminoAuditoria"
                      label={t('inputs.fimAuditoria')}
                      disabled={tipoUsuarioLogado === TIPO_USUARIO.AUDITOR.MONITOR}
                    />
                  </MainColumn>

                  <FieldText
                    required
                    name="equipeAuditoria"
                    label={t('inputs.equipeAuditora')}
                    disabled={tipoUsuarioLogado === TIPO_USUARIO.AUDITOR.MONITOR}
                  />

                  <FieldText
                    required
                    name="numeroAuditoria"
                    label={t('inputs.numeroAuditoria')}
                    disabled={tipoUsuarioLogado === TIPO_USUARIO.AUDITOR.MONITOR}
                  />

                  <FieldText
                    required
                    name="documentos"
                    label={t('inputs.documentosLIFE')}
                    disabled={tipoUsuarioLogado === TIPO_USUARIO.AUDITOR.MONITOR}
                  />

                  <FieldSelect
                    required
                    id="tipoAuditoria"
                    name="tipoAuditoria"
                    label={t('inputs.tipoAuditoria')}
                    options={(comboPressaoTipoAuditoria || []).map((option) => ({
                      label: t(option.label),
                      value: option.id,
                    }))}
                    disabled={tipoUsuarioLogado === TIPO_USUARIO.AUDITOR.MONITOR}
                  />

                </MainColumn>
                {tipoUsuarioLogado === TIPO_USUARIO.AUDITOR.AUDITOR && (
                  <div className={classes.footerSection}>
                    <Button
                      disabled={pristine || isSaving}
                      variant="danger"
                      onClick={() => {
                        form.reset()
                        Object.keys(values).forEach((key) => {
                          form.resetFieldState(key)
                        })
                      }}
                    >
                      {t('inputs.cancelar')}
                    </Button>

                    <Button
                      disabled={pristine || !valid}
                      loading={isSaving}
                      type="submit"
                    >
                      {t('inputs.salvar')}
                    </Button>
                  </div>
                )}
              </form>
            </MuiPickersUtilsProvider>
          )}
        />
      )}
    />
  )
}

export default DadosGerais
