import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchCriteriosPrincipioActionTypes = apiActionTypes('principios/API_LISTAR_CRITERIOS_PRINCIPIO')
const getCriterioActionTypes = apiActionTypes('criterios/API_GET_CRITERIO')
const createCriterioActionTypes = apiActionTypes('criterios/API_CRIAR_CRITERIO')
const updateCriterioActionTypes = apiActionTypes('criterios/API_ATUALIZAR_CRITERIO')
const deleteCriterioActionTypes = apiActionTypes('criterios/API_EXCLUIR_CRITERIO')

export const API_LISTAR_CRITERIOS_PRINCIPIO = asApiTypesObject(fetchCriteriosPrincipioActionTypes)
export const API_GET_CRITERIO = asApiTypesObject(getCriterioActionTypes)
export const API_CRIAR_CRITERIO = asApiTypesObject(createCriterioActionTypes)
export const API_ATUALIZAR_CRITERIO = asApiTypesObject(updateCriterioActionTypes)
export const API_EXCLUIR_CRITERIO = asApiTypesObject(deleteCriterioActionTypes)

export const apiListarCriteriosPrincipio = (principioId, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/criterios/buscarPorPrincipio`,
  method: 'GET',
  types: fetchCriteriosPrincipioActionTypes,
}, { ...meta, query: { principioId } })

export const apiGetCriterio = (id, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/criterios/${id}`,
  method: 'GET',
  types: getCriterioActionTypes,
}, meta)

export const apiCriarCriterio = (criterio, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/criterios`,
  method: 'POST',
  types: createCriterioActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(criterio),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiAtualizarCriterio = ({ id, criterio }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/criterios/${id}`,
  method: 'PUT',
  types: updateCriterioActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(criterio),
}, { ...meta, successNotification: 'mensagens.atualizado' })

export const apiExcluirCriterio = ({ id }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/criterios/${id}`,
  method: 'DELETE',
  types: deleteCriterioActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })
