import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Tabs from 'components/Tabs'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import { useDispatch } from 'react-redux'
import { callSelecionarProjeto } from 'store/modules/projetos'
import { diff } from 'deep-object-diff'
import { isFormChanged } from 'utils/forms'
import RouterPrompt from 'components/AppRouter/RouterPrompt'
import DadosGerais from './DadosGerais'
import Pontuacao from './Pontuacao'
import Irc from './Irc'

const useStyles = makeStyles((theme) => ({
  tabList: {
    marginBottom: theme.spacing(4),
  },
}))

const Projetos = ({ setTelaSelecionada, setProjectName, projetoId, setAllowChange }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dialog = useConfirmationDialog()
  const dispatch = useDispatch()

  const [allowTabChange, setAllowTabChange] = useState(true)
  const [activeTab, setActiveTab] = useState('0')

  const tabs = [
    { titulo: 'desempenhoBiodiversidade.dadosProjeto', component: DadosGerais },
    { titulo: 'desempenhoBiodiversidade.pontuacao', component: Pontuacao },
    { titulo: 'desempenhoBiodiversidade.irc', component: Irc },
  ]

  const handleTabChange = (value) => {
    if (!allowTabChange) {
      return dialog.showConfirmation({
        title: t('dialogs.salvarDadosTitulo'),
        message: t('dialogs.salvarDadosDescricao'),
      }).then((result) => result && setActiveTab(value))
    }
    return setActiveTab(value)
  }

  const handleAllowTabChange = ({ initialValues, values }) => {
    const diffValues = diff(initialValues, values)
    const changed = isFormChanged({ dirtyValues: diffValues, formValues: values, initialValues })
    setAllowTabChange(!changed)
    setAllowChange(!changed)
  }

  useEffect(() => {
    if (activeTab === '1') {
      dispatch(callSelecionarProjeto({ id: projetoId }))
    }
  }, [activeTab, projetoId, dispatch])

  return (
    <>
      <RouterPrompt
        when={!allowTabChange}
        title={t('dialogs.salvarDadosTitulo')}
        message={t('dialogs.salvarDadosDescricao')}
      />
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        className={classes.tabList}
        renderTabs={({ Tab }) => tabs.map((tab, index) => (
          <Tab label={t(tab.titulo)} value={index.toString()} key={index.toString()} />
        ))}
      >
        {({ TabPanel }) => tabs.map(({ component: Component }, index) => (
          <TabPanel value={index.toString()} key={index.toString()}>
            <Component
              handleProjectName={setProjectName}
              handleAddAcoes={setTelaSelecionada}
              setChangeTab={handleAllowTabChange}
            />
          </TabPanel>
        ))}
      </Tabs>
    </>

  )
}

Projetos.propTypes = {
  setTelaSelecionada: PropTypes.func,
  setProjectName: PropTypes.func,
  projetoId: PropTypes.number,
  setAllowChange: PropTypes.func,
}

export default Projetos
