import { filter, map } from 'lodash'
import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { API_ATUALIZAR_ACAO, API_CRIAR_ACAO, API_EXCLUIR_ACAO } from 'store/modules/api/acoes.actions'
import { API_SALVAR_IRC } from 'store/modules/api/classesIrc.actions'
import { API_LISTAR_DADOS_DESEMPENHO_BIODIVERSIDADE } from 'store/modules/api/desempenhoBiodiversidade.actions'
import { API_ATUALIZAR_PROJETO, API_CRIAR_PROJETO, API_EXCLUIR_PROJETO, API_GET_PROJETO } from 'store/modules/api/projetos.actions'
import { CALL_NOVO_PROJETO } from 'store/modules/projetos/projetos.actions'
import { API_EXCLUIR_ANEXO } from 'store/modules/api/anexos.actions'

const lista = (state = [], { type, payload }) => {
  switch (type) {
    case API_LISTAR_DADOS_DESEMPENHO_BIODIVERSIDADE.success: return payload.projetos
    case API_CRIAR_PROJETO.success: return [...state, payload?.projeto]
    case API_EXCLUIR_PROJETO.success: return state.filter((item) => item.id !== payload.id)
    case API_ATUALIZAR_PROJETO.success:
      return map(state, (u) => (u.id === payload?.projeto.id ? payload.projeto : u))
    default: return state
  }
}

const quantidadeAcoesEmpresa = (state = 0, { type, payload }) => {
  switch (type) {
    case API_LISTAR_DADOS_DESEMPENHO_BIODIVERSIDADE.success: return payload.quantidadeAcoes
    case API_CRIAR_ACAO.success: return state + 1
    case API_EXCLUIR_ACAO.success: return state - 1
    case API_EXCLUIR_PROJETO.success: return payload.quantidadeAcoes
    default: return state
  }
}

const selecionado = (state = null, { type, payload }) => {
  switch (type) {
    case API_CRIAR_ACAO.success:
      return {
        ...state,
        acoes: [...(state.acoes || []), payload.acao],
      }
    case API_ATUALIZAR_ACAO.success:
      return {
        ...state,
        pontuacaoProjetoAcoesSimuladas: payload.acao?.projeto.pontuacaoProjetoAcoesSimuladas,
        pontuacaoProjetoAcoesVigentesAdicionais:
          payload.acao?.projeto.pontuacaoProjetoAcoesVigentesAdicionais,
        pontuacaoProjetoAcoesVigentesNaoAdicionais:
          payload.acao?.projeto.pontuacaoProjetoAcoesVigentesNaoAdicionais,
        pontuacaoProjetoAcoesNaoVigentesAdicionais:
          payload.acao?.projeto.pontuacaoProjetoAcoesNaoVigentesAdicionais,
        pontuacaoProjetoAcoesNaoVigentesNaoAdicionais:
          payload.acao?.projeto.pontuacaoProjetoAcoesNaoVigentesNaoAdicionais,
        pontuacaoTotalProjeto: payload.acao?.projeto.pontuacaoTotalProjeto,

        pontuacaoProjetoAcoesSimuladasIRC: payload.acao?.projeto.pontuacaoProjetoAcoesSimuladasIRC,
        pontuacaoProjetoAcoesVigentesAdicionaisIRC:
          payload.acao?.projeto.pontuacaoProjetoAcoesVigentesAdicionaisIRC,
        pontuacaoProjetoAcoesVigentesNaoAdicionaisIRC:
          payload.acao?.projeto.pontuacaoProjetoAcoesVigentesNaoAdicionaisIRC,
        pontuacaoProjetoAcoesNaoVigentesAdicionaisIRC:
          payload.acao?.projeto.pontuacaoProjetoAcoesNaoVigentesAdicionaisIRC,
        pontuacaoProjetoAcoesNaoVigentesNaoAdicionaisIRC:
          payload.acao?.projeto.pontuacaoProjetoAcoesNaoVigentesNaoAdicionaisIRC,
        pontuacaoTotalProjetoIRC: payload.acao?.projeto.pontuacaoTotalProjetoIRC,

        acoes: map(state.acoes, (u) => (u.id === payload.acao.id ? payload.acao : u)),
      }
    case API_EXCLUIR_ACAO.success:
      return {
        ...state,
        acoes: filter(state.acoes, (item) => item.id !== payload.id),
      }
    case API_GET_PROJETO.success: return payload
    case CALL_NOVO_PROJETO: return null
    case API_EXCLUIR_PROJETO.success: return null
    case API_CRIAR_PROJETO.success: return payload?.projeto
    case API_ATUALIZAR_PROJETO.success: return payload?.projeto
    case API_SALVAR_IRC.success: return {
      ...state,
      valorIrc: payload.projeto.valorIrc,
      informacoesAdicionaisIrc: payload.projeto.informacoesAdicionaisIrc,
      evidenciaIrc: payload.projeto.evidenciaIrc,
      anexoIrc: payload.projeto.anexoIrc,
    }
    case API_EXCLUIR_ANEXO.success:
      return {
        ...state,
        anexo: state?.anexo?.id === +payload ? null : state?.anexo,
        anexoIrc: state?.anexoIrc?.id === +payload ? null : state?.anexoIrc,
      }
    default: return state
  }
}

const isCarregandoProjetos = loadingReducer(true, API_LISTAR_DADOS_DESEMPENHO_BIODIVERSIDADE)

const isSaving = loadingReducer(false, {
  request: [
    API_ATUALIZAR_PROJETO.request,
    API_CRIAR_PROJETO.request,
  ],
  success: [
    API_ATUALIZAR_PROJETO.success,
    API_CRIAR_PROJETO.success,
  ],
  failure: [
    API_ATUALIZAR_PROJETO.failure,
    API_CRIAR_PROJETO.failure,
  ],
})

export default combineReducers({
  lista,
  selecionado,
  isCarregandoProjetos,
  isSaving,
  quantidadeAcoesEmpresa,
})
