export const ACTION_PATH = 'app/auth'

export const USER_AUTHENTICATE = `${ACTION_PATH}/USER_AUTHENTICATE`
export const USER_RESET_PASSWORD_REQUEST = `${ACTION_PATH}/USER_RESET_PASSWORD_REQUEST`
export const USER_RESET_PASSWORD = `${ACTION_PATH}/USER_RESET_PASSWORD`
export const USER_LOGOUT = `${ACTION_PATH}/USER_LOGOUT`

export const callUserAuthenticate = (payload) => ({
  type: USER_AUTHENTICATE,
  payload,
})

export const callUserResetPasswordRequest = (payload) => ({
  type: USER_RESET_PASSWORD_REQUEST,
  payload,
})

export const callUserResetPassword = (payload) => ({
  type: USER_RESET_PASSWORD,
  payload,
})

export const userLogout = () => ({
  type: USER_LOGOUT,
})
