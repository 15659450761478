import { Grid, Typography, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { FieldAutocomplete, FieldText, FieldUpload } from 'components/Fields'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { TIPO_USUARIO } from 'utils/constantes'

const useStyles = makeStyles((theme) => ({
  main: {
    boxSizing: 'border-box',
    width: '100%',
  },
  custom: {
    width: '100%',
    marginBottom: '18px',
  },
  container: {
    maxWidth: '100%',
    borderRadius: 6,
    display: 'flex',
    padding: theme.spacing('12px', 2),
    border: `1px solid ${theme.palette.grey.main}`,
  },
  heading: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(14),
  },
  list: {
    width: 85,
    flexShrink: 0,
    listStyle: 'none',
    margin: theme.spacing(0, 2, 0, 0),
    padding: theme.spacing(0, 2, 0, 0),
    borderRight: `1px solid ${theme.palette.grey.main}`,
  },
  listItem: {
    fontSize: 14,
    borderRadius: 3,
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: theme.spacing(1),
    minHeight: theme.spacing(5),
    justifyContent: 'space-between',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.grey.F4,
    },
  },
  listItemSelected: {
    backgroundColor: theme.palette.blue.light,
    '&:hover': {
      backgroundColor: theme.palette.blue.light,
    },
  },
  separator: {
    height: 1,
    width: '100%',
    margin: theme.spacing(1, 0),
    backgroundColor: theme.palette.grey.main,
  },
  form: {
    display: 'block',
  },
}))

const QualifyAction = ({ items = [], onSelect }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [selected, setSelected] = useState(0)
  const qualificadorSelecionado = items[selected]

  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const handleSelect = (index) => () => {
    setSelected(index)
    onSelect(index, items[selected]?.id)
  }

  useEffect(() => {
    onSelect(selected, items[selected]?.id)
  }, [selected, onSelect, items])

  return (
    <div className={classes.main}>
      <div className={classes.custom}>
        <Typography className={classes.heading} component="p">
          {t('desempenhoBiodiversidade.qualificarAcao')}
        </Typography>
      </div>
      <div className={classes.container}>
        <ul className={classes.list}>
          {items.map((qualificador, index) => (
            <Fragment key={qualificador.id}>
              {!!index && <li className={classes.separator} />}
              <li
                key={qualificador.id}
                onClick={handleSelect(index)}
                className={clsx(
                  classes.listItem,
                  selected === index && classes.listItemSelected,
                )}
              >
                {qualificador.identificador}
              </li>
            </Fragment>
          ))}
        </ul>
        {qualificadorSelecionado && (
          <Grid spacing={2} className={classes.form} container>
            <Grid item xs={12}>
              <FieldAutocomplete
                required
                label={qualificadorSelecionado.nomeColuna}
                name={`listaAcaoQualificador.${selected}.qualificadorAvaliacaoId`}
                options={qualificadorSelecionado.qualificadorAvaliacoes.map((option) => ({
                  label: option.descricao,
                  value: option.id,
                }))}
                disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldText
                multiline
                minRows={5}
                maxRows={5}
                label={t('inputs.comentarioQualificador')}
                placeholder={t('inputs.adicioneSeuComentario')}
                name={`listaAcaoQualificador.${selected}.comentario`}
                disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldText
                multiline
                minRows={5}
                maxRows={5}
                label={t('inputs.evidenciaQualificador')}
                name={`listaAcaoQualificador.${selected}.evidencia`}
                placeholder={t('inputs.incluaEvidenciaQualificador')}
                disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldUpload
                name={`listaAcaoQualificador.${selected}.anexo`}
                disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
              />
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  )
}

QualifyAction.propTypes = {
  items: PropTypes.array,
  onSelect: PropTypes.func,
}

export default QualifyAction
