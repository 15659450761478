import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchConversoesAreaActionTypes = apiActionTypes('conversoesArea/API_LISTAR_CONVERSOES_AREA')

export const API_LISTAR_CONVERSOES_AREA = asApiTypesObject(fetchConversoesAreaActionTypes)

export const apiListarConversoesArea = (data, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/conversoesArea`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchConversoesAreaActionTypes,
}, { ...meta })
