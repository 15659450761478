/* eslint-disable react/prop-types */
import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { getToken } from 'store/modules/auth/auth.selectors'

const withAuth = (C) => ({ token, ...rest }) => {
  if (!token) {
    return (
      <Redirect to={{ pathname: '/login' }} />
    )
  }

  return (
    <C {...rest} />
  )
}

export default ((C) => connect((state) => ({
  token: getToken(state),
}))(withAuth(C)))
