import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { filter } from 'lodash'
import {
  Grid,
  makeStyles,
  Box,
} from '@material-ui/core'
import { Form, FormSpy } from 'react-final-form'
import { validateFormValues } from 'utils/forms'
import * as yup from 'yup'
import { callCriarUsuario, callAtualizarUsuario, callNovoUsuario } from 'store/modules/usuarios'
import { getTipoUsuarios } from 'store/modules/tipoUsuarios/tipoUsuarios.selectors'
import { getEmpresaUsuario, getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { getUsuarioSelecionado } from 'store/modules/usuarios/usuarios.selectors'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'
import { VIEW_CLIENT, TIPO_USUARIO } from 'utils/constantes'
import { FieldAutocomplete, FieldText } from 'components/Fields'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  gridButton: {
    padding: theme.spacing(3, 1, 1),
  },
  title: {
    fontSize: '32px',
    fontWeight: '400',
    color: '#0F3444',
  },
  subtitle: {
    fontFamily: 'Inter',
    color: '#0F3444',
    fontWeight: '400',
    margin: theme.spacing(0, 0, 6, 0),
    fontSize: '14px',
  },
  formContainer: {
    padding: theme.spacing(4, 0, 5),
    marginBottom: theme.spacing(5),
    borderBottom: '1px solid #C9CEDB',
  },
  btnAdd: {
    border: '1px solid #C9CEDB',
    height: theme.spacing(5),
    textTransform: 'capitalize',
  },
  saveButton: {
    background: theme.palette.hover.lightBlue,
    fontSize: theme.typography.pxToRem(14),
    textTransform: 'capitalize',
  },
  cancelButton: {
    background: '#F85453',
    fontSize: theme.typography.pxToRem(14),
    textTransform: 'capitalize',
    marginRight: theme.spacing(4),
  },
}))

const EmpresaUsuarioCadastro = ({ setChangeTab }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [addUser, setAddUser] = useState(false)

  const listaTipoUsuarios = filter(useSelector(getTipoUsuarios), (o) => VIEW_CLIENT.includes(o.id))
  const usuarioSelecionado = useSelector(getUsuarioSelecionado)
  const empresa = useSelector(getEmpresaUsuario)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const handleSave = (values, form) => {
    const data = {
      ...values,
      empresaId: empresa.id,
    }
    if (usuarioSelecionado) {
      dispatch(callAtualizarUsuario({
        id: usuarioSelecionado.id,
        usuario: data,
        successNotification: 'mensagens.atualizado',
      }))
    } else {
      dispatch(callCriarUsuario(data))
      setTimeout(() => {
        form.reset()
        Object.keys(values).forEach((key) => {
          form.resetFieldState(key)
        })
      }, 500)
    }
  }

  useEffect(() => {
    if (usuarioSelecionado?.id) {
      setAddUser(true)
    }
  }, [usuarioSelecionado?.id])

  const handleCancel = (resetForm) => (() => {
    dispatch(callNovoUsuario())
    resetForm()
    setAddUser(false)
  })

  return (
    <Box className={classes.formContainer}>
      {!addUser && tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LIDER && (
        <Grid container>
          <Grid item xs={12}>
            <Button
              onClick={() => setAddUser(!addUser)}
              className={classes.saveButton}
              variant="outlined"
            >
              {t('usuario.adicionarUsuario')}
            </Button>
          </Grid>
        </Grid>
      )}
      {addUser && (
      <Form
        onSubmit={handleSave}
        validate={validateFormValues(yup.object({
          nome: yup.string().required(),
          email: yup.string().email().required(),
          tipoUsuarioId: yup.number().required(),
        }))}
        initialValues={{
          nome: usuarioSelecionado?.nome ?? '',
          email: usuarioSelecionado?.email ?? '',
          tipoUsuarioId: usuarioSelecionado?.tipoUsuario?.id ?? null,
        }}
        render={({ handleSubmit, form, isSubmitting, valid }) => (
          <form onSubmit={handleSubmit}>
            <FormSpy
              onChange={({ values: valuesSpy, initialValues: initialValuesSpy }) => {
                setChangeTab({
                  values: valuesSpy,
                  initialValues: initialValuesSpy,
                })
              }}
              subscription={{ values: true, initialValues: true }}
            />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FieldText
                  required
                  label={t('inputs.nomeCompleto')}
                  name="nome"
                  placeholder={t('inputs.insiraNome')}
                  disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                />
              </Grid>
              <Grid item xs={6}>
                <FieldText
                  required
                  label={t('inputs.email')}
                  name="email"
                  placeholder={t('inputs.insiraNome')}
                  disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                />
              </Grid>
              <Grid item xs={6}>
                <FieldAutocomplete
                  required
                  name="tipoUsuarioId"
                  label={t('inputs.tipoPermissao')}
                  disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                  options={(listaTipoUsuarios || []).map((tipo) => ({
                    value: tipo.id,
                    label: t(tipo.tipo),
                  }))}
                />
              </Grid>
              <Grid container direction="row" justifyContent="flex-end" spacing={2} className={classes.gridButton}>
                <Grid item>
                  <Button variant="danger" onClick={handleCancel(form.reset)}>{t('inputs.cancelar')}</Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    disabled={isSubmitting || !valid}
                  >
                    {usuarioSelecionado?.id ? t('usuario.atualizarUsuario') : t('usuario.adicionarUsuario')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      />
      )}
    </Box>
  )
}

EmpresaUsuarioCadastro.propTypes = {
  setChangeTab: PropTypes.func,
}

export default EmpresaUsuarioCadastro
