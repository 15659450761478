import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import { map } from 'lodash'
import { makeStyles, Grid, Typography, RadioGroup, FormControlLabel } from '@material-ui/core'
import { Radio } from 'final-form-material-ui'
import { getPrincipios } from 'store/modules/principios/principios.selectors'
import { callBuscarCriteriosPrincipio } from 'store/modules/criterios/criterios.actions'
import {
  callBuscarIndicadoresCriterio,
  callCriarIndicador,
  callAtualizarIndicador,
  callExcluirIndicador,
} from 'store/modules/indicadores/indicadores.actions'
import { getCriteriosPrincipioSelecionado } from 'store/modules/criterios/criterios.selectors'
import { getIndicadoresCriterioSelecionado } from 'store/modules/indicadores/indicadores.selectors'
import { Form, Field } from 'react-final-form'
import * as yup from 'yup'
import { validateFormValues } from 'utils/forms'
import { FieldText } from 'components/Fields'
import Accordion from 'components/Accordion'
import Button from 'components/Button'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { TIPO_USUARIO } from 'utils/constantes'
import { TabListItem, TabItem } from '../styles'

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
  screenTitle: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(5),
    fontSize: theme.typography.pxToRem(24),
  },
  listItems: {
    border: '1px solid #C9CEDB',
    boxShadow: '0px 8px 32px rgba(15, 52, 68, 0.08)',
    borderRadius: '8px',
    padding: '0px 16px',
    marginTop: '32px',
  },
  divider: {
    height: 1,
    width: 'calc(100% + 32px)',
    marginLeft: '-16px',
    backgroundColor: theme.palette.grey.main,
  },
  accordionTitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  formButtons: {
    margin: '12px',
  },
  adicionarIndicador: {
    marginTop: '2rem',
  },
  inputRadio: {
    display: 'flex',
    alignItems: 'center',
  },
  listCriterios: {
    marginTop: '32px !important',
  },
}))

const initialValue = {
  essencial: false,
  descricao: '',
  verificadores: '',
  notasRodape: '',
}

const Indicadores = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const dialog = useConfirmationDialog()

  const listaPrincipios = useSelector(getPrincipios)
  const listaCriteriosPrincipioSelecionado = useSelector(getCriteriosPrincipioSelecionado)
  const listaIndicadoresCriterioSelecionado = useSelector(getIndicadoresCriterioSelecionado)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const [tabPrincipioSelecionada, setTabPrincipioSelecionada] = useState(0)
  const [tabCriterioSelecionado, setTabCriterioSelecionada] = useState(0)
  const [principioSelecionado, setPrincipioSelecionado] = useState(null)
  const [criterioSelecionado, setCriterioSelecionado] = useState(null)
  const [listaForm, setListaForm] = useState([])
  const [openItem, setOpenItem] = useState('')

  useEffect(() => {
    if (listaPrincipios.length) {
      setPrincipioSelecionado(listaPrincipios[0])
      setTabPrincipioSelecionada(listaPrincipios[0].id)
      dispatch(callBuscarCriteriosPrincipio(listaPrincipios[0].id))
    }
  }, [dispatch, listaPrincipios])

  useEffect(() => {
    if (listaCriteriosPrincipioSelecionado.length) {
      setCriterioSelecionado(listaCriteriosPrincipioSelecionado[0])
      setTabCriterioSelecionada(listaCriteriosPrincipioSelecionado[0].id)
      dispatch(callBuscarIndicadoresCriterio(listaCriteriosPrincipioSelecionado[0].id))
    }
  }, [dispatch, listaCriteriosPrincipioSelecionado])

  useEffect(() => {
    setOpenItem('')
    setListaForm(listaIndicadoresCriterioSelecionado)
  }, [listaIndicadoresCriterioSelecionado])

  const handleTabPrincipioClick = (principio) => {
    setTabPrincipioSelecionada(principio.id)
    setPrincipioSelecionado(principio)
    dispatch(callBuscarCriteriosPrincipio(principio.id))
  }

  const handleTabCriterioClick = (criterio) => {
    setTabCriterioSelecionada(criterio.id)
    setCriterioSelecionado(criterio)
    dispatch(callBuscarIndicadoresCriterio(criterio.id))
  }

  const handleExpand = (item) => {
    setOpenItem(openItem === item ? '' : item)
  }

  const handleCancel = (form) => {
    form.reset()
    setOpenItem('')
  }

  const handleSave = ({ essencial, ...values }) => {
    if (values.id) {
      return dispatch(callAtualizarIndicador({
        id: values.id,
        indicador: {
          ...values,
          essencial: essencial === 'true',
          updatedAt: undefined,
          createdAt: undefined,
          deletedAt: undefined,
        },
      }))
    }
    return dispatch(callCriarIndicador({
      ...values,
      criterioId: criterioSelecionado.id,
      essencial: essencial === 'true',
    }))
  }

  const handleDelete = ({ id, numero }, index) => {
    if (id) {
      dialog.showConfirmation({
        title: t('dialogs.confirmarExclusao'),
        message: t('dialogs.excluirIndicador', { indicador: numero }),
      }).then((result) => result && dispatch(callExcluirIndicador({ id })))
    } else {
      let lista = [...listaForm]
      lista = lista.slice(0, index)
      setListaForm(lista)
    }
  }

  const handleAdd = () => {
    setListaForm([...listaForm, { ...initialValue, numero: listaForm.length + 1 }])
    setOpenItem(listaForm.length)
  }

  const desabilitarFormulario = Boolean(tipoUsuarioLogado === TIPO_USUARIO.ADMIN.OBSERVADOR)

  return (
    <>
      <div className={classes.sectionTitle}>
        <Typography className={classes.screenTitle}>
          {t('paisesCalculos.indicadores')}
        </Typography>
      </div>
      <TabListItem>
        {listaPrincipios && (
          map(listaPrincipios, (principio, i) => (
            <TabItem
              key={i}
              selected={principio.id === tabPrincipioSelecionada}
              onClick={() => handleTabPrincipioClick(principio)}
            >
              {`${t('paisesCalculos.principio')} ${principio.numero ?? ''}`}
            </TabItem>
          ))
        )}
      </TabListItem>
      {principioSelecionado?.id === tabPrincipioSelecionada && (
        <>
          <TabListItem className={classes.listCriterios}>
            {listaCriteriosPrincipioSelecionado && (
              map(listaCriteriosPrincipioSelecionado, (criterio, i) => (
                <TabItem
                  key={i}
                  selected={criterio.id === tabCriterioSelecionado}
                  onClick={() => handleTabCriterioClick(criterio)}
                >
                  {`${t('paisesCalculos.criterio')} ${criterio.numero ?? ''}`}
                </TabItem>
              ))
            )}
          </TabListItem>
        </>
      )}
      {criterioSelecionado?.id === tabCriterioSelecionado && (
        <>
          <div className={classes.listItems}>
            {map(listaForm, (indicador, index) => (
              <Form
                key={index}
                onSubmit={handleSave}
                initialValues={indicador ? { ...indicador, essencial: indicador.essencial ? 'true' : 'false' } : initialValue}
                validate={validateFormValues(yup.object().shape({
                  essencial: yup.boolean(),
                  descricao: yup.string(),
                  verificadores: yup.string(),
                  notasRodape: yup.string(),
                }))}
                render={({ handleSubmit, values, form, pristine }) => (
                  <form onSubmit={handleSubmit}>
                    <Accordion
                      key={index}
                      expanded={index === openItem}
                      variant="no-borders"
                      classes={{ title: classes.accordionTitle }}
                      title={`${t('paisesCalculos.indicador')} ${indicador.numero ?? ''}`}
                      onDelete={() => handleDelete(values, index)}
                      onExpand={() => handleExpand(index)}
                      disableDelete={desabilitarFormulario}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={3} className={classes.inputRadio}>
                          <Typography className={classes.contentRowTitle}>
                            {t('inputs.indicadorEssencial')}
                          </Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <RadioGroup row>
                            <FormControlLabel
                              label={t('inputs.nao')}
                              control={(
                                <Field
                                  color="primary"
                                  name="essencial"
                                  component={Radio}
                                  type="radio"
                                  value="false"
                                  disabled={desabilitarFormulario}
                                />
                              )}
                            />
                            <FormControlLabel
                              label={t('inputs.sim')}
                              control={(
                                <Field
                                  color="primary"
                                  name="essencial"
                                  component={Radio}
                                  type="radio"
                                  value="true"
                                  disabled={desabilitarFormulario}
                                />
                              )}
                            />
                          </RadioGroup>
                        </Grid>
                        <Grid item xs={12}>
                          <FieldText
                            name="numero"
                            label={t('inputs.ordem')}
                            placeholder={t('inputs.insiraOrdemIndicador')}
                            disabled={desabilitarFormulario}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FieldText
                            multiline
                            minRows={5}
                            name="descricao"
                            label={t('inputs.descricaoIndicador')}
                            placeholder={t('inputs.insiraDescricaoIndicador')}
                            disabled={desabilitarFormulario}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FieldText
                            multiline
                            minRows={5}
                            name="verificadores"
                            label={t('inputs.verificadores')}
                            placeholder={t('inputs.insiraVerificadores')}
                            disabled={desabilitarFormulario}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FieldText
                            multiline
                            minRows={5}
                            name="notasRodape"
                            label={t('inputs.notasRodape')}
                            placeholder={t('inputs.insiraNotasRodape')}
                            disabled={desabilitarFormulario}
                          />
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          className={classes.formButtons}
                        >
                          <Grid className={classes.cancelButton} item>
                            <Button variant="danger" onClick={() => handleCancel(form)}>
                              {t('inputs.cancelar')}
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button type="submit" disabled={Boolean(pristine && values.id) || desabilitarFormulario}>
                              {t('inputs.salvar')}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Accordion>
                    <div className={classes.divider} />
                  </form>
                )}
              />
            ))}
          </div>
          <Grid container direction="row" justifyContent="flex-end" className={classes.adicionarIndicador}>
            <Grid item>
              <Button onClick={handleAdd} disabled={desabilitarFormulario}>
                {t('paisesCalculos.adicionarIndicador')}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

export default Indicadores
