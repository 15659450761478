import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchRegioesHidrograficasActionTypes = apiActionTypes('regioesHidrograficas/API_LISTAR_REGIOES_HIDROGRAFICAS')
const createRegioesHidrograficasActionTypes = apiActionTypes('regioesHidrograficas/API_CRIAR_REGIOES_HIDROGRAFICAS')
const deleteRegioesHidrograficasActionTypes = apiActionTypes('regioesHidrograficas/API_EXCLUIR_REGIOES_HIDROGRAFICAS')
const updateRegioesHidrograficasActionTypes = apiActionTypes('regioesHidrograficas/API_ATUALIZAR_REGIOES_HIDROGRAFICAS')

export const API_LISTAR_REGIOES_HIDROGRAFICAS = asApiTypesObject(
  fetchRegioesHidrograficasActionTypes,
)
export const API_CRIAR_REGIOES_HIDROGRAFICAS = asApiTypesObject(
  createRegioesHidrograficasActionTypes,
)
export const API_EXCLUIR_REGIOES_HIDROGRAFICAS = asApiTypesObject(
  deleteRegioesHidrograficasActionTypes,
)
export const API_ATUALIZAR_REGIOES_HIDROGRAFICAS = asApiTypesObject(
  updateRegioesHidrograficasActionTypes,
)

export const apiListarRegioesHidrograficas = ({ paisId }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/regioesHidrograficas`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchRegioesHidrograficasActionTypes,
}, { ...meta, query: { paisId } })

export const apiCriarRegioesHidrograficas = (regioesHidrograficas, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/regioesHidrograficas`,
  method: 'POST',
  types: createRegioesHidrograficasActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(regioesHidrograficas),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiExcluirRegioesHidrograficas = ({ id }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/regioesHidrograficas/${id}`,
  method: 'DELETE',
  types: deleteRegioesHidrograficasActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })

export const apiAtualizarRegioesHidrograficas = ({ id,
  regioesHidrograficas }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/regioesHidrograficas/${id}`,
  method: 'PUT',
  types: updateRegioesHidrograficasActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(regioesHidrograficas),
}, { ...meta, successNotification: 'mensagens.atualizado' })
