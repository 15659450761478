import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const getFaturamentoActionTypes = apiActionTypes('faturamentos/API_GET_FATURAMENTO')
const createFaturamentoActionTypes = apiActionTypes('faturamentos/API_CRIAR_FATURAMENTO')
const updateFaturamentoActionTypes = apiActionTypes('faturamentos/API_ATUALIZAR_FATURAMENTO')

export const API_GET_FATURAMENTO = asApiTypesObject(getFaturamentoActionTypes)
export const API_CRIAR_FATURAMENTO = asApiTypesObject(createFaturamentoActionTypes)
export const API_ATUALIZAR_FATURAMENTO = asApiTypesObject(updateFaturamentoActionTypes)

export const apiGetFaturamento = (empresaId, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/faturamento`,
  method: 'GET',
  types: getFaturamentoActionTypes,
}, { ...meta, query: { empresaId } })

export const apiCriarFaturamento = (faturamento, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/faturamento`,
  method: 'POST',
  types: createFaturamentoActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(faturamento),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiAtualizarFaturamento = ({ id, faturamento }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/faturamento/${id}`,
  method: 'PUT',
  types: updateFaturamentoActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(faturamento),
}, { ...meta, successNotification: 'mensagens.atualizado' })
