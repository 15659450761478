import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import MenuAddLineIcon from 'remixicon-react/MenuAddLineIcon'
import { IconButton, Typography, makeStyles } from '@material-ui/core'
import Tooltip from 'components/Tooltip'
import Filters from './Filters'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: theme.spacing(4),
  },
  search: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchIcon: {
    color: theme.palette.grey.main,
  },
  list: {
    padding: 0,
    width: '100%',
    listStyle: 'none',
    margin: theme.spacing(4, 0, 0),
  },
  listItem: {
    fontSize: 14,
    minHeight: 40,
    display: 'flex',
    borderRadius: 4,
    cursor: 'pointer',
    position: 'relative',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    justifyContent: 'space-between',
    color: theme.palette.primary.main,
  },
  listItemActive: {
    backgroundColor: theme.palette.blue.light,
  },
  separator: {
    height: 1,
    width: '100%',
    margin: theme.spacing(1, 0),
    backgroundColor: theme.palette.grey.main,
  },
  button: {
    right: 0,
    position: 'absolute',
  },
  message: {
    fontSize: 14,
    fontStyle: 'italic',
    marginTop: theme.spacing(4),
    padding: theme.spacing('12px', 1),
  },
}))

const FilterList = ({
  items,
  selected,
  onSelect,
  onActionClick,
  emptyMessage,
  loadingMessage,
  customFilters,
  emptyItemLabel,
  toolTip,
}) => {
  const classes = useStyles()

  const [filteredItems, setFilteredItems] = useState(items || [])

  const handleSelect = (id) => ({ target }) => {
    if (onSelect && target.id === 'listItem') onSelect(id)
  }

  const handleActionClick = (id) => () => {
    if (onActionClick) onActionClick(id)
  }

  useEffect(() => {
    if (items) setFilteredItems(items)
  }, [items])

  if (!items) {
    return <Typography className={classes.message}>{loadingMessage}</Typography>
  }

  return items.length ? (
    <div className={classes.wrapper}>
      <Filters setItems={setFilteredItems} items={items} customFilters={customFilters} />
      <ul className={classes.list}>
        {filteredItems.map((item) => (
          <Fragment key={item.id}>
            <li
              id="listItem"
              onClick={handleSelect(item.id)}
              className={clsx(
                classes.listItem,
                selected === item.id && classes.listItemActive,
              )}
            >
              {item.nome || emptyItemLabel}
              {onActionClick && (
                <Tooltip title={toolTip}>
                  <IconButton className={classes.button} onClick={handleActionClick(item.id)}>
                    <MenuAddLineIcon className={classes.icon} />
                  </IconButton>
                </Tooltip>
              )}
            </li>
            <li>
              <div className={classes.separator} />
            </li>
          </Fragment>
        ))}
      </ul>
    </div>
  ) : (
    <Typography className={classes.message}>{emptyMessage}</Typography>
  )
}

FilterList.propTypes = {
  onSelect: PropTypes.func,
  emptyMessage: PropTypes.string,
  loadingMessage: PropTypes.string,
  onActionClick: PropTypes.func,
  emptyItemLabel: PropTypes.string,
  toolTip: PropTypes.string,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      nome: PropTypes.string,
    }),
  ),
  customFilters: PropTypes.array,
}

export default FilterList
