import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { filter, map } from 'lodash'
import {
  API_CRIAR_QUALIFICADOR,
  API_ATUALIZAR_QUALIFICADOR,
  API_EXCLUIR_QUALIFICADOR,
  API_GET_QUALIFICADOR,
  API_LISTAR_QUALIFICADORES,
} from 'store/modules/api/qualificadores.actions'
import { CALL_NOVO_QUALIFICADOR } from 'store/modules/qualificadores/qualificadores.actions'
import { API_EXCLUIR_ANEXO } from 'store/modules/api/anexos.actions'

const lista = (state = [], { type, payload }) => {
  switch (type) {
    case API_CRIAR_QUALIFICADOR.success: return payload
    case API_EXCLUIR_QUALIFICADOR.success: return filter(state, (o) => o.id !== payload)
    case API_ATUALIZAR_QUALIFICADOR.success:
      return map(state, (o) => (o.id === payload.id ? payload : o))
    case API_LISTAR_QUALIFICADORES.success: return payload
    default: return state
  }
}

const selecionado = (state = null, { type, payload }) => {
  switch (type) {
    case API_GET_QUALIFICADOR.success: return payload
    case API_CRIAR_QUALIFICADOR.success: return payload || state
    case API_ATUALIZAR_QUALIFICADOR.success: return payload
    case API_EXCLUIR_QUALIFICADOR.success: return null
    case CALL_NOVO_QUALIFICADOR: return null
    case API_EXCLUIR_ANEXO.success:
      return state?.anexo?.id === +payload ? { ...state, anexo: null } : state
    default: return state
  }
}

const isCarregandoAcoes = loadingReducer(false, API_GET_QUALIFICADOR)

const isSaving = loadingReducer(false, API_CRIAR_QUALIFICADOR)

export default combineReducers({
  lista,
  selecionado,
  isCarregandoAcoes,
  isSaving,
})
