import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchPaisesActionTypes = apiActionTypes('paises/API_LISTAR_PAISES')
const getPaisActionTypes = apiActionTypes('paises/API_GET_PAIS')
const createPaisActionTypes = apiActionTypes('paises/API_CRIAR_PAIS')
const updatePaisActionTypes = apiActionTypes('paises/API_ATUALIZAR_PAIS')
const deletePaisActionTypes = apiActionTypes('paises/API_EXCLUIR_PAIS')

export const API_LISTAR_PAISES = asApiTypesObject(fetchPaisesActionTypes)
export const API_GET_PAISES = asApiTypesObject(getPaisActionTypes)
export const API_CRIAR_PAISES = asApiTypesObject(createPaisActionTypes)
export const API_ATUALIZAR_PAISES = asApiTypesObject(updatePaisActionTypes)
export const API_EXCLUIR_PAIS = asApiTypesObject(deletePaisActionTypes)

export const apiListarPaises = (data, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/paises`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchPaisesActionTypes,
}, meta)

export const apiGetPais = (id, meta = {}) => apiAction({
  method: 'GET',
  endpoint: `${API_ENDPOINT}/paises/${id}`,
  headers: { 'Content-Type': 'application/json' },
  types: getPaisActionTypes,
}, meta)

export const apiCriarPais = (data, meta = {}) => apiAction({
  method: 'POST',
  body: JSON.stringify(data),
  types: createPaisActionTypes,
  endpoint: `${API_ENDPOINT}/paises`,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiAtualizarPais = ({ id, payload }, meta = {}) => apiAction({
  method: 'PUT',
  types: updatePaisActionTypes,
  body: JSON.stringify(payload),
  endpoint: `${API_ENDPOINT}/paises/${id}`,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.atualizado' })

export const apiExcluirPais = (id, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/paises/${id}`,
  method: 'DELETE',
  types: deletePaisActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido', id })
