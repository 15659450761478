import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Banner from 'components/Banner'
import Sidebar from 'components/Sidebar'
import TopBar from 'components/AppRouter/TopBar'
import { useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    margin: theme.spacing(3, 10, 5),
  },
  drawerOpen: {
    paddingLeft: '100px',
  },
}))

const MainWrap = ({ children }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const { pathname } = useLocation()
  const location = pathname.split('/')

  const ref = useRef()

  useEffect(() => {
    ref.current.scrollIntoView()
  })

  const toggleDrawer = () => {
    setOpen((state) => !state)
  }

  return (
    <div className={classes.root} ref={ref}>
      <Sidebar open={open} onChange={toggleDrawer} />
      <div className={classes.content}>
        <Banner screen={location[location.length - 1]} />
        <TopBar />
        <main className={clsx({ [classes.drawerOpen]: open })}>
          {children}
        </main>
      </div>
    </div>
  )
}

MainWrap.propTypes = {
  children: PropTypes.node,
}

export default MainWrap
