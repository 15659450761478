import React from 'react'
import { Field } from 'react-final-form'
import UploadFile from 'components/UploadFile'
import PropTypes from 'prop-types'

const Upload = ({ name, disabled, onDelete }) => (
  <Field
    name={name}
    render={({ input }) => (
      <UploadFile {...input} disabled={disabled} onDelete={onDelete} />
    )}
  />
)

Upload.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onDelete: PropTypes.func,
}

export default Upload
