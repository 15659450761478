const ACTION_PATH = 'app/tema'

export const CALL_CRIAR_TEMA = `${ACTION_PATH}/CALL_CRIAR_TEMA`
export const CALL_ATUALIZAR_TEMA = `${ACTION_PATH}/CALL_ATUALIZAR_TEMA`
export const CALL_SALVAR_LISTA_TEMA = `${ACTION_PATH}/CALL_SALVAR_LISTA_TEMA`
export const CALL_EXCLUIR_TEMA = `${ACTION_PATH}/CALL_EXCLUIR_TEMA`

export const callCriarTema = (tema) => ({
  type: CALL_CRIAR_TEMA,
  payload: tema,
})

export const callAtualizarTema = ({ id, tema }) => ({
  type: CALL_ATUALIZAR_TEMA,
  payload: { id, tema },
})

export const callSalvarListaTema = (payload) => ({
  type: CALL_SALVAR_LISTA_TEMA,
  payload,
})

export const callExcluirTema = ({ id }) => ({
  type: CALL_EXCLUIR_TEMA,
  payload: { id },
})
