const ACTION_PATH = 'app/tiposGases'

export const CALL_CRIAR_TIPO_GASES = `${ACTION_PATH}/CALL_CRIAR_TIPO_GASES`
export const CALL_EXCLUIR_TIPO_GASES = `${ACTION_PATH}/CALL_EXCLUIR_TIPO_GASES`
export const CALL_ATUALIZAR_TIPO_GASES = `${ACTION_PATH}/CALL_ATUALIZAR_TIPO_GASES`

export const callCriarTipoGas = (payload) => ({
  type: CALL_CRIAR_TIPO_GASES,
  payload,
})

export const callAtualizarTipoGas = (payload) => ({
  type: CALL_ATUALIZAR_TIPO_GASES,
  payload,
})

export const callExcluirTipoGas = ({ id }) => ({
  type: CALL_EXCLUIR_TIPO_GASES,
  payload: { id },
})
