import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { filter, map } from 'lodash'
import {
  API_LISTAR_CRITERIOS_PRINCIPIO,
  API_CRIAR_CRITERIO,
  API_EXCLUIR_CRITERIO,
  API_ATUALIZAR_CRITERIO,
} from 'store/modules/api/criterios.actions'

const listaCriteriosPrincipioSelecionado = (state = [], { type, payload }) => {
  switch (type) {
    case API_LISTAR_CRITERIOS_PRINCIPIO.success: return payload
    case API_CRIAR_CRITERIO.success: return [...state, payload]
    case API_EXCLUIR_CRITERIO.success: return filter(state, (o) => o.id !== payload)
    case API_ATUALIZAR_CRITERIO.success:
      return map(state, (o) => (o.id === payload.id ? payload : o))
    default: return state
  }
}

const isCarregandoListaCriteriosPrincipioSelecionado =
  loadingReducer(false, API_LISTAR_CRITERIOS_PRINCIPIO)

export default combineReducers({
  listaCriteriosPrincipioSelecionado,
  isCarregandoListaCriteriosPrincipioSelecionado,
})
