import {
  Box,
  Grid,
  makeStyles,
} from '@material-ui/core'
import PageTitle from 'components/PageTitle'
import Tabs from 'components/Tabs'
import { noop, some } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getDadosUsuario } from 'store/modules/auth/auth.selectors'
import { getDivisoesSetores } from 'store/modules/divisoesSetores/divisoesSetores.selectors'
import { getEmpresaSelecionada } from 'store/modules/empresas/empresas.selectors'
import { TIPO_USUARIO } from 'utils/constantes'
import { diff } from 'deep-object-diff'
import { isFormChanged } from 'utils/forms'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import RouterPrompt from 'components/AppRouter/RouterPrompt'
import EmpresaCopiarDados from './EmpresaCopiarDados'
import EmpresaDivisaoSetorDetalhe from './EmpresaDivisaoSetorDetalhe'
import EmpresaPerfilDetalhe from './EmpresaPerfilDetalhe'
import EmpresaUnidadeDetalhe from './EmpresaUnidadeDetalhe'
import EmpresaUsuarioLista from './EmpresaUsuarioLista'

const useStyles = makeStyles((theme) => ({
  container: {
    boxShadow: '0px 8px 32px rgba(15, 52, 68, 0.08)',
    borderRadius: 8,
    padding: theme.spacing(1, 2, 4),
    backgroundColor: theme.palette.common.white,
  },
  backButton: {
    margin: theme.spacing(0, 4, 0, 0),
  },
  saveButton: {
    background: theme.palette.hover.lightBlue,
    fontSize: theme.typography.pxToRem(14),
    textTransform: 'capitalize',
  },
  formContainer: {
    padding: theme.spacing(4, 2, 8),
    maxWidth: 830,
    margin: '0 auto',
  },
  gridButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  mainTitle: {
    color: theme.palette.common.white,
    fontSize: 32,
    marginTop: 44,
    marginLeft: 80,
    marginBottom: 80,
  },
}))

const Empresa = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dialog = useConfirmationDialog()

  const usuario = useSelector(getDadosUsuario)
  const empresa = useSelector(getEmpresaSelecionada)
  const listaDivisoesSetores = useSelector(getDivisoesSetores)

  const [activeTab, setActiveTab] = useState('0')
  const [allowTabChange, setAllowTabChange] = useState(true)

  const exibirAbaDados = TIPO_USUARIO.CLIENTE.LIDER === usuario.tipoUsuario.id

  const handleSalvar = (extraFunc = noop) => (() => {
    extraFunc?.()
  })

  const desativarAba = (id) => {
    if (id === '1') {
      return !empresa?.possuiDivisaoSetor
    }

    if (id === '2') {
      if (!empresa?.possuiDivisaoSetor) {
        return !empresa?.possuiDivisaoSetor
      }
      return !some(listaDivisoesSetores, 'possuiUnidade')
    }

    return false
  }

  const handleTabChange = (value) => {
    if (!allowTabChange && +activeTab !== 4) {
      return dialog.showConfirmation({
        title: t('dialogs.salvarDadosTitulo'),
        message: t('dialogs.salvarDadosDescricao'),
      }).then((result) => {
        if (result) {
          setActiveTab(value)
          setAllowTabChange(true)
        }
      })
    }
    setAllowTabChange(true)
    return setActiveTab(value)
  }

  const handleAllowTabChange = ({ initialValues, values }) => {
    const diffValues = diff(initialValues, values)
    const changed = isFormChanged({ dirtyValues: diffValues, formValues: values, initialValues })
    setAllowTabChange(!changed)
  }

  return (
    <Grid>
      <RouterPrompt
        when={!allowTabChange}
        title={t('dialogs.salvarDadosTitulo')}
        message={t('dialogs.salvarDadosDescricao')}
      />
      <PageTitle title={t('empresa.titulo')} />
      <Box className={classes.container}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          renderTabs={({ Tab }) => ([
            <Tab label={t('meuPerfil.minhaEmpresa')} value="0" key="0" disabled={desativarAba(0)} />,
            <Tab label={t('meuPerfil.divisaoSetor')} value="1" key="1" disabled={desativarAba(1)} />,
            <Tab label={t('meuPerfil.unidade')} value="2" key="2" disabled={desativarAba(2)} />,
            <Tab label={t('meuPerfil.usuarios')} value="3" key="3" disabled={desativarAba(3)} />,
            (exibirAbaDados ? <Tab label={t('meuPerfil.dados')} value="4" key="4" disabled={desativarAba(4)} /> : null),
          ].filter(Boolean))}
        >
          {({ TabPanel }) => (
            <>
              <TabPanel value="0">
                <EmpresaPerfilDetalhe onSave={handleSalvar} setChangeTab={handleAllowTabChange} />
              </TabPanel>
              <TabPanel value="1">
                <EmpresaDivisaoSetorDetalhe
                  onSave={handleSalvar}
                  setChangeTab={handleAllowTabChange}
                />
              </TabPanel>
              <TabPanel value="2">
                <EmpresaUnidadeDetalhe onSave={handleSalvar} setChangeTab={handleAllowTabChange} />
              </TabPanel>
              <TabPanel value="3">
                <EmpresaUsuarioLista onSave={handleSalvar} setChangeTab={handleAllowTabChange} />
              </TabPanel>
              <TabPanel value="4">
                <EmpresaCopiarDados onSave={handleSalvar} />
              </TabPanel>
            </>
          )}
        </Tabs>
      </Box>
    </Grid>
  )
}

export default Empresa
