import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, mergeMap, map } from 'rxjs/operators'
import { apiListarUnidades } from 'store/modules/api/unidades.actions'
import { apiListarDivisoesSetores } from 'store/modules/api/divisoesSetores.actions'
import { ofLocationChangeTo } from 'utils/location'
import { CALL_LISTAR_DADOS_PRESSAO_BIODIVERSIDADE } from 'store/modules/pressaoBiodiversidade/pressaoBiodiversidade.actions'
import { getEmpresaUsuario, getPaisEmpresa } from 'store/modules/auth/auth.selectors'
import { apiListarDadosPressaoBiodiversidade } from 'store/modules/api/pressaoBiodiversidade.actions'
import { apiListarResiduoDestinacoes } from 'store/modules/api/residuoDestinacoes.actions'
import { apiListarClasseResiduos } from 'store/modules/api/classeResiduos.actions'
import { apiListarTiposGases } from 'store/modules/api/tiposGases.actions'
import { apiListarEscopoGases } from 'store/modules/api/escopoGases.actions'
import { apiListarFontesEnergia } from 'store/modules/api/fontesEnergia.actions'
import { apiListarClassesOcupacao } from 'store/modules/api/classesOcupacao.actions'
import { apiListarRegioesHidrograficas } from 'store/modules/api/regioesHidrograficas.actions'
import { apiListarTipoAgua } from 'store/modules/api/tipoAgua.actions'
import { apiListarEspecieSilvicultura } from 'store/modules/api/especieSilvicultura.actions'
import { apiListarMatrizResiduos } from 'store/modules/api/matrizResiduos.actions'

const onLocationChangePressaoBiodiversidade = (action$, state$) => action$.pipe(
  ofLocationChangeTo('/pressaoBiodiversidade'),
  debounceTime(250),
  mergeMap(() => {
    const data = { paisId: getPaisEmpresa(state$.value)?.id }

    return [
      apiListarUnidades(getEmpresaUsuario(state$.value)?.id),
      apiListarDivisoesSetores(getEmpresaUsuario(state$.value)?.id),
      apiListarResiduoDestinacoes(data),
      apiListarClasseResiduos(),
      apiListarTiposGases(data),
      apiListarEscopoGases(),
      apiListarFontesEnergia(data),
      apiListarClassesOcupacao(data),
      apiListarRegioesHidrograficas(data),
      apiListarTipoAgua(data),
      apiListarEspecieSilvicultura(data),
      apiListarMatrizResiduos(data),
    ]
  }),
)

const onCallListarDadosPressaoBiodiversidade = (action$) => action$.pipe(
  ofType(CALL_LISTAR_DADOS_PRESSAO_BIODIVERSIDADE),
  debounceTime(250),
  map(({ payload }) => apiListarDadosPressaoBiodiversidade(payload)),
)

export default combineEpics(
  onLocationChangePressaoBiodiversidade,
  onCallListarDadosPressaoBiodiversidade,
)
