import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from '../apiAction'

const getRelatorioIndicadoresGestaoDetalhadoActionTypes = apiActionTypes('relatorios/API_GET_RELATORIO_INDICADORES_GESTAO_DETALHADO')
const getRelatorioIndicadoresGestaoResumidoActionTypes = apiActionTypes('relatorios/API_GET_RELATORIO_INDICADORES_GESTAO_RESUMIDO')
const getRelatorioIndicadoresGestaoGeralActionTypes = apiActionTypes('relatorios/API_GET_RELATORIO_INDICADORES_GESTAO_GERAL')

export const API_GET_RELATORIO_INDICADORES_GESTAO_DETALHADO =
  asApiTypesObject(getRelatorioIndicadoresGestaoDetalhadoActionTypes)
export const API_GET_RELATORIO_INDICADORES_GESTAO_RESUMIDO =
  asApiTypesObject(getRelatorioIndicadoresGestaoResumidoActionTypes)
export const API_GET_RELATORIO_INDICADORES_GESTAO_GERAL =
  asApiTypesObject(getRelatorioIndicadoresGestaoGeralActionTypes)

export const apiGetRelatorioIndicadoresGestaoDetalhado = ({
  idioma, anoBase, empresaId,
}, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/relatorios/relatorioIndicadoresGestaoDetalhado`,
  method: 'GET',
  types: getRelatorioIndicadoresGestaoDetalhadoActionTypes,
}, { ...meta, query: { idioma, anoBase, empresaId } })

export const apiGetRelatorioIndicadoresGestaoResumido = ({
  idioma, anoBase, empresaId,
}, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/relatorios/relatorioIndicadoresGestaoResumido`,
  method: 'GET',
  types: getRelatorioIndicadoresGestaoResumidoActionTypes,
}, { ...meta, query: { idioma, anoBase, empresaId } })

export const apiGetRelatorioIndicadoresGestaoGeral = ({
  idioma, anoBase, empresaId,
}, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/relatorios/relatorioIndicadoresGestaoGeral`,
  method: 'GET',
  types: getRelatorioIndicadoresGestaoGeralActionTypes,
}, { ...meta, query: { idioma, anoBase, empresaId } })
