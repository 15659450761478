import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchTipoAguaActionTypes = apiActionTypes('tipoAgua/API_LISTAR_TIPO_AGUA')
const createTipoAguaActionTypes = apiActionTypes('TipoAgua/API_CRIAR_TIPO_AGUA')
const deleteTipoAguaActionTypes = apiActionTypes('TipoAgua/API_EXCLUIR_TIPO_AGUA')
const updateTipoAguaActionTypes = apiActionTypes('TipoAgua/API_ATUALIZAR_TIPO_AGUA')

export const API_LISTAR_TIPO_AGUA = asApiTypesObject(fetchTipoAguaActionTypes)
export const API_CRIAR_TIPO_AGUA = asApiTypesObject(createTipoAguaActionTypes)
export const API_EXCLUIR_TIPO_AGUA = asApiTypesObject(deleteTipoAguaActionTypes)
export const API_ATUALIZAR_TIPO_AGUA = asApiTypesObject(updateTipoAguaActionTypes)

export const apiListarTipoAgua = ({ paisId }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/tipoAgua`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchTipoAguaActionTypes,
}, { ...meta, query: { paisId } })

export const apiCriarTipoAgua = (tipoAgua, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/tipoAgua`,
  method: 'POST',
  types: createTipoAguaActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(tipoAgua),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiExcluirTipoAgua = ({ id }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/tipoAgua/${id}`,
  method: 'DELETE',
  types: deleteTipoAguaActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })

export const apiAtualizarTipoAgua = ({ id, tipoAgua }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/tipoAgua/${id}`,
  method: 'PUT',
  types: updateTipoAguaActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(tipoAgua),
}, { ...meta, successNotification: 'mensagens.atualizado' })
