import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map, ignoreElements } from 'rxjs/operators'
import {
  apiGetRelatorioGestaoDetalhado,
  apiGetRelatorioGestaoResumido,
  apiGetRelatorioGestaoGeral,
  API_GET_RELATORIO_GESTAO_DETALHADO,
  API_GET_RELATORIO_GESTAO_RESUMIDO,
  API_GET_RELATORIO_GESTAO_GERAL,
} from 'store/modules/api/relatorios/relatoriosGestao.actions'
import {
  CALL_RELATORIO_GESTAO_DETALHADO,
  CALL_RELATORIO_GESTAO_RESUMIDO,
  CALL_RELATORIO_GESTAO_GERAL,
} from 'store/modules/relatorios/gestao/relatoriosGestao.actions'
import { saveAs } from 'file-saver'

const onCallRelatorioGestaoDetalhado = (action$) => action$.pipe(
  ofType(CALL_RELATORIO_GESTAO_DETALHADO),
  debounceTime(250),
  map(({ payload }) => apiGetRelatorioGestaoDetalhado(payload)),
)

const onDownloadRelatorioGestaoDetalhado = (action$) => action$.pipe(
  ofType(API_GET_RELATORIO_GESTAO_DETALHADO.success),
  // map(({ payload }) => saveAs(payload.base64, `${payload.nome}.pdf`)),
  ignoreElements(),
)

const onCallRelatorioGestaoResumido = (action$) => action$.pipe(
  ofType(CALL_RELATORIO_GESTAO_RESUMIDO),
  debounceTime(250),
  map(({ payload }) => apiGetRelatorioGestaoResumido(payload)),
)

const onDownloadRelatorioGestaoResumido = (action$) => action$.pipe(
  ofType(API_GET_RELATORIO_GESTAO_RESUMIDO.success),
  map(({ payload }) => saveAs(payload.base64, `${payload.nome}.pdf`)),
  ignoreElements(),
)

const onCallRelatorioGestaoGeral = (action$) => action$.pipe(
  ofType(CALL_RELATORIO_GESTAO_GERAL),
  debounceTime(250),
  map(({ payload }) => apiGetRelatorioGestaoGeral(payload)),
)

const onDownloadRelatorioGestaoGeral = (action$) => action$.pipe(
  ofType(API_GET_RELATORIO_GESTAO_GERAL.success),
  // map(({ payload }) => saveAs(payload.base64, `${payload.nome}.pdf`)),
  ignoreElements(),
)

export default combineEpics(
  onCallRelatorioGestaoDetalhado,
  onCallRelatorioGestaoResumido,
  onCallRelatorioGestaoGeral,
  onDownloadRelatorioGestaoDetalhado,
  onDownloadRelatorioGestaoResumido,
  onDownloadRelatorioGestaoGeral,
)
