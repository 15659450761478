import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map, ignoreElements } from 'rxjs/operators'
import {
  apiGetRelatorioIPBDetalhado,
  apiGetRelatorioIPBResumido,
  apiGetRelatorioIPBGeral,
  API_GET_RELATORIO_IPB_DETALHADO,
  API_GET_RELATORIO_IPB_RESUMIDO,
  API_GET_RELATORIO_IPB_GERAL,
} from 'store/modules/api/relatorios/relatoriosIPB.actions'
import { saveAs } from 'file-saver'
import {
  CALL_RELATORIO_IPB_DETALHADO,
  CALL_RELATORIO_IPB_RESUMIDO,
  CALL_RELATORIO_IPB_GERAL,
} from './relatoriosIPB.actions'

const onCallRelatorioIPBDetalhado = (action$) => action$.pipe(
  ofType(CALL_RELATORIO_IPB_DETALHADO),
  debounceTime(250),
  map(({ payload }) => apiGetRelatorioIPBDetalhado(payload)),
)

const onDownloadRelatorioIPBDetalhado = (action$) => action$.pipe(
  ofType(API_GET_RELATORIO_IPB_DETALHADO.success),
  map(({ payload }) => saveAs(payload.base64, `${payload.nome}.pdf`)),
  ignoreElements(),
)

const onCallRelatorioIPBResumido = (action$) => action$.pipe(
  ofType(CALL_RELATORIO_IPB_RESUMIDO),
  debounceTime(250),
  map(({ payload }) => apiGetRelatorioIPBResumido(payload)),
)

const onDownloadRelatorioIPBResumido = (action$) => action$.pipe(
  ofType(API_GET_RELATORIO_IPB_RESUMIDO.success),
  map(({ payload }) => saveAs(payload.base64, `${payload.nome}.pdf`)),
  ignoreElements(),
)

const onCallRelatorioIPBGeral = (action$) => action$.pipe(
  ofType(CALL_RELATORIO_IPB_GERAL),
  debounceTime(250),
  map(({ payload }) => apiGetRelatorioIPBGeral(payload)),
)

const onDownloadRelatorioIPBGeral = (action$) => action$.pipe(
  ofType(API_GET_RELATORIO_IPB_GERAL.success),
  map(({ payload }) => saveAs(payload.base64, `${payload.nome}.pdf`)),
  ignoreElements(),
)

export default combineEpics(
  onCallRelatorioIPBDetalhado,
  onCallRelatorioIPBResumido,
  onCallRelatorioIPBGeral,
  onDownloadRelatorioIPBDetalhado,
  onDownloadRelatorioIPBResumido,
  onDownloadRelatorioIPBGeral,
)
