import React from 'react'
import PropTypes from 'prop-types'
import { Autocomplete } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import Input from './BaseInput'

const BaseAutocomplete = ({
  name,
  options,
  value,
  inputProps,
  multiple,
  getOptionLabel,
  getOptionSelected,
  noOptionsText,
  ...props
}) => {
  const { t } = useTranslation()

  const getDefaultOptionLabel = (option) => (
    option.label || ''
  )

  const getDefaultOptionSelected = (option, { value: selectedValue }) => (
    option.value === selectedValue
  )

  return (
    <Autocomplete
      {...props}
      name={name}
      options={options}
      multiple={multiple}
      noOptionsText={noOptionsText ?? t('inputs.semOpcoes')}
      getOptionLabel={getOptionLabel || getDefaultOptionLabel}
      getOptionSelected={getOptionSelected || getDefaultOptionSelected}
      value={multiple ? (value || []) : (options.find((option) => option.value === value) || null)}
      renderInput={(params) => (
        <Input multiple={multiple} {...inputProps} {...params} />
      )}
    />
  )
}

BaseAutocomplete.propTypes = {
  multiple: PropTypes.bool,
  inputProps: PropTypes.object,
  noOptionsText: PropTypes.string,
  getOptionLabel: PropTypes.func,
  getOptionSelected: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    }),
  ),
}

export default BaseAutocomplete
