import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map, ignoreElements, filter } from 'rxjs/operators'
import {
  apiGetRelatorioPABSDetalhado,
  apiGetRelatorioPABSResumido,
  apiGetRelatorioPABSGeral,
  API_GET_RELATORIO_PABS_DETALHADO,
  API_GET_RELATORIO_PABS_RESUMIDO,
  API_GET_RELATORIO_PABS_GERAL,
} from 'store/modules/api/relatorios/relatoriosPABS.actions'
import { saveAs } from 'file-saver'
import { enqueueSnackbar } from 'store/modules/notifications'
import {
  CALL_RELATORIO_PABS_DETALHADO,
  CALL_RELATORIO_PABS_RESUMIDO,
  CALL_RELATORIO_PABS_GERAL,
} from './relatoriosPABS.actions'

const onCallRelatorioPABSDetalhado = (action$) => action$.pipe(
  ofType(CALL_RELATORIO_PABS_DETALHADO),
  debounceTime(250),
  map(({ payload }) => apiGetRelatorioPABSDetalhado(payload)),
)

const onDownloadRelatorioPABSDetalhado = (action$) => action$.pipe(
  ofType(API_GET_RELATORIO_PABS_DETALHADO.success),
  filter(({ payload }) => !payload.error),
  // map(({ payload }) => saveAs(payload.base64, `${payload.nome}.pdf`)),
  ignoreElements(),
)

const onErrorRelatorioPABSDetalhado = (action$) => action$.pipe(
  ofType(API_GET_RELATORIO_PABS_DETALHADO.success),
  filter(({ payload }) => payload.error),
  map(({ payload = {} }) => enqueueSnackbar({
    message: payload.error,
    options: { variant: 'error', autoHideDuration: 3000, dismissible: true },
  })),
)

const onCallRelatorioPABSResumido = (action$) => action$.pipe(
  ofType(CALL_RELATORIO_PABS_RESUMIDO),
  debounceTime(250),
  map(({ payload }) => apiGetRelatorioPABSResumido(payload)),
)

const onDownloadRelatorioPABSResumido = (action$) => action$.pipe(
  ofType(API_GET_RELATORIO_PABS_RESUMIDO.success),
  map(({ payload }) => saveAs(payload.base64, `${payload.nome}.pdf`)),
  ignoreElements(),
)

const onCallRelatorioPABSGeral = (action$) => action$.pipe(
  ofType(CALL_RELATORIO_PABS_GERAL),
  debounceTime(250),
  map(({ payload }) => apiGetRelatorioPABSGeral(payload)),
)

const onDownloadRelatorioPABSGeral = (action$) => action$.pipe(
  ofType(API_GET_RELATORIO_PABS_GERAL.success),
  map(({ payload }) => saveAs(payload.base64, `${payload.nome}.pdf`)),
  ignoreElements(),
)

export default combineEpics(
  onCallRelatorioPABSDetalhado,
  onCallRelatorioPABSResumido,
  onCallRelatorioPABSGeral,
  onDownloadRelatorioPABSDetalhado,
  onDownloadRelatorioPABSResumido,
  onDownloadRelatorioPABSGeral,
  onErrorRelatorioPABSDetalhado,
)
