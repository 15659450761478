import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import {
  apiCopiarDados,
} from 'store/modules/api/servico.actions'
import {
  CALL_COPIAR_DADOS,
} from 'store/modules/servico/servico.actions'

const onCallCopiarDados = (action$) => action$.pipe(
  ofType(CALL_COPIAR_DADOS),
  debounceTime(250),
  map(({ payload }) => apiCopiarDados(payload)),
)

export default combineEpics(
  onCallCopiarDados,
)
