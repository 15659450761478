import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchIndicadoresAdminActionTypes = apiActionTypes('classesIrc/API_LISTAR_CLASSES_INDICADORES_ADMIN')
const salvarIndicadoresAdminActionTypes = apiActionTypes('classesIrc/API_SALVAR_INDICADORES_ADMIN')
const deleteClasseIndicadoresAdminActionTypes = apiActionTypes('classesIrc/API_EXCLUIR_CLASSES_INDICADORES_ADMIN')
const deleteIndicadoresAdminActionTypes = apiActionTypes('classesIrc/API_EXCLUIR_INDICADORES_ADMIN')

export const API_LISTAR_CLASSES_INDICADORES_ADMIN =
  asApiTypesObject(fetchIndicadoresAdminActionTypes)

export const API_SALVAR_INDICADORES_ADMIN =
  asApiTypesObject(salvarIndicadoresAdminActionTypes)

export const API_EXCLUIR_CLASSES_INDICADORES_ADMIN =
  asApiTypesObject(deleteClasseIndicadoresAdminActionTypes)

export const API_EXCLUIR_INDICADORES_ADMIN =
  asApiTypesObject(deleteIndicadoresAdminActionTypes)

export const apiListarIndicadoresAdmin = ({ paisId }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/classesIrcAdmin`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchIndicadoresAdminActionTypes,
}, { ...meta, query: { paisId } })

export const apiSalvarIndicadoresAdmin = (payload, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/classesIrcAdmin/salvarIndicadores`,
  method: 'POST',
  types: salvarIndicadoresAdminActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(payload),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiExcluirIndicadorAdmin = ({ id }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/classesIrcAdmin/excluirIndicador/${id}`,
  method: 'DELETE',
  types: deleteIndicadoresAdminActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })

export const apiExcluirClasseIndicadoresAdmin = ({ id }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/classesIrcAdmin/${id}`,
  method: 'DELETE',
  types: deleteClasseIndicadoresAdminActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })
