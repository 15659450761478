import React from 'react'
import { Form } from 'react-final-form'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { validateFormValues } from 'utils/forms'
import { Grid, makeStyles } from '@material-ui/core'
import { FieldSelect } from 'components/Fields'
import SurveyLineIcon from 'remixicon-react/SurveyLineIcon'
import AwardLineIcon from 'remixicon-react/AwardLineIcon'
import Button from 'components/Button'
import { getIdiomas } from 'store/modules/idiomas/idiomas.selectors'
import { callRelatorioAuditoria, callRelatorioSumario } from 'store/modules/relatorios/auditoria'
import {
  getIsCarregandoRelatorioAuditoria,
  getIsCarregandoRelatorioSumario,
} from 'store/modules/relatorios/auditoria/auditoria.selectors'
import { getEmpresaAuditoriaSelecionada, getAnoSelecionado } from 'store/modules/auditor/auditor.selectors'
import Card from './Card'

const useStyles = makeStyles((theme) => ({
  submitButton: {
    marginTop: theme.spacing(3),
  },
}))

const TabAuditoria = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const idiomas = useSelector(getIdiomas)
  const loadingRelatorioAuditoria = useSelector(getIsCarregandoRelatorioAuditoria)
  const loadingRelatorioSumario = useSelector(getIsCarregandoRelatorioSumario)

  const anoSelecionado = useSelector(getAnoSelecionado)
  const empresaSelecionada = useSelector(getEmpresaAuditoriaSelecionada)

  const RELATORIOS = [
    {
      title: t('relatorios.relatarioAuditoria'),
      icon: SurveyLineIcon,
      action: callRelatorioAuditoria,
      loading: loadingRelatorioAuditoria,
    },
    {
      title: t('relatorios.sumarioPublico'),
      icon: AwardLineIcon,
      action: callRelatorioSumario,
      loading: loadingRelatorioSumario,
    },
  ]

  return (
    <Grid spacing={4} container>
      {RELATORIOS.map(({ action, title, icon, loading }, index) => (
        <Grid item xs={12} md={4} key={index}>
          <Form
            validate={validateFormValues(yup.object({
              idioma: yup.number().required(),
            }))}
            onSubmit={(values) => dispatch(action({
              anoBase: anoSelecionado,
              empresaId: empresaSelecionada.id,
              idioma: values.idioma,
            }))}
            render={({ handleSubmit, submitting, values }) => (
              <form onSubmit={handleSubmit}>
                <Card color="orange" title={title} icon={icon}>
                  <Grid spacing={1} justifyContent="center" container>
                    <Grid item xs={12}>
                      <FieldSelect
                        required
                        name="idioma"
                        label={t('inputs.idioma')}
                        options={idiomas.map((idioma) => ({
                          label: t(idioma.label),
                          value: idioma.id,
                        }))}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        loading={loading}
                        loadingText="inputs.gerandoRelatorio"
                        type="submit"
                        className={classes.submitButton}
                        disabled={!values.idioma || submitting}
                      >
                        {t('relatorios.downloadRelatorio')}
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
              </form>
            )}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default TabAuditoria
