import React, { useState } from 'react'
import {
  Menu,
  MenuItem,
  makeStyles,
  IconButton,
  Fade,
} from '@material-ui/core'
import { userLogout } from 'store/modules/auth'
import { useDispatch, useSelector } from 'react-redux'
import { getDadosUsuario } from 'store/modules/auth/auth.selectors'
import ArrowDropDownLineIcon from 'remixicon-react/ArrowDropDownLineIcon'
import Avatar from 'components/Avatar'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginBottom: theme.spacing(4),
    justifyContent: 'space-between',
  },
  positionLogout: {
    top: 0,
    right: 8,
    opacity: 0,
    position: 'absolute',
    transition: 'opacity 400ms ease-in-out',
    '&:hover': {
      opacity: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
    '& svg': {
      fill: theme.palette.common.white,
    },
    zIndex: 1000,
  },
  userAvatar: {
    marginLeft: 'auto',
  },
}))

const TopBar = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const usuario = useSelector(getDadosUsuario)
  const [anchorEl, setAnchorEl] = useState(null)
  const openMenu = !!anchorEl

  const handleLogout = () => {
    localStorage.removeItem('usuarioId')
    sessionStorage.removeItem('usuarioId')
    dispatch(userLogout())
  }

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <header className={classes.root}>
      {usuario.empresa?.nome && (
        <Avatar
          title={usuario.empresa?.nome}
          image={usuario.empresa?.avatar}
        />
      )}
      <Avatar
        invert
        title={usuario?.nome}
        image={usuario?.avatar}
        description={usuario?.funcao}
        className={classes.userAvatar}
      >
        <IconButton
          aria-haspopup="true"
          onClick={handleMenuOpen}
          aria-controls="menu-appbar"
          className={classes.positionLogout}
          aria-label="account of current user"
        >
          <ArrowDropDownLineIcon />
        </IconButton>
        <Menu
          keepMounted
          id="menu-appbar"
          open={openMenu}
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          TransitionComponent={Fade}
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'center',
          }}
          transformOrigin={{
            horizontal: 'center',
            vertical: 'center',
          }}
          getContentAnchorEl={null}
        >
          {/* <MenuItem onClick={handleCloseMenu}>Profile</MenuItem>
          <MenuItem onClick={handleCloseMenu}>My account</MenuItem>
          <Divider /> */}
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Avatar>
    </header>
  )
}

export default TopBar
