import React from 'react'
import { withStyles, TextField } from '@material-ui/core'

export const StyledInput = withStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      color: theme.palette.primary.main,
      '& input, & textarea': {
        height: '11px',
        textAlign: 'left',
        fontWeight: 400,
        fontSize: 14,
        '&::placeholder': {
          fontWeight: 400,
          fontSize: 14,
        },
      },
    },
    '& .MuiFormLabel-root': {
      color: theme.palette.primary.main,
      fontWeight: 400,
      fontSize: 14,
      '&.Mui-error': {
        color: '#f44336',
        fontWeight: 400,
        fontSize: 14,
      },
    },
    '& .MuiInputLabel-formControl.MuiInputLabel-shrink': {
      top: 0,
    },
    '& .MuiInputLabel-formControl': {
      top: -2,
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    },
    '& .MuiIconButton-label > svg': {
      fill: '#e0e0e0',
      '&:hover': {
        fill: '#164C64',
      },
    },
    '& .MuiSelect-selectMenu': {
      height: 12,
      fontWeight: 400,
      fontSize: 14,
      paddingTop: 14,
      minHeight: 'auto',
    },
    '& .MuiInputBase-formControl': {
      height: (props) => (props.multiple ? 'auto' : 48),
    },
    '& .MuiInputBase-formControl.MuiInputBase-multiline': {
      height: 'unset',
    },
    '& .Mui-disabled': {
      color: '#A0A8BD',
      backgroundColor: '#F4F5F8',
    },
    '& .MuiFormHelperText-root': {
      fontWeight: 400,
      fontSize: 11,
    },
    // '& .MuiInputAdornment-root.MuiInputAdornment-positionEnd': {
    //   width: 25,
    // },
  },
}))(TextField)

const InputField = (props) => (
  <StyledInput fullWidth variant="outlined" {...props} />
)

export default InputField
