import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchDadosDesempenhoBiodiversidadeActionTypes = apiActionTypes('desempenhoBiodiversidade/API_LISTAR_DADOS_DESEMPENHO_BIODIVERSIDADE')

export const API_LISTAR_DADOS_DESEMPENHO_BIODIVERSIDADE =
  asApiTypesObject(fetchDadosDesempenhoBiodiversidadeActionTypes)

export const apiListarDadosDesempenhoBiodiversidade = ({ empresaId, ano }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/desempenhoBiodiversidade`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchDadosDesempenhoBiodiversidadeActionTypes,
}, { ...meta, query: { empresaId, ano } })
