const ACTION_PATH = 'app/unidade'

export const CALL_CRIAR_UNIDADE = `${ACTION_PATH}/CALL_CRIAR_UNIDADE`
export const CALL_ATUALIZAR_UNIDADE = `${ACTION_PATH}/CALL_ATUALIZAR_UNIDADE`
export const CALL_SALVAR_LISTA_UNIDADE = `${ACTION_PATH}/CALL_SALVAR_LISTA_UNIDADE`

export const callCriarUnidade = (unidade) => ({
  type: CALL_CRIAR_UNIDADE,
  payload: unidade,
})

export const callAtualizarUnidade = ({ id, unidade }) => ({
  type: CALL_ATUALIZAR_UNIDADE,
  payload: { id, unidade },
})

export const callSalvarListaUnidade = (payload) => ({
  type: CALL_SALVAR_LISTA_UNIDADE,
  payload,
})
