import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import { TabPanel as MuiTabPanel } from '@material-ui/lab'

const useStyles = makeStyles({
  tabPanel: {
    padding: 0,
  },
})

const TabPanel = ({ className, ...props }) => {
  const classes = useStyles()

  return (
    <MuiTabPanel {...props} className={clsx(classes.tabPanel, className)} />
  )
}

TabPanel.propTypes = {
  className: PropTypes.string,
}

export default TabPanel
