import { createTheme } from '@material-ui/core'
import '@fontsource/poppins'
import '@fontsource/inter'

export default createTheme({
  typography: {
    fontFamily: 'Poppins',
    secondary: 'Inter',
  },
  palette: {
    primary: {
      main: '#0F3444',
      light: '#164C64',
    },
    secondary: {
      main: '#FBFCFD',
    },
    danger: {
      main: '#FF0033',
    },
    white: {
      main: '#FFFFFF',
    },
    blue: {
      main: '#31ADE3',
      light: ' #EAF7FC',
      dark: '#1B2746',
    },
    grey: {
      main: '#C9CEDB',
      blue: '#78839D',
      F4: '#F4F5F8',
      A0: '#A0A8BD',
      FA: '#FAFAFA',
      F5: '#F5F5F5',
      '2E': '#2E3A59',
      82: '#828282',
      E7: '#E7E7E7',
      EA: '#EAEBEE',
    },
    hover: {
      lightBlue: '#2A95C3',
    },
    buttons: {
      default: ['#2A95C3', '#31ADE3', '#237DA3'],
      danger: ['#F85453', '#F97372', '#D54847'],
      green: ['#219754', '#49AA73', '#E9F5EE'],
    },
    status: {
      red: ['#ffadad', '#F85453', '#FEEEEE'],
      yellow: ['', '#FDC02E'],
      blue: ['#C5E8F7', '#31ADE3'],
      green: ['#C1E2CF', '#219754', '#E9F5EE'],
      purple: ['#DAD4F6', '#7B65DE', '#473B81'],
      orange: ['#FADBCA', '#EE7D40'],
      grey: ['#FAFAFA', '#78839D'],
    },
    shadows: {
      main: 'rgba(15, 52, 68, 0.08)',
      light: 'rgba(0, 0, 0, 0.05)',
    },
    error: {
      main: '#f44336',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },
  },
})
