import React from 'react'
import {
  makeStyles,
  Box,
  Grid,
} from '@material-ui/core'
import PageTitle from 'components/PageTitle'
import { useSelector } from 'react-redux'
import { noop, some } from 'lodash'
import { useTranslation } from 'react-i18next'
import { getEmpresaSelecionada } from 'store/modules/empresas/empresas.selectors'
import { getDivisoesSetores } from 'store/modules/divisoesSetores/divisoesSetores.selectors'
import Tabs from 'components/Tabs'
import EmpresaRelatorio from './EmpresaRelatorio/Relatorio'
import EmpresaDadosGerais from './EmpresaDadosGerais/DadosGerais'
import EmpresaPlanoAuditoria from './EmpresaPlanoAuditoria/PlanoAuditoria'
import EmpresaPareceres from './EmpresaPareceres/Pareceres'

const useStyles = makeStyles((theme) => ({
  container: {
    boxShadow: '0px 8px 32px rgba(15, 52, 68, 0.08)',
    borderRadius: 8,
    padding: theme.spacing(1, 2, 4),
    backgroundColor: theme.palette.common.white,
  },
  backButton: {
    margin: theme.spacing(0, 4, 0, 0),
  },
  saveButton: {
    background: theme.palette.hover.lightBlue,
    fontSize: theme.typography.pxToRem(14),
    textTransform: 'capitalize',
  },
  formContainer: {
    padding: theme.spacing(4, 2, 8),
    maxWidth: 830,
    margin: '0 auto',
  },
  gridButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  mainTitle: {
    color: theme.palette.common.white,
    fontSize: 32,
    marginTop: 44,
    marginLeft: 80,
    marginBottom: 80,
  },
}))

const Empresa = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const empresa = useSelector(getEmpresaSelecionada)
  const listaDivisoesSetores = useSelector(getDivisoesSetores)

  const handleSalvar = (extraFunc = noop) => (() => {
    extraFunc?.()
  })

  const desativarAba = (id) => {
    if (id === '1') {
      return !empresa?.possuiDivisaoSetor
    }

    if (id === '2') {
      if (!empresa?.possuiDivisaoSetor) {
        return !empresa?.possuiDivisaoSetor
      }
      return !some(listaDivisoesSetores, 'possuiUnidade')
    }

    return false
  }

  return (
    <Grid>
      <PageTitle title={t('empresa.titulo')} />
      <Box className={classes.container}>
        <Tabs
          renderTabs={({ Tab }) => ([
            <Tab label={t('meuPerfil.minhaEmpresa')} value="0" key="0" disabled={desativarAba(0)} />,
            <Tab label={t('meuPerfil.divisaoSetor')} value="1" key="1" disabled={desativarAba(1)} />,
            <Tab label={t('meuPerfil.unidade')} value="2" key="2" disabled={desativarAba(2)} />,
            <Tab label={t('meuPerfil.usuarios')} value="3" key="3" disabled={desativarAba(3)} />,
          ])}
        >
          {({ TabPanel }) => (
            <>
              <TabPanel value="0">
                <EmpresaRelatorio onSave={handleSalvar} />
              </TabPanel>
              <TabPanel value="1">
                <EmpresaDadosGerais onSave={handleSalvar} />
              </TabPanel>
              <TabPanel value="2">
                <EmpresaPlanoAuditoria onSave={handleSalvar} />
              </TabPanel>
              <TabPanel value="3">
                <EmpresaPareceres onSave={handleSalvar} />
              </TabPanel>
            </>
          )}
        </Tabs>
      </Box>
    </Grid>
  )
}

export default Empresa
