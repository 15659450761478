import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

const MainColumn = ({ columns, ...props }) => (
  <Box display="grid" gridGap={16} gridTemplateColumns={columns} width="100%" {...props} />
)

MainColumn.propTypes = {
  columns: PropTypes.string,
}

export default MainColumn
