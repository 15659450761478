import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchGruposActionTypes = apiActionTypes('grupos/API_LISTAR_GRUPOS')
const updateGrupoActionTypes = apiActionTypes('grupos/API_ATUALIZAR_GRUPOS')
const createGrupoActionTypes = apiActionTypes('grupos/API_CRIAR_GRUPOS')
const deleteGruposActionTypes = apiActionTypes('grupos/API_EXCLUIR_GRUPOS')

export const API_LISTAR_GRUPOS = asApiTypesObject(fetchGruposActionTypes)
export const API_CRIAR_GRUPO = asApiTypesObject(createGrupoActionTypes)
export const API_EXCLUIR_GRUPO = asApiTypesObject(deleteGruposActionTypes)

export const apiListarGrupos = ({ paisId }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/grupos`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchGruposActionTypes,
}, { ...meta, query: { paisId } })

export const apiAtualizarGrupo = ({ id, grupo }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/grupos/${id}`,
  method: 'PUT',
  types: updateGrupoActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(grupo),
}, { ...meta, successNotification: 'mensagens.atualizado' })

export const apiCriarGrupo = (grupo, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/grupos`,
  method: 'POST',
  types: createGrupoActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(grupo),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiSalvarListaGrupo = ({ lista }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/grupos/salvarLista`,
  method: 'POST',
  types: createGrupoActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(lista),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiExcluirGrupo = ({ id }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/grupos/${id}`,
  method: 'DELETE',
  types: deleteGruposActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })
