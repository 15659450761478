import React, { useState } from 'react'
import {
  Grid,
  Typography,
  makeStyles,
  AppBar,
  Box,
  FormControlLabel,
  Radio,
} from '@material-ui/core'
import { FieldArray } from 'react-final-form-arrays'
import { Form, FormSpy } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import * as yup from 'yup'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { get, map, isEmpty, head } from 'lodash'
import { getDivisoesSetores } from 'store/modules/divisoesSetores/divisoesSetores.selectors'
import { getSetores } from 'store/modules/setores/setores.selectors'
import { callSalvarListaDivisaoSetor } from 'store/modules/divisoesSetores'
import { useTranslation } from 'react-i18next'
import { getEmpresaUsuario, getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import Button from 'components/Button'
import Tab from 'components/Tabs/Tab'
import { validateFormValues } from 'utils/forms'
import { TIPO_USUARIO } from 'utils/constantes'
import { FieldAutocomplete, FieldText } from 'components/Fields'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(7, 0, 2),
  },
  container: {
    maxWidth: '672px',
    margin: '0 auto',
  },
  root: {
    width: '10%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  content: {
    flexGrow: 0,
  },
  expandIcon: {
    order: -1,
    marginRight: '0',
    color: 'blue',
  },
  accordionExpanded: {
    minHeight: 'unset !important',
  },
  title: {
    fontSize: '32px',
    fontWeight: '400',
    color: '#0F3444',
  },
  subtitle: {
    marginBottom: theme.spacing(3),
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '400',
    color: '#0F3444',
  },
  formContainer: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    padding: theme.spacing(4),
    margin: theme.spacing(0, 0, 2, 0),
    '& .MuiTabPanel-root': {
      padding: '40px 0 0',
    },
    '& .Mui-selected': {
      color: '#31ADE3',
    },
    '& .MuiAppBar-root': {
      borderBottom: '1px solid #C9CEDB',
    },
  },
  label: {
    fontFamily: 'Inter',
    color: theme.palette.primary.main,
    fontWeight: '400',
    fontSize: '14px',
  },
  separator: {
    width: '100%',
    height: 1,
    background: '#C9CEDB',
    margin: theme.spacing(3, 'auto'),
    border: '0',
  },
  tabPanel: {
    padding: 0,
  },
  addDivision: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  btnAdd: {
    border: '1px solid #C9CEDB',
    height: theme.spacing(5),
    fontFamily: 'Poppins',
    textTransform: 'capitalize',
  },
  tabList: {
    marginBottom: theme.spacing(5),
    borderBottom: '1px solid #C9CEDB',
    '& .MuiTab-root': {
      color: '#78839D',
      fontSize: '14px',
      minWidth: 'auto',
      '&.Mui-selected': {
        color: '#31ADE3',
      },
    },
  },
  tabIndicator: {
    height: 4,
    transform: 'scaleX(0.8)',
    borderRadius: theme.spacing(2, 2, 0, 0),
    backgroundColor: theme.palette.blue.main,
  },
}))

const EmpresaDivisaoSetorDetalhe = ({ Botoes, setChangeTab }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const empresa = useSelector(getEmpresaUsuario)
  const listaDivisoesSetores = useSelector(getDivisoesSetores)
  const listaSetores = useSelector(getSetores)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const [tabSelecionado, setTabSelecionado] = useState(isEmpty(listaDivisoesSetores) ? '0' : `${head(listaDivisoesSetores)?.nome}0`)
  const [lastPositionTab, setLastPositionTab] = useState('0')

  const handleSave = (values) => {
    const data = map(values.divisoesSetores, (divisaoSetor) => ({
      ...divisaoSetor,
      pais: undefined,
      uf: undefined,
      municipio: undefined,
      setor: undefined,
      empresaId: empresa.id,
    }))
    dispatch(callSalvarListaDivisaoSetor({ lista: data, successNotification: 'mensagens.criado' }))
  }

  const handleChange = () => ((e, newValue) => {
    setTabSelecionado(newValue)
  })

  const initialValues = [{
    nome: '',
    cnpj: '',
    endereco: '',
    cep: '',
    nomeUf: '',
    nomeMunicipio: '',
    setorId: null,
    nomeContato: '',
    emailContato: '',
    cargoContato: '',
    telefoneContato: '',
    informacoesAdicionais: '',
    possuiUnidade: false,
    nomePais: '',
  }]

  return (
    <Box className={classes.container}>
      <Form
        initialValues={{
          divisoesSetores: isEmpty(listaDivisoesSetores) ? initialValues : listaDivisoesSetores,
        }}
        validate={
          validateFormValues(
            yup.object().shape({
              divisoesSetores: yup.array().of(
                yup.object().shape({
                  nome: yup.string().required(),
                  cnpj: yup.string().required(),
                  endereco: yup.string().required(),
                  cep: yup.string().required(),
                  nomeUf: yup.string().required(),
                  nomeMunicipio: yup.string().required(),
                  setorId: yup.number().required(),
                  nomeContato: yup.string().required(),
                  emailContato: yup.string().email().required(),
                  nomePais: yup.string().required(),
                }),
              ),
            }),
          )
        }
        mutators={{ ...arrayMutators }}
        onSubmit={(values) => {
          handleSave(values)
          setTimeout(() => {
            setTabSelecionado(values.divisoesSetores[Number(lastPositionTab)].nome +
              lastPositionTab)
          }, 700)
        }}
        render={({
          handleSubmit,
          errors,
          form,
          values,
          pristine,
          valid,
          form: { mutators: { push } },
        }) => (
          <form>
            <FormSpy
              onChange={({ values: valuesSpy, initialValues: initialValuesSpy }) => {
                setChangeTab({
                  values: valuesSpy,
                  initialValues: initialValuesSpy,
                })
              }}
              subscription={{ values: true, initialValues: true }}
            />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FieldArray name="divisoesSetores" validateOnChange>
                  {() => (
                    <>
                      {tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LIDER && (
                        <Grid container className={classes.addDivision}>
                          <Button
                            onClick={() => {
                              push('divisoesSetores', {
                                nome: '',
                                cnpj: '',
                                endereco: '',
                                cep: '',
                                nomeUf: '',
                                nomeMunicipio: '',
                                setorId: null,
                                nomeContato: '',
                                emailContato: '',
                                cargoContato: '',
                                telefoneContato: '',
                                informacoesAdicionais: '',
                                possuiUnidade: false,
                                nomePais: '',
                              })
                              setTabSelecionado(String(values.divisoesSetores?.length))
                              setLastPositionTab(values.divisoesSetores?.length)
                            }}
                            className={classes.btnAdd}
                          >
                            {t('divisaoSetor.adicionarDivisaoSetor')}
                          </Button>
                        </Grid>
                      )}
                      <TabContext value={tabSelecionado}>
                        <AppBar position="static" color="transparent" elevation={0} component="nav">
                          <TabList
                            onChange={handleChange()}
                            className={classes.tabList}
                            aria-label="lista de setores"
                            variant="scrollable"
                            scrollButtons="auto"
                            classes={{ indicator: classes.tabIndicator }}
                          >
                            {map(values.divisoesSetores, (divisaoSetor, i) => (
                              <Tab
                                label={listaDivisoesSetores?.[i]?.id ? listaDivisoesSetores?.[i]?.nome : `${t('divisaoSetor.divisaoSetor')} ${i + 1}`}
                                value={(listaDivisoesSetores?.[i]?.nome ?? '') + i.toString()}
                                key={(listaDivisoesSetores?.[i]?.nome ?? '') + i.toString()}
                                disableRipple
                                onClick={() => {
                                  setLastPositionTab(String(i))
                                }}
                              />
                            ))}
                          </TabList>
                        </AppBar>
                        {map(values.divisoesSetores, (divisaoSetor, i) => (
                          <TabPanel
                            value={(listaDivisoesSetores?.[i]?.nome ?? '') + i.toString()}
                            key={(listaDivisoesSetores?.[i]?.nome ?? '') + i.toString()}
                            className={classes.tabPanel}
                          >
                            <Typography component="p" className={classes.subtitle}>
                              {t('cadastroInicial.dadosGeraisDaDivisao')}
                            </Typography>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <FieldText
                                  required
                                  name={`divisoesSetores.${i}.nome`}
                                  label={t('inputs.nomeDivisaoSetor')}
                                  disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FieldText
                                  required
                                  name={`divisoesSetores.${i}.cnpj`}
                                  label={t('inputs.cnpj')}
                                  disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FieldText
                                  required
                                  name={`divisoesSetores.${i}.nomePais`}
                                  label={t('inputs.pais')}
                                  disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FieldText
                                  required
                                  name={`divisoesSetores.${i}.nomeUf`}
                                  label={t('inputs.estado')}
                                  placeholder={t('inputs.estado')}
                                  disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FieldText
                                  required
                                  name={`divisoesSetores.${i}.nomeMunicipio`}
                                  label={t('inputs.municipio')}
                                  placeholder={t('inputs.municipio')}
                                  disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <FieldText
                                  required
                                  name={`divisoesSetores.${i}.endereco`}
                                  label={t('inputs.endereco')}
                                  disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FieldText
                                  required
                                  name={`divisoesSetores.${i}.cep`}
                                  label={t('inputs.cep')}
                                  disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FieldAutocomplete
                                  required
                                  name={`divisoesSetores.${i}.setorId`}
                                  label={t('inputs.setor')}
                                  options={(listaSetores || []).map((item) => ({
                                    value: item.id,
                                    label: t(item.nome),
                                  }))}
                                  disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                                />
                              </Grid>
                              <Grid item xs={12}><hr className={classes.separator} /></Grid>
                              <Grid item xs={12}>
                                <Typography component="p" className={classes.label}>
                                  {t('divisaoSetor.contatoDivisaoSetor')}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FieldText
                                  required
                                  name={`divisoesSetores.${i}.nomeContato`}
                                  label={t('inputs.nomeContato')}
                                  disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FieldText
                                  required
                                  name={`divisoesSetores.${i}.emailContato`}
                                  label={t('inputs.emailContato')}
                                  disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FieldText
                                  name={`divisoesSetores.${i}.cargoContato`}
                                  label={t('inputs.cargoContato')}
                                  disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FieldText
                                  name={`divisoesSetores.${i}.telefoneContato`}
                                  label={t('inputs.telefoneContato')}
                                  disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                                />
                              </Grid>
                              <Grid item xs={12}><hr className={classes.separator} /></Grid>
                              <Grid item xs={12}>
                                <Typography component="p" className={classes.label}>
                                  {t('divisaoSetor.empresaPossuiUnidadesDentroDivisaoSetor')}
                                </Typography>
                                <Grid item xs={12}>
                                  <FormControlLabel
                                    className={classes.label}
                                    label={<Typography className={classes.label}>{t('inputs.sim')}</Typography>}
                                    control={(
                                      <Radio
                                        checked={get(values, `divisoesSetores.${i}.possuiUnidade`, false)}
                                        onChange={(e) => {
                                          form.change(`divisoesSetores.${i}.possuiUnidade`, e.target.checked)
                                        }}
                                        color="primary"
                                        disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                                      />
                                    )}
                                  />
                                  <FormControlLabel
                                    className={classes.label}
                                    label={<Typography className={classes.label}>{t('inputs.nao')}</Typography>}
                                    control={(
                                      <Radio
                                        checked={!get(values, `divisoesSetores.${i}.possuiUnidade`, false)}
                                        onChange={(e) => {
                                          form.change(`divisoesSetores.${i}.possuiUnidade`, !e.target.checked)
                                        }}
                                        color="primary"
                                        disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                                      />
                                    )}
                                  />
                                </Grid>
                              </Grid>
                              <Grid item xs={12}><hr className={classes.separator} /></Grid>
                              <Grid item xs={12}>
                                <FieldText
                                  name={`divisoesSetores.${i}.informacoesAdicionais`}
                                  label={t('inputs.adicioneInformacoesAdicionais')}
                                  multiline
                                  minRows={5}
                                  maxRows={5}
                                  disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                                />
                              </Grid>
                            </Grid>
                          </TabPanel>
                        ))}
                      </TabContext>
                      {tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LIDER && (
                        <>
                          {Botoes && (
                            <Box mt={7}>
                              <Botoes extraFunc={handleSubmit} formErrors={errors} />
                            </Box>
                          )}
                          {!Botoes && (
                            <Grid container justifyContent="flex-end" className={classes.submit}>
                              <Button
                                onClick={handleSubmit}
                                disabled={pristine || !valid}
                              >
                                {t('inputs.salvar')}
                              </Button>
                            </Grid>
                          )}
                        </>
                      )}
                    </>
                  )}
                </FieldArray>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Box>
  )
}

EmpresaDivisaoSetorDetalhe.propTypes = {
  Botoes: PropTypes.func,
  setChangeTab: PropTypes.func,
}

export default EmpresaDivisaoSetorDetalhe
