import React from 'react'
import { Checkbox, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  checkbox: {
    padding: theme.spacing(0.5),
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.buttons.default[1],
    },
  },
}))

const BaseCheckbox = (props) => {
  const classes = useStyles()

  return (
    <Checkbox
      color="primary"
      className={classes.checkbox}
      {...props}
    />
  )
}

export default BaseCheckbox
