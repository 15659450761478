import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { Grid, makeStyles } from '@material-ui/core'
import { chain, bignumber } from 'mathjs'
import { useSelector, useDispatch } from 'react-redux'
import { getAcaoSelecionada, getIsSavingAcoes } from 'store/modules/acoes/acoes.selectors'
import { callAtualizarAcao, callExcluirAcao } from 'store/modules/acoes'
import { getMoedas } from 'store/modules/moedas/moedas.selectors'
import { getEcorregioes } from 'store/modules/ecorregioes/ecorregioes.selectors'
import { getBiomas } from 'store/modules/biomas/biomas.selectors'
import { getProjetoSelecionado } from 'store/modules/projetos/projetos.selectors'
import { useTranslation } from 'react-i18next'
import { validateFormValues } from 'utils/forms'
import { Form, FormSpy } from 'react-final-form'
import { FieldText, FieldCurrency, FieldSelect, FieldUpload } from 'components/Fields'
import ArrowRightCircleLineIcon from 'remixicon-react/ArrowRightCircleLineIcon'
import Button from 'components/Button'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import { getEmpresaUsuario, getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { TIPO_USUARIO } from 'utils/constantes'

const useStyles = makeStyles((theme) => ({
  information: {
    color: theme.palette.primary.main,
    fontFamily: 'Poppins',
    fontSize: 10,
    fontWeight: 400,
  },
}))

const DadosAcao = ({ setChangeTab }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const dialog = useConfirmationDialog()
  const classes = useStyles()
  const { pais } = useSelector(getEmpresaUsuario)
  const projetoSelecionado = useSelector(getProjetoSelecionado)
  const acaoSelecionada = useSelector(getAcaoSelecionada)
  const listaMoedas = useSelector(getMoedas)
  const listaEcorregioes = useSelector(getEcorregioes)
  const listaBiomas = useSelector(getBiomas)
  const isSaving = useSelector(getIsSavingAcoes)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const handleClickMapaEcorregioes = (link) => {
    window.open(link, '_blank')
  }

  const calcularFaturamento = ({ investimento, cotacaoDolar } = {}) => {
    let faturamento = null
    if (investimento && cotacaoDolar) {
      faturamento = chain(bignumber(investimento))
        .divide(bignumber(cotacaoDolar))
        .number()
        .done()
    }
    return faturamento
  }

  const handleCurrencyChange = (form, { investimento, cotacaoDolar }) => {
    form.change('valorUs', calcularFaturamento({ investimento, cotacaoDolar }))
  }

  const handleSave = (values) => {
    dispatch(callAtualizarAcao({
      id: acaoSelecionada.id,
      acao: {
        ...values,
        projetoId: projetoSelecionado.id,
      },
    }))
  }

  const handleDelete = () => {
    const { id } = acaoSelecionada
    dialog.showConfirmation({
      title: t('dialogs.confirmarExclusao'),
      message: t('dialogs.excluirProjetoAcao', { acao: acaoSelecionada.descricao, projeto: projetoSelecionado.nome }),
    }).then((result) => result && dispatch(callExcluirAcao({ id })))
  }

  return (
    <Form
      onSubmit={handleSave}
      validate={validateFormValues(yup.object({
        descricao: yup.string().required(),
        objetivo: yup.string().required(),
        area: yup.number().positive().nullable(),
        nomeUf: yup.string().nullable(),
        nomeMunicipio: yup.string().nullable(),
        ecorregiaoId: yup.number().required(),
        investimento: yup.number().positive().nullable(),
        moedaId: yup.number().nullable(),
        cotacaoDolar: yup.number().positive().nullable(),
        biomaId: yup.number().nullable(),
        comentarioOrganizacao: yup.string(),
        evidenciaAcaoOrganizacao: yup.string(),
        nomePais: yup.string().required(),
      }))}
      initialValues={{
        descricao: acaoSelecionada?.descricao ?? '',
        objetivo: acaoSelecionada?.objetivo ?? '',
        area: acaoSelecionada?.area ?? null,
        nomeUf: acaoSelecionada?.nomeUf ?? null,
        nomeMunicipio: acaoSelecionada?.nomeMunicipio ?? null,
        investimento: acaoSelecionada?.investimento ?? null,
        valorUs: acaoSelecionada?.valorUs ?? null,
        moedaId: acaoSelecionada?.moedaId ?? null,
        cotacaoDolar: acaoSelecionada?.cotacaoDolar ?? null,
        ecorregiaoId: acaoSelecionada?.ecorregiaoId ?? null,
        biomaId: acaoSelecionada?.biomaId ?? null,
        comentarioOrganizacao: acaoSelecionada?.comentarioOrganizacao ?? '',
        evidenciaAcaoOrganizacao: acaoSelecionada?.evidenciaAcaoOrganizacao ?? '',
        anexo: acaoSelecionada?.anexo ?? null,
        nomePais: acaoSelecionada?.nomePais ?? null,
      }}
      render={({ handleSubmit, form, values, pristine, valid }) => (
        <form onSubmit={handleSubmit}>
          <FormSpy
            onChange={({ values: valuesSpy, initialValues }) => {
              setChangeTab({
                values: valuesSpy,
                initialValues,
              })
            }}
            subscription={{ values: true, initialValues: true }}
          />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FieldText
                required
                name="descricao"
                label={t('inputs.nomeAcao')}
                placeholder={t('inputs.insiraNome')}
                disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldText
                required
                name="objetivo"
                label={t('inputs.objetivoAcao')}
                placeholder={t('inputs.objetivo')}
                disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldText
                name="area"
                type="number"
                label={t('inputs.areaHA')}
                placeholder={t('inputs.insiraAreaHA')}
                disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldText
                required
                name="nomePais"
                label={t('inputs.pais')}
                placeholder={t('inputs.insiraPais')}
                disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldText
                name="nomeUf"
                label={t('inputs.estado')}
                placeholder={t('inputs.estado')}
                disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldText
                name="nomeMunicipio"
                label={t('inputs.municipio')}
                placeholder={t('inputs.municipio')}
                disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldSelect
                required
                name="ecorregiaoId"
                label={t('inputs.ecorregiao')}
                options={listaEcorregioes.map((option) => ({
                  label: option.nome,
                  value: option.id,
                }))}
                disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldSelect
                name="biomaId"
                label={t('inputs.bioma')}
                options={listaBiomas.map((option) => ({
                  label: option.nome,
                  value: option.id,
                }))}
                disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
              />
            </Grid>
            {
              pais?.linkEcorregioesTerrestres ? (
                <Grid item xs={12}>
                  <Button
                    link
                    startIcon={ArrowRightCircleLineIcon}
                    onClick={() => handleClickMapaEcorregioes(pais?.linkEcorregioesTerrestres)}
                  >
                    {t('desempenhoBiodiversidade.verMapaEcorregioes')}
                  </Button>
                </Grid>
              ) : null
            }
            <Grid item xs={12} sm={6}>
              <FieldCurrency
                name="investimento"
                label={t('inputs.investimento')}
                onChange={handleCurrencyChange(form, values)}
                disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldSelect
                name="moedaId"
                label={t('inputs.moeda')}
                options={(listaMoedas || []).map((option) => ({
                  label: t(option.nome),
                  value: option.id,
                }))}
                disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldCurrency
                name="cotacaoDolar"
                label={t('inputs.cotacaoDolar')}
                disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldCurrency
                disabled
                name="valorUs"
                label={t('inputs.valorUs')}
              />
            </Grid>
            <Grid item xs={12}>
              <small className={classes.information}>
                {t('faturamento.informacao')}
              </small>
            </Grid>
            <Grid item xs={12}>
              <FieldText
                multiline
                minRows={5}
                maxRows={5}
                name="comentarioOrganizacao"
                label={t('inputs.comentarioOrganizacao')}
                placeholder={t('inputs.adicioneComentarioOrganizacao')}
                disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldText
                multiline
                minRows={5}
                maxRows={5}
                name="evidenciaAcaoOrganizacao"
                placeholder={t('inputs.comentarioEmpresa')}
                label={t('inputs.evidenciaAcaoOrganizacao')}
                disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldUpload
                name="anexo"
                disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
              />
            </Grid>
            {tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LEITOR && (
              <Grid item xs={12}>
                <Grid container direction="row" justifyContent="space-between">
                  <Button variant="danger" disabled={!acaoSelecionada} onClick={handleDelete}>
                    {t('inputs.excluir')}
                  </Button>
                  <Button
                    type="submit"
                    disabled={pristine || !valid}
                    loading={isSaving}
                  >
                    {t('inputs.salvar')}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </form>
      )}
    />
  )
}

DadosAcao.propTypes = {
  setChangeTab: PropTypes.func,
}

export default DadosAcao
