import { filter } from 'lodash'
import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { API_LISTAR_CLASSES_IRC, API_SALVAR_IRC, API_EXCLUIR_CLASSES_IRC, API_ATUALIZAR_CLASSES_IRC } from 'store/modules/api/classesIrc.actions'

const lista = (state = [], { type, payload }) => {
  switch (type) {
    case API_LISTAR_CLASSES_IRC.success: return payload
    case API_SALVAR_IRC.success: return payload.lista
    case API_ATUALIZAR_CLASSES_IRC.success:
      return state.map((e) => (e.id === payload.id ?
        payload : e))
    case API_EXCLUIR_CLASSES_IRC.success:
      return filter(state, (o) => o.id !== Number(payload))
    default: return state
  }
}

const isCarregandoLista = loadingReducer(false, API_LISTAR_CLASSES_IRC)

const isSaving = loadingReducer(false, API_SALVAR_IRC)
const isUpdate = loadingReducer(false, API_ATUALIZAR_CLASSES_IRC)

export default combineReducers({
  lista,
  isCarregandoLista,
  isSaving,
  isUpdate,
})
