import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { filter, map } from 'lodash'
import { API_LISTAR_DADOS_IPB } from 'store/modules/api/pressaoBiodiversidade.actions'
import { API_CRIAR_ENERGIA, API_ATUALIZAR_ENERGIA, API_EXCLUIR_ENERGIA, API_GET_ENERGIA } from 'store/modules/api/energia.actions'
import { CALL_NOVO_ENERGIA } from 'store/modules/energia/energia.actions'
import { API_EXCLUIR_ANEXO } from 'store/modules/api/anexos.actions'

const lista = (state = [], { type, payload }) => {
  switch (type) {
    case API_LISTAR_DADOS_IPB.success: return payload.consumoEnergia
    case API_CRIAR_ENERGIA.success: return [...state, payload.consumoEnergia]
    case API_EXCLUIR_ENERGIA.success: return filter(state, (u) => u.id !== payload.id)
    case API_ATUALIZAR_ENERGIA.success:
      return map(state, (u) => (u.id === payload.consumoEnergia.id ? payload.consumoEnergia : u))
    default: return state
  }
}

const selecionado = (state = null, { type, payload }) => {
  switch (type) {
    case API_GET_ENERGIA.success: return payload
    case CALL_NOVO_ENERGIA: return null
    case API_CRIAR_ENERGIA.success: return null
    case API_ATUALIZAR_ENERGIA.success: return null
    case API_EXCLUIR_ANEXO.success:
      return state?.anexo?.id === +payload ? { ...state, anexo: null } : state
    default: return state
  }
}

const isCarregandoEnergia = loadingReducer(false, API_LISTAR_DADOS_IPB)

const isSaving = loadingReducer(false, {
  request: [
    API_ATUALIZAR_ENERGIA.request,
    API_CRIAR_ENERGIA.request,
  ],
  success: [
    API_ATUALIZAR_ENERGIA.success,
    API_CRIAR_ENERGIA.success,
  ],
  failure: [
    API_ATUALIZAR_ENERGIA.failure,
    API_CRIAR_ENERGIA.failure,
  ],
})

export default combineReducers({
  lista,
  selecionado,
  isCarregandoEnergia,
  isSaving,
})
