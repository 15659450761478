import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import {
  API_GET_RELATORIO_AUDITORIA,
  API_GET_RELATORIO_SUMARIO,
} from 'store/modules/api/relatorios/relatoriosAuditoria.actions'

const isCarregandoRelatorioAuditoria =
  loadingReducer(false, API_GET_RELATORIO_AUDITORIA)
const isCarregandoRelatorioSumario =
  loadingReducer(false, API_GET_RELATORIO_SUMARIO)

export default combineReducers({
  isCarregandoRelatorioAuditoria,
  isCarregandoRelatorioSumario,
})
