import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box, makeStyles, Tab, Typography } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import TabAspectos from './TabAspectos'
import TabCalibracaoDMB from './TabCalibracaoDMB'

export const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
  screenTitle: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(5),
    fontSize: theme.typography.pxToRem(24),
  },
  panel: {
    padding: theme.spacing(4, 0),
  },
  tabIndicator: {
    height: 4,
    transform: 'scaleX(0.8)',
    borderRadius: theme.spacing(2, 2, 0, 0),
    backgroundColor: theme.palette.blue.main,
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
  tabList: {
    '& .MuiTab-root': {
      '&.Mui-selected': {
        color: '#31ADE3',
      },
    },
  },
}))

const FatoresValores = ({ paisId }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const tabs = [
    { key: '0', title: t('paisesCalculos.AspectosAmbientais'), component: TabAspectos },
    { key: '1', title: t('paisesCalculos.CalibracaoDMB'), component: TabCalibracaoDMB },
  ]

  const [currentTab, setCurrentTab] = useState(tabs[0].key)

  return (
    <>
      <div className={classes.sectionTitle}>
        <Typography className={classes.screenTitle}>
          {t('paisesCalculos.FatoresValores')}
        </Typography>
      </div>
      <TabContext value={currentTab}>
        <div className={classes.sectionHeader}>
          <Box>
            <TabList
              classes={{ indicator: classes.tabIndicator }}
              className={classes.tabList}
            >
              {
            tabs.map((elem, index) => (
              <Tab
                label={elem.title}
                value={elem.key}
                key={index}
                onClick={() => { setCurrentTab(elem.key) }}
              />
            ))
          }
            </TabList>
          </Box>
        </div>
        { tabs.map(({ component: Component, ...tab }) => (
          <TabPanel className={classes.panel} value={tab.key} key={tab.key}>
            <Component paisId={paisId} />
          </TabPanel>
        ))}
      </TabContext>
    </>
  )
}

FatoresValores.propTypes = {
  paisId: PropTypes.number,
}

export default FatoresValores
