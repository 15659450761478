import { combineReducers } from 'redux'
import { SELECIONAR_EMPRESA_AUDICAO, CHANGE_ANO_SELECIONADO } from 'store/modules/auditor/auditor.actions'
import {
  API_LISTAR_USUARIO_EMPRESAS_AUDITORIAS,
  API_LISTAR_DADOS_AUDITORIAS,
  API_SALVAR_DADOS_AUDITORIA,
  API_LISTAR_UNIDADES_EMPRESA,
  API_SALVAR_PARECER_PRESSAO,
  API_SALVAR_PARECER_DESEMPENHO,
  API_SALVAR_PARECER_INDICADOR,
} from 'store/modules/api/auditor.actions'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { loadingReducer } from 'redux-foundry'

const empresasAuditorias = (state = [], { type, payload }) => {
  switch (type) {
    case API_LISTAR_USUARIO_EMPRESAS_AUDITORIAS.success: return payload
    default: return state
  }
}

const empresaSelecionada = (state = null, { type, payload }) => (
  (type === SELECIONAR_EMPRESA_AUDICAO) ? payload : state
)

const dadosAuditoriaSelecionada = (state = null, { type, payload }) => {
  switch (type) {
    case API_LISTAR_DADOS_AUDITORIAS.success: return isEmpty(payload) ? null : payload
    case API_SALVAR_DADOS_AUDITORIA.success: return payload.auditoria
    default: return state
  }
}

const isSavingAuditoriaSelecionada = loadingReducer(false, API_SALVAR_DADOS_AUDITORIA)

const anoSelecionado = (state = +moment().format('YYYY'), { type, payload }) => (
  (type === CHANGE_ANO_SELECIONADO) ? payload : state
)

const listaParecerPressaoBiodiverdidade = (state = [], { type, payload }) => {
  switch (type) {
    case API_LISTAR_DADOS_AUDITORIAS.success:
      return payload.pareceresPressaoBiodiversidade || []
    case API_SALVAR_PARECER_PRESSAO.success:
      return [...state.filter((e) => e.id !== payload.id), payload]
    default:
      return state
  }
}

const isSavingParecerPressaoBiodiverdidade = loadingReducer(false, API_SALVAR_PARECER_PRESSAO)

const listaUnidadesEmpresa = (state = [], { type, payload }) => (
  (type === API_LISTAR_UNIDADES_EMPRESA.success) ? (payload.unidades || []) : state
)

const listaProjetosEmpresa = (state = [], { type, payload }) => (
  (type === API_LISTAR_UNIDADES_EMPRESA.success) ? (payload.projetos || []) : state
)

const listaPrincipiosEmpresa = (state = [], { type, payload }) => (
  (type === API_LISTAR_UNIDADES_EMPRESA.success) ? (payload.principios || []) : state
)

const listaParecerDesempenhoBiodiverdidade = (state = [], { type, payload }) => {
  switch (type) {
    case API_LISTAR_DADOS_AUDITORIAS.success: return payload.pareceresDesempenhoBiodiversidade || []
    case API_SALVAR_PARECER_DESEMPENHO.success:
      return [...state.filter((e) => e.id !== payload.id), payload]
    default: return state
  }
}

const isSavingParecerDesempenhoBiodiverdidade = loadingReducer(false, API_SALVAR_PARECER_DESEMPENHO)

const listaParecerIndicadorGestao = (state = [], { type, payload }) => {
  switch (type) {
    case API_LISTAR_DADOS_AUDITORIAS.success: return payload.pareceresIndicadorGestao || []
    case API_SALVAR_PARECER_INDICADOR.success:
      return [...state.filter((e) => e.id !== payload.id), payload]
    default: return state
  }
}

const isSavingParecerIndicadorGestao = loadingReducer(false, API_SALVAR_PARECER_INDICADOR)

export default combineReducers({
  empresasAuditorias,
  empresaSelecionada,
  dadosAuditoriaSelecionada,
  anoSelecionado,
  listaParecerPressaoBiodiverdidade,
  listaUnidadesEmpresa,
  listaParecerDesempenhoBiodiverdidade,
  listaProjetosEmpresa,
  listaParecerIndicadorGestao,
  listaPrincipiosEmpresa,
  isSavingParecerPressaoBiodiverdidade,
  isSavingParecerIndicadorGestao,
  isSavingParecerDesempenhoBiodiverdidade,
  isSavingAuditoriaSelecionada,
})
