import {
  Grid,
  makeStyles,
} from '@material-ui/core'
import Button from 'components/Button'
import { FieldAutocomplete, FieldSelect, FieldText, FieldUpload } from 'components/Fields'
import { find, get } from 'lodash'
import { bignumber, chain } from 'mathjs'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { getClasseResiduos } from 'store/modules/classeResiduos/classeResiduos.selectors'
import { getConversoesMassa } from 'store/modules/conversoesMassa/conversoesMassa.selectors'
import { getConversoesTempo } from 'store/modules/conversoesTempo/conversoesTempo.selectors'
import { getMatrizResiduos } from 'store/modules/matrizResiduos/matrizResiduos.selectors'
import { callAtualizarResiduos, callCriarResiduos, callNovoResiduo } from 'store/modules/residuos'
import { getIsSavingResiduos, getResiduosSelecionado } from 'store/modules/residuos/residuos.selectors'
import { TIPO_USUARIO } from 'utils/constantes'
import { validateFormValues } from 'utils/forms'
import * as yup from 'yup'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(12, 1.5, 2),
  },
}))

const Residuos = ({ unidadeSelecionada, anoSelecionado, setChangeTab }) => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const [exibirFormulario, setExibirFormulario] = useState(false)

  const residuoSelecionado = useSelector(getResiduosSelecionado)
  const listaClasseResiduos = useSelector(getClasseResiduos)
  const listaConversoesMassa = useSelector(getConversoesMassa)
  const listaConversoesTempo = useSelector(getConversoesTempo)
  const matrizResiduos = useSelector(getMatrizResiduos)
  const isSaving = useSelector(getIsSavingResiduos)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const opcoesResiduos = (classeId = residuoSelecionado?.residuoClasse) => {
    const options = matrizResiduos
      .filter((item) => (classeId ? item.residuoClasseId === classeId : false))
      .map(({ residuoDestinacao }) => ({
        value: residuoDestinacao.id,
        label: t(residuoDestinacao.destinacao),
      }))
      .sort((a, b) => a.label.localeCompare(b.label, i18n.language))
    return options
  }

  const calcularQuantidade = ({ conversaoMassaId, conversaoTempoId, quantidade }) => {
    let quantidadeCalculada = null
    if (conversaoMassaId && conversaoTempoId && quantidade) {
      const conversaoMassa = find(listaConversoesMassa, { id: conversaoMassaId })
      const conversaoTempo = find(listaConversoesTempo, { id: conversaoTempoId })

      quantidadeCalculada = chain(bignumber(quantidade))
        .multiply(bignumber(conversaoMassa?.fator))
        .multiply(bignumber(conversaoTempo?.fator))
        .number()
        .format({ notation: 'fixed', precision: 5 })
        .done()

      if (Number(quantidadeCalculada) === residuoSelecionado?.quantidadeCalculada) {
        return residuoSelecionado.quantidadeCalculada
      }
    }
    return quantidadeCalculada
  }

  const handleValueChange = (form, { conversaoMassaId, conversaoTempoId, quantidade }) => {
    form.change('quantidadeCalculada', calcularQuantidade({ conversaoMassaId, conversaoTempoId, quantidade }))
  }

  const alterarStateFormulario = () => {
    setExibirFormulario(!exibirFormulario)
    dispatch(callNovoResiduo())
  }

  const handleSave = (values, form) => {
    const data = {
      ...values,
      unidadeId: get(unidadeSelecionada, 'id'),
      ano: anoSelecionado,
      empresaId: get(unidadeSelecionada, 'empresaId'),
    }

    if (!residuoSelecionado && callCriarResiduos) {
      dispatch(callCriarResiduos(data))
    } else if (callAtualizarResiduos) {
      dispatch(callAtualizarResiduos({ id: residuoSelecionado.id, residuo: data }))
    }
    form.reset()
    alterarStateFormulario()
  }

  useEffect(() => {
    if (residuoSelecionado?.id) {
      setExibirFormulario(true)
    }
  }, [residuoSelecionado])

  useEffect(() => {
    setExibirFormulario(false)
  }, [unidadeSelecionada])

  return exibirFormulario ? (
    <Form
      onSubmit={handleSave}
      initialValues={{
        descricao: residuoSelecionado?.descricao ?? '',
        quantidade: residuoSelecionado?.quantidade ?? null,
        residuoClasseId: residuoSelecionado?.residuoClasseId ?? null,
        residuoDestinacaoId: residuoSelecionado?.residuoDestinacaoId ?? null,
        conversaoMassaId: residuoSelecionado?.conversaoMassaId ?? null,
        conversaoTempoId: residuoSelecionado?.conversaoTempoId ?? null,
        evidenciaOcorrencia: residuoSelecionado?.evidenciaOcorrencia ?? '',
        comentario: residuoSelecionado?.comentario ?? '',
        quantidadeCalculada: residuoSelecionado?.quantidadeCalculada ?? null,
        anexo: residuoSelecionado?.anexo ?? null,
      }}
      validate={validateFormValues(yup.object({
        descricao: yup.string().required(),
        residuoClasseId: yup.number().required(),
        residuoDestinacaoId: yup.number().required(),
        quantidade: yup.number().positive().required(),
        conversaoMassaId: yup.number().required(),
        conversaoTempoId: yup.number().required(),
        evidenciaOcorrencia: yup.string(),
        comentario: yup.string(),
      }))}
      render={({ handleSubmit, submitting, values, form, pristine, valid }) => (
        <form onSubmit={handleSubmit}>
          <FormSpy
            onChange={({ values: valuesSpy, initialValues }) => {
              setChangeTab({
                values: valuesSpy,
                initialValues,
              })
            }}
            subscription={{ values: true, initialValues: true, pristine: true }}
          />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FieldText
                required
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                name="descricao"
                label={t('inputs.descricao')}
                placeholder={t('inputs.insiraDescricao')}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldAutocomplete
                required
                name="residuoClasseId"
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                label={t('inputs.classeResiduo')}
                onChange={() => form.change('residuoDestinacaoId', null)}
                options={listaClasseResiduos.map((option) => ({
                  value: option.id,
                  label: t(option.classe),
                }))}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldAutocomplete
                required
                noOptionsText={t('inputs.selecioneClasseResiduo')}
                name="residuoDestinacaoId"
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                label={t('inputs.destinacao')}
                options={opcoesResiduos(values.residuoClasseId)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldText
                required
                type="number"
                name="quantidade"
                label={t('inputs.quantidade')}
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                onChange={handleValueChange(form, values)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldSelect
                required
                id="conversaoMassaId"
                name="conversaoMassaId"
                label={t('inputs.unidade')}
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                options={(listaConversoesMassa || []).map((option) => ({
                  label: t(option.unidade),
                  value: option.id,
                }))}
                onChange={handleValueChange(form, values)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldSelect
                required
                id="conversaoTempoId"
                name="conversaoTempoId"
                label={t('inputs.periodo')}
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                options={(listaConversoesTempo || []).map((option) => ({
                  label: t(option.unidade),
                  value: option.id,
                }))}
                onChange={handleValueChange(form, values)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldText
                disabled
                type="number"
                name="quantidadeCalculada"
                label={t('inputs.quantidadeTonAno')}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldText
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                name="comentario"
                label={t('inputs.comentario')}
                multiline
                minRows={5}
                maxRows={5}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldText
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                name="evidenciaOcorrencia"
                label={t('inputs.evidenciaOcorrencia')}
                multiline
                minRows={5}
                maxRows={5}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldUpload
                name="anexo"
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
              />
            </Grid>
            <Grid container direction="row" justifyContent="space-between" className={classes.submit}>
              <Button variant="danger" onClick={alterarStateFormulario}>
                {t('inputs.cancelar')}
              </Button>
              <Button
                type="submit"
                disabled={submitting || !unidadeSelecionada || pristine || !valid}
                loading={isSaving}
              >
                {t('inputs.salvar')}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  ) : (
    <>
      {tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LEITOR && (
        <Button onClick={alterarStateFormulario}>
          {t('pressaoBiodiversidade.incluirOcorrencia')}
        </Button>
      )}
    </>
  )
}

Residuos.propTypes = {
  unidadeSelecionada: PropTypes.object,
  anoSelecionado: PropTypes.string,
  setChangeTab: PropTypes.func,
}

export default Residuos
