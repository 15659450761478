import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchFontesEnergiaActionTypes = apiActionTypes('fontesEnergia/API_LISTAR_FONTES_ENERGIA')
const createFontesEnergiaActionTypes = apiActionTypes('fontesEnergia/API_CRIAR_FONTES_ENERGIA')
const deleteFontesEnergiaActionTypes = apiActionTypes('fontesEnergia/API_EXCLUIR_FONTES_ENERGIA')
const updateFontesEnergiaActionTypes = apiActionTypes('fontesEnergia/API_ATUALIZAR_FONTES_ENERGIA')

export const API_LISTAR_FONTES_ENERGIA = asApiTypesObject(fetchFontesEnergiaActionTypes)
export const API_CRIAR_FONTES_ENERGIA = asApiTypesObject(createFontesEnergiaActionTypes)
export const API_EXCLUIR_FONTES_ENERGIA = asApiTypesObject(deleteFontesEnergiaActionTypes)
export const API_ATUALIZAR_FONTES_ENERGIA = asApiTypesObject(updateFontesEnergiaActionTypes)

export const apiListarFontesEnergia = ({ paisId }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/fontesEnergia`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchFontesEnergiaActionTypes,
}, { ...meta, query: { paisId } })

export const apiCriarFontesEnergia = (fontesEnergia, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/fontesEnergia`,
  method: 'POST',
  types: createFontesEnergiaActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(fontesEnergia),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiExcluirFontesEnergia = ({ id }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/fontesEnergia/${id}`,
  method: 'DELETE',
  types: deleteFontesEnergiaActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })

export const apiAtualizarFontesEnergia = ({ id, fonteEnergia }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/fontesEnergia/${id}`,
  method: 'PUT',
  types: updateFontesEnergiaActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(fonteEnergia),
}, { ...meta, successNotification: 'mensagens.atualizado' })
