import React from 'react'
import PropTypes from 'prop-types'
import { Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    minHeight: 48,
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(6),
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 32,
    flexShrink: 0,
    color: theme.palette.common.white,
  },
}))

const PageTitle = ({ title, children }) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.title} variant="h1">{title}</Typography>
      {children && children}
    </div>
  )
}

PageTitle.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
}

export default PageTitle
