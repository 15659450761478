import React, { useState } from 'react'
import {
  Grid,
  Typography,
  Paper,
  InputAdornment,
  IconButton,
  makeStyles,
  withStyles,
} from '@material-ui/core'
import { buildYupLocale } from 'i18n/validations'
import * as yup from 'yup'
import { validateFormValues } from 'utils/forms'
import { FieldText } from 'components/Fields'
import { Form } from 'react-final-form'
import { push } from 'connected-react-router'
import { callUserAuthenticate } from 'store/modules/auth'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import EyeLineIcon from 'remixicon-react/EyeLineIcon'
import EyeOffLineIcon from 'remixicon-react/EyeOffLineIcon'
import background from 'assets/images/bg-instituto-life.png'
import logo from 'assets/images/login/Logo_LK_login.svg'
import Button from 'components/Button'

const StyledPaper = withStyles((theme) => ({
  root: {
    padding: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
}))(Paper)

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  login: {
    color: theme.palette.primary.main,
    fontFamily: 'Poppins',
    fontWeight: '400',
    marginTop: 'clamp(64px, 75px, 86px)',
    fontSize: '2rem',
  },
  loginTitle: {
    color: theme.palette.primary.main,
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    marginTop: '8px',
    marginBottom: 'clamp(25px, 28px, 32px)',
  },
  image: {
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  slogan: {
    color: theme.palette.primary.main,
    fontFamily: 'Poppins',
    marginTop: '16px',
  },
  form: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  langContainer: {
    position: 'absolute',
    bottom: 0,
  },
  submitContainer: {
    marginBottom: '80px',
    marginTop: '20px',
  },
  input: {
    marginTop: '16px',
    marginBottom: '16px',
  },
  selectedLanguage: {
    color: `${theme.palette.buttons.default[2]} !important`,
    border: `1px solid ${theme.palette.buttons.default[2]} !important`,
    transition: 'all 0.3s',
  },
}))

const LoginPage = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const currentLanguage = i18n?.language
  const [showPassword, setShowPassword] = useState(false)

  const initialValue = {
    email: '',
    senha: '',
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleOnSubmit = (values) => {
    dispatch(callUserAuthenticate(values))
  }

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={12} sm={8} md={5} lg={4} component={StyledPaper} elevation={6} square>
        <Grid container direction="column" alignItems="center" justifyContent="center">
          <Grid xs={12} item style={{ textAlign: 'center' }}>
            <img src={logo} alt="Logo LIFE Key" style={{ maxHeight: '140px' }} />
            <Typography component="h1" variant="h5" className={classes.slogan}>
              {t('app.slogan')}
            </Typography>
          </Grid>
        </Grid>
        <Form
          onSubmit={handleOnSubmit}
          validate={validateFormValues(yup.object({
            email: yup.string().email().required(),
            senha: yup.string().required(),
          }))}
          initialValues={initialValue}
          render={({ handleSubmit, valid }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <Typography component="h2" variant="h3" className={classes.login}>
                {t('login.login')}
              </Typography>
              <Typography component="p" variant="subtitle1" className={classes.loginTitle}>
                {t('login.titulo')}
              </Typography>
              <FieldText
                required
                name="email"
                type="email"
                label={t('inputs.email')}
                autoFocus
                variant="outlined"
                className={classes.input}
              />
              <FieldText
                required
                variant="outlined"
                name="senha"
                type={showPassword ? 'text' : 'password'}
                label={t('inputs.senha')}
                className={classes.input}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={(event) => event.preventDefault()}
                      >
                        {showPassword ? <EyeLineIcon /> : <EyeOffLineIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Grid container justifyContent="space-between" alignItems="center" className={classes.submitContainer}>
                <Grid item>
                  <Button
                    type="submit"
                    variant="outlined"
                    disabled={!valid}
                  >
                    {t('login.entrar')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => dispatch(push('/esqueciSenha'))}
                    variant="transparent"
                  >
                    {t('login.esqueceuSenha')}
                  </Button>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-evenly"
                alignItems="center"
                className={classes.langContainer}
              >
                <Button
                  variant="outlined"
                  className={currentLanguage === 'pt-BR' ? classes.selectedLanguage : ''}
                  onClick={() => {
                    i18n.changeLanguage('pt-BR')
                    buildYupLocale(null, t)
                  }}
                >
                  {t('idioma.portuguesSigla')}
                </Button>
                <Button
                  variant="outlined"
                  className={currentLanguage === 'en-US' ? classes.selectedLanguage : ''}
                  onClick={() => {
                    i18n.changeLanguage('en-US')
                    buildYupLocale(null, t)
                  }}
                >
                  {t('idioma.inglesSigla')}
                </Button>
                <Button
                  variant="outlined"
                  className={currentLanguage === 'es' ? classes.selectedLanguage : ''}
                  onClick={() => {
                    i18n.changeLanguage('es')
                    buildYupLocale(null, t)
                  }}
                >
                  {t('idioma.espanholSigla')}
                </Button>
              </Grid>
            </form>
          )}
        />
      </Grid>
      <Grid item xs={false} sm={4} md={7} lg={8} className={classes.image} />
    </Grid>
  )
}

export default LoginPage
