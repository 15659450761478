import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 112,
    borderRadius: 8,
    display: 'flex',
    boxSizing: 'border-box',
    padding: theme.spacing(2),
    margin: theme.spacing(6, 0),
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey.FA,
    lineHeight: theme.typography.pxToRem(16),
    boxShadow: `0 8px 32px ${theme.palette.shadows.main}`,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  description: {
    margin: 0,
    fontSize: theme.typography.pxToRem(14),
  },
  percentage: {
    width: 100,
    flexShrink: 0,
    textAlign: 'center',
    marginLeft: theme.spacing(2),
    fontSize: theme.typography.pxToRem(32),
    lineHeight: theme.typography.pxToRem(40),
    color: (props) => theme.palette.status[props.percentageColor][1],
  },
  smallText: {
    marginTop: 'auto',
    fontSize: theme.typography.pxToRem(10),
  },
}))

const CardStatistics = ({ description, percentage, smallText }) => {
  const percentageColor = useMemo(() => {
    if (percentage < 40) return 'red'
    if (percentage < 70) return 'yellow'
    return 'green'
  }, [percentage])
  const classes = useStyles({ percentageColor })

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <p className={classes.description}>{description}</p>
        <div className={classes.smallText}>{smallText}</div>
      </div>
      <div className={classes.percentage}>
        {Number.isNaN(percentage) ? 0 : percentage}%
      </div>
    </div>
  )
}

CardStatistics.propTypes = {
  description: PropTypes.string,
  percentage: PropTypes.number,
  smallText: PropTypes.string,
}

export default CardStatistics
