import React from 'react'
import { makeStyles, Tab as MuiTab } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  tab: {
    minWidth: 'unset',
    padding: '12px 12px 20px',
    marginRight: theme.spacing(4),
    color: theme.palette.grey.blue,
  },
  tabActive: {
    color: theme.palette.blue.main,
  },
}))

const Tab = (props) => {
  const classes = useStyles()

  return (
    <MuiTab {...props} className={classes.tab} classes={{ selected: classes.tabActive }} />
  )
}

export default Tab
