import React, { useState, useEffect } from 'react'
import {
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { Form, FormSpy } from 'react-final-form'
import { FieldSelect, FieldText, FieldUpload, FieldAutocomplete } from 'components/Fields'
import * as yup from 'yup'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { get, find, filter } from 'lodash'
import { chain, bignumber } from 'mathjs'
import { getConsumoAguaSelecionado, getIsSavingConsumoAgua } from 'store/modules/consumoAgua/consumoAgua.selectors'
import { callAtualizarConsumoAgua, callCriarConsumoAgua, callNovoConsumoAgua } from 'store/modules/consumoAgua'
import { getRegioesHidrograficas } from 'store/modules/regioesHidrograficas/regioesHidrograficas.selectors'
import { getConversoesVolume } from 'store/modules/conversoesVolume/conversoesVolume.selectors'
import { getConversoesTempo } from 'store/modules/conversoesTempo/conversoesTempo.selectors'
import { getConversoesArea } from 'store/modules/conversoesArea/conversoesArea.selectors'
import { getTipoAgua } from 'store/modules/tipoAgua/tipoAgua.selectors'
import { getEstados } from 'store/modules/estados/estados.selectors'
import { getMunicipios } from 'store/modules/municipios/municipios.selectors'
import { getEspecieSilvicultura } from 'store/modules/especieSilvicultura/especieSilvicultura.selectors'
import { getEmpresaUsuario, getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { useTranslation } from 'react-i18next'
import ArrowRightCircleLineIcon from 'remixicon-react/ArrowRightCircleLineIcon'
import Button from 'components/Button'
import { validateFormValues } from 'utils/forms'
import Accordion from 'components/Accordion'
import { TIPO_USUARIO } from 'utils/constantes'

const useStyles = makeStyles((theme) => ({
  linkEcorregioes: {
    color: 'rgba(0, 0, 0, 0.54)',
    letterSpacing: '1.25px',
    margin: '15px',
    lineHeight: '16px',
    fontSize: '14px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: theme.palette.primary.main,
  },
  accordionRoot: {
    backgroundColor: '#8997B1',
    color: 'white',
    height: '43px',
    minHeight: '43px',
  },
  content: {
    flexGrow: 0,
  },
  expandIcon: {
    order: -1,
    marginRight: '0',
    color: 'white',
  },
  accordionExpanded: {
    minHeight: 'unset !important',
  },
  submit: {
    margin: theme.spacing(12, 1.5, 2),
  },
}))

const ConsumoAgua = ({ unidadeSelecionada, anoSelecionado, setChangeTab }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const [exibirFormulario, setExibirFormulario] = useState(false)

  const { pais } = useSelector(getEmpresaUsuario)
  const consumoAguaSelecionado = useSelector(getConsumoAguaSelecionado)
  const listaRegioesHidrograficas = useSelector(getRegioesHidrograficas)
  const listaConversoesVolume = useSelector(getConversoesVolume)
  const listaConversoesTempo = useSelector(getConversoesTempo)
  const listaConversoesArea = useSelector(getConversoesArea)
  const listaTipoAgua = useSelector(getTipoAgua)
  const listaEstados = useSelector(getEstados)
  const listaMunicipios = useSelector(getMunicipios)
  const listaEspeciesSilvicultura = useSelector(getEspecieSilvicultura)
  const isSaving = useSelector(getIsSavingConsumoAgua)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const regHidroOrdered = listaRegioesHidrograficas.sort((a, b) => a.nome.localeCompare(b.nome))

  const calcularQuantidade = ({
    quantidade,
    conversaoVolumeId,
    conversaoTempoId,
  }) => {
    let quantidadeCalculada = null
    if (conversaoVolumeId && conversaoTempoId && quantidade) {
      const conversaoVolume = find(listaConversoesVolume, { id: conversaoVolumeId })
      const conversaoTempo = find(listaConversoesTempo, { id: conversaoTempoId })

      quantidadeCalculada = chain(bignumber(quantidade))
        .multiply(bignumber(conversaoVolume.fator))
        .multiply(bignumber(conversaoTempo.fator))
        .number()
        .format({ notation: 'fixed', precision: 5 })
        .done()

      if (Number(quantidadeCalculada) === consumoAguaSelecionado?.quantidadeCalculada) {
        return consumoAguaSelecionado.quantidadeCalculada
      }
    }

    return quantidadeCalculada
  }

  const calcularQuantidadeSilvicultura = ({
    conversaoAreaId,
    quantidadeSilvicultura,
    municipioId,
    especieSilviculturaId,
  }) => {
    let quantidadeCalculada = null
    if (conversaoAreaId && quantidadeSilvicultura && municipioId && especieSilviculturaId) {
      const conversaoArea = find(listaConversoesArea, { id: conversaoAreaId })
      const municipio = find(listaMunicipios, { id: municipioId })
      const especie = find(listaEspeciesSilvicultura, { id: especieSilviculturaId })

      quantidadeCalculada = chain(bignumber(quantidadeSilvicultura))
        .multiply(bignumber(conversaoArea.fator))
        .multiply(bignumber(municipio.estacaoProxima.precipitacao))
        .multiply(bignumber(especie.fator))
        .multiply(bignumber(10))
        .number()
        .done()

      if (Number(quantidadeCalculada) === consumoAguaSelecionado?.quantidadeCalculadaSilvicultura) {
        return consumoAguaSelecionado.quantidadeCalculadaSilvicultura
      }
    }

    return quantidadeCalculada
  }

  const alterarStateFormulario = () => {
    setExibirFormulario(!exibirFormulario)
    dispatch(callNovoConsumoAgua())
  }

  const handleSave = (values, form) => {
    const data = {
      ...values,
      unidadeId: get(unidadeSelecionada, 'id'),
      ano: anoSelecionado,
      empresaId: get(unidadeSelecionada, 'empresaId'),
    }

    if (!consumoAguaSelecionado && callCriarConsumoAgua) {
      dispatch(callCriarConsumoAgua(data))
    } else if (callAtualizarConsumoAgua) {
      dispatch(callAtualizarConsumoAgua({ id: consumoAguaSelecionado.id, consumoAgua: data }))
    }
    form.reset()
    alterarStateFormulario()
  }

  useEffect(() => {
    if (consumoAguaSelecionado?.id) {
      setExibirFormulario(true)
    }
  }, [consumoAguaSelecionado])

  useEffect(() => {
    setExibirFormulario(false)
  }, [unidadeSelecionada])

  const handleClickMapa = (link) => {
    window.open(link, '_blank')
  }

  const handleValueChange = (form, { conversaoVolumeId, conversaoTempoId, quantidade }) => {
    form.change('quantidadeCalculada', calcularQuantidade({ conversaoVolumeId, conversaoTempoId, quantidade }))
  }

  const handleValueChangeSilvicultura = (form, {
    conversaoAreaId, quantidadeSilvicultura, municipioId, especieSilviculturaId,
  }) => {
    form.change('quantidadeCalculadaSilvicultura', calcularQuantidadeSilvicultura({ conversaoAreaId, quantidadeSilvicultura, municipioId, especieSilviculturaId }))
  }

  const checkIsValid = (value) => value === 'silvicultura'
  const [isOpenSilvicultura, setIsOpenSilvicultura] = useState(false)
  const permission = !unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR
  const isDisabledInput = isOpenSilvicultura || permission

  return exibirFormulario ? (
    <Form
      onSubmit={handleSave}
      initialValues={{
        descricao: consumoAguaSelecionado?.descricao ?? '',
        regiaoHidrograficaId: consumoAguaSelecionado?.regiaoHidrograficaId ?? null,
        conversaoVolumeId: consumoAguaSelecionado?.conversaoVolumeId ?? null,
        conversaoTempoId: consumoAguaSelecionado?.conversaoTempoId ?? null,
        tipoAguaId: consumoAguaSelecionado?.tipoAguaId ?? null,
        quantidade: consumoAguaSelecionado?.quantidade ?? null,
        evidenciaOcorrencia: consumoAguaSelecionado?.evidenciaOcorrencia ?? '',
        comentario: consumoAguaSelecionado?.comentario ?? '',
        quantidadeCalculada: consumoAguaSelecionado?.quantidadeCalculada ?? null,
        silvicultura: consumoAguaSelecionado?.silvicultura ?? false,
        ufId: consumoAguaSelecionado?.ufId ?? null,
        municipioId: consumoAguaSelecionado?.municipioId ?? null,
        especieSilviculturaId: consumoAguaSelecionado?.especieSilviculturaId ?? null,
        latitude: consumoAguaSelecionado?.latitude ?? null,
        longitude: consumoAguaSelecionado?.longitude ?? null,
        conversaoAreaId: consumoAguaSelecionado?.conversaoAreaId ?? null,
        descricaoSilvicultura: consumoAguaSelecionado?.descricaoSilvicultura ?? '',
        tipoAguaSilviculturaId: consumoAguaSelecionado?.tipoAguaSilviculturaId ?? null,
        regiaoHidrograficaSilviculturaId:
          consumoAguaSelecionado?.regiaoHidrograficaSilviculturaId ?? null,
        quantidadeSilvicultura: consumoAguaSelecionado?.quantidadeSilvicultura ?? null,
        quantidadeCalculadaSilvicultura:
          consumoAguaSelecionado?.quantidadeCalculadaSilvicultura ?? null,
        comentarioSilvicultura: consumoAguaSelecionado?.comentarioSilvicultura ?? null,
        evidenciaOcorrenciaSilvicultura:
          consumoAguaSelecionado?.evidenciaOcorrenciaSilvicultura ?? null,
        anexo: consumoAguaSelecionado?.anexo ?? null,
        anexoSilvicultura: consumoAguaSelecionado?.anexoSilvicultura ?? null,
      }}
      validate={validateFormValues(yup.object({
        // FIRST FORM
        descricao: yup.string().when(
          'silvicultura',
          {
            is: (val) => !val,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.nullable(),
          },
        ),
        regiaoHidrograficaId: yup.number().when(
          'silvicultura',
          {
            is: (val) => !val,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.nullable(),
          },
        ),
        conversaoVolumeId: yup.number().when(
          'silvicultura',
          {
            is: (val) => !val,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.nullable(),
          },
        ),
        conversaoTempoId: yup.number().when(
          'silvicultura',
          {
            is: (val) => !val,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.nullable(),
          },
        ),
        tipoAguaId: yup.number().when(
          'silvicultura',
          {
            is: (val) => !val,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.nullable(),
          },
        ),
        quantidade: yup.number().positive().when(
          'silvicultura',
          {
            is: (val) => !val,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.nullable(),
          },
        ),
        evidenciaOcorrencia: yup.string(),
        comentario: yup.string(),
        // SECOND FORM
        silvicultura: yup.boolean(),
        descricaoSilvicultura: yup.string().when(
          'silvicultura',
          {
            is: (val) => val,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.nullable(),
          },
        ),
        regiaoHidrograficaSilviculturaId: yup.number().when(
          'silvicultura',
          {
            is: (val) => val,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.nullable(),
          },
        ),
        especieSilviculturaId: yup.number().when(
          'silvicultura',
          {
            is: (val) => val,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.nullable(),
          },
        ),
        quantidadeSilvicultura: yup.number().positive().when(
          'silvicultura',
          {
            is: (val) => val,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.nullable(),
          },
        ),
        conversaoAreaId: yup.number().when(
          'silvicultura',
          {
            is: (val) => val,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.nullable(),
          },
        ),
        ufId: yup.number().when(
          'silvicultura',
          {
            is: (val) => val,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.nullable(),
          },
        ),
        municipioId: yup.number().when(
          'silvicultura',
          {
            is: (val) => val,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.nullable(),
          },
        ),
      }))}
      render={({ handleSubmit, submitting, values, form, pristine, valid }) => (
        <form onSubmit={handleSubmit}>
          <FormSpy
            onChange={({ values: valuesSpy, initialValues }) => {
              setChangeTab({
                values: valuesSpy,
                initialValues,
              })
            }}
            subscription={{ values: true, initialValues: true, pristine: true }}
          />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FieldText
                disabled={isDisabledInput}
                name="descricao"
                label={`${t('inputs.descricao')} *`}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldSelect
                id="tipoAguaId"
                name="tipoAguaId"
                label={`${t('inputs.tipoAgua')} *`}
                disabled={isDisabledInput}
                options={(listaTipoAgua || []).map((option) => ({
                  label: option.tipo,
                  value: option.id,
                }))}
                onChange={handleValueChange(form, values)}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldAutocomplete
                name="regiaoHidrograficaId"
                disabled={isDisabledInput}
                label={`${t('inputs.regiaoHidrografica')} *`}
                options={regHidroOrdered.map((option) => ({
                  value: option.id,
                  label: option.nome,
                }))}
              />
            </Grid>
            {pais.linkRegioesHidrograficas && (
              <Grid item xs={12}>
                <Button
                  link
                  startIcon={ArrowRightCircleLineIcon}
                  // disabled={!values.regiaoHidrograficaId || !find(listaRegioesHidrograficas, {
                  //   id: values.regiaoHidrograficaId,
                  // }).link}
                  // onClick={() => handleClickMapa(find(listaRegioesHidrograficas, {
                  //   id: values.regiaoHidrograficaId,
                  // }).link)}
                  onClick={() => handleClickMapa(pais.linkRegioesHidrograficas)}
                >
                  {t('pressaoBiodiversidade.verMapaRegioesHidrograficas')}
                </Button>
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <FieldText
                type="number"
                name="quantidade"
                label={`${t('inputs.quantidade')} *`}
                disabled={isDisabledInput}
                onChange={handleValueChange(form, values)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldSelect
                id="conversaoVolumeId"
                name="conversaoVolumeId"
                label={`${t('inputs.unidade')} *`}
                disabled={isDisabledInput}
                options={(listaConversoesVolume || []).map((option) => ({
                  label: t(option.unidade),
                  value: option.id,
                }))}
                onChange={handleValueChange(form, values)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldSelect
                id="conversaoTempoId"
                name="conversaoTempoId"
                label={`${t('inputs.periodo')} *`}
                disabled={isDisabledInput}
                options={(listaConversoesTempo || []).map((option) => ({
                  label: t(option.unidade),
                  value: option.id,
                }))}
                onChange={handleValueChange(form, values)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldText
                disabled
                type="number"
                name="quantidadeCalculada"
                label={t('inputs.quantidadeM3Ano')}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldText
                disabled={isDisabledInput}
                name="comentario"
                label={t('inputs.comentario')}
                multiline
                minRows={5}
                maxRows={5}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldText
                disabled={isDisabledInput}
                name="evidenciaOcorrencia"
                label={t('inputs.evidenciaOcorrencia')}
                multiline
                minRows={5}
                maxRows={5}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldUpload
                name="anexoSilvicultura"
                disabled={isDisabledInput}
              />
            </Grid>
            <Grid item xs={12}>
              <Accordion
                title={t('pressaoBiodiversidade.silviculturaTitulo')}
                expanded={get(values, 'silvicultura', false)}
                onExpand={() => {
                  setIsOpenSilvicultura(!isOpenSilvicultura)
                  form.change('silvicultura', !values.silvicultura)
                  if (!values.silvicultura) {
                    form.change('especieSilviculturaId', null)
                    form.change('ufId', null)
                    form.change('municipioId', null)
                    form.change('latitude', null)
                    form.change('longitude', null)
                    form.change('quantidadeCalculada', null)
                  }
                }}
                variant={get(values, 'silvicultura') ? 'no-borders' : 'border'}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FieldText
                      required={() => checkIsValid(values.silvicultura)}
                      name="descricaoSilvicultura"
                      label={t('inputs.descricao')}
                      disabled={!unidadeSelecionada ||
                        tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      {t('pressaoBiodiversidade.tipoAguaVerdeSilvicultura')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FieldAutocomplete
                      required={() => checkIsValid(values.silvicultura)}
                      name="regiaoHidrograficaSilviculturaId"
                      disabled={!unidadeSelecionada ||
                        tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                      label={t('inputs.regiaoHidrografica')}
                      options={regHidroOrdered.map((option) => ({
                        value: option.id,
                        label: option.nome,
                      }))}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      link
                      disabled={!values.regiaoHidrograficaId || !find(listaRegioesHidrograficas, {
                        id: values.regiaoHidrograficaId,
                      }).link}
                      startIcon={ArrowRightCircleLineIcon}
                      onClick={() => handleClickMapa(find(listaRegioesHidrograficas, {
                        id: values.regiaoHidrograficaId,
                      }).link)}
                    >
                      {t('pressaoBiodiversidade.verMapaRegioesHidrograficas')}
                    </Button>
                  </Grid>

                  <Grid item xs={12}>
                    <FieldSelect
                      required={() => checkIsValid(values.silvicultura)}
                      id="especieSilviculturaId"
                      name="especieSilviculturaId"
                      label={t('inputs.especie')}
                      disabled={!unidadeSelecionada ||
                        tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                      options={(listaEspeciesSilvicultura || []).map((option) => ({
                        label: option.especie,
                        value: option.id,
                      }))}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FieldText
                      required={() => checkIsValid(values.silvicultura)}
                      type="number"
                      disabled={!unidadeSelecionada ||
                        tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                      name="quantidadeSilvicultura"
                      label={t('inputs.quantidade')}
                      onChange={handleValueChangeSilvicultura(form, values)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FieldSelect
                      required={() => checkIsValid(values.silvicultura)}
                      id="conversaoAreaId"
                      name="conversaoAreaId"
                      label={t('inputs.unidadeArea')}
                      disabled={!unidadeSelecionada ||
                        tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                      options={(listaConversoesArea || []).map((option) => ({
                        label: t(option.unidade),
                        value: option.id,
                      }))}
                      onChange={handleValueChangeSilvicultura(form, values)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FieldAutocomplete
                      required={() => checkIsValid(values.silvicultura)}
                      name="ufId"
                      disabled={!unidadeSelecionada ||
                        tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                      label={t('inputs.estado')}
                      options={listaEstados.map((option) => ({
                        value: option.id,
                        label: option.nome,
                      }))}
                      onChange={() => {
                        form.change('municipioId', null)
                        form.change('latitude', null)
                        form.change('longitude', null)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FieldAutocomplete
                      required={() => checkIsValid(values.silvicultura)}
                      name="municipioId"
                      disabled={!unidadeSelecionada ||
                        tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                      label={t('inputs.municipio')}
                      options={filter(listaMunicipios, { ufId: values.ufId }).map((option) => ({
                        value: option.id,
                        label: option.nome,
                      }))}
                      onChange={(value) => {
                        const municipio = find(listaMunicipios, { id: value })
                        form.change('latitude', municipio.estacaoProxima.latitude)
                        form.change('longitude', municipio.estacaoProxima.longitude)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography component="h3" className={classes.title}>
                      {t('pressaoBiodiversidade.estacaoMeteorologica')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FieldText
                      disabled
                      name="latitude"
                      label={t('inputs.latitude')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FieldText
                      disabled
                      name="longitude"
                      label={t('inputs.longitude')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FieldText
                      disabled
                      type="number"
                      label={t('inputs.quantidadeM3Ano')}
                      name="quantidadeCalculadaSilvicultura"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FieldText
                      disabled={!unidadeSelecionada ||
                        tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                      name="comentarioSilvicultura"
                      label={t('inputs.comentario')}
                      multiline
                      minRows={5}
                      maxRows={5}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FieldText
                      disabled={!unidadeSelecionada ||
                        tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                      name="evidenciaOcorrenciaSilvicultura"
                      label={t('inputs.evidenciaOcorrencia')}
                      multiline
                      minRows={5}
                      maxRows={5}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FieldUpload
                      name="anexo"
                      disabled={!unidadeSelecionada ||
                        tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                    />
                  </Grid>
                </Grid>
              </Accordion>
            </Grid>

            <Grid container direction="row" justifyContent="space-between" className={classes.submit}>
              <Button variant="danger" onClick={alterarStateFormulario}>
                {t('inputs.cancelar')}
              </Button>
              <Button
                type="submit"
                disabled={submitting || !unidadeSelecionada || pristine || !valid}
                loading={isSaving}
              >
                {t('inputs.salvar')}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  ) : (
    <>
      {tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LEITOR && (
        <Button onClick={alterarStateFormulario}>
          {t('pressaoBiodiversidade.incluirOcorrencia')}
        </Button>
      )}
    </>
  )
}

ConsumoAgua.propTypes = {
  unidadeSelecionada: PropTypes.object,
  anoSelecionado: PropTypes.string,
  setChangeTab: PropTypes.func,
}

export default ConsumoAgua
