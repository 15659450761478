import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchBiomasActionTypes = apiActionTypes('biomas/API_LISTAR_BIOMAS')
const createBiomasActionTypes = apiActionTypes('biomas/API_CRIAR_BIOMAS')
const deleteBiomasActionTypes = apiActionTypes('biomas/API_EXCLUIR_BIOMAS')
const updateBiomasActionTypes = apiActionTypes('biomas/API_ATUALIZAR_BIOMAS')

export const API_LISTAR_BIOMAS = asApiTypesObject(fetchBiomasActionTypes)
export const API_CRIAR_CLASSES_BIOMAS = asApiTypesObject(createBiomasActionTypes)
export const API_EXCLUIR_CLASSES_BIOMAS = asApiTypesObject(deleteBiomasActionTypes)
export const API_ATUALIZAR_CLASSES_BIOMAS = asApiTypesObject(updateBiomasActionTypes)

export const apiListarBiomas = ({ paisId }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/biomas`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchBiomasActionTypes,
}, { ...meta, query: { paisId } })

export const apiCriarEcorregioes = (bioma, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/biomas`,
  method: 'POST',
  types: createBiomasActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(bioma),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiExcluirEcorregioes = ({ id }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/biomas/${id}`,
  method: 'DELETE',
  types: deleteBiomasActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })

export const apiAtualizarEcorregioes = ({ id, bioma }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/biomas/${id}`,
  method: 'PUT',
  types: updateBiomasActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(bioma),
}, { ...meta, successNotification: 'mensagens.atualizado' })
