import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchTiposGasesActionTypes = apiActionTypes('tiposGases/API_LISTAR_TIPOS_GASES')
const createTiposGasesActionTypes = apiActionTypes('tiposGases/API_CRIAR_TIPOS_GASES')
const deleteTiposGasesActionTypes = apiActionTypes('tiposGases/API_EXCLUIR_TIPOS_GASES')
const updateTiposGasesActionTypes = apiActionTypes('tiposGases/API_ATUALIZAR_TIPOS_GASES')

export const API_LISTAR_TIPOS_GASES = asApiTypesObject(fetchTiposGasesActionTypes)
export const API_CRIAR_TIPOS_GASES = asApiTypesObject(createTiposGasesActionTypes)
export const API_EXCLUIR_TIPOS_GASES = asApiTypesObject(deleteTiposGasesActionTypes)
export const API_ATUALIZAR_TIPOS_GASES = asApiTypesObject(updateTiposGasesActionTypes)

export const apiListarTiposGases = ({ paisId }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/gasesTipo`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchTiposGasesActionTypes,
}, { ...meta, query: { paisId } })

export const apiCriarTipoGas = (tiposGases, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/gasesTipo`,
  method: 'POST',
  types: createTiposGasesActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(tiposGases),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiExcluirTipoGas = ({ id }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/gasesTipo/${id}`,
  method: 'DELETE',
  types: deleteTiposGasesActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })

export const apiAtualizarTipoGas = ({ id, tiposGas }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/gasesTipo/${id}`,
  method: 'PUT',
  types: updateTiposGasesActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(tiposGas),
}, { ...meta, successNotification: 'mensagens.atualizado' })
