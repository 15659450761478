const ACTION_PATH = 'app/empresas'

export const CALL_CRIAR_EMPRESA = `${ACTION_PATH}/CALL_CRIAR_EMPRESA`
export const CALL_ATUALIZAR_EMPRESA = `${ACTION_PATH}/CALL_ATUALIZAR_EMPRESA`
export const CALL_FINALIZAR_CADASTRO_INICIAL = `${ACTION_PATH}/CALL_FINALIZAR_CADASTRO_INICIAL`
export const CALL_SELECIONAR_EMPRESA = `${ACTION_PATH}/CALL_SELECIONAR_EMPRESA`
export const CALL_EXCLUIR_EMPRESA = `${ACTION_PATH}/CALL_EXCLUIR_EMPRESA`
export const CALL_NOVA_EMPRESA = `${ACTION_PATH}/CALL_NOVA_EMPRESA`
export const CALL_SELECIONAR_EMPRESA_FILTRO = `${ACTION_PATH}/CALL_SELECIONAR_EMPRESA_FILTRO`

export const callCriarEmpresa = (empresa) => ({
  type: CALL_CRIAR_EMPRESA,
  payload: empresa,
})

export const callAtualizarEmpresa = (payload) => ({
  type: CALL_ATUALIZAR_EMPRESA,
  payload,
})

export const callFinalizarCadastroInicial = (id) => ({
  type: CALL_FINALIZAR_CADASTRO_INICIAL,
  payload: id,
})

export const callSelecionarEmpresa = ({ id }) => ({
  type: CALL_SELECIONAR_EMPRESA,
  payload: { id },
})

export const callExcluirEmpresa = ({ id }) => ({
  type: CALL_EXCLUIR_EMPRESA,
  payload: { id },
})

export const callNovaEmpresa = () => ({
  type: CALL_NOVA_EMPRESA,
})

export const callSelecionarEmpresaFiltro = (empresa) => ({
  type: CALL_SELECIONAR_EMPRESA_FILTRO,
  payload: empresa,
})
