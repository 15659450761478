export const getEmpresasAuditorias = (state) => state.auditor.empresasAuditorias
export const getEmpresaAuditoriaSelecionada = (state) => state.auditor.empresaSelecionada
export const getDadosAuditoriaSelecionada = (state) => state.auditor.dadosAuditoriaSelecionada
export const getAnoSelecionado = (state) => state.auditor.anoSelecionado

export const getIsSavingAuditoriaSelecionada =
  (state) => state.auditor.isSavingAuditoriaSelecionada

export const getParecerPressaoBiodiverdidadeSelecionado =
  (state) => state.auditor.parecerPressaoBiodiverdidadeSelecionado
export const getListaParecerPressaoBiodiverdidade =
  (state) => state.auditor.listaParecerPressaoBiodiverdidade
export const getIsSavingParecerPressaoBiodiverdidade =
  (state) => state.auditor.isSavingParecerPressaoBiodiverdidade

export const getParecerDesempenhoBiodiverdidadeSelecionado =
  (state) => state.auditor.parecerDesempenhoBiodiverdidadeSelecionado
export const getListaParecerDesempenhoBiodiverdidade =
  (state) => state.auditor.listaParecerDesempenhoBiodiverdidade
export const getIsSavingParecerDesempenhoBiodiverdidade =
  (state) => state.auditor.isSavingParecerDesempenhoBiodiverdidade

export const getParecerIndicadorGestaoSelecionado =
  (state) => state.auditor.parecerIndicadorGestaoSelecionado
export const getListaParecerIndicadorGestao =
  (state) => state.auditor.listaParecerIndicadorGestao
export const getIsSavingParecerIndicadorGestao =
  (state) => state.auditor.isSavingParecerIndicadorGestao

export const getListaUnidadesEmpresa = (state) => state.auditor.listaUnidadesEmpresa
export const getListaProjetosEmpresa = (state) => state.auditor.listaProjetosEmpresa
export const getListaPrincipiosEmpresa = (state) => state.auditor.listaPrincipiosEmpresa
