const ACTION_PATH = 'app/relatorios'

export const CALL_RELATORIO_AUDITORIA = `${ACTION_PATH}/CALL_RELATORIO_AUDITORIA`
export const CALL_RELATORIO_SUMARIO = `${ACTION_PATH}/CALL_RELATORIO_SUMARIO`

export const callRelatorioAuditoria = (payload) => ({
  type: CALL_RELATORIO_AUDITORIA,
  payload,
})

export const callRelatorioSumario = (payload) => ({
  type: CALL_RELATORIO_SUMARIO,
  payload,
})
