import { Grid, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import Accordion from 'components/Accordion'
import Button from 'components/Button'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import { FieldText } from 'components/Fields'
import Filters from 'components/FilterList/Filters'
import PropTypes from 'prop-types'
import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { callAtualizarClasseOcupacao, callCriarClasseOcupacao, callExcluirClasseOcupacao } from 'store/modules/classesOcupacao'
import { getClassesOcupacao, getIsSavingClassesOcupacao, getIsUpdatingClassesOcupacao } from 'store/modules/classesOcupacao/classesOcupacao.selectors'
import { validateFormValues } from 'utils/forms'
import * as yup from 'yup'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { TIPO_USUARIO } from 'utils/constantes'

const useStyles = makeStyles((theme) => ({
  rowText: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(5),
    fontSize: theme.typography.pxToRem(14),
  },
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    width: (props) => props.fullWidth && '100%',
  },
  hidden: {
    display: 'none',
  },
  formAdd: {
    transition: '0.20s ease-out',
    maxHeight: 0,
    overflow: 'hidden',
  },
  openFormAdd: {
    marginTop: '-10px',
    paddingTop: 10,
    maxHeight: '1300px',
    transition: '0.25s ease-in',
  },
  normalTop: {
    marginTop: '16px',
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '0 !important',
  },
  itemAlignRight: {
    maxWidth: '190px',
  },
  listItems: {
    marginTop: '32px',
    border: '1px solid #C9CEDB',
    boxShadow: '0px 8px 32px rgba(15, 52, 68, 0.08)',
    borderRadius: '8px',
    padding: '0px 16px',
  },
  itemTitle: {
    fontFamily: 'Poppins',
    color: '#0F3444',
    fontWeight: '600',
    fontSize: '16px',
  },
  divider: {
    height: 1,
    width: 'calc(100% + 32px)',
    marginLeft: '-16px',
    backgroundColor: theme.palette.grey.main,
  },
  dividerField: {
    borderBottom: `1px solid ${theme.palette.grey.main}`,
    margin: '8px 0',
  },
}))

const TabUsoSolo = forwardRef(({ paisId, controlAdded, setControlAdded }, ref) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const isSaving = useSelector(getIsSavingClassesOcupacao)
  const isUpdating = useSelector(getIsUpdatingClassesOcupacao)
  const filterScreen = [
    { value: true, label: 'filtros.nomeAZ', name: 'order' },
    { value: false, label: 'filtros.nomeZA', name: 'order' },
  ]
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)
  const dialog = useConfirmationDialog()
  const listaClassesOcupacao = useSelector(getClassesOcupacao)
  const [data, setData] = useState(listaClassesOcupacao)

  const desabilitarFormulario = Boolean(tipoUsuarioLogado === TIPO_USUARIO.ADMIN.OBSERVADOR)

  const validation = yup.object({
    cobertura: yup.string().required(),
    subCategoria: yup.string(),
    descricao: yup.string(),
    fator: yup.number().required(),
  })

  const handleUpdate = (values) => {
    const { id } = values
    const payload = ({ ...values, paisId })
    delete payload.id
    dispatch(callAtualizarClasseOcupacao({ id, classeOcupacao: payload }))
  }

  const handleSave = (values, form) => {
    const payload = ({ ...values, paisId })
    dispatch(callCriarClasseOcupacao(payload))
    form.reset()
    Object.keys(values).forEach((key) => {
      form.resetFieldState(key)
    })
  }

  const handleDelete = (id, classeOcupacao) => dialog.showConfirmation({
    title: t('dialogs.confirmarExclusao'),
    message: t('dialogs.excluirClasseOcupacao', {
      classeOcupacao,
    }),
  }).then((result) => {
    if (result) {
      dispatch(callExcluirClasseOcupacao({ id }))
    }
    return null
  })

  const [openItem, setOpenItem] = useState('')

  const handleExpand = (item) => {
    setOpenItem(openItem === item ? '' : item)
  }

  useImperativeHandle(
    ref,
    () => ({
      handleButtonAdded() {
        setControlAdded(true)
        handleExpand('')
      },
    }),
  )

  const clearFields = (form, values, type) => {
    if (type === 'add') {
      setControlAdded(!controlAdded)
    }

    form.reset()
    Object.keys(values).forEach((key) => {
      form.resetFieldState(key)
    })
  }

  const FieldsForm = () => (
    <Grid container>
      <Grid item container spacing={2}>
        <Grid item xs={9}>
          <FieldText
            label={t('inputs.UsoSolo')}
            name="subCategoria"
            disabled={desabilitarFormulario}
          />
        </Grid>
        <Grid item xs={3}>
          <FieldText
            required
            type="number"
            label={t('inputs.MSA')}
            name="fator"
            disabled={desabilitarFormulario}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.normalTop}>
        <FieldText
          required
          label={t('inputs.CoberturaArea')}
          name="cobertura"
          disabled={desabilitarFormulario}
        />
      </Grid>

      <Grid item xs={12} className={classes.normalTop}>
        <FieldText
          label={t('inputs.Descricao')}
          name="descricao"
          multiline
          minRows={5}
          maxRows={5}
          disabled={desabilitarFormulario}
        />
      </Grid>
    </Grid>
  )

  const ItemForm = (position) => {
    const itemClasseOcupacao = [data[position]]
    return (
      <Form
        onSubmit={handleUpdate}
        validate={validateFormValues(validation)}
        initialValues={{
          cobertura: itemClasseOcupacao[0]?.cobertura ?? '',
          subCategoria: itemClasseOcupacao[0]?.subCategoria ?? '',
          descricao: itemClasseOcupacao[0]?.descricao ?? '',
          fator: itemClasseOcupacao[0]?.fator ?? '',
          id: itemClasseOcupacao[0]?.id ?? '',
        }}
        render={({ handleSubmit, form, pristine, valid }) => (
          <form onSubmit={handleSubmit}>
            <Accordion
              hideButton
              variant="no-borders"
              classes={{ title: classes.itemTitle }}
              title={itemClasseOcupacao[0]?.subCategoria}
              onDelete={() => {
                handleDelete(
                  itemClasseOcupacao[0]?.id,
                  itemClasseOcupacao[0]?.cobertura,
                )
                handleExpand('')
              }}
              expanded={position === openItem}
              onExpand={() => handleExpand(position)}
              disableDelete={desabilitarFormulario}
            >
              <Grid container>
                { FieldsForm() }
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    className={classes.normalTop}
                  >
                    <Grid item className={classes.deleteButton}>
                      <Button
                        variant="danger"
                        onClick={() => { form.reset(); handleExpand('') }}
                      >
                        {t('inputs.cancelar')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        disabled={Boolean(pristine || !valid) || desabilitarFormulario}
                        loading={isUpdating}
                      >
                        {t('inputs.salvar')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Accordion>
            <div className={classes.divider} />
          </form>
        )}
      />
    )
  }

  useEffect(() => {
    setData(listaClassesOcupacao)
  }, [listaClassesOcupacao])

  return (
    <>
      <div className={clsx(classes.search, controlAdded && classes.hidden)}>
        <Filters
          fullWidth
          items={listaClassesOcupacao}
          setItems={setData}
          origin={{ vertical: 'top', horizontal: 'right' }}
          filterScreen={filterScreen}
          nameSearch="subCategoria"
        />
      </div>
      <div
        className={clsx(
          classes.formAdd,
          controlAdded && classes.openFormAdd,
        )}
      >
        <Form
          onSubmit={handleSave}
          initialValues={{
            cobertura: '',
            subCategoria: '',
            descricao: '',
            fator: '',
          }}
          validate={validateFormValues(validation)}
          render={({ handleSubmit, form, values, valid }) => (
            <form onSubmit={handleSubmit}>
              <Grid container>
                { FieldsForm() }
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    className={classes.normalTop}
                  >
                    <Grid item className={classes.deleteButton}>
                      <Button
                        variant="danger"
                        onClick={(e) => {
                          e.preventDefault()
                          clearFields(form, values, 'add')
                        }}
                      >
                        {t('inputs.cancelar')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        disabled={!valid || desabilitarFormulario}
                        loading={isSaving}
                      >
                        {t('inputs.salvar')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </div>
      <div className={classes.listItems}>
        {
          data.length > 0 &&
          data.map((elem, index) => (
            <Fragment key={index}>
              {ItemForm(index)}
            </Fragment>
          ))
        }
      </div>
    </>
  )
})

TabUsoSolo.propTypes = {
  paisId: PropTypes.number,
  controlAdded: PropTypes.bool,
  setControlAdded: PropTypes.func,
}

export default TabUsoSolo
