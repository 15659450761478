import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchSetoresActionTypes = apiActionTypes('setors/API_LISTAR_SETORES')

export const API_LISTAR_SETORES = asApiTypesObject(fetchSetoresActionTypes)

export const apiListarSetores = (meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/setores`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchSetoresActionTypes,
}, { ...meta })
