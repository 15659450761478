import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import * as yup from 'yup'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import Button from 'components/Button'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-final-form'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import Filters from 'components/FilterList/Filters'
import { validateFormValues } from 'utils/forms'
import { useSelector, useDispatch } from 'react-redux'
import { FieldAutocomplete, FieldText, FieldSelect } from 'components/Fields'
import EditLineIcon from 'remixicon-react/EditLineIcon'
import DeleteLineIcon from 'remixicon-react/DeleteBin7LineIcon'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { TIPO_USUARIO } from 'utils/constantes'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import { getTipoUsuariosAuditor } from 'store/modules/tipoUsuarios/tipoUsuarios.selectors'
import { getEmpresas } from 'store/modules/empresas/empresas.selectors'
import { getUsuarioAuditorSelecionado, getUsuariosAuditor } from 'store/modules/usuariosAuditor/usuariosAuditor.selectors'
import {
  callCriarUsuarioAuditor,
  callAtualizarUsuarioAuditor,
  callSelecionarUsuarioAuditor,
  callNovoUsuarioAuditor,
  callExcluirUsuarioAuditor,
} from 'store/modules/usuariosAuditor'
import Pagination from 'components/Pagination'

const useStyles = makeStyles((theme) => ({
  containerBox: {
    padding: '0 15px',
    marginTop: '32px',
  },
  sectionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  screenTitle: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(5),
    fontSize: theme.typography.pxToRem(24),
  },
  spaceBottom: {
    marginBottom: '18px',
  },
  spaceTop: {
    marginTop: '72px',
  },
  rowText: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(5),
    fontSize: theme.typography.pxToRem(14),
  },
  tableActionsIcon: {
    width: 16,
    marginLeft: 6,
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      color: theme.palette.blue.main,
    },
  },
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '49%',
  },
  hidden: {
    display: 'none',
  },
  formAdd: {
    transition: '0.20s ease-out',
    maxHeight: 0,
    overflow: 'hidden',
  },
  openFormAdd: {
    marginTop: '-10px',
    paddingTop: 10,
    maxHeight: '1300px',
    transition: '0.25s ease-in',
  },
  normalTop: {
    marginTop: '16px',
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '0 !important',
  },
  itemAlignRight: {
    maxWidth: '190px',
  },
  listItems: {
    marginTop: '32px',
    border: '1px solid #C9CEDB',
    boxShadow: '0px 8px 32px rgba(15, 52, 68, 0.08)',
    borderRadius: '8px',
    padding: '0px 16px',
  },
  itemTitle: {
    fontFamily: 'Poppins',
    color: '#0F3444',
    fontWeight: '600',
    fontSize: '16px',
  },
  divider: {
    height: 1,
    width: 'calc(100% + 32px)',
    marginLeft: '-16px',
    backgroundColor: theme.palette.grey.main,
  },
  dividerField: {
    borderBottom: `1px solid ${theme.palette.grey.main}`,
    margin: '8px 0',
  },
  tableBody: {
    marginTop: '72px',
    padding: theme.spacing(2, 3),
  },
  pagination: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableHeadCell: {
    padding: 0,
    fontSize: 14,
    color: theme.palette.grey.blue,
    paddingBottom: theme.spacing(3),
    fontWeight: theme.typography.fontWeightRegular,
  },
  tableBodyRow: {
    fontSize: 12,
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.grey.main}`,
    },
  },
  tableBodyCell: {
    padding: theme.spacing(1, 0),
  },
  containerItemEmpresa: {
    display: 'flex',
    gap: '8px',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
  },
  itemEmpresa: {
    backgroundColor: '#D7DCE9',
    color: '#0F3444',
    padding: '4px 8px',
    fontSize: '12px',
    borderRadius: '32px',
  },
}))

const ListaAuditores = forwardRef(({ controlAdded, setControlAdded }, ref) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const dialog = useConfirmationDialog()

  const usuarios = useSelector(getUsuariosAuditor)
  const usuarioSelecionado = useSelector(getUsuarioAuditorSelecionado)
  const tiposUsuario = useSelector(getTipoUsuariosAuditor)
  const empresas = useSelector(getEmpresas)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const [page, setPage] = useState(0)
  const [data, setData] = useState([])

  const pageSize = 8

  const validationSchema = yup.object({
    nome: yup.string().required(),
    email: yup.string().required(),
    tipoUsuarioId: yup.string().required(),
  })

  const filterScreen = [
    { value: true, label: 'filtros.nomeAZ', name: 'order' },
    { value: false, label: 'filtros.nomeZA', name: 'order' },
  ]

  useImperativeHandle(
    ref,
    () => ({
      handleButtonAdded() {
        setControlAdded(true)
        dispatch(callNovoUsuarioAuditor())
      },
    }),
  )

  const handleSave = (values, form) => {
    dispatch(usuarioSelecionado ?
      callAtualizarUsuarioAuditor({ usuario: values, id: usuarioSelecionado.id }) :
      callCriarUsuarioAuditor(values))

    form.reset()
    Object.keys(values).forEach((key) => {
      form.resetFieldState(key)
    })
    setControlAdded(false)
  }

  const tableColumns = [
    { title: t('auditoresAdm.tabelaNome'), key: 'nome', width: '19.90%' },
    { title: t('auditoresAdm.tabelaEmail'), key: 'email', width: '24.70%' },
    { title: t('auditoresAdm.tabelaPermissao'), key: 'tipoUsuario.tipo', width: '20%' },
    { title: t('auditoresAdm.tabelaEmpresasParaAuditar'), key: 'empresasAuditor', width: '20.70%' },
    ...tipoUsuarioLogado !== TIPO_USUARIO.ADMIN.OBSERVADOR ? [{ title: t('inputs.acoes'), key: 'actions', width: '4%' }] : [],
  ]

  const handleDelete = (id, nome) => dialog.showConfirmation({
    title: t('dialogs.confirmarExclusao'),
    message: t('dialogs.excluirAuditor', { auditor: nome }),
  }).then((result) => {
    if (result) {
      if (id) {
        dispatch(callExcluirUsuarioAuditor({ id }))
        if (data?.length === 9) {
          setPage(0)
        }
      }
    }
  })

  const handleEdit = ({ id }) => {
    setControlAdded(true)
    dispatch(callSelecionarUsuarioAuditor({ id }))
  }

  const filteredData = data.slice(page * pageSize, page * pageSize + pageSize)
    .map((item) => ({
      ...item,
      actions: (
        <div className={classes.tableActions}>
          <EditLineIcon onClick={() => handleEdit(item)} className={classes.tableActionsIcon} />
          <DeleteLineIcon
            onClick={() => handleDelete(item.id, item.nome)}
            className={classes.tableActionsIcon}
          />
        </div>
      ),
    }))

  const fieldsForm = () => (
    <>
      <Grid item xs={12} sm={6}>
        <FieldText
          required
          label={t('inputs.nomeAuditor')}
          name="nome"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FieldText
          required
          label={t('inputs.emailAuditor')}
          name="email"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FieldSelect
          required
          options={(tiposUsuario || []).map((tipoUsuario) => ({
            label: t(tipoUsuario.tipo),
            value: tipoUsuario.id,
          }))}
          name="tipoUsuarioId"
          label={t('inputs.permissaoUsuario')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FieldAutocomplete
          multiple
          options={empresas}
          label={t('inputs.empresasAuditar')}
          name="empresasAuditor"
          getOptionLabel={(option) => option.nome || ''}
          getOptionSelected={(option, value) => option.id === value.id}
        />
      </Grid>
    </>
  )

  useEffect(() => {
    setData(usuarios)
  }, [usuarios])

  return (
    <div className={classes.containerBox}>
      <div
        className={clsx(
          classes.formAdd,
          controlAdded && classes.openFormAdd,
        )}
      >
        <Typography className={clsx(classes.screenTitle, classes.spaceBottom)}>
          {t('auditoresAdm.dadosAuditor')}
        </Typography>
        <Form
          onSubmit={handleSave}
          validate={validateFormValues(validationSchema)}
          initialValues={{
            nome: usuarioSelecionado?.nome ?? '',
            email: usuarioSelecionado?.email ?? '',
            tipoUsuarioId: usuarioSelecionado?.tipoUsuarioId ?? null,
            empresasAuditor: usuarioSelecionado?.empresasAuditor ?? null,
          }}
          render={({ handleSubmit, form, values, pristine, valid }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                {fieldsForm()}
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                  >
                    <Grid item className={classes.deleteButton}>
                      <Button
                        variant="danger"
                        type="button"
                        onClick={() => {
                          form.reset()
                          Object.keys(values).forEach((key) => {
                            form.resetFieldState(key)
                          })
                          setControlAdded(false)
                        }}
                      >
                        {t('inputs.cancelar')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        disabled={pristine || !valid}
                      >
                        {t('inputs.salvar')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </div>

      <div className={clsx(classes.sectionTitle, controlAdded && classes.spaceTop)}>
        <Typography className={classes.screenTitle}>
          {t('auditoresAdm.auditores')}
        </Typography>

        <div className={classes.search}>
          <Filters
            fullWidth
            items={usuarios}
            setItems={setData}
            onChange={() => setPage(0)}
            origin={{ vertical: 'top', horizontal: 'right' }}
            filterScreen={filterScreen}
          />
        </div>
      </div>

      <div className={classes.tableBody}>
        <table className={classes.table}>
          <thead>
            <tr>
              {tableColumns.map((column) => (
                <th
                  key={column.key}
                  width={column.width}
                  align={column.align || 'left'}
                  className={classes.tableHeadCell}
                >
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item) => (
              <tr className={classes.tableBodyRow} key={item.id}>
                {tableColumns.map((column, index) => (
                  <td
                    key={column.key}
                    width={column.width}
                    align={column.align || 'left'}
                    className={classes.tableBodyCell}
                  >
                    {index === 3 && (
                      <div className={classes.containerItemEmpresa}>
                        {get(item, column.key).map((elem) => (
                          <span className={classes.itemEmpresa} key={elem.nome}>
                            {elem.nome}
                          </span>
                        ))}
                      </div>
                    )}
                    {index === 4 && (
                      item[column.key]
                    )}
                    {index !== 3 && index !== 4 && (
                      t(get(item, column.key))
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={classes.pagination}>
        {!!filteredData.length && (
          <Pagination
            page={page}
            onChange={setPage}
            pageSize={pageSize}
            count={data.length}
          />
        )}
      </div>
    </div>
  )
})

ListaAuditores.propTypes = {
  // paisId: PropTypes.number,
  controlAdded: PropTypes.bool,
  setControlAdded: PropTypes.func,
}

export default ListaAuditores
