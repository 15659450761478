import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { filter, map } from 'lodash'
import {
  API_LISTAR_INDICADORES_CRITERIO,
  API_CRIAR_INDICADOR,
  API_EXCLUIR_INDICADOR,
  API_ATUALIZAR_INDICADOR,
} from 'store/modules/api/indicadores.actions'

const listaIndicadoresCriterioSelecionado = (state = [], { type, payload }) => {
  switch (type) {
    case API_LISTAR_INDICADORES_CRITERIO.success: return payload
    case API_CRIAR_INDICADOR.success: return [...state, payload]
    case API_EXCLUIR_INDICADOR.success: return filter(state, (o) => o.id !== payload)
    case API_ATUALIZAR_INDICADOR.success:
      return map(state, (o) => (o.id === payload.id ? payload : o))
    default: return state
  }
}

const isCarregandoListaIndicadoresCriterioSelecionado =
  loadingReducer(false, API_LISTAR_INDICADORES_CRITERIO)

export default combineReducers({
  listaIndicadoresCriterioSelecionado,
  isCarregandoListaIndicadoresCriterioSelecionado,
})
