const ACTION_PATH = 'app/acoes'

export const CALL_CRIAR_ACAO = `${ACTION_PATH}/CALL_CRIAR_ACAO`
export const CALL_ATUALIZAR_ACAO = `${ACTION_PATH}/CALL_ATUALIZAR_ACAO`
export const CALL_EXCLUIR_ACAO = `${ACTION_PATH}/CALL_EXCLUIR_ACAO`
export const CALL_SELECIONAR_ACAO = `${ACTION_PATH}/CALL_SELECIONAR_ACAO`
export const CALL_NOVO_ACAO = `${ACTION_PATH}/CALL_NOVO_ACAO`

export const callCriarAcao = (acao) => ({
  type: CALL_CRIAR_ACAO,
  payload: acao,
})

export const callAtualizarAcao = ({ id, acao }) => ({
  type: CALL_ATUALIZAR_ACAO,
  payload: { id, acao },
})

export const callExcluirAcao = ({ id }) => ({
  type: CALL_EXCLUIR_ACAO,
  payload: id,
})

export const callSelecionarAcao = ({ id }) => ({
  type: CALL_SELECIONAR_ACAO,
  payload: { id },
})

export const callNovaAcao = () => ({
  type: CALL_NOVO_ACAO,
})
