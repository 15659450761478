const ACTION_PATH = 'app/usuariosAuditor'

export const CALL_CRIAR_USUARIO_AUDITOR = `${ACTION_PATH}/CALL_CRIAR_USUARIO_AUDITOR`
export const CALL_ATUALIZAR_USUARIO_AUDITOR = `${ACTION_PATH}/CALL_ATUALIZAR_USUARIO_AUDITOR`
export const CALL_EXCLUIR_USUARIO_AUDITOR = `${ACTION_PATH}/CALL_EXCLUIR_USUARIO_AUDITOR`
export const CALL_SELECIONAR_USUARIO_AUDITOR = `${ACTION_PATH}/CALL_SELECIONAR_USUARIO_AUDITOR`
export const CALL_NOVO_USUARIO_AUDITOR = `${ACTION_PATH}/CALL_NOVO_USUARIO_AUDITOR`

export const callCriarUsuarioAuditor = (usuario) => ({
  type: CALL_CRIAR_USUARIO_AUDITOR,
  payload: usuario,
})

export const callAtualizarUsuarioAuditor = (payload) => ({
  type: CALL_ATUALIZAR_USUARIO_AUDITOR,
  payload,
})

export const callExcluirUsuarioAuditor = ({ id }) => ({
  type: CALL_EXCLUIR_USUARIO_AUDITOR,
  payload: { id },
})

export const callSelecionarUsuarioAuditor = ({ id }) => ({
  type: CALL_SELECIONAR_USUARIO_AUDITOR,
  payload: { id },
})

export const callNovoUsuarioAuditor = () => ({
  type: CALL_NOVO_USUARIO_AUDITOR,
})
