import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { API_LISTAR_MUNICIPIOS } from 'store/modules/api/municipios.actions'

const lista = (state = null, { type, payload }) => (
  (type === API_LISTAR_MUNICIPIOS.success) ? payload : state
)

const isCarregandoLista = loadingReducer(false, API_LISTAR_MUNICIPIOS)

export default combineReducers({
  lista,
  isCarregandoLista,
})
