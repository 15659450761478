import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { makeStyles, Typography } from '@material-ui/core'
import UploadLineIcon from 'remixicon-react/Upload2LineIcon'
import DownloadLineIcon from 'remixicon-react/Download2LineIcon'
import DeleteBinLineIcon from 'remixicon-react/DeleteBin7LineIcon'
import Image2LineIcon from 'remixicon-react/Image2LineIcon'
import FilePdfLineIcon from 'remixicon-react/FilePdfLineIcon'
import { saveAs } from 'file-saver'
import { isEmpty, last } from 'lodash'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { enqueueSnackbar } from 'store/modules/notifications'
import { callExcluirAnexo } from 'store/modules/anexos'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'

const useStyles = makeStyles((theme) => ({
  column: {
    display: 'flex',
    marginBottom: 34,
    flexDirection: 'column',
  },
  main: {
    height: 48,
    marginTop: 5,
    display: 'flex',
    alignItems: 'center',
  },
  uploadIconDiv: {
    display: 'flex',
    border: `1px solid ${theme.palette.grey.main}`,
    width: '100%',
    height: 48,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: theme.palette.common.white,
    cursor: 'pointer',
    '&:hover': {
      border: '1px solid rgba(0, 0, 0, 0.87)',
    },
    '&$disabled': {
      cursor: 'default',
      border: '1px solid rgba(0, 0, 0, 0.26)',
      '& $anexoUpload': {
        color: theme.palette.grey.A0,
      },
      '& $uploadIcon': {
        cursor: 'default',
        color: theme.palette.grey.A0,
        '&:hover': {
          color: theme.palette.grey.A0,
        },
      },
      '& ~ $uploadText': {
        color: theme.palette.grey.A0,
      },
    },
    '&$error': {
      border: `1px solid ${theme.palette.error.main}`,
      '& $anexoUpload': {
        color: theme.palette.error.main,
      },
      '& $uploadIcon': {
        color: theme.palette.error.main,
      },
      '& ~ $uploadText, ~ small': {
        color: theme.palette.error.main,
      },
      '& ~ small': {
        fontSize: theme.typography.pxToRem(12),
        maxWidth: '38%',
        padding: theme.spacing(0.5, 2),
      },
    },
  },
  anexoUpload: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  fileDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  fileName: {
    color: theme.palette.primary.main,
    fontSize: 12,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginLeft: 10,
  },
  uploadIcon: {
    width: 20,
    color: theme.palette.primary.main,
    flexShrink: 0,
    '&:hover': {
      color: theme.palette.hover.lightBlue,
      cursor: 'pointer',
    },
  },
  iconsDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  fileIcon: {
    color: theme.palette.primary.main,
  },
  downloadIcon: {
    color: theme.palette.primary.main,
    marginRight: 20,
    '&:hover': {
      color: theme.palette.hover.lightBlue,
      cursor: 'pointer',
    },
  },
  deleteIcon: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.hover.lightBlue,
      cursor: 'pointer',
    },
  },
  uploadText: {
    maxWidth: '45%',
    color: theme.palette.primary.main,
    fontSize: 12,
    paddingLeft: 15,
    flexShrink: 0,
  },
  imageInput: {
    display: 'none',
  },
  disabled: {
    border: '1px solid #B5B6B8',
    backgroundColor: theme.palette.grey.F4,
  },
  error: {
    color: theme.palette.error.main,
  },
  errorText: {
    marginTop: 2,
    color: theme.palette.error.main,
  },
}))

const UploadFile = ({
  onChange,
  value,
  name,
  title,
  subtitle,
  maxSize = 10485760, // 10mb
  disabled,
  onDelete,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const dialog = useConfirmationDialog()
  const [errorUpload, setErrorUpload] = useState(false)
  const [type, setType] = useState(null)

  const maxSizeLegivel = maxSize / 1024 / 1024 // transforma bytes em megabytes

  useEffect(() => {
    if (value && value.nomeAnexo && !type) {
      setType(last(value.nomeAnexo.split('.')))
    }
  }, [type, value])

  const getBase64 = (file) => new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = () => resolve(fileReader.result)
    fileReader.onerror = reject
    fileReader.readAsDataURL(file)
  })

  const handleFileSelect = (e) => {
    if (!isEmpty(e.target.files)) {
      const [file] = e.target.files

      if (file.size > maxSize) {
        dispatch(enqueueSnackbar({
          message: t('inputs.anexoTamanhoMaximoAlerta', { size: maxSizeLegivel }),
          options: { variant: 'warning' },
        }))
        setErrorUpload(true)
        return
      }

      getBase64(file).then((result) => {
        if (onChange) {
          setType(file.type)
          onChange({ nomeAnexo: file.name, anexo: result, novo: true })
          setErrorUpload(false)
        }
      })
    }
  }

  const handleDelete = () => {
    if (!disabled) {
      dialog.showConfirmation({
        title: t('dialogs.confirmarExclusao'),
        message: t('dialogs.excluirAnexo', { nome: value?.nomeAnexo }),
      }).then((result) => {
        if (result) {
          if (value.id) {
            dispatch(callExcluirAnexo(value.id))
          }
          if (onChange) {
            onChange(null)
          }
          if (onDelete) {
            onDelete()
          }
        }
      })
    }
  }

  const baixarAnexo = () => {
    saveAs(value?.anexo, value?.nomeAnexo)
  }

  return (
    <div className={classes.column}>
      {!value?.nomeAnexo && (
        <>
          <div className={classes.main}>
            <label
              htmlFor={name}
              className={clsx(
                classes.uploadIconDiv,
                { [classes.error]: errorUpload, [classes.disabled]: disabled },
              )}
            >
              <div className={classes.anexoUpload}>
                {t(title || 'inputs.anexoEvidencia')}
              </div>
              <input
                id={name}
                onChange={handleFileSelect}
                type="file"
                name={name}
                accept="image/*,application/pdf"
                className={classes.imageInput}
                disabled={disabled}
              />
              <UploadLineIcon className={classes.uploadIcon} />
            </label>
            <Typography component="p" className={classes.uploadText}>
              {t(subtitle || 'inputs.anexoSubtitulo', { size: maxSizeLegivel })}
            </Typography>
          </div>
          {errorUpload && (
            <small className={classes.errorText}>{t('inputs.erroUpload')}</small>
          )}
        </>
      )}
      <div className={classes.showFile}>
        {value?.nomeAnexo && (
          <div className={classes.fileDiv}>
            <div className={classes.fileDiv}>
              {type === 'application/pdf' || type === 'pdf' ?
                <FilePdfLineIcon /> :
                <Image2LineIcon />}
              <p className={classes.fileName}>
                {value.nomeAnexo}{!!value.novo}
              </p>
            </div>
            <div className={classes.iconsDiv}>
              <DownloadLineIcon
                className={classes.downloadIcon}
                onClick={baixarAnexo}
              />
              <DeleteBinLineIcon
                className={classes.deleteIcon}
                onClick={handleDelete}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

UploadFile.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  maxSize: PropTypes.number,
  disabled: PropTypes.bool,
  onDelete: PropTypes.func,
}

export default UploadFile
