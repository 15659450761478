import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { makeStyles, Grid } from '@material-ui/core'
import { Form } from 'react-final-form'
import Button from 'components/Button'
import Accordion from 'components/Accordion'
import { FieldText } from 'components/Fields'
import { useSelector, useDispatch } from 'react-redux'
import { getAspectos, getIsUpdatingAspectos } from 'store/modules/aspectos/aspectos.selectors'
import { validateFormValues } from 'utils/forms'
import { callAtualizarAspecto } from 'store/modules/aspectos'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { TIPO_USUARIO } from 'utils/constantes'

const useStyles = makeStyles((theme) => ({
  rowText: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(5),
    fontSize: theme.typography.pxToRem(14),
  },
  formAdd: {
    transition: '0.20s ease-out',
    maxHeight: 0,
    overflow: 'hidden',
  },
  openFormAdd: {
    marginTop: '-10px',
    paddingTop: 10,
    maxHeight: '1300px',
    transition: '0.25s ease-in',
  },
  normalTop: {
    marginTop: '16px',
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '0 !important',
  },
  itemAlignRight: {
    maxWidth: '190px',
  },
  listItems: {
    border: '1px solid #C9CEDB',
    boxShadow: '0px 8px 32px rgba(15, 52, 68, 0.08)',
    borderRadius: '8px',
    padding: '0px 16px',
  },
  itemTitle: {
    fontFamily: 'Poppins',
    color: '#0F3444',
    fontWeight: '600',
    fontSize: '16px',
  },
  divider: {
    height: 1,
    width: 'calc(100% + 32px)',
    marginLeft: '-16px',
    backgroundColor: theme.palette.grey.main,
  },
  dividerField: {
    borderBottom: `1px solid ${theme.palette.grey.main}`,
    margin: '8px 0',
  },
  itemsForm: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    rowGap: '20px',
  },
  itemFormField: {
    width: 'calc( 50% - 4px )',
  },
  itemFormFieldFull: {
    width: '100%',
  },
}))

const TabAspectos = ({ paisId }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const isUpdating = useSelector(getIsUpdatingAspectos)
  const listaAspectos = useSelector(getAspectos)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)
  const [data, setData] = useState(listaAspectos)

  const desabilitarFormulario = Boolean(tipoUsuarioLogado === TIPO_USUARIO.ADMIN.OBSERVADOR)

  const handleUpdate = (values) => {
    const { id } = values
    const payload = ({ ...values, paisId })
    delete payload.id
    dispatch(callAtualizarAspecto({ id, aspecto: payload }))
  }

  const [openItem, setOpenItem] = useState('')

  const handleExpand = (item) => {
    setOpenItem(openItem === item ? '' : item)
  }

  const formFields = [
    {
      name: 'valorDeReferencia',
      label: t('inputs.ValorReferencia'),
      class: classes.itemFormField,
      required: true,
      type: 'number',
    },
    {
      name: 'valorMaximo',
      label: t('inputs.ValorMaximo'),
      class: classes.itemFormField,
      required: true,
      type: 'number',
    },
    {
      name: 'fator',
      label: t('inputs.FatorCorreçãoAI'),
      class: classes.itemFormField,
      required: true,
      type: 'number',
    },
    {
      name: 'unidadeDeMedida',
      label: t('inputs.UnidadeMedida'),
      class: classes.itemFormField,
      required: true,
      type: 'text',
    },
    {
      name: 'informacaoValores',
      label: t('inputs.InformacoesValores'),
      class: classes.itemFormFieldFull,
      required: false,
      type: 'textarea',
    },
  ]

  const FieldsForm = () => (
    <div className={classes.itemsForm}>
      {formFields.map((elem, index) => (
        elem.type !== 'textarea' ? (
          <FieldText
            key={index}
            required={elem.required}
            label={elem.label}
            name={elem.name}
            className={elem.class}
            type={elem.type ?? 'text'}
            disabled={desabilitarFormulario}
          />
        ) : (
          <FieldText
            key={index}
            required={elem.required}
            label={elem.label}
            name={elem.name}
            className={elem.class}
            type={elem.type}
            multiline
            minRows={5}
            maxRows={5}
            disabled={desabilitarFormulario}
          />
        )
      ))}
    </div>
  )

  const ItemForm = (position) => {
    const itemAspectos = [data[position]]
    return (
      <Form
        onSubmit={handleUpdate}
        validate={validateFormValues(yup.object({
          valorDeReferencia: yup.number().required(),
          unidadeDeMedida: yup.string().required(),
          fator: yup.number().required(),
          valorMaximo: yup.number().required(),
          informacaoValores: yup.string(),
        }))}
        initialValues={{
          valorDeReferencia: itemAspectos[0]?.valorDeReferencia ?? null,
          unidadeDeMedida: itemAspectos[0]?.unidadeDeMedida ?? '',
          fator: itemAspectos[0]?.fator ?? null,
          valorMaximo: itemAspectos[0]?.valorMaximo ?? null,
          informacaoValores: itemAspectos[0]?.informacaoValores ?? '',
          id: itemAspectos[0]?.id ?? '',
        }}
        render={({ handleSubmit, form, pristine, valid }) => (
          <form onSubmit={handleSubmit}>
            <Accordion
              variant="no-borders"
              classes={{ title: classes.itemTitle }}
              title={t(itemAspectos[0]?.label)}
              expanded={position === openItem}
              onExpand={() => handleExpand(position)}
            >
              <Grid container>
                { FieldsForm() }
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    className={classes.normalTop}
                  >
                    <Grid item className={classes.deleteButton}>
                      <Button
                        variant="danger"
                        onClick={() => { form.reset(); handleExpand('') }}
                      >
                        {t('inputs.cancelar')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        disabled={Boolean(pristine || !valid) || desabilitarFormulario}
                        loading={isUpdating}
                      >
                        {t('inputs.salvar')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Accordion>
            <div className={classes.divider} />
          </form>
        )}
      />
    )
  }

  useEffect(() => {
    setData(listaAspectos)
  }, [listaAspectos])

  return (
    <>
      <div className={classes.listItems}>
        {
          data.length > 0 &&
          data.map((elem, index) => (
            <Fragment key={index}>
              {ItemForm(index)}
            </Fragment>
          ))
        }
      </div>
    </>
  )
}

TabAspectos.propTypes = {
  paisId: PropTypes.number,
}

export default TabAspectos
