import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import {
  API_LISTAR_MATRIZ_RESIDUOS,
  API_SALVAR_NOVAS_MATRIZES,
  API_SALVAR_MATRIZ_RESIDUO,
  API_EXCLUIR_MATRIZ_RESIDUO,
} from 'store/modules/api/matrizResiduos.actions'

const lista = (state = [], { type, payload }) => {
  switch (type) {
    case API_LISTAR_MATRIZ_RESIDUOS.success:
      return payload
    case API_SALVAR_NOVAS_MATRIZES.success:
      return payload
    case API_SALVAR_MATRIZ_RESIDUO.success:
      return payload
    case API_EXCLUIR_MATRIZ_RESIDUO.success:
      return payload
    default:
      return state
  }
}

const isCarregandoLista = loadingReducer(false, API_LISTAR_MATRIZ_RESIDUOS)
const isSaving = loadingReducer(false, {
  request: [
    API_SALVAR_NOVAS_MATRIZES.request,
    API_SALVAR_MATRIZ_RESIDUO.request,
  ],
  success: [
    API_SALVAR_NOVAS_MATRIZES.success,
    API_SALVAR_MATRIZ_RESIDUO.success,
  ],
  failure: [
    API_SALVAR_NOVAS_MATRIZES.failure,
    API_SALVAR_MATRIZ_RESIDUO.failure,
  ],
})

export default combineReducers({
  lista,
  isCarregandoLista,
  isSaving,
})
