import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchClassesIrcActionTypes = apiActionTypes('classesIrc/API_LISTAR_CLASSES_IRC')
const salvarClassesIrcActionTypes = apiActionTypes('classesIrc/API_SALVAR_IRC')
const deleteClassesIrcActionTypes = apiActionTypes('classesIrc/API_EXCLUIR_CLASSES_IRC')
const updateClassesIrcActionTypes = apiActionTypes('classesIrc/API_ATUALIZAR_CLASSES_IRC')

export const API_LISTAR_CLASSES_IRC = asApiTypesObject(fetchClassesIrcActionTypes)
export const API_SALVAR_IRC = asApiTypesObject(salvarClassesIrcActionTypes)
export const API_EXCLUIR_CLASSES_IRC = asApiTypesObject(deleteClassesIrcActionTypes)
export const API_ATUALIZAR_CLASSES_IRC = asApiTypesObject(updateClassesIrcActionTypes)

export const apiListarClassesIrc = ({ projetoId, paisId }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/classesIrc`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchClassesIrcActionTypes,
}, { ...meta, query: { projetoId, paisId } })

export const apiSalvarIrc = (payload, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/classesIrc`,
  method: 'POST',
  types: salvarClassesIrcActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(payload),
}, { ...meta, successNotification: 'mensagens.criado' })
