const TIPO_USUARIO = {
  CLIENTE: {
    LIDER: 1,
    PADRAO: 2,
    LEITOR: 3,
  },
  ADMIN: {
    ADMIN: 4,
    GESTOR: 5,
    OBSERVADOR: 6,
  },
  AUDITOR: {
    AUDITOR: 7,
    MONITOR: 8,
  },
}

const VIEW_CLIENT = [
  TIPO_USUARIO.CLIENTE.LIDER,
  TIPO_USUARIO.CLIENTE.PADRAO,
  TIPO_USUARIO.CLIENTE.LEITOR,
]

const VIEW_ADMIN = [
  TIPO_USUARIO.ADMIN.ADMIN,
  TIPO_USUARIO.ADMIN.GESTOR,
  TIPO_USUARIO.ADMIN.OBSERVADOR,
]

const VIEW_AUDITOR = [
  TIPO_USUARIO.AUDITOR.AUDITOR,
  TIPO_USUARIO.AUDITOR.MONITOR,
]

const TIPO_INDICADORES = {
  ATENDIDO: 'ATENDIDO',
  NAO_ATENDIDO: 'NAO_ATENDIDO',
  PARCIALMENTE_ATENDIDO: 'PARCIALMENTE_ATENDIDO',
  NAO_PREENCHIDO: 'NAO_PREENCHIDO',
  NAO_APLICAVEL: 'NAO_APLICAVEL',
}

const DOCUMENTS_URL = 'https://lifeinstituteglobal.org/documentos-life/'

const comboPressaoTipoAuditoria = [
  { id: 'FASE_1', label: 'comboParecerPressao.fase1' },
  { id: 'CERTIFICACAO', label: 'comboParecerPressao.certificacao' },
  { id: 'VERIFICACAO', label: 'comboParecerPressao.verificacao' },
  { id: 'RECERTIFICACAO', label: 'comboParecerPressao.recertificacao' },
  { id: 'INTERNA', label: 'comboParecerPressao.interna' },
  { id: '1_ACOMPANHAMENTO', label: 'comboParecerPressao.1acompanhamento' },
  { id: '2_ACOMPANHAMENTO', label: 'comboParecerPressao.2acompanhamento' },
  { id: '3_ACOMPANHAMENTO', label: 'comboParecerPressao.3acompanhamento' },
  { id: '4_ACOMPANHAMENTO', label: 'comboParecerPressao.4acompanhamento' },
]

const comboPressaoComentarSobre = [
  { id: 'IP_DE_ASPECTO', label: 'comboParecerPressao.ipAspecto' },
  { id: 'FATURAMENTO', label: 'comboParecerPressao.faturamento' },
  { id: 'RESIDUO', label: 'comboParecerPressao.ocorrenciaResiduo' },
  { id: 'GEE', label: 'comboParecerPressao.ocorrenciaGEE' },
  { id: 'AGUASILVICULTURA', label: 'comboParecerPressao.ocorrenciaAguaSilvicultura' },
  { id: 'ENERGIA', label: 'comboParecerPressao.ocorrenciaEnergia' },
  { id: 'AREA', label: 'comboParecerPressao.ocorrenciaArea' },
  { id: 'OUTRO', label: 'comboParecerPressao.outro' },
]

const comboPressaoTipoComentario = [
  { id: 'COMENTARIO', label: 'comboParecerPressao.comentario' },
  { id: 'ACAO_MELHORIA', label: 'comboParecerPressao.acaoMelhoria' },
  { id: 'REQUISITO_MELHORIA', label: 'comboParecerPressao.requisitoMelhoria' },
  { id: 'OPORTUNIDADE_MELHORIA', label: 'comboParecerPressao.oportunidadeMelhoria' },
]

const comboPressaoIpAspecto = [
  { id: 'RESIDUOS', label: 'comboParecerPressao.residuos' },
  { id: 'GEE', label: 'comboParecerPressao.gee' },
  { id: 'AGUA', label: 'comboParecerPressao.agua' },
  { id: 'ENERGIA', label: 'comboParecerPressao.energia' },
  { id: 'AREA', label: 'comboParecerPressao.area' },
]

const comboPressaoOcorrenciaFaturamento = [
  { id: 'FATURAMENTO_BRUTO', label: 'comboParecerPressao.faturamentoBruto' },
  { id: 'MOEDA', label: 'comboParecerPressao.moeda' },
  { id: 'COTACAO_DOLAR', label: 'comboParecerPressao.cotacaoDolar' },
  { id: 'FATURAMENTO_DOLAR', label: 'comboParecerPressao.faturamentoDolar' },
  { id: 'COMENTARIO', label: 'comboParecerPressao.comentario' },
  { id: 'EVIDENCIA', label: 'comboParecerPressao.evidencia' },
  { id: 'ANEXO', label: 'comboParecerPressao.anexo' },
]

const comboPressaoOcorrenciaResiduo = [
  { id: 'DESCRICAO', label: 'comboParecerPressao.descricao' },
  { id: 'CLASSE_RESIDUO', label: 'comboParecerPressao.classeResiduo' },
  { id: 'DESTINACAO', label: 'comboParecerPressao.destinacao' },
  { id: 'QUANTIDADE', label: 'comboParecerPressao.quantidade' },
  { id: 'UNIDADE', label: 'comboParecerPressao.unidade' },
  { id: 'PERIODO', label: 'comboParecerPressao.periodo' },
  { id: 'QUANTIDADE_ANO', label: 'comboParecerPressao.quantidadeTAno' },
  { id: 'COMENTARIO', label: 'comboParecerPressao.comentario' },
  { id: 'EVIDENCIA', label: 'comboParecerPressao.evidencia' },
  { id: 'ANEXO', label: 'comboParecerPressao.anexo' },
]

const comboPressaoOcorrenciaGee = [
  { id: 'DESCRICAO', label: 'comboParecerPressao.descricao' },
  { id: 'GAS', label: 'comboParecerPressao.gas' },
  { id: 'ESCOPO', label: 'comboParecerPressao.escopo' },
  { id: 'QUANTIDADE', label: 'comboParecerPressao.quantidade' },
  { id: 'UNIDADE', label: 'comboParecerPressao.unidade' },
  { id: 'PERIODO', label: 'comboParecerPressao.periodo' },
  { id: 'QUANTIDADE_ANO', label: 'comboParecerPressao.quantidadeTCO2Ano' },
  { id: 'COMENTARIO', label: 'comboParecerPressao.comentario' },
  { id: 'EVIDENCIA', label: 'comboParecerPressao.evidencia' },
  { id: 'ANEXO', label: 'comboParecerPressao.anexo' },
]

const comboPressaoOcorrenciaAgua = [
  { id: 'DESCRICAO', label: 'comboParecerPressao.descricao' },
  { id: 'TIPO_AGUA', label: 'comboParecerPressao.tipoAgua' },
  { id: 'REGIAO_HIDROGRAFICA', label: 'comboParecerPressao.regiaoHidrografica' },
  { id: 'ESPECIE', label: 'comboParecerPressao.especie' },
  { id: 'QUANTIDADE', label: 'comboParecerPressao.quantidade' },
  { id: 'UNIDADE', label: 'comboParecerPressao.unidade' },
  { id: 'PERIODO', label: 'comboParecerPressao.periodo' },
  { id: 'UNIDADE_AREA', label: 'comboParecerPressao.unidadeArea' },
  { id: 'ESTADO', label: 'comboParecerPressao.estado' },
  { id: 'MUNICIPIO', label: 'comboParecerPressao.municipio' },
  { id: 'QUANTIDADE_ANO', label: 'comboParecerPressao.quantidadeM3Ano' },
  { id: 'COMENTARIO', label: 'comboParecerPressao.comentario' },
  { id: 'EVIDENCIA', label: 'comboParecerPressao.evidencia' },
  { id: 'ANEXO', label: 'comboParecerPressao.anexo' },
]

const comboPressaoOcorrenciaEnergia = [
  { id: 'DESCRICAO', label: 'comboParecerPressao.descricao' },
  { id: 'FONTE_ENERGIA', label: 'comboParecerPressao.fonteEnergia' },
  { id: 'QUANTIDADE', label: 'comboParecerPressao.quantidade' },
  { id: 'UNIDADE', label: 'comboParecerPressao.unidade' },
  { id: 'PERIODO', label: 'comboParecerPressao.periodo' },
  { id: 'QUANTIDADE_ANO', label: 'comboParecerPressao.quantidadeTepAno' },
  { id: 'COMENTARIO', label: 'comboParecerPressao.comentario' },
  { id: 'EVIDENCIA', label: 'comboParecerPressao.evidencia' },
  { id: 'ANEXO', label: 'comboParecerPressao.anexo' },
]

const comboPressaoOcorrenciaArea = [
  { id: 'DESCRICAO', label: 'comboParecerPressao.descricao' },
  { id: 'TIPO_AREA', label: 'comboParecerPressao.tipoArea' },
  { id: 'ECORREGIAO', label: 'comboParecerPressao.ecorregiao' },
  { id: 'USO_SOLO', label: 'comboParecerPressao.usoSolo' },
  { id: 'QUANTIDADE', label: 'comboParecerPressao.quantidade' },
  { id: 'UNIDADE', label: 'comboParecerPressao.unidade' },
  { id: 'QUANTIDADE_ANO', label: 'comboParecerPressao.quantidadeHaAno' },
  { id: 'COMENTARIO', label: 'comboParecerPressao.comentario' },
  { id: 'EVIDENCIA', label: 'comboParecerPressao.evidencia' },
  { id: 'ANEXO', label: 'comboParecerPressao.anexo' },
]

const comboDesempenhoTipoComentario = [
  { id: 'COMENTARIO', label: 'comboParecerDesempenho.comentario' },
  { id: 'ACAO_MELHORIA', label: 'comboParecerDesempenho.acaoMelhoria' },
  { id: 'REQUISITO_MELHORIA', label: 'comboParecerDesempenho.requisitoMelhoria' },
  { id: 'OPORTUNIDADE_MELHORIA', label: 'comboParecerDesempenho.oportunidadeMelhoria' },
]

const comboDesempenhoComentarSobre = [
  { id: 'DADOS_PROJETO', label: 'comboParecerDesempenho.dadosProjeto' },
  { id: 'PONTUACAO_PROJETO', label: 'comboParecerDesempenho.pontuacaoProjeto' },
  { id: 'IRC_PROJETO', label: 'comboParecerDesempenho.ircProjeto' },
  { id: 'DADOS_ACAO', label: 'comboParecerDesempenho.dadosAcao' },
  { id: 'CLASSIFICACAO_ACAO', label: 'comboParecerDesempenho.classificacaoAcao' },
  { id: 'QUALIFICADORES_ACAO', label: 'comboParecerDesempenho.qualificadoresAcao' },
  { id: 'PONTUACAO_ACAO', label: 'comboParecerDesempenho.pontuacaoAcao' },
  { id: 'OUTRO', label: 'comboParecerPressao.outro' },
]

const comboDesempenhoOcorrenciaProjeto = [
  { id: 'NOME_PROJETO', label: 'comboParecerDesempenho.nomeProjeto' },
  { id: 'OBJETIVO', label: 'comboParecerDesempenho.objetivo' },
  { id: 'INVESTIMENTO', label: 'comboParecerDesempenho.investimento' },
  { id: 'MOEDA', label: 'comboParecerDesempenho.moeda' },
  { id: 'COTACAO_DOLAR', label: 'comboParecerDesempenho.cotacaoDolar' },
  { id: 'INVESTIMENTO_DOLAR', label: 'comboParecerDesempenho.investimentoDolar' },
  { id: 'RESPONSAVEL_PROJETO', label: 'comboParecerDesempenho.responsavelProjeto' },
  { id: 'COMENTARIO', label: 'comboParecerDesempenho.comentario' },
  { id: 'EVIDENCIA', label: 'comboParecerDesempenho.evidencia' },
  { id: 'ANEXO', label: 'comboParecerDesempenho.anexo' },
]

const comboDesempenhoOcorrenciaPontuacaoProjeto = [
  { id: 'TOTAL_IRC', label: 'comboParecerDesempenho.totalIrc' },
  { id: 'TOTAL_SEM_IRC', label: 'comboParecerDesempenho.totalSemIrc' },
  { id: 'VIGENTES_ADICIONAIS_IRC', label: 'comboParecerDesempenho.vigentesAdicionaisIrc' },
  { id: 'VIGENTES_ADICIONAIS_SEM_IRC', label: 'comboParecerDesempenho.vigentesAdicionaisSemIrc' },
  { id: 'VIGENTES_NAO_ADICIONAIS_IRC', label: 'comboParecerDesempenho.vigentesNaoAdicionaisIrc' },
  { id: 'VIGENTES_NAO_ADICIONAIS_SEM_IRC', label: 'comboParecerDesempenho.vigentesNaoAdicionaisSemIrc' },
  { id: 'NAO_VIGENTES_ADICIONAIS_IRC', label: 'comboParecerDesempenho.naoVigentesAdicionaisIrc' },
  { id: 'NAO_VIGENTES_ADICIONAIS_SEM_IRC', label: 'comboParecerDesempenho.naoVigentesAdicionaisSemIrc' },
  { id: 'NAO_VIGENTES_NAO_ADICIONAIS_IRC', label: 'comboParecerDesempenho.naoVigentesNaoAdicionaisIrc' },
  { id: 'NAO_VIGENTES_NAO_ADICIONAIS_SEM_IRC', label: 'comboParecerDesempenho.naoVigentesNaoAdicionaisSemIrc' },
  { id: 'SIMULADA_IRC', label: 'comboParecerDesempenho.simuladaIrc' },
  { id: 'SIMULADA_SEM_IRC', label: 'comboParecerDesempenho.simuladaSemIrc' },
]

const comboDesempenhoOcorrenciaIrcProjeto = [
  { id: 'VALORES_IRC', label: 'comboParecerDesempenho.valoresIrc' },
  { id: 'INDICADORES_SELECIONADOS', label: 'comboParecerDesempenho.indicadoresSelecionados' },
  { id: 'COMENTARIO', label: 'comboParecerDesempenho.comentario' },
  { id: 'EVIDENCIA', label: 'comboParecerDesempenho.evidencia' },
  { id: 'ANEXO', label: 'comboParecerDesempenho.anexo' },
]

const comboDesempenhoOcorrenciaAcaoProjeto = [
  { id: 'NOME_ACAO', label: 'comboParecerDesempenho.nomeAcao' },
  { id: 'OBJETIVO', label: 'comboParecerDesempenho.objetivoAcao' },
  { id: 'AREA_HA', label: 'comboParecerDesempenho.areaHa' },
  { id: 'PAIS_ESTADO_MUNICIPIO', label: 'comboParecerDesempenho.paisEstadoMunicipio' },
  { id: 'ECORREGIAO', label: 'comboParecerDesempenho.ecorregiao' },
  { id: 'BIOMA', label: 'comboParecerDesempenho.bioma' },
  { id: 'INVESTIMENTO', label: 'comboParecerDesempenho.investimento' },
  { id: 'MOEDA', label: 'comboParecerDesempenho.moeda' },
  { id: 'COTACAO_DOLAR', label: 'comboParecerDesempenho.cotacaoDolar' },
  { id: 'INVESTIMENTO_DOLAR', label: 'comboParecerDesempenho.investimentoDolar' },
  { id: 'COMENTARIO', label: 'comboParecerDesempenho.comentario' },
  { id: 'EVIDENCIA', label: 'comboParecerDesempenho.evidencia' },
  { id: 'ANEXO', label: 'comboParecerDesempenho.anexo' },
]

const comboDesempenhoOcorrenciaClassificacaoAcaoProjeto = [
  { id: 'GRUPO_TEMA', label: 'comboParecerDesempenho.grupoTema' },
  { id: 'PERIODO_INICIAL', label: 'comboParecerDesempenho.periodoInicial' },
  { id: 'PERIODO_FINAL', label: 'comboParecerDesempenho.periodoFinal' },
  { id: 'STATUS_ACAO', label: 'comboParecerDesempenho.statusAcao' },
  { id: 'TEMPORALIDADE', label: 'comboParecerDesempenho.temporalidade' },
  { id: 'ADICIONALIDADE', label: 'comboParecerDesempenho.adicionalidade' },
]

const comboDesempenhoOcorrenciaQualificadoresAcaoProjeto = [
  { id: 'CLASSIFICACAO', label: 'comboParecerDesempenho.classificacao' },
  { id: 'COMENTARIO', label: 'comboParecerDesempenho.comentario' },
  { id: 'EVIDENCIA', label: 'comboParecerDesempenho.evidencia' },
  { id: 'ANEXO', label: 'comboParecerDesempenho.anexo' },
]

const comboIndicadoresTipoComentario = [
  { id: 'COMENTARIO', label: 'comboParecerIndicador.comentario' },
  { id: 'ACAO_MELHORIA', label: 'comboParecerIndicador.acaoMelhoria' },
  { id: 'REQUISITO_MELHORIA', label: 'comboParecerIndicador.requisitoMelhoria' },
  { id: 'OPORTUNIDADE_MELHORIA', label: 'comboParecerIndicador.oportunidadeMelhoria' },
]

const comboIndicadoresComentarSobre = [
  { id: 'INDICADORES_ESSENCIAIS', label: 'comboParecerIndicador.indicadoresEssenciais' },
  { id: 'INDICADORES_GERAIS', label: 'comboParecerIndicador.indicadoresGerais' },
  { id: 'ATENDIMENTO_GERAL_INDICADORES', label: 'comboParecerIndicador.atendimentoGeralIndicadores' },
  { id: 'PREENCHIMENTO_INDICADOR_ESPECIFICO', label: 'comboParecerIndicador.preenchimentoIndicadorEspecifico' },
  { id: 'OUTRO', label: 'comboParecerPressao.outro' },
]

const comboIndicadoresPreenchimentoIndicadorEspecifico = [
  { id: 'SITUACAO', label: 'comboParecerIndicador.situacao' },
  { id: 'RESPONSAVEL', label: 'comboParecerIndicador.responsavel' },
  { id: 'SETOR_RESPONSAVEL', label: 'comboParecerIndicador.setorResponsavel' },
  { id: 'COMENTARIO', label: 'comboParecerIndicador.comentario' },
  { id: 'EVIDENCIA', label: 'comboParecerIndicador.evidencia' },
  { id: 'ANEXO', label: 'comboParecerIndicador.anexo' },
]

module.exports = {
  TIPO_USUARIO,
  DOCUMENTS_URL,
  TIPO_INDICADORES,
  VIEW_CLIENT,
  VIEW_ADMIN,
  VIEW_AUDITOR,
  comboPressaoComentarSobre,
  comboPressaoTipoComentario,
  comboPressaoTipoAuditoria,
  comboPressaoIpAspecto,
  comboPressaoOcorrenciaFaturamento,
  comboPressaoOcorrenciaResiduo,
  comboPressaoOcorrenciaGee,
  comboPressaoOcorrenciaAgua,
  comboPressaoOcorrenciaEnergia,
  comboPressaoOcorrenciaArea,
  comboDesempenhoComentarSobre,
  comboDesempenhoTipoComentario,
  comboDesempenhoOcorrenciaProjeto,
  comboDesempenhoOcorrenciaPontuacaoProjeto,
  comboDesempenhoOcorrenciaIrcProjeto,
  comboDesempenhoOcorrenciaAcaoProjeto,
  comboDesempenhoOcorrenciaClassificacaoAcaoProjeto,
  comboDesempenhoOcorrenciaQualificadoresAcaoProjeto,
  comboIndicadoresComentarSobre,
  comboIndicadoresTipoComentario,
  comboIndicadoresPreenchimentoIndicadorEspecifico,
}
