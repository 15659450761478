import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import { map } from 'lodash'
import { makeStyles, Grid, Typography } from '@material-ui/core'
import { getPrincipios } from 'store/modules/principios/principios.selectors'
import { callBuscarCriteriosPrincipio } from 'store/modules/criterios/criterios.actions'
import { getCriteriosPrincipioSelecionado } from 'store/modules/criterios/criterios.selectors'
import { callCriarCriterio, callAtualizarCriterio, callExcluirCriterio } from 'store/modules/criterios'
import { Form } from 'react-final-form'
import * as yup from 'yup'
import { validateFormValues } from 'utils/forms'
import { FieldText } from 'components/Fields'
import Accordion from 'components/Accordion'
import Button from 'components/Button'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { TIPO_USUARIO } from 'utils/constantes'
import { TabListItem, TabItem } from '../styles'

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
  screenTitle: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(5),
    fontSize: theme.typography.pxToRem(24),
  },
  listItems: {
    border: '1px solid #C9CEDB',
    boxShadow: '0px 8px 32px rgba(15, 52, 68, 0.08)',
    borderRadius: '8px',
    padding: '0px 16px',
    marginTop: '32px',
  },
  divider: {
    height: 1,
    width: 'calc(100% + 32px)',
    marginLeft: '-16px',
    backgroundColor: theme.palette.grey.main,
  },
  accordionTitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  formButtons: {
    margin: '12px',
  },
  adicionarCriterio: {
    marginTop: '2rem',
  },
}))

const initialValue = {
  descricao: '',
  notasRodape: '',
}

const Criterios = ({ paisId }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const dialog = useConfirmationDialog()

  const listaPrincipios = useSelector(getPrincipios)
  const listaCriteriosPrincipioSelecionado = useSelector(getCriteriosPrincipioSelecionado)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const [tabSelecionada, setTabSelecionada] = useState(0)
  const [principioSelecionado, setPrincipioSelecionado] = useState(null)
  const [listaForm, setListaForm] = useState([])
  const [openItem, setOpenItem] = useState('')

  useEffect(() => {
    if (listaPrincipios.length) {
      setPrincipioSelecionado(listaPrincipios[0])
      setTabSelecionada(listaPrincipios[0].id)
      dispatch(callBuscarCriteriosPrincipio(listaPrincipios[0].id))
    }
  }, [dispatch, listaPrincipios])

  useEffect(() => {
    setOpenItem('')
    setListaForm(listaCriteriosPrincipioSelecionado)
  }, [listaCriteriosPrincipioSelecionado])

  const handleTabClick = (principio) => {
    setTabSelecionada(principio.id)
    setPrincipioSelecionado(principio)
    dispatch(callBuscarCriteriosPrincipio(principio.id))
  }

  const handleAdd = () => {
    setListaForm([...listaForm, { ...initialValue, numero: listaForm.length + 1 }])
    setOpenItem(listaForm.length)
  }

  const handleExpand = (item) => {
    setOpenItem(openItem === item ? '' : item)
  }

  const handleSave = (values) => {
    if (values.id) {
      return dispatch(callAtualizarCriterio({
        id: values.id,
        criterio: {
          ...values,
          updatedAt: undefined,
          createdAt: undefined,
          deletedAt: undefined,
        },
      }))
    }
    return dispatch(callCriarCriterio({ ...values, paisId, principioId: principioSelecionado.id }))
  }

  const handleDelete = ({ id, numero }, index) => {
    if (id) {
      dialog.showConfirmation({
        title: t('dialogs.confirmarExclusao'),
        message: t('dialogs.excluirCriterio', { criterio: numero }),
      }).then((result) => result && dispatch(callExcluirCriterio({ id })))
    } else {
      let lista = [...listaForm]
      lista = lista.slice(0, index)
      setListaForm(lista)
    }
  }

  const handleCancel = (form) => {
    form.reset()
    setOpenItem('')
  }

  const desabilitarFormulario = Boolean(tipoUsuarioLogado === TIPO_USUARIO.ADMIN.OBSERVADOR)

  return (
    <>
      <div className={classes.sectionTitle}>
        <Typography className={classes.screenTitle}>
          {t('paisesCalculos.criterios')}
        </Typography>
      </div>
      <TabListItem>
        {listaPrincipios && (
          map(listaPrincipios, (principio, i) => (
            <TabItem
              key={i}
              selected={principio.id === tabSelecionada}
              onClick={() => handleTabClick(principio)}
            >
              {`${t('paisesCalculos.principio')} ${principio.numero ?? ''}`}
            </TabItem>
          ))
        )}
      </TabListItem>

      {principioSelecionado?.id === tabSelecionada && (
      <>
        <div className={classes.listItems}>
          {map(listaForm, (criterio, index) => (
            <Form
              key={index}
              onSubmit={handleSave}
              initialValues={criterio || initialValue}
              validate={validateFormValues(yup.object().shape({
                descricao: yup.string(),
                notasRodape: yup.string(),
              }))}
              render={({ handleSubmit, values, form, pristine }) => (
                <form onSubmit={handleSubmit}>
                  <Accordion
                    key={index}
                    expanded={index === openItem}
                    variant="no-borders"
                    classes={{ title: classes.accordionTitle }}
                    title={`${t('paisesCalculos.criterio')} ${criterio.numero ?? ''}`}
                    onDelete={() => handleDelete(values, index)}
                    onExpand={() => handleExpand(index)}
                    disableDelete={desabilitarFormulario}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <FieldText
                          name="numero"
                          label={t('inputs.ordem')}
                          placeholder={t('inputs.insiraOrdemCriterio')}
                          disabled={desabilitarFormulario}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FieldText
                          multiline
                          minRows={5}
                          name="descricao"
                          label={t('inputs.descricaoCriterio')}
                          placeholder={t('inputs.insiraDescricaoCriterio')}
                          disabled={desabilitarFormulario}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FieldText
                          multiline
                          minRows={5}
                          name="notasRodape"
                          label={t('inputs.notasRodape')}
                          placeholder={t('inputs.insiraNotasRodape')}
                          disabled={desabilitarFormulario}
                        />
                      </Grid>
                      <Grid container direction="row" justifyContent="space-between" className={classes.formButtons}>
                        <Grid className={classes.cancelButton} item>
                          <Button variant="danger" onClick={() => handleCancel(form)}>
                            {t('inputs.cancelar')}
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button type="submit" disabled={Boolean(pristine && values.id) || desabilitarFormulario}>
                            {t('inputs.salvar')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Accordion>
                  <div className={classes.divider} />
                </form>
              )}
            />
          ))}
        </div>
        <Grid container direction="row" justifyContent="flex-end" className={classes.adicionarCriterio}>
          <Grid item>
            <Button onClick={handleAdd} disabled={desabilitarFormulario}>
              {t('paisesCalculos.adicionarCriterio')}
            </Button>
          </Grid>
        </Grid>
      </>
      )}
    </>
  )
}

Criterios.propTypes = {
  paisId: PropTypes.number,
}

export default Criterios
