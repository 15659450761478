import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchEscopoGasesActionTypes = apiActionTypes('escopoGases/API_LISTAR_ESCOPO_GASES')

export const API_LISTAR_ESCOPO_GASES = asApiTypesObject(fetchEscopoGasesActionTypes)

export const apiListarEscopoGases = (data, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/escopoGases`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchEscopoGasesActionTypes,
}, { ...meta })
