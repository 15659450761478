import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { API_ATUALIZAR_ACAO, API_CRIAR_ACAO, API_EXCLUIR_ACAO } from 'store/modules/api/acoes.actions'
import { API_LISTAR_DADOS_DASHBOARD } from 'store/modules/api/dashboard.actions'
import { API_EXCLUIR_PROJETO } from 'store/modules/api/projetos.actions'

const dados = (state = {}, { type, payload }) => {
  switch (type) {
    case API_LISTAR_DADOS_DASHBOARD.success: return payload
    case API_CRIAR_ACAO.success:
    case API_ATUALIZAR_ACAO.success: return {
      ...state, dpbOrganizacao: payload.dpbOrganizacao,
    }
    case API_EXCLUIR_ACAO.success: return {
      ...state, dpbOrganizacao: payload.dpbOrganizacao,
    }
    case API_EXCLUIR_PROJETO.success: return {
      ...state, dpbOrganizacao: payload.dpbOrganizacao,
    }
    default: return state
  }
}

const anoCarregado = (state = null, { type, meta }) => (
  (type === API_LISTAR_DADOS_DASHBOARD.success) ? meta.query.ano : state
)

const unidadeCarregada = (state = null, { type, meta }) => (
  (type === API_LISTAR_DADOS_DASHBOARD.success) ? meta.query.unidadeId : state
)

const isCarregandoDados = loadingReducer(false, API_LISTAR_DADOS_DASHBOARD)

export default combineReducers({
  dados,
  isCarregandoDados,
  anoCarregado,
  unidadeCarregada,
})
