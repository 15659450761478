import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import { apiCriarOcupacaoArea, apiAtualizarOcupacaoArea, apiExcluirOcupacaoArea, apiGetOcupacaoArea } from 'store/modules/api/ocupacaoArea.actions'
import { CALL_ATUALIZAR_OCUPACAO_AREA, CALL_CRIAR_OCUPACAO_AREA, CALL_EXCLUIR_OCUPACAO_AREA, CALL_SELECIONAR_OCUPACAO_AREA } from 'store/modules/ocupacaoArea/ocupacaoArea.actions'

const onSelected = (action$) => action$.pipe(
  ofType(CALL_SELECIONAR_OCUPACAO_AREA),
  debounceTime(250),
  map(({ payload }) => apiGetOcupacaoArea(payload.id)),
)

const onCallCriarOcupacaoAreas = (action$) => action$.pipe(
  ofType(CALL_CRIAR_OCUPACAO_AREA),
  debounceTime(250),
  map(({ payload }) => apiCriarOcupacaoArea(payload)),
)

const onCallAtualizarOcupacaoAreas = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_OCUPACAO_AREA),
  debounceTime(250),
  map(({ payload }) => apiAtualizarOcupacaoArea(payload)),
)

const onCallExcluirOcupacaoArea = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_OCUPACAO_AREA),
  debounceTime(250),
  map(({ payload }) => apiExcluirOcupacaoArea(payload)),
)

export default combineEpics(
  onCallCriarOcupacaoAreas,
  onCallAtualizarOcupacaoAreas,
  onSelected,
  onCallExcluirOcupacaoArea,
)
