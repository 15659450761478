import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableHeadCell: {
    padding: 0,
    fontSize: 14,
    color: theme.palette.grey.blue,
    paddingBottom: theme.spacing(3),
    fontWeight: theme.typography.fontWeightRegular,
  },
  tableBodyRow: {
    fontSize: 12,
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.grey.main}`,
    },
  },
  tableBodyCell: {
    padding: theme.spacing(1, 0),
  },
}))

const Table = ({ columns = [], data = [] }) => {
  const classes = useStyles()

  return (
    <table className={classes.table}>
      <thead>
        <tr>
          {columns.map((column) => (
            <th
              key={column.key}
              width={column.width}
              align={column.align || 'left'}
              className={classes.tableHeadCell}
            >
              {column.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr className={classes.tableBodyRow} key={item.id}>
            {columns.map((column) => (
              <td
                key={column.key}
                width={column.width}
                align={column.align || 'left'}
                className={classes.tableBodyCell}
              >
                {item[column.key]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      align: PropTypes.string,
      width: PropTypes.string,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ),
  data: PropTypes.array,
}

export default Table
