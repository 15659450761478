import { RSAA, isRSAA } from 'redux-api-middleware'
import {
  isObject,
  isObjectOrNull,
  isFunction
} from '../utils'
import { RSAA_EXT } from './rsaaExt'

const RSAA_TYPES = ['request', 'success', 'failure']

/**
 * Symbol used to define additional meta data for an RSAA
 * @alias module:api
 * @constant {symbol}
 */
export const RSAA_META = Symbol(`${RSAA_EXT}/meta`)

/**
 * Allows configuring RSAAs with meta data.  All async FSAs dispatched due to the api call
 * will include this data inside of their `meta` property.
 *
 * Only supports object values for the RSAA `meta` property.  If an RSAA type descriptor
 * returns a non-object value for its `meta` property, RSAA metadata won't be applied to FSA.
 *
 * Since RSAAs allows for typeDescriptors to be defined as a string before redux-api-middleware
 * blows them up to real flux standard actions (FSAs), rsaaMetaMiddleware should be added before
 * redux-api-middleware in the middleware chain so that it can modify type descriptors with
 * metadata before the RSAA is consumed by the apiMiddleware.
 *
 * The when applying values to FSA meta properties, rsaaMetaMiddleware will also append an
 * additional "rsaaProps" property, with the original RSAA params used to trigger the associated
 * network request.  This can be useful for debuggin, but also in case you need to perform some
 * async logic based on the response action AND the original rsaaProps.
 *
 * This is mainly a convienence option for common use-cases.  RSAA type descriptors
 * can also be configured manually in each API action creator function. See:
 *   https://github.com/agraboso/redux-api-middleware#customizing-the-dispatched-fsas
 *
 * Example 1:
 * Any RSAA can be given a `RSAA_META` symbol property:
 * ```
 * {
 *   [RSAA]: { ... },
 *   [RSAA_META]: {
 *     foo: 'bar'
 *   }
 * }
 * ```
 * All dispatched REQUEST|SUCCESS|FAILURE actions will then be dispatched with this same metadata:
 * ```
 * {
 *   type: FAILURE,
 *   payload: { ... },
 *   meta: {
 *     foo: 'bar',
 *     // ... and any additional meta values supplied by the original RSAA
 *   }
 * }
 * ```
 *
 * @alias module:api
 * @return {function} normal redux-middleware signature
 */
const rsaaMetaMiddleware = () => next => action => {
  if (isRSAA(action) && isObjectOrNull(action[RSAA_META])) {
    return next(rsaaMeta(action))
  }
  return next(action)
}

export default rsaaMetaMiddleware

const rsaaMeta = (rsaa) => {
  const nextRSAA = { ...rsaa }
  const rsaaTypes = rsaa[RSAA].types || []
  const rsaaMeta = rsaa[RSAA_META] || {}

  const newTypes = RSAA_TYPES.map((defaultType, i) => {
    const typeDescriptor = rsaaTypes[i] || defaultType
    return processTypeDescriptor(typeDescriptor, rsaaMeta, nextRSAA)
  })

  return {
    ...nextRSAA,
    [RSAA]: {
      ...nextRSAA[RSAA],
      types: newTypes
    }
  }
}

const processTypeDescriptor = (typeDescriptor, rsaaMeta, rsaa) => {
  const rsaaProps = { ...rsaa[RSAA] }

  if (isObject(typeDescriptor)) {
    const { meta } = typeDescriptor
    if (isObjectOrNull(meta)) {
      return {
        ...typeDescriptor,
        meta: { ...meta, ...rsaaMeta, rsaaProps }
      }
    }
    if (isFunction(meta)) {
      return {
        ...typeDescriptor,
        meta: (...args) => {
          const metaValue = meta(...args)
          return isObjectOrNull(metaValue)
            ? { ...metaValue, ...rsaaMeta, rsaaProps }
            : metaValue
        }
      }
    }
    return typeDescriptor
  }

  return {
    type: typeDescriptor,
    meta: { ...rsaaMeta, rsaaProps }
  }
}
