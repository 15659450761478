import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { makeStyles, Grid, Typography } from '@material-ui/core'
import Filters from 'components/FilterList/Filters'
import { Form } from 'react-final-form'
import Button from 'components/Button'
import Accordion from 'components/Accordion'
import { FieldText } from 'components/Fields'
import { useSelector, useDispatch } from 'react-redux'
import { getFontesEnergia, getIsSavingFontesEnergia, getIsUpdatingFontesEnergia } from 'store/modules/fontesEnergia/fontesEnergia.selectors'
import { validateFormValues } from 'utils/forms'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import { callCriarFonteEnergias, callExcluirFonteEnergias, callAtualizarFonteEnergias } from 'store/modules/fontesEnergia'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { TIPO_USUARIO } from 'utils/constantes'

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
  screenTitle: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(5),
    fontSize: theme.typography.pxToRem(24),
  },
  rowText: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(5),
    fontSize: theme.typography.pxToRem(14),
  },
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    width: (props) => props.fullWidth && '100%',
  },
  hidden: {
    display: 'none',
  },
  formAdd: {
    transition: '0.20s ease-out',
    maxHeight: 0,
    overflow: 'hidden',
  },
  openFormAdd: {
    paddingTop: 10,
    marginTop: '22px',
    maxHeight: '1300px',
    transition: '0.25s ease-in',
  },
  normalTop: {
    marginTop: '16px',
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '0 !important',
  },
  itemAlignRight: {
    maxWidth: '190px',
  },
  listItems: {
    marginTop: '32px',
    border: '1px solid #C9CEDB',
    boxShadow: '0px 8px 32px rgba(15, 52, 68, 0.08)',
    borderRadius: '8px',
    padding: '0px 16px',
  },
  itemTitle: {
    fontFamily: 'Poppins',
    color: '#0F3444',
    fontWeight: '600',
    fontSize: '16px',
  },
  divider: {
    height: 1,
    width: 'calc(100% + 32px)',
    marginLeft: '-16px',
    backgroundColor: theme.palette.grey.main,
  },
  dividerField: {
    borderBottom: `1px solid ${theme.palette.grey.main}`,
    margin: '8px 0',
    display: 'flex',
    alignItems: 'center',
  },
}))

const FontesEnergia = ({ paisId }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const dialog = useConfirmationDialog()

  const isSaving = useSelector(getIsSavingFontesEnergia)
  const isUpdating = useSelector(getIsUpdatingFontesEnergia)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const desabilitarFormulario = Boolean(tipoUsuarioLogado === TIPO_USUARIO.ADMIN.OBSERVADOR)

  const filterScreen = [
    { value: true, label: 'filtros.nomeAZ', name: 'order' },
    { value: false, label: 'filtros.nomeZA', name: 'order' },
  ]
  const listaFontesEnergia = useSelector(getFontesEnergia)

  const [data, setData] = useState(listaFontesEnergia)
  const [controlAdded, setControlAdded] = useState(false)

  const validation = yup.object({
    alteracaoDisponibilidadeHidrica: yup.number().required(),
    alteracaoEstruturaEcossistema: yup.number().required(),
    alteracaoHabitat: yup.number().required(),
    alteracaoPaisagem: yup.number().required(),
    alteracaoQualidadeAgua: yup.number().required(),
    alteracaoQualidadeAr: yup.number().required(),
    alteracaoQualidadeSolo: yup.number().required(),
    contribuicaoMudancaClima: yup.number().required(),
    fonteEnergetica: yup.string().required(),
    geracaoSismos: yup.number().required(),
    indiceImpacto: yup.number().required(),
    intensificacaoAssoreamento: yup.number().required(),
    intensificacaoErosao: yup.number().required(),
    percentualRede: yup.number().required(),
  })

  const handleUpdate = (values) => {
    const { id } = values
    const payload = ({ ...values, paisId })
    delete payload.id
    dispatch(callAtualizarFonteEnergias({ id, fonteEnergia: payload }))
  }

  const handleSave = (values, form) => {
    const payload = ({ ...values, paisId })
    dispatch(callCriarFonteEnergias(payload))
    form.reset()
    Object.keys(values).forEach((key) => {
      form.resetFieldState(key)
    })
  }

  const handleDelete = (id, fonteEnergia) => dialog.showConfirmation({
    title: t('dialogs.confirmarExclusao'),
    message: t('dialogs.excluirFonteEnergia', {
      fonteEnergia,
    }),
  }).then((result) => {
    if (result) {
      dispatch(callExcluirFonteEnergias({ id }))
    }
    return null
  })

  const [openItem, setOpenItem] = useState('')

  const handleExpand = (item) => {
    setOpenItem(openItem === item ? '' : item)
  }

  const clearFields = (form, values, type) => {
    if (type === 'add') {
      setControlAdded(!controlAdded)
    }
    form.reset()
    Object.keys(values).forEach((key) => {
      form.resetFieldState(key)
    })
  }

  const formFields = [
    {
      name: 'fonteEnergetica',
      label: t('paisesCalculos.fonteEnergetica'),
      required: true,
    },
    {
      name: 'alteracaoDisponibilidadeHidrica',
      label: t('paisesCalculos.alteracaoDisponibilidadeHidrica'),
      required: true,
    },
    {
      name: 'alteracaoQualidadeAgua',
      label: t('paisesCalculos.alteracaoQualidadeAgua'),
      required: true,
    },
    {
      name: 'contribuicaoMudancaClima',
      label: t('paisesCalculos.contribuicaoMudancaClima'),
      required: true,
    },
    {
      name: 'alteracaoQualidadeAr',
      label: t('paisesCalculos.alteracaoQualidadeAr'),
      required: true,
    },
    {
      name: 'intensificacaoAssoreamento',
      label: t('paisesCalculos.intensificacaoAssoreamento'),
      required: true,
    },
    {
      name: 'intensificacaoErosao',
      label: t('paisesCalculos.intensificacaoErosao'),
      required: true,
    },
    {
      name: 'geracaoSismos',
      label: t('paisesCalculos.geracaoSismos'),
      required: true,
    },
    {
      name: 'alteracaoPaisagem',
      label: t('paisesCalculos.alteracaoPaisagem'),
      required: true,
    },
    {
      name: 'alteracaoQualidadeSolo',
      label: t('paisesCalculos.alteracaoQualidadeSolo'),
      required: true,
    },
    {
      name: 'alteracaoHabitat',
      label: t('paisesCalculos.alteracaoHabitat'),
      required: true,
    },
    {
      name: 'alteracaoEstruturaEcossistema',
      label: t('paisesCalculos.alteracaoEstruturaEcossistema'),
      required: true,
    },
    {
      name: 'indiceImpacto',
      label: t('paisesCalculos.indiceImpacto'),
      required: true,
    },
    {
      name: 'percentualRede',
      label: t('paisesCalculos.percentualRede'),
      required: true,
    },
  ]

  const FieldsForm = () => (
    formFields.map((elem, index) => (
      <Fragment key={index}>
        {index === 0 ? (
          <Grid item xs={12}>
            <FieldText
              required={elem.required}
              label={elem.label}
              name={elem.name}
              disabled={desabilitarFormulario}
            />
          </Grid>
        ) : (
          <>
            <Grid container spacing={2} className={classes.dividerField}>
              <Grid item xs={9}>
                <Typography className={classes.rowText}>
                  {`${elem.label} ${elem.required ? ' *' : ''} `}
                </Typography>
              </Grid>
              <Grid item xs={3} className={classes.alignRight}>
                <FieldText
                  type="number"
                  name={elem.name}
                  className={classes.itemAlignRight}
                  InputProps={{
                    inputProps: {
                      style: { textAlign: 'center' },
                    },
                  }}
                  disabled={desabilitarFormulario}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Fragment>
    ))
  )

  const ItemForm = (position) => {
    const itemFonteEnergia = [data[position]]
    return (
      <Form
        onSubmit={handleUpdate}
        validate={validateFormValues(validation)}
        initialValues={{
          alteracaoDisponibilidadeHidrica: itemFonteEnergia[0]?.alteracaoDisponibilidadeHidrica ?? '',
          alteracaoEstruturaEcossistema: itemFonteEnergia[0]?.alteracaoEstruturaEcossistema ?? '',
          alteracaoHabitat: itemFonteEnergia[0]?.alteracaoHabitat ?? '',
          alteracaoPaisagem: itemFonteEnergia[0]?.alteracaoPaisagem ?? '',
          alteracaoQualidadeAgua: itemFonteEnergia[0]?.alteracaoQualidadeAgua ?? '',
          alteracaoQualidadeAr: itemFonteEnergia[0]?.alteracaoQualidadeAr ?? '',
          alteracaoQualidadeSolo: itemFonteEnergia[0]?.alteracaoQualidadeSolo ?? '',
          contribuicaoMudancaClima: itemFonteEnergia[0]?.contribuicaoMudancaClima ?? '',
          fonteEnergetica: itemFonteEnergia[0]?.fonteEnergetica ?? '',
          geracaoSismos: itemFonteEnergia[0]?.geracaoSismos ?? '',
          indiceImpacto: itemFonteEnergia[0]?.indiceImpacto ?? '',
          intensificacaoAssoreamento: itemFonteEnergia[0]?.intensificacaoAssoreamento ?? '',
          intensificacaoErosao: itemFonteEnergia[0]?.intensificacaoErosao ?? '',
          percentualRede: itemFonteEnergia[0]?.percentualRede ?? '',
          id: itemFonteEnergia[0]?.id ?? '',
        }}
        render={({ handleSubmit, form, pristine, valid }) => (
          <form onSubmit={handleSubmit}>
            <Accordion
              hideButton
              variant="no-borders"
              classes={{ title: classes.itemTitle }}
              title={itemFonteEnergia[0]?.fonteEnergetica}
              expanded={position === openItem}
              onExpand={() => handleExpand(position)}
              onDelete={() => {
                handleDelete(
                  itemFonteEnergia[0]?.id,
                  itemFonteEnergia[0]?.fonteEnergetica,
                )
                handleExpand('')
              }}
              disableDelete={desabilitarFormulario}
            >
              <Grid container>
                { FieldsForm() }
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    className={classes.normalTop}
                  >
                    <Grid item className={classes.deleteButton}>
                      <Button
                        variant="danger"
                        onClick={() => { form.reset(); handleExpand('') }}
                      >
                        {t('inputs.cancelar')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        disabled={Boolean(pristine || !valid) || desabilitarFormulario}
                        loading={isUpdating}
                      >
                        {t('inputs.salvar')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Accordion>
            <div className={classes.divider} />
          </form>
        )}
      />
    )
  }

  useEffect(() => {
    setData(listaFontesEnergia)
  }, [listaFontesEnergia])

  return (
    <>
      <div className={classes.sectionTitle}>
        <Typography className={classes.screenTitle}>
          {t('paisesCalculos.TabelaFontesEnergeticas')}
        </Typography>
        <Button
          variant="default"
          disabled={controlAdded || desabilitarFormulario}
          onClick={() => {
            setControlAdded(true)
            handleExpand('')
          }}
        >
          {t('paisesCalculos.AdicionarFonteEnergia')}
        </Button>
      </div>
      <div className={clsx(classes.search, controlAdded && classes.hidden)}>
        <Filters
          fullWidth
          items={listaFontesEnergia}
          setItems={setData}
          origin={{ vertical: 'top', horizontal: 'right' }}
          filterScreen={filterScreen}
          nameSearch="fonteEnergetica"
        />
      </div>
      <div
        className={clsx(
          classes.formAdd,
          controlAdded && classes.openFormAdd,
        )}
      >
        <Form
          onSubmit={handleSave}
          initialValues={{
            alteracaoDisponibilidadeHidrica: '0',
            alteracaoEstruturaEcossistema: '0',
            alteracaoHabitat: '0',
            alteracaoPaisagem: '0',
            alteracaoQualidadeAgua: '0',
            alteracaoQualidadeAr: '0',
            alteracaoQualidadeSolo: '0',
            contribuicaoMudancaClima: '0',
            fonteEnergetica: '',
            geracaoSismos: '0',
            indiceImpacto: '0',
            intensificacaoAssoreamento: '0',
            intensificacaoErosao: '0',
            percentualRede: '0',
          }}
          validate={validateFormValues(validation)}
          render={({ handleSubmit, form, values, valid }) => (
            <form onSubmit={handleSubmit}>
              <Grid container>
                { FieldsForm() }
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    className={classes.normalTop}
                  >
                    <Grid item className={classes.deleteButton}>
                      <Button
                        variant="danger"
                        onClick={(e) => {
                          e.preventDefault()
                          clearFields(form, values, 'add')
                        }}
                      >
                        {t('inputs.cancelar')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        disabled={!valid}
                        loading={isSaving}
                      >
                        {t('inputs.salvar')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </div>
      <div className={classes.listItems}>
        {data.length > 0 &&
          data.map((elem, index) => (
            <Fragment key={index}>
              {ItemForm(index)}
            </Fragment>
          ))}
      </div>
    </>
  )
}

FontesEnergia.propTypes = {
  paisId: PropTypes.number,
}

export default FontesEnergia
