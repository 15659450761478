import { TIPO_INDICADORES } from 'utils/constantes'

const getIndicators = (items = []) => {
  const essential = items.filter((item) => item.essencial)
  const general = items.filter((item) => !item.essencial)
  return { essential, general }
}

const getIndicatorStatus = (indicators, status) => (
  indicators.filter((item) => {
    if (status === TIPO_INDICADORES.NAO_PREENCHIDO && !item.indicadorEmpresa) return true
    return item.indicadorEmpresa?.situacao === status
  }).length || 0
)

const getIndicatorsStatus = (indicators = []) => ({
  atendido: getIndicatorStatus(indicators, TIPO_INDICADORES.ATENDIDO),
  parcialmenteAtendido: getIndicatorStatus(indicators, TIPO_INDICADORES.PARCIALMENTE_ATENDIDO),
  naoAtendido: getIndicatorStatus(indicators, TIPO_INDICADORES.NAO_ATENDIDO),
  naoPreenchidos: getIndicatorStatus(indicators, TIPO_INDICADORES.NAO_PREENCHIDO),
  naoAplicavel: getIndicatorStatus(indicators, TIPO_INDICADORES.NAO_APLICAVEL),
  total: indicators.length,
})

const getIndicatorsPercentage = ({ total, value }) => {
  const result = (value * 100) / total
  return Math.round(result)
}

export const getIndicatorsInfo = (items = []) => {
  const flatItems = items
    ?.flatMap((item) => item.criterios)
    ?.flatMap((item) => item.indicadores)
  const { essential, general } = getIndicators(flatItems)
  return {
    essencial: getIndicatorsStatus(essential),
    geral: getIndicatorsStatus(general),
  }
}

export const getIndicatorsStats = ({ essencial, geral }) => ({
  indicadoresEssenciaisGerais: getIndicatorsPercentage({
    total: essencial.total + geral.total - geral.naoAplicavel - essencial.naoAplicavel,
    value: (
      essencial.atendido +
      essencial.parcialmenteAtendido +
      geral.atendido +
      geral.parcialmenteAtendido
    ),
  }),
  indicadoresEssenciais: getIndicatorsPercentage({
    total: essencial.total - essencial.naoAplicavel,
    value: essencial.atendido + essencial.parcialmenteAtendido,
  }),
  indicadoresGerais: getIndicatorsPercentage({
    total: geral.total - geral.naoAplicavel,
    value: geral.atendido + geral.parcialmenteAtendido,
  }),
})

export default {}
