import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { MenuItem, makeStyles } from '@material-ui/core'
import Input from './BaseInput'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    '& .MuiSelect-iconOutlined, & .MuiInputLabel-outlined, & .MuiSelect-root': {
      color: theme.palette.common.white,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.common.white} !important`,
    },
  },
}))

const FieldSelect = ({ options, theme, className, ...props }) => {
  const classes = useStyles()
  const isLightTheme = theme === 'light'

  return (
    <Input {...props} className={clsx(className, isLightTheme && classes.wrapper)} select>
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value || option.label}>
          {option.label}
        </MenuItem>
      ))}
    </Input>
  )
}

FieldSelect.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  theme: PropTypes.oneOf(['light', 'dark']),
}

export default FieldSelect
