import React, { useRef, useState } from 'react'
import ContentBox from 'components/ContentBox'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import PageTitle from 'components/PageTitle'
import Button from 'components/Button'
import { NavLink } from 'react-router-dom'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { TIPO_USUARIO } from 'utils/constantes'
import { useSelector } from 'react-redux'
import ListaAuditores from './ListaAuditores'

const useStyles = makeStyles((theme) => ({
  avatar: {
    display: 'flex',
    alignItems: 'center',
  },
  avatarText: {
    marginLeft: theme.spacing(2),
  },
  contentBox: {
    padding: theme.spacing(2),
  },
  header: {
    display: 'flex',
    alignItems: 'flex-start',
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
  headerButton: {
    fontSize: 14,
    display: 'flex',
    position: 'relative',
    textDecoration: 'none',
    justifyContent: 'center',
    textTransform: 'uppercase',
    marginRight: theme.spacing(3),
    color: theme.palette.grey.blue,
    padding: theme.spacing('6px', 2, '21px'),
    '&::after': {
      bottom: 0,
      opacity: 0,
      width: '65%',
      content: '""',
      display: 'block',
      position: 'absolute',
      height: theme.spacing(0.5),
      borderRadius: theme.spacing(4, 4, 0, 0),
      backgroundColor: theme.palette.blue.main,
      transition: theme.transitions.create('opacity'),
    },
  },
  headerButtonActive: {
    color: theme.palette.blue.main,
    '&::after': {
      opacity: 1,
    },
  },
  headerAction: {
    marginLeft: 'auto',
    padding: theme.spacing(1, 2),
  },
}))

const AuditoresPage = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const navigationItems = [
    { label: 'menuItemsAdm.auditoria', to: '/adm/auditores' },
  ]

  const [controlAdded, setControlAdded] = useState(false)
  const childRef = useRef()

  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  return (
    <>
      <PageTitle title={t('menuItemsAdm.auditoria')} />
      <ContentBox className={classes.contentBox}>
        <div className={classes.header}>
          {navigationItems.map((item) => (
            <NavLink
              exact
              to={item.to}
              key={item.label}
              className={classes.headerButton}
              activeClassName={classes.headerButtonActive}
            >
              {t(item.label)}
            </NavLink>
          ))}
          {tipoUsuarioLogado !== TIPO_USUARIO.ADMIN.OBSERVADOR && (
            <Button
              disabled={controlAdded}
              small
              onClick={() => { childRef.current.handleButtonAdded() }}
              className={classes.headerAction}
            >
              {t('inputs.adicionarAuditor')}
            </Button>
          )}
        </div>
        <ListaAuditores
          controlAdded={controlAdded}
          setControlAdded={setControlAdded}
          ref={childRef}
        />
      </ContentBox>
    </>
  )
}

export default AuditoresPage
