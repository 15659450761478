const ACTION_PATH = 'app/classesIrcAdmin'

export const CALL_SALVAR_IRC_ADMIN = `${ACTION_PATH}/CALL_SALVAR_IRC_ADMIN`
export const CALL_EXCLUIR_CLASSE_IRC_ADMIN = `${ACTION_PATH}/CALL_EXCLUIR_CLASSE_IRC_ADMIN`
export const CALL_ATUALIZAR_CLASSE_IRC_ADMIN = `${ACTION_PATH}/CALL_ATUALIZAR_CLASSE_IRC_ADMIN`

export const callSalvarIrcAdmin = (payload) => ({
  type: CALL_SALVAR_IRC_ADMIN,
  payload,
})

export const callAtualizarClasseIrcAdmin = (payload) => ({
  type: CALL_ATUALIZAR_CLASSE_IRC_ADMIN,
  payload,
})

export const callExcluirClasseIrcAdmin = ({ id }) => ({
  type: CALL_EXCLUIR_CLASSE_IRC_ADMIN,
  payload: { id },
})
