import React, { useState } from 'react'
import {
  Grid,
  Typography,
  makeStyles,
  AppBar,
  Box,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { FieldArray } from 'react-final-form-arrays'
import { validateFormValues } from 'utils/forms'
import arrayMutators from 'final-form-arrays'
import { Form as FinalForm, FormSpy } from 'react-final-form'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { useSelector, useDispatch } from 'react-redux'
import * as yup from 'yup'
import { map, isEmpty, head } from 'lodash'
import { getSetores } from 'store/modules/setores/setores.selectors'
import { getTipoAtividades } from 'store/modules/tipoAtividades/tipoAtividades.selectors'
import { callSalvarListaUnidade } from 'store/modules/unidades'
import { getUnidades } from 'store/modules/unidades/unidades.selectors'
import { useTranslation } from 'react-i18next'
import { getDivisoesSetores } from 'store/modules/divisoesSetores/divisoesSetores.selectors'
import { FieldAutocomplete, FieldText } from 'components/Fields'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { TIPO_USUARIO } from 'utils/constantes'
import Button from 'components/Button'
import Tab from 'components/Tabs/Tab'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(7, 0, 2),
  },
  container: {
    maxWidth: '672px',
    margin: '0 auto',
  },
  root: {
    width: '100%',
  },
  title: {
    fontSize: '32px',
    fontWeight: '400',
    color: '#0F3444',
  },
  subtitle: {
    marginBottom: theme.spacing(2),
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '400',
    color: '#0F3444',
  },
  accordionRoot: {
    backgroundColor: '#8997B1',
    color: 'white',
    height: '43px',
    minHeight: '43px',
  },
  content: {
    flexGrow: 0,
  },
  expandIcon: {
    order: -1,
    marginRight: '0',
    color: 'white',
  },
  accordionExpanded: {
    minHeight: 'unset !important',
  },
  formContainer: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    padding: theme.spacing(4),
    margin: theme.spacing(0, 0, 2, 0),
    '& .MuiTabPanel-root': {
      padding: '40px 0 0',
    },
    '& .Mui-selected': {
      color: '#31ADE3',
    },
    '& .MuiAppBar-root': {
      borderBottom: '1px solid #C9CEDB',
    },
  },
  tabPanel: {
    padding: 0,
  },
  addDivision: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  btnAdd: {
    border: '1px solid #C9CEDB',
    height: theme.spacing(5),
    textTransform: 'capitalize',
  },
  tabList: {
    marginBottom: theme.spacing(5),
    borderBottom: '1px solid #C9CEDB',
    '& .MuiTab-root': {
      color: '#78839D',
      fontSize: '14px',
      minWidth: 'auto',
      '&.Mui-selected': {
        color: '#31ADE3',
      },
    },
  },
  tabIndicator: {
    height: 4,
    transform: 'scaleX(0.8)',
    borderRadius: theme.spacing(2, 2, 0, 0),
    backgroundColor: theme.palette.blue.main,
  },
}))

const EmpresaUnidadeDetalhe = ({ Botoes, setChangeTab }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const listaUnidades = useSelector(getUnidades)
  const listaSetores = useSelector(getSetores)
  const listaTipoAtividades = useSelector(getTipoAtividades)
  const listaDivisoesSetores = useSelector(getDivisoesSetores)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const [tabSelecionado, setTabSelecionado] = useState(isEmpty(listaUnidades) ? '0' : `${head(listaUnidades)?.nome}0`)
  const [lastPositionTab, setLastPositionTab] = useState('0')

  const handleSave = (values) => {
    const data = map(values.unidades, (unidade) => ({
      ...unidade,
      uf: undefined,
      municipio: undefined,
    }))
    dispatch(callSalvarListaUnidade({ lista: data, successNotification: 'mensagens.criado' }))
  }

  const handleChange = () => ((e, newValue) => {
    setTabSelecionado(newValue)
  })

  const listaDivisaoSeletoresFiltered = listaDivisoesSetores?.filter((divisao) => (
    divisao?.possuiUnidade
  ))

  const initialValues = [{
    divisaoSetorId: '',
    nome: '',
    setorId: '',
    cep: '',
    endereco: '',
    nomeUf: '',
    nomePais: '',
    nomeMunicipio: '',
    tipoAtividadeId: '',
    escopo: '',
    informacoesAdicionais: '',
  },
  ]

  return (
    <Box className={classes.container}>
      <FinalForm
        initialValues={{
          unidades: isEmpty(listaUnidades) ? initialValues : listaUnidades,
        }}
        validate={
          validateFormValues(
            yup.object().shape({
              unidades: yup.array().of(
                yup.object().shape({
                  nome: yup.string().required(),
                  divisaoSetorId: yup.number().required(),

                  tipoAtividadeId: yup.number().required(),
                  endereco: yup.string().required(),
                  cep: yup.string().required(),
                  nomeUf: yup.string().required(),
                  nomePais: yup.string().required(),
                  nomeMunicipio: yup.string().required(),
                  setorId: yup.number().required(),
                  informacoesAdicionais: yup.string(),
                  escopo: yup.string(),
                }),
              ),
            }),
          )
        }
        mutators={{ ...arrayMutators }}
        onSubmit={(values) => {
          handleSave(values)
          setTimeout(() => {
            setTabSelecionado(values.unidades[Number(lastPositionTab)].nome +
              lastPositionTab)
          }, 700)
        }}
        render={({
          handleSubmit,
          errors,
          values,
          pristine,
          valid,
          form: { mutators: { push } },
        }) => (
          <form>
            <FormSpy
              onChange={({ values: valuesSpy, initialValues: initialValuesSpy }) => {
                setChangeTab({
                  values: valuesSpy,
                  initialValues: initialValuesSpy,
                })
              }}
              subscription={{ values: true, initialValues: true }}
            />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FieldArray name="unidades" validateOnChange>
                  {() => (
                    <>
                      {tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LIDER && (
                        <Grid container className={classes.addDivision}>
                          <Button
                            onClick={() => {
                              push('unidades', {
                                divisaoSetorId: '',
                                nome: '',
                                setorId: '',
                                cep: '',
                                endereco: '',
                                nomeUf: '',
                                nomePais: '',
                                nomeMunicipio: '',
                                tipoAtividadeId: '',
                                escopo: '',
                                informacoesAdicionais: '',
                              })
                              setTabSelecionado(String(values.unidades?.length))
                              setLastPositionTab(values.unidades?.length)
                            }}
                            className={classes.btnAdd}
                          >
                            {t('unidade.adicionarUnidade')}
                          </Button>
                        </Grid>
                      )}
                      <TabContext value={tabSelecionado}>
                        <AppBar position="static" color="transparent" elevation={0} component="nav">
                          <TabList
                            onChange={handleChange()}
                            className={classes.tabList}
                            aria-label="lista de unidades"
                            variant="scrollable"
                            scrollButtons="auto"
                            classes={{ indicator: classes.tabIndicator }}
                          >
                            {map(values.unidades, (unidade, i) => (
                              <Tab
                                label={listaUnidades?.[i]?.id ? listaUnidades?.[i]?.nome : `${t('unidade.unidade')} ${i + 1}`}
                                value={(listaUnidades?.[i]?.nome ?? '') + i.toString()}
                                key={(listaUnidades?.[i]?.nome ?? '') + i.toString()}
                                disableRipple
                                onClick={() => {
                                  setLastPositionTab(String(i))
                                }}
                              />
                            ))}
                          </TabList>
                        </AppBar>
                        {map(values.unidades, (unidade, i) => (
                          <TabPanel
                            value={(listaUnidades?.[i]?.nome ?? '') + i.toString()}
                            key={(listaUnidades?.[i]?.nome ?? '') + i.toString()}
                            className={classes.tabPanel}
                          >
                            <Typography component="p" className={classes.subtitle}>
                              {t('cadastroInicial.dadosGeraisDaUnidade')}
                            </Typography>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <FieldAutocomplete
                                  required
                                  name={`unidades.${i}.divisaoSetorId`}
                                  label={t('inputs.selecioneDivisaoSetorPertencente')}
                                  options={(listaDivisaoSeletoresFiltered || []).map((item) => ({
                                    value: item.id,
                                    label: item.nome,
                                  }))}
                                  disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <FieldText
                                  required
                                  name={`unidades.${i}.nome`}
                                  label={t('inputs.nomeUnidade')}
                                  disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                                />
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                <FieldText
                                  required
                                  name={`unidades.${i}.nomePais`}
                                  label={t('inputs.pais')}
                                  placeholder={t('inputs.pais')}
                                  disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                                />
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                <FieldText
                                  required
                                  name={`unidades.${i}.nomeUf`}
                                  label={t('inputs.estado')}
                                  placeholder={t('inputs.estado')}
                                  disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                                />
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                <FieldText
                                  required
                                  name={`unidades.${i}.nomeMunicipio`}
                                  label={t('inputs.municipio')}
                                  placeholder={t('inputs.municipio')}
                                  disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FieldText
                                  required
                                  name={`unidades.${i}.cep`}
                                  label={t('inputs.cep')}
                                  disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <FieldText
                                  required
                                  name={`unidades.${i}.endereco`}
                                  label={t('inputs.endereco')}
                                  disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FieldAutocomplete
                                  required
                                  name={`unidades.${i}.setorId`}
                                  label={t('inputs.setor')}
                                  options={(listaSetores || []).map((item) => ({
                                    value: item.id,
                                    label: t(item.nome),
                                  }))}
                                  disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FieldAutocomplete
                                  required
                                  name={`unidades.${i}.tipoAtividadeId`}
                                  label={t('inputs.tipoAtividade')}
                                  options={(listaTipoAtividades || []).map((item) => ({
                                    value: item.id,
                                    label: t(item.nome),
                                  }))}
                                  disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <FieldText
                                  name={`unidades.${i}.escopo`}
                                  label={t('inputs.escopo')}
                                  disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <FieldText
                                  name={`unidades.${i}.informacoesAdicionais`}
                                  label={t('inputs.adicioneInformacoesAdicionais')}
                                  multiline
                                  minRows={5}
                                  maxRows={5}
                                  disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                                />
                              </Grid>
                            </Grid>
                          </TabPanel>
                        ))}
                      </TabContext>
                      {tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LIDER && (
                        <>
                          {Botoes && (
                            <Box mt={7}>
                              <Botoes extraFunc={handleSubmit} formErrors={errors} />
                            </Box>
                          )}
                          {!Botoes && (
                            <Grid container justifyContent="flex-end" className={classes.submit}>
                              <Button
                                onClick={handleSubmit}
                                disabled={pristine || !valid}
                              >
                                {t('inputs.salvar')}
                              </Button>
                            </Grid>
                          )}
                        </>
                      )}
                    </>
                  )}
                </FieldArray>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Box>
  )
}

EmpresaUnidadeDetalhe.propTypes = {
  Botoes: PropTypes.func,
  setChangeTab: PropTypes.func,
}

export default EmpresaUnidadeDetalhe
