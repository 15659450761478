import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map, ignoreElements } from 'rxjs/operators'
import {
  apiGetRelatorioIndicadoresGestaoDetalhado,
  apiGetRelatorioIndicadoresGestaoResumido,
  apiGetRelatorioIndicadoresGestaoGeral,
  API_GET_RELATORIO_INDICADORES_GESTAO_DETALHADO,
  API_GET_RELATORIO_INDICADORES_GESTAO_RESUMIDO,
  API_GET_RELATORIO_INDICADORES_GESTAO_GERAL,
} from 'store/modules/api/relatorios/relatoriosIndicadoresGestao.actions'
import { saveAs } from 'file-saver'
import {
  CALL_RELATORIO_INDICADORES_GESTAO_DETALHADO,
  CALL_RELATORIO_INDICADORES_GESTAO_RESUMIDO,
  CALL_RELATORIO_INDICADORES_GESTAO_GERAL,
} from './relatoriosIndicadoresGestao.actions'

const onCallRelatorioIndicadoresGestaoDetalhado = (action$) => action$.pipe(
  ofType(CALL_RELATORIO_INDICADORES_GESTAO_DETALHADO),
  debounceTime(250),
  map(({ payload }) => apiGetRelatorioIndicadoresGestaoDetalhado(payload)),
)

const onDownloadRelatorioIndicadoresGestaoDetalhado = (action$) => action$.pipe(
  ofType(API_GET_RELATORIO_INDICADORES_GESTAO_DETALHADO.success),
  // map(({ payload }) => saveAs(payload.base64, `${payload.nome}.pdf`)),
  ignoreElements(),
)

const onCallRelatorioIndicadoresGestaoResumido = (action$) => action$.pipe(
  ofType(CALL_RELATORIO_INDICADORES_GESTAO_RESUMIDO),
  debounceTime(250),
  map(({ payload }) => apiGetRelatorioIndicadoresGestaoResumido(payload)),
)

const onDownloadRelatorioIndicadoresGestaoResumido = (action$) => action$.pipe(
  ofType(API_GET_RELATORIO_INDICADORES_GESTAO_RESUMIDO.success),
  map(({ payload }) => saveAs(payload.base64, `${payload.nome}.pdf`)),
  ignoreElements(),
)

const onCallRelatorioIndicadoresGestaoGeral = (action$) => action$.pipe(
  ofType(CALL_RELATORIO_INDICADORES_GESTAO_GERAL),
  debounceTime(250),
  map(({ payload }) => apiGetRelatorioIndicadoresGestaoGeral(payload)),
)

const onDownloadRelatorioIndicadoresGestaoGeral = (action$) => action$.pipe(
  ofType(API_GET_RELATORIO_INDICADORES_GESTAO_GERAL.success),
  // map(({ payload }) => saveAs(payload.base64, `${payload.nome}.pdf`)),
  ignoreElements(),
)

export default combineEpics(
  onCallRelatorioIndicadoresGestaoDetalhado,
  onCallRelatorioIndicadoresGestaoResumido,
  onCallRelatorioIndicadoresGestaoGeral,
  onDownloadRelatorioIndicadoresGestaoDetalhado,
  onDownloadRelatorioIndicadoresGestaoResumido,
  onDownloadRelatorioIndicadoresGestaoGeral,
)
