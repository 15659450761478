import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchMatrizResiduosActionTypes = apiActionTypes('matrizResiduos/API_LISTAR_MATRIZ_RESIDUOS')
const salvarNovasMatrizesActionTypes = apiActionTypes('matrizResiduos/API_SALVAR_NOVAS_MATRIZES')
const salvarMatrizResiduoActionTypes = apiActionTypes('matrizResiduos/API_SALVAR_MATRIZ_RESIDUO')
const deleteMatrizResiduoActionTypes = apiActionTypes('matrizResiduos/API_EXCLUIR_MATRIZ_RESIDUO')

export const API_LISTAR_MATRIZ_RESIDUOS = asApiTypesObject(fetchMatrizResiduosActionTypes)
export const API_SALVAR_NOVAS_MATRIZES = asApiTypesObject(salvarNovasMatrizesActionTypes)
export const API_SALVAR_MATRIZ_RESIDUO = asApiTypesObject(salvarMatrizResiduoActionTypes)
export const API_EXCLUIR_MATRIZ_RESIDUO = asApiTypesObject(deleteMatrizResiduoActionTypes)

export const apiListarMatrizResiduos = ({ paisId }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/matrizResiduo`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchMatrizResiduosActionTypes,
}, { ...meta, query: { paisId } })

export const apiSalvarNovasMatrizes = (lista, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/matrizResiduo/salvarNovasMatrizes`,
  method: 'POST',
  types: salvarNovasMatrizesActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(lista),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiSalvarMatrizResiduo = (matriz, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/matrizResiduo`,
  method: 'POST',
  types: salvarMatrizResiduoActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(matriz),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiExcluirMatrizResiduo = (id, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/matrizResiduo/${id}`,
  method: 'DELETE',
  types: deleteMatrizResiduoActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })
