import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import Button from 'components/Button'
import { find } from 'lodash'
import { Grid, makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { Form, FormSpy } from 'react-final-form'
import {
  callCriarIndicadorEmpresa,
  callAtualizarIndicadorEmpresa,
} from 'store/modules/indicadorEmpresa'
import { getIsSavingIndicadorEmpresa } from 'store/modules/indicadorEmpresa/indicadorEmpresa.selectors'
import { useTranslation } from 'react-i18next'
import { getEmpresaUsuario, getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { FieldText, FieldSelect, FieldUpload } from 'components/Fields'
import { TIPO_INDICADORES, TIPO_USUARIO } from 'utils/constantes'
import { validateFormValues } from 'utils/forms'
import { callListarIndicadorEmpresa } from 'store/modules/indicadorEmpresa/indicadorEmpresa.actions'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(4, 0, 0),
  },
}))

const situacoes = [
  { id: TIPO_INDICADORES.ATENDIDO, label: 'indicadoresGestao.atendido' },
  { id: TIPO_INDICADORES.NAO_ATENDIDO, label: 'indicadoresGestao.naoAtendido' },
  { id: TIPO_INDICADORES.PARCIALMENTE_ATENDIDO, label: 'indicadoresGestao.parcialmenteAtendido' },
  { id: TIPO_INDICADORES.NAO_PREENCHIDO, label: 'indicadoresGestao.naoPreenchido' },
  { id: TIPO_INDICADORES.NAO_APLICAVEL, label: 'indicadoresGestao.naoAplicavel' },
]

const IndicadorEmpresa = ({
  indicadorEmpresaParam,
  indicadorId,
  onSave,
  scrollToTop,
  anoSelecionado,
  setSalvando,
  setChangeTab,
  canResetForm,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const empresa = useSelector(getEmpresaUsuario)
  const isSaving = useSelector(getIsSavingIndicadorEmpresa)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const [indicadorEmpresa, setIndicadorEmpresa] = useState({})
  let resetForm

  useEffect(() => {
    if (!canResetForm) return
    setTimeout(() => {
      resetForm[0]()
      Object.keys(resetForm[2]).forEach((key) => {
        resetForm[1](key)
      })
    }, 500)

    setIndicadorEmpresa({
      ...indicadorEmpresaParam,
      situacao: find(situacoes, { id: indicadorEmpresaParam.situacao }),
    })
  }, [indicadorEmpresaParam, resetForm, canResetForm])

  const handleSave = (values, form) => {
    setSalvando(true)
    scrollToTop()
    const data = {
      ...indicadorEmpresa,
      ...values,
      indicadorId,
      empresaId: empresa.id,
      empresa: undefined,
      ano: anoSelecionado,
    }
    if (indicadorEmpresaParam.id) {
      dispatch(callAtualizarIndicadorEmpresa({
        id: indicadorEmpresaParam.id,
        indicadorEmpresa: data,
      }))
    } else {
      dispatch(callCriarIndicadorEmpresa(data))
    }
    if (onSave) {
      onSave({ ...indicadorEmpresa, ...data, situacao: values?.situacao }, indicadorId)
    }

    Object.keys(values).forEach((key) => {
      form.resetFieldState(key)
    })
  }

  return (
    <Form
      onSubmit={handleSave}
      validate={validateFormValues(yup.object().shape({
        situacao: yup.string().required(),
        responsavel: yup.string().required(),
        setor: yup.string().required(),
      }))}
      initialValues={{
        situacao: indicadorEmpresaParam?.situacao ?? null,
        responsavel: indicadorEmpresaParam?.responsavel ?? '',
        setor: indicadorEmpresaParam?.setor ?? '',
        comentarioOrganizacao: indicadorEmpresaParam?.comentarioOrganizacao ?? '',
        evidenciaAcaoOrganizacao: indicadorEmpresaParam?.evidenciaAcaoOrganizacao ?? '',
        anexo: indicadorEmpresaParam?.anexo ?? null,
      }}
      render={({ handleSubmit,
        form, form: { reset, resetFieldState },
        values,
        pristine,
        valid }) => {
        resetForm = [reset, resetFieldState, values]
        return (
          <form onSubmit={handleSubmit}>
            <FormSpy
              onChange={({ values: valuesSpy, initialValues }) => {
                setChangeTab({
                  values: valuesSpy,
                  initialValues,
                })
              }}
              subscription={{ values: true, initialValues: true }}
            />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FieldSelect
                  required
                  name="situacao"
                  label={t('inputs.situacao')}
                  options={(situacoes || []).map(({ label, id }) => ({
                    label: t(label),
                    value: id,
                  }))}
                  disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldText
                  required
                  name="responsavel"
                  label={t('inputs.responsavel')}
                  disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldText
                  required
                  name="setor"
                  label={t('inputs.setorResponsavel')}
                  disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldText
                  name="comentarioOrganizacao"
                  label={t('indicadoresGestao.comentariosReferentesIndicador')}
                  multiline
                  minRows={5}
                  maxRows={5}
                  disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldText
                  name="evidenciaAcaoOrganizacao"
                  label={t('indicadoresGestao.evidenciaIndicador')}
                  multiline
                  minRows={5}
                  maxRows={5}
                  disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldUpload
                  name="anexo"
                  disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                  onDelete={() => {
                    setTimeout(() => {
                      dispatch(callListarIndicadorEmpresa({
                        empresaId: empresa.id, ano: anoSelecionado, paisId: empresa.pais.id,
                      }))
                    }, 1000)
                  }}
                />
              </Grid>
              {tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LEITOR && (
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="space-between"
                  className={classes.submit}
                >
                  <Button
                    disabled={pristine || !valid}
                    variant="danger"
                    onClick={() => {
                      reset()
                      Object.keys(values).forEach((key) => {
                        form.resetFieldState(key)
                      })
                    }}
                    type="button"
                  >
                    {t('inputs.cancelar')}
                  </Button>
                  <Button
                    disabled={pristine || !valid}
                    type="submit"
                    loading={isSaving}
                  >
                    {t('inputs.salvar')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </form>
        )
      }}
    />
  )
}

IndicadorEmpresa.propTypes = {
  indicadorEmpresaParam: PropTypes.object,
  indicadorId: PropTypes.number,
  onSave: PropTypes.func,
  scrollToTop: PropTypes.func,
  anoSelecionado: PropTypes.string,
  setSalvando: PropTypes.func,
  setChangeTab: PropTypes.func,
  canResetForm: PropTypes.bool,
}

export default IndicadorEmpresa
