import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Button,
  Grid,
  Typography,
  Paper,
  InputAdornment,
  IconButton,
  makeStyles,
  withStyles,
} from '@material-ui/core'
import * as yup from 'yup'
import { validateFormValues } from 'utils/forms'
import { FieldText } from 'components/Fields'
import { Form } from 'react-final-form'
import { callUserResetPassword } from 'store/modules/auth'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { push } from 'connected-react-router'
import EyeLineIcon from 'remixicon-react/EyeLineIcon'
import EyeOffLineIcon from 'remixicon-react/EyeOffLineIcon'
import background from 'assets/images/bg-instituto-life.png'
import logo from 'assets/images/login/Logo_LK_login.svg'

const StyledPaper = withStyles((theme) => ({
  root: {
    padding: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
}))(Paper)

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  reset: {
    color: theme.palette.primary.main,
    fontFamily: 'Poppins',
    fontWeight: '400',
    marginTop: 'clamp(64px, 75px, 86px)',
    fontSize: '2rem',
  },
  resetTitle: {
    color: theme.palette.primary.main,
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    marginTop: '8px',
    marginBottom: 'clamp(25px, 28px, 32px)',
  },
  image: {
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  form: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  langContainer: {
    position: 'absolute',
    bottom: 0,
  },
  slogan: {
    color: theme.palette.primary.main,
    fontFamily: 'Poppins',
    marginTop: '16px',
  },
  senha: {
    fontSize: '12px',
    fontFamily: 'Poppins',
    color: '#164C64',
    lineHeight: '16px',
    textTransform: 'inherit',
  },
  submitContainer: {
    marginBottom: '80px',
    marginTop: '20px',
  },
  button: {
    textTransform: 'none',
  },
  input: {
    marginTop: '16px',
    marginBottom: '16px',
  },
}))

const AlterarSenha = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const { token } = useParams()

  const handleOnSubmit = (values) => {
    dispatch(callUserResetPassword({ ...values, token }))
  }

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={12} sm={8} md={5} lg={4} component={StyledPaper} elevation={6} square>
        <Grid container direction="column" alignItems="center" justifyContent="center">
          <Grid xs={12} item style={{ textAlign: 'center' }}>
            <img src={logo} alt="Logo LIFE Key" style={{ maxHeight: '140px' }} />
            <Typography component="h1" variant="h5" className={classes.slogan}>
              {t('app.slogan')}
            </Typography>
          </Grid>
        </Grid>

        <Form
          onSubmit={handleOnSubmit}
          validate={validateFormValues(yup.object({
            senha: yup.string().required(),
            confirmarSenha: yup.string().when('senha', (senha, campo) => (senha ? campo.required().oneOf([yup.ref('senha')], t('alterarSenha.senhaIncompativel')) : campo)),
          }))}
          initialValues={{
            senha: '',
            confirmarSenha: '',
          }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <Typography component="h2" variant="h3" className={classes.reset}>
                {t('alterarSenha.alterarSenha')}
              </Typography>
              <Typography component="p" variant="subtitle1" className={classes.resetTitle}>
                {t('alterarSenha.titulo')}
              </Typography>
              <FieldText
                variant="outlined"
                name="senha"
                type={showPassword ? 'text' : 'password'}
                label={t('inputs.senha')}
                className={classes.input}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={(event) => event.preventDefault()}
                      >
                        {showPassword ? <EyeLineIcon /> : <EyeOffLineIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FieldText
                variant="outlined"
                name="confirmarSenha"
                type={showPassword ? 'text' : 'password'}
                label={t('inputs.confirmarSenha')}
                className={classes.input}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={(event) => event.preventDefault()}
                      >
                        {showPassword ? <EyeLineIcon /> : <EyeOffLineIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Grid container justifyContent="space-between" alignItems="center" className={classes.submitContainer}>
                <Grid item>
                  <Button
                    type="submit"
                    variant="outlined"
                    color="default"
                    className={classes.button}
                  >
                    {t('alterarSenha.alterar')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={() => dispatch(push('/login'))} className={classes.senha}>
                    {t('alterarSenha.voltarLogin')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </Grid>
      <Grid item xs={false} sm={4} md={7} lg={8} className={classes.image} />
    </Grid>
  )
}

export default AlterarSenha
