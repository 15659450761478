import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchTipoAtividadesActionTypes = apiActionTypes('tipoAtividades/API_LISTAR_TIPO_ATIVIDADES')
const createTipoAtividadesActionTypes = apiActionTypes('tipoAtividades/API_CRIAR_TIPO_ATIVIDADES')
const deleteTipoAtividadesActionTypes = apiActionTypes('tipoAtividades/API_EXCLUIR_TIPO_ATIVIDADES')
const updateTipoAtividadesActionTypes = apiActionTypes('tipoAtividades/API_ATUALIZAR_TIPO_ATIVIDADES')

export const API_LISTAR_TIPO_ATIVIDADES = asApiTypesObject(fetchTipoAtividadesActionTypes)
export const API_CRIAR_TIPO_ATIVIDADES = asApiTypesObject(createTipoAtividadesActionTypes)
export const API_EXCLUIR_TIPO_ATIVIDADES = asApiTypesObject(deleteTipoAtividadesActionTypes)
export const API_ATUALIZAR_TIPO_ATIVIDADES = asApiTypesObject(updateTipoAtividadesActionTypes)

export const apiListarTipoAtividades = ({ paisId }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/tipoAtividades`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchTipoAtividadesActionTypes,
}, { ...meta, query: { paisId } })

export const apiCriarTipoAtividades = (tiposAtividade, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/tipoAtividades`,
  method: 'POST',
  types: createTipoAtividadesActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(tiposAtividade),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiExcluirTipoAtividades = ({ id }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/tipoAtividades/${id}`,
  method: 'DELETE',
  types: deleteTipoAtividadesActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })

export const apiAtualizarTipoAtividades = ({ id, tipoAtividade }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/tipoAtividades/${id}`,
  method: 'PUT',
  types: updateTipoAtividadesActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(tipoAtividade),
}, { ...meta, successNotification: 'mensagens.atualizado' })
