import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import { apiCriarResiduo, apiAtualizarResiduo, apiExcluirResiduo, apiGetResiduo } from 'store/modules/api/residuos.actions'
import { CALL_ATUALIZAR_RESIDUOS, CALL_CRIAR_RESIDUOS, CALL_EXCLUIR_RESIDUO, CALL_SELECIONAR_RESIDUO } from 'store/modules/residuos/residuos.actions'

const onSelected = (action$) => action$.pipe(
  ofType(CALL_SELECIONAR_RESIDUO),
  debounceTime(250),
  map(({ payload }) => apiGetResiduo(payload.id)),
)

const onCallCriarResiduos = (action$) => action$.pipe(
  ofType(CALL_CRIAR_RESIDUOS),
  debounceTime(250),
  map(({ payload }) => apiCriarResiduo(payload)),
)

const onCallAtualizarResiduos = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_RESIDUOS),
  debounceTime(250),
  map(({ payload }) => apiAtualizarResiduo(payload)),
)

const onCallExcluirResiduo = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_RESIDUO),
  debounceTime(250),
  map(({ payload }) => apiExcluirResiduo(payload)),
)

export default combineEpics(
  onCallCriarResiduos,
  onCallAtualizarResiduos,
  onSelected,
  onCallExcluirResiduo,
)
