import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from '../apiAction'

const getRelatorioGestaoDetalhadoActionTypes = apiActionTypes('relatorios/API_GET_RELATORIO_GESTAO_DETALHADO')
const getRelatorioGestaoResumidoActionTypes = apiActionTypes('relatorios/API_GET_RELATORIO_GESTAO_RESUMIDO')
const getRelatorioGestaoGeralActionTypes = apiActionTypes('relatorios/API_GET_RELATORIO_GESTAO_GERAL')

export const API_GET_RELATORIO_GESTAO_DETALHADO =
  asApiTypesObject(getRelatorioGestaoDetalhadoActionTypes)
export const API_GET_RELATORIO_GESTAO_RESUMIDO =
  asApiTypesObject(getRelatorioGestaoResumidoActionTypes)
export const API_GET_RELATORIO_GESTAO_GERAL =
  asApiTypesObject(getRelatorioGestaoGeralActionTypes)

export const apiGetRelatorioGestaoDetalhado = ({
  idioma, anoBase, empresaId,
}, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/relatorios/relatorioGestaoDetalhado`,
  method: 'GET',
  types: getRelatorioGestaoDetalhadoActionTypes,
}, { ...meta, query: { idioma, anoBase, empresaId } })

export const apiGetRelatorioGestaoResumido = ({
  idioma, anoBase, empresaId,
}, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/relatorios/relatorioGestaoResumido`,
  method: 'GET',
  types: getRelatorioGestaoResumidoActionTypes,
}, { ...meta, query: { idioma, anoBase, empresaId } })

export const apiGetRelatorioGestaoGeral = ({
  idioma, anoBase, empresaId,
}, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/relatorios/relatorioGestaoGeral`,
  method: 'GET',
  types: getRelatorioGestaoGeralActionTypes,
}, { ...meta, query: { idioma, anoBase, empresaId } })
