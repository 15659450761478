import { filter } from 'lodash'
import { VIEW_ADMIN, VIEW_AUDITOR, VIEW_CLIENT } from 'utils/constantes'

export const getTipoUsuarios = (state) => state.tipoUsuarios.lista
export const getTipoUsuariosAuditor = (state) => filter(state.tipoUsuarios.lista, (tipo) => (
  VIEW_AUDITOR.includes(tipo.id)
))
export const getTipoUsuariosAdmin = (state) => filter(state.tipoUsuarios.lista, (tipo) => (
  VIEW_ADMIN.includes(tipo.id)
))
export const getTipoUsuariosCliente = (state) => filter(state.tipoUsuarios.lista, (tipo) => (
  VIEW_CLIENT.includes(tipo.id)
))
export const getIsCarregandoListaTipoUsuarios = (state) => state.tipoUsuarios.isCarregandoLista
