import {
  Backdrop,
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput as MuiOutlinedInput,
  Select,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import Card from 'components/Card'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import MainColumn from 'components/MainColumn'
import PageTitle from 'components/PageTitle'
import Tab from 'components/Tabs/Tab'
import { find, get, map, range } from 'lodash'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import FileCopyLineIcon from 'remixicon-react/FileCopy2LineIcon'
import { callListarDadospressaoBiodiversidade } from 'store/modules/pressaoBiodiversidade/pressaoBiodiversidade.actions'
import { getAnoCarregado, getIndices, getIsLoading } from 'store/modules/pressaoBiodiversidade/pressaoBiodiversidade.selectors'
import { getUnidadeSelecionada, getUnidades } from 'store/modules/unidades/unidades.selectors'
import { DOCUMENTS_URL } from 'utils/constantes'
import { formatDecimal } from 'utils/numbers'
import RouterPrompt from 'components/AppRouter/RouterPrompt'
import { diff } from 'deep-object-diff'
import { isFormChanged } from 'utils/forms'
import ConsumoAgua from './ConsumoAgua'
import ConsumoAguaLista from './ConsumoAguaLista'
import Energia from './Energia'
import EnergiaLista from './EnergiaLista'
import Faturamento from './Faturamento'
import GasesEfeitoEstufa from './GasesEfeitoEstufa'
import GasesEfeitoEstufaLista from './GasesEfeitoEstufaLista'
import OcupacaoArea from './OcupacaoArea'
import OcupacaoAreaLista from './OcupacaoAreaLista'
import Residuos from './Residuos'
import ResiduosLista from './ResiduosLista'
import { cards, metrics } from './items'

const OutlinedInput = withStyles((theme) => ({
  root: {
    '& $notchedOutline': {
      borderColor: theme.palette.white.main,
    },
    '&:hover $notchedOutline': {
      borderColor: theme.palette.white.main,
    },
    '&$focused $notchedOutline': {
      borderColor: theme.palette.white.main,
    },
  },
  focused: {},
  notchedOutline: {},
}))(MuiOutlinedInput)

const useStyles = makeStyles((theme) => ({
  bgBox: {
    position: 'absolute',
    width: '100%',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.32) 21.75%, rgba(0, 0, 0, 0) 100%)',
    backgroundColor: 'rgba(255, 255, 255, 0.25)',
    height: '336px',
    zIndex: -10,
  },
  bgImage: {
    width: '100%',
    filter: 'blur(20px)',
  },
  divisao: {
    width: 300,
    marginRight: 30,
    border: `2px solid ${theme.palette.common.white}`,
    borderRadius: 8,
  },
  ano: {
    width: 150,
    border: `2px solid ${theme.palette.common.white}`,
    borderRadius: 8,
  },
  selectorGrid: {
    backgroundColor: 'transparent',
    marginBottom: 30,
    width: '100%',
    padding: '0 30px',
  },
  gridEstatisticas: {
    marginTop: 48,
    height: 208,
    width: 308,
    color: theme.palette.common.white,
    borderRadius: 8,
    padding: 20,
    boxShadow: '0px 8px 32px rgba(15, 52, 68, 0.08)',
  },
  gridEstatisticasValores: {
    alignSelf: 'flex-start',
  },
  icon: {
    fontSize: theme.typography.pxToRem(48),
    height: 'unset',
    textAlign: 'center',
  },
  imageIcon: {
    height: '100%',
  },
  iconRoot: {
    textAlign: 'center',
  },
  gridMetricas: {
    marginTop: 48,
    background: theme.palette.common.white,
    borderRadius: 8,
    boxShadow: '0px 8px 32px rgba(15, 52, 68, 0.08)',
    padding: theme.spacing(3),
    overflow: 'hidden',
  },
  addDados: {
    color: theme.palette.primary.main,
  },
  mainTitle: {
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(32),
  },
  iconBox: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 8,
    marginBottom: 8,
    minHeight: 48,
    maxHeight: 48,
    minWidth: 48,
    maxWidth: 48,
    display: 'flex',
    alignItems: 'center',
  },
  titleBox: {
    marginTop: theme.spacing(1),
  },
  ipbTitle: {
    color: theme.palette.primary.main,
    fontFamily: theme.typography.secondary,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    textAlign: 'left',
  },
  boxSmallText: {
    marginTop: 8,
    color: theme.palette.primary.main,
    fontSize: 10,
    fontWeight: '500',
    textAlign: 'left',
  },
  metricsTitle: {
    color: theme.palette.primary.main,
    fontFamily: theme.typography.secondary,
    fontWeight: 550,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: '16px',
  },
  documentosLife: {
    display: 'block',
    height: 208,
    backgroundColor: theme.palette.white.main,
    marginTop: 48,
    color: theme.palette.white.main,
    textAlign: 'left',
    borderRadius: 8,
    padding: 16,
    boxShadow: '0px 8px 32px rgba(15, 52, 68, 0.08)',
    overflow: 'hidden',
  },
  documentosLink: {
    display: 'flex',
    height: '100%',
    textDecoration: 'none',
  },
  tabBox: {
    width: '100%',
    boxSizing: 'border-box',
    padding: theme.spacing(5),
    marginTop: theme.spacing(6),
    borderRadius: theme.spacing(1),
    background: theme.palette.common.white,
    boxShadow: '0px 8px 32px rgba(15, 52, 68, 0.08)',
    '& .MuiTabPanel-root': {
      padding: theme.spacing(4, 0),
    },
  },
  formControl: {
    height: 60,
    width: 260,
    marginLeft: theme.spacing(1),
    '& .MuiFormLabel-root': {
      color: theme.palette.white.main,
      fontSize: theme.typography.pxToRem(14),
    },
    '& .MuiInputBase-root:hover': {
      color: theme.palette.white.main,
    },
  },
  select: {
    color: theme.palette.white.main,
  },
  iconSelect: {
    color: theme.palette.white.main,
  },
  tabHeader: {
    width: '100%',
    borderBottom: `1px solid ${theme.palette.grey.main}`,
    '& .Mui-selected': {
      color: theme.palette.blue.main,
      '&.Mui-disabled': {
        color: theme.palette.grey.blue,
      },
    },
    '& .MuiTab-wrapper': {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(14),
    },
    '& .MuiTab-root': {
      minWidth: 'fit-content',
      margin: theme.spacing(0, 1.5),
    },
  },
  cardText: {
    fontSize: theme.typography.pxToRem(10),
    marginTop: theme.spacing(3),
    color: theme.palette.primary.main,
    fontFamily: theme.typography.secondary,
  },
  tabPanel: {
    width: '100%',
  },
  tabIndicator: {
    height: 4,
    transform: 'scaleX(0.8)',
    borderRadius: theme.spacing(2, 2, 0, 0),
    backgroundColor: theme.palette.blue.main,
  },
  tabList: {
    marginTop: -1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const anoAtual = +moment().format('YYYY')
const anos = map(range(2014, anoAtual + 1), (ano) => ({
  value: ano.toString(), label: `${(ano - 1).toString()}/${ano.toString().slice(2)}`,
}))

const PressaoBiodiversidade = () => {
  const location = useLocation()
  const params = location?.state?.data
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const dialog = useConfirmationDialog()

  const isLoading = useSelector(getIsLoading)
  const anoCarregado = useSelector(getAnoCarregado)
  const listaUnidades = useSelector(getUnidades)
  const unidadeSelecionada = useSelector(getUnidadeSelecionada)
  const [unidadeSelecionadaCombo, setUnidadeSelecionadaCombo] =
    useState(unidadeSelecionada?.id ?? (params?.unidade || null))

  const indices = useSelector(getIndices)

  const [anoSelecionado, setAnoSelecionado] =
      useState(params?.ano || anoCarregado || anoAtual.toString())
  const [tabSelecionado, setTabSelecionado] = useState('0')
  const [allowTabChange, setAllowTabChange] = useState(true)

  const handleTabChange = (e, newValue) => {
    if (!allowTabChange) {
      return dialog.showConfirmation({
        title: t('dialogs.salvarDadosTitulo'),
        message: t('dialogs.salvarDadosDescricao'),
      }).then((result) => {
        if (result) {
          setTabSelecionado(newValue)
          setAllowTabChange(true)
        }
      })
    }

    return setTabSelecionado(newValue)
  }

  const handleAllowTabChange = ({ initialValues, values }) => {
    const diffValues = diff(initialValues, values)
    const changed = isFormChanged({ dirtyValues: diffValues, formValues: values, initialValues })
    setAllowTabChange(!changed)
  }

  const handleChangeSelect = (event, cb) => {
    if (!allowTabChange) {
      return dialog.showConfirmation({
        title: t('dialogs.salvarDadosTitulo'),
        message: t('dialogs.salvarDadosDescricao'),
      }).then((result) => {
        if (result) {
          cb(event)
          setAllowTabChange(true)
        }
      })
    }
    return cb(event)
  }

  const handleChangeAno = (event) => {
    setAnoSelecionado(event.target.value)

    if (unidadeSelecionada) {
      dispatch(callListarDadospressaoBiodiversidade({
        ...unidadeSelecionada, ano: event.target.value,
      }))
    }
  }

  const handleChangeComboUnidade = (event) => {
    const { value } = event.target
    const data = find(listaUnidades, { id: value })

    setUnidadeSelecionadaCombo(value)
    dispatch(callListarDadospressaoBiodiversidade({ ...data, ano: anoSelecionado }))
  }

  const gerarNomeSeletor = (option) => {
    if (!option?.empresa?.possuiDivisaoSetor) {
      return option?.empresa?.nome
    }
    if (!option?.divisaoSetor?.possuiUnidade) {
      return option?.divisaoSetor?.nome
    }
    return `${option?.divisaoSetor?.nome} / ${option?.nome}`
  }

  const tabs = [
    { titulo: 'pressaoBiodiversidade.faturamento', index: '0', Tela: Faturamento },
    { titulo: 'pressaoBiodiversidade.residuos', index: '1', Tela: Residuos, Lista: ResiduosLista },
    { titulo: 'pressaoBiodiversidade.GEE', index: '2', Tela: GasesEfeitoEstufa, Lista: GasesEfeitoEstufaLista },
    { titulo: 'pressaoBiodiversidade.agua', index: '3', Tela: ConsumoAgua, Lista: ConsumoAguaLista },
    { titulo: 'pressaoBiodiversidade.energia', index: '4', Tela: Energia, Lista: EnergiaLista },
    { titulo: 'pressaoBiodiversidade.area', index: '5', Tela: OcupacaoArea, Lista: OcupacaoAreaLista },
  ]

  const listaUnidadesOrdered = listaUnidades?.sort((a, b) => (
    a?.divisaoSetor?.nome?.localeCompare(b?.divisaoSetor?.nome, i18n.language)
  ))

  return (
    <div>
      <RouterPrompt
        when={!allowTabChange}
        title={t('dialogs.salvarDadosTitulo')}
        message={t('dialogs.salvarDadosDescricao')}
      />
      <Backdrop className={classes.backdrop} open={isLoading} transitionDuration={500}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <PageTitle title={t('pressaoBiodiversidade.pageTitle')}>
        <div>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="divisao-setor-unidade">{t('pressaoBiodiversidade.selecioneDivisaoUnidade')}</InputLabel>
            <Select
              classes={{
                select: classes.select,
                icon: classes.iconSelect,
              }}
              value={unidadeSelecionadaCombo || ''}
              onChange={(event) => handleChangeSelect(event, handleChangeComboUnidade)}
              inputProps={{
                name: 'divisao-setor-unidade',
                id: 'divisao-setor-unidade',
              }}
              input={<OutlinedInput label={t('pressaoBiodiversidade.selecioneDivisaoUnidade')} />}
            >
              {map(listaUnidadesOrdered, (option) => (
                <MenuItem key={`${option.nome} ${option.id}`} value={option.id}>
                  {gerarNomeSeletor(option)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="ano-base">
              {t('pressaoBiodiversidade.selecioneAno')}
            </InputLabel>
            <Select
              classes={{
                select: classes.select,
                icon: classes.iconSelect,
              }}
              value={anoSelecionado}
              onChange={(event) => handleChangeSelect(event, handleChangeAno)}
              label={t('pressaoBiodiversidade.selecioneAno')}
              inputProps={{
                name: 'ano-base',
                id: 'ano-base',
              }}
              input={<OutlinedInput label={t('pressaoBiodiversidade.selecioneAno')} />}
            >
              {map(anos, (option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </PageTitle>

      <MainColumn columns="1fr 1fr 1fr 120px">
        {cards.map((item) => (
          <Card
            hasGradient
            key={item.name}
            icon={item.icon}
            title={t(item.name)}
            status={item.statusColor}
            value={formatDecimal({
              min: item.decimalMin,
              max: item.decimalMax,
              locale: i18n.language,
              value: get(indices, item.selector),
            })}
          >
            {!!item.text && (
            <Typography className={classes.cardText}>{t(item.text)}</Typography>
            )}
          </Card>
        ))}
        <Card
          shrink
          status="purple"
          href={DOCUMENTS_URL}
          icon={FileCopyLineIcon}
          title={t('pressaoBiodiversidade.documentosLife')}
        />
      </MainColumn>
      <MainColumn mt={6} columns="repeat(5, 1fr)">
        {metrics.map((metric) => (
          <Card
            small
            key={metric.name}
            icon={metric.icon}
            title={t(metric.name)}
            status={metric.statusColor}
            value={formatDecimal({
              min: 2,
              max: 5,
              locale: i18n.language,
              value: get(indices, metric.selector),
            })}
          />
        ))}
      </MainColumn>

      <Box className={classes.tabBox} pt={3}>
        <Grid container justifyContent="space-evenly" alignItems="baseline">
          <TabContext value={tabSelecionado}>
            <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.tabHeader}>
              <Typography className={classes.addDados} component="h3" variant="h5">
                {t('pressaoBiodiversidade.adicionarDados')}
              </Typography>
              <TabList
                onChange={handleTabChange}
                classes={{
                  root: classes.tabList,
                  indicator: unidadeSelecionada && classes.tabIndicator,
                }}
              >
                {map(tabs, ({ titulo, index }) => (
                  <Tab
                    label={t(titulo)}
                    value={index}
                    key={titulo + index}
                    disabled={!unidadeSelecionada}
                  />
                ))}
              </TabList>
            </Box>
            {map(tabs, ({ titulo, index, Tela, Lista }) => (
              <TabPanel className={classes.tabPanel} value={index} key={titulo + index}>
                <Grid container direction="row" spacing={5}>
                  {Lista && (
                  <Grid item xs={12} sm={Lista ? 5 : 4} style={{ borderRight: '1px solid #C9CEDB' }}>
                    <Lista />
                  </Grid>
                  )}
                  <Grid item xs={12} sm={Lista ? 7 : 12}>
                    <Tela
                      setChangeTab={handleAllowTabChange}
                      unidadeSelecionada={unidadeSelecionada}
                      anoSelecionado={anoSelecionado}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
            ))}
          </TabContext>
        </Grid>
      </Box>
    </div>
  )
}

export default PressaoBiodiversidade
