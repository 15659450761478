import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import ContentBox from 'components/ContentBox'
import Button from 'components/Button'
import Filters from 'components/FilterList/Filters'
import Table from 'components/Table'
import Pagination from 'components/Pagination'
import EditLineIcon from 'remixicon-react/EditLineIcon'
import DeleteLineIcon from 'remixicon-react/DeleteBin7LineIcon'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { TIPO_USUARIO } from 'utils/constantes'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import SaveForm from './SaveForm'

const useStyles = makeStyles((theme) => ({
  contentBox: {
    padding: theme.spacing(2),
  },
  header: {
    display: 'flex',
    alignItems: 'flex-start',
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
  headerButton: {
    fontSize: 14,
    display: 'flex',
    position: 'relative',
    textDecoration: 'none',
    justifyContent: 'center',
    textTransform: 'uppercase',
    marginRight: theme.spacing(3),
    color: theme.palette.grey.blue,
    padding: theme.spacing('6px', 2, '21px'),
    '&::after': {
      bottom: 0,
      opacity: 0,
      width: '65%',
      content: '""',
      display: 'block',
      position: 'absolute',
      height: theme.spacing(0.5),
      borderRadius: theme.spacing(4, 4, 0, 0),
      backgroundColor: theme.palette.blue.main,
      transition: theme.transitions.create('opacity'),
    },
  },
  headerButtonActive: {
    color: theme.palette.blue.main,
    '&::after': {
      opacity: 1,
    },
  },
  headerAction: {
    marginLeft: 'auto',
    padding: theme.spacing(1, 2),
  },
  tableHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    justifyContent: 'space-between',
  },
  tableTitle: {
    margin: 0,
    fontSize: 24,
    fontWeight: theme.typography.fontWeightRegular,
  },
  tableFilters: {
    width: '49%',
  },
  tableCustomList: {
    padding: theme.spacing(2, 3, 3),
  },
  tableBody: {
    padding: theme.spacing(2, 3),
  },
  tableActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tableActionsIcon: {
    width: 16,
    marginLeft: 6,
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      color: theme.palette.blue.main,
    },
  },
  pagination: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  edit: {
    maxHeight: 0,
    overflow: 'hidden',
    transition: theme.transitions.create('max-height'),
  },
  editOpen: {
    maxHeight: 1200,
  },
}))

const AdminLayout = ({
  title,
  buttonText,
  tableColumns = [],
  tableData = [],
  onDelete,
  onEdit,
  onCancel,
  renderList,
  renderForm,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const [data, setData] = useState(tableData)
  const [page, setPage] = useState(0)
  const [edit, setEdit] = useState(false)

  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const pageSize = 10

  const navigationItems = pathname === '/adm/usuariosLife' ? [
    { label: 'menuItemsAdm.usuariosLife', to: '/adm/usuariosLife' },
  ] : [
    { label: 'menuItemsAdm.empresas', to: '/adm/empresas' },
    { label: 'menuItemsAdm.usuarios', to: '/adm/usuarios' },
    { label: 'menuItemsAdm.licencas', to: '/adm/licencas' },
  ]

  const handleActionClick = (state) => () => {
    setEdit(state)
    onCancel()
  }

  const handleDelete = (item) => () => {
    if (onDelete) onDelete(item)
  }

  const handleEdit = (item) => () => {
    if (onEdit) onEdit(item)
    setEdit(true)
  }

  const handleCancel = () => {
    if (onCancel) onCancel()
    setEdit(false)
  }

  const filteredData = data
    .slice(page * pageSize, page * pageSize + pageSize)
    .map((item) => ({
      ...item,
      actions: (
        <div className={classes.tableActions}>
          <EditLineIcon onClick={handleEdit(item)} className={classes.tableActionsIcon} />
          <DeleteLineIcon onClick={handleDelete(item)} className={classes.tableActionsIcon} />
        </div>
      ),
    }))

  useEffect(() => {
    setData(tableData)
  }, [tableData])

  return (
    <ContentBox className={classes.contentBox}>
      <div className={classes.header}>
        {navigationItems.map((item) => (
          <NavLink
            exact
            to={item.to}
            key={item.label}
            className={classes.headerButton}
            activeClassName={classes.headerButtonActive}
          >
            {t(item.label)}
          </NavLink>
        ))}
        {tipoUsuarioLogado !== TIPO_USUARIO.ADMIN.OBSERVADOR && buttonText && !edit && (
          <Button
            small
            onClick={handleActionClick(true)}
            className={classes.headerAction}
          >
            {buttonText}
          </Button>
        )}
      </div>
      {renderForm && (
        <div className={clsx(classes.edit, edit && classes.editOpen)}>
          {renderForm((props) => <SaveForm {...props} onCancel={handleCancel} />)}
        </div>
      )}
      <div className={classes.tableHeader}>
        <h1 className={classes.tableTitle}>{title}</h1>
        <div className={classes.tableFilters}>
          <Filters
            fullWidth
            items={tableData}
            setItems={setData}
            onChange={() => setPage(0)}
            origin={{ vertical: 'top', horizontal: 'right' }}
          />
        </div>
      </div>
      {renderList && (
        <div className={classes.tableCustomList}>
          {renderList()}
        </div>
      )}
      <div className={classes.tableBody}>
        <Table
          data={filteredData}
          columns={[
            ...tableColumns,
            ...tipoUsuarioLogado !== TIPO_USUARIO.ADMIN.OBSERVADOR ? [{ title: t('inputs.acoes'), key: 'actions', width: '4%' }] : [],
          ]}
        />
      </div>
      <div className={classes.pagination}>
        {!!filteredData.length && (
          <Pagination
            page={page}
            onChange={setPage}
            pageSize={pageSize}
            count={data.length}
          />
        )}
      </div>
    </ContentBox>
  )
}

AdminLayout.propTypes = {
  title: PropTypes.string,
  tableData: PropTypes.array,
  buttonText: PropTypes.string,
  tableColumns: PropTypes.array,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onCancel: PropTypes.func,
  renderList: PropTypes.func,
  renderForm: PropTypes.func,
}

export default AdminLayout
