import React, { Fragment, useState } from 'react'
import AuditorEmpresaLayout from 'components/AuditorEmpresaLayout'
import { useTranslation } from 'react-i18next'
import { Box, makeStyles, Tab } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import Detalhados from './TabDetalhados'
import Resumidos from './TabResumidos'
import Auditoria from './TabAuditoria'

export const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
  screenTitle: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(5),
    fontSize: theme.typography.pxToRem(24),
  },
  panel: {
    padding: theme.spacing(4, 0),
  },
  tabContainer: {
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
  tabIndicator: {
    height: 4,
    transform: 'scaleX(0.8)',
    borderRadius: theme.spacing(2, 2, 0, 0),
    backgroundColor: theme.palette.blue.main,
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
  tabList: {
    marginTop: '28px',
    '& .MuiTab-root': {
      marginRight: '24px',
      padding: '6px 16px 21px',
      '&.Mui-selected': {
        color: '#31ADE3',
      },
    },
  },
}))

const Relatorio = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const tabs = [
    { key: '0', title: t('relatorios.detalhados'), component: Detalhados },
    { key: '1', title: t('relatorios.resumidos'), component: Resumidos },
    { key: '2', title: t('relatorios.auditoria'), component: Auditoria },
  ]

  const [currentTab, setCurrentTab] = useState(tabs[0].key)

  return (
    <AuditorEmpresaLayout
      renderChildren={(
        <>
          <TabContext value={currentTab}>
            <Box className={classes.tabContainer}>
              <TabList
                classes={{ indicator: classes.tabIndicator }}
                className={classes.tabList}
              >
                {tabs.map((elem, index) => (
                  <Tab
                    label={elem.title}
                    value={elem.key}
                    key={index}
                    onClick={() => { setCurrentTab(elem.key) }}
                  />
                ))}
              </TabList>
            </Box>

            {tabs.map(({ component: Component, ...tab }) => (
              <TabPanel className={classes.panel} value={tab.key} key={tab.key}>
                <Component />
              </TabPanel>
            ))}
          </TabContext>
        </>
      )}
    />
  )
}

export default Relatorio
