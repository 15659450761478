import React, { Suspense, useEffect } from 'react'
import { Provider } from 'react-redux'
import AppRouter from 'components/AppRouter'
import Notifier from 'components/Notifications'
import { ThemeProvider } from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'
import PropTypes from 'prop-types'
import { create, all } from 'mathjs'
import ConfirmDialogProvider from 'components/Dialog'
import Notification from 'components/Notification'
import theme from 'theme'

const App = ({ store }) => {
  useEffect(() => {
    const mathjs = create(all)
    mathjs.config({ number: 'BigNumber' })
  }, [])

  return (
    <Suspense fallback={<div>Loading... </div>}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ConfirmDialogProvider>
            <SnackbarProvider
              maxSnack={5}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              content={(id, props) => <Notification id={id} {...props} />}
            >
              <Notifier />
              <AppRouter />
            </SnackbarProvider>
          </ConfirmDialogProvider>
        </ThemeProvider>
      </Provider>
    </Suspense>
  )
}

App.propTypes = {
  store: PropTypes.object,
}

export default App
