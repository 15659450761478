import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { filter, map } from 'lodash'
import { API_LISTAR_DADOS_IPB } from 'store/modules/api/pressaoBiodiversidade.actions'
import { API_CRIAR_RESIDUO, API_ATUALIZAR_RESIDUO, API_EXCLUIR_RESIDUO, API_GET_RESIDUOS } from 'store/modules/api/residuos.actions'
import { CALL_NOVO_RESIDUO } from 'store/modules/residuos/residuos.actions'
import { API_EXCLUIR_ANEXO } from 'store/modules/api/anexos.actions'

const lista = (state = [], { type, payload }) => {
  switch (type) {
    case API_LISTAR_DADOS_IPB.success: return payload.residuos
    case API_CRIAR_RESIDUO.success: return [...state, payload.geracaoResiduo]
    case API_EXCLUIR_RESIDUO.success: return filter(state, (u) => u.id !== payload.id)
    case API_ATUALIZAR_RESIDUO.success:
      return map(state, (u) => (u.id === payload.geracaoResiduo.id ? payload.geracaoResiduo : u))
    default: return state
  }
}

const selecionado = (state = null, { type, payload }) => {
  switch (type) {
    case API_GET_RESIDUOS.success: return payload
    case CALL_NOVO_RESIDUO: return null
    case API_CRIAR_RESIDUO.success: return null
    case API_ATUALIZAR_RESIDUO.success: return null
    case API_EXCLUIR_ANEXO.success:
      return state?.anexo?.id === +payload ? { ...state, anexo: null } : state
    default: return state
  }
}

const isCarregandoResiduos = loadingReducer(false, API_LISTAR_DADOS_IPB)

const isSaving = loadingReducer(false, {
  request: [
    API_ATUALIZAR_RESIDUO.request,
    API_CRIAR_RESIDUO.request,
  ],
  success: [
    API_ATUALIZAR_RESIDUO.success,
    API_CRIAR_RESIDUO.success,
  ],
  failure: [
    API_ATUALIZAR_RESIDUO.failure,
    API_CRIAR_RESIDUO.failure,
  ],
})

export default combineReducers({
  lista,
  selecionado,
  isCarregandoResiduos,
  isSaving,
})
