const ACTION_PATH = 'app/estacaoMeteorologica'

export const CALL_CRIAR_ESTACAO_METEOROLOGICA = `${ACTION_PATH}/CALL_CRIAR_ESTACAO_METEOROLOGICA`
export const CALL_EXCLUIR_ESTACAO_METEOROLOGICA = `${ACTION_PATH}/CALL_EXCLUIR_ESTACAO_METEOROLOGICA`
export const CALL_ATUALIZAR_ESTACAO_METEOROLOGICA = `${ACTION_PATH}/CALL_ATUALIZAR_ESTACAO_METEOROLOGICA`

export const callCriarEstacaoMeteorologica = (payload) => ({
  type: CALL_CRIAR_ESTACAO_METEOROLOGICA,
  payload,
})

export const callAtualizarEstacaoMeteorologica = (payload) => ({
  type: CALL_ATUALIZAR_ESTACAO_METEOROLOGICA,
  payload,
})

export const callExcluirEstacaoMeteorologica = ({ id }) => ({
  type: CALL_EXCLUIR_ESTACAO_METEOROLOGICA,
  payload: { id },
})
