import {
  isNil,
  isNonArrayObject,
  matchesObject,
  normalizeArray,
  emptyAction,
  find
} from '../utils'

const { isArray } = Array

const normalizeArrayPayload = (payload) => normalizeArray(payload).filter(v => !isNil(v))

const defaultMerge = (dest, source) => {
  if (isArray(dest) && isArray(source)) {
    return [ ...dest, ...source ]
  }
  if (isNonArrayObject(dest) && isNonArrayObject(source)) {
    return { ...dest, ...source }
  }
  return source
}

/**
 * Default value for dictionaryReducer's `getFilterCallback` option
 * @alias module:dictionary
 * @param {*} payload a filter action's payload
 * @returns {function} Array.filter callback function
 */
export const getDictionaryFilterCallback = (payload) => (value) => {
  if (isArray(value) && isArray(payload)) {
    return value.length === payload.length && !find(value, (elt, i) => elt !== payload[i])
  }
  if (isNonArrayObject(value) && isNonArrayObject(payload)) {
    return matchesObject(value, payload)
  }
  // TODO: handle more "common" filter cases in default filter callback?
  return payload === value
}

/**
 * Default value for dictionaryReducer's `getValueReducer` option
 * @alias module:dictionary
 * @param {{}} options subset of dictionaryReducer options.
 * @param {function} options.merge value merge implementation for duplicate keys in dict
 * @returns {function} "redux-style" reducer; given a value and 'reduce' action, returns next value
 */
export const getDictionaryValueReducer = ({ merge }) => (val, { payload }, id) => merge(val, payload)

const updateValue = (dict, payload, meta) => {
  const ids = isNonArrayObject(meta)
    ? normalizeArrayPayload(meta.ids)
    : normalizeArrayPayload(meta)
  return ids.reduce((acc, id) => ({ ...acc, [id]: payload }), dict)
}

const merge = (dict, payload, mergeFn) => {
  const ids = Object.keys(payload || {}).filter(v => !isNil(v))
  return ids.reduce((acc, id) => ({
    ...acc,
    [id]: mergeFn(acc[id], payload[id])
  }), dict)
}

const mergeValue = (dict, payload, meta, mergeFn) => {
  const ids = isNonArrayObject(meta)
    ? normalizeArrayPayload(meta.ids)
    : normalizeArrayPayload(meta)
  return ids.reduce((acc, id) => ({
    ...acc,
    [id]: mergeFn(acc[id], payload)
  }), dict)
}

const remove = (dict, ids) => ids.reduce((acc, id) => {
  const { [id]: _, ...rest } = acc
  return rest
}, dict)

const filter = (dict, payload, getFilterCallback) => {
  const ids = Object.keys(dict)
  return ids.reduce((acc, id) => {
    if (!getFilterCallback(payload)(acc[id], id, acc)) {
      const { [id]: _, ...rest } = acc
      return rest
    }
    return acc
  }, dict)
}

const reject = (dict, payload, getFilterCallback) => {
  const invertedCallback = (...a) => (...b) => !getFilterCallback(...a)(...b)
  return filter(dict, payload, invertedCallback)
}

const reduce = (dict, action, reducer) => {
  const { meta } = action
  const normalizedMeta = isNonArrayObject(meta)
    ? normalizeArrayPayload(meta.ids)
    : normalizeArrayPayload(meta)
  const realIds = normalizedMeta.filter(id => !isNil(dict[id]))
  const ids = realIds.length ? realIds : Object.keys(dict)
  return ids.reduce((acc, id) => ({
    ...acc,
    [id]: reducer(dict[id], action, id)
  }), dict)
}

const batch = (dict, reducer, actions) => (
  actions.reduce((acc, cur) => reducer(acc, cur), dict)
)

/**
 * Generic "dictionary" implementation for redux.
 * Allows for action managed mutations of an arbitrary set of key-value pairs.
 * Useful for keeping track of client-side state for multiple entities by key,
 * such as mapping other client state to Server-API loaded entities by ID.
 *
 * @alias module:dictionary
 * @param {{}} defaultState
 * @param {{}} [types={}] action types to match for payload history
 * @param {string|symbol} types.updateValue update action type
 * @param {string|symbol} types.merge merge action type
 * @param {string|symbol} types.mergeValue mergeValue action type
 * @param {string|symbol} types.remove remove action type
 * @param {string|symbol} types.filter filter action type
 * @param {string|symbol} types.reject reject action type
 * @param {string|symbol} types.reset reset action type
 * @param {string|symbol} types.reduce reduce action type
 * @param {string|symbol} types.batch batch action type
 * @param {{}} [options={}] additional options for resulting reducer
 * @param {function} options.getValueReducer method to invoke on value(s) when processing "reduce" actions
 * @param {function} options.getFilterCallback given action payload, returns filter callback function
 * @param {function} options.merge merge method for reducers. see `defaultMerge`
 * @returns {function} redux reducer function
 */
const dictionaryReducer = (defaultState = {}, {
  updateValue: updateValueAction,
  merge: mergeAction,
  mergeValue: mergeValueAction,
  remove: removeAction,
  filter: filterAction,
  reject: rejectAction,
  reset: resetAction,
  reduce: reduceAction,
  batch: batchAction
} = {}, {
  getValueReducer = getDictionaryValueReducer,
  getFilterCallback = getDictionaryFilterCallback,
  merge: mergeFn = defaultMerge
} = {}) => {
  const reduceHandler = getValueReducer({ merge: mergeFn })
  const reducer = (dict = defaultState, action = emptyAction()) => {
    const { type, payload, meta } = action
    switch (type) {
      case updateValueAction: {
        // take new "value", merge onto prev value(s) in dict or add new KvP(s), and return new state
        return updateValue(dict, payload, meta)
      }
      case mergeAction: {
        // take new "dict", merge onto prev dict, and return new state
        return merge(dict, payload, mergeFn)
      }
      case mergeValueAction: {
        return mergeValue(dict, payload, meta, mergeFn)
      }
      case removeAction: {
        // remove KvP(s) from "dict" by "key(s)"
        return remove(dict, normalizeArrayPayload(payload))
      }
      case filterAction: {
        // remove KvP(s) from "dict" by filterCallback condition
        return filter(dict, payload, getFilterCallback)
      }
      case rejectAction: {
        // inverse of filterAction
        return reject(dict, payload, getFilterCallback)
      }
      case resetAction: {
        // reset "dict" to default state, or to provided new "dict"
        return merge(defaultState, payload || defaultState, mergeFn)
      }
      case reduceAction: {
        // invoke reduceHandler on item value(s) in "dict" and return new state for each
        return reduce(dict, action, reduceHandler)
      }
      case batchAction: {
        // group together "dictionary" actions into a single action
        return batch(dict, reducer, normalizeArrayPayload(payload))
      }
      default: return dict
    }
  }
  return reducer
}

export default dictionaryReducer
