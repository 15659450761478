import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const getConsumoAguaActionTypes = apiActionTypes('consumoAgua/API_GET_CONSUMO_AGUA')
const createConsumoAguaActionTypes = apiActionTypes('consumoAgua/API_CRIAR_CONSUMO_AGUA')
const updateConsumoAguaActionTypes = apiActionTypes('consumoAgua/API_ATUALIZAR_CONSUMO_AGUA')
const deleteConsumoAguaActionTypes = apiActionTypes('consumoAgua/API_EXCLUIR_CONSUMO_AGUA')

export const API_GET_CONSUMO_AGUA = asApiTypesObject(getConsumoAguaActionTypes)
export const API_CRIAR_CONSUMO_AGUA = asApiTypesObject(createConsumoAguaActionTypes)
export const API_ATUALIZAR_CONSUMO_AGUA = asApiTypesObject(updateConsumoAguaActionTypes)
export const API_EXCLUIR_CONSUMO_AGUA = asApiTypesObject(deleteConsumoAguaActionTypes)

export const apiGetConsumoAgua = (id, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/consumoAgua/${id}`,
  method: 'GET',
  types: getConsumoAguaActionTypes,
}, meta)

export const apiCriarConsumoAgua = (consumoAgua, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/consumoAgua`,
  method: 'POST',
  types: createConsumoAguaActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(consumoAgua),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiAtualizarConsumoAgua = ({ id, consumoAgua }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/consumoAgua/${id}`,
  method: 'PUT',
  types: updateConsumoAguaActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(consumoAgua),
}, { ...meta, successNotification: 'mensagens.atualizado' })

export const apiExcluirConsumoAgua = (id, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/consumoAgua/${id}`,
  method: 'DELETE',
  types: deleteConsumoAguaActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })
