import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import {
  apiCriarAcaoClassificacao,
  apiAtualizarAcaoClassificacao,
  apiExcluirAcaoClassificacao,
  apiGetAcaoClassificacao,
} from 'store/modules/api/acaoClassificacao.actions'
import {
  CALL_ATUALIZAR_ACAO_CLASSIFICACAO,
  CALL_CRIAR_ACAO_CLASSIFICACAO,
  CALL_EXCLUIR_ACAO_CLASSIFICACAO,
  CALL_SELECIONAR_ACAO_CLASSIFICACAO,
} from 'store/modules/acaoClassificacao/acaoClassificacao.actions'

const onSelected = (action$) => action$.pipe(
  ofType(CALL_SELECIONAR_ACAO_CLASSIFICACAO),
  debounceTime(250),
  map(({ payload }) => apiGetAcaoClassificacao(payload.id)),
)

const onCallCriarAcao = (action$) => action$.pipe(
  ofType(CALL_CRIAR_ACAO_CLASSIFICACAO),
  debounceTime(250),
  map(({ payload }) => apiCriarAcaoClassificacao(payload)),
)

const onCallAtualizarAcaoClassificacao = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_ACAO_CLASSIFICACAO),
  debounceTime(250),
  map(({ payload }) => apiAtualizarAcaoClassificacao(payload)),
)

const onCallExcluirAcaoClassificacao = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_ACAO_CLASSIFICACAO),
  debounceTime(250),
  map(({ payload }) => apiExcluirAcaoClassificacao(payload)),
)

export default combineEpics(
  onCallCriarAcao,
  onCallAtualizarAcaoClassificacao,
  onSelected,
  onCallExcluirAcaoClassificacao,
)
