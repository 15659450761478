import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { API_LISTAR_TEMAS, API_EXCLUIR_TEMA, API_CRIAR_TEMA } from 'store/modules/api/temas.actions'
import { filter } from 'lodash'

const lista = (state = [], { type, payload }) => {
  switch (type) {
    case API_LISTAR_TEMAS.success: return payload
    case API_CRIAR_TEMA.success: return payload
    case API_EXCLUIR_TEMA.success: return filter(state, (o) => o.id !== payload)
    default: return state
  }
}

const isCarregandoLista = loadingReducer(false, API_LISTAR_TEMAS)

const isSaving = loadingReducer(false, API_CRIAR_TEMA)

export default combineReducers({
  lista,
  isCarregandoLista,
  isSaving,
})
