import { combineEpics } from 'redux-observable'
import { debounceTime, mergeMap } from 'rxjs/operators'
import { apiGetEmpresa } from 'store/modules/api/empresas.actions'
import { apiListarUnidades } from 'store/modules/api/unidades.actions'
import { apiListarDivisoesSetores } from 'store/modules/api/divisoesSetores.actions'
import { apiFetchUsers } from 'store/modules/api/usuarios.actions'
import { ofLocationChangeTo } from 'utils/location'
import { getDadosUsuario } from 'store/modules/auth/auth.selectors'

const onLocationChangeToMeuPerfl = (action$, state$) => action$.pipe(
  ofLocationChangeTo(['/meuPerfil', '/dadosEmpresa']),
  debounceTime(250),
  mergeMap(() => [
    apiListarUnidades(getDadosUsuario(state$.value).empresa.id),
    apiListarDivisoesSetores(getDadosUsuario(state$.value).empresa.id),
    apiGetEmpresa(getDadosUsuario(state$.value).empresa.id),
    apiFetchUsers(getDadosUsuario(state$.value).empresa.id),
  ]),
)

export default combineEpics(
  onLocationChangeToMeuPerfl,
)
