import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import {
  apiListarTemas,
  apiAtualizarTema,
  apiCriarTema,
  apiSalvarListaTema,
  apiExcluirTema,
} from 'store/modules/api/temas.actions'
import {
  CALL_ATUALIZAR_TEMA,
  CALL_CRIAR_TEMA,
  CALL_SALVAR_LISTA_TEMA,
  CALL_EXCLUIR_TEMA,
} from 'store/modules/temas/temas.actions'
import { ofLocationChangeTo } from 'utils/location'

const onLocationChangeToLista = (action$) => action$.pipe(
  ofLocationChangeTo('/temas'),
  debounceTime(250),
  map(({ payload }) => apiListarTemas(payload)),
)

const onCallCriarTema = (action$) => action$.pipe(
  ofType(CALL_CRIAR_TEMA),
  debounceTime(250),
  map(({ payload }) => apiCriarTema(payload)),
)

const onCallAtualizarTema = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_TEMA),
  debounceTime(250),
  map(({ payload }) => apiAtualizarTema(payload)),
)

const onCallSalvarListaTema = (action$) => action$.pipe(
  ofType(CALL_SALVAR_LISTA_TEMA),
  debounceTime(250),
  map(({ payload }) => apiSalvarListaTema({ lista: payload })),
)

const onCallExcluirTemas = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_TEMA),
  debounceTime(250),
  map(({ payload }) => apiExcluirTema(payload)),
)

export default combineEpics(
  onLocationChangeToLista,
  onCallCriarTema,
  onCallAtualizarTema,
  onCallSalvarListaTema,
  onCallExcluirTemas,
)
