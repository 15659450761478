const ACTION_PATH = 'app/matrizResiduos'

export const CALL_SALVAR_NOVAS_MATRIZES = `${ACTION_PATH}/CALL_SALVAR_NOVAS_MATRIZES`
export const CALL_SALVAR_MATRIZ_RESIDUO = `${ACTION_PATH}/CALL_SALVAR_MATRIZ_RESIDUO`
export const CALL_EXCLUIR_MATRIZ_RESIDUO = `${ACTION_PATH}/CALL_EXCLUIR_MATRIZ_RESIDUO`

export const callSalvarNovasMatrizes = (payload) => ({
  type: CALL_SALVAR_NOVAS_MATRIZES,
  payload,
})

export const callSalvarMatrizResiduo = (payload) => ({
  type: CALL_SALVAR_MATRIZ_RESIDUO,
  payload,
})

export const callExcluirMatrizResiduo = ({ id }) => ({
  type: CALL_EXCLUIR_MATRIZ_RESIDUO,
  payload: id,
})
