import React, { useState } from 'react'
import moment from 'moment'
import * as yup from 'yup'
import PageTitle from 'components/PageTitle'
import AdminLayout from 'components/AdminLayout'
import Avatar from 'components/Avatar'
import Autocomplete from 'components/Fields/BaseAutocomplete'
import { useTranslation } from 'react-i18next'
import { Grid, makeStyles } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { FieldAutocomplete, FieldText } from 'components/Fields'
import { getEmpresas } from 'store/modules/empresas/empresas.selectors'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import { getTipoUsuariosCliente } from 'store/modules/tipoUsuarios/tipoUsuarios.selectors'
import { getUsuarios, getUsuarioSelecionado } from 'store/modules/usuarios/usuarios.selectors'
import {
  callAtualizarUsuario,
  callCriarUsuario,
  callNovoUsuario,
  callExcluirUsuario,
  callSelecionarUsuario,
} from 'store/modules/usuarios'

const useStyles = makeStyles((theme) => ({
  avatar: {
    display: 'flex',
    alignItems: 'center',
  },
  avatarText: {
    marginLeft: theme.spacing(2),
  },
  selectedCompany: {
    width: 356,
  },
}))

const Usuario = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const dialog = useConfirmationDialog()

  const usuarios = useSelector(getUsuarios)
  const empresas = useSelector(getEmpresas)
  const tiposUsuario = useSelector(getTipoUsuariosCliente)
  const usuarioSelecionado = useSelector(getUsuarioSelecionado)

  const [selectedCompany, setSelectedCompany] = useState(null)

  const tableColumns = [
    { title: t('usuariosAdm.tabelaNome'), key: 'name', width: '30%' },
    { title: t('usuariosAdm.tabelaEmpresa'), key: 'company', width: '27%' },
    { title: t('usuariosAdm.tabelaPermissao'), key: 'permission', width: '19%' },
    { title: t('usuariosAdm.tabelaCadastro'), key: 'date', width: '20%' },
  ]

  const handleSelectedCompany = (_, option) => {
    setSelectedCompany(option ? option.value : null)
  }

  const handleSave = (values) => {
    dispatch(usuarioSelecionado ?
      callAtualizarUsuario({ usuario: values, id: usuarioSelecionado.id }) :
      callCriarUsuario(values))
  }

  const handleDelete = ({ id, nome }) => (
    dialog.showConfirmation({
      title: t('dialogs.confirmarExclusao'),
      message: t('dialogs.excluirUsuario', { usuario: nome }),
    }).then((result) => result && dispatch(callExcluirUsuario({ id })))
  )

  const handleEdit = ({ id }) => {
    dispatch(callSelecionarUsuario({ id }))
  }

  const handleCancel = () => {
    dispatch(callNovoUsuario())
  }

  const getTableData = (data) => data
    .filter((item) => (
      selectedCompany ? item.empresa?.id === selectedCompany : true
    ))
    .map((item) => ({
      ...item,
      company: t(item.empresa?.nome),
      permission: t(item.tipoUsuario?.tipo),
      date: moment(item.createdAt).format('DD/MM/YYYY'),
      name: (
        <div className={classes.avatar}>
          <Avatar initials={item.nome} image={item.avatar} size="small" />
          <span className={classes.avatarText}>{item.nome}</span>
        </div>
      ),
    }))

  return (
    <>
      <PageTitle title={t('menuItemsAdm.contas')} />
      <AdminLayout
        title={t('menuItemsAdm.usuarios')}
        buttonText={t('usuariosAdm.adicionarUsuario')}
        tableColumns={tableColumns}
        tableData={getTableData(usuarios)}
        onDelete={handleDelete}
        onEdit={handleEdit}
        onCancel={handleCancel}
        renderList={() => (
          <Autocomplete
            name="selectedCompany"
            value={selectedCompany}
            onChange={handleSelectedCompany}
            className={classes.selectedCompany}
            inputProps={{ label: t('inputs.selecioneEmpresa') }}
            options={empresas.map((item) => ({ label: item.nome, value: item.id }))}
          />
        )}
        renderForm={(Form) => (
          <Form
            onSave={handleSave}
            validate={yup.object({
              nome: yup.string().required(),
              email: yup.string().email().required(),
              empresaId: yup.number().required().nullable(),
              tipoUsuarioId: yup.number().required().nullable(),
            })}
            title={t('usuariosAdm.dadosUsuario')}
            initialValues={{
              nome: usuarioSelecionado?.nome ?? '',
              email: usuarioSelecionado?.email ?? '',
              empresaId: usuarioSelecionado?.empresa.id ?? null,
              tipoUsuarioId: usuarioSelecionado?.tipoUsuario?.id ?? null,
            }}
          >
            <Grid spacing={4} container>
              <Grid item xs={12}>
                <Grid spacing={2} container>
                  <Grid item xs={6}>
                    <FieldText required name="nome" label={t('inputs.nome')} />
                  </Grid>
                  <Grid item xs={6}>
                    <FieldText required name="email" label={t('inputs.email')} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid spacing={2} container>
                  <Grid item xs={6} style={{ borderWidth: 1, borderColor: 'red' }}>
                    <FieldAutocomplete
                      required
                      name="empresaId"
                      label={t('inputs.empresaUsuario')}
                      options={empresas.map((item) => ({
                        value: item.id,
                        label: item.nome,
                      }))}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FieldAutocomplete
                      required
                      name="tipoUsuarioId"
                      label={t('inputs.permissaoUsuario')}
                      options={tiposUsuario.map((item) => ({
                        value: item.id,
                        label: t(item.tipo),
                      }))}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      />
    </>
  )
}

export default Usuario
