import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchMunicipiosActionTypes = apiActionTypes('municipios/API_LISTAR_MUNICIPIOS')

export const API_LISTAR_MUNICIPIOS = asApiTypesObject(fetchMunicipiosActionTypes)

export const apiListarMunicipios = (data, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/municipios`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchMunicipiosActionTypes,
}, { ...meta })
