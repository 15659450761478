import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import {
  apiCriarClassesOcupacao,
  apiExcluirClassesOcupacao,
  apiAtualizarClassesOcupacao,
} from 'store/modules/api/classesOcupacao.actions'
import {
  CALL_CRIAR_CLASSE_OCUPACAO,
  CALL_EXCLUIR_CLASSE_OCUPACAO,
  CALL_ATUALIZAR_CLASSE_OCUPACAO,
} from 'store/modules/classesOcupacao/classesOcupacao.actions'

const onCallCriarClasseOcupacao = (action$) => action$.pipe(
  ofType(CALL_CRIAR_CLASSE_OCUPACAO),
  debounceTime(250),
  map(({ payload }) => apiCriarClassesOcupacao(payload)),
)

const onCallExcluirClasseOcupacao = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_CLASSE_OCUPACAO),
  debounceTime(250),
  map(({ payload }) => apiExcluirClassesOcupacao(payload)),
)

const onCallAtualizarClasseOcupacao = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_CLASSE_OCUPACAO),
  debounceTime(250),
  map(({ payload }) => apiAtualizarClassesOcupacao(payload)),
)

export default combineEpics(
  onCallCriarClasseOcupacao,
  onCallExcluirClasseOcupacao,
  onCallAtualizarClasseOcupacao,
)
