import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchEspecieSilviculturaActionTypes = apiActionTypes('especieSilvicultura/API_LISTAR_ESPECIE_SILVICULTURA')
const createEspecieSilviculturaActionTypes = apiActionTypes('especieSilvicultura/API_CRIAR_ESPECIE_SILVICULTURA')
const deleteEspecieSilviculturaActionTypes = apiActionTypes('especieSilvicultura/API_EXCLUIR_ESPECIE_SILVICULTURA')
const updateEspecieSilviculturaActionTypes = apiActionTypes('especieSilvicultura/API_ATUALIZAR_ESPECIE_SILVICULTURA')

export const API_LISTAR_ESPECIE_SILVICULTURA = asApiTypesObject(
  fetchEspecieSilviculturaActionTypes,
)
export const API_CRIAR_ESPECIE_SILVICULTURA = asApiTypesObject(
  createEspecieSilviculturaActionTypes,
)
export const API_EXCLUIR_ESPECIE_SILVICULTURA = asApiTypesObject(
  deleteEspecieSilviculturaActionTypes,
)
export const API_ATUALIZAR_ESPECIE_SILVICULTURA = asApiTypesObject(
  updateEspecieSilviculturaActionTypes,
)

export const apiListarEspecieSilvicultura = ({ paisId }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/especieSilvicultura`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchEspecieSilviculturaActionTypes,
}, { ...meta, query: { paisId } })

export const apiCriarEspecieSilvicultura = (especieSilvicultura, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/especieSilvicultura`,
  method: 'POST',
  types: createEspecieSilviculturaActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(especieSilvicultura),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiExcluirEspecieSilvicultura = ({ id }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/especieSilvicultura/${id}`,
  method: 'DELETE',
  types: deleteEspecieSilviculturaActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })

export const apiAtualizarEspecieSilvicultura = ({ id,
  especieSilvicultura }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/especieSilvicultura/${id}`,
  method: 'PUT',
  types: updateEspecieSilviculturaActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(especieSilvicultura),
}, { ...meta, successNotification: 'mensagens.atualizado' })
