import {
  withStyles,
  Tab,
} from '@material-ui/core'

const StyledTab = withStyles(() => ({
  root: {
    color: '#78839D',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400,
    opacity: 'unset',
  },
  selected: {
    color: '#31ade3',
  },
}))(Tab)

export default StyledTab
