import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { map } from 'lodash'
import { makeStyles, Grid, Typography } from '@material-ui/core'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { FieldText } from 'components/Fields'
import Accordion from 'components/Accordion'
import Button from 'components/Button'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import { validateFormValues } from 'utils/forms'
import { getPrincipios } from 'store/modules/principios/principios.selectors'
import { callCriarPrincipio, callAtualizarPrincipio, callExcluirPrincipio } from 'store/modules/principios'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { TIPO_USUARIO } from 'utils/constantes'

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
  screenTitle: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(5),
    fontSize: theme.typography.pxToRem(24),
  },
  listItems: {
    border: '1px solid #C9CEDB',
    boxShadow: '0px 8px 32px rgba(15, 52, 68, 0.08)',
    borderRadius: '8px',
    padding: '0px 16px',
  },
  divider: {
    height: 1,
    width: 'calc(100% + 32px)',
    marginLeft: '-16px',
    backgroundColor: theme.palette.grey.main,
  },
  accordionTitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  formButtons: {
    margin: '12px',
  },
  adicionarPrincipio: {
    marginTop: '2rem',
  },
}))

const initialValue = {
  nome: '',
  descricao: '',
  notasRodape: '',
}

const Principios = ({ paisId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const dialog = useConfirmationDialog()
  const classes = useStyles()

  const listaPrincipios = useSelector(getPrincipios)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const [listaForm, setListaForm] = useState()
  const [openItem, setOpenItem] = useState('')

  useEffect(() => {
    setListaForm(listaPrincipios)
  }, [listaPrincipios])

  const handleAdd = () => {
    setListaForm([...listaForm, { ...initialValue, numero: listaForm.length + 1 }])
    setOpenItem(listaForm.length)
  }

  const handleExpand = (item) => {
    setOpenItem(openItem === item ? '' : item)
  }

  const handleSave = (values) => {
    if (values.id) {
      return dispatch(callAtualizarPrincipio({
        id: values.id,
        principio: {
          ...values,
          updatedAt: undefined,
          createdAt: undefined,
          deletedAt: undefined,
          criterios: undefined,
        },
      }))
    }
    return dispatch(callCriarPrincipio({ ...values, paisId }))
  }

  const handleDelete = ({ id, nome }, index) => {
    if (id) {
      dialog.showConfirmation({
        title: t('dialogs.confirmarExclusao'),
        message: t('dialogs.excluirPrincipio', { principio: nome }),
      }).then((result) => result && dispatch(callExcluirPrincipio({ id })))
    } else {
      let lista = [...listaForm]
      lista = lista.slice(0, index)
      setListaForm(lista)
    }
  }

  const handleCancel = (form) => {
    form.reset()
    setOpenItem('')
  }

  const desabilitarFormulario = Boolean(tipoUsuarioLogado === TIPO_USUARIO.ADMIN.OBSERVADOR)

  return (
    <>
      <div className={classes.sectionTitle}>
        <Typography className={classes.screenTitle}>
          {t('paisesCalculos.principios')}
        </Typography>
      </div>
      <div className={classes.listItems}>
        {map(listaForm, (principio, index) => (
          <Form
            key={index}
            onSubmit={handleSave}
            initialValues={principio || initialValue}
            validate={validateFormValues(yup.object().shape({
              nome: yup.string(),
              descricao: yup.string(),
              notasRodape: yup.string(),
            }))}
            render={({ handleSubmit, values, form, pristine }) => (
              <form onSubmit={handleSubmit}>
                <Accordion
                  key={index}
                  expanded={index === openItem}
                  variant="no-borders"
                  classes={{ title: classes.accordionTitle }}
                  title={`${t('paisesCalculos.principio')} ${principio.numero ?? ''}`}
                  onDelete={() => handleDelete(values, index)}
                  onExpand={() => handleExpand(index)}
                  disableDelete={desabilitarFormulario}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FieldText
                        name="numero"
                        label={t('inputs.ordem')}
                        placeholder={t('inputs.insiraOrdemPrincipio')}
                        disabled={desabilitarFormulario}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FieldText
                        name="nome"
                        label={t('inputs.nomePrincipio')}
                        placeholder={t('inputs.insiraNomePrincipio')}
                        disabled={desabilitarFormulario}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FieldText
                        multiline
                        minRows={5}
                        name="descricao"
                        label={t('inputs.descricaoPrincipio')}
                        placeholder={t('inputs.insiraDescricaoPrincipio')}
                        disabled={desabilitarFormulario}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FieldText
                        multiline
                        minRows={5}
                        name="notasRodape"
                        label={t('inputs.notasRodape')}
                        placeholder={t('inputs.insiraNotasRodape')}
                        disabled={desabilitarFormulario}
                      />
                    </Grid>
                    <Grid container direction="row" justifyContent="space-between" className={classes.formButtons}>
                      <Grid className={classes.cancelButton} item>
                        <Button variant="danger" onClick={() => handleCancel(form)}>
                          {t('inputs.cancelar')}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button type="submit" disabled={Boolean(pristine && values.id) || desabilitarFormulario}>
                          {t('inputs.salvar')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Accordion>
                <div className={classes.divider} />
              </form>
            )}
          />
        ))}
      </div>
      <Grid container direction="row" justifyContent="flex-end" className={classes.adicionarPrincipio}>
        <Grid item>
          <Button onClick={handleAdd} disabled={desabilitarFormulario}>
            {t('paisesCalculos.adicionarPrincipio')}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

Principios.propTypes = {
  paisId: PropTypes.number,
}

export default Principios
