const ACTION_PATH = 'app/regioesHidrograficas'

export const CALL_CRIAR_REGIAOS_HIDROGRAFICAS = `${ACTION_PATH}/CALL_CRIAR_REGIAOS_HIDROGRAFICAS`
export const CALL_EXCLUIR_REGIAOS_HIDROGRAFICAS = `${ACTION_PATH}/CALL_EXCLUIR_REGIAOS_HIDROGRAFICAS`
export const CALL_ATUALIZAR_REGIAOS_HIDROGRAFICAS = `${ACTION_PATH}/CALL_ATUALIZAR_REGIAOS_HIDROGRAFICAS`

export const callCriarRegioesHidrograficas = (payload) => ({
  type: CALL_CRIAR_REGIAOS_HIDROGRAFICAS,
  payload,
})

export const callAtualizarRegioesHidrograficas = (payload) => ({
  type: CALL_ATUALIZAR_REGIAOS_HIDROGRAFICAS,
  payload,
})

export const callExcluirRegioesHidrograficas = ({ id }) => ({
  type: CALL_EXCLUIR_REGIAOS_HIDROGRAFICAS,
  payload: { id },
})
