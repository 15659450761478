import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import {
  API_GET_RELATORIO_PABS_DETALHADO,
  API_GET_RELATORIO_PABS_RESUMIDO,
  API_GET_RELATORIO_PABS_GERAL,
} from 'store/modules/api/relatorios/relatoriosPABS.actions'

const isCarregandoRelatorioPABSDetalhado =
  loadingReducer(false, API_GET_RELATORIO_PABS_DETALHADO)
const isCarregandoRelatorioPABSResumido =
  loadingReducer(false, API_GET_RELATORIO_PABS_RESUMIDO)
const isCarregandoRelatorioPABSGeral =
  loadingReducer(false, API_GET_RELATORIO_PABS_GERAL)

export default combineReducers({
  isCarregandoRelatorioPABSDetalhado,
  isCarregandoRelatorioPABSResumido,
  isCarregandoRelatorioPABSGeral,
})
