import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const getEnergiaActionTypes = apiActionTypes('energia/API_GET_ENERGIA')
const createEnergiaActionTypes = apiActionTypes('energia/API_CRIAR_ENERGIA')
const updateEnergiaActionTypes = apiActionTypes('energia/API_ATUALIZAR_ENERGIA')
const deleteEnergiaActionTypes = apiActionTypes('energia/API_EXCLUIR_ENERGIA')

export const API_GET_ENERGIA = asApiTypesObject(getEnergiaActionTypes)
export const API_CRIAR_ENERGIA = asApiTypesObject(createEnergiaActionTypes)
export const API_ATUALIZAR_ENERGIA = asApiTypesObject(updateEnergiaActionTypes)
export const API_EXCLUIR_ENERGIA = asApiTypesObject(deleteEnergiaActionTypes)

export const apiGetEnergia = (id, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/consumoEnergia/${id}`,
  method: 'GET',
  types: getEnergiaActionTypes,
}, meta)

export const apiCriarEnergia = (energia, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/consumoEnergia`,
  method: 'POST',
  types: createEnergiaActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(energia),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiAtualizarEnergia = ({ id, energia }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/consumoEnergia/${id}`,
  method: 'PUT',
  types: updateEnergiaActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(energia),
}, { ...meta, successNotification: 'mensagens.atualizado' })

export const apiExcluirEnergia = (id, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/consumoEnergia/${id}`,
  method: 'DELETE',
  types: deleteEnergiaActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })
