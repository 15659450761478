import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import {
  apiAtualizarAspectos,
} from 'store/modules/api/aspectos.actions'
import {
  CALL_ATUALIZAR_ASPECTOS,
} from 'store/modules/aspectos/aspectos.actions'

const onCallAtualizarAspecto = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_ASPECTOS),
  debounceTime(250),
  map(({ payload }) => apiAtualizarAspectos(payload)),
)

export default combineEpics(
  onCallAtualizarAspecto,
)
