import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 8,
    display: 'grid',
    paddingBottom: 8,
    gridTemplateColumns: 'repeat(5, 1fr)',
  },
  item: {
    textAlign: 'center',
    position: 'relative',
    '&:not(:last-child)::after': {
      top: 0,
      right: 0,
      width: 1,
      height: 65,
      content: '""',
      display: 'block',
      position: 'absolute',
      backgroundColor: theme.palette.grey.E7,
    },
  },
  itemValue: {
    fontSize: theme.typography.pxToRem(32),
    lineHeight: theme.typography.pxToRem(40),
    '$item:nth-child(1) &': {
      color: theme.palette.status.green[1],
    },
    '$item:nth-child(2) &': {
      color: theme.palette.status.yellow[1],
    },
    '$item:nth-child(3) &': {
      color: theme.palette.status.red[1],
    },
    '$item:nth-child(4) &': {
      color: theme.palette.status.purple[2],
    },
    '$item:nth-child(5) &': {
      color: theme.palette.grey[82],
    },
  },
  itemLabel: {
    marginTop: theme.spacing(1),
    fontSize: theme.typography.pxToRem(10),
    fontFamily: theme.typography.secondary,
  },
}))

const CardInfo = ({
  atendido,
  parcialmenteAtendido,
  naoAtendido,
  naoAplicavel,
  naoPreenchidos,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const values = [
    { label: 'indicadoresGestao.atendido', value: atendido },
    { label: 'indicadoresGestao.parcialmenteAtendido', value: parcialmenteAtendido },
    { label: 'indicadoresGestao.naoAtendido', value: naoAtendido },
    { label: 'indicadoresGestao.naoAplicavel', value: naoAplicavel },
    { label: 'indicadoresGestao.naoPreenchidos', value: naoPreenchidos },
  ]

  return (
    <div className={classes.container}>
      {values.map(({ label, value }) => (
        <div className={classes.item} key={label}>
          <div className={classes.itemValue}>
            {value && value < 10 ? `0${value}` : value}
          </div>
          <div className={classes.itemLabel}>{t(label)}</div>
        </div>
      ))}
    </div>
  )
}

CardInfo.propTypes = {
  atendido: PropTypes.number,
  parcialmenteAtendido: PropTypes.number,
  naoAtendido: PropTypes.number,
  naoAplicavel: PropTypes.number,
  naoPreenchidos: PropTypes.number,
}

export default CardInfo
