import withAuth from 'components/Auth'
import { ConnectedRouter } from 'connected-react-router'
import AlterarSenha from 'pages/AlterarSenha'
import DashboardPage from 'pages/DashboardPage'
import EsqueceuSenha from 'pages/EsqueceuSenha'
import HomePage from 'pages/HomePage'
import LoginPage from 'pages/LoginPage'
import VersionPage from 'pages/Version'
import MeuPerfil from 'pages/MeuPerfil'
import Relatorios from 'pages/Relatorios'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { history } from 'store/middleware'
// import CadastroInicial from 'pages/CadastroInicial'
import AuditoresAdm from 'pages/AdmPages/AuditoresPage/Auditores'
import EmpresasAdm from 'pages/AdmPages/EmpresasPage/Empresa'
import LicencasAdm from 'pages/AdmPages/LicencasPage/Licenca'
import PaisesCalculos from 'pages/AdmPages/PaisesCalculosPage/PaisesCalculos'
import UsuariosLifeAdm from 'pages/AdmPages/UsuariosLifePage/UsuarioLife'
import UsuariosAdm from 'pages/AdmPages/UsuariosPage/Usuario'
import AuditoriaEmpresa from 'pages/AuditorPages/Empresa/Empresa'
import AuditoriaEmpresaConclusao from 'pages/AuditorPages/Empresa/EmpresaConclusao/Conclusao'
import AuditoriaEmpresaDadosGerais from 'pages/AuditorPages/Empresa/EmpresaDadosGerais/DadosGerais'
import AuditoriaEmpresaPareceres from 'pages/AuditorPages/Empresa/EmpresaPareceres/Pareceres'
import AuditoriaEmpresaPlanoAuditoria from 'pages/AuditorPages/Empresa/EmpresaPlanoAuditoria/PlanoAuditoria'
import AuditoriaEmpresaRelatorio from 'pages/AuditorPages/Empresa/EmpresaRelatorio/Relatorio'
import ListaAuditorias from 'pages/AuditorPages/ListaAuditorias'
import MeuPerfilAuditor from 'pages/AuditorPages/MeuPerfil'
import DesempenhoBiodiversidade from 'pages/DesempenhoBiodiversidade'
import EmpresaDetalhe from 'pages/Empresa'
import EmpresaLista from 'pages/Empresa/EmpresaLista'
import IndicadoresGestao from 'pages/IndicadoresGestao'
import ParecerAuditoria from 'pages/ParecerAuditoria/ParecerAuditoria'
import PlanoAuditoria from 'pages/PlanoAuditoria/PlanoAuditoria'
import PressaoBiodiversidade from 'pages/PressaoBiodiversidade'
import UsuarioDetalhe from 'pages/UsuarioDetalhe'
import UsuarioLista from 'pages/UsuarioDetalhe/UsuarioLista'
import { VIEW_ADMIN, VIEW_AUDITOR, VIEW_CLIENT } from 'utils/constantes'

import MainWrap from './MainWrap'
import PrivateRoute from './PrivateRoute'

const AuthRoutes = withAuth(() => (
  <MainWrap>
    <ConnectedRouter
      history={history}
    >
      <Switch>
        <PrivateRoute exact requiredPermissions={VIEW_CLIENT} path="/" component={HomePage} />
        <PrivateRoute exact requiredPermissions={VIEW_CLIENT} path="/meuPerfil" component={MeuPerfil} />
        <PrivateRoute exact requiredPermissions={VIEW_CLIENT} path="/usuarios" component={UsuarioLista} />
        <PrivateRoute exact requiredPermissions={VIEW_CLIENT} path="/usuarios/:id" component={UsuarioDetalhe} />
        <PrivateRoute exact requiredPermissions={VIEW_CLIENT} path="/usuarios/novo" component={UsuarioDetalhe} />
        <PrivateRoute exact requiredPermissions={VIEW_CLIENT} path="/empresas" component={EmpresaLista} />
        <PrivateRoute exact requiredPermissions={VIEW_CLIENT} path="/dadosEmpresa" component={EmpresaDetalhe} />
        <PrivateRoute exact requiredPermissions={VIEW_CLIENT} path="/dashboard" component={DashboardPage} />
        <PrivateRoute exact requiredPermissions={VIEW_CLIENT} path="/pressaoBiodiversidade" component={PressaoBiodiversidade} />
        <PrivateRoute exact requiredPermissions={VIEW_CLIENT} path="/desempenhoBiodiversidade" component={DesempenhoBiodiversidade} />
        <PrivateRoute exact requiredPermissions={VIEW_CLIENT} path="/indicadoresGestao" component={IndicadoresGestao} />
        <PrivateRoute exact requiredPermissions={VIEW_CLIENT} path="/relatorios" component={Relatorios} />
        <PrivateRoute exact requiredPermissions={VIEW_CLIENT} path="/secaoAuditoria" component={PlanoAuditoria} />
        <PrivateRoute exact requiredPermissions={VIEW_CLIENT} path="/parecerAuditoria" component={ParecerAuditoria} />

        <PrivateRoute exact requiredPermissions={VIEW_ADMIN} path="/adm/empresas" component={EmpresasAdm} />
        <PrivateRoute exact requiredPermissions={VIEW_ADMIN} path="/adm/auditores" component={AuditoresAdm} />
        <PrivateRoute exact requiredPermissions={VIEW_ADMIN} path="/adm/usuarios" component={UsuariosAdm} />
        <PrivateRoute exact requiredPermissions={VIEW_ADMIN} path="/adm/licencas" component={LicencasAdm} />
        <PrivateRoute exact requiredPermissions={VIEW_ADMIN} path="/adm/cadastrarOc" component={() => <>CADASTRAR OC ADM</>} />
        <PrivateRoute exact requiredPermissions={VIEW_ADMIN} path="/adm/cadastrarAuditor" component={() => <>CADASTRAR AUDITOR ADM</>} />
        <PrivateRoute exact requiredPermissions={VIEW_ADMIN} path="/adm/ticketsSuporte" component={() => <>TICKETS SUPORTE ADM</>} />
        <PrivateRoute exact requiredPermissions={VIEW_ADMIN} path="/adm/paisesCalculos" component={PaisesCalculos} />
        <PrivateRoute exact requiredPermissions={VIEW_ADMIN} path="/adm/usuariosLife" component={UsuariosLifeAdm} />

        <PrivateRoute exact requiredPermissions={VIEW_AUDITOR} path="/auditorias" component={ListaAuditorias} />
        <PrivateRoute exact requiredPermissions={VIEW_AUDITOR} path="/auditoria/dadosGerais" component={AuditoriaEmpresaDadosGerais} />
        <PrivateRoute exact requiredPermissions={VIEW_AUDITOR} path="/auditoria/meuPerfil" component={MeuPerfilAuditor} />
        <PrivateRoute exact requiredPermissions={VIEW_AUDITOR} path="/auditoria/empresa" component={AuditoriaEmpresa} />
        <PrivateRoute exact requiredPermissions={VIEW_AUDITOR} path="/auditoria/relatorios" component={AuditoriaEmpresaRelatorio} />
        <PrivateRoute exact requiredPermissions={VIEW_AUDITOR} path="/auditoria/planoAuditoria" component={AuditoriaEmpresaPlanoAuditoria} />
        <PrivateRoute exact requiredPermissions={VIEW_AUDITOR} path="/auditoria/pareceres" component={AuditoriaEmpresaPareceres} />
        <PrivateRoute exact requiredPermissions={VIEW_AUDITOR} path="/auditoria/conclusao" component={AuditoriaEmpresaConclusao} />
        <PrivateRoute exact requiredPermissions={VIEW_AUDITOR} path="/auditoria/dashboard" component={UsuariosLifeAdm} />
        <Redirect to="/" />
      </Switch>
    </ConnectedRouter>
  </MainWrap>
))

const AppRouter = () => (
  <ConnectedRouter history={history}>
    <Switch>
      {/* <PrivateRoute exact requiredPermissions={VIEW_CLIENT}
      path="/cadastroInicial" component={CadastroInicial} /> */}
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/esqueciSenha" component={EsqueceuSenha} />
      <Route exact path="/alterarSenha/:token" component={AlterarSenha} />
      <Route exact path="/version" component={VersionPage} />
      <Route component={AuthRoutes} />
    </Switch>
  </ConnectedRouter>
)

export default AppRouter
