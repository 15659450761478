const ACTION_PATH = 'app/relatorios'

export const CALL_RELATORIO_INDICADORES_GESTAO_DETALHADO = `${ACTION_PATH}/CALL_RELATORIO_INDICADORES_GESTAO_DETALHADO`
export const CALL_RELATORIO_INDICADORES_GESTAO_RESUMIDO = `${ACTION_PATH}/CALL_RELATORIO_INDICADORES_GESTAO_RESUMIDO`
export const CALL_RELATORIO_INDICADORES_GESTAO_GERAL = `${ACTION_PATH}/CALL_RELATORIO_INDICADORES_GESTAO_GERAL`

export const callRelatorioIndicadoresGestaoDetalhado = (payload) => ({
  type: CALL_RELATORIO_INDICADORES_GESTAO_DETALHADO,
  payload,
})

export const callRelatorioIndicadoresGestaoResumido = (payload) => ({
  type: CALL_RELATORIO_INDICADORES_GESTAO_RESUMIDO,
  payload,
})

export const callRelatorioIndicadoresGestaoGeral = (payload) => ({
  type: CALL_RELATORIO_INDICADORES_GESTAO_GERAL,
  payload,
})
