import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import {
  apiGetIndicadorEmpresa,
  apiCriarIndicadorEmpresa,
  apiAtualizarIndicadorEmpresa,
  apiExcluirIndicadorEmpresa,
  API_CRIAR_INDICADOR_EMPRESA,
  API_ATUALIZAR_INDICADOR_EMPRESA,
} from 'store/modules/api/indicadorEmpresa.actions'
import {
  CALL_ATUALIZAR_INDICADOR_EMPRESA,
  CALL_CRIAR_INDICADOR_EMPRESA,
  CALL_EXCLUIR_INDICADOR_EMPRESA,
  CALL_SELECIONAR_INDICADOR_EMPRESA,
  CALL_LISTAR_INDICADOR_EMPRESA,
} from 'store/modules/indicadorEmpresa/indicadorEmpresa.actions'
import {
  apiListarPrincipios,
} from 'store/modules/api/principios.actions'
import { getEmpresaUsuario, getPaisEmpresa } from 'store/modules/auth/auth.selectors'

const onSelected = (action$) => action$.pipe(
  ofType(CALL_SELECIONAR_INDICADOR_EMPRESA),
  debounceTime(250),
  map(({ payload }) => apiGetIndicadorEmpresa(payload.id)),
)

const onCallCriarIndicadorEmpresa = (action$) => action$.pipe(
  ofType(CALL_CRIAR_INDICADOR_EMPRESA),
  debounceTime(250),
  map(({ payload }) => apiCriarIndicadorEmpresa(payload)),
)

const onCallAtualizarIndicadorEmpresa = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_INDICADOR_EMPRESA),
  debounceTime(250),
  map(({ payload }) => apiAtualizarIndicadorEmpresa(payload)),
)

const onCallExcluirIndicadorEmpresa = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_INDICADOR_EMPRESA),
  debounceTime(250),
  map(({ payload }) => apiExcluirIndicadorEmpresa(payload)),
)

const onCallListarIndicadorEmpresa = (action$) => action$.pipe(
  ofType(CALL_LISTAR_INDICADOR_EMPRESA),
  debounceTime(250),
  map(({ payload }) => apiListarPrincipios(payload)),
)

const onSalvarIndicadorEmpresa = (action$, state$) => action$.pipe(
  ofType(API_CRIAR_INDICADOR_EMPRESA.success, API_ATUALIZAR_INDICADOR_EMPRESA.success),
  debounceTime(250),
  map(({ payload }) => apiListarPrincipios({
    empresaId: getEmpresaUsuario(state$.value).id,
    ano: payload.indicadorEmpresa.ano,
    paisId: getPaisEmpresa(state$.value).id,
  })),
)

export default combineEpics(
  onSelected,
  onCallCriarIndicadorEmpresa,
  onCallAtualizarIndicadorEmpresa,
  onCallExcluirIndicadorEmpresa,
  onCallListarIndicadorEmpresa,
  onSalvarIndicadorEmpresa,
)
