import { split, last, toUpper } from 'lodash'

export default (nome = '') => {
  let avatar = ''
  const nomeSplit = split(nome, ' ')
  if (nomeSplit.length > 1) {
    avatar = nomeSplit[0].charAt(0) + last(nomeSplit).charAt(0)
  } else {
    avatar = nome.substring(0, 2)
  }

  return toUpper(avatar)
}
