import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation } from 'react-router-dom'
import { Box, Drawer, IconButton, Typography, makeStyles } from '@material-ui/core'
import { getDadosUsuario } from 'store/modules/auth/auth.selectors'
import ArrowDropRightLineIcon from 'remixicon-react/ArrowDropRightLineIcon'
import ArrowDropLeftLineIcon from 'remixicon-react/ArrowDropLeftLineIcon'
import Tooltip from 'components/Tooltip'
import { ReactComponent as MainLogo } from 'assets/images/menu/Logo_LK_Menu.svg'
import { VIEW_CLIENT, VIEW_ADMIN, VIEW_AUDITOR } from 'utils/constantes'
import { userItems, adminItems, auditorItems } from './items'

const drawerWidth = 328

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    letterSpacing: 0,
    width: drawerWidth,
    whiteSpace: 'nowrap',
    zIndex: theme.zIndex.appBar + 1,
    background: theme.palette.white.main,
    '& .MuiListItem-button:hover': {
      borderRadius: 8,
      '& $sideBarItemIcon, & $sideBarItemText': {
        color: theme.palette.primary.main,
      },
    },
  },
  drawerPaper: {
    scrollbarWidth: 'none',
    borderRight: `1px solid ${theme.palette.grey.F4}`,
    boxShadow: `0 ${theme.spacing(1)}px ${theme.spacing(4)}px rgba(15, 52, 68, 0.08)`,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  drawerOpen: {
    position: 'fixed',
    width: drawerWidth,
    boxShadow: '0px 8px 32px rgba(15, 52, 68, 0.08)',
    '& $toolbar': {
      left: drawerWidth - 17,
    },
  },
  drawerClose: {
    width: 100,
    alignItems: 'center',
  },
  toolbar: {
    left: 84,
    zIndex: 10,
    display: 'flex',
    position: 'fixed',
    borderRadius: '50%',
    alignItems: 'center',
    top: theme.spacing(4),
    width: theme.spacing(4),
    justifyContent: 'center',
    height: theme.spacing(4),
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey.F4}`,
  },
  list: {
    margin: '0 auto',
    width: theme.spacing(5),
    '$drawerOpen &': {
      width: '100%',
    },
  },
  title: {
    fontSize: 14,
    marginLeft: 8,
    fontWeight: 400,
  },
  logoWrapper: {
    height: 100,
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    '$drawerOpen &': {
      marginLeft: theme.spacing(2),
    },
  },
  toolbarIcon: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.blue.main,
    },
  },
  sideBarItemText: {
    fontSize: 14,
    fontWeight: 400,
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  sideBarItem: {
    padding: 0,
    display: 'block',
    textDecoration: 'none',
    whiteSpace: 'break-spaces',
    margin: theme.spacing(1, 0),
    '&:last-child': {
      margin: theme.spacing(1, 0, 4),
    },
    '$drawerOpen &': {
      display: 'flex',
      alignItems: 'center',
      margin: theme.spacing(0, 2, 1),
      width: `calc(100% - ${theme.spacing(5)}px)`,
      '&:hover': {
        borderRadius: theme.spacing(1),
        backgroundColor: theme.palette.grey.F4,
      },
    },
  },
  sideBarItemActive: {
    '& $sideBarItemIcon': {
      color: theme.palette.blue.main,
      backgroundColor: theme.palette.grey.F4,
    },
    '$drawerOpen &': {
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.grey.F4,
    },
  },
  sideBarItemIcon: {
    height: 40,
    minWidth: 40,
    display: 'flex',
    margin: '0 auto',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.spacing(1),
    color: theme.palette.primary.main,
    '$drawerOpen &': {
      margin: 0,
      '&:hover': {
        backgroundColor: theme.palette.grey.F4,
      },
    },
  },
  secondary: {
    height: 18,
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    margin: theme.spacing(3, 0, 1),
    color: theme.palette.grey.main,
    '&:first-child': {
      margin: theme.spacing(2, 0, 1),
    },
  },
  secondaryContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '$drawerOpen &': {
    },
  },
  secondaryText: {
    fontSize: 10,
    textTransform: 'uppercase',
    paddingLeft: theme.spacing(4),
    fontWeight: theme.typography.fontWeightMedium,
  },
  separator: {
    height: 2,
    borderRadius: 4,
    width: theme.spacing(3),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.grey.main,
    '$drawerOpen &': {
      display: 'none',
    },
  },
}))

const SideBar = ({ open, onChange }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const usuario = useSelector(getDadosUsuario)
  const location = useLocation()

  let items = []
  if (VIEW_CLIENT.includes(usuario?.tipoUsuario?.id)) {
    items = userItems
  } else if (VIEW_ADMIN.includes(usuario?.tipoUsuario?.id)) {
    items = adminItems
  } else if (VIEW_AUDITOR.includes(usuario?.tipoUsuario?.id)) {
    items = auditorItems
  }

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx(classes.drawerPaper, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={onChange}>
          {open ? <ArrowDropLeftLineIcon /> : <ArrowDropRightLineIcon />}
        </IconButton>
      </div>
      <div key="Logo" className={classes.logoWrapper}>
        <MainLogo />
        {open && (
          <Typography component="h1" className={classes.title}>
            {t('app.slogan')}
          </Typography>
        )}
      </div>
      <div className={classes.list}>
        {items.map(({ primary, secondary, to, icon, activeIcon }) => (primary ? (
          <Box
            className={clsx(primary && classes.sideBarItem)}
            key={`${secondary}-${primary}`}
            {...(to ? {
              to,
              exact: true,
              component: NavLink,
              activeClassName: classes.sideBarItemActive,
            } : {})}
          >
            {icon && (
              <Tooltip title={t(primary)}>
                <div className={classes.sideBarItemIcon}>
                  {location.pathname === to && activeIcon ? activeIcon : icon}
                </div>
              </Tooltip>
            )}
            {open && (
              <Typography className={classes.sideBarItemText}>
                {t(primary)}
              </Typography>
            )}
          </Box>
        ) : (
          <div key={`${secondary}-separator`} className={classes.secondary}>
            <div className={clsx(classes.separator)} />
            <Typography className={classes.secondaryText}>{t(secondary)}</Typography>
          </div>
        )))}
      </div>
    </Drawer>
  )
}

SideBar.propTypes = {
  open: PropTypes.bool,
  onChange: PropTypes.func,
}

export default SideBar
