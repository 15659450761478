import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map, ignoreElements } from 'rxjs/operators'
import {
  apiListarEmpresas,
  apiGetEmpresa,
  apiGetEmpresaUsuario,
  apiAtualizarEmpresa,
  apiCriarEmpresa,
  apiFinalizarCadastroInicial,
  apiExcluirEmpresa,
  API_FINALIZAR_CADASTRO,
  API_GET_EMPRESA_USUARIO,
} from 'store/modules/api/empresas.actions'
import {
  CALL_ATUALIZAR_EMPRESA,
  CALL_CRIAR_EMPRESA,
  CALL_SELECIONAR_EMPRESA,
  CALL_EXCLUIR_EMPRESA,
  CALL_FINALIZAR_CADASTRO_INICIAL,
} from 'store/modules/empresas/empresas.actions'
import { localStore } from 'store/localStore'
import { ofLocationChangeTo } from 'utils/location'
import { getDadosUsuario } from 'store/modules/auth/auth.selectors'

const onLocationChangeToLista = (action$) => action$.pipe(
  ofLocationChangeTo(['/empresas', '/adm/empresas']),
  debounceTime(250),
  map(({ payload }) => apiListarEmpresas(payload)),
)

const onSelected = (action$) => action$.pipe(
  ofType(CALL_SELECIONAR_EMPRESA),
  ofLocationChangeTo('/empresas/:id'),
  debounceTime(250),
  map(({ payload }) => apiGetEmpresa(payload.id)),
)

const onCallGetEmpresa = (action$) => action$.pipe(
  ofType(CALL_SELECIONAR_EMPRESA),
  debounceTime(250),
  map(({ payload }) => apiGetEmpresa(payload.id)),
)

const onDadosEmpresa = (action$, state$) => action$.pipe(
  ofLocationChangeTo('/dadosEmpresa'),
  debounceTime(250),
  map(() => apiGetEmpresa(getDadosUsuario(state$.value).empresa.id)),
)

const onCallCriarEmpresa = (action$) => action$.pipe(
  ofType(CALL_CRIAR_EMPRESA),
  debounceTime(250),
  map(({ payload }) => apiCriarEmpresa(payload)),
)

const onCallAtualizarEmpresa = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_EMPRESA),
  debounceTime(250),
  map(({ payload }) => apiAtualizarEmpresa(payload)),
)

const onCallFinalizarCadastroInicial = (action$, state$) => action$.pipe(
  ofType(CALL_FINALIZAR_CADASTRO_INICIAL),
  debounceTime(250),
  map(() => apiFinalizarCadastroInicial(getDadosUsuario(state$.value).empresa.id)),
)

const onFinalizarCadastroInicialSuccess = (action$, state$) => action$.pipe(
  ofType(API_FINALIZAR_CADASTRO.success),
  debounceTime(250),
  map(() => apiGetEmpresaUsuario(getDadosUsuario(state$.value).empresa.id)),
)

const onGetEmpresaUsuario = (action$, state$) => action$.pipe(
  ofType(API_GET_EMPRESA_USUARIO.success),
  debounceTime(250),
  map(() => localStore.saveState(state$.value)),
  ignoreElements(),
)

const onCallExcluirEmpresa = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_EMPRESA),
  debounceTime(250),
  map(({ payload }) => apiExcluirEmpresa(payload)),
)

export default combineEpics(
  onLocationChangeToLista,
  onSelected,
  onDadosEmpresa,
  onCallCriarEmpresa,
  onCallAtualizarEmpresa,
  onCallFinalizarCadastroInicial,
  onCallExcluirEmpresa,
  onCallGetEmpresa,
  onFinalizarCadastroInicialSuccess,
  onGetEmpresaUsuario,
)
