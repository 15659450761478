import React, { useState } from 'react'

const versionPage = () => {
  const version = '1.2.3.1'

  return (
    <h3>
      version: {version}
    </h3>
  )
}

export default versionPage
