import React, { useState } from 'react'
import PageTitle from 'components/PageTitle'
import { makeStyles, Grid, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Tabs from 'components/Tabs'
import { isFormChanged } from 'utils/forms'
import { diff } from 'deep-object-diff'
import RouterPrompt from 'components/AppRouter/RouterPrompt'
import MeusDados from './MeusDados'

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: '8px',
    padding: theme.spacing(1, 2, 3),
    backgroundColor: theme.palette.common.white,
    boxShadow: `0px 8px 32px ${theme.palette.shadows.main}`,
    width: '100%',
  },
  content: {
    boxSizing: 'border-box',
    padding: theme.spacing(4, 8),
    maxWidth: theme.spacing(100),
    margin: theme.spacing(0, 'auto'),
  },
}))

const MeuPerfil = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [allowTabChange, setAllowTabChange] = useState(true)

  const handleAllowTabChange = ({ initialValues, values }) => {
    const diffValues = diff(initialValues, values)
    const changed = isFormChanged({ dirtyValues: diffValues, formValues: values, initialValues })
    setAllowTabChange(!changed)
  }

  return (
    <Grid container item xs={12}>
      <RouterPrompt
        when={!allowTabChange}
        title={t('dialogs.salvarDadosTitulo')}
        message={t('dialogs.salvarDadosDescricao')}
      />
      <PageTitle title={t('meuPerfil.titulo')} />
      <Box className={classes.container}>
        <Tabs
          renderTabs={({ Tab }) => ([
            <Tab label={t('meuPerfil.meusDados')} value="0" key="0" />,
          ])}
        >
          {({ TabPanel }) => (
            <div className={classes.content}>
              <TabPanel value="0">
                <MeusDados setChangeTab={handleAllowTabChange} />
              </TabPanel>
            </div>
          )}
        </Tabs>
      </Box>
    </Grid>
  )
}

export default MeuPerfil
