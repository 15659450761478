import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { API_LISTAR_CONVERSOES_AREA } from 'store/modules/api/conversoesArea.actions'

const lista = (state = [], { type, payload }) => (
  (type === API_LISTAR_CONVERSOES_AREA.success) ? payload : state
)

const isCarregandoLista = loadingReducer(false, API_LISTAR_CONVERSOES_AREA)

export default combineReducers({
  lista,
  isCarregandoLista,
})
