import { createEpicMiddleware } from 'redux-observable'
import thunkMiddleware from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { apiMiddleware } from 'redux-api-middleware'
import { rsaaMetaMiddleware } from 'redux-foundry'
import { noop } from 'lodash'
import authMiddleware from './authMiddleware'

export const history = createBrowserHistory({
  getUserConfirmation: noop,
})
export const epicMiddleware = createEpicMiddleware()

export const middleware = [
  authMiddleware,
  rsaaMetaMiddleware,
  apiMiddleware,
  routerMiddleware(history),
  thunkMiddleware,
  epicMiddleware,
]
