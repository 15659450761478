import { history, middleware, epicMiddleware } from 'store/middleware'
import { configureStore } from '@reduxjs/toolkit'
import { localStore, LOAD_LOCAL_STORE } from './localStore'
import appReducer, { rootEpic } from './modules'

const rootReducer = (state, action) => {
  if (action.type === 'app/auth/USER_LOGOUT') {
    return appReducer(history)({}, action)
  }
  return appReducer(history)(state, action)
}

const persistedLocalState = localStore.loadState() || {}
const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState: persistedLocalState,
})

epicMiddleware.run(rootEpic)

store.dispatch({ type: LOAD_LOCAL_STORE, payload: persistedLocalState })

export default store
