import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import {
  apiSalvarNovasMatrizes,
  apiSalvarMatrizResiduo,
  apiExcluirMatrizResiduo,
} from 'store/modules/api/matrizResiduos.actions'
import {
  CALL_SALVAR_NOVAS_MATRIZES,
  CALL_SALVAR_MATRIZ_RESIDUO,
  CALL_EXCLUIR_MATRIZ_RESIDUO,
} from 'store/modules/matrizResiduos/matrizResiduos.actions'

const onCallSalvarNovasMatrizes = (action$) => action$.pipe(
  ofType(CALL_SALVAR_NOVAS_MATRIZES),
  debounceTime(250),
  map(({ payload }) => apiSalvarNovasMatrizes(payload)),
)

const onCallSalvarMatrizResiduo = (action$) => action$.pipe(
  ofType(CALL_SALVAR_MATRIZ_RESIDUO),
  debounceTime(250),
  map(({ payload }) => apiSalvarMatrizResiduo(payload)),
)

const onCallExcluirMatrizResiduo = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_MATRIZ_RESIDUO),
  debounceTime(250),
  map(({ payload }) => apiExcluirMatrizResiduo(payload)),
)

export default combineEpics(
  onCallSalvarNovasMatrizes,
  onCallSalvarMatrizResiduo,
  onCallExcluirMatrizResiduo,
)
