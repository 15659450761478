export const ACTION_PATH = 'app/auditor'

export const SELECIONAR_EMPRESA_AUDICAO = `${ACTION_PATH}/SELECIONAR_EMPRESA_AUDICAO`
export const LISTAR_DADOS_AUDITORIA = `${ACTION_PATH}/LISTAR_DADOS_AUDITORIA`
export const SALVAR_DADOS_AUDITORIA = `${ACTION_PATH}/SALVAR_DADOS_AUDITORIA`
export const CHANGE_ANO_SELECIONADO = `${ACTION_PATH}/CHANGE_ANO_SELECIONADO`
export const SALVAR_PARECER_PRESSAO = `${ACTION_PATH}/SALVAR_PARECER_PRESSAO`
export const SALVAR_PARECER_DESEMPENHO = `${ACTION_PATH}/SALVAR_PARECER_DESEMPENHO`
export const SALVAR_PARECER_INDICADOR = `${ACTION_PATH}/SALVAR_PARECER_INDICADOR`

export const callSelecionarEmpresaAudicao = (payload) => ({
  type: SELECIONAR_EMPRESA_AUDICAO,
  payload,
})

export const callListarDadosAuditoria = (payload) => ({
  type: LISTAR_DADOS_AUDITORIA,
  payload,
})

export const callSalvarDadosAuditoria = (payload) => ({
  type: SALVAR_DADOS_AUDITORIA,
  payload,
})

export const changeAnoSelecionado = (payload) => ({
  type: CHANGE_ANO_SELECIONADO,
  payload,
})

export const callSalvarParecerPressao = (payload) => ({
  type: SALVAR_PARECER_PRESSAO,
  payload,
})

export const callSalvarParecerDesempenho = (payload) => ({
  type: SALVAR_PARECER_DESEMPENHO,
  payload,
})

export const callSalvarParecerIndicador = (payload) => ({
  type: SALVAR_PARECER_INDICADOR,
  payload,
})
