import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { makeStyles, Grid, Typography } from '@material-ui/core'
import { Form } from 'react-final-form'
import Button from 'components/Button'
import { FieldText } from 'components/Fields'
import { useSelector, useDispatch } from 'react-redux'
import { getPaisSelecionado, getIsUpdatingListaPaises } from 'store/modules/paises/paises.selectors'
import { validateFormValues } from 'utils/forms'
import { callAtualizarPais } from 'store/modules/paises'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { TIPO_USUARIO } from 'utils/constantes'

const useStyles = makeStyles((theme) => ({
  rowText: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(5),
    fontSize: theme.typography.pxToRem(14),
  },
  formAdd: {
    transition: '0.20s ease-out',
    maxHeight: 0,
    overflow: 'hidden',
  },
  openFormAdd: {
    marginTop: '-10px',
    paddingTop: 10,
    maxHeight: '1300px',
    transition: '0.25s ease-in',
  },
  normalTop: {
    marginTop: '16px',
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '0 !important',
  },
  itemAlignRight: {
    maxWidth: '190px',
  },
  listItems: {
  },
  itemTitle: {
    fontFamily: 'Poppins',
    color: '#0F3444',
    fontWeight: '600',
    fontSize: '16px',
  },
  divider: {
    height: 1,
    width: 'calc(100% + 32px)',
    marginLeft: '-16px',
    backgroundColor: theme.palette.grey.main,
  },
  dividerField: {
    borderBottom: `1px solid ${theme.palette.grey.main}`,
    margin: '8px 0',
    display: 'flex',
    alignItems: 'center',
  },
  itemsForm: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '8px',
    rowGap: '20px',
  },
  itemFormField: {
    width: 'calc( 49% - 1px )',
  },
  itemFormFieldFull: {
    width: '100%',
  },
}))

const TabCalibracaoDMB = ({ paisId }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const isUpdating = useSelector(getIsUpdatingListaPaises)
  const paisSelecionado = useSelector(getPaisSelecionado)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const desabilitarFormulario = Boolean(tipoUsuarioLogado === TIPO_USUARIO.ADMIN.OBSERVADOR)

  const handleUpdate = (values) => {
    const { id } = values
    const payload = ({ ...values, paisId })
    delete payload.id
    dispatch(callAtualizarPais({ id, payload }))
  }

  const [openItem, setOpenItem] = useState('')

  const handleExpand = (item) => {
    setOpenItem(openItem === item ? '' : item)
  }

  const formFields = [
    {
      name: 'constanteACB',
      label: t('inputs.Constante'),
      required: true,
    },
    {
      name: 'fatorIIB',
      label: t('inputs.FatorCalibraçãoIPB'),
      required: true,
    },
    {
      name: 'fatorFB',
      label: t('inputs.FatorCalibraçãoFB'),
      required: true,
    },
  ]

  const FieldsForm = () => (
    formFields.map((elem, index) => (
      <Grid container spacing={2} className={classes.dividerField} key={index}>
        <Grid item xs={9} className={classes.normalTop}>
          <Typography className={classes.rowText}>
            {`${elem.label} ${elem.required ? ' *' : ''} `}
          </Typography>
        </Grid>
        <Grid item xs={3} className={classes.alignRight}>
          <FieldText
            type="number"
            name={elem.name}
            className={classes.itemAlignRight}
            InputProps={{
              inputProps: {
                style: { textAlign: 'center' },
              },
            }}
            disabled={desabilitarFormulario}
          />
        </Grid>
      </Grid>
    ))
  )

  return (
    <>
      <div className={classes.listItems}>
        <Form
          onSubmit={handleUpdate}
          validate={validateFormValues(yup.object({
            constanteACB: yup.number().required(),
            fatorIIB: yup.number().required(),
            fatorFB: yup.number().required(),
          }))}
          initialValues={{
            constanteACB: paisSelecionado?.constanteACB ?? '1',
            fatorIIB: paisSelecionado?.fatorIIB ?? '1',
            fatorFB: paisSelecionado?.fatorFB ?? '1',
            id: paisSelecionado?.id ?? '',
          }}
          render={({ handleSubmit, form, pristine, valid }) => (
            <form onSubmit={handleSubmit}>
              <Grid container>
                { FieldsForm() }
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    className={classes.normalTop}
                  >
                    <Grid item className={classes.deleteButton}>
                      <Button
                        variant="danger"
                        onClick={() => { form.reset(); handleExpand('') }}
                        disabled={pristine || !valid}
                      >
                        {t('inputs.cancelar')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        disabled={Boolean(pristine || !valid) || desabilitarFormulario}
                        loading={isUpdating}
                      >
                        {t('inputs.salvar')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </div>
    </>
  )
}

TabCalibracaoDMB.propTypes = {
  paisId: PropTypes.number,
}

export default TabCalibracaoDMB
