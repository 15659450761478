import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { API_LISTAR_RESIDUO_DESTINACOES } from 'store/modules/api/residuoDestinacoes.actions'

const lista = (state = [], { type, payload }) => (
  (type === API_LISTAR_RESIDUO_DESTINACOES.success) ? payload : state
)

const isCarregandoLista = loadingReducer(false, API_LISTAR_RESIDUO_DESTINACOES)

export default combineReducers({
  lista,
  isCarregandoLista,
})
