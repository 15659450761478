import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchIdiomasActionTypes = apiActionTypes('idiomas/API_LISTAR_IDIOMAS')

export const API_LISTAR_IDIOMAS = asApiTypesObject(fetchIdiomasActionTypes)

export const apiListarIdiomas = (data, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/idiomas`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchIdiomasActionTypes,
}, { ...meta })
