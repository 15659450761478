import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import ArrowLeftIcon from 'remixicon-react/ArrowLeftSLineIcon'
import ArrowRightIcon from 'remixicon-react/ArrowRightSLineIcon'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  pagination: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.grey['2E'],
  },
  paginationList: {
    margin: 0,
    display: 'flex',
    listStyle: 'none',
    padding: theme.spacing(0, 4),
  },
  paginationItem: {
    width: 24,
    height: 24,
    fontSize: 14,
    flexShrink: 0,
    display: 'flex',
    borderRadius: 4,
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paginationItemActive: {
    color: theme.palette.blue.dark,
    backgroundColor: theme.palette.grey.F4,
    fontWeight: theme.typography.fontWeightBold,
  },
  navigation: {
    fontSize: 12,
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    textTransform: 'lowercase',
  },
  navigationDisabled: {
    cursor: 'not-allowed',
    color: theme.palette.grey.A0,
  },
  navigationLeft: {
    marginRight: theme.spacing(1),
  },
  navigationRight: {
    marginRight: theme.spacing(2),
  },
}))

const Pagination = ({ pageSize, count, onChange, page = 0 }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const pages = Array.from(
    new Array(Math.ceil(count / pageSize)),
    (_, index) => index,
  )

  const handleChange = (index) => () => {
    if (onChange) onChange(index)
  }

  const paginateNext = () => {
    const desiredPage = page + 1
    if (onChange && desiredPage <= pages[pages.length - 1]) onChange(desiredPage)
  }

  const paginatePrevious = () => {
    const desiredPage = page - 1
    if (onChange && desiredPage >= 0) onChange(desiredPage)
  }

  return (
    <div className={classes.pagination}>
      <div
        onClick={paginatePrevious}
        className={clsx(classes.navigation, !page && classes.navigationDisabled)}
      >
        <ArrowLeftIcon className={classes.navigationLeft} />
        {t('inputs.anterior')}
      </div>
      <ul className={classes.paginationList}>
        {pages.map((item) => (
          <li
            key={item}
            onClick={handleChange(item)}
            className={clsx(
              classes.paginationItem,
              page === item && classes.paginationItemActive,
            )}
          >
            {item + 1}
          </li>
        ))}
      </ul>
      <div
        onClick={paginateNext}
        className={clsx(
          classes.navigation,
          page === pages[pages.length - 1] && classes.navigationDisabled,
        )}
      >
        {t('inputs.seguinte')}
        <ArrowRightIcon
          className={classes.navigationRight}
        />
      </div>
    </div>
  )
}

Pagination.propTypes = {
  pageSize: PropTypes.number,
  count: PropTypes.number,
  onChange: PropTypes.func,
  page: PropTypes.number,
}

export default Pagination
