import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchQualificadorAvaliacaoActionTypes = apiActionTypes('qualificadorAvaliacao/API_LISTAR_QUALIFICADOR_AVALIACAO')

export const API_LISTAR_QUALIFICADOR_AVALIACAO =
    asApiTypesObject(fetchQualificadorAvaliacaoActionTypes)

export const apiListarQualificadorAvaliacao = (data, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/qualificadorAvaliacao`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchQualificadorAvaliacaoActionTypes,
}, { ...meta })
