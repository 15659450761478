import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map, mergeMap } from 'rxjs/operators'
import { apiListarClassesIrc } from 'store/modules/api/classesIrc.actions'
import { getPaisEmpresa } from 'store/modules/auth/auth.selectors'
import { apiCriarProjeto, apiAtualizarProjeto, apiExcluirProjeto, apiGetProjeto } from 'store/modules/api/projetos.actions'
import { CALL_ATUALIZAR_PROJETO, CALL_CRIAR_PROJETO, CALL_EXCLUIR_PROJETO, CALL_SELECIONAR_PROJETO } from 'store/modules/projetos/projetos.actions'

const onSelected = (action$, state$) => action$.pipe(
  ofType(CALL_SELECIONAR_PROJETO),
  debounceTime(250),
  mergeMap(({ payload }) => {
    const paisId = getPaisEmpresa(state$.value)?.id

    return [
      apiGetProjeto(payload.id),
      apiListarClassesIrc({ projetoId: payload.id, paisId }),
    ]
  }),
)

const onCallCriarProjetos = (action$) => action$.pipe(
  ofType(CALL_CRIAR_PROJETO),
  debounceTime(250),
  map(({ payload }) => apiCriarProjeto(payload)),
)

const onCallAtualizarProjetos = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_PROJETO),
  debounceTime(250),
  map(({ payload }) => apiAtualizarProjeto(payload)),
)

const onCallExcluirProjeto = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_PROJETO),
  debounceTime(250),
  map(({ payload }) => apiExcluirProjeto(payload)),
)

export default combineEpics(
  onCallCriarProjetos,
  onCallAtualizarProjetos,
  onSelected,
  onCallExcluirProjeto,
)
