import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import {
  apiCriarTipoAtividades,
  apiExcluirTipoAtividades,
  apiAtualizarTipoAtividades,
} from 'store/modules/api/tipoAtividades.actions'

import {
  CALL_CRIAR_TIPO_ATIVIDADE,
  CALL_EXCLUIR_TIPO_ATIVIDADE,
  CALL_ATUALIZAR_TIPO_ATIVIDADE,
} from 'store/modules/tipoAtividades/tipoAtividades.actions'

const onCallCriarTipoAtividade = (action$) => action$.pipe(
  ofType(CALL_CRIAR_TIPO_ATIVIDADE),
  debounceTime(250),
  map(({ payload }) => apiCriarTipoAtividades(payload)),
)

const onCallExcluirTipoAtividade = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_TIPO_ATIVIDADE),
  debounceTime(250),
  map(({ payload }) => apiExcluirTipoAtividades(payload)),
)

const onCallAtualizarTipoAtividade = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_TIPO_ATIVIDADE),
  debounceTime(250),
  map(({ payload }) => apiAtualizarTipoAtividades(payload)),
)

export default combineEpics(
  onCallCriarTipoAtividade,
  onCallExcluirTipoAtividade,
  onCallAtualizarTipoAtividade,
)
