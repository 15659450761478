import { Grid, makeStyles } from '@material-ui/core'
import AdminLayout from 'components/AdminLayout'
import Avatar from 'components/Avatar'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import { FieldAutocomplete, FieldText } from 'components/Fields'
import PageTitle from 'components/PageTitle'
import Tag from 'components/Tag'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { callAtualizarEmpresa, callCriarEmpresa, callExcluirEmpresa, callNovaEmpresa, callSelecionarEmpresa } from 'store/modules/empresas'
import { getEmpresaSelecionada, getEmpresas } from 'store/modules/empresas/empresas.selectors'
import { getIdiomas } from 'store/modules/idiomas/idiomas.selectors'
import { getLicencas } from 'store/modules/licencas/licencas.selectors'
import { getPaises } from 'store/modules/paises/paises.selectors'
import * as yup from 'yup'

const useStyles = makeStyles((theme) => ({
  avatar: {
    display: 'flex',
    alignItems: 'center',
  },
  avatarText: {
    marginLeft: theme.spacing(2),
  },
}))

const Empresa = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const dialog = useConfirmationDialog()

  const empresas = useSelector(getEmpresas)
  const empresaSelecionada = useSelector(getEmpresaSelecionada)
  const countries = useSelector(getPaises)
  const languages = useSelector(getIdiomas)
  const licenses = useSelector(getLicencas)

  const tableColumns = [
    { title: t('empresasAdm.tabelaNome'), key: 'name', width: '30%' },
    { title: t('empresasAdm.tabelaLicenca'), key: 'type', width: '27%' },
    { title: t('empresasAdm.tabelaCadastro'), key: 'date', width: '19%' },
  ]

  const handleSave = (values) => {
    dispatch(empresaSelecionada ?
      callAtualizarEmpresa({
        empresa: { ...values, possuiDivisaoSetor: true },
        id: empresaSelecionada.id,
      }) :
      callCriarEmpresa(values))
  }

  const handleDelete = ({ id, nome }) => (
    dialog.showConfirmation({
      title: t('dialogs.confirmarExclusao'),
      message: t('dialogs.excluirEmpresa', { empresa: nome }),
    }).then((result) => result && dispatch(callExcluirEmpresa({ id })))
  )

  const handleEdit = ({ id }) => {
    dispatch(callSelecionarEmpresa({ id }))
  }

  const handleCancel = () => {
    dispatch(callNovaEmpresa())
  }

  const getStatusColor = (value) => {
    const [, status] = value.split('.')
    return ({
      ativo: 'green',
    })[status]
  }

  const getTableData = (data) => data.map((item) => ({
    ...item,
    type: item.licenca?.nome || '-',
    date: moment(item.createdAt).format('DD/MM/YYYY'),
    status: (
      <Tag variant="outlined" color={getStatusColor(item.status)}>
        {t(item.status)}
      </Tag>
    ),
    name: (
      <div className={classes.avatar}>
        <Avatar initials={item.nome} image={item.avatar} size="small" />
        <span className={classes.avatarText}>{item.nome}</span>
      </div>
    ),
  }))

  return (
    <>
      <PageTitle title={t('menuItemsAdm.contas')} />
      <AdminLayout
        title={t('menuItemsAdm.empresas')}
        buttonText={t('empresasAdm.adicionarEmpresa')}
        tableColumns={tableColumns}
        tableData={getTableData(empresas)}
        onDelete={handleDelete}
        onEdit={handleEdit}
        onCancel={handleCancel}
        renderForm={(Form) => (
          <Form
            onSave={handleSave}
            validate={yup.object({
              nome: yup.string().required(),
              paisId: yup.number().required(),
              ...(!empresaSelecionada?.id ? {
                nomeResponsavel: yup.string().required(),
              } : {}),
              ...(!empresaSelecionada?.id ? {
                emailResponsavel: yup.string().required(),
              } : {}),
              idiomaId: yup.number().required(),
              licencaId: yup.number().required(),
            })}
            title={t('empresasAdm.dadosEmpresa')}
            initialValues={{
              nome: empresaSelecionada?.nome ?? '',
              paisId: empresaSelecionada?.paisId ?? null,
              idiomaId: empresaSelecionada?.idiomaId ?? null,
              licencaId: empresaSelecionada?.licencaId ?? null,
            }}
          >
            <Grid spacing={4} container>
              <Grid item xs={12}>
                <Grid spacing={2} container>
                  <Grid item xs={6}>
                    <FieldText required name="nome" label={t('empresasAdm.nomeEmpresa')} />
                  </Grid>
                  <Grid item xs={6}>
                    <FieldAutocomplete
                      required
                      name="paisId"
                      label={t('empresasAdm.pais')}
                      options={countries.map((country) => ({
                        value: country.id,
                        label: t(country.nome),
                      }))}
                      disabled={!!empresaSelecionada?.id}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {!empresaSelecionada?.id && (
                <Grid item xs={12}>
                  <Grid spacing={2} container>
                    <Grid item xs={6}>
                      <FieldText required name="nomeResponsavel" label={t('empresasAdm.nomeResponsavel')} />
                    </Grid>
                    <Grid item xs={6}>
                      <FieldText required name="emailResponsavel" label={t('empresasAdm.emailResponsavel')} />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid spacing={2} container>
                  <Grid item xs={6}>
                    <FieldAutocomplete
                      required
                      name="idiomaId"
                      label={t('empresasAdm.idiomaPreferencia')}
                      options={languages.map((language) => ({
                        value: language.id,
                        label: t(language.label),
                      }))}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FieldAutocomplete
                      required
                      name="licencaId"
                      label={t('empresasAdm.tipoLicensa')}
                      options={licenses.map((license) => ({
                        value: license.id,
                        label: license.nome,
                      }))}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      />
    </>
  )
}

export default Empresa
