const ACTION_PATH = 'app/tiposAgua'

export const CALL_CRIAR_TIPO_AGUA = `${ACTION_PATH}/CALL_CRIAR_TIPO_AGUA`
export const CALL_EXCLUIR_TIPO_AGUA = `${ACTION_PATH}/CALL_EXCLUIR_TIPO_AGUA`
export const CALL_ATUALIZAR_TIPO_AGUA = `${ACTION_PATH}/CALL_ATUALIZAR_TIPO_AGUA`

export const callCriarTipoAgua = (payload) => ({
  type: CALL_CRIAR_TIPO_AGUA,
  payload,
})

export const callAtualizarTipoAgua = (payload) => ({
  type: CALL_ATUALIZAR_TIPO_AGUA,
  payload,
})

export const callExcluirTipoAgua = ({ id }) => ({
  type: CALL_EXCLUIR_TIPO_AGUA,
  payload: { id },
})
