import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import background01 from 'assets/images/background-01.png'
import background02 from 'assets/images/background-02.png'
import background03 from 'assets/images/background-03.png'
import background04 from 'assets/images/background-04.png'
import background05 from 'assets/images/background-05.png'
import background06 from 'assets/images/background-06.png'
import background07 from 'assets/images/background-07.png'
import background08 from 'assets/images/background-08.png'
import background09 from 'assets/images/background-09.png'
import background10 from 'assets/images/background-10.png'
import background11 from 'assets/images/background-11.png'
import background12 from 'assets/images/background-12.png'
import background13 from 'assets/images/background-13.png'
import background14 from 'assets/images/background-14.png'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  background: {
    top: 0,
    left: 0,
    height: 287,
    zIndex: -10,
    width: '100%',
    overflow: 'hidden',
    position: 'absolute',
  },
  image: {
    opacity: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
    transition: theme.transitions.create('opacity'),
  },
  visibleImage: {
    opacity: 1,
  },
}))

const Banner = ({ screen }) => {
  const classes = useStyles()
  const images = [
    background01,
    background02,
    background03,
    background04,
    background05,
    background06,
    background07,
    background08,
    background09,
    background10,
    background11,
    background12,
    background13,
    background14,
  ]

  const banners = {
    pressaoBiodiversidade: 0,
    desempenhoBiodiversidade: 1,
    indicadoresGestao: 2,
    meuPerfil: 3,
    dadosEmpresa: 3,
    empresas: 3,
    usuarios: 3,
    licencas: 3,
    usuariosLife: 12,
    paisesCalculos: 4,
    relatorios: 2,
    auditores: 5,
    auditorias: 6,
    empresa: 6,
    dadosGerais: 7,
    planoAuditoria: 8,
    pareceres: 9,
    conclusao: 10,
    secaoAuditoria: 11,
    parecerAuditoria: 12,
    dashboard: 13,
  }

  return (
    <div className={classes.background}>
      {images.map((image, imageIndex) => (
        <img
          key={image}
          src={image}
          alt="headerBg"
          className={clsx(classes.image, imageIndex === banners[screen] && classes.visibleImage)}
        />
      ))}
    </div>
  )
}

Banner.propTypes = {
  screen: PropTypes.string,
}

export default Banner
