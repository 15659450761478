import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchUnidadesActionTypes = apiActionTypes('unidades/API_LISTAR_UNIDADES')
const getUnidadeActionTypes = apiActionTypes('unidades/API_GET_UNIDADES')
const createUnidadeActionTypes = apiActionTypes('unidades/API_CRIAR_UNIDADES')
const updateUnidadeActionTypes = apiActionTypes('unidades/API_ATUALIZAR_UNIDADES')

export const API_LISTAR_UNIDADES = asApiTypesObject(fetchUnidadesActionTypes)
export const API_GET_UNIDADE = asApiTypesObject(getUnidadeActionTypes)
export const API_CRIAR_UNIDADE = asApiTypesObject(createUnidadeActionTypes)
export const API_ATUALIZAR_UNIDADE = asApiTypesObject(updateUnidadeActionTypes)

export const apiListarUnidades = (empresaId, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/unidades`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchUnidadesActionTypes,
}, { ...meta, query: { empresaId } })

export const apiCriarUnidade = (unidade, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/unidades`,
  method: 'POST',
  types: createUnidadeActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(unidade),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiAtualizarUnidade = ({ id, unidade }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/unidades/${id}`,
  method: 'PUT',
  types: updateUnidadeActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(unidade),
}, { ...meta, successNotification: 'mensagens.atualizado' })

export const apiSalvarListaUnidade = ({ lista, successNotification }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/unidades/salvarLista`,
  method: 'POST',
  types: createUnidadeActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(lista),
}, { ...meta, successNotification })
