import { filter } from 'lodash'
import { VIEW_CLIENT, VIEW_ADMIN } from 'utils/constantes'

export const getUsuarios = (state) => filter(state.usuarios.lista, (usuario) => (
  VIEW_CLIENT.includes(usuario.tipoUsuario.id)
))
export const getIsCarregandoListaUsuarios = (state) => state.usuarios.isCarregandoLista
export const getUsuarioSelecionado = (state) => state.usuarios.selecionado
export const getIsCarregandoUsuario = (state) => state.usuarios.isCarregandoUsuario
export const getUsuariosLife = (state) => filter(state.usuarios.lista, (usuario) => (
  VIEW_ADMIN.includes(usuario.tipoUsuario.id)
))
