import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import Button from 'components/Button'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { validateFormValues } from 'utils/forms'
import Accordion from 'components/Accordion'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { FieldText } from 'components/Fields'
import { getTemas, getIsSavingTemas } from 'store/modules/temas/temas.selectors'
import { callSalvarListaTema, callExcluirTema } from 'store/modules/temas'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import clsx from 'clsx'
import { filter } from 'lodash'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { TIPO_USUARIO } from 'utils/constantes'

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
  screenTitle: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(5),
    fontSize: theme.typography.pxToRem(24),
  },
  cancelButton: {
    marginRight: 'auto',
  },
  group: {
    marginTop: '32px',
    border: '1px solid #C9CEDB',
    boxShadow: '0px 8px 32px rgba(15, 52, 68, 0.08)',
    borderRadius: '8px',
    padding: '0px 16px',
  },
  divider: {
    height: 1,
    backgroundColor: theme.palette.grey.main,
    width: 'calc(100% + 32px)',
    marginLeft: '-16px',
  },
  itemTitle: {
    fontFamily: 'Poppins',
    color: '#0F3444',
    fontWeight: '600',
    fontSize: '16px',
  },
  formAdd: {
    transition: '0.20s ease-out',
    maxHeight: 0,
    overflow: 'hidden',
  },
  openFormAdd: {
    paddingTop: 10,
    marginTop: '22px',
    maxHeight: 700,
    transition: '0.25s ease-in',
  },
}))

const Temas = ({ paisId }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const dialog = useConfirmationDialog()

  const listaTemas = useSelector(getTemas)
  const isSaving = useSelector(getIsSavingTemas)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const [controlAdded, setControlAdded] = useState(false)
  const [openItem, setOpenItem] = useState('')

  const desabilitarFormulario = Boolean(tipoUsuarioLogado === TIPO_USUARIO.ADMIN.OBSERVADOR)

  const validationSchema = yup.object({
    identificador: yup.string().required(),
    nome: yup.string().required(),
    fator: yup.number().required(),
  })

  const handleExpand = (item) => {
    setOpenItem(openItem === item ? '' : item)
  }

  const handleSave = (values) => {
    const data = [...filter(listaTemas, (u) => u.id !== values.id), values]
    dispatch(callSalvarListaTema(data))
  }

  const handleAdded = (values, form) => {
    const data = [...listaTemas, values].map((item) => ({ ...item, paisId }))
    form.reset()
    Object.keys(values).forEach((key) => {
      form.resetFieldState(key)
    })
    setControlAdded(false)
    dispatch(callSalvarListaTema(data))
  }

  const handleDelete = (id, nome, identificador) => {
    handleExpand('')
    return dialog.showConfirmation({
      title: t('dialogs.confirmarExclusao'),
      message: t('dialogs.excluirTema', {
        tema: identificador ? `${identificador} - ${nome}` : '',
      }),
    }).then((result) => {
      if (result) {
        if (id) return dispatch(callExcluirTema({ id }))
      }
      return null
    })
  }

  const fieldsForm = () => (
    <>
      <Grid item xs={12} sm={2}>
        <FieldText
          required
          name="identificador"
          label={t('inputs.identificador')}
          disabled={desabilitarFormulario}
        />
      </Grid>
      <Grid item xs={12} sm={7}>
        <FieldText
          required
          name="nome"
          label={t('inputs.nomeTema')}
          disabled={desabilitarFormulario}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <FieldText
          required
          label={t('inputs.peso')}
          name="fator"
          type="number"
          disabled={desabilitarFormulario}
        />
      </Grid>
    </>
  )

  return (
    <>
      <div className={classes.sectionTitle}>
        <Typography className={classes.screenTitle}>
          {t('paisesCalculos.temas')}
        </Typography>
        <Button
          variant="default"
          disabled={controlAdded || desabilitarFormulario}
          onClick={() => {
            setControlAdded(true)
            handleExpand('')
          }}
        >
          {t('paisesCalculos.adicionarTema')}
        </Button>
      </div>

      <div
        className={clsx(
          classes.formAdd,
          controlAdded && classes.openFormAdd,
        )}
      >
        <Form
          onSubmit={handleAdded}
          validate={validateFormValues(validationSchema)}
          initialValues={{
            identificador: '',
            nome: '',
            fator: '',
          }}
          render={({ handleSubmit, form, values, pristine, valid }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                {fieldsForm()}
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                  >
                    <Grid item className={classes.deleteButton}>
                      <Button
                        variant="danger"
                        type="button"
                        onClick={() => {
                          form.reset()
                          Object.keys(values).forEach((key) => {
                            form.resetFieldState(key)
                          })
                          setControlAdded(false)
                        }}
                      >
                        {t('inputs.cancelar')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        disabled={Boolean(pristine || !valid) || desabilitarFormulario}
                        loading={isSaving}
                      >
                        {t('inputs.salvar')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </div>

      <div className={classes.group}>
        {listaTemas.map((item, index) => (
          <Form
            key={index}
            onSubmit={handleSave}
            validate={validateFormValues(validationSchema)}
            initialValues={{
              identificador: item?.identificador ?? '',
              nome: item?.nome ?? '',
              fator: item?.fator ?? '',
              id: item?.id ?? '',
            }}
            render={({ handleSubmit, form, pristine, valid }) => (
              <form onSubmit={handleSubmit}>
                <Accordion
                  hideButton
                  variant="no-borders"
                  classes={{ title: classes.itemTitle }}
                  title={`${item.identificador} - ${item.nome}`}
                  onDelete={() => handleDelete(item.id, item.nome, item.identificador)}
                  expanded={index === openItem}
                  onExpand={() => handleExpand(index)}
                  disableDelete={desabilitarFormulario}
                >
                  <Grid container spacing={3}>
                    {fieldsForm()}
                    <Grid item xs={12}>
                      <Grid
                        container
                        direction="row"
                        spacing={2}
                        justifyContent="space-between"
                      >
                        <Grid item className={classes.deleteButton}>
                          <Button
                            variant="danger"
                            type="button"
                            onClick={() => {
                              form.reset(); handleExpand('')
                            }}
                          >
                            {t('inputs.cancelar')}
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            type="submit"
                            disabled={Boolean(pristine || !valid) || desabilitarFormulario}
                            loading={isSaving}
                          >
                            {t('inputs.salvar')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Accordion>
                <div className={classes.divider} />
              </form>
            )}
          />
        ))}
      </div>
    </>
  )
}

Temas.propTypes = {
  paisId: PropTypes.number,
}

export default Temas
