import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import {
  apiListarPrincipios,
  apiGetPrincipio,
  apiAtualizarPrincipio,
  apiCriarPrincipio,
  apiExcluirPrincipio,
} from 'store/modules/api/principios.actions'
import {
  CALL_ATUALIZAR_PRINCIPIO,
  CALL_CRIAR_PRINCIPIO,
  CALL_SELECIONAR_PRINCIPIO,
  CALL_EXCLUIR_PRINCIPIO,
} from 'store/modules/principios/principios.actions'
import { ofLocationChangeTo } from 'utils/location'
import { getDadosUsuario } from 'store/modules/auth/auth.selectors'

const onLocationChangeToLista = (action$) => action$.pipe(
  ofLocationChangeTo(['/principios']),
  debounceTime(250),
  map(({ payload }) => apiListarPrincipios(payload)),
)

const onSelected = (action$) => action$.pipe(
  ofType(CALL_SELECIONAR_PRINCIPIO),
  ofLocationChangeTo('/principios/:id'),
  debounceTime(250),
  map(({ payload }) => apiGetPrincipio(payload.id)),
)

const onCallGetPrincipio = (action$) => action$.pipe(
  ofType(CALL_SELECIONAR_PRINCIPIO),
  debounceTime(250),
  map(({ payload }) => apiGetPrincipio(payload.id)),
)

const onDadosPrincipio = (action$, state$) => action$.pipe(
  ofLocationChangeTo('/dadosPrincipio'),
  debounceTime(250),
  map(() => apiGetPrincipio(getDadosUsuario(state$.value).licenca.id)),
)

const onCallCriarPrincipio = (action$) => action$.pipe(
  ofType(CALL_CRIAR_PRINCIPIO),
  debounceTime(250),
  map(({ payload }) => apiCriarPrincipio(payload)),
)

const onCallAtualizarPrincipio = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_PRINCIPIO),
  debounceTime(250),
  map(({ payload }) => apiAtualizarPrincipio(payload)),
)

const onCallExcluirPrincipio = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_PRINCIPIO),
  debounceTime(250),
  map(({ payload }) => apiExcluirPrincipio(payload)),
)

export default combineEpics(
  onLocationChangeToLista,
  onSelected,
  onDadosPrincipio,
  onCallCriarPrincipio,
  onCallAtualizarPrincipio,
  onCallExcluirPrincipio,
  onCallGetPrincipio,
)
