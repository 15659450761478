import {
  AccordionDetails,
  withStyles,
} from '@material-ui/core'

const StyledAccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(AccordionDetails)

export default StyledAccordionDetails
