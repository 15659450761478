import {
  AccordionSummary,
  withStyles,
} from '@material-ui/core'

const StyledAccordionSummary = withStyles(() => ({
  root: {
    borderRadius: 0,
    borderBottom: '1px solid #C9CEDB',
    marginBottom: -1,
    minHeight: 56,
    padding: 0,
    '&$expanded': {
      minHeight: 56,
    },
    '& .Mui-expanded': {
      transform: 'none',
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {
    borderBottom: 0,
  },
}))(AccordionSummary)

export default StyledAccordionSummary
