import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchConversoesMassaActionTypes = apiActionTypes('conversoesMassa/API_LISTAR_CONVERSOES_MASSA')

export const API_LISTAR_CONVERSOES_MASSA = asApiTypesObject(fetchConversoesMassaActionTypes)

export const apiListarConversoesMassa = (data, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/conversoesMassa`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchConversoesMassaActionTypes,
}, { ...meta })
