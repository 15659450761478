import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  tag: {
    fontSize: 12,
    borderRadius: 30,
    display: 'inline-block',
    boxSizing: 'border-box',
    padding: theme.spacing('2px', 1),
    color: (props) => theme.palette.status[props.color || 'grey'][1],
    border: (props) => `1px solid ${
      theme.palette.status[props.color || 'grey'][props.outlined ? 1 : 2]
    }`,
    backgroundColor: (props) => (
      props.outlined ? 'transparent' : theme.palette.status[props.color || 'grey'][2]
    ),
  },
}))

const Tag = ({ children, variant, color }) => {
  const classes = useStyles({ outlined: variant === 'outlined', color })

  return (
    <div className={classes.tag}>
      {children}
    </div>
  )
}

Tag.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
  color: PropTypes.oneOf(['red', 'green', 'blue', 'grey']),
}

export default Tag
