import React from 'react'
import PropTypes from 'prop-types'
import ArrowRightLineIcon from 'remixicon-react/ArrowRightLineIcon'
import { Typography, Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'relative',
    textDecoration: 'none',
    boxSizing: 'border-box',
    flexDirection: 'column',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    cursor: (props) => props.clickable && 'pointer',
    boxShadow: `0 ${theme.spacing(1)}px ${theme.spacing(4)}px ${theme.palette.shadows.main}`,
  },
  gradient: {
    top: 0,
    left: 0,
    width: '100%',
    maxHeight: 120,
    position: 'absolute',
    borderRadius: theme.spacing(1, 1, 0, 0),
    height: `calc(100% - ${theme.spacing(1)}px)`,
    background: (props) => {
      const color = theme.palette.status[props.status]?.[0] || theme.palette.common.white
      return `linear-gradient(${color}, ${theme.palette.common.white})`
    },
  },
  icon: {
    alignItems: 'center',
    display: 'inline-flex',
    width: theme.spacing(6),
    height: theme.spacing(6),
    justifyContent: 'center',
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    '& > svg': {
      transform: 'scale(1.3)',
    },
    color: (props) => theme.palette.status[props.status]?.[1] || theme.palette.primary.main,
  },
  title: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    fontFamily: theme.typography.secondary,
    marginTop: (props) => (props.small ? 0 : theme.spacing(1)),
    marginLeft: (props) => (props.small ? theme.spacing(1) : 0),
    minHeight: (props) => (props.small ? '100%' : theme.spacing(4)),
  },
  value: {
    fontSize: 32,
    display: 'flex',
    alignItems: 'flex-end',
    letterSpacing: '-0.02em',
    marginTop: theme.spacing(1),
    minHeight: theme.spacing(5),
    color: (props) => theme.palette.status[props.status]?.[1] || theme.palette.primary.main,
  },
  arrowIcon: {
    width: 16,
    right: 24,
    bottom: 19,
    color: theme.palette.grey.blue,
    margin: theme.spacing('auto', 1, '3px', 'auto'),
  },
  cardText: {
    fontSize: 10,
    marginTop: theme.spacing(3),
    color: theme.palette.primary.main,
    fontFamily: theme.typography.secondary,
  },
}))

const Card = ({
  icon: Icon,
  title,
  value,
  status,
  shrink,
  small,
  href,
  hasGradient,
  children,
  text,
  onClick,
}) => {
  const classes = useStyles({ status, shrink, small, href })
  const hrefProps = { ...(href && { target: '_blank', rel: 'noreferrer', href }) }

  return (
    <Box component={href ? 'a' : 'article'} className={classes.wrapper} {...hrefProps} onClick={onClick || null}>
      {hasGradient && <div className={classes.gradient} />}
      <Box component="header" position="relative" display={small ? 'flex' : 'block'}>
        {Icon && (
          <div className={classes.icon}>
            <Icon />
          </div>
        )}
        {title && (
          <Typography className={classes.title} variant="h1">
            {title}
          </Typography>
        )}
      </Box>
      <Box position="relative">
        {value != null && (
          <Typography className={classes.value} variant="h2">
            {value}
          </Typography>
        )}
        {!!children && children}
        {!!text && (
          <Typography className={classes.cardText}>{text}</Typography>
        )}
      </Box>
      {!!(href || onClick) && (
        <ArrowRightLineIcon className={classes.arrowIcon} />
      )}
    </Box>
  )
}

Card.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.object]),
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  status: PropTypes.oneOf(['red', 'yellow', 'blue', 'green', 'purple', 'orange']),
  shrink: PropTypes.bool,
  small: PropTypes.bool,
  hasGradient: PropTypes.bool,
  children: PropTypes.node,
  text: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
}

export default Card
