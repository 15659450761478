import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    borderRadius: 8,
    padding: theme.spacing(2),
    border: (props) => `2px solid ${theme.palette.status[props.color][0]}`,
    background: (props) => `linear-gradient(to bottom, ${theme.palette.status[props.color][0]}, ${theme.palette.common.white})`,
  },
  icon: {
    width: 48,
    height: 48,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: (props) => theme.palette.status[props.color][1],
    backgroundColor: theme.palette.common.white,
  },
  title: {
    height: 54,
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2, 0),
    color: (props) => theme.palette.status[props.color][1],
    fontSize: theme.typography.pxToRem(20),
  },
  titleBig: {
    height: 118,
  },
}))

const Card = ({ icon: Icon, title, color, children }) => {
  const classes = useStyles({ color: color || 'blue' })

  return (
    <div className={classes.wrapper}>
      {Icon && (
        <div className={classes.icon}>
          <Icon />
        </div>
      )}
      <Typography className={clsx(classes.title, !Icon && classes.titleBig)}>
        {title}
      </Typography>
      {children}
    </div>
  )
}

Card.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node,
}

export default Card
