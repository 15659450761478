import React from 'react'
import PageTitle from 'components/PageTitle'
import { makeStyles, Grid, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Tabs from 'components/Tabs'
import MeusDados from './MeusDados'

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: '8px',
    padding: theme.spacing(1, 2, 3),
    backgroundColor: theme.palette.common.white,
    boxShadow: `0px 8px 32px ${theme.palette.shadows.main}`,
    width: '100%',
  },
  content: {
    boxSizing: 'border-box',
    padding: theme.spacing(4, 8),
    maxWidth: theme.spacing(100),
    margin: theme.spacing(0, 'auto'),
  },
}))

const MeuPerfil = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Grid container item xs={12}>
      <PageTitle title={t('meuPerfil.titulo')} />
      <Box className={classes.container}>
        <Tabs
          renderTabs={({ Tab }) => ([
            <Tab label={t('meuPerfil.meusDados')} value="0" key="0" />,
          ])}
        >
          {({ TabPanel }) => (
            <div className={classes.content}>
              <TabPanel value="0">
                <MeusDados />
              </TabPanel>
            </div>
          )}
        </Tabs>
      </Box>
    </Grid>
  )
}

export default MeuPerfil
