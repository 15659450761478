import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { Grid, makeStyles } from '@material-ui/core'
import { validateFormValues } from 'utils/forms'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  title: {
    margin: 0,
    fontSize: 24,
    lineHeight: '1.9em',
    marginBottom: theme.spacing(5),
  },
  footer: {
    marginTop: theme.spacing(3),
  },
}))

const SaveForm = ({ title, onSave, onCancel, validate, initialValues, children }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const handleSave = (values) => {
    if (onSave) onSave(values)
    onCancel()
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>{title}</div>
      <Form
        onSubmit={handleSave}
        initialValues={initialValues}
        validate={validateFormValues(validate)}
      >
        {({ handleSubmit, pristine, valid }) => (
          <form onSubmit={handleSubmit}>
            {children}
            <Grid container className={classes.footer} justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button variant="danger" onClick={onCancel}>
                  {t('inputs.cancelar')}
                </Button>
              </Grid>
              <Grid item>
                <Button type="submit" disabled={pristine || !valid}>
                  {t('inputs.salvar')}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Form>
    </div>
  )
}

SaveForm.propTypes = {
  title: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  children: PropTypes.node,
  validate: PropTypes.object,
  initialValues: PropTypes.object,
}

export default SaveForm
