import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  makeStyles,
} from '@material-ui/core'
import { Form, FormSpy } from 'react-final-form'
import { FieldSelect, FieldText, FieldUpload, FieldAutocomplete } from 'components/Fields'
import * as yup from 'yup'
import { useSelector, useDispatch } from 'react-redux'
import { get, find } from 'lodash'
import { useTranslation } from 'react-i18next'
import { chain, bignumber } from 'mathjs'
import { getEnergiaSelecionado, getIsSavingEnergia } from 'store/modules/energia/energia.selectors'
import { callAtualizarEnergia, callCriarEnergia, callNovoEnergia } from 'store/modules/energia'
import { getFontesEnergia } from 'store/modules/fontesEnergia/fontesEnergia.selectors'
import { getConversoesEnergia } from 'store/modules/conversoesEnergia/conversoesEnergia.selectors'
import { getConversoesTempo } from 'store/modules/conversoesTempo/conversoesTempo.selectors'
import { validateFormValues } from 'utils/forms'
import Button from 'components/Button'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { TIPO_USUARIO } from 'utils/constantes'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(12, 1.5, 2),
  },
}))

const Energia = ({ unidadeSelecionada, anoSelecionado, setChangeTab }) => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const [exibirFormulario, setExibirFormulario] = useState(false)

  const energiaSelecionado = useSelector(getEnergiaSelecionado)
  const listaFontes = useSelector(getFontesEnergia)
  const listaConversoesEnergia = useSelector(getConversoesEnergia)
  const listaConversoesTempo = useSelector(getConversoesTempo)
  const isSaving = useSelector(getIsSavingEnergia)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const calcularQuantidade = ({ conversaoEnergiaId, conversaoTempoId, quantidade }) => {
    let quantidadeCalculada = null
    if (conversaoEnergiaId && conversaoTempoId && quantidade) {
      const conversaoEnergia = find(listaConversoesEnergia, { id: conversaoEnergiaId })
      const conversaoTempo = find(listaConversoesTempo, { id: conversaoTempoId })

      quantidadeCalculada = chain(bignumber(quantidade))
        .multiply(bignumber(conversaoEnergia.fator))
        .multiply(bignumber(conversaoTempo.fator))
        .number()
        .format({ notation: 'fixed', precision: 5 })
        .done()

      if (Number(quantidadeCalculada) === energiaSelecionado?.quantidadeCalculada) {
        return energiaSelecionado.quantidadeCalculada
      }
    }

    return quantidadeCalculada
  }

  const handleValueChange = (form, { conversaoEnergiaId, conversaoTempoId, quantidade }) => {
    form.change('quantidadeCalculada', calcularQuantidade({ conversaoEnergiaId, conversaoTempoId, quantidade }))
  }

  const alterarStateFormulario = () => {
    setExibirFormulario(!exibirFormulario)
    dispatch(callNovoEnergia())
  }

  const handleSave = (values, form) => {
    const data = {
      ...values,
      unidadeId: get(unidadeSelecionada, 'id'),
      ano: anoSelecionado,
      empresaId: get(unidadeSelecionada, 'empresaId'),
    }

    if (!energiaSelecionado && callCriarEnergia) {
      dispatch(callCriarEnergia(data))
    } else if (callAtualizarEnergia) {
      dispatch(callAtualizarEnergia({ id: energiaSelecionado.id, energia: data }))
    }
    form.reset()
    alterarStateFormulario()
  }

  useEffect(() => {
    if (energiaSelecionado?.id) {
      setExibirFormulario(true)
    }
  }, [energiaSelecionado])

  useEffect(() => {
    setExibirFormulario(false)
  }, [unidadeSelecionada])

  const fontesOrder = listaFontes
    .sort((a, b) => a.fonteEnergetica.localeCompare(b.fonteEnergetica, i18n.language))

  return exibirFormulario ? (
    <Form
      onSubmit={handleSave}
      initialValues={{
        descricao: energiaSelecionado?.descricao ?? '',
        fonteEnergiaId: energiaSelecionado?.fonteEnergiaId ?? null,
        conversaoEnergiaId: energiaSelecionado?.conversaoEnergiaId ?? null,
        conversaoTempoId: energiaSelecionado?.conversaoTempoId ?? null,
        quantidade: energiaSelecionado?.quantidade ?? null,
        evidenciaOcorrencia: energiaSelecionado?.evidenciaOcorrencia ?? '',
        comentario: energiaSelecionado?.comentario ?? '',
        quantidadeCalculada: energiaSelecionado?.quantidadeCalculada ?? null,
        anexo: energiaSelecionado?.anexo ?? null,
      }}
      validate={validateFormValues(yup.object({
        descricao: yup.string().required(),
        fonteEnergiaId: yup.number().required(),
        quantidade: yup.number().positive().required(),
        conversaoEnergiaId: yup.number().required(),
        conversaoTempoId: yup.number().required(),
        evidenciaOcorrencia: yup.string(),
        comentario: yup.string(),
      }))}
      render={({ handleSubmit, submitting, values, form, pristine, valid }) => (
        <form onSubmit={handleSubmit}>
          <FormSpy
            onChange={({ values: valuesSpy, initialValues }) => {
              setChangeTab({
                values: valuesSpy,
                initialValues,
              })
            }}
            subscription={{ values: true, initialValues: true, pristine: true }}
          />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FieldText
                required
                name="descricao"
                label={t('inputs.descricao')}
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldAutocomplete
                required
                name="fonteEnergiaId"
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                label={t('inputs.fonteEnergia')}
                options={fontesOrder.map((option) => ({
                  value: option.id,
                  label: option.fonteEnergetica,
                }))}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldText
                required
                type="number"
                name="quantidade"
                label={t('inputs.quantidade')}
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                onChange={handleValueChange(form, values)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldSelect
                required
                id="conversaoEnergiaId"
                name="conversaoEnergiaId"
                label={t('inputs.unidade')}
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                onChange={handleValueChange(form, values)}
                options={(listaConversoesEnergia || []).map((option) => ({
                  label: t(option.unidade),
                  value: option.id,
                }))}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldSelect
                required
                id="conversaoTempoId"
                name="conversaoTempoId"
                label={t('inputs.periodo')}
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                onChange={handleValueChange(form, values)}
                options={(listaConversoesTempo || []).map((option) => ({
                  label: t(option.unidade),
                  value: option.id,
                }))}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldText
                disabled
                type="number"
                name="quantidadeCalculada"
                label={t('inputs.quantidadeTepAno')}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldText
                multiline
                minRows={5}
                maxRows={5}
                name="comentario"
                label={t('inputs.comentario')}
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldText
                multiline
                minRows={5}
                maxRows={5}
                name="evidenciaOcorrencia"
                disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                label={t('inputs.evidenciaOcorrencia')}
              />
            </Grid>

            <Grid item xs={12}>
              <FieldUpload name="anexo" disabled={!unidadeSelecionada || tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR} />
            </Grid>

            <Grid container direction="row" justifyContent="space-between" className={classes.submit}>
              <Button variant="danger" onClick={alterarStateFormulario}>
                {t('inputs.cancelar')}
              </Button>
              <Button
                type="submit"
                disabled={submitting || !unidadeSelecionada || pristine || !valid}
                loading={isSaving}
              >
                {t('inputs.salvar')}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  ) : (
    <>
      {tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LEITOR && (
        <Button onClick={alterarStateFormulario}>
          {t('pressaoBiodiversidade.incluirOcorrencia')}
        </Button>
      )}
    </>
  )
}

Energia.propTypes = {
  unidadeSelecionada: PropTypes.object,
  anoSelecionado: PropTypes.string,
  setChangeTab: PropTypes.func,
}

export default Energia
