import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import { apiSalvarIrc } from 'store/modules/api/classesIrc.actions'
import { CALL_SALVAR_IRC } from 'store/modules/classesIrc/classesIrc.actions'

const onCallSalvarIrc = (action$) => action$.pipe(
  ofType(CALL_SALVAR_IRC),
  debounceTime(250),
  map(({ payload }) => apiSalvarIrc(payload)),
)

export default combineEpics(
  onCallSalvarIrc,
)
