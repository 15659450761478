import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const getIndicadorEmpresaActionTypes = apiActionTypes('indicadorEmpresa/API_GET_INDICADOR_EMPRESA')
const createIndicadorEmpresaActionTypes = apiActionTypes('indicadorEmpresa/API_CRIAR_INDICADOR_EMPRESA')
const updateIndicadorEmpresaActionTypes = apiActionTypes('indicadorEmpresa/API_ATUALIZAR_INDICADOR_EMPRESA')
const deleteIndicadorEmpresaActionTypes = apiActionTypes('indicadorEmpresa/API_EXCLUIR_INDICADOR_EMPRESA')

export const API_GET_INDICADOR_EMPRESA = asApiTypesObject(getIndicadorEmpresaActionTypes)
export const API_CRIAR_INDICADOR_EMPRESA = asApiTypesObject(createIndicadorEmpresaActionTypes)
export const API_ATUALIZAR_INDICADOR_EMPRESA = asApiTypesObject(updateIndicadorEmpresaActionTypes)
export const API_EXCLUIR_INDICADOR_EMPRESA = asApiTypesObject(deleteIndicadorEmpresaActionTypes)

export const apiGetIndicadorEmpresa = (id, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/indicadorEmpresa/${id}`,
  method: 'GET',
  types: getIndicadorEmpresaActionTypes,
}, meta)

export const apiCriarIndicadorEmpresa = (indicadorEmpresa, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/indicadorEmpresa`,
  method: 'POST',
  types: createIndicadorEmpresaActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(indicadorEmpresa),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiAtualizarIndicadorEmpresa = ({ id, indicadorEmpresa }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/indicadorEmpresa/${id}`,
  method: 'PUT',
  types: updateIndicadorEmpresaActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(indicadorEmpresa),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiExcluirIndicadorEmpresa = ({ id }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/indicadorEmpresa/${id}`,
  method: 'DELETE',
  types: deleteIndicadorEmpresaActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, meta)
