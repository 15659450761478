import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import {
  apiCriarTipoGestao,
  apiExcluirTipoGestao,
  apiAtualizarTipoGestao,
} from 'store/modules/api/tiposGestao.actions'

import {
  CALL_CRIAR_TIPO_GESTAO,
  CALL_EXCLUIR_TIPO_GESTAO,
  CALL_ATUALIZAR_TIPO_GESTAO,
} from 'store/modules/tiposGestao/tiposGestao.actions'

const onCallCriarTipoGestao = (action$) => action$.pipe(
  ofType(CALL_CRIAR_TIPO_GESTAO),
  debounceTime(250),
  map(({ payload }) => apiCriarTipoGestao(payload)),
)

const onCallExcluirTipoGestao = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_TIPO_GESTAO),
  debounceTime(250),
  map(({ payload }) => apiExcluirTipoGestao(payload)),
)

const onCallAtualizarTipoGestao = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_TIPO_GESTAO),
  debounceTime(250),

  map(({ payload }) => apiAtualizarTipoGestao(payload)),
)

export default combineEpics(
  onCallCriarTipoGestao,
  onCallExcluirTipoGestao,
  onCallAtualizarTipoGestao,
)
