const { isArray } = Array

export const ACTION_PATH = '@@redux-foundry'
export const emptyAction = () => ({ type: `${ACTION_PATH}/empty` })

export const identity = val => val

export const isNil = val => val == null

export const isUndefined = (val) => val === undefined

export const isStringOrNull = (str) => (
  str == null || typeof str === 'string'
)

export const isObject = (val) => (
  val != null && typeof val === 'object'
)

export const isNonArrayObject = (val) => (
  !isArray(val) && isObject(val)
)

export const isObjectOrNull = (val) => (
  val == null || isObject(val)
)

export const isFunction = (val) => (
  typeof val === 'function'
)

export const find = (arr, predicate) => {
  for (let i = 0, il = arr.length; i < il; i++) {
    if (predicate(arr[i], i, arr)) return arr[i]
  }
  return undefined
}

export const without = (arr, ...values) => (
  arr.filter(val => values.indexOf(val) === -1)
)

export const mapValues = (object, iteratee = identity) => {
  const keys = Object.keys(object)
  return keys.reduce((acc, cur) => {
    return { ...acc, [cur]: iteratee(cur) }
  }, {})
}

export const keyBy = (collection, iteratee) => {
  let getKey = typeof iteratee === 'string'
    ? (v) => v[iteratee]
    : iteratee
  return collection.reduce((acc, cur) => ({
    ...acc,
    [getKey(cur)]: cur
  }), {})
}

// https://vincent.billey.me/pure-javascript-immutable-array/#splice
export const immutableSplice = (arr, start, deleteCount, ...items) => (
  [ ...arr.slice(0, start), ...items, ...arr.slice(start + deleteCount) ]
)

export const applyQueryParams = (url, queryParams) => {
  const [ endpoint, hash ] = url.split('#')
  const querySeparator = endpoint.indexOf('?') === -1 ? '?' : '&'
  return `${endpoint}${querySeparator}${queryParams}${hash ? `#${hash}` : ''}`
}

const defaultGetKeys = Reflect && Reflect.ownKeys
export const matchesObject = (obj, source, getKeys = defaultGetKeys, maxLevel = 2, level = 0) => {
  const srcKeys = getKeys(source)
  return !find(srcKeys, key => {
    const val = obj[key]
    const srcVal = source[key]
    if (isObject(srcVal)) {
      if (!isObject(val)) return true
      if (level < maxLevel) return !matchesObject(val, srcVal, getKeys, maxLevel, level + 1)
    }
    return val !== srcVal
  })
}

export const normalizeArray = (val) => isArray(val) ? val : [val]
