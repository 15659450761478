import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import { TabContext, TabList } from '@material-ui/lab'
import Tab from './Tab'
import TabPanel from './TabPanel'

const useStyles = makeStyles((theme) => ({
  tabList: {
    padding: theme.spacing(0, 0.5),
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
  tabIndicator: {
    height: 4,
    transform: 'scaleX(0.8)',
    borderRadius: theme.spacing(2, 2, 0, 0),
    backgroundColor: theme.palette.blue.main,
  },
}))

const Tabs = ({ value, onChange, defaultTab = '0', renderTabs, className, children }) => {
  const classes = useStyles()
  const [initialValue, setInitialValue] = useState(defaultTab)

  const handleChange = (event, newValue) => {
    if (onChange) return onChange(newValue)
    return setInitialValue(newValue)
  }

  return (
    <TabContext value={value == null ? initialValue : value}>
      <TabList
        onChange={handleChange}
        className={clsx(classes.tabList, className)}
        classes={{ indicator: classes.tabIndicator }}
      >
        {renderTabs({ Tab })}
      </TabList>
      {children({ TabPanel, value: value == null ? initialValue : value })}
    </TabContext>
  )
}

Tabs.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.func,
  renderTabs: PropTypes.func,
  className: PropTypes.string,
  defaultTab: PropTypes.number,
}

export default Tabs
