import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { chain, bignumber } from 'mathjs'
import { useTranslation } from 'react-i18next'
import { Form, FormSpy } from 'react-final-form'
import { useSelector, useDispatch } from 'react-redux'
import { FieldCheckbox, FieldText, FieldUpload } from 'components/Fields'
import { Grid, Typography, makeStyles } from '@material-ui/core'
import { callSalvarIrc } from 'store/modules/classesIrc/classesIrc.actions'
import { getProjetoSelecionado } from 'store/modules/projetos/projetos.selectors'
import { getclassesIrc, getIsSavingClassesIrc } from 'store/modules/classesIrc/classesIrc.selectors'
import Button from 'components/Button'
import Accordion from 'components/Accordion'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { TIPO_USUARIO } from 'utils/constantes'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    marginBottom: theme.spacing(4),
    color: theme.palette.primary.main,
  },
  content: {
    borderRadius: 6,
    border: `1px solid ${theme.palette.grey.main}`,
    width: '100%',
  },
  contentHeader: {
    padding: theme.spacing('12px', 2),
  },
  contentTitle: {
    fontSize: 12,
    color: theme.palette.grey.blue,
  },
  contentRow: {
    padding: theme.spacing(1, 2),
    borderTop: `1px solid ${theme.palette.grey.main}`,
  },
  contentRowTitle: {
    fontSize: 14,
  },
  contentInfo: {
    maxHeight: 0,
    overflow: 'hidden',
    color: theme.palette.grey.A0,
    backgroundColor: theme.palette.grey.F4,
    transition: theme.transitions.create('max-height'),
  },
  contentInfoOpen: {
    maxHeight: 600,
  },
  contentInfoTitle: {
    fontSize: 12,
    borderRadius: '6px 6px 0 0',
    padding: theme.spacing(1, 2),
    fontWeight: theme.typography.fontWeightBold,
    borderTop: `1px solid ${theme.palette.grey.main}`,
  },
  contentInfoText: {
    fontSize: 14,
    padding: theme.spacing(0, 2, 3),
  },
  inputsContainer: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(4),
    borderTop: `1px solid ${theme.palette.grey.main}`,
  },
}))

const Irc = ({ setChangeTab }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const listaIrc = useSelector(getclassesIrc)
  const projeto = useSelector(getProjetoSelecionado)
  const isSaving = useSelector(getIsSavingClassesIrc)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const [openItem, setOpenItem] = useState('')
  const [indicator, setIndicator] = useState(null)

  const handleSave = (values) => {
    if (projeto && callSalvarIrc) {
      dispatch(callSalvarIrc(values))
    }
  }

  const handleAccordClick = (item) => {
    setOpenItem(openItem === item ? '' : item)
  }

  const calcularIrc = (form) => ({ values }) => {
    let fatorMonitoramento = 1
    let fatorResultado = 1

    let totalMonitoramento = 0
    let totalResultado = 0

    values?.listaIrc.forEach((classe) => {
      classe?.indicadoresIrc.forEach((indicador) => {
        if (indicador?.projetoIndicadorIrc?.monitoramento) {
          totalMonitoramento += 1
        }
        if (indicador?.projetoIndicadorIrc?.resultado) {
          totalResultado += 1
        }
      })
    })

    if (totalMonitoramento === 1) {
      fatorMonitoramento = 1.2
    } else if (totalMonitoramento === 2) {
      fatorMonitoramento = 1.3
    } else if (totalMonitoramento === 3) {
      fatorMonitoramento = 1.4
    } else if (totalMonitoramento >= 4) {
      fatorMonitoramento = 1.5
    }

    if (totalResultado === 1) {
      fatorResultado = 1.5
    } else if (totalResultado === 2) {
      fatorResultado = 1.7
    } else if (totalResultado === 3) {
      fatorResultado = 1.9
    } else if (totalResultado >= 4) {
      fatorResultado = 2
    }

    const total = chain(bignumber(fatorMonitoramento))
      .multiply(bignumber(fatorResultado))
      .number()
      .done()

    form.change('projeto.valorIrc', total)
  }

  const getCheckboxName = (index, checkboxIndex, type) => (
    `listaIrc.${index}.indicadoresIrc.${checkboxIndex}.projetoIndicadorIrc.${type}`
  )

  return (
    <Form
      onSubmit={handleSave}
      initialValues={{ listaIrc, projeto }}
      render={({ pristine, handleSubmit, form, values }) => (
        <form onSubmit={handleSubmit}>
          <FormSpy
            subscription={{ values: true }}
            onChange={calcularIrc(form)}
          />
          <FormSpy
            onChange={({ values: valuesSpy, initialValues }) => {
              setChangeTab({
                values: valuesSpy,
                initialValues,
              })
            }}
            subscription={{ values: true, initialValues: true, pristine: true }}
          />
          <Typography className={classes.title}>
            <b>{t('desempenhoBiodiversidade.tituloPrincipal')}</b>
          </Typography>
          <Typography className={classes.title}>
            <b>{t('desempenhoBiodiversidade.diretrizTitulo')}</b>
            &nbsp;
            {t('desempenhoBiodiversidade.diretrizDescricao')}
          </Typography>
          {values?.listaIrc.map((item, indexClasse) => (
            <Accordion
              key={item.nome}
              title={item.nome}
              expanded={indexClasse === openItem}
              onExpand={() => handleAccordClick(indexClasse)}
              variant={indexClasse === openItem ? 'no-borders' : 'border'}
            >
              <div className={classes.content}>
                <Grid container className={classes.contentHeader}>
                  <Grid item xs={7}>
                    <Typography className={classes.contentTitle}>
                      {t('desempenhoBiodiversidade.nome')}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} style={{ textAlign: 'center' }}>
                    <Typography className={classes.contentTitle}>
                      {t('desempenhoBiodiversidade.monitoramento')}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: 'center' }}>
                    <Typography className={classes.contentTitle}>
                      {t('desempenhoBiodiversidade.resultado')}
                    </Typography>
                  </Grid>
                </Grid>
                {item?.indicadoresIrc.map((indicador, indexIndicador) => (
                  <Fragment key={indicador.id}>
                    <Grid
                      container
                      alignItems="center"
                      className={classes.contentRow}
                      onClick={() => setIndicator(indicador.id)}
                    >
                      <Grid item xs={7}>
                        <Typography className={classes.contentRowTitle}>
                          {indicador.nome}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} style={{ textAlign: 'center' }}>
                        <FieldCheckbox
                          name={getCheckboxName(indexClasse, indexIndicador, 'monitoramento')}
                          disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                        />
                      </Grid>
                      <Grid item xs={2} style={{ textAlign: 'center' }}>
                        <FieldCheckbox
                          name={getCheckboxName(indexClasse, indexIndicador, 'resultado')}
                          disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                        />
                      </Grid>
                    </Grid>
                    <div
                      className={clsx(
                        classes.contentInfo,
                        indicator === indicador.id && classes.contentInfoOpen,
                      )}
                    >
                      <Typography className={classes.contentInfoTitle}>
                        {t('desempenhoBiodiversidade.resultadoEsperado')}
                      </Typography>
                      <Typography className={classes.contentInfoText}>
                        {indicador.descricao}
                      </Typography>
                    </div>
                  </Fragment>
                ))}
              </div>
            </Accordion>
          ))}
          <div className={classes.inputsContainer}>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={12}>
                <FieldText
                  disabled
                  name="projeto.valorIrc"
                  label={t('inputs.valorIrc')}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldText
                  multiline
                  minRows={5}
                  maxRows={5}
                  name="projeto.informacoesAdicionaisIrc"
                  label={t('inputs.comentariosReferentesIrc')}
                  placeholder={t('inputs.adicioneSeuComentario')}
                  disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldText
                  multiline
                  minRows={5}
                  maxRows={5}
                  name="projeto.evidenciaIrc"
                  label={t('inputs.evidenciaIrc')}
                  placeholder={t('inputs.comentarioEmpresa')}
                  disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldUpload
                  name="projeto.anexoIrc"
                  disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                />
              </Grid>
              {tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LEITOR && (
                <Grid item>
                  <Button type="submit" loading={isSaving} disabled={pristine}>
                    {t('inputs.salvar')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </div>
        </form>
      )}
    />
  )
}

Irc.propTypes = {
  setChangeTab: PropTypes.func,
}

export default Irc
