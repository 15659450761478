import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { API_GET_INDICADOR_EMPRESA, API_CRIAR_INDICADOR_EMPRESA, API_ATUALIZAR_INDICADOR_EMPRESA } from 'store/modules/api/indicadorEmpresa.actions'
import { API_LISTAR_PRINCIPIOS } from 'store/modules/api/principios.actions'

const selecionado = (state = null, { type, payload }) => {
  switch (type) {
    case API_GET_INDICADOR_EMPRESA.success: return payload
    default: return state
  }
}

const isCarregandoIndicadorEmpresa = loadingReducer(false, API_GET_INDICADOR_EMPRESA)

const isSaving = loadingReducer(false, {
  request: [
    API_ATUALIZAR_INDICADOR_EMPRESA.request,
    API_CRIAR_INDICADOR_EMPRESA.request,
  ],
  success: [
    API_ATUALIZAR_INDICADOR_EMPRESA.success,
    API_CRIAR_INDICADOR_EMPRESA.success,
  ],
  failure: [
    API_ATUALIZAR_INDICADOR_EMPRESA.failure,
    API_CRIAR_INDICADOR_EMPRESA.failure,
  ],
})

const anoCarregado = (state = null, { type, meta }) => (
  (type === API_LISTAR_PRINCIPIOS.success) && meta.query.ano ? meta.query.ano : state
)

export default combineReducers({
  selecionado,
  isCarregandoIndicadorEmpresa,
  isSaving,
  anoCarregado,
})
