import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { filter, map } from 'lodash'
import { API_GET_PROJETO } from 'store/modules/api/projetos.actions'
import { API_CRIAR_ACAO, API_ATUALIZAR_ACAO, API_EXCLUIR_ACAO, API_GET_ACAO } from 'store/modules/api/acoes.actions'
import { CALL_NOVO_ACAO } from 'store/modules/acoes/acoes.actions'
import { API_EXCLUIR_ANEXO } from 'store/modules/api/anexos.actions'

const lista = (state = [], { type, payload }) => {
  switch (type) {
    case API_GET_PROJETO.success: return payload.acoes
    case API_CRIAR_ACAO.success: return [...state, payload.acao]
    case API_EXCLUIR_ACAO.success: return filter(state, (o) => o.id !== payload)
    case API_ATUALIZAR_ACAO.success:
      return map(state, (o) => (o.id === payload.acao.id ? payload.acao : o))
    default: return state
  }
}

const selecionada = (state = null, { type, payload }) => {
  switch (type) {
    case API_GET_ACAO.success: return payload
    case CALL_NOVO_ACAO: return null
    case API_CRIAR_ACAO.success: return payload.acao
    case API_ATUALIZAR_ACAO.success: return payload.acao
    case API_EXCLUIR_ACAO.success: return null
    case API_EXCLUIR_ANEXO.success:
      return {
        ...state,
        anexo: state?.anexo?.id === +payload ? null : state?.anexo,
        acaoQualificadores: state?.acaoQualificadores?.map(
          (qualificador) => (qualificador.anexo && qualificador.anexo.id === +payload ?
            { ...qualificador, anexo: null } :
            qualificador
          ),
        ),
      }
    default: return state
  }
}

const isCarregandoAcoes = loadingReducer(false, API_GET_PROJETO)

const isSaving = loadingReducer(false, {
  request: [
    API_ATUALIZAR_ACAO.request,
    API_CRIAR_ACAO.request,
  ],
  success: [
    API_ATUALIZAR_ACAO.success,
    API_CRIAR_ACAO.success,
  ],
  failure: [
    API_ATUALIZAR_ACAO.failure,
    API_CRIAR_ACAO.failure,
  ],
})

export default combineReducers({
  lista,
  selecionada,
  isCarregandoAcoes,
  isSaving,
})
