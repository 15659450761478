import { isUndefined, isObject } from '../utils'

const { isArray } = Array

/**
 * processes object for safe addition/merge into collection.
 * uses idAttr as unique ID for model, if present, otherwise looks for a cid attribute.
 * if neither id nor cid exist on model, generates a new cid using provided uid method.
 *
 * @alias module:collection
 * @param {{}} model model to process
 * @param {string} idAttr id attribute assigned to models in collection
 * @param {string} cidAttr cid attribute assigned to all models in collection
 * @param {function} uid method to generate a uid as model's cid when processed without id or cid
 * @return {{}} processed model
 */
export const processCollectionModel = (model, idAttr, cidAttr, uid) => {
  // http://backbonejs.org/#Model-cid
  const modelID = model[idAttr]
  const modelCID = model[cidAttr]
  const id = isUndefined(modelID) ? null : modelID
  const cid = isUndefined(modelCID) ? (id || uid()) : modelCID
  return { ...model, [cidAttr]: cid }
}

const processModel = processCollectionModel

export const processPayload = (models, ...args) => {
  if (!isObject(models)) return []
  return isArray(models)
    ? models.map(m => processModel(m, ...args))
    : processModel(models, ...args)
}

/**
 * creates a map of "collection" action creators.
 * produces an object with same properties as a collectionActionTypes object,
 * but with cooresponding action creator functions.
 *
 * @alias module:collection
 * @param {{}} [types] collectionActionTypes object
 * @param {{}} [options] action creator options
 * @param {function} [options.uid] uid generation method
 * @return {{}} map of "collection" action identifiers to action creators
 */
const collectionActions = (types, {
  idAttribute = 'id',
  cidAttribute = 'cid',
  uid: getUID
} = {}) => {
  let idCounter = 0
  const uid = getUID || (() => `${idCounter++}`)

  const add = (models, meta) => ({
    type: types.add,
    payload: processPayload(models, idAttribute, cidAttribute, uid),
    meta
  })

  const push = (model, meta) => ({
    type: types.push,
    payload: processPayload(model, idAttribute, cidAttribute, uid),
    meta
  })

  const pop = (count, meta) => ({
    type: types.pop,
    payload: count,
    meta
  })

  const remove = (ids, meta) => ({
    type: types.remove,
    payload: ids,
    meta
  })

  const filter = (payload, meta) => ({
    type: types.filter,
    payload,
    meta
  })

  const reject = (payload, meta) => ({
    type: types.reject,
    payload,
    meta
  })

  const reset = (models, meta) => ({
    type: types.reset,
    payload: processPayload(models, idAttribute, cidAttribute, uid),
    meta
  })

  const unshift = (models, meta) => ({
    type: types.unshift,
    payload: processPayload(models, idAttribute, cidAttribute, uid),
    meta
  })

  const shift = (count, meta) => ({
    type: types.shift,
    payload: count,
    meta
  })

  const reduce = (payload, meta) => ({
    type: types.reduce,
    payload,
    meta
  })

  const batch = (actions, meta) => ({
    type: types.batch,
    payload: actions,
    meta
  })

  return {
    add,
    push,
    pop,
    remove,
    filter,
    reject,
    reset,
    unshift,
    shift,
    reduce,
    batch
  }
}

export default collectionActions
