import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import {
  apiListarGrupoTema,
  apiAtualizarGrupoTema,
  apiCriarGrupoTema,
  apiSalvarListaGrupoTema,
  apiExcluirGrupoTema,
} from 'store/modules/api/grupoTema.actions'
import {
  CALL_ATUALIZAR_GRUPO_TEMA,
  CALL_CRIAR_GRUPO_TEMA,
  CALL_SALVAR_LISTA_GRUPO_TEMA,
  CALL_EXCLUIR_GRUPO_TEMA,
} from 'store/modules/grupoTema/grupoTema.actions'
import { ofLocationChangeTo } from 'utils/location'

const onLocationChangeToLista = (action$) => action$.pipe(
  ofLocationChangeTo('/grupoTema'),
  debounceTime(250),
  map(({ payload }) => apiListarGrupoTema(payload)),
)

const onCallCriarGrupoTema = (action$) => action$.pipe(
  ofType(CALL_CRIAR_GRUPO_TEMA),
  debounceTime(250),
  map(({ payload }) => apiCriarGrupoTema(payload)),
)

const onCallAtualizarGrupoTema = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_GRUPO_TEMA),
  debounceTime(250),
  map(({ payload }) => apiAtualizarGrupoTema(payload)),
)

const onCallSalvarListaGrupoTema = (action$) => action$.pipe(
  ofType(CALL_SALVAR_LISTA_GRUPO_TEMA),
  debounceTime(250),
  map(({ payload }) => apiSalvarListaGrupoTema({ lista: payload })),
)

const onCallExcluirGrupoTema = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_GRUPO_TEMA),
  debounceTime(250),
  map(({ payload }) => apiExcluirGrupoTema(payload)),
)

export default combineEpics(
  onLocationChangeToLista,
  onCallCriarGrupoTema,
  onCallAtualizarGrupoTema,
  onCallSalvarListaGrupoTema,
  onCallExcluirGrupoTema,
)
