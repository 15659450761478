const ACTION_PATH = 'app/tiposAgua'

export const CALL_CRIAR_CLASSE_OCUPACAO = `${ACTION_PATH}/CALL_CRIAR_CLASSE_OCUPACAO`
export const CALL_EXCLUIR_CLASSE_OCUPACAO = `${ACTION_PATH}/CALL_EXCLUIR_CLASSE_OCUPACAO`
export const CALL_ATUALIZAR_CLASSE_OCUPACAO = `${ACTION_PATH}/CALL_ATUALIZAR_CLASSE_OCUPACAO`

export const callCriarClasseOcupacao = (payload) => ({
  type: CALL_CRIAR_CLASSE_OCUPACAO,
  payload,
})

export const callAtualizarClasseOcupacao = (payload) => ({
  type: CALL_ATUALIZAR_CLASSE_OCUPACAO,
  payload,
})

export const callExcluirClasseOcupacao = ({ id }) => ({
  type: CALL_EXCLUIR_CLASSE_OCUPACAO,
  payload: { id },
})
