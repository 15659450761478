import makeReduxStorage from 'utils/reduxStorage'

const ACTION_PATH = 'localStore'
export const LOAD_LOCAL_STORE = `${ACTION_PATH}/LOAD_LOCAL_STORE`

export const localStore = makeReduxStorage({
  storage: localStorage,
  paths: [
    'auth',
  ],
  stateKey: 'state',
})
