import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchClassesIrcAdminActionTypes = apiActionTypes('classesIrc/API_LISTAR_CLASSES_IRC_ADMIN')
const salvarClassesIrcAdminActionTypes = apiActionTypes('classesIrc/API_SALVAR_IRC_ADMIN')
const deleteClassesIrcAdminActionTypes = apiActionTypes('classesIrc/API_EXCLUIR_CLASSES_IRC_ADMIN')
const updateClassesIrcAdminActionTypes = apiActionTypes('classesIrc/API_ATUALIZAR_CLASSES_IRC_ADMIN')

export const API_LISTAR_CLASSES_IRC_ADMIN = asApiTypesObject(fetchClassesIrcAdminActionTypes)
export const API_SALVAR_IRC_ADMIN = asApiTypesObject(salvarClassesIrcAdminActionTypes)
export const API_EXCLUIR_CLASSES_IRC_ADMIN = asApiTypesObject(deleteClassesIrcAdminActionTypes)
export const API_ATUALIZAR_CLASSES_IRC_ADMIN = asApiTypesObject(updateClassesIrcAdminActionTypes)

export const apiListarClassesIrcAdmin = ({ paisId }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/classesIrcAdmin`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchClassesIrcAdminActionTypes,
}, { ...meta, query: { paisId } })

export const apiCriarClassesIrcAdmin = (classeIrcAdmin, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/classesIrcAdmin`,
  method: 'POST',
  types: salvarClassesIrcAdminActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(classeIrcAdmin),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiExcluirClassesIrcAdmin = ({ id }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/classesIrcAdmin/${id}`,
  method: 'DELETE',
  types: deleteClassesIrcAdminActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })

export const apiAtualizarClassesIrcAdmin = ({ id, classeIrcAdmin }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/classesIrcAdmin/${id}`,
  method: 'PUT',
  types: updateClassesIrcAdminActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(classeIrcAdmin),
}, { ...meta, successNotification: 'mensagens.atualizado' })
