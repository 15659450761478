import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box, makeStyles, Tab, Typography } from '@material-ui/core'
import Button from 'components/Button'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { TIPO_USUARIO } from 'utils/constantes'
import TabUsoSolo from './TabUsoSolo'
import Ecorregioes from './Ecorregioes'
import TabBiomas from './TabBiomas'

export const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
  screenTitle: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(5),
    fontSize: theme.typography.pxToRem(24),
  },
  panel: {
    padding: theme.spacing(4, 0),
  },
  tabIndicator: {
    height: 4,
    transform: 'scaleX(0.8)',
    borderRadius: theme.spacing(2, 2, 0, 0),
    backgroundColor: theme.palette.blue.main,
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
  tabList: {
    '& .MuiTab-root': {
      '&.Mui-selected': {
        color: '#31ADE3',
      },
    },
  },
}))

const Area = ({ paisId }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const tabs = [
    { key: '0', title: t('paisesCalculos.UsoSolo'), component: TabUsoSolo },
    { key: '1', title: t('paisesCalculos.Ecorregioes'), component: Ecorregioes },
    { key: '2', title: t('paisesCalculos.biomas'), component: TabBiomas },
  ]

  const [currentTab, setCurrentTab] = useState(tabs[0].key)

  const [controlAdded, setControlAdded] = useState(false)
  const childRef = useRef()

  const desabilitarFormulario = Boolean(tipoUsuarioLogado === TIPO_USUARIO.ADMIN.OBSERVADOR)

  const hearChange = () => {
    if (controlAdded) {
      setControlAdded(false)
    }
  }

  return (
    <>
      <div className={classes.sectionTitle}>
        <Typography className={classes.screenTitle}>
          {t('paisesCalculos.Area')}
        </Typography>
      </div>
      <TabContext value={currentTab}>
        <div className={classes.sectionHeader}>
          <Box>
            <TabList
              classes={{ indicator: classes.tabIndicator }}
              className={classes.tabList}
            >
              {tabs.map((elem, index) => (
                <Tab
                  label={elem.title}
                  value={elem.key}
                  key={index}
                  onClick={() => { hearChange(); setCurrentTab(elem.key) }}
                />
              ))}
            </TabList>
          </Box>

          {(currentTab === '0' || currentTab === '2') && (
            <Button
              disabled={controlAdded || desabilitarFormulario}
              onClick={() => { childRef.current.handleButtonAdded() }}
            >
              {currentTab === '0' ? t('inputs.AdicionarUsoSolo') : t('inputs.adicionarBioma')}
            </Button>
          )}
        </div>
        {tabs.map(({ component: Component, ...tab }) => (
          <TabPanel className={classes.panel} value={tab.key} key={tab.key}>
            {
              (tab.key === '0' || tab.key === '2') ? (
                <Component
                  paisId={paisId}
                  controlAdded={controlAdded}
                  setControlAdded={setControlAdded}
                  ref={childRef}
                />
              ) : (
                <Component
                  paisId={paisId}
                />
              )
            }

          </TabPanel>
        ))}
      </TabContext>
    </>
  )
}

Area.propTypes = {
  paisId: PropTypes.number,
}

export default Area
