import { matchesType } from '../core'
import { normalizeApiTypeArrays } from './apiActionTypes'
/**
 * Basic true/false loading indicator.  Can be used with a single endpoint by using
 * the request, success, failure actions for the RSAA. You can also specify the action
 * types as arrays, in cases where more than 1 action type should be handled for
 * request, success, and/or failure cases.
 * ```
 * type RequestActionTypes = {
 *   request: string | string[],
 *   success: string | string[],
 *   failure: string | string[]
 * }
 * type RequestActionTypesArr = [
 *   request: string | string[],
 *   success: string | string[],
 *   failure: string | string[]
 * ]
 * type ApiActionTypes = RequestActionTypes | RequestActionTypesArr
 * (defaultState: bool, actionTypes: ApiActionTypes) =>
 *   (state: bool, action: FSA) => bool
 * ```
 * @alias module:api
 * @param {boolean} defaultState initial state to return, true or false
 * @param {({}|Array)} actionTypes configured action types (see type ApiActionTypes)
 * @return {function} redux reducer function
 */
const loadingReducer = (
  defaultState = false,
  actionTypes = {}
) => {
  const [ request, success, failure ] = normalizeApiTypeArrays(actionTypes)

  return (state = defaultState, action = {}) => {
    if (matchesType(action, ...success, ...failure)) {
      return false
    }
    if (matchesType(action, ...request)) {
      if (action.error) return false
      return true
    }
    return state
  }
}

export default loadingReducer
