const ACTION_PATH = 'app/paises'

export const CALL_CRIAR_PAIS = `${ACTION_PATH}/CALL_CRIAR_PAIS`
export const CALL_ATUALIZAR_PAIS = `${ACTION_PATH}/CALL_ATUALIZAR_PAIS`
export const CALL_SELECIONAR_PAIS = `${ACTION_PATH}/CALL_SELECIONAR_PAIS`
export const CALL_NOVO_PAIS = `${ACTION_PATH}/CALL_NOVO_PAIS`
export const CALL_EXCLUIR_PAIS = `${ACTION_PATH}/CALL_EXCLUIR_PAIS`

export const callSelecionarPais = ({ id }) => ({
  payload: { id },
  type: CALL_SELECIONAR_PAIS,
})

export const callCriarPais = (payload) => ({
  payload,
  type: CALL_CRIAR_PAIS,
})

export const callAtualizarPais = ({ id, payload }) => ({
  payload: { id, payload },
  type: CALL_ATUALIZAR_PAIS,
})

export const callExcluirPais = ({ id }) => ({
  payload: { id },
  type: CALL_EXCLUIR_PAIS,
})

export const callNovoPais = () => ({
  type: CALL_NOVO_PAIS,
})
