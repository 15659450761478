import React, { useRef } from 'react'
import {
  Container,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TableContainer,
  IconButton,
  makeStyles,
} from '@material-ui/core'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import { useSelector, useDispatch } from 'react-redux'
import { map, find } from 'lodash'
import { getEnergia } from 'store/modules/energia/energia.selectors'
import { callExcluirEnergia, callSelecionarEnergia } from 'store/modules/energia'
import { useTranslation } from 'react-i18next'
import { formatDecimal } from 'utils/numbers'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { TIPO_USUARIO } from 'utils/constantes'

const useStyles = makeStyles((theme) => ({
  main: {
    color: '#dc3545',
    padding: 0,
    '& .MuiSvgIcon-root': {
      fill: theme.palette.grey.blue,
      '&:hover': {
        fill: theme.palette.blue.main,
      },
    },
  },
  title: {
    fontFamily: theme.typography.secondary,
    fontWeight: 400,
    fontSize: 12,
    color: theme.palette.grey.blue,
  },
  description: {
    fontFamily: theme.typography.secondary,
    fontWeight: 400,
    fontSize: 14,
    color: theme.palette.primary.main,
  },
}))

const EnergiaLista = () => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const dialog = useConfirmationDialog()
  const ref = useRef()

  const energias = useSelector(getEnergia)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const handleEdit = (id) => {
    window.scrollTo(0, ref.current.offsetTop - 80)
    dispatch(callSelecionarEnergia({ id }))
  }

  const handleDelete = async (id) => {
    if (tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LEITOR) {
      const deletar = find(energias, { id })
      const result = await dialog.showConfirmation({
        title: t('dialogs.confirmarExclusao'),
        message: t('dialogs.excluirOcorrencia', { ocorrencia: deletar.descricao }),
      })
      if (result) {
        dispatch(callExcluirEnergia({ id }))
      }
    }
  }

  return (
    <Container className={classes.main} ref={ref}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.title}>{t('pressaoBiodiversidade.ocorrencia')}</TableCell>
              <TableCell className={classes.title}>{t('pressaoBiodiversidade.TepAno')}</TableCell>
              <TableCell className={classes.title}>{t('pressaoBiodiversidade.acoes')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {map(energias, ({ id, descricao, quantidadeCalculada }) => (
              <TableRow key={id}>
                <TableCell className={classes.description}>{descricao}</TableCell>
                <TableCell className={classes.description}>
                  {formatDecimal({
                    value: quantidadeCalculada,
                    min: 2,
                    max: 20,
                    locale: i18n.language,
                  })}
                </TableCell>
                <TableCell>
                  <IconButton aria-label="edit" onClick={() => handleEdit(id)}>
                    <EditOutlinedIcon fontSize="medium" />
                  </IconButton>
                  <IconButton aria-label="delete" onClick={() => handleDelete(id)}>
                    <DeleteOutlinedIcon fontSize="medium" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  )
}

export default EnergiaLista
