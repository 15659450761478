import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { API_LISTAR_GRUPOS, API_EXCLUIR_GRUPO, API_CRIAR_GRUPO } from 'store/modules/api/grupos.actions'
import { filter } from 'lodash'

const lista = (state = [], { type, payload }) => {
  switch (type) {
    case API_LISTAR_GRUPOS.success: return payload
    case API_CRIAR_GRUPO.success: return payload
    case API_EXCLUIR_GRUPO.success: return filter(state, (o) => o.id !== payload)
    default: return state
  }
}

const isCarregandoLista = loadingReducer(false, API_LISTAR_GRUPOS)

const isSaving = loadingReducer(false, API_CRIAR_GRUPO)

export default combineReducers({
  lista,
  isCarregandoLista,
  isSaving,
})
