import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import {
  apiCriarRegioesHidrograficas,
  apiExcluirRegioesHidrograficas,
  apiAtualizarRegioesHidrograficas,
} from 'store/modules/api/regioesHidrograficas.actions'
import {
  CALL_CRIAR_REGIAOS_HIDROGRAFICAS,
  CALL_EXCLUIR_REGIAOS_HIDROGRAFICAS,
  CALL_ATUALIZAR_REGIAOS_HIDROGRAFICAS,
} from 'store/modules/regioesHidrograficas/regioesHidrograficas.actions'

const onCallCriarRegioesHidrograficas = (action$) => action$.pipe(
  ofType(CALL_CRIAR_REGIAOS_HIDROGRAFICAS),
  debounceTime(250),
  map(({ payload }) => apiCriarRegioesHidrograficas(payload)),
)

const onCallExcluirRegioesHidrograficas = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_REGIAOS_HIDROGRAFICAS),
  debounceTime(250),
  map(({ payload }) => apiExcluirRegioesHidrograficas(payload)),
)

const onCallAtualizarRegioesHidrograficas = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_REGIAOS_HIDROGRAFICAS),
  debounceTime(250),
  map(({ payload }) => apiAtualizarRegioesHidrograficas(payload)),
)

export default combineEpics(
  onCallCriarRegioesHidrograficas,
  onCallExcluirRegioesHidrograficas,
  onCallAtualizarRegioesHidrograficas,
)
