import React from 'react'
import {
  Typography,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TableContainer,
  CircularProgress,
  IconButton,
  makeStyles,
  Box,
} from '@material-ui/core'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import { useSelector, useDispatch } from 'react-redux'
import { map, filter } from 'lodash'
import { getUsuarios, getIsCarregandoListaUsuarios } from 'store/modules/usuarios/usuarios.selectors'
import { callExcluirUsuario, callSelecionarUsuario } from 'store/modules/usuarios'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import { useTranslation } from 'react-i18next'
import { getEmpresaSelecionada } from 'store/modules/empresas/empresas.selectors'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { TIPO_USUARIO } from 'utils/constantes'
import PropTypes from 'prop-types'
import EmpresaUsuarioCadastro from './EmpresaUsuarioCadastro'

const useStyles = makeStyles((theme) => ({
  avatarRounded: {
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    backgroundColor: '#C4C4C4',
    marginRight: theme.spacing(2),
  },
  avatarDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  gridIcon: {
    color: '#9E9E9E',
    width: 20,
    height: 20,
  },
  gridHeadText: {
    color: '#9E9E9E',
    padding: '0 16px',
    borderBottom: 'none',
  },
  title: {
    marginBottom: theme.spacing(4),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: '24px',
    fontWeight: 400,
    color: theme.palette.primary.main,
  },
  container: {
    maxWidth: 672,
    margin: theme.spacing(0, 'auto', 5),
  },
  firstCell: {
    padding: 0,
  },
  ellipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '215px',
  },
  ellipsisEmail: {
    width: '182px',
  },
  lastCell: {
    padding: theme.spacing(2, 0),
  },
  actionsCell: {
    padding: theme.spacing(2, 0),
    width: 60,
  },
  icon: {
    display: 'inline-table',
    margin: '0 5px',
  },
  mailCell: {
    maxWidth: 182,
    overflowWrap: 'break-word',
  },
}))

const EmpresaUsuarioLista = ({ setChangeTab }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const dialog = useConfirmationDialog()

  const isLoading = useSelector(getIsCarregandoListaUsuarios)
  const empresa = useSelector(getEmpresaSelecionada)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const usuarios = filter(useSelector(getUsuarios), { empresa: { id: empresa?.id } })

  const classes = useStyles()

  const handleEdit = (id) => {
    dispatch(callSelecionarUsuario({ id }))
  }

  const handleDelete = async (id, nome) => {
    const result = await dialog.showConfirmation({
      title: t('dialogs.confirmarExclusao'),
      message: t('dialogs.excluirUsuario', { usuario: nome }),
    })
    if (result) {
      dispatch(callExcluirUsuario({ id }))
    }
  }

  return (
    <Box className={classes.container}>
      <EmpresaUsuarioCadastro setChangeTab={setChangeTab} />
      <Typography variant="h6" className={classes.title}>
        {t('usuario.usuariosCadastrados')}
      </Typography>
      {isLoading && <CircularProgress />}
      {!isLoading && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={`${classes.gridHeadText} ${classes.firstCell}`}>{t('inputs.nome')}</TableCell>
                <TableCell className={classes.gridHeadText}>{t('inputs.email')}</TableCell>
                <TableCell className={classes.gridHeadText}>{t('inputs.tipoPermissao')}</TableCell>
                {tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LIDER && (
                  <TableCell className={classes.gridHeadText}>{t('inputs.acoes')}</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {map(usuarios, ({ id, nome, email, tipoUsuario }) => (
                <TableRow key={id}>
                  <TableCell className={classes.firstCell}>
                    <p className={classes.ellipsis}>{nome}</p>
                  </TableCell>
                  <TableCell className={classes.mailCell}>
                    <p className={`${classes.ellipsis} ${classes.ellipsisEmail}`}>{email}</p>
                  </TableCell>
                  <TableCell>{t(tipoUsuario.tipo)}</TableCell>
                  {tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LIDER && (
                    <TableCell className={classes.actionsCell} align="right">
                      <IconButton aria-label="edit" onClick={() => handleEdit(id)} className={classes.icon}>
                        <EditOutlinedIcon fontSize="medium" className={classes.gridIcon} />
                      </IconButton>
                      <IconButton aria-label="delete" onClick={() => handleDelete(id, nome)} className={classes.icon}>
                        <DeleteOutlinedIcon fontSize="medium" className={classes.gridIcon} />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  )
}

EmpresaUsuarioLista.propTypes = {
  setChangeTab: PropTypes.func,
}

export default EmpresaUsuarioLista
