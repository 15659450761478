import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from '../apiAction'

const getRelatorioPABSDetalhadoActionTypes = apiActionTypes('relatorios/API_GET_RELATORIO_PABS_DETALHADO')
const getRelatorioPABSResumidoActionTypes = apiActionTypes('relatorios/API_GET_RELATORIO_PABS_RESUMIDO')
const getRelatorioPABSGeralActionTypes = apiActionTypes('relatorios/API_GET_RELATORIO_PABS_GERAL')

export const API_GET_RELATORIO_PABS_DETALHADO =
  asApiTypesObject(getRelatorioPABSDetalhadoActionTypes)
export const API_GET_RELATORIO_PABS_RESUMIDO =
  asApiTypesObject(getRelatorioPABSResumidoActionTypes)
export const API_GET_RELATORIO_PABS_GERAL =
  asApiTypesObject(getRelatorioPABSGeralActionTypes)

export const apiGetRelatorioPABSDetalhado = ({
  idioma, anoBase, empresaId,
}, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/relatorios/relatorioPABSDetalhado`,
  method: 'GET',
  types: getRelatorioPABSDetalhadoActionTypes,
}, { ...meta, query: { idioma, anoBase, empresaId } })

export const apiGetRelatorioPABSResumido = ({
  idioma, anoBase, empresaId,
}, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/relatorios/relatorioPABSResumido`,
  method: 'GET',
  types: getRelatorioPABSResumidoActionTypes,
}, { ...meta, query: { idioma, anoBase, empresaId } })

export const apiGetRelatorioPABSGeral = ({
  idioma, anoBase, empresaId,
}, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/relatorios/relatorioPABSGeral`,
  method: 'GET',
  types: getRelatorioPABSGeralActionTypes,
}, { ...meta, query: { idioma, anoBase, empresaId } })
