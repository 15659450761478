import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { API_LISTAR_DIVISOES_SETORES } from 'store/modules/api/divisoesSetores.actions'

const lista = (state = null, { type, payload }) => (
  (type === API_LISTAR_DIVISOES_SETORES.success) ? payload.lista : state
)

const total = (state = null, { type, payload }) => (
  (type === API_LISTAR_DIVISOES_SETORES.success) ? payload.total : state
)

const isCarregandoLista = loadingReducer(false, API_LISTAR_DIVISOES_SETORES)

export default combineReducers({
  lista,
  total,
  isCarregandoLista,
})
