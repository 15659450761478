import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchUsersActionTypes = apiActionTypes('usuarios/API_FETCH_USERS')
const getUserActionTypes = apiActionTypes('usuarios/API_GET_USER')
const createUsuarioActionTypes = apiActionTypes('usuarios/API_CRIAR_USUARIO')
const updateUsuarioActionTypes = apiActionTypes('usuarios/API_ATUALIZAR_USUARIO')
const deleteUsuarioActionTypes = apiActionTypes('usuarios/API_EXCLUIR_USUARIO')

export const API_FETCH_USERS = asApiTypesObject(fetchUsersActionTypes)
export const API_GET_USER = asApiTypesObject(getUserActionTypes)
export const API_CRIAR_USUARIO = asApiTypesObject(createUsuarioActionTypes)
export const API_ATUALIZAR_USUARIO = asApiTypesObject(updateUsuarioActionTypes)
export const API_EXCLUIR_USUARIO = asApiTypesObject(deleteUsuarioActionTypes)

export const apiFetchUsers = (empresaId, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/usuarios`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchUsersActionTypes,
}, { ...meta, ...empresaId && ({ query: { empresaId } }) })

export const apiGetUsuario = (id, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/usuarios/${id}`,
  method: 'GET',
  types: getUserActionTypes,
}, meta)

export const apiCriarUsuario = (usuario, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/usuarios`,
  method: 'POST',
  types: createUsuarioActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(usuario),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiAtualizarUsuario = ({ id, usuario, successNotification }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/usuarios/${id}`,
  method: 'PUT',
  types: updateUsuarioActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(usuario),
}, { ...meta, successNotification })

export const apiExcluirUsuario = ({ id }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/usuarios/${id}`,
  method: 'DELETE',
  types: deleteUsuarioActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })
