import {
  Button,
  withStyles,
} from '@material-ui/core'

const StyledFillBtn = withStyles((theme) => ({
  root: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.blue.main,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white.main,
    },
  },
}))(Button)

export default StyledFillBtn
