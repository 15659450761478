import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { filter, map } from 'lodash'
import {
  API_LISTAR_PRINCIPIOS,
  API_CRIAR_PRINCIPIO,
  API_EXCLUIR_PRINCIPIO,
  API_ATUALIZAR_PRINCIPIO,
} from 'store/modules/api/principios.actions'

const lista = (state = [], { type, payload }) => {
  switch (type) {
    case API_LISTAR_PRINCIPIOS.success: return payload
    case API_CRIAR_PRINCIPIO.success: return [...state, payload]
    case API_EXCLUIR_PRINCIPIO.success: return filter(state, (o) => o.id !== payload)
    case API_ATUALIZAR_PRINCIPIO.success:
      return map(state, (o) => (o.id === payload.id ? payload : o))
    default: return state
  }
}

const isCarregandoLista = loadingReducer(false, API_LISTAR_PRINCIPIOS)

export default combineReducers({
  lista,
  isCarregandoLista,
})
