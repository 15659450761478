import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import {
  apiListarCriteriosPrincipio,
  apiAtualizarCriterio,
  apiCriarCriterio,
  apiExcluirCriterio,
} from 'store/modules/api/criterios.actions'
import {
  CALL_LISTAR_CRITERIOS_PRINCIPIO,
  CALL_ATUALIZAR_CRITERIO,
  CALL_CRIAR_CRITERIO,
  CALL_EXCLUIR_CRITERIO,
} from 'store/modules/criterios/criterios.actions'

const onCallBuscarCriteriosPrincipio = (action$) => action$.pipe(
  ofType(CALL_LISTAR_CRITERIOS_PRINCIPIO),
  debounceTime(250),
  map(({ payload }) => apiListarCriteriosPrincipio(payload)),
)

const onCallCriarCriterio = (action$) => action$.pipe(
  ofType(CALL_CRIAR_CRITERIO),
  debounceTime(250),
  map(({ payload }) => apiCriarCriterio(payload)),
)

const onCallAtualizarCriterio = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_CRITERIO),
  debounceTime(250),
  map(({ payload }) => apiAtualizarCriterio(payload)),
)

const onCallExcluirCriterio = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_CRITERIO),
  debounceTime(250),
  map(({ payload }) => apiExcluirCriterio(payload)),
)

export default combineEpics(
  onCallBuscarCriteriosPrincipio,
  onCallCriarCriterio,
  onCallAtualizarCriterio,
  onCallExcluirCriterio,
)
