const ACTION_PATH = 'app/relatorios'

export const CALL_RELATORIO_IPB_DETALHADO = `${ACTION_PATH}/CALL_RELATORIO_IPB_DETALHADO`
export const CALL_RELATORIO_IPB_RESUMIDO = `${ACTION_PATH}/CALL_RELATORIO_IPB_RESUMIDO`
export const CALL_RELATORIO_IPB_GERAL = `${ACTION_PATH}/CALL_RELATORIO_IPB_GERAL`

export const callRelatorioIPBDetalhado = (payload) => ({
  type: CALL_RELATORIO_IPB_DETALHADO,
  payload,
})

export const callRelatorioIPBResumido = (payload) => ({
  type: CALL_RELATORIO_IPB_RESUMIDO,
  payload,
})

export const callRelatorioIPBGeral = (payload) => ({
  type: CALL_RELATORIO_IPB_GERAL,
  payload,
})
