import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchResiduoDestinacoesActionTypes = apiActionTypes('residuoDestinacoes/API_LISTAR_RESIDUO_DESTINACOES')

export const API_LISTAR_RESIDUO_DESTINACOES = asApiTypesObject(fetchResiduoDestinacoesActionTypes)

export const apiListarResiduoDestinacoes = ({ paisId }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/residuoDestinacoes`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchResiduoDestinacoesActionTypes,
}, { ...meta, query: { paisId } })
