const ACTION_PATH = 'app/usuarios'

export const CALL_CRIAR_USUARIO = `${ACTION_PATH}/CALL_CRIAR_USUARIO`
export const CALL_ATUALIZAR_USUARIO = `${ACTION_PATH}/CALL_ATUALIZAR_USUARIO`
export const CALL_EXCLUIR_USUARIO = `${ACTION_PATH}/CALL_EXCLUIR_USUARIO`
export const CALL_SELECIONAR_USUARIO = `${ACTION_PATH}/CALL_SELECIONAR_USUARIO`
export const CALL_NOVO_USUARIO = `${ACTION_PATH}/CALL_NOVO_USUARIO`

export const callCriarUsuario = (usuario) => ({
  type: CALL_CRIAR_USUARIO,
  payload: usuario,
})

export const callAtualizarUsuario = (payload) => ({
  type: CALL_ATUALIZAR_USUARIO,
  payload,
})

export const callExcluirUsuario = ({ id }) => ({
  type: CALL_EXCLUIR_USUARIO,
  payload: { id },
})

export const callSelecionarUsuario = ({ id }) => ({
  type: CALL_SELECIONAR_USUARIO,
  payload: { id },
})

export const callNovoUsuario = () => ({
  type: CALL_NOVO_USUARIO,
})
