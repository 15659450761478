import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const getOcupacaoAreaActionTypes = apiActionTypes('ocupacaoAreas/API_GET_OCUPACAO_AREAS')
const createOcupacaoAreaActionTypes = apiActionTypes('ocupacaoAreas/API_CRIAR_OCUPACAO_AREA')
const updateOcupacaoAreaActionTypes = apiActionTypes('ocupacaoAreas/API_ATUALIZAR_OCUPACAO_AREA')
const deleteOcupacaoAreaActionTypes = apiActionTypes('ocupacaoAreas/API_EXCLUIR_OCUPACAO_AREA')

export const API_GET_OCUPACAO_AREAS = asApiTypesObject(getOcupacaoAreaActionTypes)
export const API_CRIAR_OCUPACAO_AREA = asApiTypesObject(createOcupacaoAreaActionTypes)
export const API_ATUALIZAR_OCUPACAO_AREA = asApiTypesObject(updateOcupacaoAreaActionTypes)
export const API_EXCLUIR_OCUPACAO_AREA = asApiTypesObject(deleteOcupacaoAreaActionTypes)

export const apiGetOcupacaoArea = (id, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/ocupacaoArea/${id}`,
  method: 'GET',
  types: getOcupacaoAreaActionTypes,
}, meta)

export const apiCriarOcupacaoArea = (ocupacaoArea, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/ocupacaoArea`,
  method: 'POST',
  types: createOcupacaoAreaActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(ocupacaoArea),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiAtualizarOcupacaoArea = ({ id, ocupacaoArea }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/ocupacaoArea/${id}`,
  method: 'PUT',
  types: updateOcupacaoAreaActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(ocupacaoArea),
}, { ...meta, successNotification: 'mensagens.atualizado' })

export const apiExcluirOcupacaoArea = (id, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/ocupacaoArea/${id}`,
  method: 'DELETE',
  types: deleteOcupacaoAreaActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })
