import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const getAcaoActionTypes = apiActionTypes('acoes/API_GET_ACAO')
const createAcaoActionTypes = apiActionTypes('acoes/API_CRIAR_ACAO')
const updateAcaoActionTypes = apiActionTypes('acoes/API_ATUALIZAR_ACAO')
const deleteAcaoActionTypes = apiActionTypes('acoes/API_EXCLUIR_ACAO')

export const API_GET_ACAO = asApiTypesObject(getAcaoActionTypes)
export const API_CRIAR_ACAO = asApiTypesObject(createAcaoActionTypes)
export const API_ATUALIZAR_ACAO = asApiTypesObject(updateAcaoActionTypes)
export const API_EXCLUIR_ACAO = asApiTypesObject(deleteAcaoActionTypes)

export const apiGetAcao = (id, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/acoes/${id}`,
  method: 'GET',
  types: getAcaoActionTypes,
}, meta)

export const apiCriarAcao = (acao, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/acoes`,
  method: 'POST',
  types: createAcaoActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(acao),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiAtualizarAcao = ({ id, acao }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/acoes/${id}`,
  method: 'PUT',
  types: updateAcaoActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(acao),
}, { ...meta, successNotification: 'mensagens.atualizado' })

export const apiExcluirAcao = (id, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/acoes/${id}`,
  method: 'DELETE',
  types: deleteAcaoActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })
