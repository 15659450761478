import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import {
  apiCriarEcorregioes,
  apiExcluirEcorregioes,
  apiAtualizarEcorregioes,
} from 'store/modules/api/ecorregioes.actions'

import {
  CALL_CRIAR_ECORREGIAO,
  CALL_EXCLUIR_ECORREGIAO,
  CALL_ATUALIZAR_ECORREGIAO,
} from 'store/modules/ecorregioes/ecorregioes.actions'

const onCallCriarEcorregioes = (action$) => action$.pipe(
  ofType(CALL_CRIAR_ECORREGIAO),
  debounceTime(250),
  map(({ payload }) => apiCriarEcorregioes(payload)),
)

const onCallExcluirEcorregioes = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_ECORREGIAO),
  debounceTime(250),
  map(({ payload }) => apiExcluirEcorregioes(payload)),
)

const onCallAtualizarEcorregioes = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_ECORREGIAO),
  debounceTime(250),
  map(({ payload }) => apiAtualizarEcorregioes(payload)),
)

export default combineEpics(
  onCallCriarEcorregioes,
  onCallExcluirEcorregioes,
  onCallAtualizarEcorregioes,
)
