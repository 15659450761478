import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchIndicadoresCriterioActionTypes = apiActionTypes('indicadores/API_LISTAR_INDICADORES_CRITERIO')
const getIndicadorActionTypes = apiActionTypes('indicadores/API_GET_INDICADOR')
const createIndicadorActionTypes = apiActionTypes('indicadores/API_CRIAR_INDICADOR')
const updateIndicadorActionTypes = apiActionTypes('indicadores/API_ATUALIZAR_INDICADOR')
const deleteIndicadorActionTypes = apiActionTypes('indicadores/API_EXCLUIR_INDICADOR')

export const API_LISTAR_INDICADORES_CRITERIO = asApiTypesObject(fetchIndicadoresCriterioActionTypes)
export const API_GET_INDICADOR = asApiTypesObject(getIndicadorActionTypes)
export const API_CRIAR_INDICADOR = asApiTypesObject(createIndicadorActionTypes)
export const API_ATUALIZAR_INDICADOR = asApiTypesObject(updateIndicadorActionTypes)
export const API_EXCLUIR_INDICADOR = asApiTypesObject(deleteIndicadorActionTypes)

export const apiListarIndicadoresCriterio = (criterioId, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/indicadores/buscarPorCriterio`,
  method: 'GET',
  types: fetchIndicadoresCriterioActionTypes,
}, { ...meta, query: { criterioId } })

export const apiGetIndicador = (id, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/indicadores/${id}`,
  method: 'GET',
  types: getIndicadorActionTypes,
}, meta)

export const apiCriarIndicador = (indicador, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/indicadores`,
  method: 'POST',
  types: createIndicadorActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(indicador),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiAtualizarIndicador = ({ id, indicador }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/indicadores/${id}`,
  method: 'PUT',
  types: updateIndicadorActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(indicador),
}, { ...meta, successNotification: 'mensagens.atualizado' })

export const apiExcluirIndicador = ({ id }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/indicadores/${id}`,
  method: 'DELETE',
  types: deleteIndicadorActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })
