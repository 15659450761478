import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchTipoUsuariosActionTypes = apiActionTypes('tiposPermissoes/API_LISTAR_TIPO_USUARIOS')

export const API_LISTAR_TIPO_USUARIOS = asApiTypesObject(fetchTipoUsuariosActionTypes)

export const apiListarTipoUsuarios = (data, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/tipoUsuarios`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchTipoUsuariosActionTypes,
}, { ...meta })
