import {
  Button,
  withStyles,
} from '@material-ui/core'

const StyledButton = withStyles((theme) => ({
  root: {
    borderRadius: 4,
    border: `1px solid ${theme.palette.grey.main}`,
    color: theme.palette.primary.light,
    height: 40,
    padding: theme.spacing(0, 3),
    background: theme.palette.common.white,
    textTransform: 'capitalize',
    '&:focus': {
      background: theme.palette.common.white,
      border: `1px solid ${theme.palette.blue.main}`,
    },
    '&:disabled': {
      color: theme.palette.grey.main,
      background: theme.palette.common.white,
    },
  },
}))(Button)

export default StyledButton
