const ACTION_PATH = 'app/ecorregioes'

export const CALL_CRIAR_ECORREGIAO = `${ACTION_PATH}/CALL_CRIAR_ECORREGIAO`
export const CALL_EXCLUIR_ECORREGIAO = `${ACTION_PATH}/CALL_EXCLUIR_ECORREGIAO`
export const CALL_ATUALIZAR_ECORREGIAO = `${ACTION_PATH}/CALL_ATUALIZAR_ECORREGIAO`

export const callCriarEcorregiao = (payload) => ({
  type: CALL_CRIAR_ECORREGIAO,
  payload,
})

export const callAtualizarEcorregiao = (payload) => ({
  type: CALL_ATUALIZAR_ECORREGIAO,
  payload,
})

export const callExcluirEcorregiao = ({ id }) => ({
  type: CALL_EXCLUIR_ECORREGIAO,
  payload: { id },
})
