import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'

const RouterPrompt = ({
  when,
  onConfirm,
  onCancel,
  title,
  message,
}) => {
  const history = useHistory()
  const dialog = useConfirmationDialog()

  const handleConfirm = useCallback(async (currentPath) => {
    if (onConfirm) {
      const canRoute = await Promise.resolve(onConfirm)
      if (canRoute) {
        history.block(noop)
        history.push(currentPath)
      }
    } else {
      history.block(noop)
      history.push(currentPath)
    }
  }, [history, onConfirm])

  const handleCancel = useCallback(async (currentPath) => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel())
      if (canRoute) {
        history.block(noop)
        history.push(currentPath)
      }
    }
  }, [history, onCancel])

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        dialog.showConfirmation({
          title,
          message,
        }).then((result) => {
          if (result) {
            handleConfirm(prompt.pathname)
          } else {
            handleCancel(prompt.pathname)
          }
        })
        return 'true'
      })
    } else {
      history.block(noop)
    }
  }, [dialog, handleCancel, handleConfirm, history, message, title, when])
}

RouterPrompt.propTypes = {
  when: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
}

export default RouterPrompt
