const ACTION_PATH = 'app/energia'

export const CALL_CRIAR_ENERGIA = `${ACTION_PATH}/CALL_CRIAR_ENERGIA`
export const CALL_ATUALIZAR_ENERGIA = `${ACTION_PATH}/CALL_ATUALIZAR_ENERGIA`
export const CALL_EXCLUIR_ENERGIA = `${ACTION_PATH}/CALL_EXCLUIR_ENERGIA`
export const CALL_SELECIONAR_ENERGIA = `${ACTION_PATH}/CALL_SELECIONAR_ENERGIA`
export const CALL_NOVO_ENERGIA = `${ACTION_PATH}/CALL_NOVO_ENERGIA`

export const callCriarEnergia = (energia) => ({
  type: CALL_CRIAR_ENERGIA,
  payload: energia,
})

export const callAtualizarEnergia = ({ id, energia }) => ({
  type: CALL_ATUALIZAR_ENERGIA,
  payload: { id, energia },
})

export const callExcluirEnergia = ({ id }) => ({
  type: CALL_EXCLUIR_ENERGIA,
  payload: id,
})

export const callSelecionarEnergia = ({ id }) => ({
  type: CALL_SELECIONAR_ENERGIA,
  payload: { id },
})

export const callNovoEnergia = () => ({
  type: CALL_NOVO_ENERGIA,
})
