const ACTION_PATH = 'app/qualificadores'

export const CALL_SALVAR_LISTA_QUALIFICADOR = `${ACTION_PATH}/CALL_SALVAR_LISTA_QUALIFICADOR`
export const CALL_CRIAR_QUALIFICADOR = `${ACTION_PATH}/CALL_CRIAR_QUALIFICADOR`
export const CALL_ATUALIZAR_QUALIFICADOR = `${ACTION_PATH}/CALL_ATUALIZAR_QUALIFICADOR`
export const CALL_EXCLUIR_QUALIFICADOR = `${ACTION_PATH}/CALL_EXCLUIR_QUALIFICADOR`
export const CALL_SELECIONAR_QUALIFICADOR = `${ACTION_PATH}/CALL_SELECIONAR_QUALIFICADOR`
export const CALL_NOVO_QUALIFICADOR = `${ACTION_PATH}/CALL_NOVO_QUALIFICADOR`

export const callSalvarListaQualificador = (payload) => ({
  type: CALL_SALVAR_LISTA_QUALIFICADOR,
  payload,
})

export const callCriarQualificador = (qualificadores) => ({
  type: CALL_CRIAR_QUALIFICADOR,
  payload: qualificadores,
})

export const callAtualizarQualificador = ({ id, qualificadores }) => ({
  type: CALL_ATUALIZAR_QUALIFICADOR,
  payload: { id, qualificadores },
})

export const callExcluirQualificador = ({ id }) => ({
  type: CALL_EXCLUIR_QUALIFICADOR,
  payload: id,
})

export const callSelecionarQualificador = ({ id }) => ({
  type: CALL_SELECIONAR_QUALIFICADOR,
  payload: { id },
})

export const callNovaQualificador = () => ({
  type: CALL_NOVO_QUALIFICADOR,
})
