import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const copiarDadosActionTypes = apiActionTypes('servico/API_COPIAR_DADOS')

export const API_COPIAR_DADOS = asApiTypesObject(copiarDadosActionTypes)

export const apiCopiarDados = (payload, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/servico/copiarDados`,
  method: 'POST',
  types: copiarDadosActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(payload),
}, { ...meta, successNotification: 'mensagens.dadosCopiados' })
