const ACTION_PATH = 'app/projetos'

export const CALL_CRIAR_PROJETO = `${ACTION_PATH}/CALL_CRIAR_PROJETO`
export const CALL_ATUALIZAR_PROJETO = `${ACTION_PATH}/CALL_ATUALIZAR_PROJETO`
export const CALL_EXCLUIR_PROJETO = `${ACTION_PATH}/CALL_EXCLUIR_PROJETO`
export const CALL_SELECIONAR_PROJETO = `${ACTION_PATH}/CALL_SELECIONAR_PROJETO`
export const CALL_NOVO_PROJETO = `${ACTION_PATH}/CALL_NOVO_PROJETO`

export const callCriarProjeto = (projeto) => ({
  type: CALL_CRIAR_PROJETO,
  payload: projeto,
})

export const callAtualizarProjeto = ({ id, projeto }) => ({
  type: CALL_ATUALIZAR_PROJETO,
  payload: { id, projeto },
})

export const callExcluirProjeto = ({ id }) => ({
  type: CALL_EXCLUIR_PROJETO,
  payload: id,
})

export const callSelecionarProjeto = ({ id }) => ({
  type: CALL_SELECIONAR_PROJETO,
  payload: { id },
})

export const callNovoProjeto = () => ({
  type: CALL_NOVO_PROJETO,
})
