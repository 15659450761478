import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchEstadosActionTypes = apiActionTypes('estados/API_LISTAR_ESTADOS')

export const API_LISTAR_ESTADOS = asApiTypesObject(fetchEstadosActionTypes)

export const apiListarEstados = (data, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/estados`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchEstadosActionTypes,
}, { ...meta })
