import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
// import { InputAdornment } from '@material-ui/core'
import Input from './BaseInput'

const FieldText = ({
  name,
  onChange,
  format,
  // preffix,
  // suffix,
  // InputProps: ip,
  ...props
}) => {
  const handleChange = (input) => ({ target }) => {
    input.onChange(target.value)
    if (onChange) onChange(target.value)
  }

  const identity = (value) => {
    if (props?.type === 'number' && value === '') {
      return null
    }

    return value
  }

  // let InputProps = { ...ip }

  // if (preffix || suffix) {
  //   InputProps = {
  //     ...ip,
  //     ...(preffix && { startAdornment: <InputAdornment position="start">
  // {preffix}</InputAdornment> }),
  //     ...(suffix && { endAdornment: <InputAdornment position="end">{suffix}</InputAdornment> }),
  //   }
  // }

  return (
    <Field
      name={name}
      parse={identity}
      format={format}
      render={({ input, meta }) => (
        <Input
          {...input}
          {...props}
          // InputProps={InputProps}
          onChange={handleChange(input)}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && meta.error}
        />
      )}
    />
  )
}

FieldText.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  type: PropTypes.string,
  format: PropTypes.func,
  preffix: PropTypes.object,
  suffix: PropTypes.object,
  InputProps: PropTypes.object,
}

export default FieldText
