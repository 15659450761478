import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, mergeMap } from 'rxjs/operators'
import { CALL_LISTAR_DADOS_PAIS_CALCULOS } from 'store/modules/paisesCalculos/paisesCalculos.actions'
import { apiListarGrupos } from 'store/modules/api/grupos.actions'
import { apiListarTiposGases } from 'store/modules/api/tiposGases.actions'
import { apiListarFontesEnergia } from 'store/modules/api/fontesEnergia.actions'
import { apiListarTipoAgua } from 'store/modules/api/tipoAgua.actions'
import { apiListarRegioesHidrograficas } from 'store/modules/api/regioesHidrograficas.actions'
import { apiListarTemas } from 'store/modules/api/temas.actions'
import { apiListarGrupoTema } from 'store/modules/api/grupoTema.actions'
import { apiListarQualificadores } from 'store/modules/api/qualificadores.actions'
import { apiListarQualificadorAvaliacao } from 'store/modules/api/qualificadorAvaliacao.actions'
import { apiListarResiduoDestinacoes } from 'store/modules/api/residuoDestinacoes.actions'
import { apiListarClasseResiduos } from 'store/modules/api/classeResiduos.actions'
import { apiListarEstacaoMeteorologica } from 'store/modules/api/estacoesMeteorologicas.actions'
import { apiListarTipoAtividades } from 'store/modules/api/tipoAtividades.actions'
import { apiListarTipoGestao } from 'store/modules/api/tiposGestao.actions'
import { apiListarSetores } from 'store/modules/api/setores.actions'
import { apiListarAspectos } from 'store/modules/api/aspectos.actions'
import { apiListarClassesOcupacao } from 'store/modules/api/classesOcupacao.actions'
import { apiListarEcorregioes } from 'store/modules/api/ecorregioes.actions'
import { apiListarClassesIrcAdmin } from 'store/modules/api/classesIrcAdmin.actions'
import { apiListarIndicadoresAdmin } from 'store/modules/api/indicadoresAdmin.actions'
import { apiListarBiomas } from 'store/modules/api/biomas.actions'

import {
  apiListarPrincipiosPaisesCalculos,
} from 'store/modules/api/principios.actions'
import { apiListarMatrizResiduos } from 'store/modules/api/matrizResiduos.actions'
import { apiListarEspecieSilvicultura } from 'store/modules/api/especieSilvicultura.actions'

const onCallListarDadosPaisesCalculo = (action$) => action$.pipe(
  ofType(CALL_LISTAR_DADOS_PAIS_CALCULOS),
  debounceTime(250),
  mergeMap(({ payload }) => {
    const data = { paisId: payload }

    return [
      apiListarQualificadorAvaliacao(data),
      apiListarGrupos(data),
      apiListarTemas(data),
      apiListarGrupoTema(data),
      apiListarQualificadores(data),
      apiListarTiposGases(data),
      apiListarResiduoDestinacoes(data),
      apiListarClasseResiduos(),
      apiListarFontesEnergia(data),
      apiListarPrincipiosPaisesCalculos(data),
      apiListarMatrizResiduos(data),
      apiListarTipoAgua(data),
      apiListarRegioesHidrograficas(data),
      apiListarEspecieSilvicultura(data),
      apiListarEstacaoMeteorologica(data),
      apiListarAspectos(data),
      apiListarTipoAtividades(data),
      apiListarTipoGestao(data),
      apiListarClassesOcupacao(data),
      apiListarEcorregioes(data),
      apiListarClassesIrcAdmin(data),
      apiListarIndicadoresAdmin(data),
      apiListarSetores(),
      apiListarBiomas(data),
    ]
  }),
)

export default combineEpics(
  onCallListarDadosPaisesCalculo,
)
