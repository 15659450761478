/* eslint-disable max-len */
import React from 'react'
import DashBoardLineIcon from 'remixicon-react/DashboardLineIcon'
import DashBoardFillIcon from 'remixicon-react/DashboardFillIcon'
import FundsLineIcon from 'remixicon-react/FundsLineIcon'
import FundsFillIcon from 'remixicon-react/FundsFillIcon'
import LeafLineIcon from 'remixicon-react/LeafLineIcon'
import LeafFillIcon from 'remixicon-react/LeafFillIcon'
import BankCardLineIcon from 'remixicon-react/BankCardLineIcon'
import BankCardFillIcon from 'remixicon-react/BankCardFillIcon'
// import DraftLineIcon from 'remixicon-react/DraftLineIcon'
// import DraftFillIcon from 'remixicon-react/DraftFillIcon'
import FileCopyLineIcon from 'remixicon-react/FileCopy2LineIcon'
import FileCopyFillIcon from 'remixicon-react/FileCopy2FillIcon'
// import FileAddLineIcon from 'remixicon-react/FileAddLineIcon'
// import FileAddFillIcon from 'remixicon-react/FileAddFillIcon'
// import FileListLineIcon from 'remixicon-react/FileList3LineIcon'
// import FileListFillIcon from 'remixicon-react/FileList3FillIcon'
// import SurveyLineIcon from 'remixicon-react/SurveyLineIcon'
// import SurveyFillIcon from 'remixicon-react/SurveyFillIcon'
// import FileMarkLineIcon from 'remixicon-react/FileMarkLineIcon'
// import FileMarkFillIcon from 'remixicon-react/FileMarkFillIcon'
// import ErrorWarningLineIcon from 'remixicon-react/ErrorWarningLineIcon'
// import ErrorWarningFillIcon from 'remixicon-react/ErrorWarningFillIcon'
import BuildingLineIcon from 'remixicon-react/BuildingLineIcon'
import BuildingFillIcon from 'remixicon-react/BuildingFillIcon'
import UserLineIcon from 'remixicon-react/UserLineIcon'
import UserFillIcon from 'remixicon-react/UserFillIcon'
import TeamLineIcon from 'remixicon-react/TeamLineIcon'
import TeamFillIcon from 'remixicon-react/TeamFillIcon'
import PriceTagLineIcon from 'remixicon-react/PriceTag2LineIcon'
import PriceTagFillIcon from 'remixicon-react/PriceTag2FillIcon'
import GlobeLineIcon from 'remixicon-react/GlobeLineIcon'
import GlobeFillIcon from 'remixicon-react/GlobeFillIcon'
import GroupLineIcon from 'remixicon-react/GroupLineIcon'
import GroupFillIcon from 'remixicon-react/GroupFillIcon'
import ParentLineIcon from 'remixicon-react/ParentLineIcon'
import ParentFillIcon from 'remixicon-react/ParentFillIcon'
import FileList3LineIcon from 'remixicon-react/FileList3LineIcon'
import FileList3FillIcon from 'remixicon-react/FileList3FillIcon'
import SurveyLineIcon from 'remixicon-react/SurveyLineIcon'
import SurveyFillIcon from 'remixicon-react/SurveyFillIcon'

export const userItems = [
  // { secondary: 'menuItems.inicio' },
  { secondary: 'menuItems.inicio' },
  { primary: 'menuItems.visaoGeral', to: '/dashboard', icon: <DashBoardLineIcon />, activeIcon: <DashBoardFillIcon /> },
  { secondary: 'menuItems.avaliacao' },
  { primary: 'menuItems.pressaoBiodiversidade', to: '/pressaoBiodiversidade', icon: <FundsLineIcon />, activeIcon: <FundsFillIcon /> },
  { primary: 'menuItems.desempenhoBiodiversidade', to: '/desempenhoBiodiversidade', icon: <LeafLineIcon />, activeIcon: <LeafFillIcon /> },
  { primary: 'menuItems.indicadoresGestao', to: '/indicadoresGestao', icon: <BankCardLineIcon />, activeIcon: <BankCardFillIcon /> },
  { secondary: 'menuItems.relatorios' },
  // { primary: 'menuItems.personalizado', to: '/', icon: <DraftLineIcon />, activeIcon: <DraftFillIcon /> },
  { primary: 'menuItems.relatorios', to: '/relatorios', icon: <FileCopyLineIcon />, activeIcon: <FileCopyFillIcon /> },
  // { primary: 'menuItems.cadastrarOC', to: '/', icon: <FileAddLineIcon />, activeIcon: <FileAddFillIcon /> },
  // { primary: 'menuItems.planoAuditoria', to: '/', icon: <FileListLineIcon />, activeIcon: <FileListFillIcon /> },
  // { primary: 'menuItems.pareceresRelatorios', to: '/', icon: <SurveyLineIcon />, activeIcon: <SurveyFillIcon /> },
  // { primary: 'menuItems.certificado', to: '/', icon: <FileMarkLineIcon />, activeIcon: <FileMarkFillIcon /> },
  // { primary: 'menuItems.pendencias', to: '/', icon: <ErrorWarningLineIcon />, activeIcon: <ErrorWarningFillIcon /> },
  { secondary: 'menuItems.auditoria' },
  { primary: 'menuItems.planoAuditoria', to: '/secaoAuditoria', icon: <FileList3LineIcon />, activeIcon: <FileList3FillIcon /> },
  { primary: 'menuItems.parecerAuditoria', to: '/parecerAuditoria', icon: <SurveyLineIcon />, activeIcon: <SurveyFillIcon /> },

  { secondary: 'menuItems.configuracoes' },
  { primary: 'menuItems.minhaEmpresa', to: '/dadosEmpresa', icon: <BuildingLineIcon />, activeIcon: <BuildingFillIcon /> },
  { primary: 'menuItems.meuPerfil', to: '/meuPerfil', icon: <UserLineIcon />, activeIcon: <UserFillIcon /> },
]

export const adminItems = [
  { secondary: 'menuItemsAdm.contas' },
  { primary: 'menuItemsAdm.empresas', to: '/adm/empresas', icon: <BuildingLineIcon />, activeIcon: <BuildingFillIcon /> },
  { primary: 'menuItemsAdm.usuarios', to: '/adm/usuarios', icon: <TeamLineIcon />, activeIcon: <TeamFillIcon /> },
  { primary: 'menuItemsAdm.licencas', to: '/adm/licencas', icon: <PriceTagLineIcon />, activeIcon: <PriceTagFillIcon /> },

  { secondary: 'menuItemsAdm.auditoria' },
  { primary: 'menuItemsAdm.auditores', to: '/adm/auditores', icon: <ParentLineIcon />, activeIcon: <ParentFillIcon /> },

  { secondary: 'menuItemsAdm.configuracoes' },
  { primary: 'menuItemsAdm.paisesCalculos', to: '/adm/paisesCalculos', icon: <GlobeLineIcon />, activeIcon: <GlobeFillIcon /> },
  { primary: 'menuItemsAdm.usuariosLife', to: '/adm/usuariosLife', icon: <GroupLineIcon />, activeIcon: <GroupFillIcon /> },
]

export const auditorItems = [
  { secondary: 'menuItems.inicio' },
  { primary: 'menuItems.auditorias', to: '/auditorias', icon: <ParentLineIcon />, activeIcon: <ParentFillIcon /> },
  { secondary: 'menuItems.configuracoes' },
  { primary: 'menuItems.meuPerfil', to: '/auditoria/meuPerfil', icon: <UserLineIcon />, activeIcon: <UserFillIcon /> },
]
