const ACTION_PATH = 'app/indicadorEmpresa'

export const CALL_CRIAR_INDICADOR_EMPRESA = `${ACTION_PATH}/CALL_CRIAR_INDICADOR_EMPRESA`
export const CALL_ATUALIZAR_INDICADOR_EMPRESA = `${ACTION_PATH}/CALL_ATUALIZAR_INDICADOR_EMPRESA`
export const CALL_EXCLUIR_INDICADOR_EMPRESA = `${ACTION_PATH}/CALL_EXCLUIR_INDICADOR_EMPRESA`
export const CALL_SELECIONAR_INDICADOR_EMPRESA = `${ACTION_PATH}/CALL_SELECIONAR_INDICADOR_EMPRESA`
export const CALL_LISTAR_INDICADOR_EMPRESA = `${ACTION_PATH}/CALL_LISTAR_INDICADOR_EMPRESA`

export const callCriarIndicadorEmpresa = (indicadorEmpresa) => ({
  type: CALL_CRIAR_INDICADOR_EMPRESA,
  payload: indicadorEmpresa,
})

export const callAtualizarIndicadorEmpresa = ({ id, indicadorEmpresa }) => ({
  type: CALL_ATUALIZAR_INDICADOR_EMPRESA,
  payload: { id, indicadorEmpresa },
})

export const callExcluirIndicadorEmpresa = ({ id }) => ({
  type: CALL_EXCLUIR_INDICADOR_EMPRESA,
  payload: { id },
})

export const callSelecionarIndicadorEmpresa = ({ id }) => ({
  type: CALL_SELECIONAR_INDICADOR_EMPRESA,
  payload: { id },
})

export const callListarIndicadorEmpresa = (payload) => ({
  type: CALL_LISTAR_INDICADOR_EMPRESA,
  payload,
})
