import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchEstacaoMeteorologicaActionTypes = apiActionTypes('estacaoMeteorologica/API_LISTAR_ESTACAO_METEOROLOGICA')
const createEstacaoMeteorologicaActionTypes = apiActionTypes('estacaoMeteorologica/API_CRIAR_ESTACAO_METEOROLOGICA')
const deleteEstacaoMeteorologicaActionTypes = apiActionTypes('estacaoMeteorologica/API_EXCLUIR_ESTACAO_METEOROLOGICA')
const updateEstacaoMeteorologicaActionTypes = apiActionTypes('estacaoMeteorologica/API_ATUALIZAR_ESTACAO_METEOROLOGICA')

export const API_LISTAR_ESTACAO_METEOROLOGICA = asApiTypesObject(
  fetchEstacaoMeteorologicaActionTypes,
)
export const API_CRIAR_ESTACAO_METEOROLOGICA = asApiTypesObject(
  createEstacaoMeteorologicaActionTypes,
)
export const API_EXCLUIR_ESTACAO_METEOROLOGICA = asApiTypesObject(
  deleteEstacaoMeteorologicaActionTypes,
)
export const API_ATUALIZAR_ESTACAO_METEOROLOGICA = asApiTypesObject(
  updateEstacaoMeteorologicaActionTypes,
)

export const apiListarEstacaoMeteorologica = ({ paisId }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/estacaoMeteorologica`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchEstacaoMeteorologicaActionTypes,
}, { ...meta, query: { paisId } })

export const apiCriarEstacaoMeteorologica = (estacaoMeteorologica, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/estacaoMeteorologica`,
  method: 'POST',
  types: createEstacaoMeteorologicaActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(estacaoMeteorologica),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiExcluirEstacaoMeteorologica = ({ id }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/estacaoMeteorologica/${id}`,
  method: 'DELETE',
  types: deleteEstacaoMeteorologicaActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })

export const apiAtualizarEstacaoMeteorologica = ({ id,
  estacaoMeteorologica }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/estacaoMeteorologica/${id}`,
  method: 'PUT',
  types: updateEstacaoMeteorologicaActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(estacaoMeteorologica),
}, { ...meta, successNotification: 'mensagens.atualizado' })
