import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchConversoesEnergiaActionTypes = apiActionTypes('conversoesEnergia/API_LISTAR_CONVERSOES_ENERGIA')

export const API_LISTAR_CONVERSOES_ENERGIA = asApiTypesObject(fetchConversoesEnergiaActionTypes)

export const apiListarConversoesEnergia = (data, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/conversaoEnergia`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchConversoesEnergiaActionTypes,
}, { ...meta })
