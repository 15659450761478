import React, { forwardRef, Fragment, useImperativeHandle, useState } from 'react'
import DeleteIcon from 'remixicon-react/DeleteBin7LineIcon'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles, Grid } from '@material-ui/core'
import { Form } from 'react-final-form'
import Button from 'components/Button'
import Accordion from 'components/Accordion'
import { FieldText } from 'components/Fields'
import { useSelector, useDispatch } from 'react-redux'
import { getIndicadoresAdmin } from 'store/modules/indicadoresAdmin/indicadoresAdmin.selectors'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import {
  callSalvarIndicadoresAdmin,
  callExcluirIndicadorAdmin,
} from 'store/modules/indicadoresAdmin'
import { map } from 'lodash'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { TIPO_USUARIO } from 'utils/constantes'

const useStyles = makeStyles((theme) => ({
  rowText: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(5),
    fontSize: theme.typography.pxToRem(14),
  },
  formAdd: {
    transition: '0.20s ease-out',
    maxHeight: 0,
    overflow: 'hidden',
  },
  openFormAdd: {
    marginTop: '-10px',
    paddingTop: 10,
    maxHeight: '1300px',
    transition: '0.25s ease-in',
    marginBottom: '32px',
  },
  normalTop: {
    marginTop: '16px',
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '0 !important',
  },
  itemAlignRight: {
    maxWidth: '190px',
  },
  listItems: {
    border: '1px solid #C9CEDB',
    boxShadow: '0px 8px 32px rgba(15, 52, 68, 0.08)',
    borderRadius: '8px',
    padding: '0px 16px',
  },
  itemTitle: {
    fontFamily: 'Poppins',
    color: '#0F3444',
    fontWeight: '600',
    fontSize: '16px',
  },
  divider: {
    height: 1,
    width: 'calc(100% + 32px)',
    marginLeft: '-16px',
    backgroundColor: theme.palette.grey.main,
  },
  dividerNormal: {
    height: 1,
    width: '100%',
    backgroundColor: theme.palette.grey.main,
  },
  dividerField: {
    borderBottom: `1px solid ${theme.palette.grey.main}`,
    margin: '8px 0',
  },
  itemsForm: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  rowDelete: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '34px',
    position: 'relative',
  },
  deleteIcon: {
    position: 'absolute',
    right: 0,
    top: '10px',
    flexShrink: 0,
    display: 'block',
    width: '24px',
    cursor: ({ desabilitarFormulario }) => (desabilitarFormulario ? 'default' : 'pointer'),
    color: theme.palette.grey.blue,
    '&:hover': {
      color: ({ desabilitarFormulario }) => (desabilitarFormulario ? theme.palette.grey.blue : theme.palette.grey['2E']),
    },
  },
  itemClasse: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    marginTop: '16px',
  },
}))

const initialValue = {
  nome: '',
  indicadoresIrc: [],
}

const TabIndicadores = forwardRef(({ paisId, setControlAdded }, ref) => {
  const { t } = useTranslation()

  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)
  const desabilitarFormulario = Boolean(tipoUsuarioLogado === TIPO_USUARIO.ADMIN.OBSERVADOR)

  const classes = useStyles({ desabilitarFormulario })
  const dispatch = useDispatch()
  const dialog = useConfirmationDialog()

  const listaClassesIrcAdmin = useSelector(getIndicadoresAdmin)

  const handleSave = (values) => {
    dispatch(callSalvarIndicadoresAdmin(values))
  }

  const [openItem, setOpenItem] = useState('')

  const handleExpand = (item) => {
    setOpenItem(openItem === item ? '' : item)
  }

  useImperativeHandle(
    ref,
    () => ({
      handleButtonAdded() {
        setControlAdded(true)
        handleExpand('')
      },
    }),
  )

  const handleAdd = (push, classe) => {
    push('indicadoresIrc', {
      nome: '',
      descricao: '',
      classeIrcId: classe.id,
      paisId,
    })
  }

  const handleDeleteIndicador = (remove, index, values) => {
    const indicador = values.indicadoresIrc[index]

    dialog.showConfirmation({
      title: t('dialogs.confirmarExclusao'),
      message: t('dialogs.excluirIndicador', {
        indicador: indicador.nome,
      }),
    }).then((result) => {
      if (result) {
        if (indicador.id) {
          dispatch(callExcluirIndicadorAdmin({ id: indicador.id }))
        } else {
          remove(index)
        }
      }
      return null
    })
  }

  return (
    <div className={classes.listItems}>
      {map(listaClassesIrcAdmin, (classe, index) => (
        <Form
          key={index}
          onSubmit={handleSave}
          initialValues={classe || initialValue}
          mutators={{ ...arrayMutators }}
          render={({
            handleSubmit,
            values,
            form,
            form: { mutators: { push } },
            pristine,
            valid,
          }) => (
            <form onSubmit={handleSubmit}>
              <Accordion
                key={index}
                expanded={index === openItem}
                variant="no-borders"
                classes={{ title: classes.itemTitle }}
                title={classe.nome}
                onExpand={() => handleExpand(index)}
                disableDelete={desabilitarFormulario}
              >
                <Grid container>
                  <div className={classes.itemClasse}>
                    <FieldArray name="indicadoresIrc">
                      {({ fields }) => (
                        <>
                          {fields.map((name, i) => (
                            <Fragment key={name}>
                              <div className={classes.itemsForm}>
                                <div className={classes.rowDelete}>
                                  <FieldText
                                    label={t('inputs.nomeIndicador')}
                                    name={`${name}.nome`}
                                    disabled={desabilitarFormulario}
                                  />
                                  <DeleteIcon
                                    className={classes.deleteIcon}
                                    disabled={desabilitarFormulario}
                                    onClick={() => {
                                      if (desabilitarFormulario) return null
                                      return handleDeleteIndicador(fields.remove, i, values)
                                    }}
                                  />
                                </div>
                                <FieldText
                                  label={t('inputs.descricaoIndicador')}
                                  name={`${name}.descricao`}
                                  multiline
                                  minRows={5}
                                  maxRows={5}
                                  disabled={desabilitarFormulario}
                                />
                              </div>
                              <div className={classes.dividerNormal} />
                            </Fragment>
                          ))}
                        </>
                      )}
                    </FieldArray>
                  </div>

                  <Grid item xs={12}>
                    <Grid
                      container
                      direction="row"
                      spacing={2}
                      justifyContent="space-between"
                      className={classes.normalTop}
                    >
                      <Grid item className={classes.deleteButton}>
                        <Button
                          variant="danger"
                          onClick={() => { form.reset(); handleExpand('') }}
                        >
                          {t('inputs.cancelar')}
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button
                          onClick={() => handleAdd(push, classe)}
                          disabled={desabilitarFormulario}
                        >
                          {t('inputs.adicionarIndicador')}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          type="submit"
                          disabled={Boolean(pristine || !valid) || desabilitarFormulario}
                        >
                          {t('inputs.salvar')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Accordion>
              <div className={classes.divider} />
            </form>
          )}
        />
      ))}
    </div>
  )
})

TabIndicadores.propTypes = {
  paisId: PropTypes.number,
  setControlAdded: PropTypes.func,
}

export default TabIndicadores
