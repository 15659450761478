import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import { combineEpics } from 'redux-observable'
import acaoClassificacao, { epic as acaoClassificacaoEpic } from './acaoClassificacao'
import acoes, { epic as acoesEpic } from './acoes'
import { epic as anexosEpic } from './anexos'
import { epic as apiEpic } from './api'
import aspectos, { epic as aspectosEpic } from './aspectos'
import auditor, { epic as auditorEpic } from './auditor'
import auth, { epic as authEpic } from './auth'
import biomas, { epic as biomasEpic } from './biomas'
import { epic as cadastroInicialEpic } from './cadastroInicial'
import classeResiduos from './classeResiduos'
import classesIrc, { epic as classesIrcEpic } from './classesIrc'
import classesIrcAdmin, { epic as classesIrcAdminEpic } from './classesIrcAdmin'
import classesOcupacao, { epic as classesOcupacaoEpic } from './classesOcupacao'
import consumoAgua, { epic as consumoAguaEpic } from './consumoAgua'
import conversoesArea from './conversoesArea'
import conversoesEnergia from './conversoesEnergia'
import conversoesMassa from './conversoesMassa'
import conversoesTempo from './conversoesTempo'
import conversoesVolume from './conversoesVolume'
import criterios, { epic as criteriosEpic } from './criterios'
import dashboard, { epic as dashboardEpic } from './dashboard'
import desempenhoBiodiversidade, { epic as desempenhoBiodiversidadeEpic } from './desempenhoBiodiversidade'
import divisoesSetores, { epic as divisoesSetoresEpic } from './divisoesSetores'
import ecorregioes, { epic as ecorregioesEpic } from './ecorregioes'
import empresas, { epic as empresasEpic } from './empresas'
import energia, { epic as energiaEpic } from './energia'
import escopoGases from './escopoGases'
import especieSilvicultura, { epic as especieSilviculturaEpic } from './especieSilvicultura'
import estacaoMeteorologica, { epic as estacaoMeteorologicaEpic } from './estacaoMeteorologica'
import estados from './estados'
import faturamento, { epic as faturamentoEpic } from './faturamento'
import fontesEnergia, { epic as fontesEnergiaEpic } from './fontesEnergia'
import gasesEfeitoEstufa, { epic as gasesEfeitoEstufaEpic } from './gasesEfeitoEstufa'
import grupoTema, { epic as grupoTemaEpic } from './grupoTema'
import grupos, { epic as gruposEpic } from './grupos'
import idiomas from './idiomas'
import indicadorEmpresa, { epic as indicadorEmpresaEpic } from './indicadorEmpresa'
import indicadores, { epic as indicadoresEpic } from './indicadores'
import indicadoresAdmin, { epic as indicadoresAdminEpic } from './indicadoresAdmin'
import licencas, { epic as licencasEpic } from './licencas'
import matrizResiduos, { epic as matrizResiduosEpic } from './matrizResiduos'
import { epic as meuPerfilEpic } from './meuPerfil'
import moedas from './moedas'
import municipios from './municipios'
import notifications from './notifications'
import ocupacaoArea, { epic as ocupacaoAreaEpic } from './ocupacaoArea'
import paises, { epic as paisesEpic } from './paises'
import { epic as paisesCalculosEpic } from './paisesCalculos'
import pressaoBiodiversidade, { epic as pressaoBiodiversidadeEpic } from './pressaoBiodiversidade'
import principios, { epic as principioEpic } from './principios'
import projetos, { epic as projetosEpic } from './projetos'
import qualificadorAvaliacao from './qualificadorAvaliacao'
import qualificadores, { epic as qualificadoresEpic } from './qualificadores'
import regioesHidrograficas, { epic as regioesHidrograficasEpic } from './regioesHidrograficas'
import relatoriosIPB, { epic as relatoriosIPBEpic } from './relatorios/IPB'
import relatoriosPABS, { epic as relatoriosPABSEpic } from './relatorios/PABS'
import relatoriosAuditoria, { epic as relatoriosAuditoriaEpic } from './relatorios/auditoria'
import relatoriosGestao, { epic as relatoriosGestaoEpic } from './relatorios/gestao'
import relatoriosIndicadoresGestao, { epic as relatoriosIndicadoresGestaoEpic } from './relatorios/indicadoresGestao'
import residuoDestinacoes from './residuoDestinacoes'
import residuos, { epic as residuosEpic } from './residuos'
import servico, { epic as servicoEpic } from './servico'
import setores from './setores'
import temas, { epic as temasEpic } from './temas'
import tipoAgua, { epic as tipoAguaEpic } from './tipoAgua'
import tipoAtividades, { epic as tipoAtividadesEpic } from './tipoAtividades'
import tipoUsuarios from './tipoUsuarios'
import tiposGases, { epic as tiposGasesEpic } from './tiposGases'
import tiposGestao, { epic as tiposGestaoEpic } from './tiposGestao'
import unidades, { epic as unidadesEpic } from './unidades'
import usuarios, { epic as usuariosEpic } from './usuarios'
import usuariosAuditor, { epic as usuariosAuditorEpic } from './usuariosAuditor'

export default (history) => combineReducers({
  router: connectRouter(history),
  auth,
  notifications,
  usuarios,
  empresas,
  estados,
  municipios,
  setores,
  tipoAtividades,
  divisoesSetores,
  unidades,
  tipoUsuarios,
  moedas,
  faturamento,
  classeResiduos,
  conversoesMassa,
  conversoesTempo,
  conversoesEnergia,
  conversoesArea,
  conversoesVolume,
  residuoDestinacoes,
  principios,
  residuos,
  gasesEfeitoEstufa,
  escopoGases,
  tiposGases,
  tiposGestao,
  energia,
  fontesEnergia,
  ocupacaoArea,
  ecorregioes,
  classesOcupacao,
  consumoAgua,
  regioesHidrograficas,
  tipoAgua,
  projetos,
  indicadorEmpresa,
  biomas,
  acoes,
  idiomas,
  paises,
  licencas,
  grupos,
  temas,
  grupoTema,
  acaoClassificacao,
  classesIrc,
  classesIrcAdmin,
  indicadoresAdmin,
  especieSilvicultura,
  qualificadores,
  qualificadorAvaliacao,
  pressaoBiodiversidade,
  matrizResiduos,
  desempenhoBiodiversidade,
  relatoriosGestao,
  relatoriosIndicadoresGestao,
  relatoriosIPB,
  relatoriosPABS,
  relatoriosAuditoria,
  estacaoMeteorologica,
  aspectos,
  criterios,
  indicadores,
  usuariosAuditor,
  auditor,
  dashboard,
  servico,
})

export const rootEpic = combineEpics(
  authEpic,
  apiEpic,
  usuariosEpic,
  empresasEpic,
  cadastroInicialEpic,
  divisoesSetoresEpic,
  unidadesEpic,
  meuPerfilEpic,
  pressaoBiodiversidadeEpic,
  faturamentoEpic,
  residuosEpic,
  gasesEfeitoEstufaEpic,
  energiaEpic,
  ocupacaoAreaEpic,
  consumoAguaEpic,
  projetosEpic,
  indicadorEmpresaEpic,
  desempenhoBiodiversidadeEpic,
  dashboardEpic,
  acoesEpic,
  licencasEpic,
  acaoClassificacaoEpic,
  qualificadoresEpic,
  gruposEpic,
  temasEpic,
  grupoTemaEpic,
  anexosEpic,
  classesIrcEpic,
  classesIrcAdminEpic,
  indicadoresAdminEpic,
  paisesEpic,
  relatoriosGestaoEpic,
  relatoriosIndicadoresGestaoEpic,
  relatoriosIPBEpic,
  relatoriosPABSEpic,
  relatoriosAuditoriaEpic,
  paisesCalculosEpic,
  fontesEnergiaEpic,
  principioEpic,
  tiposGasesEpic,
  tipoAguaEpic,
  regioesHidrograficasEpic,
  especieSilviculturaEpic,
  estacaoMeteorologicaEpic,
  aspectosEpic,
  criteriosEpic,
  tipoAtividadesEpic,
  tiposGestaoEpic,
  classesOcupacaoEpic,
  ecorregioesEpic,
  indicadoresEpic,
  matrizResiduosEpic,
  biomasEpic,
  usuariosAuditorEpic,
  auditorEpic,
  servicoEpic,
)
