/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react'
import InformationIcon from 'remixicon-react/InformationFillIcon'
import CheckboxCircleIcon from 'remixicon-react/CheckboxCircleFillIcon'
import CloseCircleIcon from 'remixicon-react/CloseCircleFillIcon'
import AlertIcon from 'remixicon-react/AlertFillIcon'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  snackbar: {
    width: 420,
    height: 72,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    boxSizing: 'border-box',
    color: theme.palette.common.white,
    borderRadius: '12px 12px 2px 12px',
    padding: theme.spacing(0, 3, 0, 6),
    fontFamily: theme.typography.secondary,
    backgroundColor: theme.palette.primary.main,
    boxShadow: `0 8px 32px ${theme.palette.shadows.main}, 0 8px 32px ${theme.palette.shadows.main}`,
  },
  icon: {
    left: 18,
    position: 'absolute',
    color: (props) => ({
      default: theme.palette.common.white,
      success: theme.palette.status.green[1],
      info: theme.palette.blue.main,
      error: theme.palette.status.red[1],
      warning: theme.palette.status.yellow[1],
    })[props.variant],
  },
}))

const Notification = ({ id, message, variant }, ref) => {
  const classes = useStyles({ variant })

  const Icon = ({
    default: InformationIcon,
    success: CheckboxCircleIcon,
    info: InformationIcon,
    error: CloseCircleIcon,
    warning: AlertIcon,
  })[variant]

  return (
    <div className={classes.snackbar} key={id} ref={ref}>
      {Icon && <Icon className={classes.icon} />}
      {message}
    </div>
  )
}

export default forwardRef(Notification)
