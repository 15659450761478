import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import {
  apiSalvarIndicadoresAdmin,
  apiExcluirIndicadorAdmin,
  apiExcluirClasseIndicadoresAdmin,
} from 'store/modules/api/indicadoresAdmin.actions'
import {
  CALL_SALVAR_INDICADORES_ADMIN,
  CALL_EXCLUIR_CLASSE_INDICADORES_ADMIN,
  CALL_EXCLUIR_INDICADOR_ADMIN,
} from 'store/modules/indicadoresAdmin/indicadoresAdmin.actions'

const onCallSalvarIndicadores = (action$) => action$.pipe(
  ofType(CALL_SALVAR_INDICADORES_ADMIN),
  debounceTime(250),
  map(({ payload }) => apiSalvarIndicadoresAdmin(payload)),
)

const onCallExcluirClasseIndicadores = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_CLASSE_INDICADORES_ADMIN),
  debounceTime(250),
  map(({ payload }) => apiExcluirClasseIndicadoresAdmin(payload)),
)

const onCallExcluirIndicador = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_INDICADOR_ADMIN),
  debounceTime(250),
  map(({ payload }) => apiExcluirIndicadorAdmin(payload)),
)

export default combineEpics(
  onCallSalvarIndicadores,
  onCallExcluirClasseIndicadores,
  onCallExcluirIndicador,
)
