import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { Form, FormSpy } from 'react-final-form'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { FieldText } from 'components/Fields'
import { useTranslation } from 'react-i18next'
import { validateFormValues } from 'utils/forms'
import { useSelector, useDispatch } from 'react-redux'
import { callAtualizarProjeto } from 'store/modules/projetos'
import { getProjetoSelecionado } from 'store/modules/projetos/projetos.selectors'
import { formatDecimal } from 'utils/numbers'

const useStyles = makeStyles((theme) => ({
  tableTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    color: '#0F3444',
    marginBottom: '24px',
  },
  lastTitle: {
    marginTop: '32px',
  },
}))

const Pontuacao = ({ setChangeTab }) => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const projetoSelecionado = useSelector(getProjetoSelecionado)

  const handleSave = (values) => {
    const data = {
      ...projetoSelecionado,
      ...values,
      acoes: undefined,
    }

    if (projetoSelecionado && callAtualizarProjeto) {
      dispatch(callAtualizarProjeto({ id: projetoSelecionado.id, projeto: data }))
    }
  }

  const handleFormatPontuacao = (value) => {
    if (!value) return 0
    return formatDecimal({ min: 0, max: 5, locale: i18n.language, value })
  }

  return (
    <>
      <Form
        onSubmit={handleSave}
        validate={validateFormValues(yup.object({
          pontuacaoProjetoAcoesVigentesAdicionais: yup.number().nullable(),
          pontuacaoProjetoAcoesVigentesNaoAdicionais: yup.number().nullable(),
          pontuacaoProjetoAcoesNaoVigentesAdicionais: yup.number().nullable(),
          pontuacaoProjetoAcoesNaoVigentesNaoAdicionais: yup.number().nullable(),
          pontuacaoProjetoAcoesSimuladas: yup.number().nullable(),
          pontuacaoTotalProjeto: yup.number().nullable(),
        }))}
        initialValues={{
          pontuacaoProjetoAcoesVigentesAdicionais:
            projetoSelecionado?.pontuacaoProjetoAcoesVigentesAdicionais ?? 0,
          pontuacaoProjetoAcoesVigentesNaoAdicionais:
            projetoSelecionado?.pontuacaoProjetoAcoesVigentesNaoAdicionais ?? 0,
          pontuacaoProjetoAcoesNaoVigentesAdicionais:
            projetoSelecionado?.pontuacaoProjetoAcoesNaoVigentesAdicionais ?? 0,
          pontuacaoProjetoAcoesNaoVigentesNaoAdicionais:
            projetoSelecionado?.pontuacaoProjetoAcoesNaoVigentesNaoAdicionais ?? 0,
          pontuacaoProjetoAcoesSimuladas: projetoSelecionado?.pontuacaoProjetoAcoesSimuladas ?? 0,
          pontuacaoTotalProjeto: projetoSelecionado?.pontuacaoTotalProjeto ?? 0,

          pontuacaoProjetoAcoesSimuladasIRC:
            projetoSelecionado?.pontuacaoProjetoAcoesSimuladasIRC ?? 0,
          pontuacaoProjetoAcoesVigentesAdicionaisIRC:
            projetoSelecionado?.pontuacaoProjetoAcoesVigentesAdicionaisIRC ?? 0,
          pontuacaoProjetoAcoesVigentesNaoAdicionaisIRC:
            projetoSelecionado?.pontuacaoProjetoAcoesVigentesNaoAdicionaisIRC ?? 0,
          pontuacaoProjetoAcoesNaoVigentesAdicionaisIRC:
            projetoSelecionado?.pontuacaoProjetoAcoesNaoVigentesAdicionaisIRC ?? 0,
          pontuacaoProjetoAcoesNaoVigentesNaoAdicionaisIRC:
            projetoSelecionado?.pontuacaoProjetoAcoesNaoVigentesNaoAdicionaisIRC ?? 0,
          pontuacaoTotalProjetoIRC: projetoSelecionado?.pontuacaoTotalProjetoIRC ?? 0,

        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormSpy
              onChange={setChangeTab}
              subscription={{ pristine: true }}
            />
            <Grid item xs={12}>
              <Typography className={classes.tableTitle}>
                {t('desempenhoBiodiversidade.pontuacoesComIRC')}
              </Typography>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FieldText
                  disabled
                  format={handleFormatPontuacao}
                  placeholder={t('inputs.valor')}
                  name="pontuacaoProjetoAcoesVigentesAdicionaisIRC"
                  label={t('desempenhoBiodiversidade.pontuacaoProjetoAcoesVigentesAdicionais')}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldText
                  disabled
                  format={handleFormatPontuacao}
                  placeholder={t('inputs.valor')}
                  name="pontuacaoProjetoAcoesVigentesNaoAdicionaisIRC"
                  label={t('desempenhoBiodiversidade.pontuacaoProjetoAcoesVigentesNaoAdicionais')}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldText
                  disabled
                  format={handleFormatPontuacao}
                  placeholder={t('inputs.valor')}
                  name="pontuacaoProjetoAcoesNaoVigentesAdicionaisIRC"
                  label={t('desempenhoBiodiversidade.pontuacaoProjetoAcoesNaoVigentesAdicionais')}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldText
                  disabled
                  format={handleFormatPontuacao}
                  placeholder={t('inputs.valor')}
                  name="pontuacaoProjetoAcoesNaoVigentesNaoAdicionaisIRC"
                  label={t('desempenhoBiodiversidade.pontuacaoProjetoAcoesNaoVigentesNaoAdicionais')}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldText
                  disabled
                  format={handleFormatPontuacao}
                  placeholder={t('inputs.valor')}
                  name="pontuacaoProjetoAcoesSimuladasIRC"
                  label={t('desempenhoBiodiversidade.pontuacaoProjetoAcoesSimuladas')}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldText
                  disabled
                  format={handleFormatPontuacao}
                  name="pontuacaoTotalProjetoIRC"
                  placeholder={t('inputs.valor')}
                  label={t('desempenhoBiodiversidade.pontuacaoTotalProjeto')}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.lastTitle}>
              <Typography className={classes.tableTitle}>
                {t('desempenhoBiodiversidade.pontuacoesSemIRC')}
              </Typography>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FieldText
                  disabled
                  format={handleFormatPontuacao}
                  placeholder={t('inputs.valor')}
                  name="pontuacaoProjetoAcoesVigentesAdicionais"
                  label={t('desempenhoBiodiversidade.pontuacaoProjetoAcoesVigentesAdicionais')}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldText
                  disabled
                  format={handleFormatPontuacao}
                  placeholder={t('inputs.valor')}
                  name="pontuacaoProjetoAcoesVigentesNaoAdicionais"
                  label={t('desempenhoBiodiversidade.pontuacaoProjetoAcoesVigentesNaoAdicionais')}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldText
                  disabled
                  format={handleFormatPontuacao}
                  placeholder={t('inputs.valor')}
                  name="pontuacaoProjetoAcoesNaoVigentesAdicionais"
                  label={t('desempenhoBiodiversidade.pontuacaoProjetoAcoesNaoVigentesAdicionais')}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldText
                  disabled
                  format={handleFormatPontuacao}
                  placeholder={t('inputs.valor')}
                  name="pontuacaoProjetoAcoesNaoVigentesNaoAdicionais"
                  label={t('desempenhoBiodiversidade.pontuacaoProjetoAcoesNaoVigentesNaoAdicionais')}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldText
                  disabled
                  format={handleFormatPontuacao}
                  placeholder={t('inputs.valor')}
                  name="pontuacaoProjetoAcoesSimuladas"
                  label={t('desempenhoBiodiversidade.pontuacaoProjetoAcoesSimuladas')}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldText
                  disabled
                  format={handleFormatPontuacao}
                  name="pontuacaoTotalProjeto"
                  placeholder={t('inputs.valor')}
                  label={t('desempenhoBiodiversidade.pontuacaoTotalProjeto')}
                />
              </Grid>
            </Grid>
          </form>
        )}
      />
    </>
  )
}

Pontuacao.propTypes = {
  setChangeTab: PropTypes.func,
}

export default Pontuacao
