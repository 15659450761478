import MomentUtils from '@date-io/moment'
import { FormControlLabel, Grid, RadioGroup, Typography, makeStyles } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import Button from 'components/Button'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import { FieldAutocomplete, FieldDate, FieldSelect, FieldText } from 'components/Fields'
import { Radio } from 'final-form-material-ui'
import { filter } from 'lodash'
import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/pt-br'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Field, Form, FormSpy } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { callAtualizarAcao, callExcluirAcao } from 'store/modules/acoes'
import { getAcaoSelecionada, getIsSavingAcoes } from 'store/modules/acoes/acoes.selectors'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { getGrupoTemas } from 'store/modules/grupoTema/grupoTema.selectors'
import { getGrupos } from 'store/modules/grupos/grupos.selectors'
import { getProjetoSelecionado } from 'store/modules/projetos/projetos.selectors'
import { getQualificadores } from 'store/modules/qualificadores/qualificadores.selectors'
import { getTemas } from 'store/modules/temas/temas.selectors'
import { TIPO_USUARIO } from 'utils/constantes'
import { validateFormValues } from 'utils/forms'
import { formatDecimal } from 'utils/numbers'
import * as yup from 'yup'
import { enqueueSnackbar } from 'store/modules/notifications'
import QualifyAction from './QualifyAction'

const useStyles = makeStyles((theme) => ({
  classificacaoSeparator: {
    height: 1,
    width: '100%',
    backgroundColor: theme.palette.grey.main,
  },
  inputRadio: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const ClassificacaoPontuacao = ({ setChangeTab }) => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const dialog = useConfirmationDialog()

  const projetoSelecionado = useSelector(getProjetoSelecionado)
  const acaoSelecionada = useSelector(getAcaoSelecionada)
  const listaGrupos = useSelector(getGrupos)
  const listaTemas = useSelector(getTemas)
  const listaGrupoTema = useSelector(getGrupoTemas)
  const listaQualificadores = useSelector(getQualificadores)
  const isSaving = useSelector(getIsSavingAcoes)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const [grupoTemaSelecionado, setGrupoTemaSelecionado] = useState(null)
  const [formAlterado, setFormAlterado] = useState(false)

  const listaStatusAcao = [
    'statusAcao.acaoEmPlanejamento',
    'statusAcao.acaoContratar',
    'statusAcao.acaoRealizar',
    'statusAcao.acaoExecucao',
    'statusAcao.acaoFinalizada',
    'statusAcao.acaoSimulada',
  ]

  const listaTemporalidade = [
    { value: 'temporalidade.vigente', label: t('temporalidade.vigente') },
    { value: 'temporalidade.naoVigente', label: t('temporalidade.naoVigente') },
    { value: 'temporalidade.simulacao', label: t('temporalidade.simulacao') },
  ]

  useEffect(() => {
    moment.locale(t('inputs.calendario.idioma'))
  }, [t])

  useEffect(() => {
    setGrupoTemaSelecionado(acaoSelecionada?.grupoTema)
  }, [acaoSelecionada])

  const handleSave = ({
    pontuacaoParcial,
    pontuacaoAcaoSimulada,
    pontuacaoNaoVigenteAdicionalLegislacao,
    pontuacaoNaoVigenteNaoAdicionalLegislacao,
    pontuacaoVigenteAdicionalLegislacao,
    pontuacaoVigenteNaoAdicionalLegislacao,
    influenciaArea,
    ...values
  }) => {
    let influenciaAreaBool = influenciaArea === 'true'

    if (grupoTemaSelecionado.influenciaArea === 'influenciaArea.equacaoAreaTipoUm' ||
      grupoTemaSelecionado.influenciaArea === 'influenciaArea.equacaoAreaTipoUmFixa' ||
      grupoTemaSelecionado.influenciaArea === 'influenciaArea.equacaoAreaTipoDoisFixa') {
      influenciaAreaBool = true
    }

    const erroQualificadores =
      values.listaAcaoQualificador.some((el) => !el.qualificadorAvaliacaoId)

    if (erroQualificadores) {
      dispatch(enqueueSnackbar({
        message: t('mensagens.preencherCamposQualificadores'),
        options: { variant: 'warning' },
      }))
      return
    }

    const data = {
      ...values,
      listaAcaoQualificador: values.listaAcaoQualificador,
      pontuacaoParcial: pontuacaoParcial === 'true',
      influenciaArea: influenciaAreaBool,
    }
    if (acaoSelecionada && callAtualizarAcao) {
      dispatch(callAtualizarAcao({
        id: acaoSelecionada.id,
        acao: data,
      }))
    }
  }

  const handleDelete = () => {
    const { id } = acaoSelecionada
    dialog.showConfirmation({
      title: t('dialogs.confirmarExclusao'),
      message: t('dialogs.excluirProjetoAcao', { acao: acaoSelecionada.descricao, projeto: projetoSelecionado.nome }),
    }).then((result) => result && dispatch(callExcluirAcao({ id })))
  }

  const filtrarGrupoTemas = ({ grupoId, temaId }) => {
    const filters = {
      ...(grupoId && { grupoId }),
      ...(temaId && { temaId }),
    }

    return filter(listaGrupoTema, filters)
  }

  const handleGrupoTemaChange = (form) => () => {
    form.change('grupoTemaId', null)
  }

  const filtrarQualificadores = useCallback((grupoTemaId) => {
    const { qualificadorGrupoTemas } = listaGrupoTema.find((item) => item.id === grupoTemaId) || {}
    if (!qualificadorGrupoTemas?.length) return []

    const qualifierIds = qualificadorGrupoTemas.map((item) => item.qualificadorId)
    const qualifiers = listaQualificadores
      .filter((qualifier) => qualifierIds.includes(qualifier.id))

    return qualifiers
  }, [listaGrupoTema, listaQualificadores])

  const handleQualificadoresChange = (form) => (id) => {
    const grupoTema = listaGrupoTema.find((item) => item.id === id)
    setGrupoTemaSelecionado(grupoTema)
    const qualificadores = filtrarQualificadores(id)
      .map((qualificador) => ({ qualificadorId: qualificador.id }))
    return form.change('listaAcaoQualificador', qualificadores)
  }

  const handleQualificadorSelect = (form, values, initialValues) => (index, id) => {
    form.change(`listaAcaoQualificador.${index}.qualificadorId`, id)

    setFormAlterado(false)
    for (let i = 0; i < initialValues.listaAcaoQualificador.length; i += 1) {
      const initial = initialValues.listaAcaoQualificador[i]
      const current = values.listaAcaoQualificador[i]

      if (
        initial.anexo !== current.anexo ||
        initial.comentario !== current.comentario ||
        initial.evidencia !== current.evidencia ||
        initial.qualificadorAvaliacaoId !== current.qualificadorAvaliacaoId
      ) {
        setFormAlterado(true)
        break
      }
    }
  }

  const mapAutocompleteOptions = (option) => ({
    value: option.id,
    label: option.identificador ? `${option.identificador} - ${option.nome}` : option.nome,
  })

  const listaAcaoQualificador = useMemo(() => {
    const qualificadores = filtrarQualificadores(acaoSelecionada?.grupoTemaId)
    return qualificadores.map((qualificador) => {
      const value = acaoSelecionada?.acaoQualificadores
        .find((item) => item.qualificadorId === qualificador.id)
      return {
        qualificadorId: qualificador.id,
        ...(value && {
          id: value.id,
          anexo: value.anexo,
          evidencia: value.evidencia,
          comentario: value.comentario,
          qualificadorAvaliacaoId: value.qualificadorAvaliacaoId,
        }),
      }
    })
  }, [acaoSelecionada, filtrarQualificadores])

  const handleFormatPontuacao = (value) => {
    if (!value) return 0
    return formatDecimal({ min: 0, max: 5, locale: i18n.language, value })
  }

  return (
    <Form
      onSubmit={handleSave}
      validate={validateFormValues(yup.object({
        grupoId: yup.number().required(),
        temaId: yup.number().required(),
        grupoTemaId: yup.number().required(),
        periodoInicial: yup.date().nullable(),
        periodoFinal: yup.date().nullable(),
        statusAcao: yup.string().nullable(),
        temporalidade: yup.string().required(),
        adicionalidade: yup.boolean().required(),

        pontuacaoVigenteAdicionalLegislacao: yup.number().nullable(),
        pontuacaoVigenteNaoAdicionalLegislacao: yup.number().nullable(),
        pontuacaoNaoVigenteAdicionalLegislacao: yup.number().nullable(),
        pontuacaoNaoVigenteNaoAdicionalLegislacao: yup.number().nullable(),
        pontuacaoAcaoSimulada: yup.number().nullable(),
      }))}
      initialValues={{
        grupoId: acaoSelecionada?.grupoId ?? null,
        temaId: acaoSelecionada?.temaId ?? null,
        grupoTemaId: acaoSelecionada?.grupoTemaId ?? null,
        influenciaArea: acaoSelecionada?.influenciaArea ? 'true' : 'false',
        periodoInicial: acaoSelecionada?.periodoInicial || null,
        periodoFinal: acaoSelecionada?.periodoFinal || null,
        statusAcao: acaoSelecionada?.statusAcao ?? '',
        temporalidade: acaoSelecionada?.temporalidade ?? '',
        adicionalidade: acaoSelecionada?.adicionalidade ?? null,
        pontuacaoParcial: acaoSelecionada?.pontuacaoParcial ? 'true' : 'false',
        pontuacaoVigenteAdicionalLegislacao:
          acaoSelecionada?.pontuacaoVigenteAdicionalLegislacao ?? 0,
        pontuacaoVigenteNaoAdicionalLegislacao:
          acaoSelecionada?.pontuacaoVigenteNaoAdicionalLegislacao ?? 0,
        pontuacaoNaoVigenteAdicionalLegislacao:
          acaoSelecionada?.pontuacaoNaoVigenteAdicionalLegislacao ?? 0,
        pontuacaoNaoVigenteNaoAdicionalLegislacao:
          acaoSelecionada?.pontuacaoNaoVigenteNaoAdicionalLegislacao ?? 0,
        pontuacaoAcaoSimulada: acaoSelecionada?.pontuacaoAcaoSimulada ?? 0,
        listaAcaoQualificador,
      }}
      render={({ handleSubmit, form, values, pristine, valid, initialValues }) => (
        <form onSubmit={handleSubmit}>
          <FormSpy
            onChange={({ values: valuesSpy, initialValues: initialValuesSpy }) => {
              setChangeTab({
                values: valuesSpy,
                initialValues: initialValuesSpy,
              })
            }}
            subscription={{ values: true, initialValues: true, pristine: true }}
          />
          <MuiPickersUtilsProvider
            locale={t('inputs.calendario.idioma')}
            utils={MomentUtils}
            libInstance={moment}
          >
            <Grid container spacing={3} justifyContent="space-between">
              <Grid item xs={12}>
                <FieldAutocomplete
                  required
                  name="grupoId"
                  label={t('inputs.selecaoGrupo')}
                  onChange={handleGrupoTemaChange(form)}
                  options={listaGrupos.map(mapAutocompleteOptions)}
                  disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldAutocomplete
                  required
                  name="temaId"
                  label={t('inputs.selecaoTema')}
                  onChange={handleGrupoTemaChange(form)}
                  options={listaTemas.map(mapAutocompleteOptions)}
                  disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                />
              </Grid>
              <Grid item xs={12}>
                <div className={classes.classificacaoSeparator} />
              </Grid>
              <Grid item xs={12}>
                {values.grupoId != null && values.temaId != null && (
                  <FieldAutocomplete
                    required
                    name="grupoTemaId"
                    label={t('inputs.selecaoGrupoTema')}
                    onChange={handleQualificadoresChange(form)}
                    options={filtrarGrupoTemas(values).map(mapAutocompleteOptions)}
                    disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                  />
                )}
              </Grid>
              {values.grupoTemaId != null && (
                <>
                  {grupoTemaSelecionado &&
                    (grupoTemaSelecionado.influenciaArea === 'influenciaArea.equacaoAreaTipoUm' ||
                    grupoTemaSelecionado.influenciaArea === 'influenciaArea.equacaoAreaTipoDois') &&
                  (
                    <>
                      <Grid item xs={10} className={classes.inputRadio}>
                        <Typography className={classes.contentRowTitle}>
                          {t('inputs.tamanhoAreaInfluenciaDiretamenteExecucaoAcao')}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <RadioGroup row>
                          <FormControlLabel
                            label={t('inputs.nao')}
                            control={(
                              <Field
                                color="primary"
                                name="influenciaArea"
                                component={Radio}
                                type="radio"
                                value="false"
                                disabled={
                                  tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR || (grupoTemaSelecionado.influenciaArea === 'influenciaArea.equacaoAreaTipoUm')
                                }
                              />
                            )}
                          />
                          <FormControlLabel
                            label={t('inputs.sim')}
                            control={(
                              <Field
                                color="primary"
                                name="influenciaArea"
                                component={Radio}
                                type="radio"
                                value="true"
                                disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                              />
                            )}
                          />
                        </RadioGroup>
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} sm={6}>
                    <FieldDate
                      name="periodoInicial"
                      maxDate={values.periodoFinal}
                      label={t('inputs.periodoInicial')}
                      disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FieldDate
                      name="periodoFinal"
                      minDate={values.periodoInicial}
                      label={t('inputs.periodoFinal')}
                      disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FieldSelect
                      name="statusAcao"
                      label={t('inputs.statusAcao')}
                      options={listaStatusAcao.map((label) => ({ label: t(label), value: label }))}
                      disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FieldSelect
                      required
                      name="temporalidade"
                      options={listaTemporalidade}
                      label={t('inputs.temporalidade')}
                      disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FieldSelect
                      required
                      name="adicionalidade"
                      label={t('inputs.adicionalidade')}
                      options={[
                        { value: true, label: t('inputs.sim') },
                        { value: false, label: t('inputs.nao') },
                      ]}
                      disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <QualifyAction
                      onSelect={handleQualificadorSelect(form, values, initialValues)}
                      items={filtrarQualificadores(values.grupoTemaId)}
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.inputRadio}>
                    <Typography className={classes.contentRowTitle}>
                      {t('inputs.pontuacaoParcial')}
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <RadioGroup row>
                      <FormControlLabel
                        label={t('inputs.nao')}
                        control={(
                          <Field
                            color="primary"
                            name="pontuacaoParcial"
                            component={Radio}
                            type="radio"
                            value="false"
                            disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                          />
                        )}
                      />
                      <FormControlLabel
                        label={t('inputs.sim')}
                        control={(
                          <Field
                            color="primary"
                            name="pontuacaoParcial"
                            component={Radio}
                            type="radio"
                            value="true"
                            disabled={tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LEITOR}
                          />
                        )}
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FieldText
                      disabled
                      format={handleFormatPontuacao}
                      name="pontuacaoVigenteAdicionalLegislacao"
                      label={t('inputs.pontuacaoVigenteAdicionalLegislacao')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FieldText
                      disabled
                      format={handleFormatPontuacao}
                      name="pontuacaoVigenteNaoAdicionalLegislacao"
                      label={t('inputs.pontuacaoVigenteNaoAdicionalLegislacao')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FieldText
                      disabled
                      format={handleFormatPontuacao}
                      name="pontuacaoNaoVigenteAdicionalLegislacao"
                      label={t('inputs.pontuacaoNaoVigenteAdicionalLegislacao')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FieldText
                      disabled
                      format={handleFormatPontuacao}
                      name="pontuacaoNaoVigenteNaoAdicionalLegislacao"
                      label={t('inputs.pontuacaoNaoVigenteNaoAdicionalLegislacao')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FieldText
                      disabled
                      format={handleFormatPontuacao}
                      name="pontuacaoAcaoSimulada"
                      label={t('inputs.pontuacaoAcaoSimulada')}
                    />
                  </Grid>
                  {tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LEITOR && (
                    <>
                      <Grid item>
                        <Button variant="danger" disabled={!acaoSelecionada} onClick={handleDelete}>
                          {t('inputs.excluir')}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          type="submit"
                          disabled={!formAlterado && (pristine || !valid)}
                          loading={isSaving}
                        >
                          {t('inputs.salvar')}
                        </Button>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
          </MuiPickersUtilsProvider>
        </form>
      )}
    />
  )
}

ClassificacaoPontuacao.propTypes = {
  setChangeTab: PropTypes.func,
}

export default ClassificacaoPontuacao
