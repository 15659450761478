import { combineEpics } from 'redux-observable'
import { mergeMap, filter } from 'rxjs/operators'
import { apiGetEmpresa } from 'store/modules/api/empresas.actions'
import { apiFetchUsers } from 'store/modules/api/usuarios.actions'
import { apiListarDivisoesSetores } from 'store/modules/api/divisoesSetores.actions'
import { apiListarUnidades } from 'store/modules/api/unidades.actions'
import { ofLocationChangeTo } from 'utils/location'
import { TIPO_USUARIO } from 'utils/constantes'
import { getDadosUsuario, getIsAuthenticated, getEmpresaUsuario } from 'store/modules/auth/auth.selectors'

const onIniciarCadastros = (action$, state$) => action$.pipe(
  ofLocationChangeTo('/cadastroInicial'),
  filter(() => (
    getIsAuthenticated(state$.value) &&
    getDadosUsuario(state$.value).tipoUsuario.id === TIPO_USUARIO.CLIENTE.LIDER
  )),
  mergeMap(() => [
    apiListarUnidades(getEmpresaUsuario(state$.value).id),
    apiListarDivisoesSetores(getDadosUsuario(state$.value).empresa.id),
    apiGetEmpresa(getEmpresaUsuario(state$.value).id),
    apiFetchUsers(getEmpresaUsuario(state$.value).id),
  ]),
)

export default combineEpics(
  onIniciarCadastros,
)
