import { setIn } from 'final-form'
import { isFunction, isObject } from 'lodash'

export const validateFormValues = (schema) => async (values) => {
  const validator = isFunction(schema) ? schema() : schema

  try {
    await validator.validate(values, { abortEarly: false })
  } catch (errors) {
    return errors.inner.reduce((formError, innerError) => (
      setIn(formError, innerError.path, innerError.message)
    ), {})
  }

  return null
}

/**
 * ? Validação somente com pristine não estava funcionando para todos os casos
 * ? Exemplo: Quando não está sendo inicializado os inputs de texto com ''
 */
export const isFormChanged = ({
  dirtyValues,
  formValues,
  initialValues = {},
}) => Object.keys(dirtyValues).some((key) => {
  if (dirtyValues[key]) {
    if (key === 'anexo') {
      if (formValues[key] === null) {
        return false
      }
      return true
    }
    if (isObject(dirtyValues[key])) {
      return isFormChanged({
        dirtyValues: dirtyValues[key],
        formValues: formValues[key],
        initialValues: initialValues[key],
      })
    }
    if (initialValues) {
      return formValues[key] !== initialValues[key]
    }
  }
  return false
})

export const validate = () => null
