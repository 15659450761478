import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { API_LISTAR_DADOS_IPB } from 'store/modules/api/pressaoBiodiversidade.actions'
import { API_ATUALIZAR_FATURAMENTO, API_CRIAR_FATURAMENTO } from 'store/modules/api/faturamento.actions'
import { API_EXCLUIR_ANEXO } from 'store/modules/api/anexos.actions'

const selecionado = (state = null, { type, payload }) => {
  switch (type) {
    case API_LISTAR_DADOS_IPB.success: return payload.faturamento || null
    case API_CRIAR_FATURAMENTO.success: return payload.faturamento
    case API_ATUALIZAR_FATURAMENTO.success: return payload.faturamento
    case API_EXCLUIR_ANEXO.success:
      return state?.anexo?.id === +payload ? { ...state, anexo: null } : state
    default: return state
  }
}

const isCarregandoFaturamento = loadingReducer(false, API_LISTAR_DADOS_IPB)
const isSaving = loadingReducer(false, {
  request: [
    API_ATUALIZAR_FATURAMENTO.request,
    API_CRIAR_FATURAMENTO.request,
  ],
  success: [
    API_ATUALIZAR_FATURAMENTO.success,
    API_CRIAR_FATURAMENTO.success,
  ],
  failure: [
    API_ATUALIZAR_FATURAMENTO.failure,
    API_CRIAR_FATURAMENTO.failure,
  ],
})

export default combineReducers({
  selecionado,
  isCarregandoFaturamento,
  isSaving,
})
