import {
  Box,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core'
import Button from 'components/Button'
import { FieldSelect } from 'components/Fields'
import { map, range } from 'lodash'
import moment from 'moment'
import React from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getEmpresaSelecionada } from 'store/modules/empresas/empresas.selectors'
import { callCopiarDados } from 'store/modules/servico'
import { getIsSavingDados } from 'store/modules/servico/servico.selectors'
import { validateFormValues } from 'utils/forms'
import * as yup from 'yup'

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '672px',
    margin: theme.spacing(3.5, 'auto', 0),
  },
  formContainer: {
    marginBottom: theme.spacing(8),
  },
  title: {
    color: '#0F3444',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '40px',
    marginBottom: theme.spacing(3),
  },
  subtitle: {
    color: '#0F3444',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    marginBottom: theme.spacing(3),
  },
  alerta: {
    color: '#0F3444',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    marginBottom: theme.spacing(2),
  },
  asterisco: {
    color: '#0F3444',
    fontFamily: 'Inter',
    fontSize: '10px',
    fontStyle: 'italic',
    fontWeight: 400,
    lineHeight: '24px',
    marginBottom: theme.spacing(3),
  },
}))

const anoAtual = +moment().format('YYYY')
const anos = map(range(2014, anoAtual + 1), (ano) => ({
  value: ano.toString(), label: `${(ano - 1).toString()}/${ano.toString().slice(2)}`,
}))

const EmpresaCopiarDados = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const empresaSelecionada = useSelector(getEmpresaSelecionada)
  const isSaving = useSelector(getIsSavingDados)

  const handleSave = (values) => {
    dispatch(callCopiarDados({ ...values, empresaId: empresaSelecionada.id }))
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.formContainer}>
        <Typography component="h1" className={classes.title}>
          {t('meuPerfil.dadosTitulo')}
        </Typography>
        <Typography component="h2" className={classes.subtitle}>
          {t('meuPerfil.dadosTexto1')}
        </Typography>
        <Typography component="h2" className={classes.subtitle}>
          {t('meuPerfil.dadosTexto2')}
        </Typography>
        <Typography component="h3" className={classes.alerta}>
          {t('meuPerfil.dadosTexto3')}
        </Typography>
        <Form
          onSubmit={handleSave}
          validate={validateFormValues(
            yup.object({
              anoOrigem: yup.string().required(),
              anoAlvo: yup.string().required(),
            }),
          )}
          initialValues={{
            anoOrigem: '',
            anoAlvo: '',
          }}
          render={({ handleSubmit, form, values, pristine, valid }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FieldSelect
                    id="anoOrigem"
                    name="anoOrigem"
                    label={t('inputs.anoBaseOrigem')}
                    options={anos.map((option) => ({
                      label: option.label,
                      value: option.value,
                    }))}
                    onChange={(e) => {
                      if (values.anoAlvo === e.toString()) {
                        form.change('anoAlvo', '')
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FieldSelect
                    id="anoAlvo"
                    name="anoAlvo"
                    label={t('inputs.anoBaseDestino')}
                    options={anos.filter((ano) => ano.value !== values.anoOrigem).map((option) => ({
                      label: option.label,
                      value: option.value,
                    }))}
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="center" style={{ marginTop: '15px' }}>
                <Button
                  onClick={handleSubmit}
                  loading={isSaving}
                  disabled={pristine || !valid}
                  loadingText="inputs.copiandoDados"
                >
                  {t('inputs.duplicarDados')}
                </Button>
              </Grid>
            </form>
          )}
        />
      </Box>
    </Box>
  )
}

export default EmpresaCopiarDados
