import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchPrincipiosActionTypes = apiActionTypes('principios/API_LISTAR_PRINCIPIOS')
const getPrincipioActionTypes = apiActionTypes('principios/API_GET_PRINCIPIO')
const createPrincipioActionTypes = apiActionTypes('principios/API_CRIAR_PRINCIPIO')
const updatePrincipioActionTypes = apiActionTypes('principios/API_ATUALIZAR_PRINCIPIO')
const deletePrincipioActionTypes = apiActionTypes('principios/API_EXCLUIR_PRINCIPIO')

export const API_LISTAR_PRINCIPIOS = asApiTypesObject(fetchPrincipiosActionTypes)
export const API_GET_PRINCIPIO = asApiTypesObject(getPrincipioActionTypes)
export const API_CRIAR_PRINCIPIO = asApiTypesObject(createPrincipioActionTypes)
export const API_ATUALIZAR_PRINCIPIO = asApiTypesObject(updatePrincipioActionTypes)
export const API_EXCLUIR_PRINCIPIO = asApiTypesObject(deletePrincipioActionTypes)

export const apiListarPrincipios = ({ empresaId, ano, paisId }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/principios`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchPrincipiosActionTypes,
}, { ...meta, query: { empresaId, ano, paisId } })

export const apiListarPrincipiosPaisesCalculos = ({ paisId }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/principios/listarPrincipiosPaisesCalculos`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchPrincipiosActionTypes,
}, { ...meta, query: { paisId } })

export const apiGetPrincipio = (id, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/principios/${id}`,
  method: 'GET',
  types: getPrincipioActionTypes,
}, meta)

export const apiCriarPrincipio = (principio, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/principios`,
  method: 'POST',
  types: createPrincipioActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(principio),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiAtualizarPrincipio = ({ id, principio }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/principios/${id}`,
  method: 'PUT',
  types: updatePrincipioActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(principio),
}, { ...meta, successNotification: 'mensagens.atualizado' })

export const apiExcluirPrincipio = ({ id }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/principios/${id}`,
  method: 'DELETE',
  types: deletePrincipioActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })
