import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const getGasEfeitoEstufaActionTypes = apiActionTypes('gasesEfeitoEstufa/API_GET_GASES_EFEITO_ESTUFA')
const createGasEfeitoEstufaActionTypes = apiActionTypes('gasesEfeitoEstufa/API_CRIAR_GAS_EFEITO_ESTUFA')
const updateGasEfeitoEstufaActionTypes = apiActionTypes('gasesEfeitoEstufa/API_ATUALIZAR_GAS_EFEITO_ESTUFA')
const deleteGasEfeitoEstufaActionTypes = apiActionTypes('gasesEfeitoEstufa/API_EXCLUIR_GAS_EFEITO_ESTUFA')

export const API_GET_GASES_EFEITO_ESTUFA = asApiTypesObject(getGasEfeitoEstufaActionTypes)
export const API_CRIAR_GAS_EFEITO_ESTUFA = asApiTypesObject(createGasEfeitoEstufaActionTypes)
export const API_ATUALIZAR_GAS_EFEITO_ESTUFA = asApiTypesObject(updateGasEfeitoEstufaActionTypes)
export const API_EXCLUIR_GAS_EFEITO_ESTUFA = asApiTypesObject(deleteGasEfeitoEstufaActionTypes)

export const apiGetGasEfeitoEstufa = (id, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/emissaoGases/${id}`,
  method: 'GET',
  types: getGasEfeitoEstufaActionTypes,
}, meta)

export const apiCriarGasEfeitoEstufa = (gasEfeitoEstufa, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/emissaoGases`,
  method: 'POST',
  types: createGasEfeitoEstufaActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(gasEfeitoEstufa),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiAtualizarGasEfeitoEstufa = ({ id, gasEfeitoEstufa }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/emissaoGases/${id}`,
  method: 'PUT',
  types: updateGasEfeitoEstufaActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(gasEfeitoEstufa),
}, { ...meta, successNotification: 'mensagens.atualizado' })

export const apiExcluirGasEfeitoEstufa = (id, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/emissaoGases/${id}`,
  method: 'DELETE',
  types: deleteGasEfeitoEstufaActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })
