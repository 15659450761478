import React from 'react'
import {
  Typography,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TableContainer,
  CircularProgress,
  IconButton,
  makeStyles,
  Avatar,
} from '@material-ui/core'
import { Edit, Delete } from '@material-ui/icons'
import { useSelector, useDispatch } from 'react-redux'
import { map } from 'lodash'
import { getUsuarios, getIsCarregandoListaUsuarios } from 'store/modules/usuarios/usuarios.selectors'
import { callExcluirUsuario, callSelecionarUsuario } from 'store/modules/usuarios'
import gerarAvatar from 'utils/gerarAvatar'
import { useTranslation } from 'react-i18next'
import UsuarioCadastro from './UsuarioCadastro'

const useStyles = makeStyles((theme) => ({
  avatarRounded: {
    color: 'black',
    fontSize: '0.875rem',
    fontWeight: '700',
    backgroundColor: '#C4C4C4',
    marginRight: theme.spacing(2),
  },
  avatarDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  gridIcon: {
    color: '#9E9E9E',
  },
  gridHeadText: {
    color: '#9E9E9E',
  },
}))

const UsuarioLista = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsCarregandoListaUsuarios)
  const usuarios = useSelector(getUsuarios)

  const classes = useStyles()

  const handleEdit = (id) => {
    dispatch(callSelecionarUsuario({ id }))
  }

  const handleDelete = (id) => {
    dispatch(callExcluirUsuario({ id }))
  }

  return (
    <>
      <UsuarioCadastro />
      <Typography variant="h6" gutterBottom>
        {t('usuario.usuariosCadastrados')}
      </Typography>
      {isLoading && <CircularProgress />}
      {!isLoading && (
      <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.gridHeadText}>{t('inputs.nome')}</TableCell>
                <TableCell className={classes.gridHeadText}>{t('inputs.email')}</TableCell>
                <TableCell className={classes.gridHeadText}>{t('inputs.tipoPermissao')}</TableCell>
                <TableCell className={classes.gridHeadText}>{t('inputs.status')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {map(usuarios, ({ id, nome, email, tipoUsuario }) => (
                <TableRow key={id}>
                  <TableCell>
                    <div className={classes.avatarDiv}>
                      <Avatar className={classes.avatarRounded}>
                        {gerarAvatar(nome)}
                      </Avatar>
                      {nome}
                    </div>
                  </TableCell>
                  <TableCell>{email}</TableCell>
                  <TableCell>{t(tipoUsuario.tipo)}</TableCell>
                  <TableCell>{t('inputs.ativo')}</TableCell>
                  <TableCell>
                    <IconButton aria-label="edit" onClick={() => handleEdit(id)}>
                      <Edit fontSize="medium" className={classes.gridIcon} />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => handleDelete(id)}>
                      <Delete fontSize="medium" className={classes.gridIcon} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
      )}
    </>
  )
}

export default UsuarioLista
