import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const getProjetoActionTypes = apiActionTypes('projetos/API_GET_PROJETO')
const createProjetoActionTypes = apiActionTypes('projetos/API_CRIAR_PROJETO')
const updateProjetoActionTypes = apiActionTypes('projetos/API_ATUALIZAR_PROJETO')
const deleteProjetoActionTypes = apiActionTypes('projetos/API_EXCLUIR_PROJETO')

export const API_GET_PROJETO = asApiTypesObject(getProjetoActionTypes)
export const API_CRIAR_PROJETO = asApiTypesObject(createProjetoActionTypes)
export const API_ATUALIZAR_PROJETO = asApiTypesObject(updateProjetoActionTypes)
export const API_EXCLUIR_PROJETO = asApiTypesObject(deleteProjetoActionTypes)

export const apiGetProjeto = (id, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/projetos/${id}`,
  method: 'GET',
  types: getProjetoActionTypes,
}, meta)

export const apiCriarProjeto = (projeto, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/projetos`,
  method: 'POST',
  types: createProjetoActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(projeto),
}, { ...meta })

export const apiAtualizarProjeto = ({ id, projeto }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/projetos/${id}`,
  method: 'PUT',
  types: updateProjetoActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(projeto),
}, { ...meta, successNotification: 'mensagens.atualizado' })

export const apiExcluirProjeto = (id, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/projetos/${id}`,
  method: 'DELETE',
  types: deleteProjetoActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido', id })
