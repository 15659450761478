import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { FormControlLabel, makeStyles, Radio, Typography } from '@material-ui/core'
import Button from 'components/Button'
import Filters from 'components/FilterList/Filters'
import clsx from 'clsx'
import { get, find } from 'lodash'
import Pagination from 'components/Pagination'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import { useSelector, useDispatch } from 'react-redux'
import {
  getListaParecerDesempenhoBiodiverdidade,
} from 'store/modules/auditor/auditor.selectors'
import {
  comboDesempenhoComentarSobre,
  comboDesempenhoOcorrenciaIrcProjeto,
  comboDesempenhoOcorrenciaPontuacaoProjeto,
  comboDesempenhoOcorrenciaProjeto,
  comboDesempenhoTipoComentario,
  comboDesempenhoOcorrenciaAcaoProjeto,
  comboDesempenhoOcorrenciaClassificacaoAcaoProjeto,
  comboDesempenhoOcorrenciaQualificadoresAcaoProjeto,
  TIPO_USUARIO,
} from 'utils/constantes'
import { callSalvarParecerDesempenho } from 'store/modules/auditor'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'

const useStyles = makeStyles((theme) => ({
  withoutResults: {
    fontSize: '32px',
    fontWeight: '400',
    color: '#C9CEDB',
    textAlign: 'center',
    padding: '8vh 0',
  },
  contentBox: {
    minHeight: '40vh',
  },
  maxInput: {
    maxWidth: '420px',
  },
  footerSection: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '33px',
    marginBottom: '33px',
  },
  search: {
    marginBottom: '32px',
  },
  listComments: {
    listStyle: 'none',
    padding: '32px 10px',
    borderTop: `1px solid ${theme.palette.grey.main}`,
    display: 'flex',
    flexDirection: 'column',
    rowGap: '12px',
  },
  lineComment: {
    display: 'flex',
    columnGap: '16px',
  },
  textComment: {
    fontSize: '14px',
    color: '#0F3444',
    whiteSpace: 'pre-line',
    margin: 0,
  },
  firstColumn: {
    minWidth: '180px',
  },
  pending: {
    displa: 'block',
    borderRadius: '100px',
    padding: '3px 12px',
    color: '#FFFFFF',
    backgroundColor: '#F85453',
  },
  concluded: {
    displa: 'block',
    borderRadius: '100px',
    padding: '3px 12px',
    color: '#FFFFFF',
    backgroundColor: '#219754',
  },
  pagination: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.grey.main}`,
    paddingTop: '32px',
    boxSizing: 'border-box',
  },
}))

const TabDesempenhoBiodiversidade = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dialog = useConfirmationDialog()
  const dispatch = useDispatch()

  const listaPareceres = useSelector(getListaParecerDesempenhoBiodiverdidade)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const [page, setPage] = useState(0)
  const pageSize = 5
  const filterScreen = [
    { value: true, label: 'filtros.maisAntigos', name: 'time' },
    { value: false, label: 'filtros.maisRecentes', name: 'time' },
  ]

  const handleSave = (values) => {
    dialog.showConfirmation({
      title: t('dialogs.conclusaoParecerAuditoria'),
      message: t('dialogs.temCertezaQueDesejaConfirmarConclusaoDesteParecerAuditoria'),
    }).then((result) => {
      if (result) {
        dispatch(callSalvarParecerDesempenho(values))
      }
    })
  }

  const [data, setData] = useState(listaPareceres)

  useEffect(() => {
    setData(listaPareceres)
  }, [listaPareceres])

  const filteredData = data
    .slice(page * pageSize, page * pageSize + pageSize).map((item) => ({ ...item }))

  const getNomeCampo = (parecer) => {
    switch (parecer.tipoOcorrencia) {
      case 'DADOS_PROJETO':
        return t(find(comboDesempenhoOcorrenciaProjeto, { id: parecer.campo }).label)
      case 'PONTUACAO_PROJETO':
        return t(find(comboDesempenhoOcorrenciaPontuacaoProjeto, { id: parecer.campo }).label)
      case 'IRC_PROJETO':
        return t(find(comboDesempenhoOcorrenciaIrcProjeto, { id: parecer.campo }).label)
      case 'DADOS_ACAO':
        return t(find(comboDesempenhoOcorrenciaAcaoProjeto, { id: parecer.campo }).label)
      case 'CLASSIFICACAO_ACAO':
        return t(find(
          comboDesempenhoOcorrenciaClassificacaoAcaoProjeto,
          { id: parecer.campo },
        ).label)
      case 'QUALIFICADORES_ACAO':
        return t(find(
          comboDesempenhoOcorrenciaQualificadoresAcaoProjeto,
          { id: parecer.campo },
        ).label)
      default:
        return ''
    }
  }

  return (
    <div className={classes.contentBox}>
      <div className={classes.search}>
        <Filters
          fullWidth
          items={listaPareceres}
          setItems={setData}
          origin={{ vertical: 'top', horizontal: 'right' }}
          filterScreen={filterScreen}
          placeholder={`${data.length} ${t('inputs.comentarios')}`}
          disabled
        />
      </div>
      {data.length > 0 ? (
        <>
          {filteredData.map((parecer, index) => (
            <Form
              key={index}
              onSubmit={handleSave}
              initialValues={{
                id: parecer.id,
                resolvido: parecer?.resolvido ?? false,
              }}
              render={({ handleSubmit, values, form }) => (
                <form onSubmit={handleSubmit}>
                  <ul className={classes.listComments} key={index}>
                    <li className={classes.lineComment}>
                      <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.auditorResponsavel')}</h3>
                      <p className={classes.textComment}>{parecer.auditor}</p>
                    </li>
                    <li className={classes.lineComment}>
                      <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.tipoComentario')}</h3>
                      <p className={classes.textComment}>
                        {t(find(comboDesempenhoTipoComentario, {
                          id: parecer.tipoComentario,
                        }).label)}
                      </p>
                    </li>
                    <li className={classes.lineComment}>
                      <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.comentarioSobre')}</h3>
                      <p className={classes.textComment}>
                        {t(find(comboDesempenhoComentarSobre, {
                          id: parecer.tipoOcorrencia,
                        }).label)}
                      </p>
                    </li>
                    {
                      parecer.tipoOcorrencia !== 'OUTRO' && (
                      <li className={classes.lineComment}>
                        <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.qualProjeto')}</h3>
                        <p className={classes.textComment}>{parecer.projeto?.nome}</p>
                      </li>
                      )
                    }

                    {
                      parecer.tipoOcorrencia === 'PONTUACAO_PROJETO' && (
                      <li className={classes.lineComment}>
                        <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.qualPontuacao')}</h3>
                        <p className={classes.textComment}>
                          {t(find(
                            comboDesempenhoOcorrenciaPontuacaoProjeto,
                            { id: parecer.tipoPontuacao },
                          )?.label)}
                        </p>
                      </li>
                      )
                    }

                    {parecer.acaoId && (
                      <li className={classes.lineComment}>
                        <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.qualAcao')}</h3>
                        <p className={classes.textComment}>{parecer.acao?.descricao}</p>
                      </li>
                    )}

                    {parecer.qualificadorId && (
                      <li className={classes.lineComment}>
                        <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.qualQualificador')}</h3>
                        <p className={classes.textComment}>{`${parecer.qualificador?.identificador} ${parecer.qualificador?.nome}`}</p>
                      </li>
                    )}

                    {
                      !['PONTUACAO_PROJETO', 'PONTUACAO_ACAO', 'OUTRO'].includes(parecer.tipoOcorrencia) && (
                      <li className={classes.lineComment}>
                        <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.campoItem')}</h3>
                        <p className={classes.textComment}>{getNomeCampo(parecer)}</p>
                      </li>
                      )
                    }

                    <li className={classes.lineComment}>
                      <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.validadeDias')}</h3>
                      <p className={classes.textComment}>{parecer.validade}</p>
                    </li>
                    <li className={classes.lineComment}>
                      <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.comentario')}</h3>
                      <p className={classes.textComment}>{parecer.comentario}</p>
                    </li>
                    <li className={classes.lineComment}>
                      <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.status')}</h3>
                      <p className={
                        clsx(
                          classes.textComment,
                          parecer.resolvido ? classes.concluded : classes.pending,
                        )
                        }
                      >
                        {t(`auditoriaEmpresa.${(parecer.resolvido ? 'concluido' : 'pendente')}`)}
                      </p>
                    </li>
                    {!parecer.resolvido && tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LEITOR && (
                      <li className={classes.lineComment}>
                        <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.conclusao')}</h3>
                        <FormControlLabel
                          className={classes.label}
                          label={<Typography className={classes.label}>{t('inputs.sim')}</Typography>}
                          control={(
                            <Radio
                              checked={get(values, 'resolvido', false)}
                              onChange={() => form.change('resolvido', !values.status)}
                              color="primary"
                            />
                          )}
                        />
                      </li>
                    )}
                  </ul>

                  {!parecer.resolvido && tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LEITOR && (
                    <div className={classes.footerSection}>
                      <Button
                        disabled={!values.resolvido}
                        variant="danger"
                        onClick={() => form.change('resolvido', false)}
                      >
                        {t('inputs.cancelar')}
                      </Button>

                      <Button
                        disabled={!values.resolvido}
                        type="submit"
                      >
                        {t('inputs.salvar')}
                      </Button>
                    </div>
                  )}

                </form>
              )}
            />
          ))}
          <div className={classes.pagination}>
            {!!filteredData.length && (
              <Pagination
                page={page}
                onChange={setPage}
                pageSize={pageSize}
                count={data.length}
              />
            )}
          </div>
        </>
      ) : (
        <Typography component="h1" className={classes.withoutResults}>
          {t('auditoriaEmpresa.seuAuditorAindaNaoEnviouComentarios')}
        </Typography>
      )}
    </div>
  )
}

export default TabDesempenhoBiodiversidade
