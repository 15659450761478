import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { API_LISTAR_SETORES } from 'store/modules/api/setores.actions'

const lista = (state = null, { type, payload }) => (
  (type === API_LISTAR_SETORES.success) ? payload : state
)

const isCarregandoLista = loadingReducer(false, API_LISTAR_SETORES)

export default combineReducers({
  lista,
  isCarregandoLista,
})
