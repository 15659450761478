import React, { useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { map, keys, find, isEmpty } from 'lodash'
import {
  Grid,
  Typography,
  makeStyles,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TableContainer,
  IconButton,
} from '@material-ui/core'
import Edit2LineIcon from 'remixicon-react/Edit2LineIcon'
import DeleteBin7LineIcon from 'remixicon-react/DeleteBin7LineIcon'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import {
  callCriarUsuario,
  callAtualizarUsuario,
  callNovoUsuario,
  callExcluirUsuario,
  callSelecionarUsuario,
} from 'store/modules/usuarios'
import { getTipoUsuariosCliente } from 'store/modules/tipoUsuarios/tipoUsuarios.selectors'
import { getEmpresaUsuario } from 'store/modules/auth/auth.selectors'
import { getUsuarioSelecionado, getUsuarios } from 'store/modules/usuarios/usuarios.selectors'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-final-form'
import {
  StyledButton,
} from 'components/commonStyles'
import Button from 'components/Button'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import { validateFormValues } from 'utils/forms'
import { FieldAutocomplete, FieldText } from 'components/Fields'

const useStyles = makeStyles((theme) => ({
  gridButton: {
    padding: theme.spacing(5, 0),
  },
  backButton: {
    margin: theme.spacing(0, 4, 0, 0),
  },
  mr2: {
    marginRight: theme.spacing(2),
  },
  title: {
    fontSize: '32px',
    fontWeight: 400,
    color: theme.palette.primary.main,
  },
  subtitle: {
    fontFamily: theme.typography.secondary,
    color: theme.palette.primary.main,
    fontWeight: 400,
    margin: theme.spacing(0, 0, 6, 0),
    fontSize: 14,
  },
  formContainer: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 8,
    padding: theme.spacing(4),
    margin: theme.spacing(0, 0, 2, 0),
  },
  mailCell: {
    maxWidth: 182,
    overflowWrap: 'break-word',
  },
  actionsCell: {
    padding: theme.spacing(2, 0),
    width: 60,
  },
  actionIcon: {
    color: theme.palette.common.black,
    width: 20,
    height: 20,
  },
  icon: {
    display: 'inline-table',
    margin: '0 5px',
  },
  gridHeadText: {
    color: theme.palette.grey.blue,
    fontSize: 14,
    borderBottom: 'none',
  },
  tableTitle: {
    fontSize: 14,
    fontWeight: 'normal',
    color: theme.palette.primary.main,
    margin: theme.spacing(3, 0, 2),
  },
  firstCell: {
    padding: theme.spacing(2, 2, 2, 0),
  },
  lastCell: {
    padding: theme.spacing(2, 0),
  },
}))

const UsuarioCadastro = ({ Botoes }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const dialog = useConfirmationDialog()

  const listaTipoUsuarios = useSelector(getTipoUsuariosCliente)
  const usuarioSelecionado = useSelector(getUsuarioSelecionado)
  const empresa = useSelector(getEmpresaUsuario)
  const usuarios = useSelector(getUsuarios)

  const top = useRef()

  const handleSave = (values, form) => {
    const data = {
      ...values,
      empresaId: empresa.id,
    }

    if (usuarioSelecionado) {
      dispatch(callAtualizarUsuario({
        id: usuarioSelecionado.id,
        usuario: data,
        successNotification: 'mensagens.atualizado',
      }))
    } else {
      dispatch(callCriarUsuario(data))
    }

    form.reset()
    Object.keys(values).forEach((key) => {
      form.resetFieldState(key)
    })
  }

  const handleEdit = (id) => {
    top.current.scrollIntoView()
    dispatch(callSelecionarUsuario({ id }))
  }

  const handleDelete = async (id) => {
    const usuarioExcluir = find(usuarios, { id })
    const result = await dialog.showConfirmation({
      title: t('dialogs.confirmarExclusao'),
      message: t('dialogs.excluirUsuario', { usuario: usuarioExcluir.nome }),
    })
    if (result) {
      dispatch(callExcluirUsuario({ id }))
    }
  }

  const handleCancel = (form, values) => (() => {
    dispatch(callNovoUsuario())
    form.reset()
    Object.keys(values).forEach((key) => {
      form.resetFieldState(key)
    })
  })

  return (
    <>
      <Grid item xs={6} className={classes.formContainer}>
        <Form
          onSubmit={handleSave}
          validate={validateFormValues(yup.object({
            nome: yup.string().required(),
            email: yup.string().email().required(),
            tipoUsuarioId: yup.number().required(),
          }))}
          initialValues={{
            nome: usuarioSelecionado?.nome ?? '',
            email: usuarioSelecionado?.email ?? '',
            tipoUsuarioId: usuarioSelecionado?.tipoUsuario?.id ?? null,
          }}
          render={({ handleSubmit, form, isSubmitting, values, touched, errors }) => (
            <form onSubmit={handleSubmit}>
              <Grid container alignItems="center" direction="row" spacing={4}>
                <Grid item xs={7} lg={9}>
                  <Typography component="h1" className={classes.title}>
                    {t('usuario.cadastroUsuariosSistema')}
                  </Typography>
                </Grid>
                <Grid container item xs={5} lg={3} justifyContent="flex-end">
                  <Button
                    type="button"
                    onClick={handleCancel(form, values)}
                    disabled={keys(touched).length === 0 || keys(errors).length !== 0}
                  >
                    {t('usuario.adicionarUsuario')}
                  </Button>
                </Grid>
              </Grid>
              <Typography component="p" className={classes.subtitle} ref={top}>
                {t('cadastroInicial.etapa5')}
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FieldText
                    required
                    name="nome"
                    label={t('inputs.nomeCompleto')}
                    placeholder={t('inputs.insiraNome')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FieldText
                    required
                    name="email"
                    label={t('inputs.email')}
                    placeholder={t('inputs.insiraEmail')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FieldAutocomplete
                    required
                    name="tipoUsuarioId"
                    label={t('inputs.tipoPermissao')}
                    options={(listaTipoUsuarios || []).map((tipo) => ({
                      value: tipo.id,
                      label: t(tipo.tipo),
                    }))}
                  />
                </Grid>

                {usuarioSelecionado?.id ? (
                  <Grid container item xs={12} direction="row" justifyContent="space-between" className={classes.gridButton}>
                    <StyledButton
                      disabled={isSubmitting}
                      onClick={handleCancel(form, values)}
                    >
                      {t('inputs.cancelar')}
                    </StyledButton>
                    <StyledButton
                      disabled={isSubmitting ||
                        keys(touched).length === 0 ||
                        keys(errors).length !== 0}
                      onClick={handleSubmit}
                    >
                      {t('usuario.atualizarUsuario')}
                    </StyledButton>
                  </Grid>
                ) : (
                  <Grid container item xs={12} direction="row" justifyContent="flex-end" className={classes.gridButton}>
                    <StyledButton
                      disabled={isSubmitting ||
                        keys(touched).length === 0 ||
                        keys(errors).length !== 0}
                      onClick={handleSubmit}
                    >
                      {t('usuario.convidarUsuario')}
                    </StyledButton>
                  </Grid>
                )}

                {!isEmpty(usuarios) && (
                  <Grid item xs={12}>
                    <Typography component="h3" className={classes.tableTitle}>
                      {`${t('usuario.usuariosCadastrados')}:`}
                    </Typography>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell className={`${classes.gridHeadText} ${classes.firstCell}`}>{t('inputs.nome')}</TableCell>
                            <TableCell className={`${classes.gridHeadText} ${classes.mailCell}`}>{t('inputs.email')}</TableCell>
                            <TableCell className={classes.gridHeadText}>{t('inputs.permissao')}</TableCell>
                            <TableCell className={`${classes.gridHeadText} ${classes.lastCell}`} align="right">{t('inputs.acoes')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {map(usuarios, ({ id, nome, email, tipoUsuario }) => (
                            <TableRow key={id}>
                              <TableCell className={classes.firstCell}>{nome}</TableCell>
                              <TableCell className={classes.mailCell}>{email}</TableCell>
                              <TableCell>{t(tipoUsuario.tipo)}</TableCell>
                              <TableCell className={classes.actionsCell} align="right">
                                <IconButton aria-label="edit" onClick={() => handleEdit(id)} className={classes.icon}>
                                  <Edit2LineIcon className={classes.actionIcon} />
                                </IconButton>
                                <IconButton aria-label="delete" onClick={() => handleDelete(id)} className={classes.icon}>
                                  <DeleteBin7LineIcon className={classes.actionIcon} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )}
                {Botoes && <Botoes extraFunc={handleSubmit} formErrors={errors} />}
              </Grid>
            </form>
          )}
        />
      </Grid>
    </>
  )
}

UsuarioCadastro.propTypes = {
  Botoes: PropTypes.func,
}

export default UsuarioCadastro
