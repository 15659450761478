import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { API_LISTAR_QUALIFICADOR_AVALIACAO } from 'store/modules/api/qualificadorAvaliacao.actions'

const lista = (state = [], { type, payload }) => (
  (type === API_LISTAR_QUALIFICADOR_AVALIACAO.success) ? payload.lista : state
)

const total = (state = null, { type, payload }) => (
  (type === API_LISTAR_QUALIFICADOR_AVALIACAO.success) ? payload.total : state
)

const isCarregandoLista = loadingReducer(false, API_LISTAR_QUALIFICADOR_AVALIACAO)

export default combineReducers({
  lista,
  total,
  isCarregandoLista,
})
