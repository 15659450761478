import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchGrupoTemaTemaActionTypes = apiActionTypes('grupoTema/API_LISTAR_GRUPO_TEMA')
const updateGrupoTemaActionTypes = apiActionTypes('grupoTema/API_ATUALIZAR_GRUPO_TEMA')
const createGrupoTemaActionTypes = apiActionTypes('grupoTema/API_CRIAR_GRUPO_TEMA')
const deleteGrupoTemaActionTypes = apiActionTypes('grupoTema/API_EXCLUIR_GRUPO_TEMA')

export const API_LISTAR_GRUPO_TEMA = asApiTypesObject(fetchGrupoTemaTemaActionTypes)
export const API_CRIAR_GRUPO_TEMA = asApiTypesObject(createGrupoTemaActionTypes)
export const API_EXCLUIR_GRUPO_TEMA = asApiTypesObject(deleteGrupoTemaActionTypes)

export const apiListarGrupoTema = ({ paisId }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/grupoTema`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchGrupoTemaTemaActionTypes,
}, { ...meta, query: { paisId } })

export const apiAtualizarGrupoTema = ({ id, grupo }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/grupoTema/${id}`,
  method: 'PUT',
  types: updateGrupoTemaActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(grupo),
}, { ...meta, successNotification: 'mensagens.atualizado' })

export const apiCriarGrupoTema = (grupo, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/grupoTema`,
  method: 'POST',
  types: createGrupoTemaActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(grupo),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiSalvarListaGrupoTema = ({ lista }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/grupoTema/salvarLista`,
  method: 'POST',
  types: createGrupoTemaActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(lista),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiExcluirGrupoTema = ({ id }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/grupoTema/${id}`,
  method: 'DELETE',
  types: deleteGrupoTemaActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })
