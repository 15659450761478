import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { LOCATION_CHANGE } from 'connected-react-router'
import { API_GET_LICENCA, API_LISTAR_LICENCAS, API_CRIAR_LICENCA, API_EXCLUIR_LICENCA, API_ATUALIZAR_LICENCA } from 'store/modules/api/licencas.actions'
import { CALL_NOVA_LICENCA } from 'store/modules/licencas/licencas.actions'
import { matchLocation } from 'utils/location'
import { filter, map } from 'lodash'

const lista = (state = [], { type, payload }) => {
  switch (type) {
    case API_LISTAR_LICENCAS.success: return payload
    case API_CRIAR_LICENCA.success: return [...state, payload]
    case API_EXCLUIR_LICENCA.success: return filter(state, (o) => o.id !== payload)
    case API_ATUALIZAR_LICENCA.success:
      return map(state, (o) => (o.id === payload.id ? payload : o))
    default: return state
  }
}

const selecionado = (state = null, { type, payload }) => {
  switch (type) {
    case API_GET_LICENCA.success: return payload
    case CALL_NOVA_LICENCA: return null
    case LOCATION_CHANGE:
      return (matchLocation(payload, '/licencas/:id') || matchLocation(payload, '/dadosLicenca')) ? state : null
    case API_ATUALIZAR_LICENCA.success: return payload
    default: return state
  }
}

const isCarregandoLista = loadingReducer(false, API_LISTAR_LICENCAS)
const isCarregandoLicenca = loadingReducer(false, API_GET_LICENCA)

export default combineReducers({
  lista,
  isCarregandoLista,
  isCarregandoLicenca,
  selecionado,
})
