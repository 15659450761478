import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { getDadosUsuario } from 'store/modules/auth/auth.selectors'
import hasAuthorization from 'utils/auth'
import { VIEW_CLIENT, VIEW_ADMIN, VIEW_AUDITOR } from 'utils/constantes'

const PrivateRoute = ({ component: Component, requiredPermissions, ...otherProps }) => {
  const usuario = useSelector(getDadosUsuario)

  const redirectTo = () => {
    if (!usuario || isEmpty(usuario)) {
      return '/login'
    }

    if (VIEW_CLIENT.includes(usuario.tipoUsuario.id)) {
      return '/'
    }

    if (VIEW_ADMIN.includes(usuario.tipoUsuario.id)) {
      return '/adm/empresas'
    }

    if (VIEW_AUDITOR.includes(usuario.tipoUsuario.id)) {
      return '/auditorias'
    }

    return '/login'
  }

  return (
    <Route
      {...otherProps}
      render={(props) => (
        hasAuthorization(requiredPermissions, usuario?.tipoUsuario?.id) ?
          <Component {...props} /> :
          <Redirect to={otherProps.redirectTo || redirectTo()} />
      )}
    />
  )
}

PrivateRoute.propTypes = {
  requiredPermissions: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.object]),
}

export default PrivateRoute
