const ACTION_PATH = 'app/consumoAgua'

export const CALL_CRIAR_CONSUMO_AGUA = `${ACTION_PATH}/CALL_CRIAR_CONSUMO_AGUA`
export const CALL_ATUALIZAR_CONSUMO_AGUA = `${ACTION_PATH}/CALL_ATUALIZAR_CONSUMO_AGUA`
export const CALL_EXCLUIR_CONSUMO_AGUA = `${ACTION_PATH}/CALL_EXCLUIR_CONSUMO_AGUA`
export const CALL_SELECIONAR_CONSUMO_AGUA = `${ACTION_PATH}/CALL_SELECIONAR_CONSUMO_AGUA`
export const CALL_NOVO_CONSUMO_AGUA = `${ACTION_PATH}/CALL_NOVO_CONSUMO_AGUA`

export const callCriarConsumoAgua = (consumoAgua) => ({
  type: CALL_CRIAR_CONSUMO_AGUA,
  payload: consumoAgua,
})

export const callAtualizarConsumoAgua = ({ id, consumoAgua }) => ({
  type: CALL_ATUALIZAR_CONSUMO_AGUA,
  payload: { id, consumoAgua },
})

export const callExcluirConsumoAgua = ({ id }) => ({
  type: CALL_EXCLUIR_CONSUMO_AGUA,
  payload: id,
})

export const callSelecionarConsumoAgua = ({ id }) => ({
  type: CALL_SELECIONAR_CONSUMO_AGUA,
  payload: { id },
})

export const callNovoConsumoAgua = () => ({
  type: CALL_NOVO_CONSUMO_AGUA,
})
