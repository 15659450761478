import { RSAA, isRSAA } from 'redux-api-middleware'
import { API_ENDPOINT } from 'env'
import { startsWith, find } from 'lodash'
import { getToken } from 'store/modules/auth/auth.selectors'

export const AUTH_TOKEN_KEY = 'Authorization'

const EXCLUDE_URLS = []

const excludeUrls = (state, rsaa = {}) => {
  const rsaaParams = rsaa[RSAA] || {}
  const { endpoint = '' } = rsaaParams
  return !!find(EXCLUDE_URLS, (url) => endpoint.startsWith(url))
}

const excludeConditions = [
  excludeUrls,
]

const authMiddleware = ({ getState }) => (next) => (action = {}) => {
  if (!isRSAA(action)) return next(action)

  const state = getState()

  const rsaaParams = action[RSAA] || {}
  const { endpoint = '', headers = {} } = rsaaParams

  if (headers[AUTH_TOKEN_KEY]) return next(action)
  if (!startsWith(endpoint, API_ENDPOINT)) return next(action)
  if (find(excludeConditions, (cond) => cond(state, action))) return next(action)

  const token = getToken(state)

  rsaaParams.headers = {
    ...headers,
    'Content-Type': headers['Content-Type'] || 'application/json',
    [AUTH_TOKEN_KEY]: `Bearer ${token}`,
  }

  return next(action)
}

export default authMiddleware
