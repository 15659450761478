const ACTION_PATH = 'app/tiposGestao'

export const CALL_CRIAR_TIPO_GESTAO = `${ACTION_PATH}/CALL_CRIAR_TIPO_GESTAO`
export const CALL_EXCLUIR_TIPO_GESTAO = `${ACTION_PATH}/CALL_EXCLUIR_TIPO_GESTAO`
export const CALL_ATUALIZAR_TIPO_GESTAO = `${ACTION_PATH}/CALL_ATUALIZAR_TIPO_GESTAO`

export const callCriarTipoGestao = (payload) => ({
  type: CALL_CRIAR_TIPO_GESTAO,
  payload,
})

export const callAtualizarTipoGestao = (payload) => ({
  type: CALL_ATUALIZAR_TIPO_GESTAO,
  payload,
})

export const callExcluirTipoGestao = ({ id }) => ({
  type: CALL_EXCLUIR_TIPO_GESTAO,
  payload: { id },
})
