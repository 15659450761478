import styled from 'styled-components'
import { ListItem } from '@material-ui/core'

export const ListItemStyled = styled(ListItem)`
  ${({ warning }) => warning && `
    color: #EE7D40;
  `}

  ${({ completed }) => completed && `
    color: #219754;
  `}
`

export const TabListItem = styled.ul`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #C9CEDB;
  padding: 0 4px;
  margin: 0;
  list-style: none;
  width: 100%;
  flex-wrap: wrap;
`

export const TabItem = styled.li`
  font-family: 'Poppins';
  font-size: 14px;
  color: #78839D;
  text-transform: uppercase;
  height: 58px;
  margin: 0px 24px;
  padding: 6px 0px 10px;
  min-width: 90px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  ${({ warning }) => warning && `
    color: #EE7D40;
  `}

  ${({ completed }) => completed && `
    color: #219754;
  `}

  ${({ selected }) => selected && `
    color: #31ADE3;

    &::after {
      content: "";
      background-color: #31ADE3;
      display: block;
      height: 4px;
      border-radius: 20px 20px 0px 0px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
    }
  `}
`
