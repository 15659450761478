import { filter, map } from 'rxjs/operators'
import { get, findKey } from 'lodash'
import { matchPath } from 'react-router'
import { ofType } from 'redux-observable'
import { LOCATION_CHANGE } from 'connected-react-router'

/**
 * redux-observable rxjs operator
 * Filter only LOCATION_CHANGE action types and checks if location.path matches given path.
 *
 * @param {string|string[]} path - Targeting location path
 * @return {Observable} An Observable with parsed payload if desired.
 */
export const ofLocationChangeTo = (path) => (source) => source.pipe(
  ofType(LOCATION_CHANGE),
  filter(({ payload }) => (
    get(matchPath(get(payload, 'location.pathname'), { path }), 'isExact', false)
  )),
  map((action) => ({
    ...action.payload,
    payload: get(matchPath(get(action, 'payload.location.pathname'), { path }), 'params', action.payload),
  })),
)

/**
 * Checks if LOCATION_CHANGE action payload location.path matches given path.
 *
 * @param {object} payload - LOCATION_CHANGE action payload
 * @param {string|string[]} path - Targeting location path
 * @param {boolean} [isExact=false] - Should the location match exactly the entire path
 * @return {boolean} If the payload location matches given path
 */
export const matchLocation = (payload, path, isExact = false) => {
  const match = matchPath(get(payload, 'location.pathname'), { path })
  return !isExact ? !!match : get(match, 'isExact', false)
}

/**
 * Iterates paths and return first key that matches with location.path.
 *
 * @param {object} location - Location object provided by Router
 * @param {object} paths - Object with keys and paths
 * @param {boolean} [isExact=true] - Should the location match exactly the entire path
 * @return {string} First key that matches location.path
 */
export const switchPath = (location, paths, isExact = true) => (
  findKey(paths, (path) => {
    const match = matchPath(get(location, 'pathname'), { path })
    return !isExact ? !!match : get(match, 'isExact', false)
  })
)
