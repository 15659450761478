import {
  Backdrop,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput as MuiOutlinedInput,
  Select,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core'
import clsx from 'clsx'
import Button from 'components/Button'
import Card from 'components/Card'
import ColumnsBox from 'components/ColumnsBox'
import FilterList from 'components/FilterList'
import MainColumn from 'components/MainColumn'
import PageTitle from 'components/PageTitle'
import Tooltip from 'components/Tooltip'
import { get, map, range, size } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import AddLineIcon from 'remixicon-react/AddLineIcon'
import ArrowLeftLineIcon from 'remixicon-react/ArrowLeftSLineIcon'
import BookMarkLineIcon from 'remixicon-react/BookmarkLineIcon'
import BuildingLineIcon from 'remixicon-react/Building4LineIcon'
import FileCopyLineIcon from 'remixicon-react/FileCopy2LineIcon'
import FileTextLineIcon from 'remixicon-react/FileTextLineIcon'
import LeafLineIcon from 'remixicon-react/LeafLineIcon'
import { callCriarAcao, callNovaAcao, callSelecionarAcao } from 'store/modules/acoes'
import { getAcaoSelecionada } from 'store/modules/acoes/acoes.selectors'
import { getEmpresaUsuario, getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { callListarDadosDesempenhoBiodiversidade } from 'store/modules/desempenhoBiodiversidade/desempenhoBiodiversidade.actions'
import { getAnoCarregado, getIndices as getIndicesDesempenho, getIsLoading } from 'store/modules/desempenhoBiodiversidade/desempenhoBiodiversidade.selectors'
import { callCriarProjeto, callNovoProjeto, callSelecionarProjeto } from 'store/modules/projetos'
import { getIsCarregandoProjetos, getProjetoSelecionado, getProjetos, getQuantidadeAcoesEmpresa } from 'store/modules/projetos/projetos.selectors'
import { DOCUMENTS_URL, TIPO_USUARIO } from 'utils/constantes'
import { formatDecimal } from 'utils/numbers'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import { diff } from 'deep-object-diff'
import { isFormChanged } from 'utils/forms'
import Acoes from './Acoes'
import Projetos from './Projetos'

const useStyles = makeStyles((theme) => ({
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  contentTitle: {
    fontSize: 24,
    color: theme.palette.primary.main,
  },
  projectTitle: {
    fontSize: 24,
    maxHeight: 0,
    marginBottom: 0,
    overflow: 'hidden',
    color: theme.palette.primary.main,
    transition: theme.transitions.create(['max-height', 'margin-bottom']),
  },
  projectTitleOpen: {
    maxHeight: theme.spacing(10),
    marginBottom: theme.spacing(2),
  },
  tabList: {
    marginBottom: theme.spacing(4),
  },
  actionsHeader: {
    minHeight: 56,
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
  actionsTitle: {
    fontSize: 24,
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  formControl: {
    height: 60,
    width: 260,
    marginLeft: theme.spacing(1),
    '& .MuiFormLabel-root': {
      color: theme.palette.white.main,
      fontSize: theme.typography.pxToRem(14),
    },
    '& .MuiInputBase-root:hover': {
      color: theme.palette.white.main,
    },
  },
  select: {
    color: theme.palette.white.main,
  },
  iconSelect: {
    color: theme.palette.white.main,
  },
  cardText: {
    fontSize: theme.typography.pxToRem(10),
    marginTop: theme.spacing(3),
    color: theme.palette.primary.main,
    fontFamily: theme.typography.secondary,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const OutlinedInput = withStyles((theme) => ({
  root: {
    '& $notchedOutline': {
      borderColor: theme.palette.white.main,
    },
    '&:hover $notchedOutline': {
      borderColor: theme.palette.white.main,
    },
    '&$focused $notchedOutline': {
      borderColor: theme.palette.white.main,
    },
  },
  focused: {},
  notchedOutline: {},
}))(MuiOutlinedInput)

const TELA_PROJETOS = 'TELA_PROJETOS'
const TELA_ACOES = 'TELA_ACOES'

const anoAtual = +moment().format('YYYY')
const anos = map(range(2014, anoAtual + 1), (ano) => ({
  value: ano.toString(), label: `${(ano - 1).toString()}/${ano.toString().slice(2)}`,
}))

const DesempenhoBiodiversidade = () => {
  const location = useLocation()
  const params = location?.state?.data
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const isLoading = useSelector(getIsLoading)
  const quantidadeAcoesEmpresa = useSelector(getQuantidadeAcoesEmpresa)
  const projetos = useSelector(getProjetos)
  const empresa = useSelector(getEmpresaUsuario)
  const projetosLoading = useSelector(getIsCarregandoProjetos)
  const projetoSelecionado = useSelector(getProjetoSelecionado)
  const acaoSelecionada = useSelector(getAcaoSelecionada)
  const indicesDesempenho = useSelector(getIndicesDesempenho)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)
  const anoCarregado = useSelector(getAnoCarregado)
  const dialog = useConfirmationDialog()

  const [telaSelecionada, setTelaSelecionada] = useState(TELA_PROJETOS)
  const [projectName, setProjectName] = useState('')
  const [anoSelecionado, setAnoSelecionado] =
      useState(params?.ano || anoCarregado || anoAtual.toString())

  const [allowChange, setAllowChange] = useState(true)

  const showContent = (telaSelecionada === TELA_PROJETOS && projetoSelecionado) ||
    (telaSelecionada === TELA_ACOES && acaoSelecionada)

  useEffect(() => {
    setProjectName(projetoSelecionado?.nome)
  }, [projetoSelecionado])

  const handleCreate = () => {
    if (telaSelecionada === TELA_PROJETOS) {
      dispatch(callCriarProjeto({
        empresaId: empresa.id,
        ano: anoSelecionado,
      }))
    }
    if (telaSelecionada === TELA_ACOES) {
      dispatch(callCriarAcao({
        projetoId: projetoSelecionado.id,
      }))
    }
  }

  useEffect(() => {
    if (anoCarregado !== anoSelecionado) {
      dispatch(callListarDadosDesempenhoBiodiversidade({
        empresaId: empresa.id, ano: anoSelecionado,
      }))
      dispatch(callNovoProjeto())
    }
  }, [anoCarregado, anoSelecionado, dispatch, empresa.id])

  const handleCanChangeLocation = (event, cb) => {
    if (!allowChange) {
      return dialog.showConfirmation({
        title: t('dialogs.salvarDadosTitulo'),
        message: t('dialogs.salvarDadosDescricao'),
      }).then((result) => {
        if (result) {
          cb(event)
          setAllowChange(true)
        }
      })
    }
    return cb(event)
  }

  const handleChangeAno = (event) => {
    setAnoSelecionado(event.target.value)
    dispatch(callNovoProjeto())
    dispatch(callNovaAcao())
    setProjectName('')
    setTelaSelecionada(TELA_PROJETOS)
  }

  const handleSelectProject = (id) => {
    setTelaSelecionada(TELA_PROJETOS)
    dispatch(callSelecionarProjeto({ id }))
    setProjectName(projetoSelecionado?.nome)
  }

  const handleSelectAcao = (id) => {
    setTelaSelecionada(TELA_ACOES)
    dispatch(callSelecionarAcao({ id }))
  }

  const onActionClick = (id) => {
    setTelaSelecionada(TELA_ACOES)
    dispatch(callSelecionarProjeto({ id }))
  }

  const handleActionsBack = () => {
    dispatch(callNovaAcao())
    setTelaSelecionada(TELA_PROJETOS)
  }

  return (
    <Grid container>
      <Backdrop className={classes.backdrop} open={isLoading} transitionDuration={500}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container direction="row" justifyContent="space-around" alignItems="center">
        <Grid container className={classes.titleGrid}>
          <Grid item container xs={6} justifyContent="flex-start">
            <PageTitle title={t('desempenhoBiodiversidade.titulo')} />
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="ano-base">
                {t('pressaoBiodiversidade.selecioneAno')}
              </InputLabel>
              <Select
                classes={{
                  select: classes.select,
                  icon: classes.iconSelect,
                }}
                value={anoSelecionado}
                onChange={(event) => handleCanChangeLocation(event, handleChangeAno)}
                label={t('pressaoBiodiversidade.selecioneAno')}
                inputProps={{
                  name: 'ano-base',
                  id: 'ano-base',
                }}
                input={<OutlinedInput label={t('pressaoBiodiversidade.selecioneAno')} />}
              >
                {map(anos, (option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <MainColumn columns="1fr 1fr repeat(3, 120px)">
          <Card
            hasGradient
            icon={LeafLineIcon}
            title={t('desempenhoBiodiversidade.dpbOrganizacao')}
            status="green"
            value={formatDecimal({
              min: 0,
              max: 2,
              locale: i18n.language,
              value: get(indicesDesempenho, 'dpbOrganizacao'),
            })}
          >
            <Typography className={classes.cardText}>{t('desempenhoBiodiversidade.infoDPBOrganizacao')}</Typography>
          </Card>

          <Card
            hasGradient
            icon={BuildingLineIcon}
            title={t('desempenhoBiodiversidade.dmbOrganizacao')}
            status="blue"
            value={formatDecimal({
              min: 0,
              max: 2,
              locale: i18n.language,
              value: get(indicesDesempenho, 'acbOrganizacao'),
            })}
          >
            <Typography className={classes.cardText}>{t('pressaoBiodiversidade.informEcoRegiao')}</Typography>
          </Card>

          <Card
            hasGradient
            status="purple"
            value={size(projetos)}
            icon={BookMarkLineIcon}
            title={t('desempenhoBiodiversidade.projetosCadastrados')}
          />
          <Card
            hasGradient
            status="purple"
            value={quantidadeAcoesEmpresa}
            icon={FileTextLineIcon}
            title={t('desempenhoBiodiversidade.acoesCadastradas')}
          />
          {/* <Card
            status="red"
            value={size(projetos)}
            icon={ErrorWarningLineIcon}
            title={t('desempenhoBiodiversidade.pendencias')}
          /> */}
          <Card
            status="purple"
            href={DOCUMENTS_URL}
            icon={FileCopyLineIcon}
            title={t('desempenhoBiodiversidade.documentosLife')}
          />
        </MainColumn>
      </Grid>
      <ColumnsBox
        header={(
          <>
            {telaSelecionada === TELA_ACOES && (
              <div className={classes.actionsHeader}>
                <Button
                  icon={ArrowLeftLineIcon}
                  onClick={(event) => handleCanChangeLocation(event, handleActionsBack)}
                />
                <Typography className={classes.actionsTitle}>
                  {t('desempenhoBiodiversidade.projeto')} - {projetoSelecionado?.nome ?? ''}
                </Typography>
              </div>
            )}
          </>
        )}
        leftColumn={(
          <>
            <div className={classes.contentHeader}>
              <Typography className={classes.contentTitle}>
                {telaSelecionada === TELA_ACOES ?
                  t('desempenhoBiodiversidade.acoesCadastradas') :
                  t('desempenhoBiodiversidade.projetosCadastrados')}
              </Typography>
              <Tooltip
                title={telaSelecionada === TELA_ACOES ?
                  t('desempenhoBiodiversidade.adicionarAcaoTooltip') :
                  t('desempenhoBiodiversidade.adicionarProjetoTooltip')}
              >
                <div>
                  {tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LEITOR && (
                    <Button
                      icon={AddLineIcon}
                      onClick={() => handleCanChangeLocation(null, handleCreate)}
                    />
                  )}
                </div>
              </Tooltip>
            </div>
            {telaSelecionada === TELA_PROJETOS && (
              <FilterList
                onActionClick={(event) => handleCanChangeLocation(event, onActionClick)}
                onSelect={(event) => handleCanChangeLocation(event, handleSelectProject)}
                selected={projetoSelecionado?.id}
                emptyItemLabel={t('inputs.nomeProjeto')}
                items={!projetosLoading ? projetos : null}
                emptyMessage={t('desempenhoBiodiversidade.semProjetos')}
                loadingMessage={t('desempenhoBiodiversidade.carregandoProjetos')}
                toolTip={t('desempenhoBiodiversidade.verAcoesProjeto')}
              />
            )}
            {telaSelecionada === TELA_ACOES && (
              <FilterList
                onSelect={(event) => handleCanChangeLocation(event, handleSelectAcao)}
                selected={acaoSelecionada?.id}
                emptyItemLabel={t('inputs.nomeAcao')}
                emptyMessage={t('desempenhoBiodiversidade.semAcoes')}
                loadingMessage={t('desempenhoBiodiversidade.carregandoAcoes')}
                items={(projetoSelecionado ?
                  (projetoSelecionado.acoes || [])
                    .map((item) => ({ nome: item.descricao, ...item })) :
                  null
                )}
              />
            )}
          </>
        )}
        rightColumn={(
          <>
            {showContent && (
              <>
                <div
                  className={clsx(
                    classes.projectTitle,
                    telaSelecionada === TELA_PROJETOS && classes.projectTitleOpen,
                  )}
                >
                  {projectName || t('inputs.nomeProjeto')}
                </div>
                {telaSelecionada === TELA_PROJETOS && (
                  <Projetos
                    setProjectName={setProjectName}
                    setTelaSelecionada={
                      (event) => handleCanChangeLocation(
                        event,
                        setTelaSelecionada,
                      )
                    }
                    ano={anoSelecionado}
                    projetoId={projetoSelecionado?.id}
                    setAllowChange={setAllowChange}
                  />
                )}
                {telaSelecionada === TELA_ACOES && <Acoes setAllowChange={setAllowChange} />}
              </>
            )}
          </>
        )}
      />
    </Grid>
  )
}

export default DesempenhoBiodiversidade
