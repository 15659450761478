import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { filter, map } from 'lodash'
import { API_LISTAR_DADOS_IPB } from 'store/modules/api/pressaoBiodiversidade.actions'
import {
  API_CRIAR_GAS_EFEITO_ESTUFA,
  API_ATUALIZAR_GAS_EFEITO_ESTUFA,
  API_EXCLUIR_GAS_EFEITO_ESTUFA,
  API_GET_GASES_EFEITO_ESTUFA,
} from 'store/modules/api/gasesEfeitoEstufa.actions'
import { CALL_NOVO_GAS_EFEITO_ESTUFA } from 'store/modules/gasesEfeitoEstufa/gasesEfeitoEstufa.actions'
import { API_EXCLUIR_ANEXO } from 'store/modules/api/anexos.actions'

const lista = (state = [], { type, payload }) => {
  switch (type) {
    case API_LISTAR_DADOS_IPB.success: return payload.emissaoGases
    case API_CRIAR_GAS_EFEITO_ESTUFA.success: return [...state, payload.emissaoGases]
    case API_EXCLUIR_GAS_EFEITO_ESTUFA.success: return filter(state, (u) => u.id !== payload.id)
    case API_ATUALIZAR_GAS_EFEITO_ESTUFA.success:
      return map(state, (u) => (u.id === payload.emissaoGases.id ? payload.emissaoGases : u))
    default: return state
  }
}

const selecionado = (state = null, { type, payload }) => {
  switch (type) {
    case API_GET_GASES_EFEITO_ESTUFA.success: return payload
    case CALL_NOVO_GAS_EFEITO_ESTUFA: return null
    case API_CRIAR_GAS_EFEITO_ESTUFA.success: return null
    case API_ATUALIZAR_GAS_EFEITO_ESTUFA.success: return null
    case API_EXCLUIR_ANEXO.success:
      return state?.anexo?.id === +payload ? { ...state, anexo: null } : state
    default: return state
  }
}

const isSaving = loadingReducer(false, {
  request: [
    API_ATUALIZAR_GAS_EFEITO_ESTUFA.request,
    API_CRIAR_GAS_EFEITO_ESTUFA.request,
  ],
  success: [
    API_ATUALIZAR_GAS_EFEITO_ESTUFA.success,
    API_CRIAR_GAS_EFEITO_ESTUFA.success,
  ],
  failure: [
    API_ATUALIZAR_GAS_EFEITO_ESTUFA.failure,
    API_CRIAR_GAS_EFEITO_ESTUFA.failure,
  ],
})

const isCarregandoGasesEfeitoEstufa = loadingReducer(false, API_LISTAR_DADOS_IPB)

export default combineReducers({
  lista,
  selecionado,
  isCarregandoGasesEfeitoEstufa,
  isSaving,
})
