const ACTION_PATH = 'app/principios'

export const CALL_CRIAR_PRINCIPIO = `${ACTION_PATH}/CALL_CRIAR_PRINCIPIO`
export const CALL_ATUALIZAR_PRINCIPIO = `${ACTION_PATH}/CALL_ATUALIZAR_PRINCIPIO`
export const CALL_SELECIONAR_PRINCIPIO = `${ACTION_PATH}/CALL_SELECIONAR_PRINCIPIO`
export const CALL_EXCLUIR_PRINCIPIO = `${ACTION_PATH}/CALL_EXCLUIR_PRINCIPIO`
export const CALL_NOVA_PRINCIPIO = `${ACTION_PATH}/CALL_NOVA_PRINCIPIO`

export const callCriarPrincipio = (principio) => ({
  type: CALL_CRIAR_PRINCIPIO,
  payload: principio,
})

export const callAtualizarPrincipio = ({ id, principio }) => ({
  type: CALL_ATUALIZAR_PRINCIPIO,
  payload: { id, principio },
})

export const callExcluirPrincipio = ({ id }) => ({
  type: CALL_EXCLUIR_PRINCIPIO,
  payload: { id },
})

export const callNovoPrincipio = () => ({
  type: CALL_NOVA_PRINCIPIO,
})
