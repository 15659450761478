import qs from 'qs'
import { replace } from 'lodash'
import { configureApiAction, configureApiActionTypes } from 'redux-foundry'

export const apiActionTypes = configureApiActionTypes({
  prefix: 'api',
  delim: '/',
})

export const apiActionTypesFromType = (type) => apiActionTypes(replace(replace(type, /api\//, ''), /\/Success|\/Failure|\/Request/, ''))

const apiAction = configureApiAction({
  enableMeta: true,
  metaQuery: (obj) => qs.stringify(obj),
})

export default apiAction
