import React, { useState } from 'react'
import * as yup from 'yup'
import { mapValues, isEmpty } from 'lodash'
import { Form, Field, FormSpy } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { validateFormValues } from 'utils/forms'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, makeStyles, InputAdornment, IconButton } from '@material-ui/core'
import { getDadosUsuario } from 'store/modules/auth/auth.selectors'
import { callAtualizarUsuario, callCriarUsuario } from 'store/modules/usuarios'
import DeleteBinLineIcon from 'remixicon-react/DeleteBin7LineIcon'
import MainColumn from 'components/MainColumn'
import Avatar from 'components/Avatar'
import Button from 'components/Button'
import FieldText from 'components/Fields/Text'
import EyeLineIcon from 'remixicon-react/EyeLineIcon'
import EyeOffLineIcon from 'remixicon-react/EyeOffLineIcon'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    color: theme.palette.primary.main,
  },
  avatarSection: {
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  infoSection: {
    marginTop: theme.spacing(8),
  },
  footerSection: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(8),
  },
}))

const MeusDados = ({ setChangeTab }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const selectedUser = useSelector(getDadosUsuario)

  const [showPassword, setShowPassword] = useState(false)
  const [avatarPreview, setAvatarPreview] = useState(null)

  const handleSave = (values) => {
    const data = {
      ...mapValues(values, (v) => (v === '' ? null : v)),
      empresa: undefined,
      tipoUsuario: undefined,
      confirmarSenha: undefined,
    }

    if (!selectedUser) return dispatch(callCriarUsuario(data))
    return dispatch(callAtualizarUsuario({
      id: selectedUser.id,
      usuario: data,
      successNotification: 'mensagens.atualizado',
    }))
  }

  const handleDeleteImage = (form) => () => {
    form.change('avatar', null)
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <Form
      onSubmit={handleSave}
      validate={validateFormValues(yup.object({
        nome: yup.string().required(),
        email: yup.string().email().required(),
        funcao: yup.string().required(),
        telefone: yup.string().required(),
        senha: yup.string(),
        confirmarSenha: yup.string().when('senha', (senha, campo) => (senha ? campo.required().oneOf([yup.ref('senha')], t('alterarSenha.senhaIncompativel')) : campo)),
      }))}
      initialValues={{
        nome: selectedUser?.nome ?? '',
        email: selectedUser?.email ?? '',
        tipoUsuario: selectedUser?.tipoUsuario ?? null,
        funcao: selectedUser?.funcao ?? '',
        telefone: selectedUser?.telefone ?? '',
        avatar: selectedUser?.avatar ?? '',
      }}
      render={({ handleSubmit, values, form, pristine }) => (
        <form onSubmit={handleSubmit}>
          <FormSpy
            onChange={({ values: valuesSpy, initialValues }) => {
              setChangeTab({
                values: valuesSpy,
                initialValues,
              })
            }}
            subscription={{ values: true, initialValues: true, pristine: true }}
          />
          <Typography variant="h2" className={classes.title}>
            {t('usuarioDetalhe.fotoDePerfil')}
          </Typography>
          <MainColumn className={classes.avatarSection} columns="repeat(3, min-content)">
            <Avatar size="large" image={avatarPreview || values?.avatar} initials={values?.nome} />
            <Button component="label" id="image-upload" variant="outlined">
              {t('meuPerfil.alterar')}
              <Field type="file" name="avatar">
                {({ input: { value, onChange, ...input } }) => (
                  <input
                    {...input}
                    hidden
                    type="file"
                    accept="image/*"
                    id="image-upload"
                    onChange={(e) => {
                      if (!isEmpty(e.target.files)) {
                        const fileReader = new FileReader()
                        fileReader.onload = () => {
                          if (fileReader.readyState === 2) {
                            form.change('avatar', fileReader.result)
                            form.change('novoAvatar', fileReader.result)
                            form.change('novoAvatarNome', e.target.files[0].name)
                            setAvatarPreview(fileReader.result)
                          }
                        }
                        fileReader.readAsDataURL(e.target.files[0])
                      }
                    }}
                  />
                )}
              </Field>
            </Button>
            <Button variant="outlined" icon={DeleteBinLineIcon} id="image-delete" onClick={handleDeleteImage(form)} />
          </MainColumn>
          <MainColumn className={classes.infoSection}>
            <Typography variant="h2" className={classes.title}>
              {t('usuarioDetalhe.dadosGerais')}
            </Typography>
            <FieldText
              required
              name="nome"
              label={t('inputs.nomeCompleto')}
              placeholder={t('inputs.insiraNome')}
            />
            <FieldText
              required
              name="email"
              label={t('inputs.email')}
              placeholder={t('inputs.insiraEmail')}
            />
            <MainColumn columns="repeat(2, 1fr)">
              <FieldText
                required
                name="funcao"
                label={t('inputs.funcao')}
                placeholder={t('inputs.insiraFuncao')}
              />
              <FieldText
                required
                type="tel"
                name="telefone"
                label={t('inputs.telefone')}
                placeholder={t('inputs.insiraTelefone')}
              />
            </MainColumn>
          </MainColumn>
          <MainColumn className={classes.infoSection}>
            <Typography variant="h2" className={classes.title}>
              {t('usuarioDetalhe.senha')}
            </Typography>
            <MainColumn columns="repeat(2, 1fr)">
              <FieldText
                name="senha"
                type={showPassword ? 'text' : 'password'}
                label={t('inputs.novaSenha')}
                placeholder={t('inputs.insiraSenha')}
                autoComplete="new-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={(event) => event.preventDefault()}
                      >
                        {showPassword ? <EyeLineIcon /> : <EyeOffLineIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FieldText
                type={showPassword ? 'text' : 'password'}
                name="confirmarSenha"
                label={t('inputs.confirmarNovaSenha')}
                placeholder={t('inputs.confirmeSuaSenha')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={(event) => event.preventDefault()}
                      >
                        {showPassword ? <EyeLineIcon /> : <EyeOffLineIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </MainColumn>
          </MainColumn>
          <div className={classes.footerSection}>
            <Button disabled={pristine} type="submit">
              {t('inputs.salvar')}
            </Button>
          </div>
        </form>
      )}
    />
  )
}

MeusDados.propTypes = {
  setChangeTab: PropTypes.func,
}

export default MeusDados
