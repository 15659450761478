import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const getAcaoClassificacaoActionTypes = apiActionTypes('acaoClassificacao/API_GET_ACAO_CLASSIFICACAO')
const createAcaoClassificacaoActionTypes = apiActionTypes('acaoClassificacao/API_CRIAR_ACAO_CLASSIFICACAO')
const updateAcaoClassificacaoActionTypes = apiActionTypes('acaoClassificacao/API_ATUALIZAR_ACAO_CLASSIFICACAO')
const deleteAcaoClassificacaoActionTypes = apiActionTypes('acaoClassificacao/API_EXCLUIR_ACAO_CLASSIFICACAO')

export const API_GET_ACAO_CLASSIFICACAO = asApiTypesObject(getAcaoClassificacaoActionTypes)
export const API_CRIAR_ACAO_CLASSIFICACAO = asApiTypesObject(createAcaoClassificacaoActionTypes)
export const API_ATUALIZAR_ACAO_CLASSIFICACAO = asApiTypesObject(updateAcaoClassificacaoActionTypes)
export const API_EXCLUIR_ACAO_CLASSIFICACAO = asApiTypesObject(deleteAcaoClassificacaoActionTypes)

export const apiGetAcaoClassificacao = (id, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/acaoClassificacao/${id}`,
  method: 'GET',
  types: getAcaoClassificacaoActionTypes,
}, meta)

export const apiCriarAcaoClassificacao = (acaoClassificacao, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/acaoClassificacao`,
  method: 'POST',
  types: createAcaoClassificacaoActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(acaoClassificacao),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiAtualizarAcaoClassificacao = ({ id, acaoClassificacao }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/acaoClassificacao/${id}`,
  method: 'PUT',
  types: updateAcaoClassificacaoActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(acaoClassificacao),
}, { ...meta, successNotification: 'mensagens.atualizado' })

export const apiExcluirAcaoClassificacao = (id, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/acaoClassificacao/${id}`,
  method: 'DELETE',
  types: deleteAcaoClassificacaoActionTypes,
  headers: { 'Content-Type': 'application/json' },
}, { ...meta, successNotification: 'mensagens.excluido' })
