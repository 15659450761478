import { mapValues } from '../utils'

/**
 * action types for "collectionReducer"
 * @alias module:collection
 * @constant
 * @type {{}}
 * @prop {string} add
 * @prop {string} push
 * @prop {string} pop
 * @prop {string} remove
 * @prop {string} filter
 * @prop {string} reject
 * @prop {string} reset
 * @prop {string} unshift
 * @prop {string} shift
 * @prop {string} reduce
 * @prop {string} batch
 */
export const COLLECTION_TYPES = Object.freeze({
  add: 'add',
  push: 'push',
  pop: 'pop',
  remove: 'remove',
  filter: 'filter',
  reject: 'reject',
  reset: 'reset',
  unshift: 'unshift',
  shift: 'shift',
  reduce: 'reduce',
  batch: 'batch'
})

/**
 * creates a map of "collection" action types.
 * produces an object in the form expected by the `actionTypes` argument
 * of the `collectionReducer` method.  can be given a `getType` argument
 * to customize the action type values, per action.
 *
 * @alias module:collection
 * @param {function} [getType] assigns property values to resulting object
 * @return {{}} map of "collection" action types to string|symbol values
 */
const collectionActionTypes = (getType) => mapValues(COLLECTION_TYPES, getType)

export default collectionActionTypes
