import { combineReducers } from 'redux'
import { API_AUTHENTICATE } from 'store/modules/api/auth.actions'
import { API_ATUALIZAR_EMPRESA, API_GET_EMPRESA_USUARIO } from 'store/modules/api/empresas.actions'
import { API_ATUALIZAR_USUARIO } from 'store/modules/api/usuarios.actions'
import { USER_LOGOUT } from 'store/modules/auth/auth.actions'

const token = (state = null, { type, payload }) => {
  switch (type) {
    case API_AUTHENTICATE.success: return payload.token
    case USER_LOGOUT: return {}
    default: return state
  }
}

const usuario = (state = null, { type, payload }) => {
  switch (type) {
    case API_AUTHENTICATE.success: return payload.dadosUsuario
    case API_ATUALIZAR_USUARIO.success: return state.id === payload.id ? payload : state
    case API_GET_EMPRESA_USUARIO.success: return { ...state, empresa: payload }
    case API_ATUALIZAR_EMPRESA.success: return {
      ...state,
      empresa: (state.empresa?.id === payload?.id ? payload : state.empresa),
    }
    case USER_LOGOUT: return {}
    default: return state
  }
}

export default combineReducers({
  token,
  usuario,
})
