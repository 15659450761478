import { filter, map } from 'lodash'
import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import {
  API_LISTAR_CLASSES_INDICADORES_ADMIN,
  API_SALVAR_INDICADORES_ADMIN,
  API_EXCLUIR_INDICADORES_ADMIN,
} from 'store/modules/api/indicadoresAdmin.actions'
import {
  API_SALVAR_IRC_ADMIN,
} from 'store/modules/api/classesIrcAdmin.actions'

const lista = (state = [], { type, payload }) => {
  switch (type) {
    case API_LISTAR_CLASSES_INDICADORES_ADMIN.success:
      return payload.sort((a, b) => a.nome.localeCompare(b.nome))
    case API_SALVAR_INDICADORES_ADMIN.success:
      return map(state, (u) => (u.id === payload?.id ? payload : u))
        .sort((a, b) => a.nome.localeCompare(b.nome))
    case API_EXCLUIR_INDICADORES_ADMIN.success:
      return map(state, (classe) => ({
        ...classe,
        indicadoresIrc: filter(classe.indicadoresIrc, (o) => o.id !== Number(payload)),
      }))
    case API_SALVAR_IRC_ADMIN.success:
      return [...state, payload].sort((a, b) => a.nome.localeCompare(b.nome))
    default: return state
  }
}

const isCarregandoLista = loadingReducer(false, API_LISTAR_CLASSES_INDICADORES_ADMIN)

const isSaving = loadingReducer(false, API_SALVAR_INDICADORES_ADMIN)

export default combineReducers({
  lista,
  isCarregandoLista,
  isSaving,
})
