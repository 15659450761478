import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { API_LISTAR_PAISES, API_GET_PAISES, API_CRIAR_PAISES, API_ATUALIZAR_PAISES, API_EXCLUIR_PAIS } from 'store/modules/api/paises.actions'
import { CALL_NOVO_PAIS } from './paises.actions'
import { CALL_SELECIONAR_PAIS } from '../paisesCalculos/paisesCalculos.actions'

const lista = (state = [], { type, payload, meta }) => {
  switch (type) {
    case API_LISTAR_PAISES.success:
      return payload
    case API_CRIAR_PAISES.success:
      return [...state, payload]
    case API_EXCLUIR_PAIS.success:
      return state.filter((item) => item.id !== meta.id)
    case API_ATUALIZAR_PAISES.success:
      return state.map((pais) => (pais.id === payload.id ? payload : pais))
    default:
      return state
  }
}

const selecionado = (state = null, { type, payload }) => {
  switch (type) {
    case API_GET_PAISES.success: return payload
    case API_CRIAR_PAISES.success: return payload
    case API_ATUALIZAR_PAISES.success: return payload
    case API_EXCLUIR_PAIS.success: return null
    case CALL_NOVO_PAIS: return null
    case CALL_SELECIONAR_PAIS: return payload
    default: return state
  }
}

const isCarregandoLista = loadingReducer(false, API_LISTAR_PAISES)
const isUpdate = loadingReducer(false, API_ATUALIZAR_PAISES)

export default combineReducers({
  lista,
  selecionado,
  isCarregandoLista,
  isUpdate,
})
