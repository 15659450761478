import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import { apiCriarClassesIrcAdmin,
  apiExcluirClassesIrcAdmin,
  apiAtualizarClassesIrcAdmin } from 'store/modules/api/classesIrcAdmin.actions'

import { CALL_SALVAR_IRC_ADMIN,
  CALL_EXCLUIR_CLASSE_IRC_ADMIN,
  CALL_ATUALIZAR_CLASSE_IRC_ADMIN } from 'store/modules/classesIrcAdmin/classesIrcAdmin.actions'

const onCallSalvarIrcAdmin = (action$) => action$.pipe(
  ofType(CALL_SALVAR_IRC_ADMIN),
  debounceTime(250),
  map(({ payload }) => apiCriarClassesIrcAdmin(payload)),
)

const onCallExcluirClasseIrcAdmin = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_CLASSE_IRC_ADMIN),
  debounceTime(250),
  map(({ payload }) => apiExcluirClassesIrcAdmin(payload)),
)

const onCallAtualizarClasseIrcAdmin = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_CLASSE_IRC_ADMIN),
  debounceTime(250),
  map(({ payload }) => apiAtualizarClassesIrcAdmin(payload)),
)

export default combineEpics(
  onCallSalvarIrcAdmin,
  onCallExcluirClasseIrcAdmin,
  onCallAtualizarClasseIrcAdmin,
)
