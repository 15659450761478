import { combineReducers } from 'redux'
import { loadingReducer } from 'redux-foundry'
import { API_ATUALIZAR_ACAO, API_CRIAR_ACAO, API_EXCLUIR_ACAO } from 'store/modules/api/acoes.actions'
import { API_LISTAR_DADOS_DESEMPENHO_BIODIVERSIDADE } from 'store/modules/api/desempenhoBiodiversidade.actions'
import { API_EXCLUIR_PROJETO } from 'store/modules/api/projetos.actions'

const indices = (state = null, { type, payload }) => {
  switch (type) {
    case API_LISTAR_DADOS_DESEMPENHO_BIODIVERSIDADE.success: return { ...payload }
    case API_CRIAR_ACAO.success:
    case API_ATUALIZAR_ACAO.success: return {
      ...state, dpbOrganizacao: payload.dpbOrganizacao,
    }
    case API_EXCLUIR_ACAO.success: return {
      ...state, dpbOrganizacao: payload.dpbOrganizacao,
    }
    case API_EXCLUIR_PROJETO.success: return {
      ...state, dpbOrganizacao: payload.dpbOrganizacao,
    }
    default: return state
  }
}

const anoCarregado = (state = null, { type, meta }) => (
  (type === API_LISTAR_DADOS_DESEMPENHO_BIODIVERSIDADE.success) ? meta.query.ano : state
)

const isLoading = loadingReducer(false, API_LISTAR_DADOS_DESEMPENHO_BIODIVERSIDADE)

export default combineReducers({
  indices,
  isLoading,
  anoCarregado,
})
