import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import {
  apiCriarTipoAgua,
  apiExcluirTipoAgua,
  apiAtualizarTipoAgua,
} from 'store/modules/api/tipoAgua.actions'
import {
  CALL_CRIAR_TIPO_AGUA,
  CALL_EXCLUIR_TIPO_AGUA,
  CALL_ATUALIZAR_TIPO_AGUA,
} from 'store/modules/tipoAgua/tipoAgua.actions'

const onCallCriarTipoAgua = (action$) => action$.pipe(
  ofType(CALL_CRIAR_TIPO_AGUA),
  debounceTime(250),
  map(({ payload }) => apiCriarTipoAgua(payload)),
)

const onCallExcluirTipoAgua = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_TIPO_AGUA),
  debounceTime(250),
  map(({ payload }) => apiExcluirTipoAgua(payload)),
)

const onCallAtualizarTipoAgua = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_TIPO_AGUA),
  debounceTime(250),
  map(({ payload }) => apiAtualizarTipoAgua(payload)),
)

export default combineEpics(
  onCallCriarTipoAgua,
  onCallExcluirTipoAgua,
  onCallAtualizarTipoAgua,
)
