import {
  TextField as MuiTextField,
  withStyles,
} from '@material-ui/core'

const StyledMuiTextField = withStyles((theme) => ({
  root: {
    minHeight: '60px',
    '& .MuiInputBase-root': {
      color: theme.palette.primary.main,
      fontWeight: 400,
      fontSize: 14,
      '& input': {
        height: '11px',
        textAlign: 'left',
      },
    },
    '& .MuiFormLabel-root': {
      color: theme.palette.primary.main,
      fontWeight: 400,
      fontSize: 14,
      '&.Mui-error': {
        color: '#f44336',
        fontWeight: 400,
        fontSize: 14,
      },
    },
    '& .MuiInputLabel-formControl': {
      top: '-2px',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    },
    '& .Mui-disabled': {
      color: '#A0A8BD',
      backgroundColor: '#F4F5F8',
    },
    '& .MuiIconButton-label > svg': {
      fill: '#e0e0e0',
      '&:hover': {
        fill: '#164C64',
      },
    },
    '& .MuiFormHelperText-root': {
      fontWeight: 400,
      fontSize: 11,
    },
  },
}))(MuiTextField)

export default StyledMuiTextField
