import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import { apiCriarEnergia, apiAtualizarEnergia, apiExcluirEnergia, apiGetEnergia } from 'store/modules/api/energia.actions'
import { CALL_ATUALIZAR_ENERGIA, CALL_CRIAR_ENERGIA, CALL_EXCLUIR_ENERGIA, CALL_SELECIONAR_ENERGIA } from 'store/modules/energia/energia.actions'

const onSelected = (action$) => action$.pipe(
  ofType(CALL_SELECIONAR_ENERGIA),
  debounceTime(250),
  map(({ payload }) => apiGetEnergia(payload.id)),
)

const onCallCriarEnergia = (action$) => action$.pipe(
  ofType(CALL_CRIAR_ENERGIA),
  debounceTime(250),
  map(({ payload }) => apiCriarEnergia(payload)),
)

const onCallAtualizarEnergia = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_ENERGIA),
  debounceTime(250),
  map(({ payload }) => apiAtualizarEnergia(payload)),
)

const onCallExcluirEnergia = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_ENERGIA),
  debounceTime(250),
  map(({ payload }) => apiExcluirEnergia(payload)),
)

export default combineEpics(
  onCallCriarEnergia,
  onCallAtualizarEnergia,
  onSelected,
  onCallExcluirEnergia,
)
