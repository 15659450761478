import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import InputField from './BaseInput'

const FieldCurrency = ({ name, onChange, ...props }) => {
  const { i18n } = useTranslation()
  const currentLanguage = i18n?.language === 'en-US' ? 'en-US' : 'pt-BR'
  const handleFormat = (value) => Intl.NumberFormat(
    currentLanguage,
    { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 },
  ).format(value)

  const handleParse = (value) => (
    value ? Number(value.replace(/[,.]/g, '')) / 100 : null
  )

  const handleChange = (input) => (event) => {
    input.onChange(event)
    if (onChange) onChange()
  }

  return (
    <Field
      name={name}
      parse={handleParse}
      format={handleFormat}
      render={({ input, meta }) => (
        <InputField
          {...input}
          {...props}
          onChange={handleChange(input)}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && meta.error}
        />
      )}
    />
  )
}

FieldCurrency.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
}

export default FieldCurrency
