const ACTION_PATH = 'app/ocupacaoAreas'

export const CALL_CRIAR_OCUPACAO_AREA = `${ACTION_PATH}/CALL_CRIAR_OCUPACAO_AREA`
export const CALL_ATUALIZAR_OCUPACAO_AREA = `${ACTION_PATH}/CALL_ATUALIZAR_OCUPACAO_AREA`
export const CALL_EXCLUIR_OCUPACAO_AREA = `${ACTION_PATH}/CALL_EXCLUIR_OCUPACAO_AREA`
export const CALL_SELECIONAR_OCUPACAO_AREA = `${ACTION_PATH}/CALL_SELECIONAR_OCUPACAO_AREA`
export const CALL_NOVO_OCUPACAO_AREA = `${ACTION_PATH}/CALL_NOVO_OCUPACAO_AREA`

export const callCriarOcupacaoArea = (ocupacaoArea) => ({
  type: CALL_CRIAR_OCUPACAO_AREA,
  payload: ocupacaoArea,
})

export const callAtualizarOcupacaoArea = ({ id, ocupacaoArea }) => ({
  type: CALL_ATUALIZAR_OCUPACAO_AREA,
  payload: { id, ocupacaoArea },
})

export const callExcluirOcupacaoArea = ({ id }) => ({
  type: CALL_EXCLUIR_OCUPACAO_AREA,
  payload: id,
})

export const callSelecionarOcupacaoArea = ({ id }) => ({
  type: CALL_SELECIONAR_OCUPACAO_AREA,
  payload: { id },
})

export const callNovoOcupacaoArea = () => ({
  type: CALL_NOVO_OCUPACAO_AREA,
})
