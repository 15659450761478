import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    borderRadius: 8,
    boxSizing: 'border-box',
    padding: theme.spacing(4),
    backgroundColor: theme.palette.common.white,
    boxShadow: `0px 8px 32px ${theme.palette.shadows.main}`,
  },
}))

const ContentBox = ({ className, ...props }) => {
  const classes = useStyles()

  return (
    <Box {...props} className={clsx(classes.container, className)} />
  )
}

ContentBox.propTypes = {
  className: PropTypes.string,
}

export default ContentBox
