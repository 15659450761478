import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import DeleteBin7LineIcon from 'remixicon-react/DeleteBin7LineIcon'
import ArrowDropDownLineIcon from 'remixicon-react/ArrowDropDownLineIcon'
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  accordion: {
    width: '100%',
    borderRadius: 0,
    boxShadow: 'none',
    '&:last-child': {
      borderRadius: 0,
    },
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  accordionExpanded: {
    borderRadius: 0,
  },
  summary: {
    borderRadius: 0,
    marginBottom: -1,
    minHeight: 'unset',
    cursor: 'unset !important',
    padding: theme.spacing(2, 0, 1),
    transition: theme.transitions.create('border'),
    borderBottom: (props) => {
      const transparentBorder = (['border-top', 'no-borders'].includes(props.variant))
      return `1px solid ${transparentBorder ? 'transparent' : theme.palette.grey.main}`
    },
    borderTop: (props) => {
      const transparentBorder = (['border-bottom', 'no-borders'].includes(props.variant))
      return `1px solid ${transparentBorder ? 'transparent' : theme.palette.grey.main}`
    },
    '&.Mui-expanded': {
      minHeight: 'unset',
    },
  },
  summaryContent: {
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  summaryIcon: {
    margin: 0,
    padding: 0,
    '&.Mui-expanded': {
      transform: 'unset',
    },
  },
  details: {
    padding: theme.spacing(1, 0),
  },
  heading: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(14),
  },
  custom: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  options: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
  iconArrowClose: {
    cursor: 'pointer',
    transform: 'rotate(0deg)',
    transition: 'transform .5s ease-in-out',
  },
  iconArrowOpen: {
    cursor: 'pointer',
    transform: 'rotate(-180deg)',
    transition: 'transform .5s ease-in-out',
  },
  deleteIcon: {
    cursor: ({ disableDelete }) => (disableDelete ? 'default' : 'pointer'),
    color: ({ disableDelete }) => (disableDelete ? 'rgb(234, 235, 238)' : theme.palette.grey.blue),
  },
}))

const Accordion = ({
  expanded,
  title,
  variant,
  onExpand,
  classes: classNames = {},
  children,
  onDelete,
  disableDelete = false,
}) => {
  const classes = useStyles({ variant, disableDelete })
  const [defaultExpanded, setDefaultExpanded] = useState(false)
  const isExpanded = expanded != null ? expanded : defaultExpanded

  const handleExpand = () => {
    if (onExpand) return onExpand()
    return setDefaultExpanded((state) => !state)
  }

  return (
    <MuiAccordion
      elevation={0}
      expanded={isExpanded}
      className={classes.accordion}
      classes={{ expanded: classes.accordion }}
    >

      <AccordionSummary
        className={classes.summary}
        IconButtonProps={{ disableRipple: true }}
        classes={{ expandIcon: classes.summaryIcon, content: classes.summaryContent }}
        expandIcon={(
          <>
            <ArrowDropDownLineIcon
              size={30}
              onClick={() => handleExpand()}
              className={isExpanded ? classes.iconArrowOpen : classes.iconArrowClose}
            />
            {onDelete && (
              <DeleteBin7LineIcon
                className={classes.deleteIcon}
                onClick={!disableDelete ? onDelete : null}
              />
            )}
          </>
        )}
      >

        <div className={classes.custom}>
          <Typography className={clsx(classNames.title, classes.heading)} component="p">
            {title}
          </Typography>
        </div>

      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {children}
      </AccordionDetails>
    </MuiAccordion>
  )
}

Accordion.propTypes = {
  title: PropTypes.string,
  expanded: PropTypes.bool,
  onExpand: PropTypes.func,
  children: PropTypes.node,
  classes: PropTypes.shape({ title: PropTypes.string }),
  variant: PropTypes.oneOf(['no-borders', 'border-top', 'border-bottom', 'border']),
  onDelete: PropTypes.func,
  disableDelete: PropTypes.bool,
}

export default Accordion
