import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map, mergeMap } from 'rxjs/operators'
import {
  apiListarUsuariosAuditores,
  apiGetUsuarioAuditor,
  apiCriarUsuarioAuditor,
  apiAtualizarUsuarioAuditor,
  apiExcluirUsuarioAuditor,
} from 'store/modules/api/usuariosAuditor.actions'
import { apiListarEmpresas } from 'store/modules/api/empresas.actions'
import {
  CALL_ATUALIZAR_USUARIO_AUDITOR,
  CALL_CRIAR_USUARIO_AUDITOR,
  CALL_EXCLUIR_USUARIO_AUDITOR,
  CALL_SELECIONAR_USUARIO_AUDITOR,
} from 'store/modules/usuariosAuditor/usuariosAuditor.actions'
import { ofLocationChangeTo } from 'utils/location'

const onLocationChangeToLista = (action$) => action$.pipe(
  ofLocationChangeTo('/adm/auditores'),
  debounceTime(250),
  mergeMap(() => [
    apiListarUsuariosAuditores(),
    apiListarEmpresas(),
  ]),
)

const onSelected = (action$) => action$.pipe(
  ofType(CALL_SELECIONAR_USUARIO_AUDITOR),
  debounceTime(250),
  map(({ payload }) => apiGetUsuarioAuditor(payload.id)),
)

const onCallCriarUsuario = (action$) => action$.pipe(
  ofType(CALL_CRIAR_USUARIO_AUDITOR),
  debounceTime(250),
  map(({ payload }) => apiCriarUsuarioAuditor(payload)),
)

const onCallAtualizarUsuario = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_USUARIO_AUDITOR),
  debounceTime(250),
  map(({ payload }) => apiAtualizarUsuarioAuditor(payload)),
)

const onCallExcluirUsuario = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_USUARIO_AUDITOR),
  debounceTime(250),
  map(({ payload }) => apiExcluirUsuarioAuditor(payload)),
)

export default combineEpics(
  onLocationChangeToLista,
  onSelected,
  onCallCriarUsuario,
  onCallAtualizarUsuario,
  onCallExcluirUsuario,
)
