import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchConversoesVolumeActionTypes = apiActionTypes('conversoesVolume/API_LISTAR_CONVERSOES_VOLUME')

export const API_LISTAR_CONVERSOES_VOLUME = asApiTypesObject(fetchConversoesVolumeActionTypes)

export const apiListarConversoesVolume = (data, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/conversaoVolume`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchConversoesVolumeActionTypes,
}, { ...meta })
