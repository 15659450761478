import React, { useState, useEffect } from 'react'
import {
  Grid,
  Typography,
  FormControlLabel,
  Radio,
  makeStyles,
  Box,
  Avatar,
} from '@material-ui/core'
import { Form, FormSpy } from 'react-final-form'
import * as yup from 'yup'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { get, isEmpty } from 'lodash'
import { getEmpresaSelecionada, getIsSavingEmpresa } from 'store/modules/empresas/empresas.selectors'
import { callAtualizarEmpresa, callCriarEmpresa } from 'store/modules/empresas'
import { useTranslation } from 'react-i18next'
import DeleteBinLineIcon from 'remixicon-react/DeleteBin7LineIcon'
import Button from 'components/Button'
import MainColumn from 'components/MainColumn'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { TIPO_USUARIO } from 'utils/constantes'
import { validateFormValues } from 'utils/forms'
import { FieldText } from 'components/Fields'

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '672px',
    margin: theme.spacing(3.5, 'auto', 0),
  },
  formContainer: {
    marginBottom: theme.spacing(8),
  },
  title: {
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: '24px',
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.87)',
  },
  subtitle: {
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: '24px',
    fontWeight: 400,
    color: theme.palette.primary.main,
  },
  label: {
    fontFamily: theme.typography.secondary,
    color: theme.palette.primary.main,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
  },
  separator: {
    width: '100%',
    height: 1,
    background: theme.palette.grey.main,
    margin: theme.spacing(2, 'auto'),
    border: '0',
  },
  labelTextArea: {
    fontFamily: theme.typography.secondary,
    color: theme.palette.primary.main,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(3),
  },
  inputTextArea: {
    marginBottom: theme.spacing(6),
  },
  avatarLarge: {
    width: 96,
    height: 96,
  },
  avatarSection: {
    alignItems: 'center',
    margin: theme.spacing(2, 0, 4),
  },
}))

const EmpresaPerfilDetalhe = ({ setPossuiDivisoes, setChangeTab }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const empresaSelecionada = useSelector(getEmpresaSelecionada)
  const isSaving = useSelector(getIsSavingEmpresa)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const [avatarPreview, setAvatarPreview] = useState(null)

  const handleSave = (values) => {
    if (!empresaSelecionada && callCriarEmpresa) {
      dispatch(callCriarEmpresa(values))
    } else if (callAtualizarEmpresa) {
      dispatch(callAtualizarEmpresa({
        id: empresaSelecionada.id,
        empresa: values,
        successNotification: 'mensagens.atualizado',
      }))
    }
  }

  const handleDeleteImage = (form) => {
    form.change('avatar', undefined)
    form.change('avatar', null)
  }

  useEffect(() => {
    if (empresaSelecionada) {
      setPossuiDivisoes?.(empresaSelecionada.possuiDivisaoSetor)
    }
  }, [empresaSelecionada, setPossuiDivisoes])

  return (
    <Box className={classes.container}>
      <Box className={classes.formContainer}>
        <Form
          onSubmit={handleSave}
          validate={validateFormValues(
            yup.object({
              nome: yup.string().required(),
              endereco: yup.string().required(),
              cep: yup.string().required(),
              cnpj: yup.string().required(),
              nomePais: yup.string().required(),
              site: yup.string(),
              nomeUf: yup.string().required(),
              nomeMunicipio: yup.string().required(),
              informacoesAdicionais: yup.string(),
              possuiDivisaoSetor: yup.boolean().required(),
            }),
          )}
          initialValues={{
            nome: empresaSelecionada?.nome ?? '',
            endereco: empresaSelecionada?.endereco ?? '',
            cep: empresaSelecionada?.cep ?? '',
            cnpj: empresaSelecionada?.cnpj ?? '',
            nomePais: empresaSelecionada?.nomePais ?? '',
            site: empresaSelecionada?.site ?? '',
            nomeUf: empresaSelecionada?.nomeUf ?? '',
            nomeMunicipio: empresaSelecionada?.nomeMunicipio ?? '',
            informacoesAdicionais: empresaSelecionada?.informacoesAdicionais ?? '',
            possuiDivisaoSetor: empresaSelecionada?.possuiDivisaoSetor ?? false,
            avatar: empresaSelecionada?.avatar ?? '',
          }}
          render={({ handleSubmit, form, values, pristine }) => (
            <form onSubmit={handleSubmit}>
              <FormSpy
                onChange={({ values: valuesSpy, initialValues }) => {
                  setChangeTab({
                    values: valuesSpy,
                    initialValues,
                  })
                }}
                subscription={{ values: true, initialValues: true }}
              />
              <Typography component="h3" className={classes.title}>
                {t('usuarioDetalhe.logoDaEmpresa')}
              </Typography>
              <MainColumn className={classes.avatarSection} columns="repeat(3, min-content)">
                <Avatar className={classes.avatarLarge} src={avatarPreview || values?.avatar} />
                {tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LIDER && (
                  <>
                    <Button component="label" variant="outlined">
                      {t('meuPerfil.alterar')}
                      <input
                        name="avatar"
                        accept="image/*"
                        id="contained-button-file"
                        type="file"
                        hidden
                        onChange={(e) => {
                          if (!isEmpty(e.target.files)) {
                            const fileReader = new FileReader()
                            fileReader.onload = () => {
                              if (fileReader.readyState === 2) {
                                form.change('novoAvatar', fileReader.result)
                                form.change('novoAvatarNome', e.target.files[0].name)
                                setAvatarPreview(fileReader.result)
                              }
                            }
                            fileReader.readAsDataURL(e.target.files[0])
                          }
                        }}
                      />
                    </Button>
                    <Button
                      variant="outlined"
                      icon={DeleteBinLineIcon}
                      onClick={() => handleDeleteImage(form)}
                    />
                  </>
                )}
              </MainColumn>
              <Grid item xs={12}>
                <Typography component="h3" className={classes.subtitle}>
                  {t('usuarioDetalhe.dadosGeraisDaEmpresa')}
                </Typography>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FieldText
                    required
                    label={t('inputs.razaoSocialEmpresa')}
                    name="nome"
                    placeholder={t('inputs.insiraRazaoSocial')}
                    disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FieldText
                    required
                    name="cnpj"
                    label={t('inputs.cnpj')}
                    placeholder={t('inputs.insiraCnpj')}
                    disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FieldText
                    required
                    name="nomePais"
                    label={t('inputs.pais')}
                    placeholder={t('inputs.insiraPais')}
                    disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FieldText
                    required
                    name="nomeUf"
                    label={t('inputs.estado')}
                    placeholder={t('inputs.estado')}
                    disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FieldText
                    required
                    name="nomeMunicipio"
                    label={t('inputs.municipio')}
                    placeholder={t('inputs.municipio')}
                    disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FieldText
                    required
                    name="endereco"
                    label={t('inputs.endereco')}
                    placeholder={t('inputs.insiraEndereço')}
                    disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FieldText
                    required
                    name="cep"
                    label={t('inputs.cep')}
                    placeholder={t('inputs.insiraCEP')}
                    disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FieldText
                    name="site"
                    label={t('inputs.site')}
                    placeholder={t('inputs.insiraSite')}
                    disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                  />
                </Grid>
                <Grid item xs={12}><hr className={classes.separator} /></Grid>
                <Grid item xs={12}>
                  <Typography component="p" className={classes.subtitle}>
                    {t('empresa.empresaPossuiDivisoes')}
                  </Typography>
                  <Grid item xs={12}>
                    <FormControlLabel
                      className={classes.label}
                      label={<Typography className={classes.label}>{t('inputs.sim')}</Typography>}
                      control={(
                        <Radio
                          checked={get(values, 'possuiDivisaoSetor', false)}
                          onChange={() => {
                            form.change('possuiDivisaoSetor', true)
                            setPossuiDivisoes(true)
                          }}
                          color="primary"
                          size="small"
                          disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                        />
                      )}
                    />
                    <FormControlLabel
                      className={classes.label}
                      label={<Typography className={classes.label}>{t('inputs.nao')}</Typography>}
                      control={(
                        <Radio
                          checked={!get(values, 'possuiDivisaoSetor', false)}
                          onChange={() => {
                            form.change('possuiDivisaoSetor', false)
                            setPossuiDivisoes(false)
                          }}
                          color="primary"
                          size="small"
                          disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}><hr className={classes.separator} /></Grid>
                <Grid item xs={12}>
                  <FieldText
                    className={classes.inputTextArea}
                    name="informacoesAdicionais"
                    label={t('inputs.adicioneInformacoesAdicionais')}
                    placeholder={t('inputs.insiraInformacoesAdicionais')}
                    multiline
                    minRows={5}
                    maxRows={5}
                    disabled={tipoUsuarioLogado !== TIPO_USUARIO.CLIENTE.LIDER}
                  />
                </Grid>
              </Grid>
              {tipoUsuarioLogado === TIPO_USUARIO.CLIENTE.LIDER && (
                <Grid container justifyContent="flex-end">
                  <Button
                    onClick={handleSubmit}
                    loading={isSaving}
                    disabled={pristine}
                  >
                    {t('inputs.salvar')}
                  </Button>
                </Grid>
              )}
            </form>
          )}
        />
      </Box>
    </Box>
  )
}

EmpresaPerfilDetalhe.propTypes = {
  setPossuiDivisoes: PropTypes.func,
  setChangeTab: PropTypes.func,
}

export default EmpresaPerfilDetalhe
