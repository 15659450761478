import { combineEpics, ofType } from 'redux-observable'
import { debounceTime, map } from 'rxjs/operators'
import {
  apiCriarEcorregioes,
  apiExcluirEcorregioes,
  apiAtualizarEcorregioes,
} from 'store/modules/api/biomas.actions'
import {
  CALL_CRIAR_BIOMA,
  CALL_EXCLUIR_BIOMA,
  CALL_ATUALIZAR_BIOMA,
} from 'store/modules/biomas/biomas.actions'

const onCallCriarBioma = (action$) => action$.pipe(
  ofType(CALL_CRIAR_BIOMA),
  debounceTime(250),
  map(({ payload }) => apiCriarEcorregioes(payload)),
)

const onCallExcluirBioma = (action$) => action$.pipe(
  ofType(CALL_EXCLUIR_BIOMA),
  debounceTime(250),
  map(({ payload }) => apiExcluirEcorregioes(payload)),
)

const onCallAtualizarBioma = (action$) => action$.pipe(
  ofType(CALL_ATUALIZAR_BIOMA),
  debounceTime(250),
  map(({ payload }) => apiAtualizarEcorregioes(payload)),
)

export default combineEpics(
  onCallCriarBioma,
  onCallExcluirBioma,
  onCallAtualizarBioma,
)
