import {
  Accordion,
  withStyles,
} from '@material-ui/core'

const StyledAccordion = withStyles(() => ({
  root: {
    borderRadius: 0,
    borderTop: '1px solid #C9CEDB',
    borderBottom: '1px solid #C9CEDB',
    boxShadow: 'none',
    '&:last-child': {
      borderRadius: 0,
    },
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {
    borderRadius: 0,
  },
}))(Accordion)

export default StyledAccordion
