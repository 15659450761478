import React, { useEffect } from 'react'
import AuditorEmpresaLayout from 'components/AuditorEmpresaLayout'
import * as yup from 'yup'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { validateFormValues } from 'utils/forms'
import { makeStyles } from '@material-ui/core'
import MainColumn from 'components/MainColumn'
import Button from 'components/Button'
import FieldText from 'components/Fields/Text'
import { FieldDate } from 'components/Fields'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { getEmpresaAuditoriaSelecionada,
  getDadosAuditoriaSelecionada,
  getAnoSelecionado,
  getIsSavingAuditoriaSelecionada } from 'store/modules/auditor/auditor.selectors'
import { callSalvarDadosAuditoria } from 'store/modules/auditor'
import { TIPO_USUARIO } from 'utils/constantes'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import moment from 'moment'
import 'moment/locale/es'

const useStyles = makeStyles((theme) => ({
  infoSection: {
    marginTop: theme.spacing(8),
  },
  footerSection: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(8),
  },
  viewEditForm: {
    boxSizing: 'border-box',
    padding: theme.spacing(4, 8),
    margin: theme.spacing(0, 'auto'),
  },
}))

const Conclusao = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const isSaving = useSelector(getIsSavingAuditoriaSelecionada)
  const empresaSelecionada = useSelector(getEmpresaAuditoriaSelecionada)
  const dadosAuditoria = useSelector(getDadosAuditoriaSelecionada)
  const anoSelecionado = useSelector(getAnoSelecionado)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  useEffect(() => {
    moment.locale(t('inputs.calendario.idioma'))
  }, [t])

  const handleSave = (values) => {
    const data = {
      ...values,
      empresaId: empresaSelecionada.id,
      ano: anoSelecionado,
    }
    dispatch(callSalvarDadosAuditoria(data))
  }

  return (
    <AuditorEmpresaLayout
      renderChildren={(
        <Form
          onSubmit={handleSave}
          validate={validateFormValues(yup.object({
            conclusaoAuditoria: yup.string().required(),
            comentariosAdicionais: yup.string().required(),
            proximosPassos: yup.string().required(),
            dataEmissao: yup.string().required(),
          }))}
          initialValues={{
            conclusaoAuditoria: dadosAuditoria?.conclusaoAuditoria ?? '',
            comentariosAdicionais: dadosAuditoria?.comentariosAdicionais ?? '',
            proximosPassos: dadosAuditoria?.proximosPassos ?? '',
            dataEmissao: dadosAuditoria?.dataEmissao ?? '',
            numeroRevisao: dadosAuditoria?.numeroRevisao ?? null,
            dataRevisao: dadosAuditoria?.dataRevisao ?? null,
          }}
          render={({ handleSubmit, values, form, pristine, valid }) => (
            <MuiPickersUtilsProvider
              locale={t('inputs.calendario.idioma')}
              utils={MomentUtils}
              libInstance={moment}
            >
              <form onSubmit={handleSubmit}>
                <div className={classes.viewEditForm}>
                  <MainColumn className={classes.infoSection}>
                    <FieldText
                      required
                      name="conclusaoAuditoria"
                      label={t('inputs.conclusaoAuditoria')}
                      multiline
                      minRows={5}
                      disabled={tipoUsuarioLogado === TIPO_USUARIO.AUDITOR.MONITOR}
                    />
                    <FieldText
                      required
                      name="comentariosAdicionais"
                      label={t('inputs.comentariosadicionais')}
                      multiline
                      minRows={5}
                      disabled={tipoUsuarioLogado === TIPO_USUARIO.AUDITOR.MONITOR}
                    />
                    <FieldText
                      required
                      name="proximosPassos"
                      label={t('inputs.planejamentoParaProximoAuditoria')}
                      multiline
                      minRows={5}
                      disabled={tipoUsuarioLogado === TIPO_USUARIO.AUDITOR.MONITOR}
                    />

                    <MainColumn columns="repeat(3, 1fr)">
                      <FieldDate
                        required
                        name="dataEmissao"
                        label={t('inputs.dataEmissao')}
                        disabled={tipoUsuarioLogado === TIPO_USUARIO.AUDITOR.MONITOR}
                      />
                      <FieldText
                        type="number"
                        name="numeroRevisao"
                        label={t('inputs.numeroRevisao')}
                        disabled={tipoUsuarioLogado === TIPO_USUARIO.AUDITOR.MONITOR}
                      />
                      <FieldDate
                        name="dataRevisao"
                        label={t('inputs.dataRevisao')}
                        disabled={tipoUsuarioLogado === TIPO_USUARIO.AUDITOR.MONITOR}
                      />
                    </MainColumn>

                  </MainColumn>
                  {tipoUsuarioLogado === TIPO_USUARIO.AUDITOR.AUDITOR && (
                    <div className={classes.footerSection}>
                      <Button
                        disabled={pristine || isSaving}
                        variant="danger"
                        onClick={() => {
                          form.reset()
                          Object.keys(values).forEach((key) => {
                            form.resetFieldState(key)
                          })
                        }}
                      >
                        {t('inputs.cancelar')}
                      </Button>

                      <Button
                        disabled={pristine || !valid}
                        loading={isSaving}
                        type="submit"
                      >
                        {t('inputs.enviar')}
                      </Button>
                    </div>
                  )}
                </div>
              </form>
            </MuiPickersUtilsProvider>
          )}
        />
      )}
    />
  )
}

export default Conclusao
